import { Routes } from '@angular/router';

export const passwordRoutes: Routes = [
  {
    path: 'forgot-password',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/forgot-password/forgot.component').then(
            (o) => o.ForgotComponent
          ),
        children: [
          {
            path: 'reset',
            loadComponent: () =>
              import(
                './pages/forgot-password/reset-form/reset-form.component'
              ).then((o) => o.ResetFormComponent),
          },
          {
            path: '',
            loadComponent: () =>
              import(
                './pages/forgot-password/user-form/user-form.component'
              ).then((o) => o.UserFormComponent),
            pathMatch: 'full',
          },
          { path: '**', redirectTo: '' },
        ],
      },
    ],
  },
];
