import { QRBoxDimension } from "./qr-box-dimensions";

export interface ScannerAspectRatio {
  name: string;
  ratio: number;
}

export const SOFTLINE_CONST_SCANNER_ASPECT_RATIO_1: ScannerAspectRatio = {
  name: '1:1',
  ratio: 1
}
export const SOFTLINE_CONST_SCANNER_ASPECT_RATIO_0p5: ScannerAspectRatio = {
  name: '1:2',
  ratio: 0.5
}
export const SOFTLINE_CONST_SCANNER_ASPECT_RATIO_1p3: ScannerAspectRatio = {
  name: '4:3',
  ratio: 1.333334
}

export const SOFTLINE_CONST_SCANNER_ASPECT_RATIO_1p7: ScannerAspectRatio = {
  name: '16:9',
  ratio: 1.777778
}
