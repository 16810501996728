import { Affect, ClearAffect, PropertyAffect, ValueAffect } from "../data/affects";
import { Dictionary, getValue } from "@softline/core";

export function handleAffected(affect: Affect, value: any): Dictionary<unknown> {
  switch (affect.name) {
    case "clear":
      return handleClearAffected(affect);
    case "value":
      return handleValueAffected(affect);
    case "property":
      return handlePropertyAffected(affect, value);
    default:
      throw new Error(`[handleAffected]:no affect named '${(affect as any).name}'`)
  }
}

export function handleClearAffected(affect: ClearAffect): Dictionary<unknown> {
  return {[affect.property]: undefined}
}

export function handleValueAffected(affect: ValueAffect): Dictionary<unknown> {
  return {[affect.property]: affect.value}
}

export function handlePropertyAffected(affect: PropertyAffect, value: any): Dictionary<unknown>  {
  if(affect.source)
    value = getValue(value, affect.source)
  return {[affect.property]: value}
}
