import { effect, inject, Injectable, OnDestroy, Signal, TemplateRef, Type } from "@angular/core";
import { BottomOutletService } from "../program/services/bottom-outlet.service";

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithBottomContent = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class BottomContentMixin extends Base implements OnDestroy {

    currentBottomContent?: TemplateRef<any> | Type<any>;

    outletService = inject(BottomOutletService);
    abstract bottomContent: Signal<TemplateRef<any> | Type<any> | null | undefined>

    contentEffect = effect(() => {
      const content = this.bottomContent();
      if(this.currentBottomContent)
        this.outletService.remove(this.currentBottomContent)
      if(!content)
        return;
      this.outletService.append(content);
      this.currentBottomContent = content;
    },);

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnDestroy(): void {
      if(this.currentBottomContent)
        this.outletService.remove(this.currentBottomContent)

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return BottomContentMixin;
}
