import { Component, Inject, OnInit, Optional, Type } from "@angular/core";
import { SOFTLINE_CONFIG_DASHBOARD_COMPONENT } from '../../application.shared';
import { CommonModule } from "@angular/common";
import { BehaviorSubject, combineLatestWith, Observable } from "rxjs";
import grapesjs from "grapesjs";
import Components = grapesjs.Components;
import { isDefined, Store } from "@softline/core";
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from "@softline/auth";
import { map } from "rxjs/operators";
import { isDashboardComponent } from "../types/dashboard-component";

@Component({
  selector: 'soft-dashboard',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  private _components$ = new BehaviorSubject<(Type<Component> | DashboardComponent)[]>([]);
  components$ : Observable<Type<Component>[]> = this._components$.pipe(
    combineLatestWith(this.store.observe(SOFTLINE_FEATURE_AUTHORIZATION, AuthorizationStore.getters.permissions)),
    map(([components, permissions]) =>
      components.map(o => {
        if(!isDashboardComponent(o))
          return o;
        if(!isDefined(o.permission) || permissions.indexOf(o.permission) > -1)
          return o.component;
        return undefined;
    }).filter((o): o is Type<Component> => !!o))
  )

  constructor(
    private store: Store,
    @Optional()
    @Inject(SOFTLINE_CONFIG_DASHBOARD_COMPONENT)
    components: (Type<Component> | DashboardComponent)[] = []
  ) {
    this._components$.next(components);
  }

  ngOnInit(): void {}
}
