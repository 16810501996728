import {
  AbstractControl, ValidationErrors,
  ValidatorFn,
  Validators as BaseValidators
} from "@angular/forms";
import {
  containsText,
  Dictionary,
  equals,
  isDefined,
} from '@softline/core';
import { Validation } from '@softline/core';

export interface ValidationExtras {
  validation?: Validation;
  success?: boolean;
  successValidation?: Validation;
}

export namespace Validators {
  export function min(min: number, extras?: ValidationExtras): ValidatorFn {
    return (control: AbstractControl) => {
      let result = BaseValidators.min(min)(control);
      if (result && isDefined(result['min']))
        result['min'] = extras?.validation ?? {
          isValid: false,
          messages: [
            {
              type: 'error',
              subject: '#UI_CORE.VALIDATION.MIN.SUBJECT',
              message: '#UI_CORE.VALIDATION.MIN.MESSAGE',
              data: result['min'],
            },
          ],
        };
      else if (extras?.success) {
        result = { ...(result ?? {}) };
        result['min'] = extras?.successValidation ?? {
          isValid: true,
          messages: [
            {
              type: 'success',
              subject: '#UI_CORE.VALIDATION.MIN.SUBJECT',
              message: '#UI_CORE.VALIDATION.MIN.MESSAGE',
              data: { min },
            },
          ],
        };
      }
      return result;
    };
  }

  export function max(max: number, extras?: ValidationExtras): ValidatorFn {
    return (control: AbstractControl) => {
      let result = BaseValidators.max(max)(control);
      if (result && isDefined(result['max']))
        result['max'] = extras?.validation ?? {
          isValid: false,
          messages: [
            {
              type: 'error',
              subject: '#UI_CORE.VALIDATION.MAX.SUBJECT',
              message: '#UI_CORE.VALIDATION.MAX.MESSAGE',
              data: result['max'],
            },
          ],
        };
      else if (extras?.success) {
        result = { ...(result ?? {}) };
        result['max'] = extras?.successValidation ?? {
          isValid: true,
          messages: [
            {
              type: 'success',
              subject: '#UI_CORE.VALIDATION.MAX.SUBJECT',
              message: '#UI_CORE.VALIDATION.MAX.MESSAGE',
              data: { max },
            },
          ],
        };
      }
      return result;
    };
  }

  export function required(extras?: ValidationExtras): ValidatorFn {
    return (control: AbstractControl) => {
      let result = BaseValidators.required(control);
      if (result && isDefined(result['required']))
        result['required'] = extras?.validation ?? {
          isValid: false,
          messages: [
            {
              type: 'error',
              subject: '#UI_CORE.VALIDATION.REQUIRED.SUBJECT',
              message: '#UI_CORE.VALIDATION.REQUIRED.MESSAGE',
            },
          ],
        };
      else if (extras?.success) {
        result = { ...(result ?? {}) };
        result['required'] = extras?.successValidation ?? {
          isValid: true,
          messages: [
            {
              type: 'success',
              subject: '#UI_CORE.VALIDATION.REQUIRED.SUBJECT',
              message: '#UI_CORE.VALIDATION.REQUIRED.MESSAGE',
            },
          ],
        };
      }
      return result;
    };
  }

  export function email(extras?: ValidationExtras): ValidatorFn {
    return (control: AbstractControl) => {
      let result = BaseValidators.email(control);
      if (result && isDefined(result['email']))
        result['email'] = extras?.validation ?? {
          isValid: false,
          messages: [
            {
              type: 'error',
              subject: '#UI_CORE.VALIDATION.EMAIL.SUBJECT',
              message: '#UI_CORE.VALIDATION.EMAIL.MESSAGE',
            },
          ],
        };
      else if (extras?.success) {
        result = { ...(result ?? {}) };
        result['email'] = extras?.successValidation ?? {
          isValid: true,
          messages: [
            {
              type: 'success',
              subject: '#UI_CORE.VALIDATION.EMAIL.SUBJECT',
              message: '#UI_CORE.VALIDATION.EMAIL.MESSAGE',
            },
          ],
        };
      }
      return result;
    };
  }

  export function minLength(
    minLength: number,
    extras?: ValidationExtras
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let result = BaseValidators.minLength(minLength)(control);
      if (result && isDefined(result['minlength']))
        result['minlength'] = extras?.validation ?? {
          isValid: false,
          messages: [
            {
              type: 'error',
              subject: '#UI_CORE.VALIDATION.MIN_LENGTH.SUBJECT',
              message: '#UI_CORE.VALIDATION.MIN_LENGTH.MESSAGE',
              data: result['minlength'],
            },
          ],
        };
      else if (extras?.success) {
        const actualLength = control.value?.length;
        result = { ...(result ?? {}) };
        result['minlength'] = extras?.successValidation ?? {
          isValid: true,
          messages: [
            {
              type: 'success',
              subject: '#UI_CORE.VALIDATION.MIN_LENGTH.SUBJECT',
              message: '#UI_CORE.VALIDATION.MIN_LENGTH.MESSAGE',
              data: { actualLength, requiredLength: minLength },
            },
          ],
        };
      }
      return result;
    };
  }

  export function maxLength(
    maxLength: number,
    extras?: ValidationExtras
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let result = BaseValidators.maxLength(maxLength)(control);
      if (result && isDefined(result['maxlength']))
        result['maxlength'] = extras?.validation ?? {
          isValid: false,
          messages: [
            {
              type: 'error',
              subject: '#UI_CORE.VALIDATION.MAX_LENGTH.SUBJECT',
              message: '#UI_CORE.VALIDATION.MAX_LENGTH.MESSAGE',
              data: result['maxlength'],
            },
          ],
        };
      else if (extras?.success) {
        const actualLength = control.value?.length;
        result = { ...(result ?? {}) };
        result['maxlength'] = extras?.successValidation ?? {
          isValid: true,
          messages: [
            {
              type: 'success',
              subject: '#UI_CORE.VALIDATION.MIN_LENGTH.SUBJECT',
              message: '#UI_CORE.VALIDATION.MIN_LENGTH.MESSAGE',
              data: { actualLength, requiredLength: maxLength },
            },
          ],
        };
      }
      return result;
    };
  }

  export function isNumber(
    extras?: ValidationExtras
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let result: ValidationErrors | null = null;
      if (typeof control.value !== 'number' || Number.isNaN(control.value)) {
        result = {};
        result['isNumber'] = extras?.validation ?? {
          isValid: false,
          messages: [
            {
              type: 'error',
              subject: '#UI_CORE.VALIDATION.IS_NUMBER.SUBJECT',
              message: '#UI_CORE.VALIDATION.IS_NUMBER.MESSAGE',
            },
          ],
        };
      }
      else if (extras?.success) {
        result = {};
        result['isNumber'] = extras?.successValidation ?? {
          isValid: true,
          messages: [
            {
              type: 'success',
              subject: '#UI_CORE.VALIDATION.IS_NUMBER.SUBJECT',
              message: '#UI_CORE.VALIDATION.IS_NUMBER.MESSAGE'
            },
          ],
        };
      }
      return result;
    };
  }

  export class Matcher<T extends object> {
    private isNot = false;

    constructor(private property: keyof T) {}

    not(): this {
      this.isNot = true;
      return this;
    }

    toBe(value: any): ValidatorFn {
      const func = (o: T) => o[this.property] === value;
      return this.toValidatorFn(func);
    }
    toEqual(value: any): ValidatorFn {
      const func = (o: T) => equals(o[this.property], value);
      return this.toValidatorFn(func);
    }
    toContain(text: string, ignoreCase?: boolean): ValidatorFn {
      const func = (o: T) => containsText(o[this.property], text, ignoreCase);
      return this.toValidatorFn(func);
    }
    toBeLessThan(value: any): ValidatorFn {
      const func = (o: T) => o[this.property] < value;
      return this.toValidatorFn(func);
    }
    toBeLessThanOrEqual(value: any): ValidatorFn {
      const func = (o: T) => o[this.property] <= value;
      return this.toValidatorFn(func);
    }
    toBeGreaterThan(value: any): ValidatorFn {
      const func = (o: T) => o[this.property] > value;
      return this.toValidatorFn(func);
    }
    toBeGreaterThanOrEqual(value: any): ValidatorFn {
      const func = (o: T) => o[this.property] >= value;
      return this.toValidatorFn(func);
    }
    toBeUndefined(): ValidatorFn {
      const func = (o: T) => o[this.property] === undefined;
      return this.toValidatorFn(func);
    }

    toBeProperty(name: keyof T): ValidatorFn {
      const func = (o: T) => o[this.property] === o[name];
      return this.toValidatorFn(func);
    }
    toEqualProperty(name: keyof T): ValidatorFn {
      const func = (o: T) => equals(o[this.property], o[name]);
      return this.toValidatorFn(func);
    }
    toContainProperty(name: keyof T, ignoreCase?: boolean): ValidatorFn {
      const func = (o: T) =>
        containsText(
          o[this.property],
          o[name] as unknown as string,
          ignoreCase
        );
      return this.toValidatorFn(func);
    }
    toBeLessThanProperty(name: keyof T): ValidatorFn {
      const func = (o: T) => o[this.property] < o[name];
      return this.toValidatorFn(func);
    }
    toBeLessThanOrEqualProperty(name: keyof T): ValidatorFn {
      const func = (o: T) => o[this.property] <= o[name];
      return this.toValidatorFn(func);
    }
    toBeGreaterThanProperty(name: keyof T): ValidatorFn {
      const func = (o: T) => o[this.property] > o[name];
      return this.toValidatorFn(func);
    }
    toBeGreaterThanOrEqualProperty(name: keyof T): ValidatorFn {
      const func = (o: T) => o[this.property] >= o[name];
      return this.toValidatorFn(func);
    }

    private toValidatorFn(
      func: (value: T) => boolean,
      validation?: Validation
    ): ValidatorFn {
      return (control: AbstractControl) => {
        let result = func(control.value);
        if (this.isNot) result = !result;
        if (!result)
          return {
            expect: validation ?? {
              isValid: false,
              messages: [
                {
                  type: 'error',
                  subject: '#UI_CORE.VALIDATION.REQUIRED.SUBJECT',
                  message: '#UI_CORE.VALIDATION.REQUIRED.MESSAGE',
                },
              ],
            },
          };
        return {};
      };
    }
  }
}
