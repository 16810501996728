import { AfterViewInit, Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
  standalone: true,
  selector: '[softFocus]',
  exportAs: 'softFocus'
})
export class SetFocusDirective implements AfterViewInit {

  constructor(private el: ElementRef) {
    if (!el.nativeElement['focus']) {
      throw new Error('Element does not accept focus.');
    }
  }

  ngAfterViewInit(): void {
    this.focus();
  }

  focus(): void {
    let input: HTMLInputElement | undefined;
    const stack = [this.el.nativeElement];
    while (stack.length) {
      const current = stack.pop();
      if(current.tagName === 'INPUT') {
        input = current as HTMLInputElement;
        break;
      }
      else if(current.children?.length)
        stack.push( ...current.children)
    }
    if(input)
      input.focus();
  }
}
