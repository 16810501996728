import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import JsBarcode from 'jsbarcode';
import { isNullOrEmpty } from "@softline/core";

let id = 0;

@Component({
  selector: 'soft-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit, AfterViewChecked {
  private _viewInit = false;

  canvasId = 'barcodeCanvas' + ++id;

  @Input() data = '';
  @Input() labelType:
    | 'codabar'
    | 'code39'
    | 'code128'
    | 'ean13'
    | 'ean8'
    | 'itf'
    | 'upc' = 'ean13';

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewChecked(): void {
    if(!isNullOrEmpty(this.data))
      JsBarcode(`#${this.canvasId}`, this.data, { format: this.labelType });
    this._viewInit = true;
  }
}
