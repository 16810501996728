import { runInZone } from '@softline/core';
import { TranslationLoader } from './translation-loader';
import { lastValueFrom, of, throwError } from 'rxjs';
import { TranslationFile } from '../data/translation-file';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { SOFTLINE_CONFIG_TRANSLATION_BASE_PATH } from '../i18n.shared';
import { catchError, map, tap } from 'rxjs/operators';
import { Logger } from '@softline/core';

@Injectable()
export class HttpTranslationLoader extends TranslationLoader {
  constructor(
    private httpClient: HttpClient,
    @Inject(SOFTLINE_CONFIG_TRANSLATION_BASE_PATH) private basePath: string,
    private zone: NgZone,
    private logger: Logger
  ) {
    super();
  }

  load(language: string, module?: string): Promise<TranslationFile> {
    const path = this.getPath(language, module);
    this.logger.log(`[Softline UICore] Translations: loading ${path}`);
    return lastValueFrom(
      this.httpClient.get<object>(path).pipe(
        map((result) => ({ language, module, translations: result })),
        tap((o) =>
          this.logger.log(`[Softline UICore] Translations: loaded ${path}`)
        ),
        catchError((e) => {
          this.logger.log(
            `[[Softline UICore] Translations: loading failed ${path} (${e.message})`
          );
          return of({ language, module, translations: [] });
        }),
        runInZone(this.zone)
      )
    );
  }

  private getPath(language: string, module?: string): string {
    let path = this.basePath;
    if (module) path += `/modules/${module}`;
    path += `/i18n/${language}.json`;
    return path;
  }
}
