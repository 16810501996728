<div class="grid-container">
  <div
    *ngFor="let art of belegarten()"
    class="soft-card grid-item-container"
    [routerLink]="[art.id, 'find']"
  >
    <span class="application-icon">
      <soft-icon name="fa-regular fa-file-invoice fa-4x"></soft-icon>
    </span>
    <div class="soft-container soft-container-center set-width">
      <span class="application-title">{{ art.kundenName ?? art.name }}</span>
    </div>
  </div>
</div>
