import { inject, Injectable } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Breakpoint } from "./breakpoint.store";
import { SOFTLINE_CONFIG_BREAKPOINTS } from "./breakpoint.shared";
import { map } from "rxjs/operators";
import { toSignal } from "@angular/core/rxjs-interop";

type Constructor<T> = new(...args: any[]) => T;

export const WithBreakpoints = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class BreakpointMixin extends Base {

    breakpointObserver = inject(BreakpointObserver);
    breakpoints = inject(SOFTLINE_CONFIG_BREAKPOINTS);

    breakpoint = toSignal(this.breakpointObserver
      .observe([
        `(min-width: ${this.breakpoints.sm})`,
        `(min-width: ${this.breakpoints.md})`,
        `(min-width: ${this.breakpoints.lg})`,
        `(min-width: ${this.breakpoints.xl})`,
        `(min-width: ${this.breakpoints.xxl})`,
      ])
      .pipe(
        map((o) => {
          let breakpoint: Breakpoint = null;
          if (o.breakpoints[`(min-width: ${this.breakpoints.xxl})`])
            breakpoint = '2xl';
          else if (o.breakpoints[`(min-width: ${this.breakpoints.xl})`])
            breakpoint = 'xl';
          else if (o.breakpoints[`(min-width: ${this.breakpoints.lg})`])
            breakpoint = 'lg';
          else if (o.breakpoints[`(min-width: ${this.breakpoints.md})`])
            breakpoint = 'md';
          else if (o.breakpoints[`(min-width: ${this.breakpoints.sm})`])
            breakpoint = 'sm';
          return breakpoint;
        })), {initialValue: null});

    constructor(...args: any[]) {
      super(...args);
    }
  }
  return BreakpointMixin;
}
