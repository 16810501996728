import { Message } from '../../../../data/message';
import { Entity } from './entity';

export type Change<T extends Entity> =
  | CreateChange<T>
  | UpdateChange<T>
  | DeleteChange<T>
  | PatchChange<T>;

export interface CreateChange<T extends Entity> {
  action: 'create';
  entity: T;
}

export interface UpdateChange<T extends Entity> {
  action: 'update';
  entity: T;
}

export interface DeleteChange<T extends Entity> {
  action: 'delete';
  id: string | number;
}

export interface PatchChange<T extends Entity> {
  action: 'patch';
  id: string | number;
  changes: Partial<T>;
}

export interface LazySaveResult<T extends Entity> {
  succeeded: ChangeSuccess<T>[];
  failed: ChangeFailure<T>[];
}

export interface ChangeSuccess<T extends Entity> {
  action: 'create' | 'patch' | 'update' | 'delete';
  entity: T;
}

export interface ChangeFailure<T extends Entity> {
  change: Change<T>;
  messages: Message[];
}

export function getChangeId<T extends Entity>(
  change: Change<T>
): string | number {
  switch (change.action) {
    case 'create':
    case 'update':
      return change.entity.id;
    case 'patch':
    case 'delete':
      return change.id;
    default:
      throw new Error(`getChangeId: unknown action`);
  }
}
