import { contentChild, contentChildren, Directive, ElementRef, forwardRef, input, Input } from "@angular/core";
import { ModalOption } from "../../../modal/data/option-modal-config";
import { SelectOptionBase } from "../select/select-option/select-option-base";
import { string } from "mathjs";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'soft-select-box-option',
  standalone: true,
  providers: [
    {
      provide: SelectOptionBase,
      useExisting: forwardRef(() => SelectBoxOptionDirective),
    },
  ],
})
export class SelectBoxOptionDirective extends SelectOptionBase {

  @Input({required: true}) value: any;
  @Input() label?: string;
  @Input() icon?: string;
  @Input() class?: string;
  @Input() disabled?: boolean;

  constructor(public element: ElementRef) {
    super();
  }

}
