<div class="text-lg font-semibold mb-3 mt-2 px-4 sm:px-0">
  {{'#WWS_WARENUEBERNAHME.DIALOG.TITEL_WARENUEBERNAHME_WIRD_BEARBEITET' | translate}}
</div>

<div class="flex flex-col px-4 sm:px-0">
  <div class="mb-1">
    {{'#WWS_WARENUEBERNAHME.DIALOG.LABEL_WARENUEBERNAHME_WIRD_BEARBEITET' | translate}}
    <span class="font-semibold">"{{exzess.anwender}}"</span>
    {{'#WWS_WARENUEBERNAHME.DIALOG.LABEL_WARENUEBERNAHME_BEARBEITET' | translate}}
  </div>

  <div class="mb-3">
    {{'#WWS_WARENUEBERNAHME.DIALOG.LABEL_DENNOCH_FORTFAHREN' | translate}}
  </div>
</div>

<div class="flex flex-row w-full sm:gap-2">
  <button
    class="soft-button secondary w-1/2 mt-2 h-14"
    (click)="close(false)">
    {{'#WWS_WARENUEBERNAHME.DIALOG.LABEL_ABBRECHEN' | translate}}
  </button>
  <button
    class="soft-button primary w-1/2 mt-2 h-14"
    (click)="close(true)">
    {{'#WWS_WARENUEBERNAHME.DIALOG.LABEL_FORTFAHREN' | translate}}
  </button>
</div>
