@if (definition()?.visible | booleanRule: value:true) { @for (child of
definition()?.definitions ?? []; track 'name') {
<div [ngClass]="child.class | classRule : value">
  @if (child.type === 'group') {
  <soft-dynamic-template-field-list
    [value]="value()"
    [definition]="$any(child)"
    [loading]="loading()"
  >
  </soft-dynamic-template-field-list>
  } @else if (value()[child['name']] | isDefinedNotEmpty) {
  <div class="flex flex-row justify-between">
    <label
      *ngIf="child.title"
      class="soft-label"
      [ngClass]="(child.titleClass | classRule : value) || 'left'"
      >{{ child.title }}</label
    >
    <soft-dynamic-value
      class="w-full"
      [definition]="child"
      [value]="value()"
      [loading]="loading()"
    ></soft-dynamic-value>
  </div>
  }
</div>
} }
