import { Position } from './position';
import {
  createAction,
  createGetter,
  createMutation,
  DateService,
  mutate,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import { GeolocationService } from './services/geolocation.service';

export interface State {
  positions: {
    timestamp: string;
    position: Position;
  }[];
}

export const mutations = {
  add: createMutation<State, { timestamp: string; position: Position }>(
    'addPosition'
  ),
  clear: createMutation<State>('clear'),
};

export const actions = {
  getCurrentPosition: createAction<State, undefined, Position>(
    'getCurrentPosition'
  ),
};

export const getters = {
  positions: createGetter<State, Position[]>('scans'),
  latest: createGetter<State, Position>('latest'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    positions: [],
  },
  mutations: [
    mutate(mutations.add, ({ state, params }) => ({
      ...state,
      positions: [...state.positions, params],
    })),
    mutate(mutations.clear, ({ state }) => ({ ...state, scans: [] })),
  ],
  actions: [
    on(
      actions.getCurrentPosition,
      async ({ commit, featureName, injector }) => {
        const service = injector.get(GeolocationService);
        const dateService = injector.get(DateService);
        const position = await service.getCurrentPosition();
        commit(featureName, mutations.add, {
          timestamp: dateService.now(),
          position,
        });
        return position;
      }
    ),
  ],
  getters: [
    select(getters.latest, ({ state }) => {
      const index = state.positions.length - 1;
      if (index === -1) return undefined;
      return state.positions[index]?.position;
    }),
  ],
};
