import { Routes } from "@angular/router";
import { DashboardPage } from "./pages/dashboard/dashboard.page";
import { AuthenticationGuard } from "@softline/auth";
import { DashboardGuard } from "./guards/dashboard.guard";

export const dashboardRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardPage,
    canActivate: [AuthenticationGuard, DashboardGuard],
  },
]
