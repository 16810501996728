import {Inject, Injectable} from '@angular/core';
import {WarenverprobungBelegeingabeService} from './warenverprobung-scan-service';
import {Scan} from '@softline/application';
import {Observable, of} from 'rxjs';
import {ConnectionHttpService, ConnectionResourceLocation, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {Beleg} from '../data/beleg';
import {SOFTLINE_API_MDE_WARENVERPROBUNG_FIND_BELEG} from '../warenverprobung.api';

@Injectable()
export class BestellungBelegeingabeService implements WarenverprobungBelegeingabeService {

  get inputLabel(): string {
    return 'Bestellung'
  }

  get inputPlaceholder(): string {
    return 'Belegnummer'
  }

  get pageTitle(): string {
    return 'Bestellung eingeben'
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService
  ) {}

  scan(scan: Scan): Observable<Beleg> {
    console.log('[BestellungBelegeingabeService] scan');
    return this.httpService.get({
      path: 'v1/bestellung/scan/{{code}}',
      pathParams: { code: scan.data }
    });
  }

  find(belegnumer: string): Observable<Beleg> {
    console.log('[BestellungBelegeingabeService] find');
    return this.httpService.get({
      path: SOFTLINE_API_MDE_WARENVERPROBUNG_FIND_BELEG,
      queryParams: { nummer: belegnumer, variante: 'BESTELLUNG' }
    });
  }
}
