import { inject, Pipe, PipeTransform } from '@angular/core';
import { MetaFieldNameDirective } from '../directives/object/meta-field-name.directive';
import { FormatPipe } from '@softline/ui-core';
import { MetaControlNameDirective } from '../directives/form/meta-control-name.directive';

@Pipe({
  name: 'metaFormat',
  standalone: true,
})
export class MetaFormatPipe implements PipeTransform {
  meta = inject(MetaControlNameDirective, {optional: true})
    ?? inject(MetaFieldNameDirective);

  formatPipe = inject(FormatPipe);

  transform(value: string | number | object | undefined | null | Date): string {
    if(!this.meta)
      throw new Error('[MetaFormatPipe] MetaFieldDirective is not provided.');
    const field = this.meta instanceof MetaControlNameDirective
      ? this.meta.control()
      : this.meta.field() as any;
    if(field.type === 'select') {
      const option = field['options'].find(o => o.value === value)?.label ?? '';
      console.log('---------', field, value, option);
      return option;
    }
    return this.formatPipe.transform(value, this.meta.format());
  }
}
