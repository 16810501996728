import { Route } from '@angular/router';
import { ENVIRONMENT_INITIALIZER, inject, Type } from '@angular/core';
import {
  CommandStore,
  ShellComponent,
  SOFTLINE_FEATURE_COMMANDS,
} from '@softline/application';
import { Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import { AuthenticationGuard, AuthorizationResolver } from '@softline/auth';

export const moduleRoutes: Route[] = [
  //{ path: 'my-apps', component: MyApplicationsComponent },
  //{ path: 'favorites', component: FavoritesComponent },
  {
    path: '',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/app-overview.component').then(
            (o) => o.AppOverviewComponent
          ),
      },
    ],
    resolve: {
      permissions: AuthorizationResolver,
    },
  },
];
