import {
  FieldOkConfig,
  FieldOkListViewComponent, FieldOkMasterDetailViewComponent, FieldOkMultiselectListViewComponent,
  FieldOkQueryViewComponent,
  FieldOkTableViewComponent, QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { KtostammLieferantListView } from "./list-view/ktostamm-lieferant.list-view";
import { KtostammLieferantQueryView } from "./query-view/ktostamm-lieferant.query-view";
import { KtostammLieferantMasterView } from "./master-detail-view/master-view/ktostamm-lieferant.master-view";
import { KtostammLieferantDetailView } from "./master-detail-view/detail-view/ktostamm-lieferant.detail-view";
import { FilterConfig, SortConfig } from "@softline/application";

const defaultSort= {property: 'nummer', direction: 'asc'}
const sort: SortConfig = {
  properties: [
    {property: 'nummer', title: 'Nummer'},
    {property: 'name1', title: 'Nachname'},
    {property: 'name2', title: 'Vorname'},
    {property: 'postleitzahl', title: 'PLZ'},
    {property: 'ort', title: 'Ort'},
    {property: 'strasse', title: 'Strasse'},
  ]
};
const filter: FilterConfig = {
  properties: [
    {property: 'nummer', title: 'Nummer'},
    {property: 'name1', title: 'Nachname'},
    {property: 'name2', title: 'Vorname'},
    {property: 'postleitzahl', title: 'PLZ'},
    {property: 'ort', title: 'Ort'},
    {property: 'strasse', title: 'Strasse'},
  ]
};

export const ktostammLieferantFieldOk: FieldOkConfig = {
  name: 'ktostamm.lieferant',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: "{{nummer}} - {{kurzbez}}",
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: KtostammLieferantQueryView
    },
  }, {
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: KtostammLieferantListView,
      sort,
      filter,
      defaultSort,
    }
  }, {
    name: 'masterDetail',
    component: FieldOkMasterDetailViewComponent,
    title: 'Detail',
    config: {
      masterComponent: KtostammLieferantMasterView,
      detailComponent: KtostammLieferantDetailView,
      sort,
      filter,
      defaultSort,
    }
  }]
};
export const ktostammLieferantMultiselectFieldOk: FieldOkConfig = {
  name: 'ktostamm.lieferant',
  type: 'multi',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: "{{nummer}} - {{kurzbez}}",
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: KtostammLieferantQueryView,
    },
  }, {
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl (mehrfach)',
    config: {
      component: KtostammLieferantListView,
      sort,
      filter,
      defaultSort,
    },
  }]
};
export const ktostammLieferantQueryFieldOk: FieldOkConfig = {
  name: 'ktostamm.lieferant',
  type: 'query',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: "{{nummer}} - {{kurzbez}}",
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: KtostammLieferantQueryView,
    },
  }, {
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl (mehrfach)',
    config: {
      component: KtostammLieferantListView,
      sort,
      filter,
      defaultSort,
    },
  }]
};
