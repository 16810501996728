import {ItemScan} from '@softline/application';
import {createGetter, createMutation, CustomStore, mutate, select} from '@softline/core';

export interface ScanListeState {
  scannedItems: ItemScan[];
}

const mutations = {
  addOrUpdate: createMutation<ScanListeState, ItemScan>('scanListe/addOrUpdate'),
  remove: createMutation<ScanListeState, ItemScan>('scanListe/remove'),
}

const actions = {}

const getters = {
  scannedItems: createGetter<ScanListeState, ItemScan[], void>('scanListe/getScannedItems')
}

export const ScanListStore: CustomStore<ScanListeState, typeof mutations, typeof getters, typeof actions> = {
  mutations,
  actions,
  getters,
  feature: {
    initialState: {
      scannedItems: []
    },
    actions: [],
    getters: [
      select(getters.scannedItems, ({ state }) => state.scannedItems ?? [])
    ],
    mutations: [
      mutate(mutations.addOrUpdate, ({ state, params }) => {
        const scannedItems = [...state.scannedItems];
        const index = scannedItems.findIndex(o => o.artikel.id === params.artikel.id);

        if (index === -1)
          scannedItems.push(params);
        else
          scannedItems[index] = params;

        return {
          ...state,
          scannedItems
        };
      }),
      mutate(mutations.remove, ({ state, params }) => {
        return {
          ...state,
          scannedItems: state.scannedItems.filter(o => o.artikel.id !== params.artikel.id)
        };
      })
    ]
  }
};
