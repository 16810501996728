import { NgModule } from "@angular/core";
import { FilterDefinedValuesPipe } from "./filter-defined-values.pipe";
import { VirtualScrollItemDisplayPipe } from "./virtual-scroll-item-display.pipe";
import { ScanDefinitionPipe } from "./scan-definition.pipe";
import { ResolveParametersPipe } from "./resolve-parameters.pipe";
import { PropertyClassPipe } from "./property-class.pipe";
import { PropertyStylePipe } from "./property-style.pipe";
import { CombineValidationsPipe } from "./combine-validations.pipe";
import { SelectedPipe } from "./selected.pipe";
import { TitlePipe } from "./title.pipe";
import { FormatRulePipe } from "./format-rule.pipe";
import { ClassRulePipe } from "./class-rule.pipe";
import { BooleanRulePipe } from "./boolean-rule.pipe";
import { ResultMappingConverterPipe } from "./result-mapping-converter.pipe";

@NgModule({
  imports: [
    BooleanRulePipe,
    ClassRulePipe,
    FormatRulePipe,
    TitlePipe,
    SelectedPipe,
    CombineValidationsPipe,
    PropertyStylePipe,
    PropertyClassPipe,
    ResolveParametersPipe,
    ScanDefinitionPipe,
    VirtualScrollItemDisplayPipe,
    FilterDefinedValuesPipe,
    ResultMappingConverterPipe
  ],
  providers: [FormatRulePipe],
    exports: [
      BooleanRulePipe,
      ClassRulePipe,
      FormatRulePipe,
      TitlePipe,
      SelectedPipe,
      CombineValidationsPipe,
      PropertyStylePipe,
      PropertyClassPipe,
      ResolveParametersPipe,
      ScanDefinitionPipe,
      VirtualScrollItemDisplayPipe,
      FilterDefinedValuesPipe,
      ResultMappingConverterPipe,
    ],
})
export class DynamicPipesModule {
}
