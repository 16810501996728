import { Injector } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { resolveRule } from "../functions/resolve-rule.function";
import { Affect } from "../data/affects";
import { handleAffected } from "../functions/resolve-affected.function";
import { RuleHelper } from "./rule.helper";
import { Dictionary } from "@softline/core";

export class AffectedHelper {
  static getAffectedPatch(
    affects: Affect[] | undefined,
    value: any,
    injector: Injector
  ): Dictionary<unknown> {
    if(!affects)
      return {};
    let patch = {}
    for (const affect of affects) {
      if(affect.rule && !RuleHelper.getBoolean(affect.rule, value, injector))
        continue;
      patch = {...patch, ...handleAffected(affect, value)};
    }
    return patch
  }
}
