import { Injectable } from '@angular/core';
import { GeolocationService, Position } from '@softline/application';
import { Store } from '@softline/core';

@Injectable()
export class BrowserGeolocationService extends GeolocationService {
  constructor() {
    super();
  }

  getCurrentPosition(): Promise<Position> {
    return new Promise<Position>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position: any) =>
          resolve({
            gpslaenge: position.coords.longitude,
            gpsbreite: position.coords.latitude,
            gpshoehe: position.coords.altitude,
            genauigkeit: position.coords.accuracy,
            genauigkeithoehe: position.coords.altitudeAccuracy,
            richtung: position.coords.heading,
            geschwindigkeit: position.coords.speed,
          }),
        (error: any) => reject(error),
        {
          timeout: 20000,
          enableHighAccuracy: true,
        }
      );
    });
  }
}
