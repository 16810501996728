import {CalculationData, KzAusgabeStrategy} from "../kz-ausgabe.strategy";
import {Dictionary} from "@softline/core";

export const KzAusgabeAllStrategy: KzAusgabeStrategy = {
  calculate(data: CalculationData): Dictionary<number | null> {

    const dict = data.dictionary;

    const ausgabeEh1 = data.artehs.find(o => o.kzausgabe === '1');
    const ausgabeEh2 = data.artehs.find(o => o.kzausgabe === '2');

    if (!ausgabeEh1 || ! ausgabeEh2 || !data.inputArteh?.kzausgabe) {
      return dict
    }

    if (data.inputArteh.kzausgabe === '1') {
      const ausgabeEh1Quantity = Math.ceil(data.inputValue ?? 0);
      const faktor = (ausgabeEh2.faktor / ausgabeEh1.faktor);
      const ausgabeEh2Quantity = Math.floor(ausgabeEh1Quantity / faktor);

      if (ausgabeEh2Quantity > 0) {
        const restInAusgabeEh1 = ausgabeEh2Quantity * ausgabeEh2.faktor;
        dict[ausgabeEh2.id] = ausgabeEh2Quantity;
        dict[ausgabeEh1.id] = ausgabeEh1Quantity - restInAusgabeEh1;
      } else {
        dict[ausgabeEh1.id] = ausgabeEh1Quantity;
      }
    } else if (data.inputArteh.kzausgabe === '2') {
      const faktor = ausgabeEh1.faktor / data.inputArteh.faktor;
      const ausgabeEh1Quantity = Math.ceil((data.inputValue ?? 0) / faktor);
      const ausgabeEh2Quantity = Math.floor(data.inputValue ?? 0);

      console.log('before 2 calc: ', dict);

     if (ausgabeEh2Quantity >= 1) {
        const restInAusgabeEh1 = ausgabeEh2Quantity * ausgabeEh2.faktor;
        dict[ausgabeEh2.id] = ausgabeEh2Quantity;

        if ((ausgabeEh1Quantity - restInAusgabeEh1) > 0) {
          dict[ausgabeEh1.id] = (ausgabeEh1Quantity - restInAusgabeEh1) + (dict[ausgabeEh1.id] ?? 0);
        }
      } else {
        dict[ausgabeEh1.id] = ausgabeEh1Quantity;
      }


      console.log('after 2 calc: ', dict);
    } else if (data.inputArteh.kzausgabe === 'N') {
      const faktor = (ausgabeEh1.faktor / data.inputArteh.faktor);
      const ausgabeEh1Quantity = Math.ceil((data.inputValue ?? 0) / faktor);

      const faktor2 = (ausgabeEh2.faktor / ausgabeEh1.faktor);
      const ausgabeEh2Quantity = Math.floor(ausgabeEh1Quantity / faktor2);

      if (ausgabeEh2Quantity > 0) {
        const restInAusgabeEh1 = (ausgabeEh2Quantity * ausgabeEh2.faktor);
        dict[ausgabeEh2.id] = ausgabeEh2Quantity;
        dict[ausgabeEh1.id] = ausgabeEh1Quantity - restInAusgabeEh1;
      } else {
        dict[ausgabeEh1.id] = ausgabeEh1Quantity;
      }
    }

    return dict
  }
}
