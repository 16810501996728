import { importProvidersFrom } from "@angular/core";
import {
  ConnectionHttpService, SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  StoreModule
} from "@softline/core";
import { SOFTLINE_CONFIG_MIGEL_SUPPLIER_CONTEXT_MAX_SELECT_AMOUNT, SOFTLINE_FEATURE_CONTENT } from "./milchgeld.shared";
import { ContentStore } from "./store";
import { SOFTLINE_API_CONTENT } from "./milchgeld.api";

export const milchgeldProviders = [
  importProvidersFrom([
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_CONTENT,
      feature: ContentStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service: ConnectionHttpService) => new SoftlineEntityService(service, SOFTLINE_API_CONTENT),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    }),
  ]),
  {provide: SOFTLINE_CONFIG_MIGEL_SUPPLIER_CONTEXT_MAX_SELECT_AMOUNT, useValue: 30}
]
