import { BehaviorSubject, Observable } from 'rxjs';
import { inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SOFTLINE_FEATURE_INITIAL_STATE, SOFTLINE_FEATURE_NAME } from './store2.shared';
import { Store2Feature } from './store2-feature';

@Injectable()
export abstract class InjectableStore2Feature<T> extends Store2Feature<T> {

  override readonly name= inject<string>(SOFTLINE_FEATURE_NAME);
  override readonly initialState: T = inject<T>(SOFTLINE_FEATURE_INITIAL_STATE, {optional: true}) ?? this.getDefaultState();

  constructor() {
    super();
  }
}
