import { Injectable } from '@angular/core';
import { State } from '../connection.store';
import { LocalStorageService } from '../../data/specialized/storage/local-storage.service';
import { ConnectionSaver } from '../abstraction';
import { lastValueFrom } from 'rxjs';

const CONNECTION_RESOURCE_KEY = 'Connection';

@Injectable()
export class LocalStoreConnectionSaver extends ConnectionSaver {
  constructor(private service: LocalStorageService) {
    super();
  }

  save(connectionState: State): Promise<State> {
    return lastValueFrom(
      this.service.create<State, State>(
        { key: CONNECTION_RESOURCE_KEY },
        connectionState
      )
    );
  }
}
