<h2 class="text-xl font-semibold mt-2 mb-1 px-2">Empfängerliste bearbeiten</h2>

<ul class="mt-1 w-full flex flex-col">
  <li *ngFor="let empfaenger of (empfaenger$ | async); let last = last"
      class="w-full py-2 px-2 flex items-center justify-between border-b border-b-solid"
      (click)="selectOrUnselect(empfaenger.empfaenger)"
      [ngClass]="[empfaenger.selected ? 'bg-slate-200' : 'bg-surface', last ? 'border-transparent' : 'border-gray-100']">
    <div class="py-1">{{ empfaenger?.empfaenger?.name }}</div>

    <div *ngIf="empfaenger?.selected">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-5 h-5 text-primary" *ngIf="empfaenger?.selected">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
    </div>
  </li>
</ul>

<div class="flex items-center justify-evenly w-full mt-3">
  <button class="soft-button secondary h-14 w-full" (click)="close(_initialEmpfaenger)">Abbrechen</button>
  <button class="soft-button primary h-14 w-full" (click)="save()">Speichern</button>
</div>
