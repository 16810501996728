<ng-container *ngIf="settings$ | async as settings">
  <div class="c column center">
    <h1 class="text-sm color-light pointer" (click)="openSettings()">
      {{ '#LOGIN.SETTINGS' | translate }}
    </h1>
    <h2 class="text-lg font-bold">{{ settings.title | translate }}</h2>
  </div>
  <div class="mt-3">
    <ng-container *ngComponentOutlet="settings.component ?? null"></ng-container>
  </div>
  <div class="c column center mt-8 space-y-3">
    <a class="soft-link" (click)="openSettings()">{{
      '#LOGIN.SETTINGS' | translate
    }}</a>
    <a [routerLink]="['/login']" class="soft-link">{{
      '#LOGIN.BACK' | translate
    }}</a>
  </div>
</ng-container>
