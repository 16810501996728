import { Dictionary } from '../types/dictionary';
import { Observable } from 'rxjs';
import { ResourceService } from '../data/abstraction';
import { ConnectionResourceLocation } from '../data/specialized/http/connection.location';
import { SOFTLINE_SERVICE_HTTP } from '../core.shared';
import { Inject } from '@angular/core';
import { RequestEvent } from '../data/request';
import { RemoteStoreObjectService } from '../store/specialized/object/object.service';
import { map } from 'rxjs/operators';

export class SoftlineObjectService extends RemoteStoreObjectService<ConnectionResourceLocation> {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP)
    service: ResourceService<ConnectionResourceLocation>,
    protected path: string
  ) {
    super(service);
  }

  get<T>(
    payload: unknown | undefined,
    pathParams: Dictionary<unknown> | undefined,
    queryParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    const location = { path: this.path, pathParams, queryParams };
    return this.service.get(location, payload);
  }

  create<T, TResponse>(
    resource: T,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<TResponse> {
    const location = { path: this.path, pathParams };
    return this.service.create(location, resource);
  }

  patch<T, TResponse>(
    changes: Partial<T>,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<TResponse> {
    const location = { path: this.path, pathParams };
    return this.service.patch(location, changes);
  }

  update<T, TResponse>(
    resource: T,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<TResponse> {
    const location = { path: this.path, pathParams };
    return this.service.update(location, resource);
  }

  delete<TResponse, TPayload>(
    payload: TPayload | undefined,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<TResponse> {
    const location = { path: this.path, pathParams };
    return this.service.delete(location, payload);
  }

  upload<T, TResponse>(
    resource: T,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<RequestEvent<T>> {
    const location = { path: this.path, pathParams };
    return this.service.upload(location, resource).pipe(
      map((o: RequestEvent<any>) => {
        if (o.type === 'response')
          return {
            ...o,
            response: o.response ? { ...o.response, id: o.response.id } : null,
          };
        return o;
      })
    );
  }
}
