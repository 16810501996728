import { Entity } from './entity';
import { isDefined } from "../../../../functions/is-defined.function";

export interface Validation {
  isValid: boolean;
  messages: ValidationMessage[];
}

export type ValidationType = 'error' | 'warning' | 'success' | 'info';

export interface ValidationMessage {
  subject: string;
  message: string;
  type: ValidationType;
  data?: object;
}

export interface GroupValidation extends Validation{
  properties: string[];
}

export interface ValidationProperty<T extends Entity = Entity>
  extends Validation {
  propertyName: keyof T;
}

export interface ValidationResult<T extends Entity = Entity>
  extends Validation {
  properties: ValidationProperty<T>[];
  entity: T;
}

export function isValidation(value: unknown): value is Validation {
  return typeof value === 'object'
      && value !== null
      && isDefined(value['isValid'])
      && isDefined(value['messages'])
}

export function isGroupValidation(value: unknown): value is GroupValidation {
  return typeof value === 'object'
    && value !== null
    && isDefined(value['isValid'])
    && isDefined(value['messages'])
    && isDefined(value['properties'])
}
