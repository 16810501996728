export class NestedError extends Error {
  constructor(message?: string, public inner?: Error) {
    super(message);
  }
}

export class ConnectionError extends NestedError {
  constructor(message?: string, inner?: Error) {
    super(message, inner);
  }
}

export class NetworkError extends NestedError {
  constructor(message?: string, inner?: Error) {
    super(message, inner);
  }
}

export class RequestError extends NestedError {
  constructor(message?: string, public text?: string, public code?: string, inner?: Error) {
    super(message, inner);
  }
}

export class UnauthorizedError extends NestedError {
  constructor(message?: string, inner?: Error) {
    super(message, inner);
  }
}

export class ForbiddenError extends NestedError {
  constructor(message?: string, public text?: string, public code?: string, inner?: Error) {
    super(message, inner);
  }
}

export class NotFoundError extends NestedError {
  constructor(message?: string, inner?: Error) {
    super(message, inner);
  }
}

export class ServerError extends NestedError {
  constructor(message?: string, public text?: string, public code?: string, inner?: Error) {
    super(message, inner);
  }
}

export class UnknownResponseError extends NestedError {
  constructor(message?: string, public text?: string, public code?: string, inner?: Error) {
    super(message, inner);
  }
}

export class UnknownError extends NestedError {
  constructor(message?: string, inner?: Error) {
    super(message, inner);
  }
}

export class CancelledError extends Error {}
