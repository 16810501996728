<div class="flex flex-col gap-2">
  <div *ngFor="let scan of history()"
       class="p-2 cursor-pointer hover:bg-hover"
       (click)="scanSelect.emit(scan)">
    <div class="flex flex-row justify-between">
      <span class="font-semibold">{{scan.data}}</span>
      <span class="text-xs">{{scan.timestamp | format:'DATE_TIME.SECONDS'}}</span>
    </div>
    <span class="text-xs text-light">{{scan.labelType | uppercase}}</span>
  </div>
  <div *ngIf="history().length === 0"
       class="flex flex-row justify-center text-light text-sm p-4">
    Es sind keine vorherigen Scans vorhanden!
  </div>
</div>

