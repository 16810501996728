import { Inject, Injectable } from '@angular/core';
import { Dictionary, Store } from "@softline/core";
import {
  ConnectionHttpService,
  LocalStorageService,
  ResponseResult,
  SessionStorageService,
} from '@softline/core';
import { lastValueFrom, of, startWith } from 'rxjs';
import {
  catchError,
  filter,
  map,
  onErrorResumeNext,
  take,
} from 'rxjs/operators';
import { SOFTLINE_API_AUTHENTICATION, SOFTLINE_API_SSO } from "../jwt-authentication.api";
import {
  SOFTLINE_CONFIG_JWT_EXPIRATION_TIME,
  SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME,
} from '../jwt-authentication.shared';

@Injectable({
  providedIn: 'root',
})
export class JwtAuthenticationService {
  constructor(
    private store: Store,
    private httpService: ConnectionHttpService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    @Inject(SOFTLINE_CONFIG_JWT_EXPIRATION_TIME) private expirationTime: number
  ) {}

  authenticate(
    username: string,
    password: string,
    stayAuthenticated: boolean,
    claims?: Dictionary<unknown>
  ): Promise<{ token: string, language: string }> {
    let expires: undefined | number;
    if (!stayAuthenticated) expires = this.expirationTime;
    return lastValueFrom(
      this.httpService
        .create<object, { result: string, language: string }>(
          { path: SOFTLINE_API_AUTHENTICATION },
          { username, password, expires, claims }
        )
        .pipe(map((o) => ({token: o?.result ?? '', language: o.language})))
    );
  }

  authenticateViaToken(
    token: string,
    type: string
  ): Promise<string> {
    return lastValueFrom(
      this.httpService
        .create<object, ResponseResult<string>>(
          { path: SOFTLINE_API_SSO },
          { token, type }
        )
        .pipe(map((o) => o?.result ?? ''))
    );
  }

  change(body: object): Promise<string> {
    return lastValueFrom(
      this.httpService
        .patch<object, ResponseResult<string>>(
          { path: SOFTLINE_API_AUTHENTICATION },
          body
        )
        .pipe(map((o) => o?.result ?? ''))
    );
  }

  refresh(): Promise<string> {
    return lastValueFrom(
      this.httpService
        .patch<object, ResponseResult<string>>(
          { path: SOFTLINE_API_AUTHENTICATION },
          { expires: this.expirationTime }
        )
        .pipe(map((o) => o.result))
    );
  }

  load(): Promise<string | null> {
    return lastValueFrom(
      this.localStorageService
        .get<string>({ key: SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME })
        .pipe(
          startWith(null),
          onErrorResumeNext(
            this.sessionStorageService.get<string>({
              key: SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME,
            })
          ),
          catchError((e) => of(null))
        )
    );
  }

  async save(token: string, stayAuthenticated: boolean): Promise<void> {
    if (stayAuthenticated)
      await this.localStorageService.update(
        { key: SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME },
        token
      );
    else
      await this.sessionStorageService.update(
        { key: SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME },
        token
      );
  }

  async update(token: string): Promise<void> {
    let useLocalStorage = false;
    this.localStorageService
      .get({ key: SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME })
      .pipe(
        take(1),
        catchError((o) => of(undefined))
      )
      .subscribe((o) => (useLocalStorage = !!o));
    if (useLocalStorage)
      await this.localStorageService.update(
        { key: SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME },
        token
      );
    else
      await this.sessionStorageService.update(
        { key: SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME },
        token
      );
  }

  async deleteToken(): Promise<void> {
    await this.localStorageService.delete({
      key: SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME,
    });
    await this.sessionStorageService.delete({
      key: SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME,
    });
  }
}
