import { Inject, Injectable } from "@angular/core";
import { ConnectionHttpService, SOFTLINE_SERVICE_HTTP } from "@softline/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SOFTLINE_API_ITEM_DETAIL } from "../artikel.api";
import { ArtikelDetail } from "../types/artikel-detail";

export interface LoadDetailParameters {
  ids: number[]
}

@Injectable()
export class ArtikelDetailService {
  constructor(@Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService) { }

  load(params: LoadDetailParameters): Observable<ArtikelDetail[]> {
    return this.service
      .create<LoadDetailParameters, any[]>({ path: SOFTLINE_API_ITEM_DETAIL }, params)
      .pipe(map((o) =>
        o.map(p => ({
          ...p,
          id: p.itemId,
          tieredPricelist: Object.values(p?.tieredPricelist ?? {}) ?? [],
          discountTieredPricelist: Object.values(p?.discountTieredPricelist ?? {}) ?? [],
        }))
      ));
  }
}
