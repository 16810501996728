import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { Observable } from "rxjs";
import { Artikel, ArtikelStore, SOFTLINE_FEATURE_WWS_ARTIKELSUCHE } from "@softapps/wws/core";
import { select, Store } from "@softline/core";
import { UiCoreModule } from "@softline/ui-core";

@Component({
  selector: 'soft-artikel-suchergebnis',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './artikel-suchergebnis.component.html',
  styleUrls: ['./artikel-suchergebnis.component.scss']
})
export class ArtikelSuchergebnisComponent implements OnInit {

  @Input() searchInput?: string | null;
  @Input() loading?: boolean | null;
  @Input() items: { item: Artikel, favorite: boolean }[] = [];

  @Output() selectArtikel = new EventEmitter<Artikel>()
  @Output() removeFromFavorites = new EventEmitter<Artikel>()
  @Output() addToFavorites = new EventEmitter<Artikel>()

  constructor() { }

  ngOnInit(): void {
  }

  trackByFn(index: number, item: { item: Artikel, favorite: boolean }): number {
    return item.item.id;
  }
}
