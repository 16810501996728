import {
  ActionStore,
  CollectionStore,
  createAction,
  createGetter,
  createMutation,
  Dictionary,
  KeyStore,
  mutate,
  on,
  ParamsStore,
  select,
  SOFTLINE_SERVICE_UUID,
  SubscriptionStore
} from '@softline/core';
import {lastValueFrom} from 'rxjs';
import {ItemPriceStore} from './item-price.store';
import {ItemStockStore} from './item-stock.store';
import {ItemService, LoadManyItemsResult} from '../services/item.service';
import {
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICE,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_STOCK
} from '../artikel-info.shared';
import { Item } from '../types/item';
import { ItemQuery } from '../types/item-query';

export interface LoadActionParameters {
  id: number;
  pathParams?: Dictionary<unknown>;
  token?: string;
  vkforgRestriction?: boolean
}

export interface LoadManyActionsParameters {
  query: ItemQuery;
  offset?: number;
  limit?: number;
  sort?: string;
  clear?: boolean;
  token?: string;
}

export interface State extends CollectionStore.State<Item>, KeyStore.State, ParamsStore.State, SubscriptionStore.State, ActionStore.State {
  contentUrl?: string;
  totalResultCount?: number;
  pageSize?: number;
}

const collectionStore = CollectionStore.create<Item>();

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ItemStore {

  export const mutations = {
    ...collectionStore.mutations,
    action: ActionStore.mutations,
    subscription: SubscriptionStore.mutations,
    setAdditionalInfo: createMutation<State, { totalResultCount: number }>('item set additional info'),
    setPageSize: createMutation<State, { pageSize: number }>('item set page size'),
  };

  export const actions = {
    ...SubscriptionStore.actions,
    load: createAction<State, LoadActionParameters, Item>('item load'),
    loadMany: createAction<State, LoadManyActionsParameters, LoadManyItemsResult>('item load many'),
    reload: createAction<State, Partial<LoadManyActionsParameters>, LoadManyItemsResult>('item/reload'),
  };

  export const getters = {
    ...collectionStore.getters,
    action: ActionStore.getters,
    subscription: SubscriptionStore.getters,
    loading: createGetter<State, boolean>('item loading'),
    loaded: createGetter<State, boolean>('item loaded'),
    pageSize: createGetter<State, number>('page size'),
    totalResultCount: createGetter<State, number | undefined>('item number of items'),
  };

  export const feature = {
    initialState: {
      ...collectionStore.feature.initialState,
      ...SubscriptionStore.feature.initialState,
      ...ActionStore.feature.initialState,
      ...KeyStore.feature.initialState,
      ...ParamsStore.feature.initialState,
      pageSize: 20,
    },
    mutations: [
      ...collectionStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
      ...ActionStore.feature.mutations,
      ...KeyStore.feature.mutations,
      ...ParamsStore.feature.mutations,
      mutate(mutations.setAdditionalInfo, ({state, params}) => {
        return {
          ...state,
          totalResultCount: params.totalResultCount
        };
      }),
      mutate(mutations.setPageSize, ({state, params: { pageSize }}) => {
        return {
          ...state,
          pageSize
        };
      }),
    ],
    actions: [
      ...collectionStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      ...ActionStore.feature.actions,
      ...KeyStore.feature.actions,
      ...ParamsStore.feature.actions,
      on(actions.load, async ({commit, featureName, params, dispatch, injector}) => {
        const service = injector.get(ItemService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const subscription$ = SubscriptionStore.handleSubscriptionState(service.load(params.id), featureName, commit, token);
        const result = await lastValueFrom(ActionStore.handleObservableActionState(subscription$, featureName, commit, actions.load.name, token));
        commit(featureName, mutations.addOrUpdate, result);

        dispatch(SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICE, ItemPriceStore.actions.loadManySequential, {ids: [result.id]});
        dispatch(SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_STOCK, ItemStockStore.actions.loadManySequential, {ids: [result.id], vkforgRestriction: params?.vkforgRestriction});
        return result;
      }),
      on(actions.loadMany, async ({featureName, injector, params, dispatch, commit, get}) => {
        const service = injector.get(ItemService);
        const token = params?.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const limit = params.limit ?? get(featureName, getters.pageSize) ?? 10

        const $sub = SubscriptionStore.handleSubscriptionState(
          service.loadMany(params.query, limit, params.offset ?? 0, params.sort),
          featureName,
          commit,
          token
        );

        const result = await lastValueFrom(
          ActionStore.handleObservableActionState($sub, featureName, commit, actions.loadMany.name, token)
        );

        if (params.clear)
          commit(featureName, mutations.clear);

        commit(featureName, mutations.addOrUpdateMany, result.result);
        commit(featureName, mutations.setAdditionalInfo, {totalResultCount: result._totalResultCount});

        if(result.result.length > 0) {
          dispatch(SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICE, ItemPriceStore.actions.loadManySequential, {ids: result.result.map(o => o.id)});
          dispatch(SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_STOCK, ItemStockStore.actions.loadManySequential, {ids: result.result.map(o => o.id)});
        }

        return result;
      }),
      on(actions.reload, async ({ featureName, dispatch, params }) => {
        const loadManyParams: LoadManyActionsParameters = {
          query: params?.query ?? {},
          clear: true,
          offset: params?.offset ?? 0,
          sort: params?.sort,
        };

        return await dispatch(featureName, actions.loadMany, loadManyParams);
      })
    ],
    getters: [
      ...collectionStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      ...ActionStore.feature.getters,
      ...KeyStore.feature.getters,
      ...ParamsStore.feature.getters,
      select(getters.totalResultCount, ({state}) => state.totalResultCount),
      select(getters.pageSize, ({state}) => state.pageSize ?? 20),
    ],
  };
}
