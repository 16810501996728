import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UiCoreModule } from '@softline/ui-core';
import { SOFTLINE_CONFIG_SCANNER } from "../../scanner.shared";
import { ScannerService } from '../../services/scanner.service';
import { ReactiveFormsModule } from "@angular/forms";
import { Scanner } from '../../data/scanner';
import { ExternalScannerService } from "./services/external-scanner.service";

export function externalScannerFactory(service: ScannerService): Scanner {
  return {
    name: 'external',
    title: 'Hardware (Bluetooth/USB)',
    priority: 5,
    service,
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
    exports: [ ],
})
export class ExternalScannerModule {
  static forRoot(): ModuleWithProviders<ExternalScannerModule> {
    return {
      ngModule: ExternalScannerModule,
      providers: [
        ExternalScannerService,
        {
          provide: SOFTLINE_CONFIG_SCANNER,
          multi: true,
          useFactory: externalScannerFactory,
          deps: [ExternalScannerService],
        }
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class ExternalScannerRootModule {}
