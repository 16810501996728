import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter, HostListener,
  Input,
  OnInit,
  Output
} from "@angular/core";

@Component({
  selector: 'soft-validation-icon',
  standalone: true,
  templateUrl: './validation-icon.component.html',
  styleUrls: ['./validation-icon.component.scss']
})
export class ValidationIconComponent {
  @Input() type: 'error' |'warning' | 'success' | 'info' | null = null;
}
