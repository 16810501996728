import {Umsatzstatistik} from '../types/umsatzstatistik';
import {GroupedBarChartEntry} from '../types/grouped-bar-chart-data';
import {UmsatzstatistikRemoteConfig} from '../types/remote-config';
import {sortByRemoteConfig} from './sort-by-remote-config';

export function toChartData(umsatzstatistik: Umsatzstatistik['ebeneresult'], remoteConfig: UmsatzstatistikRemoteConfig): GroupedBarChartEntry[] {
  const data: GroupedBarChartEntry[] = []

  if (!umsatzstatistik)
    return []

  for (const [name, values] of Object.entries(umsatzstatistik)) {
    if (name === 'Alle')
      continue;

    data.push({
      name: values?.bezeichnung ?? name,
      series: [
        {
          name: 'Umsatz',
          value: values.arten?.['Summe']?.values?.['netto'] <= 0 ? 1 : values.arten?.['Summe']?.values?.['netto'] ?? 0,
          extra: {
            key: name,
            dbproz: values.arten?.['Summe']?.values?.['dbproz'] ?? 0
          }
        },
        {
          name: 'Rohertrag',
          value: values.arten?.['Summe']?.values?.['db'] <= 0 ? 1 : values.arten?.['Summe']?.values?.['db'] ?? 0,
          extra: {
            key: name,
            dbproz: values.arten?.['Summe']?.values?.['dbproz'] ?? 0
          }
        },
      ],
    });
  }

  return sortByRemoteConfig(data, remoteConfig.sort);
}
