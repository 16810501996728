import {ChangeDetectionStrategy, Component, computed, inject, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCoreModule, WithModal} from '@softline/ui-core';
import {OffenerPosten} from '../../types/offener-posten';
import {DownloadStore, saveAs, SOFTLINE_FEATURE_DOWNLOAD, Store} from '@softline/core';
import {SOFTLINE_API_JOPOSTEN_BELEG_DOWNLOAD} from '../../offene-posten.api';
import {handleRequestErrors} from '@softline/application';

@Component({
  selector: 'soft-offene-posten-table-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './offene-posten-table-dialog.component.html',
  styleUrl: './offene-posten-table-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffenePostenTableDialogComponent extends WithModal() {

  private readonly store = inject(Store);
  private readonly _offenePosten = signal<OffenerPosten[]>([]);

  from?: Date;
  to?: Date;

  showExtraColumn = false;

  set offenePosten(value: OffenerPosten[]) {
    this._offenePosten.set(value);
  }

  readonly currentPage = signal(1);
  readonly pageSize = signal(50);

  readonly elemtentCount = computed(() =>
    this._offenePosten().length
  );

  readonly pageCount = computed(() => {
    return Math.ceil(this._offenePosten().length / this.pageSize())
  });

  readonly pages = computed(() => {
    const pages: number[] = [];

    for (let i = 0; i < this.pageCount(); i++) {
      pages.push(i + 1);
    }

    if (pages.length < 1)
      pages.push(1);

    return pages;
  })

  readonly visibleOffenePosten = computed(() => {
    const start = (this.currentPage() - 1) * this.pageSize();
    const end = this.currentPage() * this.pageSize()
    return this._offenePosten().slice(start, end);
  });

  constructor() {
    super();
  }

  async downloadFile(op: OffenerPosten): Promise<void> {
    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_DOWNLOAD,
        DownloadStore.actions.download,
        {
          filename: `OP_${op.symbol.symbol}_${op.belegnummer}.pdf`,
          location: {
            path: SOFTLINE_API_JOPOSTEN_BELEG_DOWNLOAD,
            pathParams: {
              idjoposten: op.id
            }
          }
        }
      );

      saveAs(result.content, result.filename);
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }
}
