import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal, UiCoreModule} from '@softline/ui-core';
import {FavoriteList} from '../../types/favorite-list';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS} from '../../artikel-info.shared';
import {FavoriteListsStore} from '../../store/favorite-lists.store';
import {BehaviorSubject, combineLatestWith} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'soft-add-favorite-list-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './add-favorite-list-dialog.component.html',
  styleUrls: ['./add-favorite-list-dialog.component.scss'],
})
export class AddFavoriteListDialogComponent implements  Modal<FavoriteList | null | undefined> {

  readonly listName$ = new BehaviorSubject<string>('');
  close!: (result: (FavoriteList | null | undefined)) => void;

  readonly validListName$ = this.listName$.pipe(
    combineLatestWith(
      this.store.observe(
        SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS,
        FavoriteListsStore.getters.all
      )
    ),
    map(([input, lists]) => {
      if (!input || input?.length < 1)
        return false;

      return lists.every(o => o.name !== input);
    })
  )

  constructor(private store: Store) {}

  registerCloseHandler(handler: (result: (FavoriteList | null | undefined)) => void) {
    this.close = handler;
  }

  async createList(): Promise<FavoriteList | undefined> {
    if (this.listName$.value?.length < 1) {
      return undefined;
    }

    const entity = { name: this.listName$.value } as FavoriteList;

    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS,
        FavoriteListsStore.actions.create,
        { entity }
      );

      this.close(result);
      return result;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
