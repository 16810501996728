@if (template().title || template().timestamp) {
<div class="w-full flex flex-row justify-between mb-3">
  <h3 class="soft-overline">{{ template().title }}</h3>
  <span class="text-light text-xs">
    {{ template().timestamp | format : 'DATE_TIME.SECONDS' }}
  </span>
</div>
}
<div class="w-full">
  <soft-dynamic-template-field-list
    [value]="template().value ?? {}"
    [definition]="$any(definition())"
  >
  </soft-dynamic-template-field-list>
</div>
