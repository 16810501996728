import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'atIndex',
})
export class AtIndexPipe<T> implements PipeTransform {
  transform(
    array: readonly T[] | null | undefined,
    index: number
  ): T | undefined {
    if (!array || array.length < index + 1) return undefined;
    return array[index];
  }
}
