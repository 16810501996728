import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard } from '@softline/auth';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  StoreModule,
} from '@softline/core';
import {
  SOFTLINE_FEATURE_HAFTRUECKLASS,
  SOFTLINE_PERMISSIONS_HAFTRUECKLASS,
} from './haftruecklass.shared';
import { HaftruecklassStore } from './store';
import { SOFTLINE_API_HAFTRUECKLAESSE } from './haftruecklass.api';

export const haftruecklassRoutes: Routes = [
  {
    path: 'haftruecklass',
    children: [
      {
        path: 'result',
        loadComponent: () =>
          import(
            './pages/haftruecklass-list/haftruecklass-list.component'
          ).then((o) => o.HaftruecklassListComponent),
      },
      {
        path: '',
        loadComponent: () =>
          import(
            './pages/haftruecklass-query/haftruecklass-query.component'
          ).then((o) => o.HaftruecklassQueryComponent),
        pathMatch: 'full',
      },
      { path: '**', redirectTo: '' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSIONS_HAFTRUECKLASS },
    providers: [
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_HAFTRUECKLASS,
          feature: HaftruecklassStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineEntityService(service, SOFTLINE_API_HAFTRUECKLAESSE),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
          ],
        })
      ),
    ],
  },
];
