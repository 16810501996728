import {
  CreateResourceService,
  DeleteResourceService,
  DownloadResourceService,
  GetResourceService,
  PatchResourceService,
  ResourceService,
  UpdateResourceService,
  UploadResourceService,
} from '../abstraction';
import { Store } from '../../store/store';
import { Observable } from 'rxjs';
import { RequestEvent } from '../request';
import { SOFTLINE_FEATURE_TRANSACTION } from '../resource.shared';
import * as TransactionStore from '../stores/transaction.store';
import { tap } from 'rxjs/operators';
import { ObservedTransaction, ReportingTransaction } from '../transaction';
import { CancelledError } from '../../types/errors';

export class TransactionResourceService<TLocation>
  implements
    GetResourceService<TLocation>,
    DownloadResourceService<TLocation>,
    CreateResourceService<TLocation>,
    UpdateResourceService<TLocation>,
    DeleteResourceService<TLocation>,
    PatchResourceService<TLocation>,
    UploadResourceService<TLocation>
{
  constructor(
    protected store: Store,
    protected uuid: () => string,
    protected service: ResourceService<TLocation>
  ) {}

  get<T, TPayload = undefined>(
    location: TLocation,
    payload?: TPayload
  ): Observable<T> {
    return this.handleObservedTransaction(
      location,
      payload,
      this.service.get(location, payload)
    );
  }

  create<T, TResponse>(
    location: TLocation,
    resource: T
  ): Observable<TResponse> {
    return this.handleObservedTransaction(
      location,
      resource,
      this.service.create(location, resource)
    );
  }

  update<T, TResponse>(
    location: TLocation,
    resource: T
  ): Observable<TResponse> {
    return this.handleObservedTransaction(
      location,
      resource,
      this.service.update(location, resource)
    );
  }

  patch<T, TResponse>(
    location: TLocation,
    changes: Partial<T>
  ): Observable<TResponse> {
    return this.handleObservedTransaction(
      location,
      changes,
      this.service.patch(location, changes)
    );
  }

  delete<TResponse, TPayload = undefined>(
    location: TLocation,
    payload?: TPayload
  ): Observable<TResponse> {
    return this.handleObservedTransaction(
      location,
      payload,
      this.service.delete(location, payload)
    );
  }

  download<T>(
    location: TLocation,
    payload?: unknown
  ): Observable<RequestEvent<Blob>> {
    return this.handleReportingTransaction(
      location,
      payload,
      this.service.download(location, payload)
    );
  }

  upload<T, TResponse>(
    location: TLocation,
    resource: T
  ): Observable<RequestEvent<TResponse>> {
    return this.handleReportingTransaction(
      location,
      resource,
      this.service.upload(location, resource)
    );
  }

  protected handleObservedTransaction<T, TReturn>(
    location: TLocation,
    params: any,
    observable: Observable<T>
  ): Observable<T> {
    const id = this.uuid();
    const transaction: ObservedTransaction<T, TLocation> = {
      id,
      type: 'observed',
      state: 'pending',
      location,
      params,
      subscription: null,
    };
    this.store.commit(
      SOFTLINE_FEATURE_TRANSACTION,
      TransactionStore.mutations.add,
      transaction
    );

    return new Observable((observer) => {
      this.store.commit(
        SOFTLINE_FEATURE_TRANSACTION,
        TransactionStore.mutations.patch,
        { id, state: 'processing', subscription: observer }
      );
      const subscription = observable
        .pipe(
          tap(
            (value) => observer.next(value),
            (error) => {
              this.store.commit(
                SOFTLINE_FEATURE_TRANSACTION,
                TransactionStore.mutations.patch,
                { id, state: 'failed', subscription: null }
              );
              observer.error(error);
            },
            () => {
              this.store.commit(
                SOFTLINE_FEATURE_TRANSACTION,
                TransactionStore.mutations.patch,
                { id, state: 'succeeded', subscription: null }
              );
              observer.complete();
            }
          )
        )
        .subscribe();
      subscription.add(() => {
        this.store.commit(
          SOFTLINE_FEATURE_TRANSACTION,
          TransactionStore.mutations.patch,
          { id, state: 'cancelled', subscription: null }
        );
        observer.error(
          new CancelledError('TransactionService: transaction cancelled')
        );
      });
      return subscription;
    });
  }

  protected handleReportingTransaction<T, TReturn>(
    location: TLocation,
    params: any,
    observable: Observable<RequestEvent<T>>
  ): Observable<RequestEvent<T>> {
    const id = this.uuid();
    const transaction: ReportingTransaction<T, TLocation> = {
      id,
      type: 'reporting',
      state: 'pending',
      location,
      params,
      sent: false,
      subscription: null,
    };
    this.store.commit(
      SOFTLINE_FEATURE_TRANSACTION,
      TransactionStore.mutations.add,
      transaction
    );

    return new Observable((observer) => {
      this.store.commit(
        SOFTLINE_FEATURE_TRANSACTION,
        TransactionStore.mutations.patch,
        { id, state: 'processing', subscription: observer }
      );
      const subscription = observable
        .pipe(
          tap(
            (value) => {
              switch (value.type) {
                case 'sent':
                  this.store.commit(
                    SOFTLINE_FEATURE_TRANSACTION,
                    TransactionStore.mutations.patch,
                    { ...transaction, sent: true }
                  );
                  break;
                case 'progress':
                  this.store.commit(
                    SOFTLINE_FEATURE_TRANSACTION,
                    TransactionStore.mutations.reportProgress,
                    { id, progress: value }
                  );
                  break;
                case 'response':
                  this.store.commit(
                    SOFTLINE_FEATURE_TRANSACTION,
                    TransactionStore.mutations.patch,
                    { ...transaction, state: 'succeeded' }
                  );
                  break;
                default:
                  break;
              }
              observer.next(value);
            },
            (error) => {
              this.store.commit(
                SOFTLINE_FEATURE_TRANSACTION,
                TransactionStore.mutations.patch,
                { id, state: 'failed', subscription: null }
              );
              observer.error(error);
            },
            () => {
              this.store.commit(
                SOFTLINE_FEATURE_TRANSACTION,
                TransactionStore.mutations.patch,
                { id, state: 'succeeded', subscription: null }
              );
              observer.complete();
            }
          )
        )
        .subscribe();
      subscription.add(() => {
        this.store.commit(
          SOFTLINE_FEATURE_TRANSACTION,
          TransactionStore.mutations.patch,
          { id, state: 'cancelled', subscription: null }
        );
        observer.error(
          new CancelledError('TransactionService: transaction cancelled')
        );
      });
      return subscription;
    });
  }
}
