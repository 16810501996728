import { Observable } from 'rxjs';

export interface BackNavigable {
  canNavigateBack$?: Observable<boolean>;
  navigateBack(): Promise<void>;
}

export function isBackNavigable(program: any): program is BackNavigable {
  return (program as BackNavigable)?.navigateBack !== undefined;
}
