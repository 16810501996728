import {
  QueryStore,
  RemoteCollectionStore,
  StoreBuilder,
  SyncedRemoteCollectionStore,
} from '@softline/core';
import { Haftruecklass } from '../types/haftruecklass';

export const HaftruecklassStore = new StoreBuilder(
  SyncedRemoteCollectionStore.create<Haftruecklass>()
).add(QueryStore.create<any>()).value;
