import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN} from '../../../artikel.shared';
import {ArtikelFavoritenStore} from '../../../store/artikel-favoriten.store';
import {ArtikelFavorit} from '../../../types/artikel-favorit';
import {Artikel} from '../../../types/artikel';
import {Observable} from 'rxjs';


@Component({
  selector: 'soft-artikel-favoriten-liste',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './artikel-favoriten-liste.component.html',
  styleUrls: ['./artikel-favoriten-liste.component.scss']
})
export class ArtikelFavoritenListeComponent implements OnInit {

  readonly favorites$: Observable<ArtikelFavorit[]> = this.store.observe(
    SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN,
    ArtikelFavoritenStore.getters.all
  );

  @Output() readonly selectArtikel = new EventEmitter<Artikel>()

  constructor(private store: Store) { }

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN,
      ArtikelFavoritenStore.actions.loadMany,
    );
  }

  trackByFn(index: number, favorite: ArtikelFavorit): number {
    return favorite.id;
  }
}
