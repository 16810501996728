import {
  MultiSelectStore,
  RemoteCollectionStore, RemoteQueryStore,
  StoreBuilder
} from "@softline/core";
import { Geschaeftsfall } from '../types/geschaeftsfall.model';

export const GeschaeftsfallStore = new StoreBuilder(
  RemoteCollectionStore.create<Geschaeftsfall>()
)
  .add(RemoteQueryStore.create<object>())
  .add(MultiSelectStore.create<Geschaeftsfall>()).value;
