import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { VkforgListView } from "./list-view/vkforg.list-view";

export const vkforgFieldOk: FieldOkConfig = {
  name: 'vkforg',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{vkforg}} - {{kurzbezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: VkforgListView
    }
  }
  ]
};
export const vkforgMultiselectFieldOk: FieldOkConfig = {
  name: 'vkforg',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{vkforg}} - {{kurzbezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: VkforgListView
    }
  }
  ]
};

export const vkforgQueryFieldOk: FieldOkConfig = {
  name: 'vkforg',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{vkforg}} - {{kurzbezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: VkforgListView
    }
  }
  ]
};
