import { Inject, Injectable } from "@angular/core";
import { Filter, FilterRegistration, SOFTLINE_REGISTRATION_FILTER } from "../types/filter";
import { FilterProvider } from "./filter.provider";

@Injectable()
export class FilterService {
  constructor(private provider: FilterProvider) {
  }

  filter<T>(values: T[], filter: Filter | null | undefined): T[] {
    if(!filter)
      return values;
    return values.filter(o => this.predicate(o, filter));
  }

  predicate<T>(value: T, filter: Filter): boolean {
    const predicate = this.provider.getFilter(filter.operator);
    return predicate(value, filter, (o) => this.provider.getFilter(o));
  }
}
