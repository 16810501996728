<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
  <h3 class="text-xl font-bold">
    {{ '#SUBKUNDEN.DIALOGS.EDIT.HEADER' | translate }}
  </h3>
  <div
    class="flex justify-between items-center border border-gray-200 mt-2 p-2 text-sm"
  >
    <label for="createName" class="soft-label font-bold required">{{
      '#SUBKUNDEN.DIALOGS.EDIT.NAME' | translate
    }}</label>
    <soft-input
      id="createName"
      formControlName="name"
      class="ml-4 max-w-xs lg:max-w-lg"
    ></soft-input>
  </div>
  <div
    class="flex justify-between items-center border border-gray-200 mt-2 p-2 text-sm"
  >
    <label for="createActive" class="soft-label font-bold required">{{
      '#SUBKUNDEN.DIALOGS.EDIT.ACTIVE' | translate
    }}</label>
    <soft-toggle-switch
      id="createActive"
      formControlName="aktiv"
    ></soft-toggle-switch>
  </div>
  <button type="submit" class="soft-button primary mt-4 w-full h-16">
    {{ '#SUBKUNDEN.DIALOGS.EDIT.SUBMIT' | translate }}
  </button>
</form>
