import { Observable } from 'rxjs';
import { Entity } from '../types/entity';
import { Change, LazySaveResult } from '../types/change';
import { Dictionary } from '../../../../types/dictionary';
import { EntityLocationParameters } from '../data/specialized/entity.location';
import { CreateResourceService } from '../../../../data/abstraction';

export class BulkService<T extends Entity, TLocation> {
  constructor(
    protected service: CreateResourceService<TLocation>,
    protected createLocation: (params: EntityLocationParameters) => TLocation
  ) {}

  save(
    changes: Change<T>,
    pathParams?: Dictionary<unknown>
  ): Observable<LazySaveResult<T>> {
    /*const path = this.createLocation({id: entity.id, pathParams});
    return this.service.create(path, changes);*/
    throw new Error('Not implemented yet');
  }
}
