import {Injectable} from '@angular/core';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN} from '../statusabfrage.shared';
import {PmsAbteilungStore} from '../store/abteilung.store';
import {AbteilungUebersicht} from '../types/abteilung';

@Injectable()
export class AbteilungenService {
  constructor(
    private readonly store: Store,
  ) {}

  async loadAbteilungen(stichdatum: string | null): Promise<void> {
    const abteilungen = await this.store.dispatch(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
      PmsAbteilungStore.actions.loadMany,
      { pathParams: { stichdatum } }
    );

    let currentChunk: AbteilungUebersicht[] = []

    for (const abteilung of abteilungen) {
      currentChunk.push(abteilung);

      if (currentChunk.length < 5)
        continue;

      await Promise.all(currentChunk.map(c => this.loadAbteilung(c.id, stichdatum)));
      currentChunk = [];
    }

    if (currentChunk.length > 0)
      await Promise.all(currentChunk.map(c => this.loadAbteilung(c.id, stichdatum)));
  }

  private async loadAbteilung(id: number, stichdatum: string | null): Promise<AbteilungUebersicht> {
    return this.store.dispatch(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
      PmsAbteilungStore.actions.load,
      {
        id,
        pathParams: {
          stichdatum
        }
      }
    );
  }
}
