import { equals } from "../../functions/equals.function";
import { SelectStrategy } from "./select.strategy";
import { Injectable } from "@angular/core";

export class SingleSelectStrategy<T> implements SelectStrategy<T | null> {
  select(value: T | null, current?:  T | null): T | null {
    return value;
  }

  unselect(value: T | null, current?:  T | null): T | null {
    if(equals(current, value))
      return null;
    return current ?? null;
  }
}
