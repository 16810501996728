export interface MessageBarConfig {
  id?: string;
  title?: string;
  message?: string;
  params?: object;
  icon?: string;
  class?: string;
  container?: string;
  group?: string;
  actions?: MessageBarAction[];
  duration?: number;
  dismiss?: {
    canDismiss: boolean;
  };
  onDismiss?(): void;
}

export interface MessageBarFactoryParams {
  message?: string;
  params?: object;

  title?: string;
  container?: string;
  group?: string;
  actions?: MessageBarAction[];
  duration?: number;
  onDismiss?(): void;
}

export interface LoadingMessageBarConfig {
  message?: string;
  params?: object;
  onDismiss?(): void;
}

export interface ProgressMessageBarConfig {
  title?: string;
  message?: string;
  params?: object;
  progress?: number;
  onDismiss?(): void;
}

export interface MessageBarAction {
  title: string;
  onExecute?: () => void;
  type: 'link' | 'button';
}
