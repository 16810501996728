import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {Observable} from 'rxjs';
import {SOFTLINE_API_WWS_UMSATZABFRAGE} from '../umsatzstatistik.api';
import {Umsatzstatistik} from '../types/umsatzstatistik';

@Injectable()
export class UmsatzstatistikService {

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private readonly httpService: ConnectionHttpService
  ) {}

  loadUmsatzstatistik(body: Record<string, unknown>): Observable<Umsatzstatistik> {
    return this.httpService.create(
      { path: SOFTLINE_API_WWS_UMSATZABFRAGE },
      body
    );
  }
}
