import { inject, Injectable } from '@angular/core';
import { Sort, SortCollectionStore, SortService, Store } from '@softline/core';

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithSort = <T extends Constructor<{}>>(featureName: string, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class SortMixin extends Base {

    store = inject(Store);
    sortService = inject(SortService);

    sort = this.store.signal(
      featureName,
      SortCollectionStore.getters.sort
    )

    constructor(...args: any[]) {
      super(...args);
    }

    async setSort(sort: Sort | null): Promise<void> {
      this.store.commit(featureName,
        SortCollectionStore.mutations.setSort,
        sort
      )
    }
  }
  return SortMixin;
}
