<h4 class="text-xl font-semibold mt-3 ml-3">
  {{ title | translate | interpolate: params }}
</h4>

<p *ngIf="subtitle" class="mb-1 mt-1 ml-3 mr-3 border-box color-light">
  {{ subtitle | translate | interpolate: params }}
</p>


<soft-tab-group [activatedIndex]="strategy.activatedView()"
                (activatedIndexChange)="strategy.activatedView.set($event)"
                [hideSelection]="config.views.length <= 1">
  <soft-tab
    *ngFor="let view of config.views; trackBy:trackByView"
    [title]="view.title"
  >
    <div class="max-h-[80vh] overflow-y-auto">
      <ng-container *ngComponentOutlet="view.component;
                                    inputs: {
                                      config: view.config
                                    }"></ng-container>
    </div>
  </soft-tab>
</soft-tab-group>


<!--
  <soft-filter-and-sort class="px-2"
                        [values]="items ?? []"
                        [filter]="filter$ | async"
                        [filterConfig]="view.config.filter"
                        [sort]="sort$ | async"
                        [sortConfig]="view.config.sort"
                        (filterChange)="onFilterChange($event)"
                        (sortChange)="onSortChange($event)"></soft-filter-and-sort>
  <div class="max-h-[70vh] max-h-[70dvh] overflow-auto">
    <ng-container [ngSwitch]="view?.config?.type">
      <soft-field-ok-data-view-list *ngSwitchCase="'list'"
                                    [config]="$any(view.config)"
                                    [items]="(filteredItems$ | async) ?? []"
                                    [loading]="loading"></soft-field-ok-data-view-list>
    </ng-container>
  </div>
-->
