<div class="font-bold text-sm soft-overline mb-1">
  {{'#WWS_WARENUEBERNAHME.KOPFDATEN.TITEL_KOPFDATEN' | translate}}
</div>

<div class="flex flex-col w-full border border-solid p-0">
  <div class="flex flex-row w-full justify-between border-b border-solid p-4">
    <span>{{'#WWS_WARENUEBERNAHME.KOPFDATEN.LABEL_LIEFERANT' | translate}}</span>
    <span *ngIf="!isBestellung">{{warenuebernahme?.lieferant?.kurzbez}}</span>
    <span *ngIf="isBestellung">{{bestellung?.lieferant?.kurzbez}}</span>
  </div>
  <div class="flex flex-row w-full justify-between border-b border-solid p-4"
       *ngIf="!isBestellung">
    <span>{{'#WWS_WARENUEBERNAHME.KOPFDATEN.LABEL_LIEFERSCHEIN_NUMMER' | translate}}</span>
    <span>{{warenuebernahme?.belegnummer}}</span>
  </div>
  <div class="flex flex-row w-full justify-between p-4">
    <span>{{'#WWS_WARENUEBERNAHME.KOPFDATEN.LABEL_BESTELLNUMMER' | translate}}</span>
    <span *ngIf="!isBestellung">{{warenuebernahme?.bestellnummer}}</span>
    <span *ngIf="isBestellung">{{bestellung?.bestellnummer}}</span>
  </div>
  <div
    class="flex flex-col w-full p-4 border-t border-solid"
    *ngIf="isBestellungUebernahme">
    <span>{{'#WWS_WARENUEBERNAHME.KOPFDATEN.LABEL_LIEFERSCHEIN_NUMMER' | translate}}</span>
    <soft-input
      #lieferscheinnummerInput
      [type]="'text'"
      [placeholder]="'#WWS_WARENUEBERNAHME.KOPFDATEN.LABEL_LIEFERSCHEIN_NUMMER'"
      [class]="'w-full mt-4'"
      (blur)="lieferscheinnummerChange.emit(lieferscheinnummerInput.value ?? '')">
    </soft-input>
  </div>
</div>
