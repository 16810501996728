<div class="flex w-full mt-2">
  <soft-input
    class="h-10"
    placeholder="Artikel suchen"
    #input
    (keydown.enter)="search(input.value)">
    <div *ngIf="(input.value?.length ?? 0) > 0"
         class="cursor-pointer"
         (click)="input.value = ''; onEmptyTextfield()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
  </soft-input>
  <button class="soft-button primary" [disabled]="((input?.value?.length || 0) < 3)" (click)="search(input.value)">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
      <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clip-rule="evenodd" />
    </svg>
  </button>
</div>
<ng-container *ngIf="itemsWithFavorite$ | async as items; else suchverlaufTemplate">
  <ng-container *ngIf="items.length > 0 || (loading$ | async); else suchverlaufTemplate">
    <soft-artikel-suchergebnis class="flex flex-grow flex-col"
                               [loading]="loading$ | async"
                               [searchInput]="searchText$ | async"
                               [items]="items"
                               (addToFavorites)="onAddToFavorites($event)"
                               (removeFromFavorites)="onRemoveFromFavorites($event)"
                               (selectArtikel)="selectArtikel.emit($event)"></soft-artikel-suchergebnis>
  </ng-container>
</ng-container>
<ng-template #suchverlaufTemplate>
  <soft-artikel-suchverlauf (selectVerlauf)="input.value = $event; search($event)"></soft-artikel-suchverlauf>
</ng-template>
