import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@softline/core';

@Pipe({
  name: 'page',
})
export class PagePipe<T> implements PipeTransform {
  transform(
    value: readonly T[] | null | undefined,
    page: number,
    size: number
  ): T[] {
    if (!isDefined(value)) return [];
    const start = (page - 1) * size;
    const end = page * size;
    return value.slice(start, end);
  }
}
