import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'take',
})
export class TakePipe<T> implements PipeTransform {
  transform(value: readonly T[] | null | undefined, count: number): T[] {
    if (!value) return [];
    return value.filter((o, i) => i < count);
  }
}
