import { InjectionToken } from '@angular/core';

export interface OfflineFeatureConfig {
  featureName: string;
  fields: string[];
}
export const SOFTLINE_SETTINGS_OFFLINE = 'offline';

export const SOFTLINE_CONFIG_OFFLINE_FEATURE = new InjectionToken<
  string | OfflineFeatureConfig
>('SOFTLINE_CONFIG_OFFLINE_FEATURE');
