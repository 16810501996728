import { Entity } from '../types/entity';
import {
  createGetter,
  createMutation,
  mutate,
  select,
} from '../../../factories';
import { StoreFeature } from '../../../store';

export interface State<T extends Entity = Entity> {
  entity: T | null;
}

export class Store<T extends Entity = Entity> {
  mutations = {
    set: createMutation<State<T>, T | null>('set'),
  };
  getters = {
    entity: createGetter<State<T>, T | null>('entity'),
  };
  feature: StoreFeature<State<T>> = {
    initialState: {
      entity: null,
    },
    mutations: [
      mutate(this.mutations.set, ({ state, params }) => ({
        ...state,
        entity: params,
      })),
    ],
    getters: [select(this.getters.entity, ({ state }) => state.entity)],
    actions: [],
  };
}

export function create<T extends Entity>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const feature = instance.feature;
