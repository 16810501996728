<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
  <div class="flex flex-col">
    <label class="soft-label top required">
      {{ '#APPLICATION.GEOLOCATION.INPUT.LONGITUDE' | translate }}
    </label>
    <soft-input
      id="application.geolocation.input#gpslaenge"
      formControlName="gpslaenge"
    ></soft-input>
  </div>
  <div class="c column mt-4">
    <label class="soft-label top required">
      {{ '#APPLICATION.GEOLOCATION.INPUT.LATITUDE' | translate }}
    </label>
    <soft-input
      id="application.geolocation.input#gpsbreite"
      formControlName="gpsbreite"
    ></soft-input>
  </div>
  <div class="flex flex-row-reverse mt-4">
    <button
      id="application.geolocation.input#submit"
      type="submit"
      class="soft-button primary"
    >
      {{ '#APPLICATION.GEOLOCATION.INPUT.SUBMIT' | translate }}
    </button>
  </div>
</form>
