import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from "@softline/core";
import {RouterModule} from "@angular/router";
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from "@softline/application";
import {SOFTLINE_FEATURE_WWS_WARENUEBERNAHME} from "../warenuebernahme.shared";
import {WwsWarenuebernahmeStore} from "../store/warenuebernahme.store";

@Component({
  selector: 'soft-warenuebernahme',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './warenuebernahme.component.html',
  styleUrls: ['./warenuebernahme.component.scss'],
})
export class WarenuebernahmeComponent implements OnInit, OnDestroy {
  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#WWS_WARENUEBERNAHME.DETAIL.TITEL_WARENUEBERNAHME'
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet, {
        name: WarenuebernahmeComponent,
        commands: [
          {
            name: '#WWS_WARENUEBERNAHME.UEBERSICHT.LABEL_OFFENE_WARENUEBERNAHMEN',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-people-carry-box',
            routerLink: '/warenuebernahme'
          },
          {
            name: '#WWS_WARENUEBERNAHME.UEBERSICHT.LABEL_BESTELLUNG_ABBUCHEN',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-truck-ramp-box',
            routerLink: '/warenuebernahme/bestellung/schritt-1'
          }
        ]
      }
    );
  }
  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      WarenuebernahmeComponent,
    );
  }
}
