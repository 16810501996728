import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
} from '../store/factories';
import { Connection } from './types/connection';
import { StoreFeature } from '../store/store';
import { ConnectionLoader, ConnectionSaver } from './abstraction';
import { isDefined } from '../functions/is-defined.function';

export interface State {
  connections: Connection[];
  selected: number | null;
}

export const mutations = {
  set: createMutation<State, State>('set'),
  select: createMutation<State, number>('number'),
  add: createMutation<State, Connection>('add'),
  update: createMutation<State, { old: Connection; new: Connection }>('update'),
  remove: createMutation<State, Connection>('remove'),
};

export const actions = {
  load: createAction<State>('load'),
  save: createAction<State>('save'),
};

export const getters = {
  connections: createGetter<State, Connection[]>('connections'),
  selectedIndex: createGetter<State, number | null>('selectedIndex'),
  selected: createGetter<State, Connection | null>('selected'),
  selectedUrl: createGetter<State, string>('selectedUrl'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    connections: [],
    selected: null,
  },
  mutations: [
    mutate(mutations.set, ({ params }) => {
      return params;
    }),
    mutate(mutations.select, ({ state, params }) => {
      return { ...state, selected: params };
    }),
    mutate(mutations.add, ({ state, params }) => {
      const connections = [...state.connections, params];
      const selected = state.selected ?? 0;
      return { ...state, connections, selected };
    }),
    mutate(mutations.update, ({ state, params }) => {
      const connections = [...state.connections];
      const index = connections.indexOf(params.old);
      connections[index] = params.new;
      return { ...state, connections };
    }),
    mutate(mutations.remove, ({ state, params }) => {
      const connections = [...state.connections];
      const index = connections.indexOf(params);

      connections.splice(index, 1);
      let selected = state.selected;
      if (index === selected) selected = null;
      else if (selected && index < selected) selected = selected - 1;
      return { ...state, connections, selected };
    }),
  ],
  actions: [
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.load, async ({ store, featureName, injector }) => {
      const service = injector.get(ConnectionLoader);
      const state = await service.load();
      store.commit(featureName, mutations.set, state);
    }),
    on(actions.save, async ({ state, injector }) => {
      const service = injector.get(ConnectionSaver);
      await service.save(state);
    }),
  ],
  getters: [
    select(getters.connections, ({ state }) => state.connections),
    select(getters.selectedIndex, ({ state }) => state.selected),
    select(getters.selected, ({ state }) => {
      if (!isDefined(state.selected)) return null;
      return state.connections[state.selected];
    }),
    select(getters.selectedUrl, ({ state }) => {
      if (!isDefined(state.selected)) return '';
      const connection = state.connections[state.selected];
      let url = connection.host.replace(/^\/+|\/+$/g, '');
      if (connection.port) url += `:${connection.port}`;
      if (connection.basePath)
        url += `/${connection.basePath.replace(/^\/+|\/+$/g, '')}`;
      return url;
    }),
  ],
};
