export interface EinheiteneingabeEinheit {
  id: number;
  arteh: string;
  bezeichnung:string;
  faktor: number;
  kzausgabe: string;
}

export interface EinheiteneingabeEinheitMenge {
  einheit: EinheiteneingabeEinheit;
  menge: number | null;
}

export interface EinheiteneingabeArtikel {
  id: number;
  nummer: string;
  lieferantArtikelnummner?: string;
  ean?: string;
  artbez1?: string;
  artbez2?: string;
  artbez3?: string;
  artbez4?: string;
  artbez5?: string;
}

export interface Einheiteneingabe {
  // Einheit die der Benutzer geändert hat (notwendig für API, damit die Berechnung korrekt ausgeführt wird)
  ideinheitEingabe?: number;
  einheiten: EinheiteneingabeEinheitMenge[];
  summeEinheit: EinheiteneingabeEinheitMenge;
  erfassungsEinheit: EinheiteneingabeEinheitMenge;
  artikel: EinheiteneingabeArtikel;
}
