import { Pipe, PipeTransform } from '@angular/core';
import {
  Definition,
  isScanDefinition,
  ScanDefinition,
} from '../data/definitions';

@Pipe({
  name: 'scanDefinition',
  standalone: true,
})
export class ScanDefinitionPipe implements PipeTransform {
  transform(value: Definition): ScanDefinition | undefined {
    if (isScanDefinition(value)) return value;
    return undefined;
  }
}
