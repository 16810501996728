import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_PWA_UPDATE } from "../../pwa.shared";
import { PwaUpdate, PwaUpdateStore } from "../store";
import { Observable } from "rxjs";
import { UiCoreModule } from "@softline/ui-core";
import { PwaUpdateService } from "../services/pwa-update.service";

@Component({
  selector: 'soft-pwa-update-settings',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './pwa-update-settings.component.html',
  styleUrls: ['./pwa-update-settings.component.scss']
})
export class PwaUpdateSettingsComponent implements OnInit {

  state$: Observable<PwaUpdate> = this.store.observe(SOFTLINE_FEATURE_PWA_UPDATE, PwaUpdateStore.getters.data);

  constructor(private store: Store, private service: PwaUpdateService) { }

  async ngOnInit(): Promise<void> {
    await this.service.checkUpdate();
  }

  onActivateUpdate(): void {
    this.service.activateUpdate();
  }
  async onCheckUpdate(): Promise<void> {
    await this.service.checkUpdate();
  }

}
