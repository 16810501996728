import { Injectable } from "@angular/core";
import { Store, TelemetryBaggageService } from "@softline/core";

@Injectable()
export class RouteBaggageService extends TelemetryBaggageService {
  constructor() {
    super()
  }

  override get(): object {
    return {
      route: window.location.href
    }
  }
}
