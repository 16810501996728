import {
  createAction,
  createGetter,
  createMutation,
  CustomStore,
  mutate,
  on,
  select,
  StoreBuilder
} from "@softline/core";
import {BestellerfassungService} from '../services/bestellerfassung.service';
import {lastValueFrom} from 'rxjs';
import {ArtikelDetailStore} from '@softapps/wws/core';
import {
  SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE
} from "../bestellerfassung.shared";
import {BestellerfassungBeweStore} from './bestellerfassung-bewe.store';
import {ItemScan, Scan} from '@softline/application';
import {BestellerfassungKopf} from '../data/bestellerfassung-kopf';
import {ModalStore, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';

export interface BestellvorschlagState {
  kopf?: BestellerfassungKopf;
  datum?: string;
  lieferant?: any;
  lagerort?: any;
  itemScanResult?: ItemScan
}

const mutations = {
  setKopf: createMutation<BestellvorschlagState, BestellerfassungKopf>('mdeBestellerfassung/setKopf'),
  setDate: createMutation<BestellvorschlagState, string | undefined>('mdeBestellerfassung/setDate'),
  setLieferant: createMutation<BestellvorschlagState, object>('mdeBestellerfassung/setLieferant'),
  setLagerort: createMutation<BestellvorschlagState, BestellerfassungKopf>('mdeBestellerfassung/setLagerort'),
  setItemScanResult: createMutation<BestellvorschlagState, ItemScan | undefined>('mdeBestellerfassimg/setItemScanResult')
}

const getters = {
  kopf: createGetter<BestellvorschlagState, BestellerfassungKopf | undefined>('mdeBestellerfassung/getKopf'),
  datum: createGetter<BestellvorschlagState, string | null | undefined>('mdeBestellerfassung/getDatum'),
  lieferant: createGetter<BestellvorschlagState, BestellerfassungKopf['lieferant'] | undefined>('mdeBestellerfassung/getLieferant'),
  lagerort: createGetter<BestellvorschlagState, BestellerfassungKopf | undefined>('mdeBestellerfassung/getLagerort'),
  itemScanResult: createGetter<BestellvorschlagState, ItemScan | undefined>('mdeBestellerfassung/getItemScanResult'),
}

const actions = {
  abschluss: createAction<BestellvorschlagState, { ediSchicken: boolean }, void>('mdeBestellerfassung/abschluss'),
  loadKopf: createAction<BestellvorschlagState, void, BestellerfassungKopf>('mdeBestellerfassung/loadKopf'),
  removeBeleg: createAction<BestellvorschlagState, void, void>('mdeBestellerfassung/removeBeleg'),
  bestellvorschlagScan: createAction<BestellvorschlagState, Scan, any>('mdeBestellerfassung/bestellvorschlagScan')
}

const bestellerfassungStore: CustomStore<
  BestellvorschlagState,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  mutations,
  getters,
  actions,
  feature: {
    initialState: {
      datum: undefined,
    },
    mutations: [
      mutate(mutations.setDate, ({ params: datum, state }) => ({
        ...state,
        datum
      })),
      mutate(mutations.setLieferant, ({ params: lieferant, state }) => ({
        ...state,
        lieferant,
      })),
      mutate(mutations.setLagerort, ({ params: lagerort, state }) => ({
        ...state,
        lagerort
      })),
      mutate(mutations.setItemScanResult, ({ params, state }) => ({
        ...state,
        itemScanResult: params
      })),
      mutate(mutations.setKopf, ({ params: kopf, state }) => ({
        ...state,
        kopf
      }))
    ],
    getters: [
      select(getters.kopf, ({ state }) => state?.kopf),
      select(getters.datum, ({ state }) => state.datum),
      select(getters.lieferant, ({ state }) => state.lieferant),
      select(getters.lagerort, ({ state }) => state.lagerort),
      select(getters.itemScanResult, ({ state }) => state?.itemScanResult),
    ],
    actions: [
      on(actions.loadKopf, async ({ injector, featureName, commit, get }) => {
        const service = injector.get(BestellerfassungService);
        const result = await lastValueFrom(service.getKopf());
        commit(featureName, mutations.setKopf, result);
        return result ?? null
      }),
      on(actions.abschluss, async ({ injector, featureName, commit, params }) => {
        const service = injector.get(BestellerfassungService);
        await lastValueFrom(service.abschluss(params?.ediSchicken));
        commit(SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_ARTIKEL_DETAILS, ArtikelDetailStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE, BestellerfassungBeweStore.mutations.clear);
        commit(featureName, mutations.setDate, null);
        commit(featureName, mutations.setKopf, null);
        commit(featureName, mutations.setLieferant, null);
        commit(featureName, mutations.setItemScanResult, null);
        commit(featureName, mutations.setLagerort, null);
      }),
      on(actions.removeBeleg, async ({ injector, featureName, commit }) => {
        const service = injector.get(BestellerfassungService);
        await lastValueFrom(service.removeBeleg());
        commit(SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_ARTIKEL_DETAILS, ArtikelDetailStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE, BestellerfassungBeweStore.mutations.clear);
        commit(featureName, mutations.setDate, null);
        commit(featureName, mutations.setKopf, null);
        commit(featureName, mutations.setLieferant, null);
        commit(featureName, mutations.setItemScanResult, null);
        commit(featureName, mutations.setLagerort, null);
      }),
      on(actions.bestellvorschlagScan, async ({ injector, featureName, commit, params }) => {
        const service = injector.get(BestellerfassungService);
        const result = await lastValueFrom(service.bestellvorschlagScan(params));

        const bestellung = await lastValueFrom(service.bestellungFromBestellvorschlag(result.id));
        commit(featureName, mutations.setLieferant, bestellung.lieferant);
        commit(featureName, mutations.setDate, bestellung.belegdatum);
        commit(featureName, mutations.setKopf, bestellung);

        return result;
      }),
    ],
  }
}

export const BestellerfassungStore = new StoreBuilder(bestellerfassungStore)
  .value;
