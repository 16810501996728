<div>
  <span class="italic" *ngIf="name">{{ name }}:</span>
  <ng-container
    *ngTemplateOutlet="
      value === null
        ? nullTemplate
        : (value | isArray)
        ? arrayTemplate
        : (value | typeof) === 'object'
        ? objectTemplate
        : (value | typeof) === 'function'
        ? functionTemplate
        : (value | typeof) === 'symbol'
        ? symbolTemplate
        : (value | typeof) === 'undefined'
        ? undefinedTemplate
        : defaultTemplate;
      context: { content: value }
    "
  ></ng-container>
</div>

<ng-template #nullTemplate> null </ng-template>

<ng-template let-content="content" #arrayTemplate>
  <span class="cursor-pointer" (click)="isExpanded = !isExpanded">
    <i
      class="fa-regular mx-2"
      [ngClass]="{ 'fa-caret-right': !isExpanded, 'fa-caret-down': isExpanded }"
    ></i>
    Array
  </span>
  <div class="cursor-pointer" *ngIf="isExpanded" class="ml-2">
    <soft-json-object-tree
      *ngFor="let child of content; let i = index"
      [name]="'[' + i + ']'"
      [value]="child"
    ></soft-json-object-tree>
  </div>
</ng-template>

<ng-template let-content="content" #objectTemplate>
  <span class="cursor-pointer" (click)="isExpanded = !isExpanded">
    <i
      class="fa-regular mx-2"
      [ngClass]="{ 'fa-caret-right': !isExpanded, 'fa-caret-down': isExpanded }"
    ></i>
    {{ getClassName(value) }}
  </span>
  <div *ngIf="isExpanded" class="ml-2">
    <soft-json-object-tree
      *ngFor="let child of content | keyvalue"
      [value]="child.value"
      [name]="$any(child.key)"
    ></soft-json-object-tree>
  </div>
</ng-template>

<ng-template #functionTemplate> function </ng-template>

<ng-template #symbolTemplate> symbol </ng-template>

<ng-template #undefinedTemplate> undefined </ng-template>

<ng-template let-content="content" #defaultTemplate>
  {{ content }}
</ng-template>
