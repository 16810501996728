import {ConnectionHttpService, Dictionary} from '@softline/core';
import {Observable} from 'rxjs';

export class HttpListService {
  constructor(private service: ConnectionHttpService, private path: string) {
  }

  get<T>(queryParams: Dictionary<unknown> | undefined): Observable<{ result: T[], sum: Partial<T> }> {
    const location = {path: this.path, queryParams};
    return this.service.get(location);
  }
}
