<ng-container *ngIf="firmen$ | async as firmen">
    <div class="c column no-wrap">
        <h6 class="font-semibold">Firma</h6>
        <soft-select [value]="(selectedFirmaId$ | async)" (valueChange)="onChange($event)">
            <soft-select-option *ngFor="let firma of firmen"
                                [value]="firma.id">
              {{ firma?.kurzbezeichnung }}
            </soft-select-option>
        </soft-select>
    </div>
</ng-container>
