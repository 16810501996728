<div class="flex flex-col mb-1" *ngIf="firma$ | async as firma">
  <h6 class="font-semibold text-base mb-1">{{ '#UTILITIES.FIRMA.LABEL' | translate }}</h6>
  <span>{{ firma?.kurzbezeichnung }}</span>
</div>

<hr class="soft-separator" />

<ng-container *ngIf="vkforgs$ | async as vkforgs">
  <h6 class="font-semibold text-base mb-1 mt-4">{{ '#UTILITIES.VKFORG.LABEL' | translate }}</h6>
  <soft-select [value]="(vkforgContext$ | async)?.vkforgid || vkforgs[0]?.id" (valueChange)="onSubmit(select.value)" #select>
    <soft-select-option *ngFor="let vkforg of vkforgs" [value]="vkforg.id">
      {{ vkforg.vkforg }} - {{ vkforg.kurzbezeichnung }}
    </soft-select-option>
  </soft-select>
</ng-container>
