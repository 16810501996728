<div class="flex flex-row gap-2">

  <div class="flex flex-col">
    <div class="soft-overline px-3">
      {{'#SOFTAPPS_CORE.PAGES.QUERY.SHORTCUTS' | translate}}
    </div>
    <div class="flex flex-row text-xs">
      @for(shortcut of shortcuts(); track shortcut) {
        <div class="flex flex-col justify-center items-center hover:underline cursor-pointer border-r px-3"
             (click)="shortcutClick.emit(shortcut.query)">
          <soft-icon [name]="shortcut.icon" class="fa-sm"></soft-icon>
          <div class="w-full text-center">
            {{shortcut.title | translate}}
          </div>
        </div>
      }
    </div>
  </div>
</div>
