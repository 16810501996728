import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { TelemetryService } from "../services/telemetry.service";
import { SOFTLINE_SERVICE_UUID } from "../../core.shared";

@Injectable()
export class TelemetryInterceptor implements HttpInterceptor {
  constructor(private service: TelemetryService,
               @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (!this.service.id)
      return next.handle(request);

    request = request.clone({
      setHeaders: {
        'X-SOFTLINE-TELEMETRY-SESSION-ID': this.service.sessionId,
        'X-SOFTLINE-TELEMETRY-STACK-ID': this.service.id,
        'X-SOFTLINE-TELEMETRY-REQUEST-ID': this.uuid().replace(/-/g, ''),
        'X-SOFTLINE-TELEMETRY-DEBUG-INFO': JSON.stringify(this.service.baggage),
      },
    });
    return next.handle(request);
  }
}
