<div>
  <ng-container [ngSwitch]="control">
    <button
      *ngSwitchCase="'button'"
      type="button"
      class="soft-button secondary"
      (click)="onClick()"
    >
      <soft-dynamic-value
        [value]="context"
        [definition]="definition"
      ></soft-dynamic-value>
    </button>
    <a *ngSwitchDefault (click)="onClick()" class="soft-link">
      <soft-dynamic-value
        [value]="context"
        [definition]="definition"
      ></soft-dynamic-value>
    </a>
  </ng-container>
</div>
