import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'soft-dynamic-date-value',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit {
  @Input() value?: any;
  @Input() format?: string;

  constructor() {}

  ngOnInit(): void {}
}
