import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { equalFilterRegistration, Filter, getValue, PropertyFilter, Store } from "@softline/core";
import { FilterConfig, FilterConfigOperator, FilterConfigProperty } from "@softline/application";
import { FilterPropertyNamePipe } from "./filter-property-name.pipe";
import { FilterOperatorPipe } from "./filter-operator.pipe";
import { ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule } from "@softline/ui-core";
import { getDefaultOperators } from "../../../functions/get-default-operators";
import { SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT } from "../../../filter-and-sort.shared";
import { FilterValueDirective } from "./values/filter-value.directive";

@Component({
  selector: 'soft-filter-item',
  standalone: true,
  imports: [CommonModule, FilterPropertyNamePipe, FilterPropertyNamePipe, FilterOperatorPipe, FilterValueDirective, UiCoreModule],
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {

  @Input() filter: Partial<PropertyFilter> = {};
  @Output() filterChange = new EventEmitter<Partial<PropertyFilter>>();
  @Input() config?: FilterConfig | undefined;
  @Input() values!: object[];
  @Output() delete = new EventEmitter<Partial<PropertyFilter>>();

  constructor(private store: Store,
              @Inject(SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT) private registeredOperators: FilterConfigOperator[]) {}

  ngOnInit(): void {}

  async onPropertyClick(): Promise<void>{
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<FilterConfigProperty>(),
      {
        title: '#APPLICATION.FILTER_AND_SORT.FILTER.FIELD_PLACEHOLDER',
        dismiss: true,
        options: this.config?.properties.map(o => ({label: o.title, value: o})) ?? []
      });
    if(result === 'DISMISSED')
      return;

    let filter = {...this.filter, property: result.property} as Partial<PropertyFilter>;

    const newOperators = result.operators ?? getDefaultOperators(this.values);

    if(newOperators.indexOf(this.filter.operator ?? '') === -1) {
      const hasEqualOperator = newOperators.indexOf(equalFilterRegistration.operator) > -1;
      filter = {property: result.property, operator: hasEqualOperator ? equalFilterRegistration.operator : undefined};
    }
    else
      filter = {property: result.property, operator: this.filter.operator};

    this.filterChange.emit(filter);
  }

  async onOperatorClick(property: string): Promise<void>{
    const propertyConfig = this.getPropertyConfig(property);
    if(!propertyConfig)
      return;

    const operatorConfigs = this.getPropertyOperatorConfigs(propertyConfig);

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose(),
      {
        title: '#APPLICATION.FILTER_AND_SORT.FILTER.OPERATOR_PLACEHOLDER',
        dismiss: true,
        options: operatorConfigs.map(o => ({label: o.title, value: o.operator})) ?? []
      });
    if(result === 'DISMISSED')
      return;
    const filter = {property, operator: result} as Filter;
    this.filterChange.emit(filter);
  }

  onDelete(): void {
    this.delete.emit(this.filter);
  }

  private getPropertyConfig(property: string): FilterConfigProperty | null {
    return this.config?.properties.find(o => o.property === property) ?? null;
  }

  private getPropertyOperatorConfigs(property: FilterConfigProperty): FilterConfigOperator[] {
    const values = this.values.map(o => getValue(o, property.property));
    const operators = property.operators ?? getDefaultOperators(values);
    return operators
      .map(o => this.registeredOperators.find(p => p.operator === o))
      .filter((o): o is FilterConfigOperator => !!o)
  }

}
