import {RemoteConfigProperty} from '@softline/application';

export interface RetourenRemoteConfiguration {
  lagerEingabe: boolean
  lagerplatzEingabe: boolean
  belegdatumEingabe: boolean
  belegdatumEditierbar: boolean
  showSchwundlisteDialog: boolean
  addierenErlaubt: boolean
  addierenSichtbar: boolean
  lagerplatzAbweichendErflager: boolean
  manartikelErlaubt: boolean
  ausgelistetDialog: boolean
  ausgelistetErlaubt: boolean
  hinweisAbweichendeMenge: boolean
  mengeVorschlag: number | null
  lagerstandType: 'lagerstand' | 'lagerstand_retourenbestand' | 'retourenbestand' | 'ausgeblendet'
}

function getPropValue(key: string, properties: RemoteConfigProperty[]): string {
  return properties.find(o => o.key === key)?.value ?? ''
}

export function extractRemoteConfiguration(properties: RemoteConfigProperty[]): RetourenRemoteConfiguration {
  const lagerEingabe = getPropValue('FELD_LAGER', properties) === 'editierbar';
  const lagerplatzEingabe = getPropValue('FELD_LAGERPLATZ', properties) === 'editierbar';
  const belegdatumEingabe = getPropValue('FELD_BELEGDATUM', properties) !== 'ausgeblendet';
  const belegdatumEditierbar = getPropValue('FELD_BELEGDATUM', properties) === 'editierbar';
  const showSchwundlisteDialog = getPropValue('FELD_SCHWUNDLISTE', properties) === 'eingeblendet';
  const addierenErlaubt = getPropValue('FELD_ADDIEREN', properties) === 'editierbar';
  const addierenSichtbar = getPropValue('FELD_ADDIEREN', properties) !== 'ausgeblendet';
  const lagerplatzAbweichendErflager = getPropValue('LAGERPLATZ_ABWEICHEND_ERFLAGER', properties) === 'true';
  const manartikelErlaubt = getPropValue('MANARTIKEL_ERLAUBT', properties) === 'true';
  const ausgelistetDialog = getPropValue('AUSGELISTET_ERLAUBT', properties) === 'frage';
  const ausgelistetErlaubt = getPropValue('AUSGELISTET_ERLAUBT', properties) === 'true';
  const lagerstandType = getPropValue('FELD_LAGERSTAND', properties) as RetourenRemoteConfiguration['lagerstandType'];
  const hinweisAbweichendeMenge = getPropValue('HINWEIS_ABWEICHENDE_MENGE', properties) === 'true'
  const mengeVorschlag = Number(getPropValue('MENGE_VORSCHLAG', properties) ?? '')

  return {
    lagerEingabe,
    lagerplatzEingabe,
    belegdatumEingabe,
    belegdatumEditierbar,
    showSchwundlisteDialog,
    addierenErlaubt,
    addierenSichtbar,
    lagerplatzAbweichendErflager,
    manartikelErlaubt,
    ausgelistetDialog,
    ausgelistetErlaubt,
    lagerstandType,
    hinweisAbweichendeMenge,
    mengeVorschlag
  }
}
