import {Step} from '@softline/application';

export const SOFTLINE_CONFIG_MDE_ETIKETTENDRUCK_STEPS: Step[] = [
  {
    header: 'Artikel suchen',
    subHeader: 'Bitte gewünschten Artikel auswählen',
  },
  {
    header: 'Etikettendaten eingeben',
    subHeader: 'Bitte Menge und Einheit anpassen',
  }
];

export const SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK = 'mdeEtikettendruck'
export const SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_BEWE = 'mdeEtikettendruckBewe'
export const SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_ARTIKEL_DETAILS = 'mdeEtikettendruckArtikelDetails';

export const SOFTLINE_PERMISSION_MDE_ETIKETTENDRUCK = 'ETIKETTENDRUCK';
