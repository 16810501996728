<div
  class="soft-container soft-container-column"
  *ngIf="form && definition"
>
  <label class="soft-label soft-label-top"
    >{{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-number-input
    [formControl]="form|formControl:definition.name"
    [readonly]="definition.readonly | booleanRule: form.value"
    [softScan]="(definition | scanDefinition)?.scan ?? false"
    [labelTypes]="(definition | scanDefinition)?.labelTypes"
    softFieldValidation
    #value
  >
  </soft-number-input>
</div>
