<span
  role="switch"
  aria-roledescription="toggle-switch"
  class="soft-toggle-switch cursor-pointer"
  [attr.aria-checked]="checked"
  [class.soft-toggle-switch-large]="size === 'large'"
  [class.soft-toggle-switch-checked]="checked"
  [class.soft-toggle-switch-disabled]="disabled"
  [class.soft-toggle-switch-readonly]="readonly"
  (click)="toggle()"
>
  <span
    class="soft-toggle-switch-handle"
    [class.soft-toggle-switch-handle-large]="size === 'large'"
    [class.soft-toggle-switch-handle-checked]="checked && size === 'default'"
    [class.soft-toggle-switch-handle-checked-large]="checked && size === 'large'"
  ></span>
</span>
