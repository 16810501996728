import {ENVIRONMENT_INITIALIZER, importProvidersFrom, inject} from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {ApplicationStore, SOFTLINE_CONFIG_OFFLINE_FEATURE, SOFTLINE_FEATURE_APPLICATIONS,} from '@softline/application';
import {
  SOFTLINE_CONFIG_ARTIKEL_INFO_DETAIL_SEQUENCE_SIZE,
  SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_ITEMS,
  SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_DETAIL,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICE,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICELIST,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_STOCK,
  SOFTLINE_FEATURE_ARTIKEL_INFO_SCAN_LISTE,
  SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
  SOFTLINE_FEATURE_LOCAL_SEARCH_HISTORY
} from './artikel-info.shared';
import {ItemStore} from './store/item.store';
import {ItemPriceStore} from './store/item-price.store';
import {ItemStockStore} from './store/item-stock.store';
import {
  SOFTLINE_API_ITEM_FAVORITE_ITEMS,
  SOFTLINE_API_ITEM_FAVORITE_LISTS,
  SOFTLINE_API_ITEM_PRICE,
  SOFTLINE_API_ITEM_STOCK
} from './artikel-info.api';
import {ItemDetailStore} from './store/item-detail.store';
import {UmsatzabfrageService} from './services/umsatzabfrage.service';
import {UmsatzabfrageStore} from './store/umsatzabfrage.store';
import {FavoriteListsStore} from './store/favorite-lists.store';
import {FavoriteService} from './services/favorite.service';
import {FavoriteItemsStore} from './store/favorite-items.store';
import {FavoriteResourceService} from './services/favorite-resource.service';
import {SearchHistoryStore} from './store/search-history.store';
import {ScanListStore} from './store/scan-liste.store';
import {GesamtabfrageBewegungenService} from './services/gesamtabfrage-bewegungen.service';
import {ItemDetailPricelistStore} from './store/item-pricelist.store';
import {SOFTLINE_CONFIG_ARTIKEL_INFO_MODAL_COMPONENT} from '@softapps/wws/core';
import {ArtikelDetailComponent} from './pages/artikel-detail/artikel-detail.component';

export const artikelInfoProviders = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: async () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'artikel-info',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Artikel-Info',
          icon: 'fa-regular fa-square-info',
          route: '/artikel-info',
          permission: 'ARTIKELINFO',
        }
      );
    },
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_ARTIKEL_INFO_DETAIL_SEQUENCE_SIZE,
    useValue: 4
  },
  FavoriteService,
  GesamtabfrageBewegungenService,
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM,
      feature: ItemStore.feature
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_LOCAL_SEARCH_HISTORY,
      feature: SearchHistoryStore.feature
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARTIKEL_INFO_SCAN_LISTE,
      feature: ScanListStore.feature
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICE,
      feature: ItemPriceStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (o: any) => new SoftlineEntityService(o, SOFTLINE_API_ITEM_PRICE),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS,
      feature: FavoriteListsStore.feature,
      providers: [
        {
          provide: FavoriteResourceService,
          useClass: FavoriteResourceService
        },
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (o: any) => new SoftlineEntityService(o, SOFTLINE_API_ITEM_FAVORITE_LISTS),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_ITEMS,
      feature: FavoriteItemsStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (o: any) => new SoftlineEntityService(o, SOFTLINE_API_ITEM_FAVORITE_ITEMS),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_DETAIL,
      feature: ItemDetailStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICELIST,
      feature: ItemDetailPricelistStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
      feature: UmsatzabfrageStore.feature,
      providers: [
        {
          provide: UmsatzabfrageService,
          useFactory: () => new UmsatzabfrageService(inject(ConnectionHttpService))
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_STOCK,
      feature: ItemStockStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (o: any) => new SoftlineEntityService(o, SOFTLINE_API_ITEM_STOCK),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    }),
  ),
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_LOCAL_SEARCH_HISTORY, fields: ['history'] },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_ARTIKEL_INFO_MODAL_COMPONENT,
    useValue: ArtikelDetailComponent
  }
];
