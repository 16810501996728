import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

export type DateChangeCallback = (date: string) => Promise<void>

@Injectable()
export class DateNavigatorService implements OnDestroy {

  private subscription?: Subscription;

  private readonly _date$ = new BehaviorSubject<string>(new Date().toISOString());
  private dateChangeCallbacks = new Set<DateChangeCallback>();

  readonly date$ = this._date$.asObservable();

  get date(): string {
    return this._date$.getValue();
  }

  constructor() {
    this.date$.subscribe(async date => {
      const callbacks = Array.from(this.dateChangeCallbacks.values());
      await Promise.all(callbacks.map(cb => cb(date)));
    });
  }

  ngOnDestroy(): void {
      console.log('Service on destroy');

      if (this.subscription && !this.subscription.closed)
        this.subscription.unsubscribe();

      this.subscription = undefined;
  }

  onDateChange(callback: DateChangeCallback): () => void {
    this.dateChangeCallbacks.add(callback);
    return () => {
      this.dateChangeCallbacks.delete(callback);
    }
  }

  setDate(date: string): void {
    this._date$.next(date);
  }
}
