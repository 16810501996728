<h6 class="font-semibold soft-overline mt-4">Suchergebnisse für "{{ searchInput }}"</h6>
<div class="bg-surface full-width flex flex-col flex-grow border-t border-t-solid border-t-separator w-full mt-1 overflow-y-auto max-h-[60vh]">
  <ul class="w-full bg-surface">
    <ng-container *ngIf="loading; else content">
      <li class="w-full flex justify-between items-center py-2 px-4 border-b border-b-solid border-b-separator" *softRepeat="5">
        <div class="flex flex-col gap-2">
          <div class="skeleton w-28 h-5"></div>
          <div class="skeleton w-12 h-4"></div>
        </div>
      </li>
    </ng-container>

    <ng-template #content>
      <ng-container *ngIf="items && (items?.length ?? 0) > 0">
        <li
          *ngFor="let item of items; let last = last; trackBy: trackByFn"
          [ngClass]="{ 'border-b border-b-solid border-b-separator': true }"
          class="w-full flex justify-between nowrap items-center px-4 py-2 gap-2 cursor-pointer">
          <div class="flex flex-col gap-1 w-full" (click)="selectArtikel.emit(item.item)">
            <h6 class="font-semibold text-sm">{{ item.item.title1 }}</h6>
            <h6 class="font-normal text-sm">{{ item.item.title2 || item.item.title3 }}</h6>
            <h6 class="font-normal text-sm text-light">{{ item.item.number }}</h6>
          </div>

          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 scale-90" *ngIf="!item.favorite" (click)="addToFavorites.emit(item.item)">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6" *ngIf="item.favorite" (click)="removeFromFavorites.emit(item.item)">
              <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 opacity-20 scale-90" (click)="selectArtikel.emit(item.item)">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </div>
        </li>
      </ng-container>
    </ng-template>
  </ul>
</div>
