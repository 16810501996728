import { Injectable, signal, TemplateRef, Type } from "@angular/core";
import { BehaviorSubject } from "rxjs";


type Reference = TemplateRef<any> | Type<any>;

@Injectable()
export class BottomOutletService {

  references$ = new BehaviorSubject<Reference[]>([]);

  append(ref: Reference): void {
    this.references$.next([...this.references$.value, ref]);
  }

  remove(ref: Reference): void {
    const references = [...this.references$.value];
    references.splice(references.indexOf(ref), 1);
    this.references$.next(references);
  }
}
