import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  private mobileSystems =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
  userAgent: string;

  constructor() {
    this.userAgent = navigator.userAgent;
  }

  isMobile(): boolean {
    return this.mobileSystems.test(this.userAgent);
  }

  isDesktop(): boolean {
    return !this.mobileSystems.test(this.userAgent);
  }
}
