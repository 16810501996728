import {
  createGetter,
  createMutation,
  mutate,
  select,
  StoreFeature,
} from '@softline/core';

export type Breakpoint = null | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export interface State {
  breakpoint: Breakpoint;
}

export const mutations = {
  set: createMutation<State, Breakpoint>('set'),
};

export const actions = {};

export const getters = {
  breakpoint: createGetter<State, Breakpoint>('breakpoint'),
  match: createGetter<State, boolean, Breakpoint | Breakpoint[]>('match'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    breakpoint: null,
  },
  mutations: [mutate(mutations.set, ({ params }) => ({ breakpoint: params }))],
  actions: [],
  getters: [
    select(getters.breakpoint, ({ state }) => state.breakpoint),
    select(getters.match, ({ state, params }) => {
      if (params === null || typeof params === 'string')
        return params === state.breakpoint;
      return (params?.indexOf(state.breakpoint) ?? -1) > -1;
    }),
  ],
};
