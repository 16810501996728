<ng-container *ngIf="(strategy.items$ | async) as items; else notFoundTemplate">
  <div class="flex flex-col sm:flex-col-reverse">
    <div class="flex flex-col max-h-[70vh] mt-2 overflow-y-auto">
      <soft-dynamic-table *ngIf="items.length > 0; else notFoundTemplate"
                          tableClass="soft-table no-border sticky"
                          class="body-border divided"
                          [loading]="strategy.loading()"
                          [definition]="config.definition"
                          [rows]="items"
                          [skeletonRows]="20"
                          (rowDblClick)="this.strategy.resolve($event)">
      </soft-dynamic-table>
    </div>
    <soft-filter-and-sort class="block mx-2 mt-2 mb-2 sm:mb-0"
                          [filter]="(filter$ | async) ?? null"
                          [filterConfig]="config.filter"
                          [sort]="(sort$ | async) ?? null"
                          [sortConfig]="config.sort"
                          [values]="items"
                          (filterChange)="onFilterChange($event)"
                          (sortChange)="onSortChange($event)">
    </soft-filter-and-sort>
  </div>
</ng-container>
<ng-template #notFoundTemplate>
  <div *ngIf="!strategy.loading()"
       class="flex flex-col text-light text-lg items-center content-center my-4">
    <span>Es wurden keine Daten gefunden</span>
  </div>
</ng-template>
