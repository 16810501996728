import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  HostListener,
  Injector,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@softline/core';
import { Modal } from '../../data/modal';
import { ModalConfig } from '../../data/modal-config';
import * as ModalStore from '../../modal.store';
import { SOFTLINE_FEATURE_MODAL } from '../../modal.shared';

@Component({
  selector: 'soft-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterContentInit {
  private contentInit = false;
  private _config!: ModalConfig<Modal<unknown>, unknown, object>;

  @ViewChild('container', { static: true, read: ViewContainerRef })
  container!: ViewContainerRef;

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    this.dismiss(this.config.dismiss === true || (this.config.dismiss && this.config.dismiss.escape), event
    );
  }

  get config(): ModalConfig<Modal<unknown>, unknown, object> {
    return this._config;
  }
  @Input() set config(value: ModalConfig<Modal<unknown>, unknown, object>) {
    this._config = value;
    if(this.contentInit)
      this.reloadContent(value);
  }
  @Input() hidden = false;

  constructor(
    private store: Store,
    private resolver: ComponentFactoryResolver
  ) {}

  ngAfterContentInit(): void {
    this.reloadContent(this._config);
    this.contentInit = true;
  }

  close(result: unknown): void {
    if (this.config.callback) this.config.callback(result);
    this.store.commit(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.mutations.remove,
      this._config
    );
  }
  dismiss(dismissible: boolean | undefined, event?: KeyboardEvent): void {
    if (!this.config.dismiss || !dismissible || this.hidden) return;
    if (this.config.onDismiss) this.config.onDismiss();
    this.store.commit(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.mutations.remove,
      this._config
    );
    if(event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  reloadContent(config: ModalConfig<Modal<unknown>, unknown, object>): void {
    if (!this.container) return;
    let resolver = this.resolver;
    let injector: Injector | undefined;
    if (config.getInjector) {
      injector = config.getInjector();
      resolver = injector.get(ComponentFactoryResolver);
    }

    this.container.clear();
    const factory = resolver.resolveComponentFactory(config.component);
    const componentRef = this.container.createComponent(
      factory,
      undefined,
      injector
    );
    const instance = componentRef.instance;
    instance.registerCloseHandler((result) => {
      this.close(result);
    });

    if (config.data) {
      for (const [key, value] of Object.entries(config.data))
        (instance as any)[key] = value;
    }
  }
}
