<div class="modal question-modal">
  <div class="p-4 sm:pl-0 sm:pt-0 text-2xl font-semibold">
    Löschen oder abschließen?
  </div>
  <div class="question pr-4 pl-4 pb-4 sm:pl-0">
    Beim verlassen gehen alle Daten verloren. Möchten Sie den Materiallieferschein abschließen oder löschen?
  </div>
  <div class="flex no-wrap">
    <button
      class="soft-button secondary no h-14 w-full"
      (click)="onClick('ABSCHLIESSEN')">
      Abschließen
    </button>
    <button
      class="soft-button primary yes sm:ml-3 h-14 w-full"
      (click)="onClick('LOESCHEN')">
      Löschen
    </button>
  </div>
</div>
