import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {RemoteConfigResolver} from '@softline/application';
import {WarenverprobungResolver} from './resolver/warenverprobung-resolver.service';
import {WARENVERPROBUNG_BELEGEINGABE_SERVICE} from './services/warenverprobung-scan-service';
import {WarenuebernahmeBelegeingabeService} from './services/warenuebernahme-belegeingabe.service';
import {BestellungBelegeingabeService} from './services/bestellung-belegeingabe.service';
import {SOFTLINE_CONFIG_WARENVERPROBUNG_BEZUGSNUMMER} from './warenverprobung.shared';

export const warenverprobungRoutes: Routes = [
  {
    path: 'mde-warenverprobung',
    title: 'Warenverprobung',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/warenverprobung.component').then(
            (o) => o.WarenverprobungComponent
          ),
        children: [
          {
            path: 'belege',
            loadComponent: () => import('./pages/beleg-eingabe/beleg-eingabe.component').then(
              o => o.BelegEingabeComponent
            )
          },
          {
            path: 'artikel-erfassen',
            loadComponent: () => import('./pages/artikel-erfassen/artikel-erfassen.component').then(
              c => c.ArtikelErfassenComponent
            ),
            loadChildren: () => [
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt1/schritt1.component').then(
                  c => c.Schritt1Component
                ),
                path: 'suche'
              },
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt2/schritt2.component').then(
                  c => c.Schritt2Component
                ),
                path: ':idartstamm'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/mde-warenverprobung/artikel-erfassen/suche'
              }
            ]
          },
          {
            path: 'auswahl',
            children: [
              {
                path: 'belegart',
                loadComponent: () => import('./pages/belegart-auswahl/belegart-auswahl.component').then(
                  o => o.BelegartAuswahlComponent
                ),
              },
              {
                path: 'bestellung/belegeingabe',
                providers: [{
                  provide: WARENVERPROBUNG_BELEGEINGABE_SERVICE,
                  useClass: BestellungBelegeingabeService
                }],
                loadComponent: () => import('./pages/beleg-eingabe/beleg-eingabe.component').then(
                  o => o.BelegEingabeComponent
                ),
              },
              {
                path: 'bestellung/bezugsnummer',
                providers: [{
                  provide: SOFTLINE_CONFIG_WARENVERPROBUNG_BEZUGSNUMMER,
                  useValue: { required: true, type: 'BESTELLUNG' }
                }],
                loadComponent: () => import('./pages/bezugsnummer/bezugsnummer.component').then(
                  o => o.BezugsnummerComponent
                ),
              },
              {
                path: 'warenuebernahme/belegeingabe',
                providers: [{
                  provide: WARENVERPROBUNG_BELEGEINGABE_SERVICE,
                  useClass: WarenuebernahmeBelegeingabeService
                }],
                loadComponent: () => import('./pages/beleg-eingabe/beleg-eingabe.component').then(
                  o => o.BelegEingabeComponent
                ),
              },
              {
                providers: [{
                  provide: SOFTLINE_CONFIG_WARENVERPROBUNG_BEZUGSNUMMER,
                  useValue: { required: false, type: 'WARENUEBERNAHME' }
                }],
                path: 'warenuebernahme/bezugsnummer',
                loadComponent: () => import('./pages/bezugsnummer/bezugsnummer.component').then(
                  o => o.BezugsnummerComponent
                ),
              },
              {
                path: '**',
                redirectTo: '/mde-warenverprobung/auswahl/belegart'
              },
            ]
          },
          {
            path: '',
            loadComponent: () => import('./pages/erfassung/erfassung.component').then(
              o => o.ErfassungComponent
            )
          },
          {
            path: '**',
            redirectTo: '/mde-warenverprobung'
          },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: 'WARENVERPROBUNG' },
    resolve: { config: RemoteConfigResolver, warenverprobung: WarenverprobungResolver },
    providers: [],
  },
];
