import { Dictionary, ValueConverter } from "@softline/core";

export class FieldOkResultConverter extends ValueConverter<Dictionary<any> | null | undefined, Dictionary<any> | null | undefined> {


  constructor(private resultMapping: Dictionary<string>,
              private options: {mapKeysOnly?: boolean} = {}) {
    super();
  }

  convert(source: Dictionary<any> | null | undefined): Dictionary<any> | null | undefined {
    if (!source)
      return source;

    const returnValue: Dictionary<unknown> = {};
    for (const [key, value] of Object.entries(source)) {
      let resultKey = this.resultMapping[key];
      if(resultKey || !this.options.mapKeysOnly)
        returnValue[resultKey ?? key] = value;
    }
    return returnValue;
  }

  convertBack(source: Dictionary<any> | null | undefined): Dictionary<any> | null | undefined {
    if (!source)
      return source;
    const returnValue: Dictionary<unknown> = {};
    for (const [key, value] of Object.entries(source)) {
      let [resultKey, _] = Object.entries(this.resultMapping)
        .find(([mappedKey, mappedValue]) => mappedValue === key) ?? [];
      if(resultKey || !this.options.mapKeysOnly)
        returnValue[resultKey ?? key] = value;
    }
    return returnValue;
  }
}
