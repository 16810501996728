import {
  ImportedNgModuleProviders,
  importProvidersFrom,
  Provider,
} from '@angular/core';
import { SOFTLINE_CONFIG_SETTINGS } from '@softline/application';
import {
  SOFTLINE_FEATURE_USER_CONTACT,
  SOFTLINE_SETTINGS_USER_CONTACT,
  UserContactStore,
} from '../utilities.shared';
import { UserContactComponent } from './components/user-contact.component';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
  SoftlineObjectService,
  StoreModule,
} from '@softline/core';
import { SOFTLINE_API_USER_CONTACT } from './user-contact.api';

export const userContactProviders: (Provider | ImportedNgModuleProviders)[] = [
  {
    provide: SOFTLINE_CONFIG_SETTINGS,
    multi: true,
    useValue: {
      key: SOFTLINE_SETTINGS_USER_CONTACT,
      component: UserContactComponent,
      title: '#UTILITIES.SETTINGS.USER_CONTACT.TITLE',
      icon: 'fa-regular fa-user',
    },
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_USER_CONTACT,
      feature: UserContactStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineObjectService(service, SOFTLINE_API_USER_CONTACT),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    })
  ),
];
