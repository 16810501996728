import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, Dictionary, SOFTLINE_SERVICE_HTTP, SOFTLINE_SERVICE_UUID} from '@softline/core';
import {ArtikelPreis} from '../types/artikel-preis';
import {SOFTLINE_API_ITEM_PRICE} from '../artikel.api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface LoadPriceParameters {
  itemId: number,
  amount: number,
  selectedOptions?: Dictionary<unknown>;
}

@Injectable()
export class ArtikelPreisService {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string
  ) {}

  load(params: LoadPriceParameters[]): Observable<ArtikelPreis[]> {
    return this.service.create<LoadPriceParameters[], ArtikelPreis[]>({path: SOFTLINE_API_ITEM_PRICE}, params)
      .pipe(
        map((o) => o.map(p => ({...p, id: p.itemId})))
      );
  }
}
