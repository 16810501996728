import {Provider} from '@angular/core';
import {SOFTLINE_CONFIG_DASHBOARD_COMPONENT} from '@softline/application';
import {KundeninfoWidgetComponent} from './kundeninfo-widget.component';

export function provideKundeninfoWidget(): Provider[] {
  return [{
    provide: SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
    multi: true,
    useValue: {
      component: KundeninfoWidgetComponent,
      permission: 'KONTENABFRAGE.KONTOINFO.WIDGET'
    }
  }]
}
