import { Pipe, PipeTransform } from "@angular/core";
import { FilterConfig } from "@softline/application";

@Pipe({
  name: 'filterPropertyName',
  standalone: true,
  pure: true
})
export class FilterPropertyNamePipe implements PipeTransform {

  constructor() {
  }

  transform(property: string | null | undefined, config: FilterConfig | undefined): string {
    if(!property)
      return '';
    const propertyConfig = config?.properties.find(o => o.property === property);
    return propertyConfig?.title ?? property;
  }

}
