import { Pipe, PipeTransform, QueryList } from '@angular/core';
import { equals } from "@softline/core";

@Pipe({
  name: 'filterBy',
  standalone: true,
  pure: true
})
export class FilterByPipe implements PipeTransform {
  transform<T extends object, P extends keyof T, U extends T[P]>(
    array: readonly T[] | QueryList<T> | null | undefined,
    property: P,
    value?: U
  ): T[] {
    if (array instanceof QueryList) array = array.toArray();

    if (!array || !Array.isArray(array)) return [];

    const returnValue = array.filter(
      (o) => equals(o[property],  value)
    );
    return returnValue;
  }
}
