import { Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { equals } from '@softline/core';
import { RadioGroup, RadioOption } from '../radio-option';

let uniqueIndex = 0;

@Component({
  selector: 'soft-radio-option',
  templateUrl: './radio-option.component.html',
  styleUrls: ['./radio-option.component.scss'],
  providers: [
    {
      provide: RadioOption,
      useExisting: forwardRef(() => RadioOptionComponent),
      multi: true,
    },
  ],
})
export class RadioOptionComponent extends RadioOption implements OnInit {
  labelName = `soft-radio-option-${++uniqueIndex}`;

  private _checked = false;
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    this.setChecked(value);
  }

  @Input() value: any;
  @Input() labelPosition: 'before' | 'after' = 'after';

  @Input() disabled = false;

  constructor(@Optional() readonly radioGroup: RadioGroup) {
    super();
  }

  ngOnInit(): void {
    if (this.radioGroup)
      this._checked = equals(this.radioGroup.value, this.value);
  }

  setChecked(value: boolean, propagateToRadioGroup: boolean = true): void {
    if (value === this._checked || this.disabled || this.radioGroup?.readonly)
      return;
    this._checked = value;

    if (propagateToRadioGroup && value && this.radioGroup)
      this.radioGroup.value = this.value;
  }
}
