import {importProvidersFrom,} from '@angular/core';
import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  StoreModule,
} from '@softline/core';
import {
  SelectedCustomerStore,
  SOFTLINE_FEATURE_CUSTOMER_QUERY,
  SOFTLINE_PERMISSION_CUSTOMER_QUERY,
} from './kundenabfrage.shared';
import {SOFTLINE_API_CUSTOMER} from './kundenabfrage.api';

export const kundenabfrageRoutes: Routes = [
  {
    path: 'kundenabfrage',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/customer-query.component').then(
            (o) => o.CustomerQueryComponent
          ),
        children: [
          {
            path: ':id',
            loadComponent: () =>
              import(
                './components/customer-detail/customer-detail.component'
              ).then((o) => o.CustomerDetailComponent),
          },
          {
            path: '',
            loadComponent: () =>
              import(
                './components/customer-input/customer-input.component'
              ).then((o) => o.CustomerInputComponent),
            pathMatch: 'full',
          },
          { path: '**', redirectTo: '' },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_CUSTOMER_QUERY },
  },
];
