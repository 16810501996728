import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export interface Deactivateable {
  canDeactivate(): Promise<boolean>
}

@Injectable()
export class OnDeactivateGuard  {
  async canDeactivate(
    component: Deactivateable,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean> {
    const nextURLWithoutSelection = nextState?.url.split(';')[0];

    if (currentState.url === nextURLWithoutSelection || nextState?.url.startsWith('/materiallieferschein-scan'))
      return true;

    return await component.canDeactivate();
  }
}
