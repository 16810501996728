import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
} from '../../store/factories';
import { HttpResourceLocation } from '../specialized/http/http.location';
import { StoreFeature } from '../../store/store';
import { InjectionToken } from '@angular/core';
import { DownloadResourceService } from '../abstraction';
import * as ProgressStore from '../../store/specialized/remote/progress.store';
import { handleProgressState } from '../../store/specialized/remote/progress.store';
import * as ActionStore from '../../store/specialized/remote/action.store';
import * as SubscriptionStore from '../../store/specialized/remote/subscription.store';
import { handleSubscriptionState } from '../../store/specialized/remote/subscription.store';
import {
  ActionState,
  handleObservableActionState,
} from '../../store/specialized/remote/action.store';
import { SOFTLINE_SERVICE_UUID } from '../../core.shared';
import { ConnectionResourceLocation } from '../specialized/http/connection.location';
import { isDefined } from '../../functions/is-defined.function';
import { lastValueFrom, Observable } from 'rxjs';
import { RequestEvent } from '../request';

export const SOFTLINE_STORE_CONNECTION_DOWNLOAD_SERVICE = new InjectionToken<
  DownloadResourceService<ConnectionResourceLocation>
>('SOFTLINE_STORE_CONNECTION_DOWNLOAD_SERVICE');
export const SOFTLINE_STORE_HTTP_DOWNLOAD_SERVICE = new InjectionToken<
  DownloadResourceService<HttpResourceLocation>
>('SOFTLINE_STORE_DOWNLOAD_SERVICE');

export interface DownloadParameters {
  location: ConnectionResourceLocation | HttpResourceLocation;
  filename: string;
  body?: unknown;
  token?: string;
}

export interface DownloadResult {
  filename: string;
  content: Blob;
}

export interface State
  extends ProgressStore.State,
    ActionStore.State,
    SubscriptionStore.State {
  downloads: DownloadResult[];
}

export const mutations = {
  progress: ProgressStore.mutations,
  action: ActionStore.mutations,
  subscription: SubscriptionStore.mutations,
  add: createMutation<State, { filename: string; content: Blob }>('add'),
  clear: createMutation<State>('clear'),
};

export const actions = {
  ...SubscriptionStore.actions,
  download: createAction<State, DownloadParameters, DownloadResult>('download'),
};

export const getters = {
  progress: ProgressStore.getters,
  action: ActionStore.getters,
  subscription: SubscriptionStore.getters,
  downloading: createGetter<State, boolean>('downloading'),
  downloaded: createGetter<State, boolean>('downloaded'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    ...ProgressStore.feature.initialState,
    ...ActionStore.feature.initialState,
    ...SubscriptionStore.feature.initialState,
    downloads: [],
  },
  mutations: [
    ...ProgressStore.feature.mutations,
    ...ActionStore.feature.mutations,
    ...SubscriptionStore.feature.mutations,
    mutate(mutations.add, ({ state, params }) => ({
      ...state,
      downloads: [...state.downloads, params],
    })),
    mutate(mutations.clear, ({ state, params }) => ({
      ...state,
      downloads: [],
    })),
  ],
  getters: [
    ...ProgressStore.feature.getters,
    ...ActionStore.feature.getters,
    ...SubscriptionStore.feature.getters,
    select(getters.downloading, ({ get, featureName, params }) => {
      const action = [actions.download.name];
      const states: ActionState[] = ['pending', 'processing'];
      return get(featureName, getters.action.hasState, {
        actions: action,
        states,
        id: params,
      });
    }),
    select(getters.downloaded, ({ get, featureName, params }) => {
      const action = [actions.download.name];
      const states: ActionState[] = ['succeeded', 'failed'];
      return get(featureName, getters.action.hasState, {
        actions: action,
        states,
        id: params,
      });
    }),
  ],
  actions: [
    ...SubscriptionStore.feature.actions,
    on(actions.download, async ({ commit, featureName, params, injector }) => {
      const filename = params.filename;
      const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
      let progress$: Observable<RequestEvent<Blob>>;
      if (isHttpLocation(params.location)) {
        const service = injector.get(SOFTLINE_STORE_HTTP_DOWNLOAD_SERVICE);
        progress$ = service.download(params.location, params.body);
      } else {
        const service = injector.get(
          SOFTLINE_STORE_CONNECTION_DOWNLOAD_SERVICE
        );
        progress$ = service.download(params.location, params.body);
      }

      const result$ = handleProgressState(
        progress$,
        featureName,
        commit,
        token
      );
      const subscription$ = handleSubscriptionState(
        result$,
        featureName,
        commit,
        token
      );
      const result = await lastValueFrom(
        handleObservableActionState(
          subscription$,
          featureName,
          commit,
          actions.download.name,
          token
        )
      );
      const download = { filename, content: result };
      commit(featureName, mutations.add, download);
      return download;
    }),
  ],
};

function getFileName(path: string): string {
  const expr = /\/(.+?)$/g;
  const match = expr.exec(path);
  if (match) return match[1];
  return path;
}

function isHttpLocation(location: unknown): location is HttpResourceLocation {
  return isDefined((location as HttpResourceLocation)?.url);
}
