import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule, Store, SOFTLINE_CONFIG_DEFAULT_LANGUAGE, SOFTLINE_STORE_FEATURES } from '@softline/core';
import { TranslatePipe } from './pipes/translate.pipe';
import {
  SOFTLINE_CONFIG_TRANSLATION_BASE_PATH,
  SOFTLINE_FEATURE_TRANSLATION,
} from './i18n.shared';
import { TranslationLoader } from './services/translation-loader';
import { HttpTranslationLoader } from './services/http-translation.loader';
import * as TranslationStore from './translation.store';
import { TranslationStore2 } from './translation.store2';

@NgModule({
  declarations: [TranslatePipe],
  imports: [CommonModule],
  exports: [TranslatePipe],
})
export class I18nModule {
  static forRoot(): ModuleWithProviders<I18nRootModule> {
    return {
      ngModule: I18nRootModule,
    };
  }
}

@NgModule({
  declarations: [],
  imports: [
    I18nModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_TRANSLATION,
      feature: TranslationStore.feature,
    }),
  ],
  exports: [I18nModule],
  providers: [
    TranslationStore2,
    TranslatePipe,
    { provide: SOFTLINE_STORE_FEATURES, useExisting: TranslationStore2, multi: true },
    { provide: SOFTLINE_CONFIG_DEFAULT_LANGUAGE, useValue: 'de' },
    { provide: TranslationLoader, useClass: HttpTranslationLoader },
    { provide: SOFTLINE_CONFIG_TRANSLATION_BASE_PATH, useValue: './assets' },
  ],
})
export class I18nRootModule {
  constructor(
    private store: Store,
    @Inject(SOFTLINE_CONFIG_DEFAULT_LANGUAGE) defaultLanguage: string
  ) {
    store.dispatch(
      SOFTLINE_FEATURE_TRANSLATION,
      TranslationStore.actions.loadLanguage,
      { language: defaultLanguage }
    );
  }
}
