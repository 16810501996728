import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {combineLatestWith, map, Observable} from 'rxjs';
import {
  BackNavigable,
  BackNavigationService,
  CommandStore,
  FilterAndSortComponent,
  FilterConfig, handleRequestErrors,
  Refreshable,
  RefreshService,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  SortConfig,
  TitleStore
} from '@softline/application';
import {base64Encode, Filter, FilterService, Sort, SortService, Store} from '@softline/core';
import {
  SOFTLINE_CONFIG_PMS_STATUSABFRAGE_SEND_NOTIFICATION_COMPONENT,
  SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
  SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER
} from '../../statusabfrage.shared';
import {PmsPersonalStore} from '../../store/personal.store';
import {DateNavigatorService} from '../../services/date-navigator.service';
import {AnzahlMitarbeiterUrlaubPipe} from '../../pipes/anzahl-mitarbeiter-urlaub.pipe';
import {PmsAbteilungStore} from '../../store/abteilung.store';
import {AnzahlMitarbeiterGesamtPipe} from "../../pipes/anzahl-mitarbeiter-gesamt.pipe";
import {AbteilungCardComponent} from '../../components/abteilung-card/abteilung-card.component';
import {AbteilungUebersicht} from '../../types/abteilung';
import {AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION} from '@softline/auth';
import {SOFTLINE_PERMISSION_SEND_NOTIFICATION} from '@softapps/allgemein/send-notification';
import {AbteilungenService} from '../../services/abteilungen.service';

@Component({
    selector: 'soft-abteilungen',
    standalone: true,
    templateUrl: './abteilungen.component.html',
    styleUrls: ['./abteilungen.component.scss'],
    providers: [DatePipe],
  imports: [CommonModule, UiCoreModule, RouterLink, FilterAndSortComponent, AnzahlMitarbeiterUrlaubPipe, AnzahlMitarbeiterGesamtPipe, AbteilungCardComponent]
})
export class AbteilungenComponent implements OnInit, OnDestroy, BackNavigable, Refreshable {

  private unregisterDateChange?: () => void;

  readonly abteilungen$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
    PmsAbteilungStore.getters.all
  );

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
    PmsAbteilungStore.getters.loading
  );

  readonly sort$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
    PmsAbteilungStore.getters.sort
  );

  readonly filter$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
    PmsAbteilungStore.getters.filter
  );

  readonly filteredItems$: Observable<AbteilungUebersicht[]> = this.abteilungen$.pipe(
    combineLatestWith(this.sort$, this.filter$),
    map(([values, sort, filter]) =>
      this.sortService.sort(this.filterService.filter(values, filter), sort)
    )
  );

  filterConfig: FilterConfig = {
    properties: [
      {
        property: 'abteilung',
        title: 'Abteilung ID',
        params: {
          formatFn: (o: any) => `${o.id}`
        }
      },
      {
        property: 'abteilung.bezeichnung',
        title: 'Abteilung-Bezeichnung',
        params: {
          formatFn: (o: any) => `${o}`
        }
      },
      {
        property: 'mitarbeiterGesamt',
        title: 'Anzahl Mitarbeiter Gesamt',
      },
      {
        property: 'anwesend',
        title: 'Anwesende Mitarbeiter'
      }
    ]
  };

  sortConfig: SortConfig = {
    properties: [
      {
        property: 'abteilung.id',
        title: 'Abteilung ID'
      },
      {
        property: 'abteilung.bezeichnung',
        title: 'Abteilungs-Bezeichnung'
      },
      {
        property: 'mitarbeiterGesamt',
        title: 'Anzahl Mitarbeiter Gesamt'
      },
      {
        property: 'anwesend',
        title: 'Anwesend'
      }
    ]
  };

  readonly trackByAbteilungId = (_: number, abteilung: AbteilungUebersicht) => abteilung.id;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly filterService: FilterService,
    private readonly sortService: SortService,
    private readonly datePipe: DatePipe,
    private readonly dateNavigatorService: DateNavigatorService,
    private readonly backNavigationService: BackNavigationService,
    private readonly refreshService: RefreshService,
    private readonly abteilungenService: AbteilungenService,
  ) {}

  async ngOnInit(): Promise<void> {
    // API Request


    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      'Abteilungen'
    );

    this.backNavigationService.set(this);
    this.refreshService.add(this);

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet, {
        name: AbteilungenComponent,
        commands: [
          {
            name: 'Aktualisieren',
            class: 'menu action-menu action-menu-top',
            icon: 'fa-regular fa-arrows-rotate',
            execute: async () => {
                await this.onNavigatorChange(this.dateNavigatorService.date)
            },
          },
          {
            name: 'Benachrichtigen',
            class: 'menu action-menu action-menu-top',
            icon: 'fa-regular fa-envelope',
            isVisible: this.store.observe(
              SOFTLINE_FEATURE_AUTHORIZATION,
              AuthorizationStore.getters.authorized,
              SOFTLINE_PERMISSION_SEND_NOTIFICATION
            ),
            execute: async () => {
              await this.router.navigate(['/send-notification'], {
                queryParams: { from: base64Encode(JSON.stringify({ url: ['/statusabfrage'], extras: {} })) },
                fragment: SOFTLINE_CONFIG_PMS_STATUSABFRAGE_SEND_NOTIFICATION_COMPONENT
              });
            }
          }
        ]
      }
    );

    this.unregisterDateChange = this.dateNavigatorService.onDateChange(async (date) => await this.onNavigatorChange(date));
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['./../'], { relativeTo: this.activatedRoute });
  }

  ngOnDestroy(): void {
    this.unregisterDateChange?.();

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      AbteilungenComponent,
    );

    this.store.commit(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
      PmsPersonalStore.mutations.setFilter,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
      PmsPersonalStore.mutations.setSort,
      null
    );

    this.backNavigationService.set(undefined);
    this.refreshService.remove(this);
  }

  async refresh(): Promise<void> {
    this.onNavigatorChange(this.dateNavigatorService.date);
  }

  async onNavigatorChange(date: string) {
    try {
      const stichdatum = this.datePipe.transform(date, 'yyyy-MM-dd');
      await this.abteilungenService.loadAbteilungen(stichdatum);
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  async onFilterChange(filter: Filter | null): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
      PmsAbteilungStore.mutations.setFilter,
      filter
    );
  }

  async onSortChange(sort: Sort | null): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
      PmsAbteilungStore.mutations.setSort,
      sort
    );
  }
}
