import { Preis } from './preis';
import { Dictionary } from '@softline/core';

export interface ArtikelPreis {
  id: string | number;
  itemId: number,
  listPrice: Preis,
  customerPrice: Preis,
  discountPrice: Preis,
  amount: number,
  discountTitle: string,
  discountPercentage: number[],
  options: Dictionary<unknown>
}
