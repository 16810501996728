import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@softline/core';
import {defer, filter, from, map, Observable, switchMap, tap} from 'rxjs';
import {RemoteConfigStore, SOFTLINE_FEATURE_REMOTE_CONFIG} from '@softline/application';
import {SOFTLINE_FEATURE_MDE_RETOUREN} from '../retouren.shared';
import {RetourenStore} from '../store/retouren.store';
import {RetourenKopf} from '../data/retouren-kopf';
import {extractRemoteConfiguration, RetourenRemoteConfiguration} from '../utils/extract-remote-configuration';

export const MODULE_NAME = 'mde-retouren'

type RetourenData = { kopf?: RetourenKopf | null; } & RetourenRemoteConfiguration

@Injectable()
export class RetourenResolver  {

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RetourenData> {
    return this.store.observe(
      SOFTLINE_FEATURE_REMOTE_CONFIG,
      RemoteConfigStore.getters.data,
    ).pipe(
      filter(o => !!o && !!o?.modules),
      map(data => data?.modules?.find(o => o.module === MODULE_NAME)),
      switchMap((module) =>
        defer(() =>
          from(
            this.store.dispatch(
              SOFTLINE_FEATURE_MDE_RETOUREN,
              RetourenStore.actions.loadKopf
            )
          )
        ).pipe(
          map(kopf => ({ module, kopf })),
        )
      ),
      tap(({ module, kopf }) => console.log('resolver:',  module, kopf)),
      map(({ module, kopf }) => ({
        kopf,
        ...extractRemoteConfiguration(module?.properties ?? [])
      })),
      tap(retourenData => console.log('RetourenData from Resolver: ', retourenData))
    )
  }
}
