import { createGetter, createMutation, mutate, select } from '../../factories';
import { StoreFeature } from '../../store';
import { Dictionary } from '../../../types/dictionary';

export interface State {
  params: Dictionary<unknown>;
}

export const mutations = {
  add: createMutation<State, { key: string; params: unknown }>('add params'),
  clear: createMutation<State>('clear params'),
};

export const getters = {
  params: createGetter<State, unknown[]>('params'),
  param: createGetter<State, unknown, string>('param'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    params: {},
  },
  mutations: [
    mutate(mutations.add, ({ state, params }) => {
      if (state.params[params.key])
        throw new Error(
          `ParamsStore - add action: There is already an param with key ${params.key}`
        );
      const p = { ...state.params, [params.key]: params.params };
      return { ...state, params: p };
    }),
    mutate(mutations.clear, ({ state, params }) => {
      return { ...state, keys: [] };
    }),
  ],
  getters: [
    select(getters.params, ({ state }) => Object.values(state.params)),
    select(getters.param, ({ state, params }) => state.params[params]),
  ],
  actions: [],
};
