export class GroupIterator<TKey, TElement> implements Iterator<TElement> {
  constructor(
    private iterator: Iterator<TElement>,
    private keySelector: (e: TElement) => TKey,
    private comparer: (first: TKey, second: TKey) => boolean,
    private key: TKey
  ) {}

  next(
    ...args: [] | [undefined]
  ): IteratorYieldResult<TElement> | IteratorReturnResult<any> {
    let result = this.iterator.next();
    while (
      !result.done &&
      !this.comparer(this.keySelector(result.value), this.key)
    )
      result = this.iterator.next();
    return result;
  }
}
