import { Component, effect, forwardRef, inject, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaControl, MetaControlNameDirective, MetaFormatPipe, MetaLabelComponent } from '@softline/dynamic';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorBase, ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule } from '@softline/ui-core';
import { isDefined, Store } from '@softline/core';

@Component({
  selector: 'soft-meta-query-bar-item',
  standalone: true,
  imports: [CommonModule, UiCoreModule, MetaLabelComponent, MetaFormatPipe, MetaControlNameDirective],
  templateUrl: './meta-query-bar-item.component.html',
  styleUrl: './meta-query-bar-item.component.scss',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MetaQueryBarItemComponent), multi: true}
  ]
})
export class MetaQueryBarItemComponent<T> extends ControlValueAccessorBase {

  private store = inject(Store);

  meta = input.required<MetaControl<unknown>>();
  name = input.required<string>();
  value = signal<T | null>(null);
  valueInput = input<T | null>(null, {alias: 'value'});
  valueInputChange = output<T>({alias: 'valueChange'});

  valueInputEffect = effect(() => {
    const valueInput = this.valueInput();
    if(isDefined(valueInput))
      this.value.set(valueInput);
  }, {allowSignalWrites: true})

  override writeValue(obj: any): void {
    this.value.set(obj);
  }

  async onClick(): Promise<void> {
    let result: any
    const meta = this.meta();
    switch (meta.type) {
      case 'date':
        result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.date, {dismiss: true})
        break;
      case 'select':
        result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.choose(), {
          title: meta.title,
          dismiss: true,
          options: meta.options
        })
        break;
    }
    if(!result || result === 'DISMISSED')
      return;
    this.value.set(result);
    this.onChange(result);
  }
}
