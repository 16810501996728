import { Component, Inject, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from "@softline/dynamic";

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ktostamm-kunde-subnummer.detail-view.html',
  styleUrls: ['./ktostamm-kunde-subnummer.detail-view.scss'],
})
export class KtostammKundeSubnummerDetailView implements FieldOkItemComponent<any>{

  @Input() item: any;

  constructor() {
  }
}
