import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@softline/core';
import {catchError, defer, EMPTY, filter, from, map, Observable, switchMap, tap, throwError} from 'rxjs';
import {RemoteConfigStore, SOFTLINE_FEATURE_REMOTE_CONFIG} from '@softline/application';
import {SOFTLINE_FEATURE_MDE_WARENVERPROBUNG} from '../warenverprobung.shared';
import {WarenverprobungStore} from '../store/warenverprobung-store';
import {MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR} from '@softline/ui-core';

const MODULE_NAME = 'mde-warenverprobung'

@Injectable()
export class WarenverprobungResolver  {

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<unknown> {
    return this.store.observe(
      SOFTLINE_FEATURE_REMOTE_CONFIG,
      RemoteConfigStore.getters.data,
    ).pipe(
      filter(o => !!o && !!o?.modules),
      map(data => data?.modules?.find(o => o.module === MODULE_NAME)),
      switchMap((module) =>
        defer(() => {
            return from(
              this.store.dispatch(
                SOFTLINE_FEATURE_MDE_WARENVERPROBUNG,
                WarenverprobungStore.actions.loadKopf
              )
            )
          }
        ).pipe(
          map(kopf => ({ module, kopf })),
        )
      ),
      tap((prop) => console.log('resolver:', prop)),
      catchError(async (e) => {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.error,
          'Fehler beim Laden des Belegkopfs! Bitte Exzess prüfen!'
        );

        return EMPTY;
      })
    )
  }
}
