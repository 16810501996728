import { Component, Inject, OnDestroy, OnInit, Optional } from "@angular/core";
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ConnectionError, isDefinedNotEmpty, Store, Trace, TelemetryService, UnauthorizedError } from "@softline/core";
import {
  JwtAuthenticationStore,
  SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION
} from "@softline/auth";
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from "@softline/ui-core";
import { Router, RouterModule } from "@angular/router";
import { LoginConfig, LoginLink, SOFTLINE_CONFIG_LOGIN, SOFTLINE_CONFIG_LOGIN_LINKS } from "../../login.shared";
import {
  ApplicationSettings,
  handleRequestErrors,
  ScannerStore,
  Settings,
  SettingsStore,
  SOFTLINE_CONFIG_SETTINGS,
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_SETTINGS, SOFTLINE_SETTINGS_APPLICATION
} from "@softline/application";
import { CommonModule } from "@angular/common";
import { firstValueFrom, map, skip, Subscription } from "rxjs";

const NOT_AUTHORIZED_ERROR_CORE = 401;
const NO_RESPONSE_ERROR_CODE = 0;

@Component({
  standalone: true,
  selector: 'soft-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [CommonModule, RouterModule, ReactiveFormsModule, UiCoreModule],
  providers: [
    TelemetryService
  ]
})
export class LoginComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  loading = false;
  loginForm: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(undefined, Validators.required),
    password: new UntypedFormControl(undefined, Validators.required),
    remember: new UntypedFormControl(false),
  });

  constructor(
    private store: Store,
    private router: Router,
    @Inject(SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE) private forwardRoute: string,
    @Inject(SOFTLINE_CONFIG_SETTINGS) private settings: Settings[],
    @Inject(SOFTLINE_CONFIG_LOGIN) public config: LoginConfig,
    @Optional() @Inject(SOFTLINE_CONFIG_LOGIN_LINKS) readonly links: LoginLink[],
  ) { }

  ngOnInit(): void {
    if(this.store.isRegistered(SOFTLINE_FEATURE_SCANNER))
      this.subscription = this.store.observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
        .pipe(
          skip(1)
        ).subscribe(async o => {
          try {
            if(o?.labelType !== 'qrcode' || !o?.data)
              return;
            const value = JSON.parse(o?.data) as {username: string, password: string};
            if(!isDefinedNotEmpty(value.username) || !isDefinedNotEmpty(value.password))
              return;
            await this.login(value.username, value.password, true);
          }
          catch (e) {
            console.log('[LoginComponent] Failure processing Login by Scan', e)
          }
        })
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  @Trace({callArgs: false})
  async submit(value: any): Promise<void> {
    await this.login(value.username, value.password, value.remember);
  }

  @Trace({callArgs: false})
  async login(
    username: string,
    password: string,
    stayAuthenticated: boolean
  ): Promise<void> {
    try {
      this.loading = true;
      const success = await this.store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.authenticate,
        { username, password, stayAuthenticated }
      );
      if (!success) return;
      const forwardRoute = this.store.get(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.getters.forwardRoute
      );
      const startupRoute = this.store.get(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<Partial<ApplicationSettings>>(),
        SOFTLINE_SETTINGS_APPLICATION
      )?.startup;
      await this.router.navigate([forwardRoute ?? startupRoute ?? this.forwardRoute]);
    } catch (e) {
      if (e instanceof UnauthorizedError)
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.warning,
          '#LOGIN.MESSAGES.FAILED.UNAUTHORIZED'
        );
      else if (e instanceof ConnectionError)
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.warning,
          '#LOGIN.MESSAGES.FAILED.NO_CONNECTION'
        );
      else handleRequestErrors(this.store, e);
    } finally {
      this.loading = false;
    }
  }

  async openSettings(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<string>(),
      {
        title: '#LOGIN.SETTINGS',
        options: await firstValueFrom(
          this.store.get(SOFTLINE_FEATURE_SETTINGS, SettingsStore.getters.visibleComponents$)
            .pipe(map(o => o.map(p => ({ value: p.key, icon: p.icon, label: p.title }))))
        ),
        dismiss: true,
      }
    );
    if (result === 'DISMISSED') return;

    await this.router.navigate(['login', 'settings'], { fragment: result });
  }

  async onScanClick(): Promise<void> {
    try{
      await this.store.dispatch(SOFTLINE_FEATURE_SCANNER, ScannerStore.actions.scan, {});
    }
    catch (e) {
      handleRequestErrors(this.store, e)
    }
  }
}
