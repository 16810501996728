<div class="flex w-full justify-between flex-wrap">
  <div class="flex flex-col">
    <h1 class="text-2xl font-bold">
      {{ '#HAFTRUECKLASS.PAGES.LIST.HEADER' | translate }}
    </h1>
    <span class="text-sm color-light"
      >{{ openItemsLength$ | async }}
      {{ '#HAFTRUECKLASS.PAGES.LIST.RESULTS' | translate }}</span
    >
  </div>
  <soft-input
    class="w-full sm:w-[30vw]"
    placeholder="{{ '#HAFTRUECKLASS.PAGES.LIST.FILTER' | translate }}"
    (valueChange)="filter$.next($event ?? '')"
  ></soft-input>
</div>
<div class="flex w-full flex-row mt-4 items-center">
  <span class="text-sm mr-3 font-bold">{{
    '#HAFTRUECKLASS.PAGES.LIST.QUERY' | translate
  }}</span>
  <soft-dynamic-filter
    [definition]="$any(queryDefinition$ | async)"
    [value]="$any(query$ | async)"
    (valueChange)="onQueryChange($event ?? {})"
  ></soft-dynamic-filter>
</div>
<hr class="my-4" />
<div class="w-full overflow-x-auto">
  <soft-dynamic-table
    [selectionMode]="'single'"
    [definition]="$any(listDefinition$ | async)"
    [selectedItem]="selected$ | async"
    (selectedItemChange)="selected$.next($event)"
    [rows]="openItems$ | async | take: 100"
    [loading]="(loading$ | async) ?? false"
    tableClass="soft-table no-border"
    class="body-border divided"
  ></soft-dynamic-table>
</div>
<div
  *ngIf="!(loading$ | async) && (loaded$ | async) && (total$ | async) === 0"
  class="soft-container center"
>
  {{ '#HAFTRUECKLASS.PAGES.LIST.NO_DATA' | translate }}
</div>
