import { AbstractControl } from '@angular/forms';
import { ValidationMessage } from '@softline/core';
import { ClassRule, FormatRule, Rule, ValidationRule } from '../data/rules';
import {
  resolveClassRule,
  resolveRule,
  resolveValidationRule,
} from '../functions/resolve-rule.function';
import { Injector } from "@angular/core";

// @dynamic
export class RuleHelper {
  static getValidator(
    rules: ValidationRule[],
    injector: Injector
  ): (control: AbstractControl) => { [key: string]: any } | null {
    return (control) => {
      const errors: ValidationMessage[] = [];
      let isValid = true;
      for (const rule of rules) {
        const validation = resolveValidationRule(rule, control.value, injector);
        isValid = isValid && validation.isValid;
        errors.push(...validation.messages);
      }
      return errors.length > 0 ? { rule: { isValid, messages: errors } } : null;
    };
  }

  static getBoolean(rule: boolean | Rule, value: any, injector: Injector): boolean {
    if (typeof rule === 'boolean')
      return rule;

    return resolveRule(rule, value, injector);
  }

  static getFormat(rule: string | FormatRule, value: any, injector: Injector): string | undefined {
    if (typeof rule === 'string') return rule;

    for (const subRule of rule.rules)
      if (resolveRule(subRule.rule, value, injector)) return subRule.format;
    return rule.default;
  }

  static getClassList(
    rule: string | (string | ClassRule)[],
    value: any,
    injector: Injector
  ): string[] {
    const result: string[] = [];
    if (!rule) return result;
    if (typeof rule === 'string')
      return rule.split(' ');

    for (const c of rule) {
      if (typeof c === 'string') result.push(...c.split(' '));
      else result.push(...resolveClassRule(c, value, injector).split(' '));
    }
    return result;
  }
}
