import {
  createAction,
  CustomStoreAddOn,
  FilterCollectionStore,
  on,
  RemoteCollectionStore,
  SortCollectionStore,
  StoreBuilder
} from "@softline/core";
import {Warenuebernahme} from "../types/warenuebernahme";
import {lastValueFrom} from "rxjs";
import {WarenuebernahmeService} from "../services/warenuebernahme-service";


type State = RemoteCollectionStore.State<Warenuebernahme>

const remoteCollectionStore = RemoteCollectionStore.create<Warenuebernahme>();

const mutations = {}

const getters = {}

const actions = {
  abschliessen: createAction<State, string, void>('wwsWarenuebernahme/actions/abschliessen'),
  sperren: createAction<State, string, void>('wwsWarenuebernahme/actions/sperren'),
  oeffnen: createAction<State, string, void>('wwsWarenuebernahme/actions/oeffnen')
}

const WwsWarenuebernahmeStoreExtension: CustomStoreAddOn<State, typeof mutations, typeof getters, typeof actions> = {
  mutations,
  getters,
  actions,
  feature: {
    actions: [
      on(actions.abschliessen, async ({ params, featureName, injector, commit }) => {
        const service = injector.get(WarenuebernahmeService);

        const result = await lastValueFrom(service.abschliessen(params));

        commit(featureName, remoteCollectionStore.mutations.remove, result);
      }),
      on(actions.sperren, async ({ params, featureName, injector, commit }) => {
        const service = injector.get(WarenuebernahmeService);

        const result = await lastValueFrom(service.sperren(params));

        commit(featureName, remoteCollectionStore.mutations.addOrUpdate, result);
      }),
      on(actions.oeffnen, async ({ params, get,  featureName, injector, commit }) => {
        const service = injector.get(WarenuebernahmeService);

        const result = await lastValueFrom(service.oeffnen(params));

        if (get(featureName, remoteCollectionStore.getters.entity, result.id)) {
          commit(featureName, remoteCollectionStore.mutations.update, result);
        }
      })
    ],
    mutations: []
  },
}

export const WwsWarenuebernahmeStore = new StoreBuilder(remoteCollectionStore)
  .add(FilterCollectionStore.create<Warenuebernahme>())
  .add(SortCollectionStore.create<Warenuebernahme>())
  .add(WwsWarenuebernahmeStoreExtension)
  .value;
