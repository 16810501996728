import { ImportedNgModuleProviders, importProvidersFrom, Provider } from "@angular/core";
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_QUERY_SERVICE,
  SoftlineQueryService,
  StoreModule
} from "@softline/core";

import { SOFTLINE_CONFIG_DASHBOARD_COMPONENT } from "@softline/application";
import { GesamtabfrageWidget } from "./gesamtabfrage/gesamtabfrage.widget";
import { SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG_WIDGET } from "../gesamtabfrage.shared";
import { BelegStore } from "../store/beleg.store";
import { SOFTLINE_API_GESAMTAFRAGE_QUERY } from "../gesamtabfrage.api";

export const gesamtabfrageWidgetProviders: (Provider | ImportedNgModuleProviders)[] = [
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG_WIDGET,
      feature: BelegStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_QUERY_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineQueryService(service, SOFTLINE_API_GESAMTAFRAGE_QUERY),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ],
    }),
  ),
  {provide: SOFTLINE_CONFIG_DASHBOARD_COMPONENT, useValue: GesamtabfrageWidget, multi: true}
];
