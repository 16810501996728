import { ENVIRONMENT_INITIALIZER, importProvidersFrom, inject, Provider, StaticProvider } from "@angular/core";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import {
  ApplicationStore,
  CommandStore,
  MenuItemStore2,
  SOFTLINE_FEATURE_APPLICATIONS,
  SOFTLINE_FEATURE_COMMANDS
} from '@softline/application';
import {default as de} from '../i18n/de.json';
import { SOFTLINE_PERMISSION_DASHBOARD } from "./dashboard.shared";
import { DashboardGuard } from "./guards/dashboard.guard";
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from "@softline/auth";

export const dashboardProviders: Provider[] = [
  DashboardGuard,
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'dashboard',
        language: 'de',
        translations: de
      });
      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: '#DASHBOARD.APP',
        icon: 'fa-regular fa-rectangle-list',
        route: '/dashboard',
        permission: SOFTLINE_PERMISSION_DASHBOARD
      });

      const menuItemStore = inject(MenuItemStore2);
      store.observe(
        SOFTLINE_FEATURE_AUTHORIZATION,
        AuthorizationStore.getters.authorized,
        SOFTLINE_PERMISSION_DASHBOARD
      ).subscribe((authorized) => {
        menuItemStore.set('dashboardProviders', [{
          name: 'dashboardRoute',
          type:'route',
          outlet: 'nav-static',
          priority: 2,
          routerLink: ['/dashboard'],
          icon: 'fa-regular fa-rectangle-list',
          visible: authorized,
          title: '#DASHBOARD.APP'
        }]);
      });
    },
    multi: true
  },
]
