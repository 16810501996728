import { ResourceService } from '../../data/abstraction';
import { CRUDParameters, Repository } from '../abstraction';
import { Observable } from 'rxjs';
import { ReadonlyHttpApiRepository } from './readonly-http-api.repository';
import { ConnectionResourceLocation } from '../../data/specialized/http/connection.location';

export class HttpApiRepository<T, TResult> extends ReadonlyHttpApiRepository<TResult> implements Repository<T, TResult> {
  constructor(service: ResourceService<ConnectionResourceLocation>,
              path: string | string[]) {
    super(service, path);
  }

  create(value: T, params?: CRUDParameters | undefined): Observable<TResult> {
    const location: ConnectionResourceLocation = {
      path: this.path,
      pathParams: params?.pathParams,
    };
    return this.service.create(location, value);
  }
  update(value: T, params?: CRUDParameters | undefined): Observable<TResult> {
    const location: ConnectionResourceLocation = {
      path: this.path,
      pathParams: params?.pathParams,
    };
    return this.service.update(location, value);
  }
  patch(patch: Partial<T>, params?: CRUDParameters | undefined): Observable<TResult> {
    const location: ConnectionResourceLocation = {
      path: this.path,
      pathParams: params?.pathParams,
    };
    return this.service.patch(location, patch);
  }
  delete(params?: CRUDParameters | undefined): Observable<TResult> {
    const location: ConnectionResourceLocation = {
      path: this.path,
      pathParams: params?.pathParams,
    };
    return this.service.delete(location);
  }
}
