import { FieldOkInputParams, FieldOkStrategy, FieldOkStrategyOptions } from "./field-ok.strategy";
import { Entity, Store } from "@softline/core";
import { Injector } from "@angular/core";

export class CommonFieldOkStrategy extends FieldOkStrategy<object, Entity> {

  constructor(
    store: Store,
    injector: Injector,
    uuid: () => string,
    options: FieldOkStrategyOptions) {
    super(store, injector, uuid, options);
  }

  override async validate(input: string, options?: FieldOkInputParams<Entity, object>): Promise<object | null> {
    if (input.length <= 0)
      return null;

    let result = await this.executeValidation(input, options?.parameters);
    if(result === 'CANCELED')
      return null;

    if (!Array.isArray(result))
      return result as object;

    result = await this.openDialog(this.options.config.defaultDataView, options);
    if(result === 'DISMISSED' || result === 'CANCELED')
      return null;

    return result as object;
  }
}
