<form class="mt-3" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="px-3">
    <h4 class="text-xl font-semibold mt-3">
      {{ '#SOFTAPPS_CORE.TEMPLATES.SAVE_TEMPLATE' | translate }}
    </h4>
    <div class="flex flex-col mt-4">
      <label class="soft-label soft-label-top required">Name</label>
      <soft-input formControlName="title" softFieldValidation></soft-input>
    </div>

    <soft-template-card
      class="mt-4"
      [definition]="$definition()"
      [template]="{ value: $templateValue() }"
    ></soft-template-card>
  </div>
  <button class="mt-4 h-16 soft-button accent w-full" type="submit">
    {{ '#SOFTAPPS_CORE.TEMPLATES.SAVE' | translate }}
  </button>
</form>
