import { computed, inject, Injectable, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { ListStore2, NestedStore2Feature } from '@softline/core';

type Constructor<T extends {}> = new(...args: any[]) => T;

type ListMixinParams<T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>>
  = InjectionToken<ListStore2<T>> | {store: InjectionToken<TStore>, feature: (o: TStore) => ListStore2<T>};

function hasSubFeature<T extends object, V extends NestedStore2Feature<any> = NestedStore2Feature<any>>(value: object): value is {store: InjectionToken<ListStore2<T>>, feature: (o: V) => ListStore2<T>} {
  return 'store' in value;
}

export const WithList = <T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>, TBase extends Constructor<{}> = Constructor<{}>>(store: ListMixinParams<T, TStore>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class ListMixin extends Base implements OnInit, OnDestroy {

    #store: ListStore2<T>;

    all = computed(() => this.#store.state());
    count = computed(() => this.#store.count());

    constructor(...args: any[]) {
      super(...args);

      if(hasSubFeature(store)) {
        const injectedStore = inject(store.store) as any;
        this.#store = store.feature(injectedStore) as any;
      }
      else
        this.#store = inject(store as any);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
    }

    ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return ListMixin;
}
