export interface Objekt {
  id: string;
  number: string;
  name: string;
  archiveKey?: string;
  geodaten?: {
    gpsbreite: number | null;
    gpslaenge: number | null;
  };
}
