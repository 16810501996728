import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal, UiCoreModule } from '@softline/ui-core';

@Component({
  selector: 'lib-edit-due-date-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './edit-due-date-dialog.component.html',
  styleUrls: ['./edit-due-date-dialog.component.css'],
})
export class EditDueDateDialogComponent implements OnInit, Modal<string> {
  private close!: (result: string) => void;

  dueDate!: string;

  constructor() {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: string) => void): void {
    this.close = handler;
  }

  onSubmit(dueDate: string): void {
    this.close(dueDate);
  }
}
