<form class="mt-2"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      #formRef="ngForm">
  <div class="gap-2 grid">
    <div class="flex flex-col">
      <label class="soft-label top">Suche</label>
      <soft-input formControlName="suche"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Artikel</label>
      <soft-input formControlName="artikelnummer"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Fremdartikelnummer</label>
      <soft-input formControlName="fremdartnr"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Lieferant</label>
      <soft-query-field-ok name="ktostamm.lieferant" formControlName="lieferantennummer" title="Lieferant"></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Hauptgruppe</label>
      <soft-query-field-ok name="arthauptgrp" formControlName="arthauptgrp" title="Hauptgruppe"></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Untergruppe</label>
      <soft-query-field-ok name="artuntergrp" formControlName="artuntergrp" title="Untergruppe"></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">EAN</label>
      <soft-input formControlName="ean"></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Konditionsgruppe</label>
      <soft-query-field-ok name="kondgruppe" formControlName="kondgruppe" title="Konditionsgruppe"></soft-query-field-ok>
    </div>
  </div>
</form>
