import { Scan } from "../data/scan";
import { isDefinedNotEmpty } from "@softline/core";
import { LabelType } from "@softline/application";

export function stringToScan(value: string, timestamp: string): Scan {
  if(!isDefinedNotEmpty(value))
    throw new Error(`stringToScan: value cannot be empty`)
  if(value[0] !== ']' && value[0] !== '}')
    throw new Error(`stringToScan: value must start with an aim`)
  const data = value.slice(3);
  const labelType = getLabelType(value);
  return {
    labelType,
    data,
    timestamp
  }
}

function getLabelType(value: string): LabelType | null {
  const aim = value.slice(0,3);
  const modifier = aim[2];
  const data = value.slice(3)
  switch (aim[1]) {
    case 'A':
      return 'code39';
    case 'C':
      if(modifier === '1')
        return 'ean128'
      return 'code128';
    case 'd':
      return 'datamatrix';
    case 'E':
      if(modifier === '4')
        return 'ean8';
      if(data.length === 12)
        return "upc_a";
      if(data.length === 8)
        return 'upc_e';
      return 'ean13';
    case 'F':
      return 'codabar';
    case 'G':
      return 'code93';
    case 'I':
      return 'itf';
    case 'L':
      return 'pdf417';
    case 'M':
      return 'msi';
    case 'Q':
      return 'qrcode';
    case 'U':
      return 'maxicode';
    case 'z':
      return 'aztec';
    default:
      return null;
  }
}
