import { inject, Injectable, OnDestroy, OnInit, Type } from "@angular/core";
import { Store } from "@softline/core";
import { CommandStore, SOFTLINE_FEATURE_COMMANDS, Template } from "@softline/application";
import { ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";

type Constructor<T> = new(...args: any[]) => T;

export interface LoadTemplateMixinParameters {
  featureName: string,
  group?: string
  dialog: Type<any>
}

export const WithLoadTemplate = <T extends Constructor<{}>>(params: LoadTemplateMixinParameters, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class LoadTemplateMixin extends Base implements OnInit, OnDestroy {

    LOAD_PARAMETERS_COMMAND_SET_NAME = this.constructor.name+'WithLoadTemplate'
    store = inject(Store);

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnInit() {
      if(super['ngOnInit'])
        super['ngOnInit']();
      this.store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.addSet,
        {
          name: this.LOAD_PARAMETERS_COMMAND_SET_NAME,
          commands: [{
            name: 'Vorlage laden',
            icon: 'fa-regular fa-folder-magnifying-glass',
            class: 'menu action-menu action-menu-top',
            execute: () => {
              this.loadTemplate();
            }
          },]
        }
      )
    }

    ngOnDestroy() {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
      this.store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.removeSet,
        this.LOAD_PARAMETERS_COMMAND_SET_NAME
      )
    }

    async loadTemplate(): Promise<void> {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open<Template<any>, any>(),
        {
          component: params.dialog,
          data: {
            featureName: params.featureName,
            group: params.group,
            ...this.createLoadTemplateModalParameters()
          },
          dismiss: true,
        }
      )
      if(result === 'DISMISSED')
        return;
      this.onLoadTemplate(result);
    }

    createLoadTemplateModalParameters(): object {
      return { }
    }

    abstract onLoadTemplate(template: Template<any>): void;
  }
  return LoadTemplateMixin;
}
