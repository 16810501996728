<div
  class="draw-input pos-rel mt-2"
  [ngClass]="{ readonly: readonly }"
  (click)="openDrawPad()"
>
  <div
    *ngIf="value && !readonly"
    class="delete-icon"
    (click)="clearDrawing(); $event.stopPropagation()"
  >
    <i class="fa-regular fa-xmark text-danger"></i>
  </div>
  <ng-container *ngIf="value">
    <div
      class="draw-image"
      [ngStyle]="{
        backgroundImage: 'url(' + ((value | localImage | async) || 'none') + ')'
      }"
    ></div>
  </ng-container>
  <small class="text-xs color-light">{{
    '#UI_CORE.COMPONENTS.DRAW_INPUT.INFO' | translate
  }}</small>
</div>
