<form [formGroup]="form">
  <div class="c row">
    <label class="soft-label top">{{
      '#APPLICATION.APPEARANCE.THEME' | translate
    }}</label>
    <soft-select formControlName="theme" (valueChange)="set()">
      <soft-select-option
        *ngFor="let theme of themes"
        [value]="theme.key ?? null"
        >{{ theme.name | translate }}</soft-select-option
      >
    </soft-select>
  </div>
  <div class="c row mt-4">
    <label class="soft-label top">{{
      '#APPLICATION.APPEARANCE.FONT_SIZE' | translate
    }}</label>
    <soft-select formControlName="fontSize" (valueChange)="set()">
      <soft-select-option
        *ngFor="let fontSize of fontSizes"
        [value]="fontSize.key ?? null"
        >{{ fontSize.name | translate }}</soft-select-option
      >
    </soft-select>
  </div>
</form>
