import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { DateService, isDefined, Store } from '@softline/core';
import { first } from 'rxjs/operators';
import {
  SOFTLINE_CONFIG_AUTHENTICATION_ROUTE,
} from '../authentication.shared';
import * as JwtAuthenticationStore from '../jwt-authentication/jwt-authentication.store';
import { JwtHelper } from '../jwt-authentication/utilities/jwt-helper';
import moment from 'moment';
import { SOFTLINE_FEATURE_JWT_AUTHENTICATION } from '../jwt-authentication/jwt-authentication.shared';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard  {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(SOFTLINE_CONFIG_AUTHENTICATION_ROUTE)
    private authenticationRoute: string,
    private dateService: DateService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    await this.store
      .observe(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.getters.loaded
      )
      .pipe(first((o) => o));

    const token = this.store.get(
      SOFTLINE_FEATURE_JWT_AUTHENTICATION,
      JwtAuthenticationStore.getters.token
    );

    const isExpired = isDefined(token)
      && moment(JwtHelper.getExpirationDate(token)).isBefore(moment(this.dateService.now()))

    if(isDefined(token) && !isExpired)
      return true;

    this.store.commit(
      SOFTLINE_FEATURE_JWT_AUTHENTICATION,
      JwtAuthenticationStore.mutations.setForwardRoute,
      state.url
    );

    if (isExpired) {
      console.log('[AuthenticationGuard]: token expired');
      await this.store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.logout,
        {expired: true}
      );
    }

    console.log('[AuthenticationGuard]: not authenticated');
    return this.router.parseUrl(this.authenticationRoute);

  }

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return await this.canActivate(childRoute, state);
  }
}
