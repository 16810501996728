import {createAction, on, StoreFeature, SyncedRemoteCollectionStore} from '@softline/core';
import {ItemDetailService} from '../services/item-detail.service';
import {lastValueFrom} from 'rxjs';
import {ItemDetail} from '../types/item-detail';

export type State = SyncedRemoteCollectionStore.State<ItemDetail>

const collectionStore = SyncedRemoteCollectionStore.create<ItemDetail>();

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ItemDetailPricelistStore {

  export const mutations = {
    ...collectionStore.mutations
  };

  export const actions = {
    ...collectionStore.actions,
    loadPricelist: createAction<State, {
      id: number;
      attributes: (keyof ItemDetail)[];
      vkforgRestriction?: boolean;
    }, void>('load pricelist'),
  };

  export const getters = {
    ...collectionStore.getters
  };

  export const feature: StoreFeature<State> = {
    initialState: {
      ...collectionStore.feature.initialState,
    },
    mutations: [
      ...collectionStore.feature.mutations
    ],
    actions: [
      ...collectionStore.feature.actions,
      on(actions.loadPricelist, async ({featureName, injector, commit, params, get}) => {
        commit(featureName, mutations.clear);
        const service = injector.get(ItemDetailService);
        const result = await lastValueFrom(service.loadPricelist(params));
        commit(featureName, mutations.addOrUpdateMany, result.map(o => o));
      })
    ],
    getters: [
      ...collectionStore.feature.getters,
    ]
  };
}
