import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { ChargeListView } from "./list-view/charge.list-view";

export const chargeFieldOk: FieldOkConfig = {
  name: 'charge',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{chargennummer}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ChargeListView
    }
  }
  ]
};
export const chargeMultiselectFieldOk: FieldOkConfig = {
  name: 'charge',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{chargennummer}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: ChargeListView
    }
  }
  ]
};

export const chargeQueryFieldOk: FieldOkConfig = {
  name: 'charge',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{chargennummer}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ChargeListView
    }
  }
  ]
};
