import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Filter, FilterService, Sort, SortService, Store} from "@softline/core";
import {
  SOFTLINE_CONFIG_WWS_WARENUEBERNAHME_BESTELLUNG_STEPS,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT
} from "../../../warenuebernahme.shared";
import {
  BackNavigable,
  BackNavigationService,
  FilterAndSortComponent,
  FilterConfig,
  HeaderComponent,
  SortConfig,
  StepHeaderComponent
} from "@softline/application";
import {Router, RouterModule} from "@angular/router";
import {WwsLieferantStore} from "../../../store/lieferant.store";
import {combineLatestWith, map, Observable} from "rxjs";
import {LieferantBestellung} from "../../../types/bestellung";
import {UiCoreModule} from "@softline/ui-core";

@Component({
  selector: 'soft-bestellung-schritt-1',
  standalone: true,
  imports: [CommonModule, HeaderComponent, StepHeaderComponent, RouterModule, FilterAndSortComponent, UiCoreModule],
  templateUrl: './bestellung-schritt-1.component.html',
  styleUrls: ['./bestellung-schritt-1.component.scss'],
})
export class BestellungSchritt1Component implements OnInit, OnDestroy, BackNavigable {

  readonly steps = SOFTLINE_CONFIG_WWS_WARENUEBERNAHME_BESTELLUNG_STEPS;

  readonly lieferanten$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
    WwsLieferantStore.getters.all
  );

  readonly sort$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
    WwsLieferantStore.getters.sort
  );

  readonly filter$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
    WwsLieferantStore.getters.filter
  );

  readonly filteredItems$: Observable<LieferantBestellung[]> = this.lieferanten$.pipe(
    combineLatestWith(this.sort$, this.filter$),
    map(([values, sort, filter]) =>
      this.sortService.sort(this.filterService.filter(values, filter), sort)
    )
  );

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
    WwsLieferantStore.getters.loading
  );

  filterConfig: FilterConfig = {
    properties: [
      {
        property: 'lieferant.lieferantennummer',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_NUMMER'
      },
      {
        property: 'lieferant.kurzbez',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_BEZEICHNUNG'
      },
      {
        property: 'lieferant.id',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_ID',
      }
    ]
  };

  sortConfig: SortConfig = {
    properties: [
      {
        property: 'lieferant.lieferantennummer',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_NUMMER'
      },
      {
        property: 'lieferant.kurzbez',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_BEZEICHNUNG'
      },
      {
        property: 'lieferant.id',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_ID',
      }
    ]
  };

  constructor(private store: Store,
              private router: Router,
              private sortService: SortService,
              private filterService: FilterService,
              private backNavigationService: BackNavigationService) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
      WwsLieferantStore.mutations.setSort,
      {
        property: 'lieferant.kurzbez',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_LIEFERANT_BEZEICHNUNG',
        direction: 'asc'
      } as any,
    );
  }
  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
      WwsLieferantStore.mutations.setFilter,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
      WwsLieferantStore.mutations.setSort,
      null
    );

    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/warenuebernahme']);
  }

  async onFilterChange(filter: Filter | null): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
      WwsLieferantStore.mutations.setFilter,
      filter
    );
  }

  async onSortChange(sort: Sort | null): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT,
      WwsLieferantStore.mutations.setSort,
      sort
    );
  }
}
