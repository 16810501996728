<ng-container  *ngIf="(loading$ | async) === false; else skeleton">
  <div  *ngIf="mitarbeiterDetail$ | async as mitarbeiterDetail; else skeleton">
    <div *ngIf="mitarbeiterDetail?.lstfix" class="flex flex-row w-full mt-3">
      <div class="flex flex-col w-full gap-0.5">
        <div class="soft-overline">
          {{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.KONTAKTDATEN.TITEL' | translate }}
        </div>
        <div class="font-bold text-lg leading-6">
          {{mitarbeiterDetail?.lstfix?.vorname}} {{mitarbeiterDetail?.lstfix?.zuname}}
        </div>
        <div class="text-base leading-5">
          {{mitarbeiterDetail?.adresse?.strasse}} {{mitarbeiterDetail?.adresse?.strassenr}}
        </div>
        <div *ngIf="mitarbeiterDetail?.adresse?.landeskennzeichen !== '' &&
                    mitarbeiterDetail?.adresse?.postleitzahl !== '' &&
                    mitarbeiterDetail?.adresse?.ort !== ''"
                    class="text-base leading-5">
          {{mitarbeiterDetail?.adresse?.landeskennzeichen}} <ng-container *ngIf="mitarbeiterDetail?.adresse?.postleitzahl || mitarbeiterDetail?.adresse?.ort">- {{mitarbeiterDetail?.adresse?.postleitzahl}} {{mitarbeiterDetail?.adresse?.ort}}</ng-container>
        </div>
        <div *ngIf="mitarbeiterDetail?.adresse?.nebenstellefa && mitarbeiterDetail?.adresse?.nebenstellefa !== ''" class="text-base leading-5 w-fit">
          Nebenstelle: {{mitarbeiterDetail?.adresse?.nebenstellefa}}
        </div>
        <a *ngIf="mitarbeiterDetail.adresse?.telefonfa && mitarbeiterDetail?.adresse?.telefonfa !== ''" class="text-base leading-5 soft-link w-fit" [href]="'tel:' + mitarbeiterDetail?.adresse?.telefonfa">
          {{mitarbeiterDetail?.adresse?.telefonfa}}
        </a>
        <a *ngIf="mitarbeiterDetail?.adresse?.handyfa && mitarbeiterDetail?.adresse?.handyfa !== ''" class="text-base leading-5 soft-link w-fit" [href]="'tel:' + mitarbeiterDetail?.adresse?.handyfa">
          {{mitarbeiterDetail?.adresse?.handyfa}}
        </a>
        <a *ngIf="mitarbeiterDetail?.adresse?.mail && mitarbeiterDetail?.adresse?.mail !== ''" class="text-base leading-5 soft-link w-fit" [href]="'mailto:' + mitarbeiterDetail?.adresse?.mail">
          {{mitarbeiterDetail?.adresse?.mail}}
        </a>
      </div>
    </div>
    <div *ngIf="(mitarbeiterDetail?.dienstplanEintraege?.length || 0) > 0" class="flex flex-row w-full mt-4 select-none">
      <div class="flex flex-col w-full gap-0">
        <div class="soft-overline">
          {{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.DIENSTPLAN.TITEL' | translate }}
        </div>
        <div class="rounded w-full block soft-card !p-0">
          <div *ngFor="let dpEintrag of mitarbeiterDetail?.dienstplanEintraege; let last = last"
              class="border-primary-50 flex flex-row w-full py-[0.625rem] px-[0.825rem] gap-10 lg:gap-14"
              [ngClass]="{'border-b-[1px]': !last}">
            <div class="flex flex-col">
              {{dpEintrag.vonzeit | date:'HH:mm'}} - {{dpEintrag.biszeit | date:'HH:mm'}}
            </div>
            <div class="flex flex-col">
              {{dpEintrag?.taetigkeit}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(mitarbeiterDetail?.stempel?.length || 0) > 0"
        class="flex flex-row w-full mt-4">
      <div class="flex flex-col w-full gap-0">
        <div class="soft-overline">
          {{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.TITEL' | translate }}
        </div>

        <soft-accordion class="rounded overflow-hidden w-full block soft-card !p-0 select-none">
          <soft-accordion-item
            *ngFor="let stempelEintrag of mitarbeiterDetail.stempel; let last = last;"
            [class]="last ? 'flex cursor-pointer flex-col w-full' : 'flex cursor-pointer flex-col w-full border-b'"
            #stempelAccordionItem>

            <div class="flex flex-row items-center w-full py-2 px-[0.825rem] justify-start gap-14 lg:gap-28 relative" *softAccordionItemHeader (click)="stempelAccordionItem.toggle()">
              <div class="absolute left-0 top-0 bottom-0 z-10 w-2 rounded-xs"
                   [ngClass]="{
                      'bg-success-500': stempelEintrag.kzkg === 'K',
                      'bg-warning-500': stempelEintrag.kzkg === 'G'
                   }">
              </div>

              <div class="flex flex-col ml-2 font-semibold">
                {{stempelEintrag.kzkg === 'K' ? ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.KOMMT_HEADER' | translate)
                                              : ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.GEHT_HEADER' | translate)}}
              </div>

              <div class="flex flex-col">
                {{stempelEintrag.zeit | date:'HH:mm'}}
              </div>

              <div class="flex flex-col ml-auto">
                <ng-container *ngIf="stempelAccordionItem.expanded">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                  </svg>
                </ng-container>

                <ng-container *ngIf="!stempelAccordionItem.expanded">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </ng-container>
              </div>
            </div>

            <div *softAccordionItemContent="stempelAccordionItem.expanded"
                class="flex flex-row relative w-full lg:items-start cursor-default pt-1">

              <div class="absolute left-0 top-0 bottom-0 z-10 w-2 rounded-xs"
                   [ngClass]="{
                      'bg-success-500': stempelEintrag.kzkg === 'K',
                      'bg-warning-500': stempelEintrag.kzkg === 'G'
                   }">
              </div>

              <div class="flex flex-col w-full px-[0.825rem] ml-2 pb-3 lg:w-1/2">
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.KOMMT_GEHT' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.kzkg === 'K' ? ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.KOMMT' | translate)
                    : ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.GEHT' | translate)}}
                  </p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.TAG' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.datum | date:'EEEE, dd.MM.yyyy'}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.STEMPEL' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.zeit | date:'HH:mm'}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.CODE' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.pmscode || ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.NICHT_ERFASST' | translate) }}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.UEBERNAHMEDATUM' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.uebernahmedatum | date:'dd.MM.yyyy HH:mm:ss' || ''}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.ANWENDER' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.anwender || ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.NICHT_ERFASST' | translate)}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.GERAET' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.pmsgeraet || ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.NICHT_ERFASST' | translate)}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.ERFASSUNGSDATUM' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.erfzeit | date:'dd.MM.yyyy HH:mm:ss'}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.BEMERKUNG' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.bemerkung || ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.NICHT_ERFASST' | translate)}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.TAETIGKEIT' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.taetigkeit || ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.NICHT_ERFASST' | translate)}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.KST' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.kst || ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.NICHT_ERFASST' | translate)}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.HERKUNFT' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.herkunft || ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.NICHT_ERFASST' | translate)}}</p>
                </div>
                <div class="flex items-center no-wrap nowrap gap-3 text-light text-sm">
                  <p class="w-full">{{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.STEMPEL.GELOESCHT' | translate }}</p>
                  <p class="w-full">{{stempelEintrag?.kzloesch || ('#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.NICHT_ERFASST' | translate)}}</p>
                </div>
              </div>
            </div>
          </soft-accordion-item>
        </soft-accordion>
      </div>
    </div>
    <div *ngIf="(mitarbeiterDetail?.termine?.length || 0) > 0"
        class="flex flex-row w-full mt-4 select-none">
      <div class="flex flex-col w-full gap-0">
        <div class="soft-overline">
          {{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.TERMINE.TITEL' | translate }}
        </div>
        <div class="rounded w-full block soft-card !p-0">
          <div *ngFor="let termin of mitarbeiterDetail.termine; let last = last"
              class="py-[0.625rem] px-[0.825rem] flex flex-row w-full gap-10 lg:gap-14"
            [ngClass]="{'border-b-[1px]': !last}">
            <div class="flex flex-col">
              {{termin.vonzeit | date:'HH:mm'}} - {{termin.biszeit | date:'HH:mm'}}
            </div>
            <div class="flex flex-col">
              {{termin.taetigkeit}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(mitarbeiterDetail?.istzeiten?.length || 0) > 0"
        class="flex flex-row w-full mt-4 !pb-5 select-none">
      <div class="flex flex-col w-full gap-0">
        <div class="soft-overline">
          {{ '#PMS_STATUSABFRAGE.MITARBEITER_DETAIL.ISTZEIT.TITEL' | translate }}
        </div>
        <div class="rounded w-full block soft-card !p-0">
          <div *ngFor="let istzeit of mitarbeiterDetail.istzeiten; let last = last"
              class="py-[0.625rem] px-[0.825rem] flex flex-row w-full gap-10 lg:gap-14"
              [ngClass]="{'border-b-[1px]': !last}">
            <div class="flex flex-col">
              {{istzeit.ist_von | date:'HH:mm'}} - {{istzeit.ist_bis | date:'HH:mm'}}
            </div>
            <div class="flex flex-col">
              {{istzeit.ist_sekunden | secondsToHours }}
            </div>
            <div class="flex flex-col">
              {{istzeit.taetigkeit}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <div class="flex flex-col w-full mt-3 gap-1">
    <div class="flex flex-row skeleton w-20 rounded"></div>
    <div class="flex flex-row skeleton w-40 rounded"></div>
    <div class="flex flex-row skeleton w-40 rounded" *softRepeat="3"></div>
  </div>
  <div class="flex flex-col w-full mt-4">
    <div class="flex flex-row skeleton w-20 rounded mt-1 mb-1"></div>
    <div class="rounded w-full block !p-0 overflow-hidden">
      <div class="flex flex-row skeleton w-full h-10" *softRepeat="2"></div>
    </div>
  </div>
  <div class="flex flex-col w-full mt-4 gap-1">
    <div class="flex flex-row skeleton w-20 rounded mt-2"></div>
    <div class="rounded w-full block !p-0 overflow-hidden">
      <div class="flex flex-row skeleton w-full h-10" *softRepeat="2"></div>
    </div>
  </div>
  <div class="flex flex-col w-full mt-4">
    <div class="flex flex-row skeleton w-20 rounded mt-1 mb-1"></div>
    <div class="rounded w-full block !p-0 overflow-hidden">
      <div class="flex flex-row skeleton w-full h-10" *softRepeat="2"></div>
    </div>
  </div>
  <div class="flex flex-col w-full mt-4">
    <div class="flex flex-row skeleton w-20 rounded mt-1 mb-1"></div>
    <div class="rounded w-full block !p-0 overflow-hidden">
      <div class="flex flex-row skeleton w-full h-10" *softRepeat="2"></div>
    </div>
  </div>
</ng-template>
