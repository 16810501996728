import { Component, StaticProvider, Type } from '@angular/core';

export interface Widget {
  outlet: string;
  component: Type<any>;
  priority?: number;
  permissions?: string[];
  class?: string | string[];
  providers?: StaticProvider[];
}
