import { Component, Inject, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from "@softline/dynamic";
import { UiCoreModule } from "@softline/ui-core";

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './faelldt.list-view.html',
  styleUrls: ['./faelldt.list-view.scss'],
})
export class FaelldtListView implements FieldOkItemComponent<any>{

  @Input() item: any;

  constructor() {
  }
}
