import { AbstractControl, FormGroup, ValidatorFn, Validators as BaseValidators } from "@angular/forms";
import { Dictionary, GroupValidation, isDefined, Validation, ValidationResult } from "@softline/core";
import { ValidationExtras } from "./validators.class";


export function requiredGroup( properties: string[], extras?: ValidationExtras): ValidatorFn {
  return (control: AbstractControl) => {
    if(!(control instanceof FormGroup))
      throw new Error('requiredGroup validation is only valid at FormGroups');

    let hasError = true;
    for(const property of properties) {
      let result = BaseValidators.required(control.controls[property]);
      if(!result || !isDefined(result['required'])){
        hasError = false;
        break;
      }
    }
    let result: Dictionary<Validation | GroupValidation> | null = null;
    if (hasError)
      result = {
        requiredGroup: extras?.validation ?? {
          isValid: false,
          properties,
          messages: [
            {
              type: 'error',
              subject: '#UI_CORE.VALIDATION.REQUIRED_GROUP.SUBJECT',
              message: '#UI_CORE.VALIDATION.REQUIRED_GROUP.MESSAGE',
              data: {properties: properties.join(', ')}
            },
          ],
        }
      };
    else if (extras?.success) {
      result = { ...(result ?? {}) };
      result['requiredGroup'] = extras?.successValidation ?? {
        isValid: true,
        properties,
        messages: [
          {
            type: 'success',
            subject: '#UI_CORE.VALIDATION.REQUIRED_GROUP.SUBJECT',
            message: '#UI_CORE.VALIDATION.REQUIRED_GROUP.MESSAGE',
            data: {properties: properties.join(', ')}
          },
        ],
      };
    }
    return result;
  };
}
