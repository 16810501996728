<div class="w-full overflow-hidden">
  <div class="flex flex-col relative">
    <ng-container *ngIf="direction === 'bottom' || offsetDirection === 'top'">
      <ng-container *ngFor="let action of actions | filterBy:'position':'top'">
        <soft-swipe-action *ngIf="!action.isVisible || (action.isVisible | async)"
                           [action]="action"
                           class="absolute top-0 h-full">
        </soft-swipe-action>
      </ng-container>
    </ng-container>
    <div class="flex flex-row relative w-full">
      <ng-container *ngIf="direction === 'right' || offsetDirection === 'left'">
        <ng-container *ngFor="let action of actions | filterBy:'position':'left'">
          <soft-swipe-action *ngIf="!action.isVisible || (action.isVisible | async)"
                             [action]="action"
                             class="absolute left-0 h-full">
          </soft-swipe-action>
        </ng-container>
      </ng-container>
      <div class="w-full z-10"
           [ngStyle]="{'transform': distance | swipeContainerTransform : direction}">
        <ng-content></ng-content>
      </div>
      <ng-container *ngIf="direction === 'left' || offsetDirection === 'right'">
        <ng-container *ngFor="let action of actions | filterBy:'position':'right'">
          <soft-swipe-action *ngIf="!action.isVisible || (action.isVisible | async)"
                             [action]="action"
                             class="absolute right-0 h-full">
          </soft-swipe-action>
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="direction === 'top' || offsetDirection === 'bottom'">
      <ng-container *ngFor="let action of actions | filterBy:'position':'bottom'">
        <soft-swipe-action *ngIf="!action.isVisible || (action.isVisible | async)"
                           [action]="action"
                           class="absolute bottom-0 h-full">
        </soft-swipe-action>
      </ng-container>
    </ng-container>
  </div>
</div>
