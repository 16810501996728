<div
  *ngIf="form && definition"
  class="soft-container soft-container-column"
>
  <label class="soft-label soft-label-top">
    {{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-entity-input
    [formControl]="form|formControl:definition.name"
    [readonly]="definition.readonly | booleanRule: form.value"
    [softScan]="(definition | scanDefinition)?.scan ?? false"
    [labelTypes]="(definition | scanDefinition)?.labelTypes"
    softFieldValidation
    #input
  >
    <ng-template let-entity="entity">
      <span>
        {{ definition.format | formatRule: form.value | interpolate: entity }}
      </span>
    </ng-template>
    <soft-entity-picker
      (query)="onQuery($event)"
      #picker
    >
      <ng-container *ngFor="let view of definition.views">
        <ng-template
          *ngIf="view.type === 'query'"
          [softQueryView]="view.type"
          [title]="view.title"
          #queryView="queryView"
        >
          <div class="entity-input-picker-content form">
            <soft-dynamic-form
              [definition]="$any(view.definition)"
              #form
            ></soft-dynamic-form>
          </div>
          <button
            class="soft-button soft-button-primary"
            (click)="
              queryView.query({ query: form.form().value });
              picker.activate('list')
            "
          >
            Abfragen
          </button>
        </ng-template>
        <ng-template
          *ngIf="view.type === 'list'"
          [softDataView]="view.type"
          [title]="view.title"
          #listView="dataView"
        >
          <div class="entity-input-picker-content list">
            <soft-dynamic-list
              [definition]="view.definition"
              [items]="(entities$ | async) ?? []"
              selectionMode="single"
              #dynamicList
            ></soft-dynamic-list>
          </div>
          <button
            class="soft-button soft-button-primary"
            (click)="listView.submit(dynamicList.selectedItem)"
          >
            Auswählen
          </button>
        </ng-template>
        <ng-template
          *ngIf="view.type === 'masterDetail'"
          [softDataView]="view.type"
          [title]="view.title"
          #masterDetailView="dataView"
        >
          <soft-master-detail
            [data]="(entities$ | async) ?? []"
            [masterTemplate]="masterTemplate"
            [detailTemplate]="detailTemplate"
            class="entity-input-picker-content master-detail"
            #masterDetail
          >
          </soft-master-detail>
          <ng-template #masterTemplate let-data="data">
            <soft-dynamic-object
              [definition]="view.masterDefinition"
              [value]="data"
            ></soft-dynamic-object>
          </ng-template>
          <ng-template #detailTemplate let-data="data">
            <div *ngIf="masterDetail.view === 'detail'">
              <a class="soft-link" (click)="masterDetail.selected = undefined"
                >Zurück</a
              >
            </div>
            <soft-dynamic-object
              [definition]="view.detailDefinition"
              [value]="data"
            ></soft-dynamic-object>
          </ng-template>

          <button
            class="soft-button soft-button-primary"
            (click)="masterDetailView.submit(masterDetail.selected)"
          >
            Auswählen
          </button>
        </ng-template>
        <ng-template
          *ngIf="view.type === 'barcode'"
          [softQueryView]="view.type"
          [title]="view.title"
        >
          Data
          <!--<soft-dynamic-form [definition]="view.definition"></soft-dynamic-form>-->
        </ng-template>
        <ng-template
          *ngIf="view.type === 'input'"
          [softQueryView]="view.type"
          [title]="view.title"
        >
          Data
          <!--<soft-dynamic-form [definition]="view.definition"></soft-dynamic-form>-->
        </ng-template>
      </ng-container>
    </soft-entity-picker>
  </soft-entity-input>
</div>
