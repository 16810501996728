import { InjectionToken } from '@angular/core';
import { TestConnectionService } from './services/test-connection.service';

export const SOFTLINE_PAGE_CONNECTION_SETTINGS = '/connection-settings';

export const SOFTLINE_SERVICE_TEST_CONNECTION =
  new InjectionToken<TestConnectionService>(
    'SOFTLINE_STORE_TEST_CONNECTION_SERVICE'
  );
export const SOFTLINE_FEATURE_CONNECTION_SETTINGS = 'connectionSettings';
export const SOFTLINE_SETTINGS_CONNECTION = 'connection';

export const SOFTLINE_CONFIG_TEST_CONNECTION_PATH = new InjectionToken<string>(
  'SOFTLINE_CONFIG_TEST_CONNECTION_PATH'
);
export const SOFTLINE_CONFIG_CONNECTION_SETTINGS_LINKS = new InjectionToken<
  { name: string; route: string | string[] }[]
>('SOFTLINE_CONFIG_CONNECTION_SETTINGS_LINKS');
