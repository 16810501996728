<soft-entity-input
  [readonly]="readonly"
  [placeholder]="placeholder"
  [value]="value"
  [title]="title"
  (valueChange)="setValue($event)"
  [strategy]="strategy"
  [parameters]="{current: value, parameters: parameters}"
  #input
>
  <ng-template let-entity="entity" #entityTemplate>
    <ng-container
      *ngTemplateOutlet="
        template || defaultTemplate;
        context: { entity: entity }
      "
    ></ng-container>
  </ng-template>
</soft-entity-input>
<ng-template let-entity="entity" #defaultTemplate>
  <ng-container *ngIf="config?.template && (config?.template | typeof) !== 'string'; else stringTemplate">
    <ng-container *ngComponentOutlet="$any(config?.template); inputs: {item: entity}"></ng-container>
  </ng-container>
  <ng-template #stringTemplate>
    <ng-container *ngIf="config?.template; else emptyTemplate">
      {{ $any(config?.template) | interpolate:entity}}
    </ng-container>
  </ng-template>
  <ng-template  let-entity="entity" #emptyTemplate>
    {{ entity | json }}
  </ng-template>
</ng-template>
