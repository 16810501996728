import {UpdateQueryStrategyFactory} from './query-update.strategy';
import {FrontendUmsatzstatistikQuery} from '../types/frontend-umsatzstatistik.query';

export const produktgruppeQueryUpdateStrategy: UpdateQueryStrategyFactory<string> = () => {
  return {
    umsatzebene: 'Produktgruppe',
    updateFn: async (key, query) => {
      query.produktgruppe = key;
      return query;
    },
    removeFn: async (query: FrontendUmsatzstatistikQuery)  => {
      delete query['produktgruppe']
      return query;
    }
  }
}
