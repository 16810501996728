import { InjectionToken } from '@angular/core';

export const SOFTLINE_PERMISSIONS_HAFTRUECKLASS = 'JOPOSTEN_MODULE';
export const SOFTLINE_FEATURE_HAFTRUECKLASS = 'haftruecklass';
export const SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY = 'haftruecklassQuery';
export const SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST = 'haftruecklassList';

export interface HaftruecklassConfig {
  nurHaftRuecklaesse?: boolean;
}

export const SOFTLINE_CONFIG_HAFTRUECKLASS =
  new InjectionToken<HaftruecklassConfig>('SOFTLINE_CONFIG_HAFTRUECKLASS');
