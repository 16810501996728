import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN} from '../materiallieferschein-scan.shared';
import {MateriallieferscheinScanStore} from '../stores/materiallieferschein-scan.store';

@Injectable()
export class KopfGuard  {

  constructor(private store: Store, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const kopf = this.store.get(
      SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN,
      MateriallieferscheinScanStore.getters.kopf,
    );

    if (!kopf || !kopf?.id) {
      await this.router.navigate(['/materiallieferschein-scan/einlesen'])
    }

    return true
  }
}
