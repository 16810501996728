import {InjectionToken} from "@angular/core";

export interface OffenePostenConfig {
  nichtFakturierteFilterAnzeigen: boolean;
}


export const SOFTLINE_PERMISSIONS_OFFENE_POSTEN = 'EINZUGSUEBERSICHT';
export const SOFTLINE_PERMISSIONS_OFFENE_POSTEN_WIDGET = 'JOPOSTEN.WIDGET';
export const SOFTLINE_PERMISSIONS_OFFENE_POSTEN_KREDITOR_WIDGET = 'JOPOSTEN.KREDITOR_WIDGET';
export const SOFTLINE_PERMISSIONS_OFFENE_POSTEN_DEBITOR_WIDGET = 'JOPOSTEN.DEBITOR_WIDGET';


export const SOFTLINE_FEATURE_OFFENE_POSTEN = 'offenePosten';
export const SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN = 'offenePostenJoposten';
export const SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_DEBITOREN = 'offenePostenJopostenDebitoren';
export const SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_KREDITOREN = 'offenePostenJopostenKreditoren';

export const SOFTLINE_CONFIG_OFFENE_POSTEN = new InjectionToken<OffenePostenConfig>(
    'SOFTLINE_CONFIG_HIDE_NICHT_FAKTURIERTE_ANZEIGEN'
);
