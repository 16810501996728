<h4 class="text-xl font-semibold mt-3 ml-3">
  {{ title | translate | interpolate: params }}
</h4>

<p *ngIf="subtitle" class="mb-1 mt-1 ml-3 mr-3 border-box color-light">
  {{ subtitle | translate | interpolate: params }}
</p>

<p *ngIf="content" class="mb-1 mt-1 ml-3 mr-3 border-box">
  <ng-container *ngTemplateOutlet="content; context: (params ?? null)"></ng-container>
</p>

<div class="flex flex-1">
  <soft-gallery
    class="grid gallery w-full px-4 sm:px-0"
    [images]="images"
    [selectedIndex]="selectedIndex"
  ></soft-gallery>
</div>
