import { Component, OnInit } from '@angular/core';
import { Modal, Validators } from "@softline/ui-core";
import { LabelType } from '../../../data/label-type';
import { Scan } from '../../../data/scan';
import { DateService } from '@softline/core';
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'soft-input-scanner',
  templateUrl: './input-scanner.component.html',
  styleUrls: ['./input-scanner.component.scss'],
})
export class InputScannerComponent implements OnInit, Modal<Scan> {
  close!: (result: Scan) => void;

  private _labelTypes: LabelType[] = [];
  get labelTypes(): LabelType[] {
    return this._labelTypes;
  }
  set labelTypes(value: LabelType[]){
    if(value.length === 1)
      this.form.patchValue({labelType: value[0]})
    this._labelTypes = value;
  }

  form = new FormGroup({
    labelType: new FormControl<LabelType | undefined>(undefined),
    data: new FormControl<string | null>(null, [Validators.required()])
  })

  constructor(public dateService: DateService) {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: Scan) => void): void {
    this.close = handler;
  }

  fillForm(scan: Scan): void {
    this.form.patchValue(scan);
  }

  onSubmit(): void {
    if(!this.form.value.data)
      return;
    this.close({
      data: this.form.value.data,
      labelType: this.form.value.labelType,
      timestamp: this.dateService.now()}
    )
  }
}
