import { computed, Directive, effect, forwardRef, inject, input, Signal } from '@angular/core';
import { MetaForm } from '../../model/meta-form';
import { MetaBaseDirective } from '../object/meta-base.directive';
import { MetaFormHelper, MetaObject } from '@softline/dynamic';
import { FormGroupDirective, ValidatorFn } from '@angular/forms';
import { Dictionary, isDefined } from '@softline/core';

@Directive({
  selector: '[softMetaForm]',
  standalone: true,
  providers: [
    {provide: MetaBaseDirective, useExisting: forwardRef(() => MetaFormDirective)}
  ]
})
export class MetaFormDirective<T> extends MetaBaseDirective<T>{
  private validators: Dictionary<ValidatorFn[]> | null = null;
  metaForm = input.required<MetaForm<T> | null>({alias: 'softMetaForm'});

  metaObject: Signal<MetaObject<T>> = computed(() => { return this.metaForm() as MetaObject<T>; });
  form = inject(FormGroupDirective);

  metaFormEffect = effect(() => {
    const meta = this.metaForm();
    if(!meta)
      return;
    const validators = MetaFormHelper.createValidators(meta);
    if(this.validators) {
      for (const key of Object.keys(this.validators)) {
        const validator = this.validators[key];
        if (isDefined(validator))
          this.form.form.controls[key].removeValidators(validator);
        this.form.form.controls[key].updateValueAndValidity();
      }
    }
    for (const key of Object.keys(validators)) {
      const validator = validators[key]
      if(isDefined(validator))
        this.form.form.controls[key].addValidators(validator);
      this.form.form.controls[key].updateValueAndValidity();
    }
    this.validators = validators;
  }, {allowSignalWrites: true});

  constructor() {
    super();
  }
}
