import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal} from '@softline/ui-core';

@Component({
  selector: 'softline-deactivate',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './deactivate.component.html',
  styleUrls: ['./deactivate.component.scss'],
})
export class DeactivateComponent implements Modal<'LOESCHEN' | 'ABSCHLIESSEN'> {

  close!: (result: ("LOESCHEN" | "ABSCHLIESSEN")) => void;

  constructor() {}

  onClick(type: 'LOESCHEN' | 'ABSCHLIESSEN') {
    this.close(type);
  }

  registerCloseHandler(handler: (result: ("LOESCHEN" | "ABSCHLIESSEN")) => void) {
    this.close = handler;
  }
}
