import {CalculationData, KzAusgabeStrategy} from "../kz-ausgabe.strategy";
import {Dictionary} from "@softline/core";

export const KzAusgabeNStrategy: KzAusgabeStrategy = {
  calculate(data: CalculationData): Dictionary<number | null> {
    if (!data?.inputValue)
      return data.dictionary;

    if (data.inputArteh.id === data.baseArteh.id) {
      data.dictionary[data.inputArteh.id] = data.inputValue

      for (const arteh of data.artehs) {
        if (arteh.id === data.inputArteh.id)
          continue;

        data.dictionary[arteh.id] = 0;
      }
    } else {
      data.dictionary[data.baseArteh.id] = data.inputValue / (data.baseArteh.faktor / data.inputArteh.faktor)

      for (const arteh of data.artehs) {
        if (arteh.id === data.baseArteh.id)
          continue;

        data.dictionary[arteh.id] = 0;
      }
    }

    return data.dictionary
  }
}
