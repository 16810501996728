<div class="w-full" *ngIf="state$ | async as state">
  <div class="flex flex-col">
    <div class="flex flex-row justify-between items-center">
      <span>{{(state.updateAvailable
        ? '#PWA.UPDATE.SETTINGS.UPDATE_AVAILABLE'
        : '#PWA.UPDATE.SETTINGS.NO_UPDATE') | translate}}</span>
      <button *ngIf="state.updateAvailable"
              class="soft-button primary"
              [disabled]="state.latestVersionHash === undefined"
              (click)="onActivateUpdate()"
      >{{'#PWA.UPDATE.SETTINGS.ACTIVATE' | translate}}</button>
      <button *ngIf="!state.updateAvailable && state.latestVersionHash === undefined"
              class="soft-button primary"
              (click)="onCheckUpdate()"
      >{{'#PWA.UPDATE.SETTINGS.CHECK' | translate}}</button>
    </div>
    <div class="flex flex-row mt-4">
      <span class="soft-label left">{{'#PWA.UPDATE.SETTINGS.CURRENT_HASH' | translate}}</span>
      <span *ngIf="!state.loading; else loadingVersion">{{state.currentVersionHash}}</span>
      <ng-template #loadingVersion>
        <span class="skeleton w-18 h-4"></span>
      </ng-template>
    </div>
    <div *ngIf="state.latestVersionHash" class="flex flex-row mt-4">
      <span class="soft-label left">{{'#PWA.UPDATE.SETTINGS.NEXT_HASH' | translate}}</span>
      <span>{{state.latestVersionHash}}</span>
    </div>
  </div>
</div>
