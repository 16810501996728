import { Type } from '@angular/core';
import { ScannerService } from '../services/scanner.service';

export interface Scanner {
  name: string;
  title: string;

  priority: number;
  service: ScannerService;
  settingsComponent?: Type<any>;
}
