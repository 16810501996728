import { isDefined } from '../../functions/is-defined.function';

export interface Connection {
  readonly host: string;
  readonly port?: number;
  readonly basePath?: string;
  readonly name?: string;
}

export function isConnection(connection: unknown): connection is Connection {
  return isDefined((connection as Connection).host);
}
