<span
  *ngIf="selectedOptions.length > 0; else placeholderTemplate"
  class="select-text"
>
  <ng-container
    *ngFor="
      let option of displayAll
        ? selectedOptions
        : (selectedOptions | take: maxDisplayed)
    "
  >
    <span class="select-pill fluid no-wrap">
      <span
        [innerHTML]="
          option?.element?.nativeElement?.innerHTML || option?.value
            | secureHtml
        "
      ></span>
      <i
        *ngIf="!readonly"
        class="fa-regular fa-xmark pointer ml-1"
        (click)="onDelete(option)"
      ></i>
    </span>
  </ng-container>
  <span
    *ngIf="displayAll === false && selectedOptions.length > maxDisplayed + 1"
    class="soft-link small fluid no-wrap"
    (click)="displayAll = true"
  >
    {{
      '#UI_CORE.COMPONENTS.MULTI_SELECT.DISPLAY_MORE_PL'
        | translate
        | interpolate: { count: selectedOptions.length - maxDisplayed }
    }}
  </span>
  <span
    *ngIf="displayAll === false && selectedOptions.length === maxDisplayed + 1"
    class="soft-link small fluid no-wrap"
    (click)="displayAll = true"
  >
    {{ '#UI_CORE.COMPONENTS.MULTI_SELECT.DISPLAY_MORE_SG' | translate }}
  </span>
  <span
    *ngIf="displayAll === true && selectedOptions.length > maxDisplayed"
    class="soft-link small fluid no-wrap"
    (click)="displayAll = false"
  >
    {{ '#UI_CORE.COMPONENTS.MULTI_SELECT.DISPLAY_LESS' | translate }}
  </span>
</span>
<ng-template #placeholderTemplate>
  <span
    class="select-text truncate pointer select-placeholder"
    (click)="onClick()"
    >{{
      placeholder ||
        ('#UI_CORE.COMPONENTS.MULTI_SELECT.PLACEHOLDER' | translate)
    }}</span
  >
</ng-template>

<div
  *ngIf="isOpen"
  class="select-panel"
  [style]="{ top: panelTop + 'px' }"
  (softClickOutside)="onOutsideClick()"
  [delayClickOutsideInit]="true"
>
  <div class="soft-scroll vertical select-panel-items-multi">
    <ng-container *ngFor="let option of options">
      <div *ngIf="isHeader(option)" class="select-option-header">
        {{ option.text }}
      </div>
      <div *ngIf="isSeparator(option)" class="select-option-separator">
        <hr />
      </div>
      <div
        *ngIf="isOption(option)"
        class="select-option"
        [class.disabled]="option.disabled"
        [class.pointer]="!option.disabled"
        (click)="!option.disabled ? onSelect(option) : undefined"
      >
        <soft-checkbox
          [class.pointer]="!option.disabled"
          [readonly]="option.disabled"
          [checked]="option | in: temporaryOptions"
          (checkedChange)="!option.disabled ? onSelect(option) : undefined"
          (click)="$event.stopPropagation()"
        >
          <span
            class="soft-label right"
            [class.pointer]="!option.disabled"
            [innerHTML]="
              option.element.nativeElement.innerHTML || option.value
                | secureHtml
            "
          ></span>
        </soft-checkbox>
      </div>
    </ng-container>
  </div>
  <a
    [class.collapse]="temporaryOptions.length >= selectableOptions.length"
    class="soft-link small mt-2 ml-2"
    (click)="selectAll()"
  >{{ '#UI_CORE.COMPONENTS.MULTI_SELECT.SELECT_ALL' | translate }}
  </a>
  <a
    [class.collapse]="temporaryOptions.length < selectableOptions.length"
    class="soft-link small mt-2 ml-2"
    (click)="selectNone()"
  >{{ '#UI_CORE.COMPONENTS.MULTI_SELECT.SELECT_NONE' | translate }}</a
  >
  <div class="w-full p-4">
    <button
      class="soft-button primary w-full h-16 rounded"
      (click)="onSubmitPanel()"
    >
      {{ '#UI_CORE.COMPONENTS.MULTI_SELECT.SUBMIT' | translate }}
    </button>
  </div>
</div>
<span
  class="soft-backdrop mobile-only z-300"
  *ngIf="isOpen"
  (click)="onOutsideClick()"
></span>

<i
  class="fa-regular fa-chevron-up pointer"
  *ngIf="!readonly && isOpen"
  (click)="onClick()"
></i>
<i
  class="fa-regular fa-chevron-down pointer"
  *ngIf="!readonly && !isOpen"
  (click)="onClick()"
></i>
