import { BehaviorSubject, Observable } from 'rxjs';

export abstract class CarouselStrategy<T> {
  protected constructor() {}

  abstract getAnimation(index: number): any;

  abstract getStart(items: T[]): T[];

  abstract canNext(items: T[], current?: T[]): boolean;

  abstract getNext(items: T[], current: T[]): T[];

  abstract canPrevious(items: T[], current?: T[]): boolean;

  abstract getPrevious(items: T[], current: T[]): T[];
}
