import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from "@softline/ui-core";

@Component({
  selector: 'soft-blocking-loading-spinner',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './blocking-loading-spinner.component.html',
  styleUrls: ['./blocking-loading-spinner.component.scss']
})
export class BlockingLoadingSpinnerComponent {}
