import { FieldOkConfig, FieldOkListViewComponent, FieldOkMultiselectListViewComponent, QueryFieldOkListViewComponent } from "@softline/dynamic";
import { LstfixListViewComponent } from "./list-view/list-view.component";

export const lstfixFieldOk: FieldOkConfig = {
    name: 'lstfix',
    type: 'default',
    priority: 0,
    defaultView: 'list',
    defaultDataView: 'list',
    template: '{{persnr}}',
    autoQuery: true,
    views: [{
      name: 'list',
      component: FieldOkListViewComponent,
      title: 'Auswahl',
      config: {
        component: LstfixListViewComponent,
      }
    }
    ]
  };

  export const lstfixFieldOkMultiselectFieldOk: FieldOkConfig = {
    name: 'lstfix',
    type: 'multi',
    priority: 0,
    defaultView: 'list',
    defaultDataView: 'list',
    template: '{{persnr}}',
    autoQuery: true,
    views: [{
      name: 'list',
      component: FieldOkMultiselectListViewComponent,
      title: 'Auswahl',
      config: {
        component: LstfixListViewComponent,
      }
    }
    ]
  };
  
  export const lstfixQueryFieldOk: FieldOkConfig = {
    name: 'lstfix',
    type: 'query',
    priority: 0,
    defaultView: 'list',
    defaultDataView: 'list',
    template: '{{persnr}}',
    autoQuery: true,
    views: [{
      name: 'list',
      component: QueryFieldOkListViewComponent,
      title: 'Auswahl',
      config: {
        component: LstfixListViewComponent,
      }
    }
    ]
  };
  