<form [formGroup]="form">
  <div class="flex flex-col mt-4">
    <label class="soft-label top">{{'#APPLICATION.SCANNER.INTEGRATED.SCAN_EVERYWHERE' | translate}}</label>
    <soft-toggle-switch formControlName="scanEverywhere"></soft-toggle-switch>
    <div class="text-xs text-light inline">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-2">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
      </svg>
      {{'#APPLICATION.SCANNER.INTEGRATED.SCAN_EVERYWHERE_INFO' | translate}}
    </div>
  </div>
</form>

