import {Step} from '@softline/application';
import {InjectionToken} from '@angular/core';

export const SOFTLINE_CONFIG_MDE_WARENVERPROBUNG_STEPS: Step[] = [
  {
    header: 'Artikel suchen',
    subHeader: 'Bitte gewünschten Artikel auswählen',
  },
  {
    header: 'Artikeldaten eingeben',
    subHeader: 'Bitte Daten eingeben',
  }
];

export interface BezugsnummerConfig {
  type: 'BESTELLUNG' | 'WARENUEBERNAHME'
  required: boolean
}

export const SOFTLINE_CONFIG_WARENVERPROBUNG_BEZUGSNUMMER = new InjectionToken<BezugsnummerConfig>('SOFTLINE_CONFIG_WARENVERPROBUNG_BEZUGSNUMMER')

export const SOFTLINE_FEATURE_MDE_WARENVERPROBUNG = 'mdeWarenverprobung';
export const SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE = 'mdeWarenverprobungBewe';
export const SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BELEGE = 'mdeWarenverprobungBelege';
export const SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_ARTIKEL_DETAILS = 'mdeWarenverprobungArtikelDetails';
