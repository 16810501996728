import { effect, inject, Injectable, OnDestroy, OnInit, signal, Signal } from "@angular/core";
import { hasOnDestroy, Store } from "@softline/core";
import * as CommandStore from "../program/command.store";
import { SOFTLINE_FEATURE_COMMANDS } from "../application.shared";
import { Command } from "../data/command";

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithCommands = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class CommandMixin extends Base implements OnDestroy {

    COMMAND_SET_NAME = this.constructor.name+'Commands';

    store = inject(Store);
    abstract commands: Signal<Command[]>;

    commandEffect = effect(() => {
      this.store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.addSet,
        {
          name: this.COMMAND_SET_NAME,
          commands: this.commands() ?? []
        }
      )
    })

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnDestroy(): void {
      this.store.commit(
        SOFTLINE_FEATURE_COMMANDS,
        CommandStore.mutations.removeSet,
        this.COMMAND_SET_NAME
      )

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

  }
  return CommandMixin;
}
