<form [formGroup]="form">
  <div class="flex flex-col">
    <label class="soft-label top">{{'#APPLICATION.SCANNER.ENGINE' | translate}}</label>
    <soft-select formControlName="scanner" (valueChange)="set()">
      <soft-select-option
        *ngFor="let scanner of scanners | sort:'priority':'DESC'"
        [value]="scanner.name"
        [disabled]="!scanner.service.isAvailable"
      >
        {{ scanner.title }}
      </soft-select-option>
    </soft-select>
  </div>
</form>

<div class="soft-overline mt-4 w-full border-b border-solid">{{ '#APPLICATION.SCANNER.TEST' | translate }}</div>
<div class="flex flex-col w-full">
    <label class="soft-label top">{{'#APPLICATION.SCANNER.LABEL_TYPES' | translate}}</label>
    <soft-multi-select #labelTypes>
      <soft-select-option-header
        [text]="'1 Dimensional'"
      ></soft-select-option-header>
      <soft-select-option [value]="'codabar'">Codabar</soft-select-option>
      <soft-select-option [value]="'code39'">Code39</soft-select-option>
      <soft-select-option [value]="'code93'">Code93</soft-select-option>
      <soft-select-option [value]="'code128'">Code128</soft-select-option>
      <soft-select-option [value]="'ean8'">EAN8</soft-select-option>
      <soft-select-option [value]="'ean13'">EAN13</soft-select-option>
      <soft-select-option [value]="'ean128'">EAN128</soft-select-option>
      <soft-select-option [value]="'itf'">ITF</soft-select-option>
      <soft-select-option [value]="'rss14'">RSS14</soft-select-option>
      <soft-select-option [value]="'upc_a'">UPC_A</soft-select-option>
      <soft-select-option [value]="'upc_e0'">UPC_E0</soft-select-option>
      <soft-select-option [value]="'upc_e1'">UPC_E1</soft-select-option>
      <soft-select-option [value]="'msi'">MSI</soft-select-option>

      <soft-select-option-header
        [text]="'2 Dimensional'"
      ></soft-select-option-header>
      <soft-select-option [value]="'aztec'">Aztec</soft-select-option>
      <soft-select-option [value]="'datamatrix'">Datamatrix</soft-select-option>
      <soft-select-option [value]="'maxicode'">MaxiCode</soft-select-option>
      <soft-select-option [value]="'pdf417'">PDF417</soft-select-option>
      <soft-select-option [value]="'rss_expanded'"
        >RSS Expanded</soft-select-option
      >
      <soft-select-option [value]="'micropdf'">MicroPDF</soft-select-option>
      <soft-select-option [value]="'qrcode'">QR Code</soft-select-option>

      <soft-select-option-header [text]="'Multi'"></soft-select-option-header>
      <soft-select-option [value]="'code'"
        >Code (Code39, Code 93, Code128)</soft-select-option
      >
      <soft-select-option [value]="'ean'"
        >EAN (EAN8, EAN13, EAN128)</soft-select-option
      >
      <soft-select-option [value]="'upc'"
        >UPC (UPC_A, UPC_E0, UPC_E1)</soft-select-option
      >
      <soft-select-option [value]="'upc_e'"
        >UPC_E (UPC_E0, UPC_E1)</soft-select-option
      >
    </soft-multi-select>

  <soft-scan-button class="mt-4" [labelType]="labelTypes.value"></soft-scan-button>
</div>

<ng-container *ngFor="let scanner of scanners">
  <ng-container *ngIf="scanner.settingsComponent && scanner.service.isAvailable">
    <div class="soft-overline mt-4 w-full border-b border-solid">{{ scanner.title }}</div>
    <ng-container *ngComponentOutlet="scanner.settingsComponent"></ng-container>
  </ng-container>
</ng-container>
