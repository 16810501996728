@if(belegart(); as selectedBelegart) {
  <div class="soft-top-container sticky">
    <div class="flex justify-between flex-wrap w-full mt-2">
      <soft-select-box title="#GESAMTABFRAGE.PAGES.QUERY.TYPE"
                       icon="fa-regular fa-file-invoice fa-2x"
                       [value]="selectedBelegart.id"
                       (valueChange)="onChangeBelegart($event)">
        @for (art of belegarten(); track art.id) {
          <soft-select-box-option [value]="art.id" [label]="art.kundenName ?? art.name"></soft-select-box-option>
        }
      </soft-select-box>
      <soft-responsive-menu></soft-responsive-menu>
    </div>
  </div>
}
<soft-dynamic-form
  class="mt-4"
  [value]="value()"
  [definition]="$any(definition())"
  (formReset)="clearStore()"
  (formSubmit)="onSubmit($event)"
  (valueChange)="formValue.set($event)"
  #form
></soft-dynamic-form>

<!--<ng-template #bottomContent>
  <div class="sm:hidden px-4 pb-4 pt-2">
    <ng-container *ngTemplateOutlet="submitButton"></ng-container>
  </div>
</ng-template>

<ng-template #submitButton>
  <soft-split-button type="accent h-16 sm:h-auto"
                     [formGroup]="form.form"
                     [disabled]="(form.form.controls | equals:({})) || form.form.invalid"
                     (action)="onSubmit(form.form.value)">
    <div class="flex flex-row gap-2">
      <soft-icon name="fa-regular fa-magnifying-glass"></soft-icon>
      {{ '#GESAMTABFRAGE.PAGES.QUERY.SUBMIT' | translate }}
    </div>
    <soft-split-option [label]="'#GESAMTABFRAGE.PAGES.QUERY.RESET'"
                       (action)="clearStore(); form.onReset($event)"></soft-split-option>
    <soft-split-option [label]="'#GESAMTABFRAGE.PAGES.QUERY.SAVE'"
                       (action)="onSaveTemplate()"
                       [disabled]="form.form.pristine"
    ></soft-split-option>
    <soft-split-option [label]="'#GESAMTABFRAGE.PAGES.QUERY.SAVE_AND_QUERY'"
                       (action)="onSaveTemplateAndExecute()"
                       [disabled]="form.form.pristine"></soft-split-option>
  </soft-split-button>
</ng-template>-->
