import {createAction, on, SyncedRemoteCollectionStore} from "@softline/core";
import {FavoriteList} from '../types/favorite-list';
import {lastValueFrom} from 'rxjs';
import {SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_ITEMS} from '../artikel-info.shared';
import {FavoriteItemsStore} from './favorite-items.store';
import {Favorite} from '../types/favorite';
import {FavoriteResourceService} from '../services/favorite-resource.service';

const syncedRemoteCollectionStore = SyncedRemoteCollectionStore.create<FavoriteList>();

type State = SyncedRemoteCollectionStore.State<FavoriteList>;

const mutations = {
  ...syncedRemoteCollectionStore.mutations,
}

const actions = {
  ...syncedRemoteCollectionStore.actions,
  addItemToList: createAction<State, { item: { id: string | number }, list: FavoriteList }, Favorite>('favorite-list/addItemToList'),
  exportViaMail: createAction<State, { id: number, mail: string }, Favorite>('favorite-list/exportViaMail'),
}

const getters = {
  ...syncedRemoteCollectionStore.getters,
}

export const FavoriteListsStore = {
  actions,
  getters,
  mutations,
  feature: {
    initialState: {
      ...syncedRemoteCollectionStore.feature.initialState
    },
    getters: [
      ...syncedRemoteCollectionStore.feature.getters
    ],
    mutations: [
      ...syncedRemoteCollectionStore.feature.mutations
    ],
    actions: [
      ...syncedRemoteCollectionStore.feature.actions,
      on(actions.addItemToList, async ({ featureName, injector, params, commit }) => {
        const service = injector.get(FavoriteResourceService);
        const result = await lastValueFrom(service.addItemToList(params.item, params.list));
        commit(SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_ITEMS, FavoriteItemsStore.mutations.addOrUpdate, result);
        return result;
      }),
      on(actions.exportViaMail, async ({ featureName, injector, params, commit }) => {
        const service = injector.get(FavoriteResourceService);
        const result = await lastValueFrom(service.exportViaMail(params));
        return result;
      })
    ]
  }
}
