import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, distinct } from 'rxjs';
import { Store2Feature } from './store2-feature';
import { map } from 'rxjs/operators';
import { isDefined } from '../functions/is-defined.function';
import { Dictionary } from '../types/dictionary';
import { SOFTLINE_STORE_STATE } from './store2.shared';

@Injectable()
export class Store2 {

  private features: Dictionary<Store2Feature<any>> = { };

  state$ = inject(SOFTLINE_STORE_STATE);
  constructor() { }

  setState(state: Dictionary<unknown>): void {
    this.state$.next(state);
  }

  resetState(): void {
    this.state$.next({});
  }

  getFeature<T extends Store2Feature<T>>(feature: string): unknown {
    return this.features[feature] as T;
  }

  resetFeatureState(feature: string): void {
    if (!isDefined(this.features[feature]))
      throw new Error(`[Store2] Feature '${feature}' is not registered`);
    const state = this.state$.value;
    delete state[feature];
    this.setState(state);
  }

  setFeatureState(feature: string, featureState: unknown): void {
    if (!isDefined(this.features[feature]))
      throw new Error(`[Store2] Feature '${feature}' is not registered`);
    const state = this.state$.value;
    this.setState({ ...state, [feature]: featureState });
  }

  registerFeature(feature: Store2Feature<any>): void {
    if (isDefined(this.features[feature.name]))
      throw new Error(`[Store2] Feature '${feature.name}' is already registered`);
    this.features[feature.name] = feature;
    this.setFeatureState(feature.name, feature.initialState);
    feature.registerCommit((state: unknown) => this.setFeatureState(feature.name, state));
    feature.registerState(
      this.state$.pipe(
        map(state => state[feature.name]),
        distinct()
      )
    );
    feature.onRegister();
  }
}
