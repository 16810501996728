<button class="bg-surface border border-solid border-primary rounded p-6 cursor-pointer shadow text-left w-full"
        [disabled]="disabled"
        (click)="startScan()">
  <div class="flex flex-row">
    <div class="flex items-center align-middle">
      <i class="fa-regular fa-barcode-scan fa-3x"></i>
    </div>
    <div class="flex flex-col ml-4">
      <div class="font-semibold text-xl">
        {{ label ? (label | translate) : ('#APPLICATION.SCANNER.BUTTON.TITLE' | translate) }}
      </div>
      <div class="color-light mt-1">
        {{'#APPLICATION.SCANNER.BUTTON.DESCRIPTION' | translate}}
      </div>
    </div>
  </div>
</button>
