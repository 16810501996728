import * as RemoteObjectStore from './remote-object.store';
import { createAction, createGetter, on, select } from '../../factories';
import { StoreFeature } from '../../store';
import {
  SOFTLINE_STORE_OBJECT_SERVICE,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
} from './object.shared';
import { handleSubscriptionState } from '../remote/subscription.store';
import {
  ActionState,
  handleObservableActionState,
} from '../remote/action.store';
import { SOFTLINE_SERVICE_UUID } from '../../../core.shared';
import { Dictionary } from '../../../types/dictionary';
import { lastValueFrom } from 'rxjs';

export interface CreateObjectActionParameters<T extends object = object> {
  data: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface UpdateObjectActionParameters<T extends object = object> {
  data: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface PatchObjectActionParameters<T extends object = object> {
  changes: Partial<T>;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface DeleteObjectActionParameters {
  payload?: unknown;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface State<T extends object = object>
  extends RemoteObjectStore.State<T> {}

export class Store<T extends object = object> {
  mutations = {
    ...this.objectStore.mutations,
  };

  actions = {
    ...this.objectStore.actions,
    create: createAction<State<T>, CreateObjectActionParameters, T>('create'),
    patch: createAction<State<T>, PatchObjectActionParameters, T>('patch'),
    update: createAction<State<T>, UpdateObjectActionParameters, T>('update'),
    delete: createAction<State<T>, DeleteObjectActionParameters, T>('delete'),
  };

  getters = {
    ...this.objectStore.getters,
    saving: createGetter<State<T>, boolean, string | undefined>('saving'),
    saved: createGetter<State<T>, boolean, string | undefined>('saved'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.objectStore.feature.initialState,
    },
    mutations: [...this.objectStore.feature.mutations],
    getters: [
      ...this.objectStore.feature.getters,
      select(this.getters.saving, ({ get, featureName, params }) => {
        const actions = [
          this.actions.create.name,
          this.actions.update.name,
          this.actions.patch.name,
          this.actions.delete.name,
        ];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.saved, ({ get, featureName, params }) => {
        const actions = [
          this.actions.create.name,
          this.actions.update.name,
          this.actions.patch.name,
          this.actions.delete.name,
        ];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
    actions: [
      ...this.objectStore.feature.actions,
      on(
        this.actions.create,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_REMOTE_OBJECT_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const subscriptionState$ = handleSubscriptionState(
            service.create(params.data, params.pathParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscriptionState$,
              featureName,
              commit,
              this.actions.create.name,
              token
            )
          );
          commit(featureName, this.mutations.set, result);
          return result;
        }
      ),
      on(
        this.actions.patch,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_REMOTE_OBJECT_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const subscriptionState$ = handleSubscriptionState(
            service.patch(params.changes, params.pathParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscriptionState$,
              featureName,
              commit,
              this.actions.patch.name,
              token
            )
          );
          commit(featureName, this.mutations.set, result);
          return result;
        }
      ),
      on(
        this.actions.update,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_REMOTE_OBJECT_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const subscriptionState$ = handleSubscriptionState(
            service.update(params.data, params.pathParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscriptionState$,
              featureName,
              commit,
              this.actions.update.name,
              token
            )
          );
          commit(featureName, this.mutations.set, result);
          return result;
        }
      ),
      on(
        this.actions.delete,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_REMOTE_OBJECT_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const subscriptionState$ = handleSubscriptionState(
            service.create(params.payload, params.pathParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscriptionState$,
              featureName,
              commit,
              this.actions.delete.name,
              token
            )
          );
          commit(featureName, this.mutations.unset);
          return result;
        }
      ),
    ],
  };

  constructor(private objectStore: RemoteObjectStore.Store<T>) {}
}

export function create<T extends object>(): Store<T> {
  const objectStore = RemoteObjectStore.create<T>();
  return new Store<T>(objectStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
