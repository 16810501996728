import {
  createAction,
  CustomStoreAddOn, FilterCollectionStore,
  on,
  SOFTLINE_STORE_ENTITY_SERVICE, SortCollectionStore,
  StoreBuilder,
  SyncedRemoteCollectionStore
} from '@softline/core';
import {BestellvorschlagBewe} from '../data/bestellvorschlag-bewe';
import {lastValueFrom} from 'rxjs';

const collectionStore = SyncedRemoteCollectionStore.create<BestellvorschlagBewe>()
type State = typeof collectionStore.feature.initialState;

const getters = {}
const mutations = {}
const actions = {
  createOrUpdate: createAction<State, Omit<BestellvorschlagBewe, 'id'>, BestellvorschlagBewe>('mdeBestellvorschlag/createOrUpdate')
}

export const BestellvorschlagBeweStoreExtension: CustomStoreAddOn<
  State,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  getters,
  mutations,
  actions,
  feature: {
    initialState: collectionStore.feature.initialState,
    getters: [],
    mutations: [],
    actions: [
      on(actions.createOrUpdate, async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
          const result = await lastValueFrom(service.create(params));
          commit(featureName, collectionStore.mutations.addOrUpdate, result);
          return result
      })
    ],
  }
}

export const BestellvorschlagBeweStore = new StoreBuilder(collectionStore)
  .add(BestellvorschlagBeweStoreExtension)
  .add(FilterCollectionStore.create<BestellvorschlagBewe>())
  .add(SortCollectionStore.create<BestellvorschlagBewe>())
  .value
