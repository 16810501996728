import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { from, Observable, of } from 'rxjs';
import { PdfViewerComponent } from '../../components/pdf-viewer.component';
import { Modal } from '@softline/ui-core';
import { AnnotationActionEventType } from 'pdfjs-dist/types/src/shared/util';
import Bl = AnnotationActionEventType.Bl;

@Component({
  selector: 'lib-pdf-viewer-dialog',
  standalone: true,
  imports: [CommonModule, PdfViewerComponent],
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss'],
})
export class PdfViewerDialogComponent implements OnInit, Modal<undefined> {
  close!: (result: undefined) => void;

  _document: Observable<Blob> | undefined;
  set document(value: Promise<Blob> | Observable<Blob> | Blob | undefined) {
    if (value instanceof Promise) this._document = from(value);
    else if (value instanceof Blob) this._document = of(value);
    else this._document = value;
  }

  constructor() {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: undefined) => void): void {
    this.close = handler;
  }
}
