import {
  createAction,
  CustomStoreAddOn,
  FilterCollectionStore,
  on,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SortCollectionStore,
  StoreBuilder,
  SyncedRemoteCollectionStore
} from '@softline/core';
import {MateriallieferscheinScanBewe} from '../types/materiallieferschein-scan-bewe';
import {lastValueFrom} from 'rxjs';
import {SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN} from '../materiallieferschein-scan.api';
import {MateriallieferscheinScanStore} from './materiallieferschein-scan.store';
import {SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN} from '../materiallieferschein-scan.shared';

const collectionStore = SyncedRemoteCollectionStore.create<MateriallieferscheinScanBewe>()
type State = typeof collectionStore.feature.initialState;

const getters = {}
const mutations = {}
const actions = {
  createOrUpdate: createAction<State, Omit<MateriallieferscheinScanBewe, 'id'>, MateriallieferscheinScanBewe>('mdeMateriallieferscheinScan/createOrUpdate')
}

export const MateriallieferscheinScanBeweStoreExtension: CustomStoreAddOn<
  State,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  getters,
  mutations,
  actions,
  feature: {
    initialState: collectionStore.feature.initialState,
    getters: [],
    mutations: [],
    actions: [
      on(actions.createOrUpdate, async ({ commit, get, featureName, params, injector }) => {
        const kopf = get(SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN, MateriallieferscheinScanStore.getters.kopf);

        if (!kopf)
          return;

        const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
        const result = await lastValueFrom(service.create(params, { idkopflf: kopf.id }));

        commit(featureName, collectionStore.mutations.addOrUpdate, result);
        return result;
      })
    ],
  }
}

export const MateriallieferscheinScanBeweStore = new StoreBuilder(collectionStore)
  .add(MateriallieferscheinScanBeweStoreExtension)
  .add(FilterCollectionStore.create<MateriallieferscheinScanBewe>())
  .add(SortCollectionStore.create<MateriallieferscheinScanBewe>())
  .value
