<ng-container *ngIf="definition">
  <ng-container *ngIf="!loading; else skeleton">
    <ul class="soft-list" *ngIf="!virtual; else virtualListTemplate">
      <li
        *ngFor="let item of items"
        (click)="toggleSelect(item); itemClick.emit(item)"
        [ngClass]="[
          'soft-list-item',
          'soft-list-item' + definition.type,
          (item | isSelected: selectedItem || selectedItems) ? 'selected' : '',
          itemClick.observed ? 'cursor-pointer' : ''
        ]"
      >
        <soft-dynamic-object
          [value]="item"
          [definition]="definition"
        ></soft-dynamic-object>
      </li>
    </ul>
  </ng-container>

  <ng-template #virtualListTemplate>
    <cdk-virtual-scroll-viewport
      *ngIf="virtual"
      [itemSize]="virtual.itemHeight"
      [ngClass]="virtual.class"
      [ngStyle]="{
        height:
          (virtual.display | virtualScrollItemDisplay: virtual.itemHeight) +
          'px'
      }"
    >
      <ul class="soft-list">
        <li
          *cdkVirtualFor="let item of items"
          (click)="toggleSelect(item)"
          [ngStyle]="{ height: virtual.itemHeight + 'px' }"
          [ngClass]="[
            'soft-list-item',
            'soft-list-item' + definition.type,
            (item | isSelected: selectedItem || selectedItems) ? 'selected' : ''
          ]"
        >
          <soft-dynamic-object
            [value]="item"
            [definition]="definition"
          ></soft-dynamic-object>
        </li>
      </ul>
    </cdk-virtual-scroll-viewport>
  </ng-template>

  <ng-template #skeleton>
    <ul class="soft-list">
      <li
        *softRepeat="skeletonRows"
        [ngClass]="['soft-list-item', 'soft-list-item' + definition.type]"
      >
        <soft-dynamic-object
          [loading]="true"
          [definition]="definition"
        ></soft-dynamic-object>
      </li>
    </ul>
  </ng-template>
</ng-container>
