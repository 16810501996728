import { Inject, Injectable } from '@angular/core';
import {
  Connection,
  createHttpResourceLocation,
  HttpLocationParameters,
  HttpService,
} from '@softline/core';
import { SOFTLINE_CONFIG_TEST_CONNECTION_PATH } from '../connection-settings.shared';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class TestConnectionService {
  constructor(
    private service: HttpService,
    @Inject(SOFTLINE_CONFIG_TEST_CONNECTION_PATH)
    private testConnectionPath: string
  ) {}

  async test(connection: Connection): Promise<boolean> {
    const httpLocationParams: HttpLocationParameters = {
      host: connection.host,
      port: connection.port,
      path: [connection.basePath ?? '', this.testConnectionPath],
    };
    const location = createHttpResourceLocation(httpLocationParams);
    try {
      await lastValueFrom(this.service.get(location));
      return true;
    } catch (e) {
      throw e;
    }
  }
}
