import { Entity } from '../types/entity';
import { EntityLocationParameters } from '../data/specialized/entity.location';
import { Dictionary } from '../../../../types/dictionary';
import { Observable } from 'rxjs';
import { Patch } from '../types/patch';
import { ResourceService } from '../../../../data/abstraction';
import { EntityService } from '../../../../softline/entity.service';

export class CommonEntityService<T extends Entity, TLocation>
  implements EntityService<T, T>
{
  constructor(
    protected service: ResourceService<TLocation>,
    protected createLocation: (params: EntityLocationParameters) => TLocation
  ) {}

  get(
    id: string | number,
    pathParams?: Dictionary<unknown>,
    payload?: unknown
  ): Observable<T> {
    const path = this.createLocation({ id, pathParams });
    return this.service.get(path, payload);
  }

  getMany(
    pathParams?: Dictionary<unknown>,
    queryParams?: Dictionary<unknown>
  ): Observable<T[]> {
    const path = this.createLocation({ pathParams, queryParams });
    return this.service.get(path);
  }

  create(
    entity: Partial<T> & Entity,
    pathParams?: Dictionary<unknown>
  ): Observable<T> {
    const path = this.createLocation({ id: entity.id, pathParams });
    return this.service.create(path, entity);
  }

  patch(patch: Patch<T>, pathParams?: Dictionary<unknown>): Observable<T> {
    const path = this.createLocation({ id: patch.id, pathParams });
    return this.service.patch(path, patch.changes);
  }

  delete(entity: T, pathParams?: Dictionary<unknown>): Observable<T> {
    const path = this.createLocation({ id: entity.id, pathParams });
    return this.service.delete(path);
  }

  update(entity: T, pathParams?: Dictionary<unknown>): Observable<T> {
    const path = this.createLocation({ id: entity.id, pathParams });
    return this.service.update(path, entity);
  }
}
