
<div class="text-lg font-semibold px-4 mt-2 sm:px-0">
  {{'#WWS_WARENUEBERNAHME.DIALOG.TITEL_EMBALLAGE_AUSWAEHLEN' | translate}}
</div>

<ng-container *ngIf="(emballagen$ | async) as emballagen">
  <div class="flex flex-col w-full py-2 h-full max-h-[80vh] overflow-y-auto">
    <div *ngFor="let emballage of emballagen; let last = last"
         class="flex py-4 cursor-pointer px-4 sm:px-0"
         (mouseover)="selectedEmballage = emballage"
         (mouseleave)="selectedEmballage = {}"
         [ngClass]="{'border-b border-solid': !last, 'bg-surface-tertiary': selectedEmballage?.id === emballage.id}"
         (click)="emballageHinzufuegen(emballage)">
      <div class="align-middle">
        {{emballage.bezeichnung}}
      </div>
    </div>
  </div>
</ng-container>

<button class="soft-button secondary w-full h-16" (click)="close('DISMISSED')">{{'#WWS_WARENUEBERNAHME.DIALOG.LABEL_ABBRECHEN' | translate}}</button>
