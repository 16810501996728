import {
  AfterContentInit,
  Component,
  ComponentRef,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef, HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewContainerRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { equals } from '@softline/core';
import { Subscription } from 'rxjs';
import { SelectOptionBase } from '../select/select-option/select-option-base';
import { SelectOptionHeaderDirective } from '../select/select-option/select-option-header.directive';
import { SelectOptionSeparatorDirective } from '../select/select-option/select-option-separator.directive';
import { SelectOptionDirective } from '../select/select-option/select-option.directive';

@Component({
  selector: 'soft-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent
  implements OnInit, OnDestroy, AfterContentInit, ControlValueAccessor
{
  private subscription?: Subscription;

  temporaryOptions: SelectOptionDirective[] = [];
  selectedOptions: SelectOptionDirective[] = [];
  _isOpen: boolean = false;
  panelTop: number = 0;
  displayAll = false;
  isOpen = false;
  @ContentChildren(SelectOptionBase) options!: QueryList<any>;

  private onChange: Function = () => {};
  private onTouch: Function = () => {};
  private _value: any[] = [];

  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() placeholder?: string;
  @Input() maxDisplayed: number = Number.POSITIVE_INFINITY;

  get value(): any[] {
    return this.selectedOptions.map((o) => o.value);
  }
  @Input()
  set value(values: any[]) {
    this._value = values;
    this.selectedOptions = this.getSelectedOptions(values);

    this.onChange(values);
    this.onTouch();
  }
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  get selectableOptions(): SelectOptionDirective[] {
    return this.options.filter((o) => this.isOption(o) && !o.disabled);
  }

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.selectedOptions = this.getSelectedOptions(this._value);
    this.subscription = this.options.changes.subscribe((changes) => {
      this.selectedOptions = this.getSelectedOptions(this._value);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this._value = obj;
    this.selectedOptions = this.getSelectedOptions(obj);
  }

  onClick(): void {
    if (this.readonly) {
      this.isOpen = false;
      return;
    }
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.panelTop =
        this.viewContainerRef.element.nativeElement.offsetHeight - 1;
      this.temporaryOptions = [...this.selectedOptions];
    }
  }

  onSelect(option: SelectOptionDirective): void {
    const options = [...this.temporaryOptions];
    const index = this.temporaryOptions.indexOf(option);
    if (index > -1) options.splice(index, 1);
    else options.push(option);
    this.temporaryOptions = options;
  }

  selectAll(): void {
    this.temporaryOptions = this.selectableOptions;
  }

  selectNone(): void {
    this.temporaryOptions = [];
  }

  onDelete(option: SelectOptionDirective): void {
    const options = [...this.selectedOptions];
    const index = options.indexOf(option);
    options.splice(index, 1);
    this.selectedOptions = options;
    this.onChange(this._value);
    this.onTouch();
    this.valueChange.emit(this._value);
  }

  onSubmitPanel(): void {
    this.selectedOptions = [...this.temporaryOptions];
    this._value = this.selectedOptions.map((o) => o.value);
    this.isOpen = false;
    this.onChange(this._value);
    this.onTouch();
    this.valueChange.emit(this._value);
  }

  onOutsideClick(): void {
    if (this.isOpen) this.isOpen = false;
    this.onTouch();
  }

  isOption(value: SelectOptionBase): value is SelectOptionDirective {
    return value instanceof SelectOptionDirective;
  }
  isSeparator(
    value: SelectOptionBase
  ): value is SelectOptionSeparatorDirective {
    return value instanceof SelectOptionSeparatorDirective;
  }
  isHeader(value: SelectOptionBase): value is SelectOptionHeaderDirective {
    return value instanceof SelectOptionHeaderDirective;
  }

  private getSelectedOptions(values: any[]): SelectOptionDirective[] {
    const selectedOptions: SelectOptionDirective[] = [];
    for (const value of values ?? []) {
      const option = this.options?.find((o) => equals(o.value, value));
      if (option) selectedOptions.push(option);
    }
    return selectedOptions;
  }
}
