import {Step} from '@softline/application';

export const SOFTLINE_CONFIG_MDE_ARTIKELVORRAT_STEPS: Step[] = [
  {
    header: 'Artikel suchen',
    subHeader: 'Bitte gewünschten Artikel auswählen',
  },
  {
    header: 'Artikeldaten',
    subHeader: 'Bitte passen Sie die Artikeldaten an',
  }
];

export const SOFTLINE_FEATURE_MDE_ARTIKELVORRAT = 'mdeArtikelvorrat'
export const SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE = 'mdeArtikelvorratBewe'
export const SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_ARTIKEL_DETAILS = 'mdeArtikelvorratArtikelDetails';
export const SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_OFFENE_KOEPFE= 'mdeArtikelvorratOffeneKopefe';

export const SOFTLINE_PERMISSION_MDE_ARTIKELVORRAT = 'ARTIKELVORRAT';
