import { computed } from '@angular/core';
import { InjectableStore2Feature } from '../../injectable-store2-feature';

export class ObjectStore2<T> extends InjectableStore2Feature<T | null> {

  value = computed(() => this.state());

  constructor() {
    super();
  }

  set(value: T): void {
    this.commit(value);
  }

  patch(value: T): void {
    this.commitPatch(value);
  }

  clear(): void {
    this.commit(null);
  }

  override getDefaultState(): T | null {
    return null;
  }
}
