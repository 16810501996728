import { createAction, createGetter, on, select } from '../../../factories';
import * as RemoteCollectionStore from './remote-collection.store';
import * as RemoteCollectionDetailStore from './remote-collection-detail.store';
import * as ActionStore from '../../remote/action.store';
import { StoreFeature } from '../../../store';
import {
  SOFTLINE_STORE_DETAIL_SERVICE_FACTORY,
  SOFTLINE_STORE_ENTITY_SERVICE,
} from '../single/entity.shared';
import { Entity } from '../types/entity';
import { Dictionary } from '../../../../types/dictionary';
import { Patch } from '../types/patch';
import { handleSubscriptionState } from '../../remote/subscription.store';
import {
  ActionState,
  handleObservableActionState,
} from '../../remote/action.store';
import { SOFTLINE_SERVICE_UUID } from '../../../../core.shared';
import * as KeyStore from '../../remote/key.store';
import * as ParamsStore from '../../remote/params.store';
import { lastValueFrom } from 'rxjs';

export interface CreateDetailActionParameters<
  T,
  K extends keyof T & string = keyof T & string
> {
  id: number | string;
  name: K;
  detail: T[K];
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface UpdateDetailActionParameters<
  T,
  K extends keyof T & string = keyof T & string
> {
  id: number | string;
  name: K;
  detail: T[K];
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface PatchDetailActionParameters<
  T,
  K extends keyof T & string = keyof T & string
> {
  patch: Patch<T[K]>;
  name: K;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface DeleteDetailActionParameters<
  T,
  K extends keyof T & string = keyof T & string
> {
  id: number | string;
  name: K;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface State<T extends object = object>
  extends RemoteCollectionDetailStore.State<T> {}

export class Store<TEntity extends Entity, T extends object = object> {
  mutations = {
    ...this.detailStore.mutations,
  };

  getters = {
    ...this.detailStore.getters,
    savingDetail: createGetter<State<T>, boolean>('savingDetail'),
    savedDetail: createGetter<State<T>, boolean>('savedDetail'),
  };

  actions = {
    ...this.detailStore.actions,
    createDetail: createAction<State, CreateDetailActionParameters<T>, T>(
      'createDetail'
    ),
    updateDetail: createAction<State, UpdateDetailActionParameters<T>, T>(
      'updateDetail'
    ),
    patchDetail: createAction<State, PatchDetailActionParameters<T>, T>(
      'patchDetail'
    ),
    deleteDetail: createAction<State, DeleteDetailActionParameters<T>, T>(
      'deleteDetail'
    ),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.detailStore.feature.initialState,
    },
    mutations: [...this.detailStore.feature.mutations],
    getters: [
      ...this.detailStore.feature.getters,
      select(this.getters.savingDetail, ({ get, featureName, params }) => {
        const actions = [
          this.actions.createDetail.name,
          this.actions.patchDetail.name,
          this.actions.updateDetail.name,
          this.actions.deleteDetail.name,
        ];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, ActionStore.getters.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.savedDetail, ({ get, featureName, params }) => {
        const actions = [
          this.actions.createDetail.name,
          this.actions.patchDetail.name,
          this.actions.updateDetail.name,
          this.actions.deleteDetail.name,
        ];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, ActionStore.getters.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
    actions: [
      ...this.detailStore.feature.actions,
      on(
        this.actions.createDetail,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const observable = service.create(
            params.id,
            params.detail,
            params.pathParams
          );
          const subscription$ = handleSubscriptionState(
            observable,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.createDetail.name,
              token
            )
          );
          commit(featureName, this.mutations.addDetail, {
            id: params.id,
            name: params.name,
            value: result,
          });
          return result;
        }
      ),
      on(
        this.actions.patchDetail,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const observable = service.patch(params.patch, params.pathParams);
          const subscription$ = handleSubscriptionState(
            observable,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.patchDetail.name,
              token
            )
          );
          commit(featureName, this.mutations.updateDetail, {
            id: params.patch.id,
            name: params.name,
            value: result,
          });
          return result;
        }
      ),
      on(
        this.actions.updateDetail,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const observable = service.update(
            params.id,
            params.detail,
            params.pathParams
          );
          const subscription$ = handleSubscriptionState(
            observable,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.updateDetail.name,
              token
            )
          );
          commit(featureName, this.mutations.updateDetail, {
            id: params.id,
            name: params.name,
            value: result,
          });
          return result;
        }
      ),
      on(
        this.actions.deleteDetail,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const observable = service.delete(params.id, params.pathParams);
          const subscription$ = handleSubscriptionState(
            observable,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.deleteDetail.name,
              token
            )
          );
          commit(featureName, this.mutations.removeDetail, {
            id: params.id,
            name: params.name,
          });
          return result;
        }
      ),
    ],
  };

  constructor(
    private detailStore: RemoteCollectionDetailStore.Store<TEntity, T>
  ) {}
}

export function create<
  TEntity extends Entity,
  T extends object = object
>(): Store<TEntity, T> {
  const detailStore = RemoteCollectionDetailStore.create<TEntity, T>();
  return new Store<TEntity, T>(detailStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
