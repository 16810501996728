import { Observable } from 'rxjs';

export interface ShellMenuItem {
  icon: string;
  name: string;
  routerLink?: any[] | string;
  canExecute?: Observable<boolean>;
  badge?: string;
  class?: string;
  execute?(): void;
}
