import { Patch } from '../../../store/specialized/entity/types/patch';
import { Dictionary } from '../../../types/dictionary';
import { computed, inject, Inject, Injectable } from '@angular/core';
import { NestedStore2Feature } from '../../nested-store2-feature';
import { SOFTLINE_FEATURE_ID_FUNC } from '../../store2.shared';


export interface CollectionState<T extends object> {
  ids: (string | number)[];
  dict: Dictionary<T>
}

@Injectable()
export class CollectionStore2<T extends object> extends NestedStore2Feature<CollectionState<T>> {

  all = computed(() => {
    return this.state().ids
      .map(id => this.state().dict[id])
      .filter(item => item !== undefined)
      .map(item => item as T)
  });
  count = computed(() => this.state().ids.length);
  dict = computed(() => {
    return this.state().dict;
  });

  protected readonly getId = inject(SOFTLINE_FEATURE_ID_FUNC, {optional: true}) ?? ((item) => item['id'])

  constructor() {
    super();
  }

  add(value: T): void {
    const id = this.getId(value);
    const index = this.state().ids.indexOf(id);
    if (index > -1)
      throw new Error(
        `[CollectionStore] add: item with id ${id} already exists in the collection.`
      );
    const ids = [...this.state().ids, value[id]];
    const dict = { ...this.state().dict, [id]: value };
    this.commitPatch({ ids, dict });
  }

  addMany(values: T[]): void {
    const state = this.state();
    const ids = [...state.ids];
    let dict = { ...state.dict };

    const duplicateIds: (string | number)[] = [];
    for (const value of values) {
      const id = this.getId(value);
      const index = state.ids.indexOf(id);
      if (index > -1)
        duplicateIds.push(id);
      ids.push(id);
      dict = { ...dict, [id]: value };
    }
    if (duplicateIds.length > 0)
      throw new Error(
        `[CollectionStore] addMany: items with ids ${duplicateIds.join(',')} already exists in the collection.`
      );
    this.commitPatch({ ids, dict });
  }

  patch(patch: Patch<T>): void {
    const state = this.state();
    const ids = [...state.ids];
    const dict = { ...state.dict };
    const index = ids.indexOf(patch.id);
    if (index === -1)
      throw new Error(
        `[CollectionStore] patch: item with id ${patch.id} does not exist in the collection.`
      );
    const newId = this.getId(patch.changes as T) ?? patch.id;
    const newValue = {...dict[patch.id], ...patch.changes} as T;
    if(patch.id !== newId) {
      ids.splice(index, 1, newId);
      delete dict[patch.id]
    }
    this.commit({
      ...this.state(),
      ids,
      dict: {...this.state().dict, [newId]: newValue}
    });
  }

  patchMany(patches: Patch<T>[]): void {
    const state = this.state();
    const ids = [...state.ids];
    const dict = { ...state.dict };

    const notFoundIds: (string | number)[] = [];
    for (const patch of patches) {
      const index = ids.indexOf(patch.id);
      if (index === -1) {
        notFoundIds.push(patch.id);
        continue;
      }
      const newId = this.getId(patch.changes as T) ?? patch.id;
      const newValue = { ...dict[patch.id], ...patch.changes } as T;
      if (patch.id !== newId) {
        ids.splice(index, 1, newId);
        delete dict[patch.id]
      }
      dict[newId] = newValue;
    }
    if (notFoundIds.length > 0)
      throw new Error(
        `[CollectionStore] patchMany: items with ids ${notFoundIds.join(',')} not found in the collection.`
      );

    this.commit({ ...this.state(), ids, dict });
  }

  update(value: T): void {
    const state = this.state();
    const ids = [...state.ids];
    const dict = { ...state.dict };

    const id = this.getId(value);
    const index = ids.indexOf(id);
    if (index === -1)
      throw new Error(
        `[CollectionStore] update: item with id ${id.id} does not exist in the collection.`
      );
    dict[id] = value;
    this.commit({ ...this.state(), ids, dict });
  }

  updateMany(values: T[]): void {
    const state = this.state();
    const ids = [...state.ids];
    const dict = { ...state.dict };

    const notFoundIds: (string | number)[] = [];
    for (const value of values) {
      const id = this.getId(value);
      const index = ids.indexOf(id);
      if (index === -1) {
        notFoundIds.push(id);
        continue;
      }
      dict[id] = value;
    }
    if (notFoundIds.length > 0)
      throw new Error(
        `[CollectionStore] updateMany: items with ids ${notFoundIds.join(',')} not found in the collection.`
      );

    this.commit({ ...this.state(), ids, dict });
  }

  remove(value: T): void {
    const id = this.getId(value);
    const index = this.state().ids.indexOf(id);
    if (index === -1)
      throw new Error(
        `[CollectionStore] remove: item with id ${id} does not exist in the collection.`
      );
    const ids = [...this.state().ids, value[id]];
    const dict = { ...this.state().dict, [id]: value };
    this.commitPatch({ ids, dict });
  }

  removeMany(values: T[]): void {
    const state = this.state();
    const ids = [...state.ids];
    const dict = { ...state.dict };

    const notFoundIds: (string | number)[] = [];
    for (const value of values) {
      const id = this.getId(value);
      const index = ids.indexOf(id);
      if (index === -1) {
        notFoundIds.push(id);
        continue;
      }
      delete dict[id];
      ids.splice(index, 1);
    }
    if (notFoundIds.length > 0)
      throw new Error(
        `[CollectionStore] removeMany: items with ids ${notFoundIds.join(',')} not found in the collection.`
      );

    this.commit({ ...this.state(), ids, dict });
  }

  addOrUpdate(value: T): void {
    const state = this.state();
    const ids = [...state.ids];
    const dict = { ...state.dict };

    const id = this.getId(value);
    const index = ids.indexOf(id);
    if (index === -1)
      ids.push(id);
    dict[id] = value;
    this.commit({ ...this.state(), ids, dict });
  }

  addOrUpdateMany(values: T[]): void {
    const state = this.state();
    const ids = [...state.ids];
    const dict = { ...state.dict };

    for (const value of values) {
      const id = this.getId(value);
      const index = ids.indexOf(id);
      if (index === -1)
        ids.push(id);
      dict[id] = value;
    }
    this.commit({ ...this.state(), ids, dict });
  }

  clear(): void {
    this.commit({
      ...this.state(),
      ids: [],
      dict: { }
    });
  }

  override getDefaultState(): CollectionState<T> {
    return {
      ids: [],
      dict: {}
    };
  }
}
