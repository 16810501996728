import { Subscription } from 'rxjs';

export type TransactionState =
  | 'pending'
  | 'processing'
  | 'succeeded'
  | 'failed'
  | 'cancelled';
export type Transaction<T = unknown, TLocation = unknown> =
  | CommonTransaction<T, TLocation>
  | ObservedTransaction<T, TLocation>
  | ReportingTransaction<T, TLocation>;

export interface CommonTransaction<T, TLocation> {
  id: string;
  type: 'basic';
  state: TransactionState;
  location: TLocation;
  params?: T;
}

export interface ObservedTransaction<T, TLocation> {
  id: string;
  type: 'observed';
  state: TransactionState;
  location: TLocation;
  params?: T;
  subscription: Subscription | null;
}

export interface ReportingTransaction<T, TLocation> {
  id: string;
  type: 'reporting';
  state: TransactionState;
  location: TLocation;
  params?: T;
  subscription?: Subscription | null;
  sent: boolean;
  upstream?: UpstreamTransactionProgress;
  downstream?: DownstreamTransactionProgress;
}

export type TransactionProgress =
  | UpstreamTransactionProgress
  | DownstreamTransactionProgress;

export interface UpstreamTransactionProgress {
  direction: 'upstream';
  loaded: number;
  total: number | null;
}

export interface DownstreamTransactionProgress {
  direction: 'downstream';
  loaded: number;
  total: number | null;
}

export function isObservedTransaction<T, TLocation>(
  value: Transaction<T, TLocation>
): value is ObservedTransaction<T, TLocation> {
  return value.type === 'observed';
}
export function isReportingTransaction<T, TLocation>(
  value: Transaction<T, TLocation>
): value is ReportingTransaction<T, TLocation> {
  return value.type === 'reporting';
}
