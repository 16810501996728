import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {SOFTLINE_PERMISSION_WWS_KREDITLIMIT,} from './kreditlimit.shared';

export const kreditlimitRoutes: Routes = [
  {
    path: 'kreditlimit',
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/kreditlimit-host.component'),
        children: [
          { path: '**', redirectTo: '' },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_WWS_KREDITLIMIT },
    providers: [],
  },
];
