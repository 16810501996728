import { SortDirection } from '@softline/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum',
  standalone: true
})
export class SumPipe implements PipeTransform {
  transform<T>(
    array: readonly T[] | null | undefined,
    field?: keyof T,
  ): number {
    let value = 0;
    if (!array || !Array.isArray(array))
      return value;

    const values = typeof array === "object"
      ? array.map(o => !!o ? o[field] : 0)
      : array;

    return values.reduce((p, c) => p + c, 0)
  }
}
