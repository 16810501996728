<ng-container *ngIf="form && definition">
  <h5>{{ definition.title }}</h5>
  <!--<soft-dynamic-form></soft-dynamic-form>-->
  <soft-validation-container
    class="soft-property-validation"
    *ngIf="form.controls[definition.name].touched"
    [validation]="form | combineValidations: definition.name"
  ></soft-validation-container>
  <hr />
</ng-container>
