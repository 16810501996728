import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { SwipeAction } from "../swipe-action";
import { animate, style, transition, trigger } from "@angular/animations";
import { I18nModule } from "../../../i18n/i18n.module";


@Component({
  selector: 'soft-swipe-action',
  standalone: true,
  imports: [CommonModule, I18nModule],
  templateUrl: './swipe-action.component.html',
  styleUrls: ['./swipe-action.component.scss'],
  animations: [
    trigger('enterLeave', [
      transition(':enter', [
        style({ transform: 'scale(0.1)', opacity: 0 }),
        animate('0.3s ease-out', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1 }),
        animate('0.2s ease-in', style({ transform: 'scale(0.1)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class SwipeActionComponent implements OnInit {

  @Input() action!: SwipeAction;

  constructor() { }

  ngOnInit(): void {
  }

}
