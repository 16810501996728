import {
  Inject,
  InjectionToken,
  Injector,
  ModuleWithProviders,
  NgModule,
  Optional,
  Provider,
  Self,
  SkipSelf,
  StaticProvider,
} from '@angular/core';
import { Store, StoreFeature } from './store';
import { mapMany } from '../functions/map-many.function';

export interface RootStoreConfig {
  features?: StoreConfig[];
}

export interface StoreConfig {
  name: string;
  feature: StoreFeature<any>;
  providers?: StaticProvider[];
}

const STORE_ROOT_CONFIG = new InjectionToken<RootStoreConfig>(
  'STORE_ROOT_CONFIG'
);
const STORE_CONFIG = new InjectionToken<StoreConfig>('STORE_CONFIG');

export class StoreModule {
  static forRoot(
    config?: RootStoreConfig
  ): ModuleWithProviders<StoreRootModule> {
    return {
      ngModule: StoreRootModule,
      providers: [Store, { provide: STORE_ROOT_CONFIG, useValue: config }],
    };
  }

  static forFeature(
    config: StoreConfig
  ): ModuleWithProviders<StoreFeatureModule> {
    return {
      ngModule: StoreFeatureModule,
      providers: [{ provide: STORE_CONFIG, useValue: config, multi: true }],
    };
  }
}

@NgModule()
export class StoreRootModule {
  constructor(
    private store: Store,
    injector: Injector,
    @Optional() @Self() @Inject(STORE_ROOT_CONFIG) config?: RootStoreConfig,
    @Optional() @SkipSelf() parentModule?: StoreRootModule
  ) {
    if (parentModule)
      throw new Error(
        'StoreRootModule is already loaded. Import it in the AppModule only'
      );

    for (const feature of config?.features ?? []) {
      const storeInjector = Injector.create({
        providers: feature?.providers ?? [],
        parent: injector,
        name: feature.name,
      });
      store.registerFeature(feature.name, feature.feature, storeInjector);
    }
  }
}

@NgModule()
export class StoreFeatureModule {
  constructor(
    private store: Store,
    injector: Injector,
    @Self() @Inject(STORE_CONFIG) configs: StoreConfig[]
  ) {
    for (const feature of configs) {
      const storeInjector = Injector.create({
        providers: feature?.providers ?? [],
        parent: injector,
        name: feature.name,
      });
      store.registerFeature(feature.name, feature.feature, storeInjector);
    }
  }
}
