import {Component, Inject, input, OnInit, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainChartComponent} from '../../components/main-chart/main-chart.component';
import {toSignal} from '@angular/core/rxjs-interop';
import {BehaviorSubject, combineLatestWith, filter, firstValueFrom, map} from 'rxjs';
import {ConnectionResourceLocation, ResourceService, SOFTLINE_SERVICE_HTTP, Store} from '@softline/core';
import {HttpErrorResponse} from '@angular/common/http';
import {handleRequestErrors, RemoteConfigStore, SOFTLINE_FEATURE_REMOTE_CONFIG} from '@softline/application';
import {UmsatzstatistikQuery} from '../../types/umsatzstatistik.query';
import moment from 'moment';
import {I18nModule, UiCoreModule} from '@softline/ui-core';
import {Umsatzstatistik} from '../../types/umsatzstatistik';
import {UmsatzstatistikService} from '../../services/umsatzstatistik-service';
import {extractUmsatzstatistikRemoteConfig} from '../../utils/extract-remote-config';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from '@softline/auth';
import {SOFTLINE_API_WWS_UMSATZABFRAGE_VKFORG} from '../../umsatzstatistik.api';
import {toChartData} from '../../utils/to-chart-data';

@Component({
  selector: 'soft-umsatzstatistik-full-width-widget',
  standalone: true,
  imports: [CommonModule, MainChartComponent, I18nModule, UiCoreModule],
  templateUrl: './umsatzstatistik-full-width-widget.component.html',
  styleUrl: './umsatzstatistik-full-width-widget.component.scss',
})
export class UmsatzstatistikFullWidthWidgetComponent implements OnInit {

  readonly vondatum = signal(moment(new Date().toISOString()).startOf('year').toISOString());
  readonly bisdatum = signal(moment(new Date().toISOString()).endOf('month').toISOString());

  readonly data$ = new BehaviorSubject<Umsatzstatistik | null>(null);

  readonly loading = signal(false);
  readonly idktostamm = input<number | null>(null);

  readonly chartData = toSignal(
    this.data$.pipe(
      filter((o): o is Umsatzstatistik => !!o),
      map(o => o.ebeneresult),
      combineLatestWith(
        this.store.observe(SOFTLINE_FEATURE_REMOTE_CONFIG, RemoteConfigStore.getters.data).pipe(
          map(o => extractUmsatzstatistikRemoteConfig(o))
        )
      ),
      map(([umsatzstatistik, remoteConfig]) =>
        toChartData(umsatzstatistik, remoteConfig)
      )
    ), { initialValue: [] }
  );

  constructor(
    private readonly store: Store,
    private readonly service: UmsatzstatistikService,
    @Inject(SOFTLINE_SERVICE_HTTP)
    private readonly httpService: ResourceService<ConnectionResourceLocation>,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const remoteConfig = await this.store.dispatch(
        SOFTLINE_FEATURE_REMOTE_CONFIG,
        RemoteConfigStore.actions.load
      );

      const umsatzstatistikRemoteConfig = extractUmsatzstatistikRemoteConfig(remoteConfig);

      const query = {
        ebene: { umsatzebene: umsatzstatistikRemoteConfig?.defaultEbene ?? 'Vkforg'  },
        umsatzart: { umsatzart: umsatzstatistikRemoteConfig?.defaultUmsatzart ?? 'Gesamt' },
        aufgliederung: { name: 'MONAT', umsatzaufgliederung: 'Monate' },
        vondatum: this.vondatum(),
        bisdatum: this.bisdatum(),
        filiale: null
      }

      this.loading.set(true);
      const result = await this.loadUmsatzstatistik(query);
      this.data$.next(result);
      this.loading.set(false);
    } catch (e) {
      this.loading.set(false);

      if (!(e instanceof HttpErrorResponse))
        return;

      handleRequestErrors(this.store, e);
    }
  }

  private async loadUmsatzstatistik(query: Partial<UmsatzstatistikQuery>): Promise<Umsatzstatistik> {
    const accountId = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    )?.['accountid'];

    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_WWS_UMSATZABFRAGE_VKFORG,
      queryParams: (this.idktostamm() ?? accountId)
        ? { idktostamm: (this.idktostamm() ?? accountId) }
        : undefined
    }

    const filialen = await firstValueFrom(this.httpService.get(location));

    const requestBody = {
      ebene: query?.ebene?.umsatzebene,
      umsatzart: query?.umsatzart?.umsatzart,
      aufgliederung: query?.aufgliederung?.name,
      filialen: query?.filiale !== null ? [query.filiale] : filialen,
      vondat: query?.vondatum,
      bisdat: query?.bisdatum,
      menge: 2,
      vergleich: true
    }

    return await firstValueFrom(this.service.loadUmsatzstatistik(requestBody));
  }
}
