import { EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export abstract class CheckOption implements ControlValueAccessor {
  abstract checked: boolean;
  abstract value: any;
  abstract trueValue: any;
  abstract falseValue: any;

  abstract registerOnChange(fn: any): void;
  abstract registerOnTouched(fn: any): void;
  abstract writeValue(obj: any): void;
  abstract setReadonlyState(readonly: boolean): void;
}

export abstract class CheckGroup {
  abstract value: any;
  abstract valueChange: EventEmitter<any>;
  abstract readonly: boolean;
}
