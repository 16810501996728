<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item['kostenstelle']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item['kontotext1']}}</span>
  </div>
  <div class="flex flex-row">
    <span>{{item['kontotext2']}}</span>
  </div>
</div>
