<div *ngIf="install$ | async as install" class="flex flex-col gap-4">
  <div class="flex flex-row justify-between items-center w-full">
    <div class="flex flex-row items-center gap-2">
      <div class="text-primary min-w-[2rem]">
        <ng-container *ngIf="install.hasServiceWorker">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </ng-container>
      </div>
      <div class="flex flex-col">
        <div class="font-semibold">{{'#PWA.INSTALL.SETTINGS.1_SERVICE_WORKER.TITLE' | translate}}</div>
        <div *ngIf="install.hasServiceWorker" class="text-light">{{'#PWA.INSTALL.SETTINGS.1_SERVICE_WORKER.UNREGISTER_TEXT' | translate}}</div>
        <div *ngIf="!install.hasServiceWorker" class="text-light">{{'#PWA.INSTALL.SETTINGS.1_SERVICE_WORKER.REGISTER_TEXT' | translate}}</div>
      </div>
    </div>
    <div>
      <button *ngIf="install.hasServiceWorker" class="soft-button link" (click)="unregisterSW()">{{'#PWA.INSTALL.SETTINGS.1_SERVICE_WORKER.UNREGISTER_BUTTON' | translate}}</button>
      <button *ngIf="!install.hasServiceWorker" class="soft-button primary" (click)="registerSW()">{{'#PWA.INSTALL.SETTINGS.1_SERVICE_WORKER.REGISTER_BUTTON' | translate}}</button>
    </div>
  </div>
  <hr class="soft-seperator">
  <div class="flex flex-row justify-between items-center w-full">
    <div class="flex flex-row items-center gap-2">
      <div class="text-primary min-w-[2rem]">
        <ng-container *ngIf="install.isPwa || install.installed || install.relatedApps.length > 0">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </ng-container>
      </div>
      <div class="flex flex-col">
        <div class="font-semibold">{{'#PWA.INSTALL.SETTINGS.2_APP.TITLE' | translate}}</div>
        <ng-container *ngIf="platform.IOS">
          <div class="mt-4">{{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.IOS.DESC' | translate}}</div>
          <ol class="list-[upper-roman] mt-4">
            <li>
              {{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.IOS.SHARE' | translate}}
              <div class="flex flex-row font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 h-4 mr-1">
                  <path d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z"/>
                </svg>
                {{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.IOS.SHARE_ACTION' | translate}}
              </div>
            </li>
            <li>
              {{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.IOS.ADD' | translate}}
              <div class="flex flex-row font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  class="w-4 h-4 mr-1">
                  <path d="m18 3h-12a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-12a3 3 0 0 0 -3-3zm1 15a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1-1v-12a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1z"/><path d="m15 11h-2v-2a1 1 0 0 0 -2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2z"/>
                </svg>
                {{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.IOS.ADD_ACTION' | translate}}
              </div>
            </li>
          </ol>
        </ng-container>
        <ng-container *ngIf="platform.BLINK">
          <ng-container *ngIf="install.isPwa || install.installed">
            <div class="text-light">{{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.DEFAULT.INSTALLED_DESC' | translate}}</div>
          </ng-container>
          <ng-container *ngIf="!install.isPwa && !install.installed">
            <div class="text-light">{{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.DEFAULT.INSTALL_DESC' | translate}}</div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!platform.IOS && !platform.ANDROID && !platform.EDGE && !platform.BLINK">
          <div class="text-light">{{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.NOT_SUPPORTED.NOT_SUPPORTED' | translate}}</div>
          <div class="mt-4">{{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.NOT_SUPPORTED.DESC' | translate}}</div>
          <ol class="list-[upper-roman] mt-4">
            <li>{{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.NOT_SUPPORTED.BROWSER_DOWNLOAD' | translate}}</li>
            <li>{{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.NOT_SUPPORTED.BROWSER_OPEN' | translate}}</li>
            <li>{{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL.NOT_SUPPORTED.BROWSER_NAVIGATE' | translate}}</li>
          </ol>
        </ng-container>
      </div>
    </div>
    <div>
      <button *ngIf="platform.BLINK && !install.isPwa && install.relatedApps.length === 0"
              [disabled]="!install.hasServiceWorker || install.canInstall === false"
              class="soft-button primary"
              (click)="installApp()">
        {{ (install.installed ? '#PWA.INSTALL.SETTINGS.2_APP.INSTALLED_BUTTON' : '#PWA.INSTALL.SETTINGS.2_APP.INSTALL_BUTTON') | translate }}
      </button>
      <button *ngIf="platform.BLINK && install.relatedApps.length > 0"
              class="soft-button primary" (click)="share()">
        {{'#PWA.INSTALL.SETTINGS.2_APP.OPEN_BUTTON' | translate}}
      </button>
      <!--<button *ngIf="installation.isPwa || installation.relatedApps.length > 0" class="soft-button link" (click)="unregisterNgServiceWorker()">{{'#PWA.INSTALL.SETTINGS.2_APP.INSTALL_BUTTON' | translate}}</button>
      <button *ngIf="!installation.isPwa && installation.relatedApps.length === 0" class="soft-button primary" (click)="registerNgServiceWorker()">{{'#PWA.INSTALL.SETTINGS.2_APP.OPEN_BUTTON' | translate}}</button>
      -->
    </div>
  </div>
</div>


