import { Component, OnInit } from '@angular/core';
import { StringDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
  selector: 'soft-dynamic-draw-input',
  templateUrl: './draw-input.component.html',
  styleUrls: ['./draw-input.component.scss'],
})
export class DrawInputComponent
  extends DynamicInputAtom<StringDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
