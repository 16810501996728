import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, ConnectionResourceLocation, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {Observable} from 'rxjs';
import {SOFTLINE_API_MDE_WARENVERPROBUNG, SOFTLINE_API_MDE_WARENVERPROBUNG_ABSCHLUSS} from '../warenverprobung.api';
import {WarenverprobungKopf} from '../data/warenverprobung-kopf';

@Injectable()
export class WarenverprobungService {

  private get location(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_WARENVERPROBUNG_ABSCHLUSS,
    }
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService
  ) {}

  abschluss(payload: object): Observable<void> {
    return this.httpService.create(this.location, payload);
  }

  removeBeleg(): Observable<unknown> {
    return this.httpService.delete({
      path: SOFTLINE_API_MDE_WARENVERPROBUNG
    });
  }

  loadKopf(): Observable<WarenverprobungKopf> {
    return this.httpService.get({
      path: SOFTLINE_API_MDE_WARENVERPROBUNG
    });
  }

  createBeleg(
    data: {
      id: number,
      variante: 'BESTELLUNG' | 'WARENUEBERNAHME',
      bezugsnummer: string
    }[]
  ): Observable<WarenverprobungKopf> {
    return this.httpService.create<any[], WarenverprobungKopf>({
      path: SOFTLINE_API_MDE_WARENVERPROBUNG
    }, data);
  }
}
