import {
  Component,
  EventEmitter,
  forwardRef, HostBinding,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { serializeHtmlCharacterEntitiesFunction } from '../../../functions/html-character-entities.function';

@Component({
  selector: 'soft-multiline-input',
  templateUrl: './multiline-input.component.html',
  styleUrls: ['./multiline-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultilineInputComponent),
      multi: true,
    },
  ],
})
export class MultilineInputComponent implements OnInit {
  valueLines = 1;

  private onChange: Function = () => {};
  onTouch: Function = () => {};

  @Input() value: string | null | undefined;
  @Output() valueChange: EventEmitter<string | null | undefined> =
    new EventEmitter<string | null | undefined>();

  @Input() placeholder?: string | null;
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() customClass = '';
  @Input() escapeHtml = false;

  @Input() canResize = false;
  @Input() lines: number | 'auto' = 'auto';
  @Input() maxLines: number = Number.POSITIVE_INFINITY;

  constructor() {}

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setValue(value: string | null): void {
    if (value && this.escapeHtml)
      value = serializeHtmlCharacterEntitiesFunction(value);
    this.value = value;

    if (this.lines === 'auto')
      this.valueLines = Math.min(
        value?.split(/\r\n|\r|\n/).length ?? 0,
        this.maxLines
      );

    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }
}
