import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {RemoteConfigResolver} from '@softline/application';
import {ArtikelvorratResolver} from './resolver/artikelvorrat.resolver';

export const artikelvorratRoutes: Routes = [
  {
    path: 'mde-artikelvorrat',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/artikelvorrat.component').then(
            (o) => o.ArtikelvorratComponent
          ),
        children: [
          {
            path: 'beleg-auswahl',
            loadComponent: () => import('./pages/beleg-auswahl/beleg-auswahl.component').then(
              o => o.BelegAuswahlComponent
            )
          },
          {
            path: 'artikel-erfassen',
            loadComponent: () => import('./pages/artikel-erfassen/artikel-erfassen.component').then(
              c => c.ArtikelErfassenComponent
            ),
            loadChildren: () => [
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt1/schritt1.component').then(
                  c => c.Schritt1Component
                ),
                path: 'suche'
              },
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt2/schritt2.component').then(
                  c => c.Schritt2Component
                ),
                path: ':idartstamm'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/mde-artikelvorrat/artikel-erfassen/suche'
              }
            ]
          },
          {
            path: '',
            loadComponent: () => import('./pages/erfassung/erfassung.component').then(
              o => o.ErfassungComponent
            )
          },
          {
            path: '**',
            redirectTo: '/mde-artikelvorrat'
          },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: 'ARTIKELVORRAT' },
    resolve: { config: RemoteConfigResolver, artikelvorrat: ArtikelvorratResolver },
    providers: [],
  },
];
