import { Scan } from '../scanner/data/scan';
import {
  createAction,
  createMutation,
  mutate,
  on,
  StoreFeature,
} from '@softline/core';
import { ItemScanService } from './item-scan.service';
import { ItemScan } from './item-scan';

export interface ItemScanResult {
  scan: Scan;
  item: ItemScan;
}

export interface State {
  scans: ItemScanResult[];
}

export const mutations = {
  add: createMutation<State, ItemScanResult>('add'),
  clear: createMutation<State, ItemScanResult>('clear'),
};

export const actions = {
  load: createAction<State, Scan, ItemScan>('load'),
};

export const getters = {};

export const feature: StoreFeature<State> = {
  initialState: {
    scans: [],
  },
  mutations: [
    mutate(mutations.add, ({ state, params }) => ({
      ...state,
      scans: [...state.scans, params],
    })),
    mutate(mutations.clear, ({ state, params }) => ({ ...state, scans: [] })),
  ],
  actions: [
    on(actions.load, async ({ commit, featureName, params, injector }) => {
      const service = injector.get(ItemScanService);
      const result = await service.load(params);
      commit(featureName, mutations.add, { scan: params, item: result });
      return result;
    }),
  ],
  getters: [],
};
