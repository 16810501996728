export abstract class ValueConverter<TIn, TOut> {
  abstract convert(value: TIn): TOut;
  abstract convertBack(value: TOut): TIn;
}

export class NoopValueConverter implements ValueConverter<any, any> {
  convert(value: any): any {
    return value;
  }

  convertBack(value: any): any {
    return value;
  }
}
