<form [formGroup]="form" [softMetaForm]="metaForm()">
  <div class="flex flex-row">
    @for (item of items(); track item){
      <div [softMetaControlName]="item.key" class="px-2 border-r">
        <soft-meta-query-bar-item [meta]="item.meta"
                                  [name]="item.key"
                                  [formControlName]="item.key"></soft-meta-query-bar-item>
      </div>
    }
  </div>
</form>
