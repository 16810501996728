import { Inject, Injectable } from "@angular/core";
import { isNullOrEmpty, Store } from "@softline/core";
import { SOFTLINE_FEATURE_SETTINGS } from "../../application.shared";
import * as SettingsStore from "../../settings/settings.store";
import { SOFTLINE_SETTINGS_APPLICATION } from "../application.shared";
import { ApplicationSettings } from "../data/application-settings";
import { Router } from "@angular/router";

@Injectable()
export class StartupRouteService {

  constructor(private store: Store, private router: Router) {

  }

  public async navigateToStartupRoute() {
    const settings = this.store.get(SOFTLINE_FEATURE_SETTINGS, SettingsStore.getters.values<ApplicationSettings>(), SOFTLINE_SETTINGS_APPLICATION);
    if(isNullOrEmpty(settings?.startup) || window.location?.pathname !== '/')
      return;
    await this.router.navigate([settings?.startup]);
  }
}
