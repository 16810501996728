<div class="flex flex-col px-4 mt-2 sm:px-0">
  <div class="text-lg font-semibold">
    {{'#WWS_WARENUEBERNAHME.DIALOG.TITEL_POSITION_ERLEDIGT_MARKIEREN' | translate}}
  </div>

  <div class="flex flex-col w-full gap-2 mt-4 py-2 border-y border-solid">
    <p class="font-semibold">
      {{bewe.lfdnr | number:'3.0'}}
    </p>
    <p>
      {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_LIEFERANT_ARTIKELNUMMER' | translate}} {{bewe.artikelMenge.artikel.lieferantArtikelNummer ?? ''}} |
      {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_EAN' | translate}} {{bewe.artikelMenge.artikel.ean}} |
      {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_ARTIKELNUMMER' | translate}} {{bewe.artikelMenge.artikel.nummer ?? ''}}
    </p>
    <p class="font-semibold text-lg">
      {{bewe.artikelMenge.artikel.artbez1 ?? 'Artikel'}}
    </p>
    <p class="text-light"
       *ngIf="bewe.artikelMenge.artikel.artbez2 && bewe.artikelMenge.artikel.artbez2 !== ''">
      {{bewe.artikelMenge.artikel.artbez2}}
    </p>
  </div>

  <div class="mt-4">
    <div class="font-bold text-sm text-primary-400 mb-1">
      {{'#WWS_WARENUEBERNAHME.DIALOG.TITEL_ANZAHL_ETIKETTEN' | translate}}
    </div>
    <soft-mengen-eingabe #mengenEingabe
                         [einheit]="('#WWS_WARENUEBERNAHME.DIALOG.ETIKETTEN_LABEL' | translate) ?? undefined"
                         [value]="anzahlEtiketten">
    </soft-mengen-eingabe>
  </div>
</div>

<button class="soft-button primary mt-6 rounded-sm h-16 w-full"
        [disabled]="mengenEingabe.value < 0"
        (click)="positionErledigen(mengenEingabe.value)">
  {{'#WWS_WARENUEBERNAHME.DIALOG.TITEL_POSITION_ERLEDIGT_MARKIEREN' | translate}}
</button>
