import { CdkPortal } from '@angular/cdk/portal';
import { TabTitleDirective } from './tab-title/tab-title.directive';
import { EventEmitter } from "@angular/core";

export abstract class Tab {
  abstract title?: string | null | undefined;
  abstract templateTitle?: TabTitleDirective;
  abstract content?: CdkPortal;

  abstract activate: EventEmitter<any>;
  abstract deactivate: EventEmitter<any>;
}
