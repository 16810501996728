import {ArtikelAttribut} from './artikel-attribut';
import {ArtikelEinheit} from './artikel-einheit';
import {ArtikelOption} from './artikel-option';
import {Media} from './media';

export interface Artikel {
  id: number;
  number: string;
  ean: string;
  name: string;
  description: string;
  attributes: ArtikelAttribut[];
  hasCustomization: boolean;
  title1: string;
  title2: string;
  title3: string;
  title4?: string;
  title5?: string;
  listing: string;
  thumbnail: Media;
  slug: string;
  unit: ArtikelEinheit;
  options: ArtikelOption[];
  availabilityPeriod: {
    from: string;
    to: string;
    manual: boolean;
  };
  orderable: boolean;
}
