import { CalendarService } from './calendar.service';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

const YEARS_BEFORE = 100;
const YEARS_AHEAD = 30;

@Injectable({
  providedIn: 'root',
})
export class MomentCalendarService extends CalendarService {
  private _startOfTheWeek: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);
  private _shortWeekdays: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >(moment.weekdaysMin());
  private _weekdays: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    moment.weekdays()
  );
  private _months: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    moment.months()
  );
  private _years = Array.from(
    { length: YEARS_BEFORE + YEARS_AHEAD + 1 },
    (value, key) => moment().year() - YEARS_BEFORE + key
  );

  format$: Observable<string> = new BehaviorSubject('DD.MM.YYYY');
  get years$(): Observable<number[]> {
    return of(this._years);
  }
  get months$(): Observable<string[]> {
    return this._months;
  }
  get weekdays$(): Observable<string[]> {
    return combineLatest([this._weekdays, this._startOfTheWeek]).pipe(
      map(([weekdays, startOfTheWeek]) => {
        const localizedWeekDays = [...weekdays];
        for (let i = 0; i < startOfTheWeek; i++)
          localizedWeekDays.push(localizedWeekDays.shift() ?? '');
        return localizedWeekDays;
      })
    );
  }
  get shortWeekdays$(): Observable<string[]> {
    return combineLatest([this._shortWeekdays, this._startOfTheWeek]).pipe(
      map(([weekdays, startOfTheWeek]) => {
        const localizedWeekDays = [...weekdays];
        for (let i = 0; i < startOfTheWeek; i++)
          localizedWeekDays.push(localizedWeekDays.shift() ?? '');
        return localizedWeekDays;
      })
    );
  }
  get shortWeekdays(): string[] {
    const localizedWeekDays = [...this._shortWeekdays.getValue()];
    for (let i = 0; i < this._startOfTheWeek.getValue(); i++)
      localizedWeekDays.push(localizedWeekDays.shift() ?? '');
    return localizedWeekDays;
  }

  getWeekdayIndex(name: string): number {
    return this._shortWeekdays.getValue().indexOf(name);
  }

  setLocale(locale: string): void {
    moment.locale(locale);
    this._months.next(moment.months());
    this._weekdays.next(moment.weekdays());
    this._shortWeekdays.next(moment.weekdaysMin());
  }

  getDaysInMonth(year: number, month: number): number {
    return moment(`${year}-${month}-1`).daysInMonth();
  }

  getWeekday(year: number, month: number, day: number): number {
    return moment(`${year}-${month}-${day}`).weekday();
  }
}
