import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Validation, ValidationMessage } from '@softline/core';

export function passwordValidator(options: {
  minLength: number;
  strengthLevel: 1 | 2 | 3 | 4;
}): ValidatorFn {
  return (control: AbstractControl) => {
    let isValid = true;
    const lengthMessage: ValidationMessage = {
      type: 'success',
      subject: '#PASSWORD.VALIDATION.PASSWORD.MIN_LENGTH',
      message: '#PASSWORD.VALIDATION.PASSWORD.MIN_LENGTH_MESSAGE',
      data: {
        requiredLength: options.minLength,
        actualLength: control.value?.length ?? 0,
      },
    };
    const uppercaseMessage: ValidationMessage = {
      type: 'success',
      subject: '#PASSWORD.VALIDATION.PASSWORD.UPPERCASE',
      message: '',
    };
    const lowercaseMessage: ValidationMessage = {
      type: 'success',
      subject: '#PASSWORD.VALIDATION.PASSWORD.LOWERCASE',
      message: '',
    };
    const numberMessage: ValidationMessage = {
      type: 'success',
      subject: '#PASSWORD.VALIDATION.PASSWORD.NUMBER',
      message: '',
    };
    const symbolMessage: ValidationMessage = {
      type: 'success',
      subject: '#PASSWORD.VALIDATION.PASSWORD.SYMBOL',
      message: '',
    };

    if (!control.value || control.value.length < options.minLength)
      lengthMessage.type = 'error';

    if (!/[A-Z]/.exec(control.value)) uppercaseMessage.type = 'error';
    if (!/[a-z]/.exec(control.value)) lowercaseMessage.type = 'error';
    if (!/[0-9]/.exec(control.value)) numberMessage.type = 'error';
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.exec(control.value))
      symbolMessage.type = 'error';

    const actualStrengthLevel =
      (uppercaseMessage.type === 'success' ? 1 : 0) +
      (lowercaseMessage.type === 'success' ? 1 : 0) +
      (numberMessage.type === 'success' ? 1 : 0) +
      (symbolMessage.type === 'success' ? 1 : 0);

    if (actualStrengthLevel >= options.strengthLevel) {
      if (uppercaseMessage.type === 'error') {
        uppercaseMessage.type = 'info';
        uppercaseMessage.message = '#PASSWORD.VALIDATION.PASSWORD.OPTIONAL';
      }
      if (lowercaseMessage.type === 'error') {
        lowercaseMessage.type = 'info';
        lowercaseMessage.message = '#PASSWORD.VALIDATION.PASSWORD.OPTIONAL';
      }
      if (numberMessage.type === 'error') {
        numberMessage.type = 'info';
        numberMessage.message = '#PASSWORD.VALIDATION.PASSWORD.OPTIONAL';
      }
      if (symbolMessage.type === 'error') {
        symbolMessage.type = 'info';
        symbolMessage.message = '#PASSWORD.VALIDATION.PASSWORD.OPTIONAL';
      }
    }

    if (
      actualStrengthLevel < options.strengthLevel ||
      lengthMessage.type === 'error'
    )
      isValid = false;

    const result: Validation = {
      isValid,
      messages: [
        lengthMessage,
        uppercaseMessage,
        lowercaseMessage,
        numberMessage,
        symbolMessage,
      ],
    };
    return { password: result };
  };
}
