import {createAction, createGetter, createMutation, mutate, ObjectStore, on, select} from "@softline/core";
import {lastValueFrom} from 'rxjs';
import {UmsatzabfrageService} from '../services/umsatzabfrage.service';

const objectStore = ObjectStore.create<UmsatzabfrageState>();

export interface UmsatzabfrageState {
  filialen: { vkforg: string; id: number; idekorg?: number }[];
  ekorgs: any[];
  sum?: { current: any; vergleich: any; };
  ekSum?: { current: any; vergleich: any; };
  params: {
    umsatz?: LoadUmsatzabfrageParams;
    einkauf?: LoadUmsatzabfrageParams;
  }
  values?: {
    date: string;
    current: any;
    vergleich: any;
  }[]
}

export interface LoadUmsatzabfrageParams {
  artikel: string;
  from: string;
  to: string;
  filialen: any[];
}

const mutations = {
  ...objectStore.mutations,
  clearAbfrage: createMutation<UmsatzabfrageState, void>('umsatzabfrage/clearAbfrage')
}

const actions = {
  loadFilialen: createAction<any, void, any>('umsatzabfrage/loadFilialen'),
  load: createAction<any, { number: string, from: string, to: string, filiale: string }, any>('umsatzabfrage/load'),
  loadEinkaufsstatisitk: createAction<any, { number: string, from: string, to: string, filiale?: { id: number; ekorg: string } }, any>('umsatzabfrage/loadEinkaufsstatisitk'),
}

const getters = {
  ...objectStore.getters,
  filialen: createGetter<typeof objectStore.feature.initialState, UmsatzabfrageState['filialen'], void>('umsatzabfrage/getFilialen'),
  einkaufFilialen: createGetter<typeof objectStore.feature.initialState, UmsatzabfrageState['ekorgs'], void>('umsatzabfrage/getEinkaufFilialen'),
  values: createGetter<typeof objectStore.feature.initialState, UmsatzabfrageState['values'], void>('umsatzabfrage/getValues'),
  params: createGetter<typeof objectStore.feature.initialState, UmsatzabfrageState['params'], void>('umsatzabfrage/getParams'),
}

export const UmsatzabfrageStore = {
  mutations,
  actions,
  getters,
  feature: {
    initialState: {
      ...objectStore.feature.initialState,
    },
    mutations: [
      ...objectStore.feature.mutations,
      mutate(mutations.clearAbfrage, ({ state }) => ({
        ...state,
        values: [],
        sum: undefined
      }))
    ],
    actions: [
      on(actions.loadFilialen, async ({ featureName, injector, commit, get }) => {
        const service = injector.get(UmsatzabfrageService);

        let filialen = get(featureName, getters.filialen)
        let einkaufFilialen = get(featureName, getters.einkaufFilialen)

        if (!filialen || filialen?.length < 1)
          filialen = await lastValueFrom(service.getFilialen());

        if (!einkaufFilialen || einkaufFilialen?.length < 1)
          einkaufFilialen = await lastValueFrom(service.getEinkaufFilialen());

        const state = get(featureName, getters.data);
        commit(featureName, mutations.set, { ...state, filialen: filialen ?? [], ekorgs: einkaufFilialen ?? [] });
        return { filialen, einkaufFilialen };
      }),
      on(actions.load, async ({ featureName, get, dispatch, injector, commit, params }) => {
        const service = injector.get(UmsatzabfrageService);

        let filialen = get(featureName, getters.data)?.filialen ?? [];
        let einkaufFilialen = get(featureName, getters.data)?.ekorgs ?? [];

        if (filialen?.length < 1 || einkaufFilialen?.length < 1) {
          const data = await dispatch(featureName, actions.loadFilialen);
          filialen = data.filialen;
          einkaufFilialen = data.einkaufFilialen;
        }

        if (filialen?.length < 1 || einkaufFilialen?.length < 1)
          return;

        const abfrageParams: LoadUmsatzabfrageParams = {
          from: params.from,
          to: params.to,
          artikel: params.number,
          filialen: params.filiale !== 'Alle' ? [{ vkforg: params.filiale }] : filialen
        }

        const umsatzabfrage = await lastValueFrom(service.monthlyCompare(abfrageParams));
        const umsatzabfrageVergleich = await lastValueFrom(service.monthlyCompareVergleich(abfrageParams));
        // const umsatzabfrageEinkauf = await lastValueFrom(service.monthlyCompareEinkauf(abfrageParams));
        // const umsatzabfrageEinkaufVergleich = await lastValueFrom(service.monthlyCompareEinkaufVergleich(abfrageParams));

        const umsatz = umsatzabfrage?.ebeneresult['Alle']?.arten;
        const umsatzVergleich = umsatzabfrageVergleich?.ebeneresult?.['Alle']?.arten
        // const umsatzEinkauf = umsatzabfrageEinkauf?.ebeneresult?.['Alle']?.arten
        // const umsatzEinkaufVergleich = umsatzabfrageEinkaufVergleich?.ebeneresult?.['Alle']?.arten

        const values: { date: string; current: any; vergleich: any }[] = [];

        for (const [key, value] of Object.entries(umsatz)) {
          const dateParts = key.split('-');
          const vergleichKey = `${(+dateParts[0] - 1)}-${dateParts[1]}-${dateParts[2]}`;
          if (!umsatzVergleich[vergleichKey] || key === 'Summe') continue;
          values.push({
            date: key,
            current: {
              ...(umsatz[key]?.values ?? {}),
            } ,
            vergleich: {
              ...(umsatzVergleich[vergleichKey]?.values ?? {}),
            }
          });
        }

        const sum = {
          current: {
            ...umsatzabfrage?.ebeneresult?.['Alle']?.arten['Summe']?.values,
          },
          vergleich: {
            ...umsatzabfrageVergleich?.ebeneresult?.['Alle']?.arten['Summe']?.values,
          }
        };

        const state = get(featureName, getters.data);
        commit(featureName, mutations.set, { ...state, values, sum });
        return { values };
      }),
      on(actions.loadEinkaufsstatisitk,  async ({ featureName, get, dispatch, injector, commit, params }) => {
        const service = injector.get(UmsatzabfrageService);

        let filialen = get(featureName, getters.data)?.filialen ?? [];
        let einkaufFilialen = get(featureName, getters.data)?.ekorgs ?? [];

        if (filialen?.length < 1 || einkaufFilialen?.length < 1) {
          const data = await dispatch(featureName, actions.loadFilialen);
          filialen = data.filialen;
          einkaufFilialen = data.einkaufFilialen;
        }

        if (filialen?.length < 1 || einkaufFilialen?.length < 1)
          return;

        const storedParams = get(featureName, getters.params);
        const lastEkAbfrageParams = storedParams?.einkauf;


        console.log('params ek: ', params)

        const abfrageParams: LoadUmsatzabfrageParams = {
          from: params.from,
          to: params.to,
          artikel: params.number,
          filialen: params?.filiale ? [params.filiale] : einkaufFilialen
        }

        commit(featureName, mutations.patch, { params: { ...storedParams, einkauf: abfrageParams } })

        const umsatzabfrageEinkauf = await lastValueFrom(service.monthlyCompareEinkauf(abfrageParams));
        const umsatzabfrageEinkaufVergleich = await lastValueFrom(service.monthlyCompareEinkaufVergleich(abfrageParams));

        const umsatzEinkauf = umsatzabfrageEinkauf?.ebeneresult?.['Alle']?.arten
        const umsatzEinkaufVergleich = umsatzabfrageEinkaufVergleich?.ebeneresult?.['Alle']?.arten

        const values: { date: string; current: any; vergleich: any }[] = [...get(featureName, getters.values) ?? []];

        for (const [key, value] of Object.entries(umsatzEinkauf)) {
          const dateParts = key.split('-');
          const vergleichKey = `${(+dateParts[0] - 1)}-${dateParts[1]}-${dateParts[2]}`;
          if (!umsatzEinkaufVergleich[vergleichKey] || key === 'Summe') continue;

          const index = values.findIndex(o => o.date === key)

          if (index < 0)
            values.push({
              date: key,
              current: {
                mengeEinkauf: (value as any)?.values?.menge ?? 0,
                nettoEinkauf: (value as any)?.values?.netto ?? 0,
              } ,
              vergleich: {
                mengeEinkauf: umsatzEinkaufVergleich[vergleichKey]?.values?.menge ?? 0,
                nettoEinkauf: umsatzEinkaufVergleich[vergleichKey]?.values?.netto ?? 0,
              }
            });
        else
          values[index] = {
            ...values[index],
            current: {
              ...(values[index]?.current ?? {}),
              mengeEinkauf: (value as any)?.values?.menge ?? 0,
              nettoEinkauf: (value as any)?.values?.netto ?? 0,
            },
            vergleich: {
              ...(values[index]?.vergleich ?? {}),
              mengeEinkauf: umsatzEinkaufVergleich[vergleichKey]?.values?.menge ?? 0,
              nettoEinkauf: umsatzEinkaufVergleich[vergleichKey]?.values?.netto ?? 0,
            }
          }
        }

        const sum = {
          current: {
            mengeEinkauf: umsatzabfrageEinkauf?.ebeneresult?.['Alle']?.arten['Summe']?.values?.menge ?? 0,
            nettoEinkauf: umsatzabfrageEinkauf?.ebeneresult?.['Alle']?.arten['Summe']?.values?.netto ?? 0
          },
          vergleich: {
            mengeEinkauf: umsatzabfrageEinkaufVergleich?.ebeneresult?.['Alle']?.arten['Summe']?.values?.menge ?? 0,
            nettoEinkauf: umsatzabfrageEinkaufVergleich?.ebeneresult?.['Alle']?.arten['Summe']?.values?.netto ?? 0
          }
        };

        const state = get(featureName, getters.data);
        commit(featureName, mutations.set, { ...state, values, ekSum: sum });
      })
    ],
    getters: [
      ...objectStore.feature.getters,
      select(getters.filialen, ({state}) => state?.data?.filialen ?? []),
      select(getters.einkaufFilialen, ({state}) => state?.data?.ekorgs ?? []),
      select(getters.values, ({state}) => state?.data?.values ?? []),
      select(getters.params, ({state}) => state?.data?.params ?? {}),
    ]
  }
}
