import { ConnectionHttpService, isDefined } from '@softline/core';
import { FieldOkParameters } from '../data/field-ok';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FieldOkResponse } from './field-ok-component.service';
import { convertData } from '../converter/data.converter';
import {
  SOFTLINE_API_FIELD_OK_QUERY,
  SOFTLINE_API_FIELD_OK_RESULT,
  SOFTLINE_API_FIELD_OK_VALIDATE,
} from '../dynamic.api';

export interface FieldOkServiceOptions<
  T extends object = object,
  TList extends object = object
> {
  valueConverter?: (value: any) => T;
  queryConverter?: (value: any) => TList;
}

export class FieldOkService<
  T extends object = object,
  TList extends object = object,
  TParams extends object = object
> {
  constructor(
    protected service: ConnectionHttpService,
    private fieldOk: string,
    private options?: FieldOkServiceOptions<T, TList>
  ) {}

  load(
    params: FieldOkParameters<TParams>,
    id: number | string
  ): Observable<T | null> {
    const queryLocation = {
      path: SOFTLINE_API_FIELD_OK_QUERY,
      pathParams: { fieldOk: this.fieldOk },
    };
    const objectLocation = {
      path: SOFTLINE_API_FIELD_OK_RESULT,
      pathParams: { fieldOk: this.fieldOk, key: encodeURIComponent(id) },
    };

    return this.service
      .create<FieldOkParameters, FieldOkResponse>(queryLocation, params)
      .pipe(
        switchMap((o) =>
          this.service.create<FieldOkParameters, FieldOkResponse>(
            objectLocation,
            params
          )
        ),
        map((o) => {
          if (typeof o.data !== 'object') return null;
          const valueConverter = this.options?.valueConverter;
          return isDefined(valueConverter)
            ? valueConverter(o.data)
            : (o.data as T);
        })
      );
  }

  query(params: FieldOkParameters<TParams>): Observable<TList[]> {
    const queryLocation = {
      path: SOFTLINE_API_FIELD_OK_QUERY,
      pathParams: { fieldOk: this.fieldOk },
    };
    return this.service
      .create<FieldOkParameters, FieldOkResponse>(queryLocation, params)
      .pipe(
        map((o) => {
          const data = convertData(o.data);
          const queryConverter = this.options?.queryConverter;
          return isDefined(queryConverter)
            ? data.map((o) => queryConverter(o))
            : (data as TList[]);
        })
      );
  }

  validate(
    params: FieldOkParameters<TParams>
  ): Observable<{ value?: T; list?: TList[] } | null> {
    const queryLocation = {
      path: SOFTLINE_API_FIELD_OK_VALIDATE,
      pathParams: { fieldOk: this.fieldOk },
    };
    return this.service
      .create<FieldOkParameters, FieldOkResponse>(queryLocation, params)
      .pipe(
        map((o) => {
          switch (o.attributeType) {
            case 'Bean':
              const valueConverter = this.options?.valueConverter;
              return {
                value: isDefined(valueConverter)
                  ? valueConverter(o.data)
                  : (o.data as T),
              };
            case 'Abfrage':
              const queryConverter = this.options?.queryConverter;
              const data = convertData(o.data);
              return {
                list: isDefined(queryConverter)
                  ? data.map((o) => queryConverter(o))
                  : (data as TList[]),
              };
            default:
              return null;
          }
        })
      );
  }
}
