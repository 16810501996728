<form [formGroup]="form">
  <div class="flex flex-row justify-between">
    <span class="font-semibold">Aktivieren</span>
    <soft-toggle-switch formControlName="enabled"></soft-toggle-switch>
  </div>
  <div *ngIf="!service || !service.available"
       class="border border-solid border-warning-600 bg-warning-200 text-warning-600 rounded p-3 mt-4 flex flex-row items-center">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
    </svg>
    <span class="ml-2">
          Auf diesem Gerät / Browser ist kein NFC verfügbar!
    </span>

  </div>
  <hr class="soft-separate" />
</form>
