import {Injectable} from '@angular/core';
import {ConnectionHttpService, ConnectionResourceLocation, ResponseResult} from '@softline/core';
import {map, Observable} from 'rxjs';
import {
  SOFTLINE_API_ARTIKELVORRAT_SCAN,
  SOFTLINE_API_MDE_ARTIKELVORRAT_ABSCHLUSS,
  SOFTLINE_API_MDE_ARTIKELVORRAT_EINLESEN,
  SOFTLINE_API_MDE_ARTIKELVORRAT_KOPF
} from '../artikelvorrat.api';
import { ArtikelvorratKopf } from '../data/artikelvorrat-kopf';
import {Scan} from '@softline/application';
import {ArtikelvorratScanResult} from '../data/artikelvorrat-scan-result';
import {ArtikelvorratBeleg} from '../data/artikelvorrat-beleg';

@Injectable()
export class ArtikelvorratService {

  private get location(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_ARTIKELVORRAT_ABSCHLUSS,
    }
  }

  private get kopfLocation(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_ARTIKELVORRAT_KOPF,
    }
  }

  private get einlesenLocation(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_ARTIKELVORRAT_EINLESEN,
    }
  }

  private bestellvorschlagScanLocation(code: string): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_ARTIKELVORRAT_SCAN,
      pathParams: { code }
    }
  }

  constructor(private httpService: ConnectionHttpService) {}

  abschluss(ediSchicken: boolean): Observable<void> {
    return this.httpService.create(this.location, { ediSchicken });
  }

  getKopf(): Observable<ArtikelvorratKopf> {
    return this.httpService.get<ResponseResult<ArtikelvorratKopf>>(this.kopfLocation).pipe(
      map(o => o?.result)
    );
  }

  einlesen(beleg: ArtikelvorratBeleg): Observable<ArtikelvorratKopf> {
    return this.httpService.create<unknown, ResponseResult<ArtikelvorratKopf>>(this.einlesenLocation, beleg).pipe(
      map(o => o?.result)
    );
  }

  removeBeleg(): Observable<unknown> {
    return this.httpService.delete<ResponseResult<ArtikelvorratKopf>>(this.kopfLocation);
  }

  belegScan(scan: Scan): Observable<ArtikelvorratScanResult> {
    return this.httpService.get<ResponseResult<ArtikelvorratScanResult>>(this.bestellvorschlagScanLocation(scan.data)).pipe(
      map(o => o?.result)
    );
  }
}
