import {Injectable} from '@angular/core';
import {
  AuthenticationContextStore,
  JwtAuthenticationStore,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION
} from '@softline/auth';
import {PatchContextStore} from '../patch-context.store';
import {SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT} from '../../utilities.shared';
import {equals, Store} from '@softline/core';
import {
  MessageBarStore,
  ModalOption,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL
} from '@softline/ui-core';
import {handleRequestErrors, RefreshService} from '@softline/application';
import {BehaviorSubject} from 'rxjs';
import {EditContextDialogComponent} from '../dialogs/edit-context-dialog/edit-context-dialog.component';

export interface PatchContextParams<T> {
  title?: string;
  options: ModalOption<T>[];
  editContextDialogOptionValue?: T;
  setOnSelect: (result: T) => Record<string, unknown> | null
}

@Injectable()
export class PatchContextService {

  private readonly _pending$ = new BehaviorSubject(false);
  readonly pending$ = this._pending$.asObservable();

  constructor(
    private readonly store: Store,
    private readonly refreshService: RefreshService
  ) {}

  async patchContextOptionsDialog<T>(params: PatchContextParams<T>): Promise<void> {
    const contextResult = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<T>(),
      {
        title: params?.title,
        dismiss: true,
        options: params.options,
      }
    );

    if (!contextResult || contextResult === 'DISMISSED')
      return;

    if (contextResult === params?.editContextDialogOptionValue) {
      const result = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
        component: EditContextDialogComponent,
        dismiss: {
          backdrop: true,
          escape: true,
          button: true
        }
      });

      if (result === 'DISMISSED')
        return;

      await this.refreshService.refresh();
      return;
    }

    const patch = await params.setOnSelect(contextResult);

    if (!patch)
      return;

    this.store.commit(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.mutations.set,
      {
        ...this.store.get(
          SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
          PatchContextStore.getters.data as never,
        ),
        ...patch
      }
    );

    await this.updateContext();
    await this.refreshService.refresh();
  }

  async updateContext(): Promise<boolean> {
    const context = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    );

    const patchedContext = this.store.get(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.getters.data as never
    );

    if (equals(context, patchedContext)) {
      return true;
    }

    try {
      this._pending$.next(true);

      await this.store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.change,
        patchedContext
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#UTILITIES.CONTEXT.SUCCESS'
      );

      this._pending$.next(false);
      return false;
    } catch (e) {
      this._pending$.next(false);
      handleRequestErrors(this.store, e);
    } finally {
      this._pending$.next(false);
    }

    return false;
  }
}
