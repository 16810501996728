import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Modal, UiCoreModule } from "@softline/ui-core";
import { FilterListComponent } from "../../components/filter-list/filter-list.component";
import { SortFormComponent } from "../../components/sort-form/sort-form.component";
import { FilterConfig } from "../../types/filter-config";
import { PropertyFilter, Sort } from "@softline/core";
import { SortConfig } from "../../types/sort-config";

export type FilterAndSort = {filter?: PropertyFilter[], sort?: Sort};

@Component({
  selector: 'soft-filter-and-sort-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule, FilterListComponent, SortFormComponent],
  templateUrl: './filter-and-sort.dialog.html',
  styleUrls: ['./filter-and-sort.dialog.scss']
})
export class FilterAndSortDialog implements OnInit, Modal<FilterAndSort> {

  close!: (result: FilterAndSort) => void;

  values: object[] = [];
  filterConfig!: FilterConfig;
  sortConfig!: SortConfig;
  filters!: Partial<PropertyFilter>[];
  sort: Partial<Sort> = {};

  constructor() { }

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: FilterAndSort) => void): void {
    this.close = handler;
  }

  async onSubmit(): Promise<void> {
    this.close({
      filter: this.filters.filter((o): o is PropertyFilter => !!o.property),
      sort: (this.sort?.property && this.sort?.direction) ? this.sort as Sort : undefined
    })
  }
}
