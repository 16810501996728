import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal, UiCoreModule} from "@softline/ui-core";
import {Bestellung} from "../../types/bestellung";
import {KopfdatenComponent} from "@softapps/wws/warenuebernahme";

@Component({
  selector: 'soft-bestellung-uebernehmen',
  standalone: true,
  imports: [CommonModule, KopfdatenComponent, UiCoreModule],
  templateUrl: './bestellung-uebernehmen-dialog.component.html',
  styleUrls: ['./bestellung-uebernehmen-dialog.component.scss'],
})
export class BestellungUebernehmenDialogComponent implements Modal<string> {

  close!: (result: string) => void;

  public _bestellung?: Bestellung;
  public lieferscheinnummer = '';

  set bestellung (bestellung: Bestellung) {
    this._bestellung = bestellung;
  }

  registerCloseHandler(handler: (result: string) => void): void {
    this.close = handler;
  }

  lieferscheinnummerChange(lieferscheinnummer: string) {
    this.lieferscheinnummer = lieferscheinnummer;
  }
}
