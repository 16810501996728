import { Routes } from '@angular/router';

export const loginRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./components/login-app.component').then(
        (o) => o.LoginAppComponent
      ),
    children: [
      {
        path: 'settings',
        loadComponent: () =>
          import('./components/settings/login-settings.component').then(
            (o) => o.LoginSettingsComponent
          ),
      },
      {
        path: '',
        loadComponent: () =>
          import('./components/login/login.component').then(
            (o) => o.LoginComponent
          ),
      },
    ],
  },
];
