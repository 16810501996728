import { ModuleWithProviders, NgModule } from '@angular/core';
import { IndexedDbStoreAdapter } from './adapter/indexed-db-store.adapter';
import {
  IndexedDbService,
  Logger,
  SOFTLINE_CONFIG_INDEXED_DB,
  Store,
} from '@softline/core';
import { SOFTLINE_INDEXED_DB_STORE } from './adapter/store.indexed-db';
import {
  SOFTLINE_CONFIG_OFFLINE_FEATURE,
  SOFTLINE_SETTINGS_OFFLINE,
} from './offline.shared';
import { SOFTLINE_CONFIG_SETTINGS } from '../application.shared';
import { StorageSettingsComponent } from './settings/storage-settings/storage-settings.component';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';

@NgModule({
  declarations: [StorageSettingsComponent],
  imports: [CommonModule, UiCoreModule],
  exports: [],
})
export class OfflineModule {
  static forRoot(): ModuleWithProviders<OfflineRootModule> {
    return {
      ngModule: OfflineRootModule,
      providers: [
        {
          provide: SOFTLINE_CONFIG_INDEXED_DB,
          multi: true,
          useValue: SOFTLINE_INDEXED_DB_STORE,
        },
        {
          provide: IndexedDbStoreAdapter,
          useFactory: (
            store: Store,
            service: IndexedDbService,
            features: string[],
            logger: Logger
          ) => new IndexedDbStoreAdapter(store, service, features, logger),
          deps: [
            Store,
            IndexedDbService,
            SOFTLINE_CONFIG_OFFLINE_FEATURE,
            Logger,
          ],
        },
        {
          provide: SOFTLINE_CONFIG_SETTINGS,
          multi: true,
          useValue: {
            key: SOFTLINE_SETTINGS_OFFLINE,
            component: StorageSettingsComponent,
            title: '#APPLICATION.OFFLINE.STORAGE.SETTINGS.TITLE',
            icon: 'fa-regular fa-database',
          },
        },
      ],
    };
  }
}

@NgModule({
  imports: [OfflineModule],
  exports: [OfflineModule],
})
export class OfflineRootModule {
  constructor(adapter: IndexedDbStoreAdapter) {}
}
