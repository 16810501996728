import {
  createGetter,
  createMutation,
  mutate,
  select,
} from "../../../factories";
import * as CollectionStore from "./collection.store";
import { FilterService } from "../../../../filter/services/filter.service";
import { Filter } from "../../../../filter/types/filter";
import { Entity } from "../types/entity";
import { StoreFeature } from "../../../store";
import { isDefined } from "../../../../functions/is-defined.function";

export interface State<T extends Entity = Entity>
  extends CollectionStore.State<T> {
  filter: Filter | null
}

export class Store<T extends Entity = Entity> {
  mutations = {
    setFilter: createMutation<State<T>, Filter | null>('setFilter'),
  };

  getters = {
    filter: createGetter<State<T>, Filter | null>('filter'),
    filtered: createGetter<State<T>, T[]>('filtered'),
  };

  feature: Partial<StoreFeature<State<T>>> = {
    initialState: {
      filter: null,
    } as State<T>,
    mutations: [
      mutate(this.mutations.setFilter, ({ state, params }) => {
        return { ...state, filter: params };
      }),
    ],
    getters: [
      select(
        this.getters.filter,
        ({ state, params }) =>
          state.filter
      ),
      select(
        this.getters.filtered,
        ({ state, params , injector}) => {
          const service = injector.get(FilterService);
          const entities = state.ids.map(o => state.entities[o]);
          if(isDefined(state.filter))
            return service.filter(entities, state.filter)
          return entities;
        }
      ),
    ],
  };
}

export function create<T extends Entity>(): Store<T> {
  return new Store<T>();
}


const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = [];
export const feature = instance.feature;
