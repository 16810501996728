import { Routes } from '@angular/router';
import { ENVIRONMENT_INITIALIZER, inject } from '@angular/core';
import { Store } from '@softline/core';
import { CommandStore, SOFTLINE_FEATURE_COMMANDS } from '@softline/application';

export const settingsRoutes: Routes = [
  {
    path: 'settings',
    loadComponent: () =>
      import('@softline/application').then((o) => o.SettingsComponent),
  },
];
