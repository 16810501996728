import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateResourceService,
  DeleteResourceService,
  GetResourceService,
  PatchResourceService,
  UpdateResourceService,
  UploadResourceService,
} from '../../abstraction';
import { IndexedDbResourceLocation } from './indexed-db.location';
import { IndexedDBConfig } from './indexed-db.config';
import { RequestEvent } from '../../request';
import { isDefined } from '../../../functions/is-defined.function';
import { SOFTLINE_CONFIG_INDEXED_DB } from '../../resource.shared';

@Injectable()
export class IndexedDbService
  implements
    GetResourceService<IndexedDbResourceLocation>,
    CreateResourceService<IndexedDbResourceLocation>,
    UpdateResourceService<IndexedDbResourceLocation>,
    DeleteResourceService<IndexedDbResourceLocation>,
    PatchResourceService<IndexedDbResourceLocation>,
    UploadResourceService<IndexedDbResourceLocation>
{
  private databases: IDBDatabase[] = [];

  constructor(private injector: Injector) {}

  get<T>(location: IndexedDbResourceLocation): Observable<T> {
    return from(
      this.getAsync(
        location.databaseName,
        location.objectStoreName,
        location.key
      )
    ) as Observable<T>;
  }

  async getAsync<T>(
    databaseName: string,
    storeName: string,
    key: string | undefined
  ): Promise<T> {
    const database = await this.getDatabase(databaseName);
    const transaction = database.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);

    return await new Promise((resolve, reject) => {
      const request = isDefined(key) ? store.get(key) : store.getAll();
      request.onsuccess = () => {
        resolve(request.result);
      };
      request.onerror = () => {
        console.log(
          `Cannot get resource ${key} in ${databaseName}/${storeName}`
        );
        reject(request.error);
      };
    });
  }

  create<T, TResponse>(
    location: IndexedDbResourceLocation,
    resource: T
  ): Observable<TResponse> {
    return from(
      this.createAsync(
        location.databaseName,
        location.objectStoreName,
        location.key,
        resource
      )
    ) as Observable<TResponse>;
  }

  async createAsync<T, TResponse>(
    databaseName: string,
    storeName: string,
    key: string,
    resource: T
  ): Promise<TResponse> {
    const database = await this.getDatabase(databaseName);
    const transaction = database.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    return await new Promise((resolve, reject) => {
      const isInlineKey = store.keyPath?.length > 0;
      const request = store.add(resource, isInlineKey ? undefined : key);
      request.onsuccess = (event) => {
        const copy = this.createCopyWithKey(
          resource,
          key ?? (event.target as any).result,
          store.keyPath
        );
        resolve(copy as any);
      };
      request.onerror = () => {
        console.log(
          `Cannot add resource ${key} in ${databaseName}/${storeName}`
        );
        reject(request.error);
      };
    });
  }

  delete<TResponse>(
    location: IndexedDbResourceLocation
  ): Observable<TResponse> {
    return from(
      this.deleteAsync(
        location.databaseName,
        location.objectStoreName,
        location.key
      )
    ) as Observable<TResponse>;
  }

  async deleteAsync<T, TResponse>(
    databaseName: string,
    storeName: string,
    key: string | undefined
  ): Promise<TResponse> {
    const deleted = await this.getAsync<TResponse>(
      databaseName,
      storeName,
      key
    );

    const database = await this.getDatabase(databaseName);
    const transaction = database.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    return await new Promise((resolve, reject) => {
      try {
        const request = isDefined(key) ? store.delete(key) : store.clear();
        request.onsuccess = (event) => {
          resolve(deleted);
        };
        request.onerror = () => {
          console.log(
            `Cannot delete resource ${key} in ${databaseName}/${storeName}`
          );
          reject(request.error);
        };
      } catch (e) {
        console.log(e);
        throw e;
      }
    });
  }

  patch<T, TResponse>(
    location: IndexedDbResourceLocation,
    changes: Partial<T>
  ): Observable<TResponse> {
    return from(
      this.patchAsync(
        location.databaseName,
        location.objectStoreName,
        location.key,
        changes
      )
    ) as Observable<TResponse>;
  }

  async patchAsync<T, TResponse>(
    databaseName: string,
    storeName: string,
    key: string,
    changes: Partial<T>
  ): Promise<TResponse> {
    const original = await this.getAsync<TResponse>(
      databaseName,
      storeName,
      key
    );

    const database = await this.getDatabase(databaseName);
    const transaction = database.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    return await new Promise((resolve, reject) => {
      const newValue = { ...original, ...changes };
      const isInlineKey = store.keyPath?.length > 0;
      const request = store.put(newValue, isInlineKey ? undefined : key);
      request.onsuccess = (event) => {
        resolve(newValue);
      };
      request.onerror = () => {
        console.log(
          `Cannot delete resource ${key} in ${databaseName}/${storeName}`
        );
        reject(request.error);
      };
    });
  }

  update<T, TResponse>(
    location: IndexedDbResourceLocation,
    resource: T
  ): Observable<TResponse> {
    return from(
      this.updateAsync(
        location.databaseName,
        location.objectStoreName,
        location.key,
        resource
      )
    ) as Observable<TResponse>;
  }

  async updateAsync<T, TResponse>(
    databaseName: string,
    storeName: string,
    key: string,
    resource: T
  ): Promise<TResponse> {
    const database = await this.getDatabase(databaseName);
    const transaction = database.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    return await new Promise((resolve, reject) => {
      const isInlineKey = store.keyPath?.length > 0;
      const request = store.put(resource, isInlineKey ? undefined : key);
      request.onsuccess = (event) => {
        resolve(resource as any);
      };
      request.onerror = () => {
        console.log(
          `Cannot delete resource ${key} in ${databaseName}/${storeName}`
        );
        reject(request.error);
      };
    });
  }

  download<TResponse>(
    location: IndexedDbResourceLocation
  ): Observable<TResponse> {
    return from(
      this.getAsync(
        location.databaseName,
        location.objectStoreName,
        location.key
      )
    ) as Observable<TResponse>;
  }

  upload<T, TResponse>(
    location: IndexedDbResourceLocation,
    resource: T
  ): Observable<RequestEvent<TResponse>> {
    return from(
      this.createAsync(
        location.databaseName,
        location.objectStoreName,
        location.key,
        resource
      )
    ).pipe(map((o) => ({ type: 'response', response: resource as any })));
  }

  deleteDatabase(name: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      const db = await this.getDatabase(name);
      db.close();
      this.databases.splice(this.databases.indexOf(db), 1);

      const request = indexedDB.deleteDatabase(name);
      request.onsuccess = () => {
        console.log(`The IndexDB database '${name}' has been deleted`);
        resolve();
      };
      request.onerror = () => {
        console.log(
          `Cannot delete the IndexDB database '${name}'`,
          request.error
        );
        reject(request.error);
      };
      request.onblocked = () => {
        console.log(
          `The deleation of the IndexDB database '${name}' was blocked by another operation`
        );
        reject(request.error);
      };
    });
  }

  private getDatabase(name: string): Promise<IDBDatabase> {
    const existingDb = this.databases.find((o) => o.name === name);
    if (existingDb)
      return new Promise((resolve, reject) =>
        !!existingDb ? resolve(existingDb) : reject()
      );

    let config: any;
    try {
      config = this.injector
        .get(SOFTLINE_CONFIG_INDEXED_DB)
        .find((o) => o.name === name);
      if (!config)
        throw new Error(`No indexedDB configuration found for '${name}'`);
    } catch (e) {
      console.log(e);
      throw e;
    }

    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(config.name, config.version);

      request.onsuccess = (result) => {
        const database = request.result;
        this.databases.push(database);
        resolve(database);
      };
      request.onerror = () => {
        console.log(`Cannot open Database '${name}'`);
        reject(`Cannot open Database '${name}'`);
      };
      request.onblocked = () => {
        alert(
          `Cannot upgrade Database '${name}'. Please close all instances of the app and try again`
        );
        reject(
          `Cannot upgrade Database '${name}'. Please close all instances of the app and try again`
        );
      };
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const database = request.result;
        const transaction = (event.target as any).transaction;

        if (event.oldVersion < 1) {
          for (const storeConfig of config.objectStoreConfigs) {
            const store = database.createObjectStore(
              storeConfig.name,
              storeConfig.parameters
            );
            for (const index of storeConfig.schema)
              store.createIndex(index.name, index.keypath, index.options);
          }
        }
        if (config.migrationCallbacks && request.transaction) {
          for (
            let i = event.oldVersion;
            i < (event.newVersion ?? event.oldVersion);
            i++
          ) {
            if (config.migrationCallbacks[i])
              config.migrationCallbacks[i](
                (event.target as any).result,
                request.transaction
              );
          }
        }
        transaction.oncomplete = () => resolve(database);
      };
    });
  }

  private createCopyWithKey<T>(
    resource: T,
    key: string,
    keyPath: string | string[]
  ): T {
    const returnValue = { ...resource } as any;
    let currentValue = returnValue;
    keyPath = typeof keyPath === 'string' ? [keyPath] : keyPath;
    for (let i = 0; i < keyPath.length; i++) {
      if (i === keyPath.length - 1) currentValue[keyPath[1]] = key;
      else {
        currentValue[keyPath[1]] = {};
        currentValue = currentValue[keyPath[1]];
      }
    }
    return returnValue;
  }
}
