import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  EntityService, KeyStore, mutate,
  on, ParamsStore,
  select, SOFTLINE_SERVICE_UUID,
  SOFTLINE_STORE_ENTITY_SERVICE, StoreBuilder,
  StoreFeature, SubscriptionStore, SyncedRemoteCollectionStore
} from '@softline/core';
import {lastValueFrom} from 'rxjs';
import {ArtikelFavorit} from '../types/artikel-favorit';

export type FavoriteState = SyncedRemoteCollectionStore.State<ArtikelFavorit> & {
  isLoading: boolean
}

const syncedRemoteCollectionStore = SyncedRemoteCollectionStore.create<ArtikelFavorit>()

export class FavoritesStoreExtension {
  actions = {
    loadFavorites: createAction<FavoriteState, {force?: boolean} | undefined, ArtikelFavorit[]>('load favorites')
  }

  getters = {
    isFavorite: createGetter<FavoriteState, boolean, number>('[FavoriteStoreExtension] isFavorite'),
    getFavorite: createGetter<FavoriteState, ArtikelFavorit | undefined, number>('[FavoriteStoreExtension] getFavorite'),
    isLoading: createGetter<FavoriteState, undefined, boolean>('[FavoriteStoreExtension] isLoading')
  }

  mutations = {
    setLoading: createMutation<typeof syncedRemoteCollectionStore.feature.initialState, boolean>('[FavoriteStoreExtension] setLoading')
  }

  feature: Partial<StoreFeature<FavoriteState>> = {
    initialState: {
      ...syncedRemoteCollectionStore.feature.initialState,
      isLoading: false,
    },
    getters: [
      select(this.getters.isFavorite, ({state, params}) => {
        return state.ids.map(o => state.entities[o])
          .findIndex(o => o?.item?.id === params) > -1;
      }),
      select(this.getters.getFavorite, ({state, params}) => {
        return state.ids.map(o => state.entities[o])
          .find(o => o?.item?.id === params);
      }),
      select(this.getters.isLoading, ({state, params}) => {
        return state.isLoading
      })
    ],
    actions: [
      on(this.actions.loadFavorites, async ({commit, featureName, params, injector, get, dispatch}) => {
        let result = get(featureName, syncedRemoteCollectionStore.getters.all);

        // same implementation as `loadManyOnce`
        // We only call the API when we don't have any favorites in the store
        if (!params?.force && result && result?.length > 0)
          return result

        const service: EntityService<ArtikelFavorit> = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});
        commit(featureName, this.mutations.setLoading, true)

        const subscription$ = SubscriptionStore.handleSubscriptionState(service.getMany(), featureName, commit, token);

        result = await lastValueFrom(ActionStore.handleObservableActionState(subscription$, featureName, commit, this.actions.loadFavorites.name, token));
        commit(featureName, syncedRemoteCollectionStore.mutations.addOrUpdateMany, result);
        commit(featureName, this.mutations.setLoading, false)

        if(result && result?.length > 0) {
          const ids = result.map(o => o.item.id)
          /*await Promise.all([
            dispatch(SOFTLINE_FEATURE_ITEM_PRICE, ItemPriceStore.actions.loadManySequential, { ids }),
            dispatch(SOFTLINE_FEATURE_ITEM_STOCK, ItemStockStore.actions.loadManySequential, { ids })
          ]) */
        }

        return result;
      }),
    ],
    mutations: [
      mutate(this.mutations.setLoading, ({ state, params: isLoading }) => ({ ...state, isLoading }))
    ]
  }
}

export const ArtikelFavoritenStore = new StoreBuilder(syncedRemoteCollectionStore)
  .add(new FavoritesStoreExtension())
  .value;
