import { MonoTypeOperatorFunction, Observable, OperatorFunction } from 'rxjs';
import { isDefined } from '../functions/is-defined.function';

export function defined<T extends R | undefined | null, R>(): OperatorFunction<
  T,
  R
> {
  return (source: Observable<T>) => {
    return new Observable((subscriber) => {
      return source.subscribe({
        next(value: T): void {
          if (isDefined(value)) subscriber.next(value as unknown as R);
        },
        error(error: any): void {
          subscriber.error(error);
        },
        complete(): void {
          subscriber.complete();
        },
      });
    });
  };
}
