import { createAction, createGetter, createMutation, mutate, on, select, StoreFeature } from "@softline/core";
import { inject } from "@angular/core";
import { Template } from "./template";
import { lastValueFrom } from "rxjs";
import { TemplateService } from "./services/template.service";

export interface LoadTemplatesParameters {
  group?: string;
}

export interface State<T> {
  templates: Template<T>[]
}

export class Store<T> {

  mutations = {
    template: {
      add: createMutation<State<T>, Template<T>>('add template'),
      addMany: createMutation<State<T>, Template<T>[]>('addMany template'),
      remove: createMutation<State<T>, Template<T>>('remove template'),
      clear: createMutation<State<T>>('clear template'),
    },
  };

  actions = {
    template: {
      loadMany: createAction<State<T>, LoadTemplatesParameters, Template<T>[]>(
        'load templates'
      ),
      loadManyOnce: createAction<State<T>, Template<T>, Template<T>[]>(
        'load once templates'
      ),
      create: createAction<State<T>, Template<T>, Template<T>>(
        'create template'
      ),
      update: createAction<State<T>, Template<T>, Template<T>>(
        'update template'
      ),
      delete: createAction<State<T>, Template<T>, Template<T>>(
        'delete template'
      ),
    },
  };

  getters = {
    template: {
      templates: createGetter<State<T>, Template<T>[], string | undefined>('templates'),
      template: createGetter<State<T>, Template<T>, string | number>('template'),
    },
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      templates: [],
    },
    mutations: [
      mutate(this.mutations.template.add, ({ state, params }) => {
        const templates = state.templates.filter(o => o.id !== params.id);
        templates.push(params)
        return { ...state, templates };
      }),
      mutate(this.mutations.template.addMany, ({ state, params }) => {
        const templates = [...state.templates]
        for(const template of params) {
          const index = templates.findIndex(o => o.id === template.id)
          if(index > -1)
            templates.splice(index, 1, template);
          else
            templates.push(template);
        }
        return { ...state, templates };
      }),
      mutate(this.mutations.template.remove, ({ state, params }) => {
        const index = state.templates.indexOf(params);
        if (index === -1)
          throw new Error(
            `TemplateStore: Can not process mutation 'remove'. Can not find item '${JSON.stringify(
              params
            )}'`
          );

        const templates = state.templates.filter((o) => o !== params);
        return { ...state, templates };
      }),
      mutate(this.mutations.template.clear, ({ state, params }) => ({
        ...state,
        templates: [],
      })),
    ],
    actions: [
      on(
        this.actions.template.loadMany,
        async ({ featureName, params, commit }) => {
          const service = inject(TemplateService);
          const templates = await lastValueFrom(service.loadMany(params?.group));
          commit(
            featureName,
            this.mutations.template.addMany,
            templates
          );
          return templates;
        }
      ),
      on(
        this.actions.template.create,
        async ({ featureName, params, commit }) => {
          const service = inject(TemplateService);
          const template = await lastValueFrom(service.create(params));
          commit(
            featureName,
            this.mutations.template.add,
            template
          );
          return template;
        }
      ),
      on(
        this.actions.template.update,
        async ({ featureName, params, commit }) => {
          const service = inject(TemplateService);
          const template = await lastValueFrom(service.update(params));
          commit(
            featureName,
            this.mutations.template.add,
            template
          );
          return template;
        }
      ),
      on(
        this.actions.template.delete,
        async ({ featureName, params, commit }) => {
          const service = inject(TemplateService);
          const template = await lastValueFrom(service.delete(params));
          commit(
            featureName,
            this.mutations.template.remove,
            template
          );
          return template;
        }
      ),
    ],
    getters: [
      select(this.getters.template.template, ({ state, params }) => state.templates.find(o => o.id === params)),
      select(this.getters.template.templates, ({ state, params }) => state.templates.filter(o => !params || o.group === params)),
    ],
  };
}

export function create<T>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
