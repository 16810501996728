import { Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard } from '@softline/auth';
import { SOFTLINE_PERMISSIONS_OFFENE_POSTEN } from './offene-posten.shared';

export const offenePostenRoutes: Routes = [
  {
    path: 'offene-posten',
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './pages/offene-posten/offene-posten.component'
            ).then((o) => o.OffenePostenComponent),
      },
      { path: '**', redirectTo: '' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSIONS_OFFENE_POSTEN },
    providers: [],
  },
];
