import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from '@softline/dynamic';
import { FieldOkObjektItem } from '../models/field-ok-objekt-item.model';

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './objekt.list-view.html',
  styleUrls: ['./objekt.list-view.scss'],
})
export class ObjektListView implements FieldOkItemComponent<FieldOkObjektItem>{

  @Input() item!: FieldOkObjektItem;

  constructor() {
  }
}
