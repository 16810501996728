import {animate, style, transition, trigger} from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, input, Input, OnInit, output, Output } from '@angular/core';
import moment from 'moment';
import {MomentCalendarService} from '../../../services/moment-calendar.service';
import {DateService, Store} from '@softline/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {I18nModule} from '../../../i18n/i18n.module';
import {L10nModule} from '../../../l10n/l10n.module';
import {UiCorePipesModule} from '../../../pipes/ui-core-pipes.module';
import {SelectComponent} from '../../form/select/select.component';
import {SelectOptionDirective} from '../../form/select/select-option/select-option.directive';
import {ComboBoxComponent} from '../../form/combo-box/combo-box.component';
import {ComboBoxOptionDirective} from '../../form/combo-box/combo-box-option/combo-box-option.directive';
import {SOFTLINE_FEATURE_MODAL} from '../../../modal/modal.shared';
import * as ModalStore from '../../../modal/modal.store';
import {DateParser} from '../../../services/date.parser';
import { MbscDatepickerModule } from '@mobiscroll/angular';
import { localeDe } from '@mobiscroll/angular';


export interface Day {
  date: moment.Moment;
  day: number;
}
export interface Week {
  number: number;
  days: Day[];
}
export interface Month {
  year: number;
  month: number;
  weeks: Week[];
}

@Component({
  selector: 'soft-date-picker',
  animations: [
    trigger('showHide', [
      transition(':enter', [
        style({ opacity: 0, clipPath: 'inset(0px 0px 100% 0px)' }),
        animate(
          '150ms',
          style({ opacity: 1, clipPath: 'inset(0px 0px 0px 0%)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '150ms',
          style({ opacity: 0, clipPath: 'inset(0px 0px 100% 0px)' })
        ),
      ]),
    ]),
  ],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    L10nModule,
    UiCorePipesModule,
    SelectComponent,
    SelectOptionDirective,
    ComboBoxComponent,
    ComboBoxOptionDirective,
    MbscDatepickerModule
  ]
})
export class DatePickerComponent {
  public localeDe = localeDe;

  value = input<string>(this.dateService.today());
  valueChange = output<string>();

  readonly shortcuts: { value: string; label: string }[] = [
    {
      label: 'Gestern',
      value: 'h-1'
    },
    {
      label: 'Vor einer Woche',
      value: 'h-7'
    },
    {
      label: 'Vor einem Monat',
      value: 'h-1m'
    },
    {
      label: 'Vor einem Jahr',
      value: 'h-1j'
    },
    {
      label: 'Wochenanfang',
      value: 'wa'
    },
    {
      label: 'Monatsanfang',
      value: 'ma'
    },
    {
      label: 'Jahresanfang',
      value: 'ja'
    },
  ];

  constructor(
    readonly dateService: DateService,
    readonly dateParser: DateParser<moment.Moment>,
    readonly store: Store
  ) { }

  onCellClick(date: Date): void {
    this.valueChange.emit(date.toISOString());
  }


  selectShortcut(shortcut:  { value: string; label: string }): void {
    const parsedDate = this.parseShortcutDate(shortcut.value);

    if (!parsedDate)
      return;

    this.valueChange.emit(moment(parsedDate).toISOString(true));
  }

  async openShortcutsList(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<string>(),
      {
        title: 'Shortcuts',
        dismiss: true,
        options: this.shortcuts.map(o => ({
          value: o.value,
          label: o.label
        }))
      }
    );

    if (!result || result === 'DISMISSED')
      return;

    const shortcut = this.shortcuts.find(o => o.value === result);

    if (!shortcut)
      return;

    this.selectShortcut(shortcut);
  }

  private parseShortcutDate(value: string): moment.Moment | null {
    const result = this.dateParser.parse(value);

    if (!result)
      return null;

    return moment.isMoment(result) ? result : moment(result);
  }
}
