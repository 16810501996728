import { Component, computed, effect, EventEmitter, inject, input, Output, signal } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import { SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME } from "../../../application.shared";
import { containsText, debouncedSignal } from "@softline/core";

export interface FilterParams {
  caseSensitive: boolean;
  wholeWord: boolean;
}

@Component({
  selector: 'soft-filter-input',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './filter-input.component.html',
  styleUrl: './filter-input.component.scss',
})
export class FilterInputComponent<T> {

  debounceTime: number = inject(SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME);

  input = signal<string>('');
  @Output() inputChange = new EventEmitter<string>();

  debouncedInput = debouncedSignal(this.input, this.debounceTime);
  @Output() debouncedInputChange = new EventEmitter<string>();

  params = signal<FilterParams>({ caseSensitive: false, wholeWord: false });
  @Output() paramsChange = new EventEmitter<FilterParams>();


  items = input<T[]>([]);
  filteredItems = computed(() => {
    const input = this.debouncedInput();
    const params = this.params();
    return this.items().filter(item => containsText(item, input, !params.caseSensitive));
  });


  inputEffect = effect(() => {
    this.inputChange.emit(this.input());
  });
  debouncedInputEffect = effect(() => {
    this.debouncedInputChange.emit(this.debouncedInput());
  });
  paramsEffect = effect(() => {
    this.paramsChange.emit(this.params());
  });

  constructor() { }

  onParamsClick(key: keyof FilterParams) {
    this.params.set({ ...this.params(), [key]: !this.params()[key] });
  }
}
