<div *ngIf="definition" [ngClass]="definition.class | classRule: value">
  <ng-container *ngFor="let child of definition.definitions">
    <div
      *ngIf="definition.visible | booleanRule: value:true"
      [ngClass]="child.class | classRule: value"
    >
      <soft-dynamic-group-value
        *ngIf="child.type === 'group'; else valueTemplate"
        [value]="value"
        [definition]="$any(child)"
        [loading]="loading"
      >
      </soft-dynamic-group-value>
      <ng-template #valueTemplate>
        <label
          *ngIf="child.title"
          class="soft-label"
          [ngClass]="(child.titleClass | classRule: value) || 'left'"
          >{{ child.title }}</label
        >
        <soft-dynamic-value
          class="w-full"
          [definition]="child"
          [value]="value"
          [loading]="loading"
        ></soft-dynamic-value>
      </ng-template>
    </div>
  </ng-container>
</div>
