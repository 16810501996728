<div class="flex flex-col relative" style="pointer-events: none">
  <svg class="donut w-full h-full z-10" viewBox="0 0 233 125" style="pointer-events: none">
    <g>
      @for(data of path(); track data) {
      <path
        fill="transparent"
        style="pointer-events: all"
        [attr.d]="data.d"
        [attr.stroke-width]="strokeWidth()"
        [attr.stroke]="data.color"
        (mouseenter)="segmentHovered.emit(data.color)"
        (mouseleave)="segmentHovered.emit('')"/>
      }
    </g>
  </svg>

  <div
    class="absolute z-10 select-none text-success-700 font-semibold flex inset-0 text-xs text-center justify-center items-center mt-8"
    style="pointer-events: none">
    <div #ref>
      <ng-content></ng-content>
    </div>

    @if (!ref.innerHTML.trim()) {
      {{ (total() || 0) | number:'.0-2' }}
    }
  </div>
</div>
