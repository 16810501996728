import {
  Directive,
  forwardRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ViewDirective } from './view-directive';

@Directive({
  selector: '[softInputView]',
  exportAs: 'inputView',
  providers: [
    {
      provide: ViewDirective,
      useExisting: forwardRef(() => InputViewDirective),
      multi: true,
    },
  ],
})
export class InputViewDirective<T> extends ViewDirective {
  private _onCreate?: (entity: Partial<T>) => void;

  @Input('softInputView') name = '';
  @Input() title = '';
  @Input() override visible = true;

  constructor(template: TemplateRef<any>, viewContainer: ViewContainerRef) {
    super(template, viewContainer);
  }

  create(entity: Partial<T>): void {
    if (this._onCreate) this._onCreate(entity);
  }

  registerCreate(onCreate: (entity: Partial<T>) => void): void {
    this._onCreate = onCreate;
  }
}
