import { DictionaryStore2, ListStore2, Store2Builder } from '@softline/core';
import { InjectionToken } from '@angular/core';
import { MenuItem } from './menu-item';

const menuItemStore2 = new Store2Builder<DictionaryStore2<MenuItem[]>>('menuItems')
  .withFeature(DictionaryStore2<MenuItem[]>);

export const MenuItemStore2 = new InjectionToken('MenuItemStore2', {
  providedIn: 'root',
  factory: () => menuItemStore2.build()
})
