<div class="flex w-full justify-between flex-wrap mt-4">
  <div class="flex flex-col">
    <h1 class="text-2xl font-bold">
      {{ '#GESAMTABFRAGE.PAGES.BUSINESS_CASE.TITLE' | translate }}
    </h1>
    <span class="text-sm color-light"
      >{{ geschaeftsfallLength$ | async }}
      {{ '#GESAMTABFRAGE.PAGES.LIST.RESULTS' | translate }}</span
    >
  </div>
  <soft-input
    class="w-full sm:w-[30vw]"
    placeholder="{{ '#GESAMTABFRAGE.PAGES.LIST.FIND_PLACEHOLDER' | translate }}"
    (valueChange)="search$.next($event ?? '')"
  >
  </soft-input>
</div>
<hr class="my-4" />

<div class="w-full overflow-x-auto">
  <soft-dynamic-table
    selectionMode="multi"
    tableClass="soft-table no-border"
    class="body-border divided"
    [definition]="$any(definition$ | async)"
    [rows]="(filteredData$ | async) ?? []"
    [loading]="(loading$ | async) ?? false"
    (selectedItemsChange)="onSelectedItemsChange($event)"
    [selectedItems]="(selected$ | async) ?? []"
  ></soft-dynamic-table>
</div>
