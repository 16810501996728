<input
  [type]="type"
  [value]="
    escapeHtml
      ? (value ?? null | deserializeHtmlCharacterEntities)
      : value ?? null
  "
  (keydown)="$event.key !== 'Enter' && $event.stopPropagation()"
  (input)="setValue(inputElement.value)"
  [readOnly]="readonly"
  [autocomplete]="autocomplete"
  placeholder="{{
    placeholder || (type === 'email' ? '#UI_CORE.COMPONENTS.INPUT.EMAIL_PLACEHOLDER' :'#UI_CORE.COMPONENTS.INPUT.PLACEHOLDER') | translate
  }}"
  (blur)="onTouch(); blur.emit()"
  #inputElement
/>
<ng-content></ng-content>
