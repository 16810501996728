import {ENVIRONMENT_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, Provider} from "@angular/core";
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  Store,
  StoreModule
} from "@softline/core";
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore} from "@softline/ui-core";
import {default as de} from "../i18n/de.json";
import {ApplicationStore, Command, SOFTLINE_FEATURE_APPLICATIONS} from "@softline/application";
import {ChecklistenStore} from './store/checklisten.store';
import {
  SOFTLINE_FEATURE_CHECKLISTEN,
  SOFTLINE_FEATURE_CHECKPUNKTE,
  SOFTLINE_PERMISSION_CHECKLISTE
} from './checkliste.shared';
import {SOFTLINE_API_CHECKLISTE_CHECKPUNKTE, SOFTLINE_API_CHECKLISTE_ENTITIES} from './checkliste.api';
import {CheckpunkteStore} from './store/checkpunkte.store';
import {ChecklistenService} from './services/checklisten.service';

const CHECKLISTE_COMMANDS: Command[] = [
  {
    class: 'menu main-menu main-menu-top separator',
  },
  {
    icon: 'fa-regular fa-check',
    name: '#CHECKLISTE.MODULE_TITLE',
    class: 'menu main-menu main-menu-top info',
    routerLink: ['/checkliste'],
  },
];

export const checklisteProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue: () => {
      const store = inject(Store);

      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'allgemein-checkliste',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: '#CHECKLISTE.MODULE_TITLE',
          icon: 'fa-regular fa-clipboard-check',
          route: '/aufgaben',
          permission: SOFTLINE_PERMISSION_CHECKLISTE
        }
      )
    },
  },
  ChecklistenService,
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_CHECKLISTEN,
      feature: ChecklistenStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_CHECKLISTE_ENTITIES
        )
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_CHECKPUNKTE,
      feature: CheckpunkteStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_CHECKLISTE_CHECKPUNKTE
        )
      }]
    })
  )
];
