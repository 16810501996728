import { inject, Injectable, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { AddOnService } from '@softline/application';

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithAddonHost = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class AddonHostMixin extends Base implements OnInit, OnDestroy {

    vcRef = inject(ViewContainerRef);
    addOnService = inject(AddOnService);

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();

      await this.addOnService.attachTo(this);
    }

    async ngOnDestroy(): Promise<void> {
      await this.addOnService.detachFrom(this);

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return AddonHostMixin;
}
