import { booleanAttribute, Injectable } from "@angular/core";
import { DateService, Store } from "@softline/core";
import { ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { LabelType } from "../../../data/label-type";
import { ScannerService } from "../../../services/scanner.service";
import { Scan } from "../../../data/scan";
import { ScanDialog } from "../../../dialogs/scan.dialog";
import { stringToScan } from "../../../functions/string-to-scan.function";
import { ScannerStore, SOFTLINE_FEATURE_SCANNER } from "@softline/application";
import { skip } from "rxjs";

const MODAL_ID = 'ExternalScannerModal';

@Injectable()
export class ExternalScannerService extends ScannerService {

  private scanValue: string = '';
  private scanStarted: boolean = false;

  private listener = async (event: KeyboardEvent) => {
    if(event.key ==='Control' || event.key ==='Shift')
      return;
    let scanCompleted = false
    if(event.ctrlKey && event.key === 'q'){
      this.scanStarted = true;
    }
    else if (this.scanStarted && event.key !== 'Enter') {
      if(event.key !== 'Alt' && event.key !== 'AltRight')
        this.scanValue += event.key
    }
    else if (this.scanStarted && this.scanValue) {
      scanCompleted = true;
      const scan = stringToScan(this.scanValue, this.dateService.now());
      const modal = this.store.get(SOFTLINE_FEATURE_MODAL, ModalStore.getters.items)
        .find(o => o.id === MODAL_ID);
      if(modal)
        await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.resolve<Scan>(),
          {id: MODAL_ID, result: scan})
      else
        this.store.commit(SOFTLINE_FEATURE_SCANNER, ScannerStore.mutations.add, scan);
      this.scanValue = '';
      this.scanStarted = false;
    } else
      this.scanStarted = false;

    if(this.scanStarted || scanCompleted) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  constructor(private store: Store, private dateService: DateService) {
    super();
  }

  override async init(): Promise<void> {
    this.isAvailable = true;
  }

  override async activate(): Promise<void> {
    document.addEventListener('keydown', this.listener, true);
  }

  override async deactivate(): Promise<void> {
    document.removeEventListener('keydown', this.listener, true);
  }

  async scan(labelType?: LabelType | LabelType[]): Promise<Scan> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Scan, any>(),
      {
        id: MODAL_ID,
        component: ScanDialog,
        priority: 50,
        dismiss: true,
      }
    );
    if (result === 'DISMISSED')
      throw new Error('ExternalScannerService: canceled scan');

    return result;
  }

  async cancel(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.close,
      MODAL_ID
    );
  }
}
