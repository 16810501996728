import { Injectable } from '@angular/core';
import { CheckOption } from '../check-group';

@Injectable()
export class CommonCheckStrategy {
  getValues(options: CheckOption[]): any[] {
    return options.map((o) => o.value);
  }

  setOptions(options: CheckOption[], values: any[] | null | undefined): void {
    if (!values || !options?.length) return;

    if (values.length !== options.length)
      throw new Error(
        'CheckGroup: Number of children does not match the number of values provided'
      );

    let i = 0;
    for (const option of options) option.writeValue(values[i++]);
  }
}
