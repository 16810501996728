import { isDefined } from './is-defined.function';

export interface CountFunctionParams<T> {
  comparer?: (f: T, s: T) => boolean;
}

export function count<T>(
  array: T[],
  value: T,
  params?: CountFunctionParams<T>
): number {
  const comparer = params?.comparer ?? ((f: T, s: T) => f === s);
  return array.reduce((p, c, i) => (comparer(c, value) ? p + 1 : p), 0);
}
