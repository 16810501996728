/*
 * Public API Surface of browser
 */

export * from './lib/cloud-messaging/services/browser-cloud-messaging.service';
export * from './lib/cloud-messaging/browser-cloud-messaging.providers';


export * from './lib/nfc-reader/browser-nfc-reader.service';
export * from './lib/nfc-reader/browser-nfc-reader.providers';

export * from './lib/pwa/pwa.providers';
export * from './lib/scanner/html5/html5-scanner.module';
export * from './lib/scanner/html5/services/html5-scanner.service';
export * from './lib/scanner/js/js-scanner.module';
export * from './lib/scanner/js/services/js-scanner.service';
export * from './lib/file/services/browser-file.service';
export * from './lib/browser.module';
