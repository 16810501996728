<div
  *ngIf="userAccounts$ | async as userAccounts"
  (click)="userAccounts && (userAccounts?.length || 0) > 1 && onChangeAccount(userAccounts)"
  [ngClass]="userAccounts.length > 1 ? 'bg-primary-600 hover:brightness-110' : ''"
  class="w-full flex flex-row items-center justify-between mt-3 cursor-pointer py-3 -mb-4 px-4">
  <ng-container *ngIf="activeUserAccount$ | async as activeUserAccount">
    <div class="text-primary-contrast-600" *ngIf="userAccounts.length > 1">{{activeUserAccount.name}}</div>
    <span *ngIf="userAccounts.length <= 1">{{activeUserAccount.name}}</span>
  </ng-container>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-primary-contrast-600 w-5 h-5" *ngIf="userAccounts.length > 1">
    <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
  </svg>
</div>
