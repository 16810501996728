import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RemoteObjectStore, Store } from '@softline/core';
import {
  AuthenticationContextStore,
  JwtAuthenticationStore,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION,
} from '@softline/auth';
import {first, map} from 'rxjs/operators';
import { handleRequestErrors, SOFTLINE_FEATURE_MASTER_DATA } from '@softline/application';
import { combineLatest, firstValueFrom } from "rxjs";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import { Firma } from "../data/firma";
import { PatchContextStore, SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT, Vkforg } from "@softapps/allgemein/utils";

@Component({
  selector: 'soft-firma-context',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './firma-context.component.html',
  styleUrls: ['./firma-context.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirmaContextComponent implements OnInit {

  firmen$ = this.store
    .observe(SOFTLINE_FEATURE_MASTER_DATA, RemoteObjectStore.getters.data)
    .pipe(map((o) => (o as { firmaModule: Firma[] })?.firmaModule ?? []));

  selectedFirmaId$ = this.store.observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data).pipe(
    map(data => (data as any)?.firmaid)
  )

  constructor(private store: Store) { }

  ngOnInit(): void {}

  async onChange(firmaid: number): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.mutations.patch,
      { firmaid }
    );
  }
}
