import { Component, InjectionToken, Type } from '@angular/core';
import { Scanner } from './data/scanner';
import { ScannerService } from './services/scanner.service';

export type ScannerServiceSelector = (scanners: Scanner[]) => ScannerService;

export const SOFTLINE_CONFIG_SCANNER_SELECTOR =
  new InjectionToken<ScannerServiceSelector>('SCAN_SERVICE_SELECTOR');
export const SOFTLINE_CONFIG_SCANNER = new InjectionToken<any>('SOFTLINE_CONFIG_SCANNER'); // new InjectionToken<Scanner[]>('SOFTLINE_CONFIG_SCANNER');

export const SOFTLINE_SETTINGS_SCANNER = 'scanner';
