import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiCoreComponentsModule } from './components/ui-core-components.module';
import { UiCoreDirectivesModule } from './directives/ui-core-directives.module';
import { I18nModule } from './i18n/i18n.module';
import { L10nModule } from './l10n/l10n.module';
import { MessageBarModule } from './message-bar/message-bar.module';
import { ModalModule } from './modal/modal.module';
import { UiCorePipesModule } from './pipes/ui-core-pipes.module';
import { DateParser } from './services/date.parser';
import { IsoDateParser } from './services/iso-date.parser';
import { IsoTimeParser } from './services/iso-time-parser';
import { TimeParser } from './services/time.parser';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_TRANSLATION } from './i18n/i18n.shared';
import * as TranslationStore from './i18n/translation.store';
import * as LocalizationStore from './l10n/localization.store';
import { SOFTLINE_FEATURE_LOCALIZATION } from './l10n/l10n.shared';
import { LayoutModule } from '@angular/cdk/layout';
import { BreakpointModule } from './utilities/breakpoints/breakpoint.module';
import { default as deLang } from '../i18n/de.json';
import { default as deLoc } from '../l10n/de.json';
import { PreviewService } from './services/preview.service';
import { PlatformModule } from '@angular/cdk/platform';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,

    PortalModule,

    MessageBarModule,
    ModalModule,
    I18nModule,
    L10nModule,

    UiCoreComponentsModule,
    UiCorePipesModule,
    UiCoreDirectivesModule,
  ],
  exports: [
    MessageBarModule,

    I18nModule,
    L10nModule,
    ModalModule,

    UiCorePipesModule,
    UiCoreDirectivesModule,
    UiCoreComponentsModule,
  ],
})
export class UiCoreModule {
  static forRoot(): ModuleWithProviders<UiCoreRootModule> {
    return {
      ngModule: UiCoreRootModule,
    };
  }
}

@NgModule({
  imports: [
    UiCoreModule,
    PlatformModule,
    LayoutModule,
    I18nModule.forRoot(),
    L10nModule.forRoot(),
    MessageBarModule.forRoot(),
    ModalModule.forRoot(),
    BreakpointModule.forRoot(),
  ],
  exports: [UiCoreModule],
  providers: [
    PreviewService,
    { provide: DateParser, useClass: IsoDateParser },
    { provide: TimeParser, useClass: IsoTimeParser },
  ],
})
export class UiCoreRootModule {
  constructor(store: Store) {
    store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
      module: 'ui-core',
      language: 'de',
      translations: deLang,
    });
    store.commit(
      SOFTLINE_FEATURE_LOCALIZATION,
      LocalizationStore.mutations.add,
      {
        module: 'ui-core',
        locale: 'de',
        formats: deLoc,
      }
    );
  }
}
