import { FieldOkStore } from "@softline/dynamic";

export interface FOKAnwender {
  id: number;
  vorname: string;
  nachname: string;
  kuerzel?: string | null;
  anwender: string | number;
}

export const SOFTLINE_FEATURE_SEND_NOTIFICATION_FIELD_OK = 'softapps/allgemein/sendNotification/fieldOkStore';
export const SendNotificationFieldOkStore = FieldOkStore.create<FOKAnwender, FOKAnwender>();
