import {
  createGetter,
  createMutation,
  isDefined,
  mutate,
  select,
  StoreFeature,
} from '@softline/core';
import { Command } from '../data/command';
import { Type } from '@angular/core';

export interface CommandSet {
  name: string | Type<any>;
  commands: Command[];
  priority?: number;
}

export interface State {
  sets: CommandSet[];
}

export const mutations = {
  addSet: createMutation<State, CommandSet>('addSet'),
  removeSet: createMutation<State, string | Type<any>>('removeSet'),
};

export const getters = {
  commands: createGetter<State, Command[], string | undefined>('commands'),
  hasCommands: createGetter<State, boolean, string | undefined>('hasCommands'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    sets: [],
  },
  mutations: [
    mutate(mutations.addSet, ({ state, params }) => {
      const index = state.sets.findIndex((o) => o.name === params.name);
      if (index === -1) return { ...state, sets: [...state.sets, params] };
      const sets = [...state.sets];
      sets.splice(index, 1, params);
      return { ...state, sets };
    }),
    mutate(mutations.removeSet, ({ state, params }) => {
      const index = state.sets.findIndex((o) => o.name === params);
      if (index === -1)
        throw new Error(
          `CommandStore: A CommandSet with name '${params}' is not registered`
        );
      const sets = [...state.sets];
      sets.splice(index, 1);
      return { ...state, sets };
    }),
  ],
  getters: [
    select(getters.commands, ({ state, params }) => {
      let commands = state.sets
        .sort(programCommandComparer)
        .map((o) => o.commands)
        .reduce((p, c) => [...p, ...c], []);
      if (isDefined(params))
        commands = commands.filter((o) => filterCommand(o, params));
      return commands;
    }),
    select(getters.hasCommands, ({ state, params }) => {
      let commands = state.sets
        .sort(programCommandComparer)
        .map((o) => o.commands)
        .reduce((p, c) => [...p, ...c], []);
      if (isDefined(params))
        commands = commands.filter((o) => filterCommand(o, params));
      return commands.length > 0;
    }),
  ],
  actions: [],
};

function filterCommand(command: Command, classname: string): boolean {
  const exp = new RegExp(`\\b${classname}\\b`);
  return !!command.class && exp.test(command.class);
}

function programCommandComparer(first: CommandSet, second: CommandSet): number {
  const f = first.priority ?? 0;
  const s = second.priority ?? 0;
  return f < s ? 1 : f > s ? -1 : 0;
}
