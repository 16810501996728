<span class="c row">
  <input
    [value]="value || null"
    [type]="isMasked ? 'password' : 'text'"
    (input)="setValue(inputElement.value)"
    [readOnly]="readonly"
    placeholder="{{
      placeholder || '#UI_CORE.COMPONENTS.PASSWORD.PLACEHOLDER' | translate
    }}"
    [autocomplete]="autocomplete"
    (blur)="onTouch()"
    #inputElement
  />
  <i
    class="fa-regular password-mask-toggle pointer"
    [ngClass]="isMasked ? 'fa-eye' : 'fa-eye-slash'"
    (click)="isMasked = !isMasked"
  ></i>
</span>
