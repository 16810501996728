import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {RemoteConfigResolver} from '@softline/application';
import {RetourenResolver} from './resolver/retouren.resolver';
import {SOFTLINE_PERMISSION_MDE_RETOUREN} from './retouren.shared';

export const retourenRoutes: Routes = [
  {
    path: 'mde-retouren',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/retouren.component').then(
            (o) => o.RetourenComponent
          ),
        children: [
          {
            path: 'eingabe-belegdatum',
            loadComponent: () => import('./pages/lieferant-eingabe/lieferant-eingabe.component').then(
              o => o.LieferantEingabeComponent
            )
          },
          {
            path: 'artikel-erfassen',
            loadComponent: () => import('./pages/artikel-erfassen/artikel-erfassen.component').then(
              c => c.ArtikelErfassenComponent
            ),
            loadChildren: () => [
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt1/schritt1.component').then(
                  c => c.Schritt1Component
                ),
                path: 'suche'
              },
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt2/schritt2.component').then(
                  c => c.Schritt2Component
                ),
                path: ':idartstamm'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/mde-retouren/artikel-erfassen/suche'
              }
            ]
          },
          {
            path: '',
            loadComponent: () => import('./pages/erfassung/erfassung.component').then(
              o => o.ErfassungComponent
            )
          },
          {
            path: '**',
            redirectTo: '/mde-retouren'
          },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_MDE_RETOUREN },
    resolve: {
      config: RemoteConfigResolver,
      retouren: RetourenResolver
    },
    providers: [],
  },
];
