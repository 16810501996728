<!--<span class="w-full flex flex-col items-center" *ngIf="firmen$ | async as firmen">
    <div class="c column no-wrap center text-center" *ngIf="firmen.length > 1">
        <span class="" *ngIf="selectedFirma$ | async as selectedFirma">{{ selectedFirma?.kurzbezeichnung || selectedFirma?.firma }}</span>
        <a class="soft-link text-center mt-2" (click)="onChangeCompany()">Firma ändern</a>
    </div>
    <span *ngIf="firmen?.length === 1" style="opacity: 0.75">{{ firmen[0]?.kurzbezeichnung || firmen[0]?.firma }}</span>
</span> -->

<div
  *ngIf="firmen$ | async as firmen"
  (click)="(firmen?.length || 0) > 1 && onChangeCompany(firmen)"
  [ngClass]="(firmen?.length || 0) > 1 ? 'bg-primary-600 hover:brightness-110' : ''"
  class="w-full flex flex-row items-center justify-between mt-3 cursor-pointer py-3 -mb-4 px-4">
  <ng-container *ngIf="selectedFirma$ | async as selectedFirma">
    <div class="text-primary-contrast-600" *ngIf="(firmen?.length || 0) > 1">{{selectedFirma?.firma}}</div>
    <span *ngIf="(firmen?.length || 0) <= 1">{{selectedFirma?.firma || firmen[0]?.kurzbezeichnung}}</span>
  </ng-container>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-primary-contrast-600 w-5 h-5" *ngIf="(firmen?.length || 0) > 1">
    <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
  </svg>
</div>

<soft-blocking-loading-spinner *ngIf="pending$ | async"></soft-blocking-loading-spinner>
