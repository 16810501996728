<div class="entity-picker-container">
  <soft-tab-group
    [(activatedIndex)]="activeViewIndex"
    [hideSelection]="(views | filter: filterViews).length <= 1"
  >
    <soft-tab
      *ngFor="let view of views | filter: filterViews"
      [title]="view.title"
    >
      <ng-template [cdkPortalOutlet]="view.portal"></ng-template>
    </soft-tab>
  </soft-tab-group>
</div>
