import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';

@Component({
  selector: 'soft-allgemein-checkliste-host',
  standalone: true,
  imports: [RouterOutlet],
  template: `<router-outlet></router-outlet>`,
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      flex-grow: 1
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export default class ChecklisteHostComponent implements OnInit, OnDestroy {

  constructor(private readonly store: Store) {}

  ngOnInit() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#CHECKLISTE.MODULE_TITLE'
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      undefined
    );
  }
}
