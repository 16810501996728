import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { BlobErrorHttpInterceptor } from './interceptors/blob-http.interceptor';
import {
  SOFTLINE_CONFIG_INDEXED_DB,
  SOFTLINE_FEATURE_TRANSACTION,
  SOFTLINE_SERVICE_RESPONSE_CONVERTER,
} from './resource.shared';
import { IndexedDBConfig } from './specialized/indexed-db/indexed-db.config';
import { StoreModule } from '../store/store.module';
import * as DownloadStore from './stores/download.store';
import {
  SOFTLINE_STORE_CONNECTION_DOWNLOAD_SERVICE,
  SOFTLINE_STORE_HTTP_DOWNLOAD_SERVICE,
} from './stores/download.store';
import { ConnectionHttpService } from './specialized/http/connection-http.service';
import { HttpService } from './specialized/http/http.service';
import * as TransactionStore from './stores/transaction.store';
import { IndexedDbService } from './specialized/indexed-db/indexed-db.service';

export const SOFTLINE_FEATURE_DOWNLOAD = 'download';

export interface ResourceModuleConfig {
  indexedDb?: IndexedDBConfig;
}

@NgModule({
  imports: [CommonModule],
})
export class ResourceModule {
  static forRoot(): ModuleWithProviders<ResourceRootModule> {
    return {
      ngModule: ResourceRootModule,
    };
  }

  static forFeature(
    config: ResourceModuleConfig
  ): ModuleWithProviders<ResourceModule> {
    const providers: Provider[] = [];
    if (config.indexedDb)
      providers.push({
        provide: SOFTLINE_CONFIG_INDEXED_DB,
        useValue: config.indexedDb,
        multi: true,
      });
    return {
      ngModule: ResourceModule,
      providers,
    };
  }
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_DOWNLOAD,
      feature: DownloadStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_TRANSACTION,
      feature: TransactionStore.feature,
    }),
  ],
  providers: [
    HttpService,
    ConnectionHttpService,
    IndexedDbService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: SOFTLINE_SERVICE_RESPONSE_CONVERTER,
      useValue: (result: any) => result.result,
    },
    {
      provide: SOFTLINE_STORE_CONNECTION_DOWNLOAD_SERVICE,
      useClass: ConnectionHttpService,
    },
    {
      provide: SOFTLINE_STORE_HTTP_DOWNLOAD_SERVICE,
      useClass: HttpService,
    },
  ],
})
export class ResourceRootModule {}
