<div class="flex justify-between flex-wrap w-full mt-4">
  <h1 class="text-2xl font-bold">
    {{ '#HAFTRUECKLASS.PAGES.QUERY.HEADER' | translate }}
  </h1>
  <div class="flex flex-nowrap">
    <button class="soft-button link mr-2" (click)="form.onReset($event)">
      {{ '#HAFTRUECKLASS.PAGES.QUERY.RESET' | translate }}
    </button>
    <button
      class="soft-button primary"
      [disabled]="form.form().invalid"
      (click)="onSubmit(form.form().value)"
    >
      {{ '#HAFTRUECKLASS.PAGES.QUERY.SUBMIT' | translate }}
    </button>
  </div>
</div>
<hr class="my-4" />
<soft-dynamic-form
  class="mt-4"
  [definition]="definition$ | async"
  [value]="value$ | async"
  (formSubmit)="onSubmit(form.form().value)"
  #form
></soft-dynamic-form>
