import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from "@angular/router";
import { Logger, Store } from '@softline/core';
import { Inject, Injectable, Optional } from '@angular/core';
import {
  AuthorizationStore, JwtAuthenticationStore, SOFTLINE_CONFIG_AUTHENTICATION_ROUTE,
  SOFTLINE_FEATURE_AUTHORIZATION, SOFTLINE_FEATURE_JWT_AUTHENTICATION } from "@softline/auth";
import { SOFTLINE_PERMISSION_DASHBOARD } from "../dashboard.shared";

@Injectable()
export class DashboardGuard
  
{
  private authToken: string = '';

  constructor(
    private store: Store,
    private router: Router,
    @Optional()
    @Inject(SOFTLINE_CONFIG_AUTHENTICATION_ROUTE)
    private authenticationRoute: string | undefined
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.isAuthorized();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.isAuthorized();
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean | UrlTree> {
    return this.isAuthorized();
  }

  private async isAuthorized(): Promise<boolean | UrlTree> {
    const authToken = this.store.get(
      SOFTLINE_FEATURE_JWT_AUTHENTICATION,
      JwtAuthenticationStore.getters.token
    ) as string;

    if (authToken !== this.authToken) {
      this.store.commit(
        SOFTLINE_FEATURE_AUTHORIZATION,
        AuthorizationStore.mutations.reset
      );
      try {
        await this.store.dispatch(
          SOFTLINE_FEATURE_AUTHORIZATION,
          AuthorizationStore.actions.load
        );
      }
      catch (e) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_JWT_AUTHENTICATION,
          JwtAuthenticationStore.actions.logout
        );
        return this.router.parseUrl(this.authenticationRoute ?? '/');
      }
      this.authToken = authToken;
    }

    const isAuthorized = this.store.get(
      SOFTLINE_FEATURE_AUTHORIZATION,
      AuthorizationStore.getters.authorized,
      SOFTLINE_PERMISSION_DASHBOARD
    );
    return isAuthorized
      ? true
      : this.router.parseUrl('/');
  }
}
