<ng-container>
  <input
    class="mr-2"
    [value]="value || null"
    (input)="onInput(inputComponent.value)"
    [readOnly]="readonly"
    placeholder="{{
      placeholder || '#UI_CORE.COMPONENTS.ENTITY_QUERY_INPUT.PLACEHOLDER' | translate
    }}"
    #inputComponent
  />
  <ng-container *ngIf="strategy.loading()">
    <i class="fa-regular fa-spinner fa-spin ml-4"></i>
  </ng-container>
  <ng-container *ngIf="value?.length || strategy.loading() || inputComponent.value.length">
    <div class="pointer order-100 ml-4">
      <i
        id="closeDialog"
        class="fa-regular fa-xmark"
        *ngIf="!readonly"
        (click)="onCancel(); $event.stopPropagation()"
      ></i>
    </div>
  </ng-container>
  <ng-container *ngIf="!strategy.loading()">
    <div
      id="openDialog"
      class="ml-4 pointer order-100"
      *ngIf="!readonly"
      (click)="onOpen(); $event.stopPropagation()"
    >
      <i class="fa-regular fa-database"></i>
    </div>
  </ng-container>
</ng-container>
