import { Injectable } from '@angular/core';
import { getExtension, saveAs } from '@softline/core';
import { FileService } from '@softline/core';
import { v4 as uuidv4 } from 'uuid';

declare const window: any;
declare const cordova: any;
declare const device: any;

@Injectable()
export class BrowserFileService extends FileService {
  constructor() {
    super();
  }

  read(file: string): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      reject('Not Implemented');
    });
  }

  write(data: Blob, filename: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      reject('Not Implemented');
    });
  }

  async open(url: string, mimeType?: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      reject('Not Implemented');
    });
  }

  openBlob(data: Blob, filename?: string, mimeType?: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!filename)
        filename = uuidv4() + '.' + getExtension(mimeType ?? data.type ?? '');
      saveAs(data, filename);
      resolve();
    });
  }

  writeTmp(data: Blob, filename?: string, mimeType?: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      reject();
    });
  }

  browse(options?: { mode?: 'single' | 'multi', accept?: string}): Promise<File[]> {
    return new Promise<File[]>((resolve, reject) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('multiple', options?.mode === 'multi' ? 'true' : 'false');
      if(options?.accept)
        input.setAttribute('accept', options?.accept);
      input.onchange = (e) => {
        if (input.files && input.files.length > 0)
          resolve(Array.from(input.files));
        reject('No files chosen');
      };
      input.click();
    });
  }
}
