<div
  *ngIf="definition && form"
  class="soft-list-item-action"
  [formGroup]="form"
>
  <input
    type="reset"
    class="soft-button link"
    (click)="form.reset()"
    [value]="definition.title || ('#DYNAMIC.INPUT.RESET' | translate)"
  />
</div>
