<div class="color-input-container">
  <input
    type="color"
    class="color-input"
    [value]="value"
    (change)="setValue(inputColorElement.value)"
    [readOnly]="readonly"
    (blur)="onTouch(); blur.emit()"
    #inputColorElement
  />
</div>
