import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE,
  SOFTLINE_CONFIG_AUTHENTICATION_ROUTE,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
  SOFTLINE_ROUTE_AUTHENTICATION,
} from './authentication.shared';
import { StoreModule, TelemetryBaggageService } from "@softline/core";
import { AuthenticationContextStore } from './authentication-context.store';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class AuthenticationModule {
  static forRoot(): ModuleWithProviders<AuthenticationRootModule> {
    return {
      ngModule: AuthenticationRootModule,
      providers: [
        {
          provide: SOFTLINE_CONFIG_AUTHENTICATION_ROUTE,
          useValue: SOFTLINE_ROUTE_AUTHENTICATION,
        },
        {
          provide: SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE,
          useValue: '/',
        }
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      feature: AuthenticationContextStore.feature,
    }),
  ],
})
export class AuthenticationRootModule {}
