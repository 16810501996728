import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'single',
})
export class SinglePipe<T, U> implements PipeTransform {
  transform(
    value: readonly T[],
    func: (value: T, parameter?: U) => boolean,
    parameter?: U
  ): unknown {
    const returnValues = value.filter((o) => func(o, parameter));
    if (returnValues.length !== 1) return undefined;
    return returnValues[0];
  }
}
