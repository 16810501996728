import {createAction, CustomStore, on, RemoteCollectionStore, StoreBuilder} from '@softline/core';
import {BestellerfassungArtikelDetail} from '../data/bestellerfassung-artikel-detail';
import {CheckEingabeService} from '../services/check-eingabe.service';
import {lastValueFrom} from 'rxjs';

type State = typeof remoteCollectionStore.feature.initialState

const remoteCollectionStore = RemoteCollectionStore.create<BestellerfassungArtikelDetail>()

const getters = {}

const mutations = {}

const actions = {
  checkEingabe: createAction<State, object, BestellerfassungArtikelDetail['artikelCheckResult']>('mdeBestellerfassung/checkEingabe')
}

const bestellerfassungArtikelDetailStoreExtension: CustomStore<State, typeof mutations, typeof getters, typeof actions> = {
  getters,
  mutations,
  actions,
  feature: {
    initialState: remoteCollectionStore.feature.initialState,
    getters: [],
    mutations: [],
    actions: [
      on(actions.checkEingabe, async ({ state, featureName, params, injector }) => {
        const service = injector.get(CheckEingabeService)
        const result = await lastValueFrom(service.checkEingabe(params));
        return result;
      })
    ]
  }
}

export const BestellerfassungArtikelDetailStore =
  new StoreBuilder(remoteCollectionStore)
    .add(bestellerfassungArtikelDetailStoreExtension)
    .value
