import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {isDefined, Store} from '@softline/core';
import {SOFTLINE_FEATURE_CONTENT} from '../../milchgeld.shared';
import {ContentStore} from '../../store';
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-content-widget',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './content-widget.component.html',
  styleUrls: ['./content-widget.component.scss']
})
export class ContentWidgetComponent {

  private path$ = new BehaviorSubject<string | undefined>(undefined);

  @Input() get path(): string | undefined {
    return this.path$.value;
  }
  set path(value: string| undefined) {
    if (isDefined(value) && value !== '')
      this.store.dispatch(SOFTLINE_FEATURE_CONTENT, ContentStore.actions.loadMany, { queryParams: { name: value }});

    this.path$.next(value);
  }

  contents$ = combineLatest([
      this.store.observe(SOFTLINE_FEATURE_CONTENT, ContentStore.getters.all),
      this.path$
    ])
    .pipe(
      map(([contents, path]) => contents.filter(o => o.name === path))
    );

  constructor(private store: Store) {}
}
