import { Pipe, PipeTransform } from '@angular/core';
import { equals } from '@softline/core';

@Pipe({
  name: 'equals',
})
export class EqualsPipe<T> implements PipeTransform {
  transform(value: T, other: T): boolean {
    return equals(value, other);
  }
}
