import { LocalizationFile } from './data/localization-file';
import {
  createAction,
  createGetter,
  createMutation,
  distinct,
  isDefined,
  mutate,
  NestedError,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import { LocalizationLoader } from './services/localization-loader';
import { SOFTLINE_CONFIG_DEFAULT_LOCALE } from './l10n.shared';

export interface State {
  locale?: string;
  files: LocalizationFile[];
}

export const mutations = {
  add: createMutation<State, LocalizationFile>('add'),
  addMany: createMutation<State, LocalizationFile[]>('addMany'),
  setLocale: createMutation<State, string>('setLanguage'),
  clear: createMutation<State>('clear'),
};

export const getter = {
  locale: createGetter<State, string>('locale'),
  format: createGetter<State, string, string>('format'),
};

export const actions = {
  load: createAction<State, { locale?: string; module?: string }>('load'),
  changeLocale: createAction<State, { locale: string }>('changeLanguage'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    files: [],
    locale: undefined,
  },
  mutations: [
    mutate(mutations.add, ({ state, params }) => {
      const files = [...state.files];
      const index = files.findIndex(
        (o) => o.locale === params.locale && o.module === params.module
      );
      if (index > -1) files.splice(index, 1);
      files.push(params);
      return { ...state, files };
    }),
    mutate(mutations.addMany, ({ state, params }) => {
      const files = [...state.files];
      for (const file of params) {
        const index = files.findIndex(
          (o) => o.locale === file.locale && o.module === file.module
        );
        if (index > -1) files.splice(index, 1);
        files.push(file);
      }
      return { ...state, files };
    }),
    mutate(mutations.setLocale, ({ state, params }) => ({
      ...state,
      locale: params,
    })),
    mutate(mutations.clear, ({ state }) => ({ ...state, translations: [] })),
  ],
  actions: [
    on(actions.load, async ({ store, params, injector, featureName }) => {
      try {
        const service = injector.get(LocalizationLoader);
        const defaultLanguage = injector.get(SOFTLINE_CONFIG_DEFAULT_LOCALE);
        const file = await service.load(
          params.locale ?? defaultLanguage,
          params.module
        );
        store.commit(featureName, mutations.add, file);
      } catch (e: any) {
        throw new NestedError(
          `LocalizationStore: Unable to load localization '${params.locale}' for module '${params.module}'`,
          e
        );
      }
    }),
    on(
      actions.changeLocale,
      async ({ store, state, params, injector, featureName }) => {
        const modules = distinct(state.files.map((o) => o.module));
        const service = injector.get(LocalizationLoader);
        const defaultLanguage = injector.get(SOFTLINE_CONFIG_DEFAULT_LOCALE);

        const newFiles: LocalizationFile[] = [];
        for (const module of modules) {
          const index = state.files.findIndex(
            (o) => o.module === module && o.locale === params.locale
          );
          if (index > -1) continue;
          const file = await service.load(
            params.locale ?? defaultLanguage,
            module
          );
          newFiles.push(file);
        }
        if (newFiles.length > 0)
          store.commit(featureName, mutations.addMany, newFiles);
        store.commit(featureName, mutations.setLocale, params.locale);
      }
    ),
  ],
  getters: [
    select(getter.locale, ({ state }) => state.locale),
    select(getter.format, ({ state, params }) => {
      for (const file of state.files) {
        const format = findFormat(params, file);
        if (isDefined(format)) return format;
      }
      return params;
    }),
  ],
};

function findFormat(key: string, file: LocalizationFile): string | undefined {
  const parts = key.split('.');
  let formats = file.formats as any;
  let format: string | undefined;
  while (parts.length > 0) {
    const partName = parts.shift() ?? '';

    if (!formats)
      break;

    const part = formats[partName];

    if (typeof part === 'object') formats = part;
    else if (parts.length === 0 && typeof part === 'string') format = part;
    else break;
  }
  return format;
}
