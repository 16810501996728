import {
  createGetter,
  createMutation,
  mutate,
  select,
} from '../../../factories';
import { StoreFeature } from '../../../store';
import { QueryParameters } from '../types/query';

export interface State<T = string | number | object> {
  sort?: string;
  limit?: number;
  offset?: number;
  query: T | null;
}

export class Store<T = string | number | object> {
  mutations = {
    query: {
      setState: createMutation<State<T>, QueryParameters<T>>('set query state'),
      setQuery: createMutation<State<T>, T | null>('set query'),
      setSort: createMutation<State<T>, string | undefined>('set sort'),
      setLimitAndOffset: createMutation<
        State<T>,
        { limit?: number; offset?: number }
      >('set limit and offset'),
    },
  };

  getters = {
    query: {
      query: createGetter<State<T>, T | null>('query'),
      queryState: createGetter<State<T>, State<T>>('full query'),
    },
  };

  feature: StoreFeature<State<T>> = {
    initialState: { query: null },
    mutations: [
      mutate(this.mutations.query.setState, ({ state, params }) => ({
        ...state,
        ...params,
      })),
      mutate(this.mutations.query.setQuery, ({ state, params }) => ({
        ...state,
        query: params,
      })),
      mutate(this.mutations.query.setLimitAndOffset, ({ state, params }) => ({
        ...state,
        ...params,
      })),
      mutate(this.mutations.query.setSort, ({ state, params }) => ({
        ...state,
        sort: params,
      })),
    ],
    getters: [
      select(this.getters.query.query, ({ state }) => state.query),
      select(this.getters.query.queryState, ({ state }) => {
        return {
          limit: state.limit,
          offset: state.offset,
          sort: state.sort,
          query: state.query,
        };
      }),
    ],
    actions: [],
  };
}

export function create<T extends string | number | object>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = [];
export const feature = instance.feature;
