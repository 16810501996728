import { Filter, PropertyFilter } from "@softline/core";
import { FilterConfigProperty } from "@softline/application";

export abstract class FilterValueComponentBase<TValues = unknown, TParams = unknown,> {

  onChange!: Function;
  filter: Partial<PropertyFilter> | null | undefined;
  values: TValues[] = [];
  config?: FilterConfigProperty<TParams> = undefined;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
}
