import {GroupedBarChartEntry} from '../types/grouped-bar-chart-data';
import {UmsatzstatistikRemoteConfig} from '../types/remote-config';

export function sortByRemoteConfig(data: GroupedBarChartEntry[], sortOption: UmsatzstatistikRemoteConfig['sort']): GroupedBarChartEntry[] {
  return data.sort((a, b) => {
    if (sortOption === 'Umsatz_asc') {
      const currentUmsatz = a.series.find(o => o.name === 'Umsatz')?.value ?? 0;
      const nextUmsatz = b.series.find(o => o.name === 'Umsatz')?.value ?? 0;
      return nextUmsatz > currentUmsatz ? -1 : 1;
    } else if (sortOption === 'DB_asc') {
      const current = a.series.find(o => o.name === 'Rohertrag')?.value ?? 0;
      const next = b.series.find(o => o.name === 'Rohertrag')?.value ?? 0;
      return next > current ? -1 : 1;
    } else if (sortOption === 'DB_desc') {
      const current = a.series.find(o => o.name === 'Rohertrag')?.value ?? 0;
      const next = b.series.find(o => o.name === 'Rohertrag')?.value ?? 0;
      return next > current ? 1 : -1;
    } else {
      const currentUmsatz = a.series.find(o => o.name === 'Umsatz')?.value ?? 0;
      const nextUmsatz = b.series.find(o => o.name === 'Umsatz')?.value ?? 0;
      return nextUmsatz > currentUmsatz ? 1 : -1;
    }
  });
}
