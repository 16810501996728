import {
  createAction,
  CustomStoreAddOn, FilterCollectionStore,
  on,
  SOFTLINE_STORE_ENTITY_SERVICE, SortCollectionStore,
  StoreBuilder,
  SyncedRemoteCollectionStore
} from '@softline/core';
import {InventurBewe} from '../data/inventur-bewe';
import {lastValueFrom} from 'rxjs';

const collectionStore = SyncedRemoteCollectionStore.create<InventurBewe>()
type State = typeof collectionStore.feature.initialState;

const getters = {}
const mutations = {}
const actions = {
  createOrUpdate: createAction<State, Omit<InventurBewe, 'id'>, InventurBewe>('mdeInventur/createOrUpdate')
}

export const InventurBeweStoreExtension: CustomStoreAddOn<
  State,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  getters,
  mutations,
  actions,
  feature: {
    initialState: collectionStore.feature.initialState,
    getters: [],
    mutations: [],
    actions: [
      on(actions.createOrUpdate, async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
          const result = await lastValueFrom(service.create(params));
          commit(featureName, collectionStore.mutations.addOrUpdate, result);
          return result
      })
    ],
  }
}

export const InventurBeweStore = new StoreBuilder(collectionStore)
  .add(InventurBeweStoreExtension)
  .add(FilterCollectionStore.create<InventurBewe>())
  .add(SortCollectionStore.create<InventurBewe>())
  .value
