import { inject, Injectable, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { CollectionStore2, NestedStore2Feature, QueryStore2, RepositoryCollectionStore2 } from '@softline/core';
import { Router } from '@angular/router';


type Constructor<T extends {}> = new (...args: any[]) => T;
type QueryPageMixinParams<
  TQuery extends object,
  TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>
> = {
  store: InjectionToken<TStore>;
  queryFeature: (o: TStore) => QueryStore2<TQuery>;
};

export const WithQueryPage = <
  TQuery extends object,
  TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>,
  TBase extends Constructor<{}> = Constructor<{}>
> (
  params: QueryPageMixinParams<TQuery, TStore>,
  Base: TBase = class {} as any
) => {
  @Injectable()
  abstract class QueryPageMixin extends Base implements OnInit, OnDestroy
  {
    #queryStore: QueryStore2<TQuery>;

    constructor(...args: any[]) {
      super(...args);
      const injectedStore = inject(params.store);
      this.#queryStore = params.queryFeature(injectedStore);
    }

    async ngOnInit(): Promise<void> {
      if (super['ngOnInit'])
        super['ngOnInit']();
    }

    ngOnDestroy(): void {
      if (super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    async query(query: TQuery): Promise<void> {
      this.#queryStore.setQuery(query);
    }
  }
  return QueryPageMixin;
};
