import { MetaObject } from '@softline/dynamic';
import { FieldOkObjekt } from '../field-ok-objekt.model';

export const fieldOkObjectMeta: MetaObject<FieldOkObjekt> = {
  id: {
    type: 'number',
  },
  objekt: {
    type: 'string',
  },
  bezeichnung: {
    type: 'string',
  },
}
