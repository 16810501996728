import {
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { QueryParameters } from '@softline/core';
import { ViewDirective } from './view-directive';

@Directive({
  selector: '[softQueryView]',
  exportAs: 'queryView',
  providers: [
    {
      provide: ViewDirective,
      useExisting: forwardRef(() => QueryViewDirective),
      multi: true,
    },
  ],
})
export class QueryViewDirective<TQuery> extends ViewDirective {
  private _onQuery?: (parameters: QueryParameters<TQuery>) => void;

  @Input('softQueryView') name = '';
  @Input() title = '';
  @Input() override visible = true;

  constructor(template: TemplateRef<any>, viewContainer: ViewContainerRef) {
    super(template, viewContainer);
  }

  query(parameters: QueryParameters<TQuery>): void {
    if (this._onQuery) this._onQuery(parameters);
  }

  registerQuery(query: (parameters: QueryParameters<TQuery>) => void): void {
    this._onQuery = query;
  }
}
