import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { TicketcobitkatListView } from "./list-view/ticketcobitkat.list-view";

export const ticketcobitkatFieldOk: FieldOkConfig = {
  name: 'ticketcobitkat',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{cobitkat}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: TicketcobitkatListView
    }
  }
  ]
};
export const ticketcobitkatMultiselectFieldOk: FieldOkConfig = {
  name: 'ticketcobitkat',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{cobitkat}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: TicketcobitkatListView
    }
  }
  ]
};

export const ticketcobitkatQueryFieldOk: FieldOkConfig = {
  name: 'ticketcobitkat',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{cobitkat}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: TicketcobitkatListView
    }
  }
  ]
};
