import { Type } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Parent } from '@softline/core';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export function activatedRouteFor(
  ...types: Type<any>[]
): OperatorFunction<any, ActivatedRouteSnapshot> {
  return (source: Observable<any>) =>
    source.pipe(
      filter((o) => o.payload),
      map(
        (o) =>
          containsComponent(
            o.payload.routerState.root,
            types
          ) as ActivatedRouteSnapshot
      ),
      filter((o) => o !== undefined)
    );
}

function containsComponent(
  root: Parent<any>,
  types: Type<any>[]
): ActivatedRouteSnapshot | undefined {
  const stack: any[] = [root];
  while (stack.length > 0) {
    const currentItem = stack.pop();
    if (types.indexOf(currentItem.component) > -1) return currentItem;

    if (currentItem.children) stack.push(...currentItem.children);
  }
  return undefined;
}
