<div
  class="w-full flex gap-2 flex-wrap"
  [class.flex-col]="valueView === 'list' || inputView !== 'common'"
>
  <ng-container *ngIf="value.length > 0" [ngSwitch]="valueView">
    <ng-container *ngSwitchCase="'list'">
      <ng-container *ngTemplateOutlet="valueListView"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="valueGridView"></ng-container>
    </ng-container>
  </ng-container>

  <ng-container [ngSwitch]="inputView">
    <ng-container *ngSwitchCase="'dragAndDrop'">
      <ng-container *ngTemplateOutlet="dragAndDrop"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="common"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #valueGridView>
  <div *ngFor="let file of value; let i = index">
    <div class="grid-value-image h-full">
      <div
        class="grid-value-view items-center justify-between"
        (click)="onShowGallery(i)"
        [ngStyle]="{
          backgroundImage:
            'url(' + ((file | localImage | async) || 'none') + ')'
        }"
      >
        <ng-container
          *ngIf="
            progress | single: getProgress:file as fileProgress;
            else deleteIcon
          "
        >
          <i
            *ngIf="
              fileProgress.state === 'pending' ||
              fileProgress.state === 'processing'
            "
            class="fa-regular fa-spinner fa-spin icon"
          ></i>
          <i
            *ngIf="fileProgress.state === 'succeeded'"
            class="fa-regular fa-check icon succeeded"
          ></i>
        </ng-container>
        <ng-template #deleteIcon>
          <i
            *ngIf="!readonly && !disabled"
            class="fa-regular fa-xmark icon delete px-2"
            (click)="onDelete($any(file))"
          ></i>
        </ng-template>

        <div class="mt-5">
          <ng-container
            *ngTemplateOutlet="fileIcon; context: { $implicit: file.type }"
          ></ng-container>
        </div>

        <span
          title="{{ file.name }}"
          class="text-xs whitespace-nowrap text-center px-2 mb-3 truncate"
        >
          {{ file.name }}
        </span>
        <ng-container
          *ngIf="progress | single: getProgress:file as fileProgress"
        >
          <div
            class="progress-bar"
            [ngStyle]="{
              width:
                ([
                  (fileProgress.uploaded * 100) /
                    (fileProgress.total || file.size),
                  100
                ]
                  | min
                  | number: '1.0-0') + '%'
            }"
          ></div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #valueListView>
  <div>
    <div class="soft-file-list" *ngFor="let file of value; let i = index">
      <div class="soft-file-list-item">
        <div class="v-stack pl-2 pt-2 pb-2 w-full">
          <span class="soft-file-list-item-description truncate max-w-[50vw]">{{
            file.name
          }}</span>
          <span class="soft-file-list-item-filesize">
            <ng-container
              *ngIf="
                progress | single: getProgress:file as fileProgress;
                else size
              "
            >
              {{ fileProgress.uploaded | filesize }} /
              {{ fileProgress.total | filesize }}
            </ng-container>
            <ng-template #size>
              {{ file.size | filesize }}
            </ng-template>
          </span>
        </div>
        <ng-container
          *ngIf="
            progress | single: getProgress:file as fileProgress;
            else actionTemplate
          "
        >
          <i
            *ngIf="
              fileProgress.state === 'pending' ||
              fileProgress.state === 'processing'
            "
            class="fa-regular fa-spinner fa-spin soft-file-list-item-processing"
          ></i>
          <i
            *ngIf="fileProgress.state === 'succeeded'"
            class="fa-regular fa-check soft-file-list-item-succeeded"
          ></i>
          <div
            class="progress"
            [ngClass]="
              'progress-' +
              ([
                (fileProgress.uploaded * 100) /
                  (fileProgress.total || file.size),
                100
              ]
                | min
                | number: '1.0-0')
            "
          ></div>
        </ng-container>
        <ng-template #actionTemplate>
          <span class="flex flex-nowrap">
            <i
              *ngIf="file.name | isImage"
              class="fa-regular fa-eye p-3 galleryButton"
              (click)="onShowGallery(i)"
            ></i>
            <i
              *ngIf="!readonly && !disabled"
              class="fa-regular fa-xmark color-danger soft-file-list-item-delete"
              (click)="onDelete(i)"
            ></i>
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dragAndDrop>
  <div [class.readonly]="disabled || readonly">
    <div
      class="soft-drag-drop-container"
      [ngClass]="{
        'p-16': this.userAgentService.isDesktop(),
        'p-4': !this.userAgentService.isDesktop()
      }"
      [class.readonly]="disabled || readonly"
      softDragDrop
      (fileDropped)="onSelectFile($event)"
      (click)="onChooseFile()"
      *ngIf="
        (selectionMode === 'multi' && value.length >= 0) ||
        (selectionMode === 'single' && value.length === 0)
      "
    >
      <span
        class="soft-chip pos-abs selected-files-length"
        *ngIf="value.length > 0"
      >
        {{
          (('file' | in: _sources)
            ? '#UI_CORE.COMPONENTS.FILE_INPUT.FILE_COUNT'
            : '#UI_CORE.COMPONENTS.FILE_INPUT.IMAGE_COUNT') +
            (value.length > 1 ? '_PL' : '')
            | translate
            | interpolate: { count: value.length }
        }}
      </span>
      <i
        *ngIf="'file' | in: _sources"
        class="fa-regular fa-cloud-arrow-up fa-3x text-primary-400"
      ></i>
      <i
        *ngIf="('file' | in: _sources) === false"
        class="fa-regular fa-camera fa-3x text-primary-400"
      ></i>

      <span class="soft-drag-drop-description mb-4">
        {{
          '#UI_CORE.COMPONENTS.FILE_INPUT.DRAG_AND_DROP_DESC' +
            (selectionMode === 'multi' ? '_PL' : '') | translate
        }}
      </span>

      <a class="text-sm font-bold text-primary cursor-pointer hover:underline">
        {{
          (('file' | in: _sources)
            ? '#UI_CORE.COMPONENTS.FILE_INPUT.CHOOSE_FILE'
            : '#UI_CORE.COMPONENTS.FILE_INPUT.CHOOSE_IMAGE'
          ) | translate
        }}
      </a>
    </div>
  </div>
</ng-template>

<ng-template #common>
  <div
    *ngIf="value.length === 0 || selectionMode === 'multi'"
    class="common-input-view py-3"
    [class.readonly]="disabled || readonly"
    (click)="onChooseFile()"
  >
    <div class="grid-value-view items-center justify-between">
      <i class="fa-regular fa-plus fa-3x text-primary-400 mt-2"></i>
      <span class="text-xs whitespace-nowrap text-center truncate">
        {{
          (('file' | in: _sources)
            ? '#UI_CORE.COMPONENTS.FILE_INPUT.ADD_FILE'
            : '#UI_CORE.COMPONENTS.FILE_INPUT.ADD_IMAGE'
          ) | translate
        }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template let-fileType #fileIcon>
  <ng-container [ngSwitch]="fileType">
    <i *ngSwitchCase="'image/png'"></i>
    <i *ngSwitchCase="'image/jpeg'"></i>
    <i *ngSwitchCase="'image/gif'"></i>
    <i *ngSwitchCase="'image/svg+xml'"></i>
    <i
      *ngSwitchCase="'application/pdf'"
      class="fa-regular fa-file-pdf fa-3x text-primary-400"
    ></i>
    <i
      *ngSwitchCase="'application/msword'"
      class="fa-regular fa-file-word fa-3x text-primary-400"
    ></i>
    <i
      *ngSwitchCase="
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      "
      class="fa-regular fa-file-word fa-3x text-primary-400"
    ></i>
    <i
      *ngSwitchCase="'application/vnd.ms-excel'"
      class="fa-regular fa-file-xls fa-3x text-primary-400"
    ></i>
    <i
      *ngSwitchCase="
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      "
      class="fa-regular fa-file-xls fa-3x text-primary-400"
    ></i>
    <i
      *ngSwitchCase="'application/vnd.ms-outlook'"
      class="fa-regular fa-envelope fa-3x text-primary-400"
    ></i>
    <i
      *ngSwitchCase="'application/zip'"
      class="fa-regular fa-file-zip fa-3x text-primary-400"
    ></i>
    <i *ngSwitchDefault class="fa-regular fa-file fa-3x text-primary-400"></i>
  </ng-container>
</ng-template>
