import { Pipe, PipeTransform } from '@angular/core';
import { ArchiveFile } from '../data/archive';
import { interpolate } from '@softline/core';
import { SOFTLINE_API_ARCHIVE_DOWNLOAD } from '../archive.api';

@Pipe({
  name: 'downloadUrl',
  pure: true,
})
export class DownloadUrlPipe implements PipeTransform {
  constructor() {}

  transform(file: ArchiveFile): string {
    return interpolate(SOFTLINE_API_ARCHIVE_DOWNLOAD, file);
  }
}
