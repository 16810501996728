export abstract class NfcReaderService {

  available: boolean = false;

  constructor() { }

  abstract init(): Promise<any>;

  abstract activate(): Promise<any>;

  abstract deactivate(): Promise<any>;
}
