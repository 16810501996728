import {inject} from '@angular/core';
import {ResolveFn} from '@angular/router';
import {Checkliste} from '../types/checkliste';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_CHECKLISTEN} from '../checkliste.shared';
import {ChecklistenStore} from '../store/checklisten.store';

export const checklistenResolver: ResolveFn<Checkliste[]> = async () => {
  const store = inject(Store);

  return await store.dispatch(
    SOFTLINE_FEATURE_CHECKLISTEN,
    ChecklistenStore.actions.loadManyOnce
  );
}
