import { inject, Injectable, InjectionToken, Injector, OnDestroy, OnInit, signal, Signal } from '@angular/core';
import { handleRequestErrors, WithLoadRepositoryCollection } from '@softline/application';
import {
  CancelledError,
  CollectionStore2, Dictionary,
  NestedStore2Feature,
  Patch,
  RepositoryCollectionStore2,
  SOFTLINE_SERVICE_UUID,
  Store
} from '@softline/core';
import { WithCollection } from './collection.mixin';

type Constructor<T extends {}> = new(...args: any[]) => T;

type CRUDRepositoryCollectionMixinParams<T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>>
  = {store: InjectionToken<TStore>, repositoryFeature: (o: TStore) => RepositoryCollectionStore2<T>};

export const WithCRUDRepositoryCollection = <T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>, TBase extends Constructor<{}> = Constructor<{}>>(params: CRUDRepositoryCollectionMixinParams<T, TStore>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class CRUDRepositoryCollectionMixin
    extends Base
    implements OnInit, OnDestroy {
    #token: string | null = null;
    #destroying = false;

    #injector = inject(Injector);
    #uuid = inject(SOFTLINE_SERVICE_UUID);
    #store = inject(Store);

    #repositoryStore: RepositoryCollectionStore2<T>;

    pathParams = signal<Dictionary<unknown> | undefined>(undefined);

    constructor(...args: any[]) {
      super(...args);
      const injectedStore = inject(params.store);
      this.#repositoryStore = params.repositoryFeature(injectedStore);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
    }

    ngOnDestroy(): void {
      this.#destroying = true;
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    async create(value: T): Promise<T | null> {
      try {
        if(this.#token)
          await this.#repositoryStore.cancel(this.#token);
        this.#token = this.#uuid();
        const params = this.pathParams();
        return await this.#repositoryStore.create({...params, value, token: this.#token });
      }
      catch (e) {
        if(!this.#destroying && !(e instanceof CancelledError))
          handleRequestErrors(this.#store, e);
        return null;
      }
      finally {
        this.#token = null;
      }
    }

    async update(value: T): Promise<T | null> {
      try {
        if(this.#token)
          await this.#repositoryStore.cancel(this.#token);
        this.#token = this.#uuid();
        const params = this.pathParams();
        return await this.#repositoryStore.update({...params, value, token: this.#token });
      }
      catch (e) {
        if(!this.#destroying && !(e instanceof CancelledError))
          handleRequestErrors(this.#store, e);
        return null;
      }
      finally {
        this.#token = null;
      }
    }

    async patch(patch: Patch<T>): Promise<T | null> {
      try {
        if(this.#token)
          await this.#repositoryStore.cancel(this.#token);
        this.#token = this.#uuid();
        const params = this.pathParams();
        return await this.#repositoryStore.patch({...params, patch, token: this.#token });
      }
      catch (e) {
        if(!this.#destroying && !(e instanceof CancelledError))
          handleRequestErrors(this.#store, e);
        return null;
      }
      finally {
        this.#token = null;
      }
    }

    async delete(value: T): Promise<T | null> {
      try {
        if(this.#token)
          await this.#repositoryStore.cancel(this.#token);
        this.#token = this.#uuid();
        const params = this.pathParams();
        return await this.#repositoryStore.delete({...params, value, token: this.#token });
      }
      catch (e) {
        if(!this.#destroying && !(e instanceof CancelledError))
          handleRequestErrors(this.#store, e);
        return null;
      }
      finally {
        this.#token = null;
      }
    }

  }
  return CRUDRepositoryCollectionMixin;
}
