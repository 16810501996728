import { Pipe, PipeTransform } from "@angular/core";
import { isDefinedNotEmpty } from "@softline/core";

@Pipe({
  name: 'isDefinedNotEmpty',
  pure: true,
  standalone: true
})
export class IsDefinedNotEmptyPipe<T> implements PipeTransform {
  transform(value: T | null | undefined): value is T {
    return isDefinedNotEmpty(value);
  }
}
