<div class="flex flex-col">
    <div class="flex flex-row text-sm color-primary">
      <span>{{item['persnr']}}</span>
    </div>
    <div class="flex flex-row">
      <span class="font-bold">{{item['kurzbez']}}</span>
    </div>
    <div class="flex flex-row">
      <span>{{item['beruf']}}</span>
    </div>
  </div>
  
