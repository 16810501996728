<div class="text-lg font-semibold mb-3 mt-2">{{'#WWS_WARENUEBERNAHME.BESTELLUNG.TITEL_BESTELLUNG_UEBERNAHME' | translate}}</div>
<soft-kopfdaten
  [isBestellung]="true"
  [isBestellungUebernahme]="true"
  [bestellung]="_bestellung"
  (lieferscheinnummerChange)="lieferscheinnummerChange($event)">
</soft-kopfdaten>

<button
  class="soft-button primary w-full h-16 text-white mt-4 rounded-sm"
  (click)="close(lieferscheinnummer)">
  {{'#WWS_WARENUEBERNAHME.BESTELLUNG.LABEL_BESTELLUNG_UEBERNEHMEN' | translate}}
</button>
