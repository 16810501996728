import { EventEmitter, Input, Output } from '@angular/core';

export abstract class RadioGroup {
  abstract value: any;
  abstract valueChange: EventEmitter<any>;
  abstract readonly: boolean;
}

export abstract class RadioOption {
  abstract checked: boolean;
  abstract value: any;
  abstract labelPosition: 'before' | 'after';
  abstract disabled: boolean;
  abstract setChecked(value: boolean, propagateToRadioGroup: boolean): void;
}
