import { Store2Feature } from './store2-feature';
import { map } from 'rxjs/operators';
import { distinct } from 'rxjs';
import { Dictionary } from '../types/dictionary';
import { isDefined } from '../functions/is-defined.function';
import { InjectableStore2Feature } from './injectable-store2-feature';

export abstract class NestedStore2Feature<T> extends InjectableStore2Feature<T>{

  private features: Dictionary<Store2Feature<any>> = { };

  protected registerSubFeature(feature: Store2Feature<any>) {
    if (isDefined(this.features[feature.name]))
      throw new Error(`[Store2] Feature '${feature.name}' is already registered`);
    this.features[feature.name] = feature;

    this.setSubFeatureState(feature.name, feature.initialState);
    feature.registerState(
      this.state$.pipe(
        map((state) => state[feature.name]),
        distinct()
    ));
    feature.registerCommit((state) => this.subCommit(feature.name, state));
    feature.onRegister();
  }

  subCommit(feature: string, featureState: unknown): void {
    if (!isDefined(this.features[feature]))
      throw new Error(`[Store2] Feature '${feature}' is not registered`);
    const state = this.state();
    this.commit({ ...state, [feature]: featureState });
  }

  setSubFeatureState(feature: string, featureState: unknown): void {
    const state = this.state();
    this.commit({ ...state, [feature]: featureState });
  }
}
