import {createAction, createMutation, mutate, ObjectStore, on, StoreBuilder, StoreFeature,} from '@softline/core';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,} from '@softline/auth';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PatchContextStoreExtension {
  export const mutations = {
    patch: createMutation<ObjectStore.State, object>('patch'),
  };

  export const actions = {
    load: createAction<ObjectStore.State, undefined, object>('load')
  };

  export const feature: Partial<StoreFeature<ObjectStore.State>> = {
    mutations: [
      mutate(mutations.patch, ({ state, params }) => ({
        ...state,
        data: { ...state.data, ...params },
      })),
    ],
    actions: [
      on(actions.load, ({ get, commit, featureName }) => {
        const context = get(
          SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
          AuthenticationContextStore.getters.data
        );
        commit(featureName, ObjectStore.mutations.set, context);
        return context;
      })
    ],
  };
}

export const PatchContextStore = new StoreBuilder(ObjectStore.create())
  .add(PatchContextStoreExtension)
  .value;
