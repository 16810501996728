import { Observable } from 'rxjs';

export interface Refreshable {
  canRefresh$?: Observable<boolean>;
  refresh(): Promise<void>;
}

export function isRefreshable(program: any): program is Refreshable {
  return (program as Refreshable)?.refresh !== undefined;
}
