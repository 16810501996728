import {Warenuebernahme} from "../types/warenuebernahme";
import {Bewe} from "../types/bewegung";
import {Bestellung} from "../types/bestellung";
import {
  createAction,
  createGetter,
  createMutation,
  CustomStoreAddOn,
  FilterCollectionStore,
  mutate,
  on,
  RemoteCollectionStore,
  select,
  SortCollectionStore,
  StoreBuilder
} from "@softline/core";
import {concat, concatAll, forkJoin, lastValueFrom, map, mergeAll, mergeMap, mergeWith, tap, toArray} from "rxjs";
import {BestellungService} from "../services/bestellung-service";
import {merge} from 'rxjs/internal/operators/merge';

type State = RemoteCollectionStore.State<Bestellung> & {
  bewegungen: Bewe[]
}

const remoteCollectionStore = RemoteCollectionStore.create<Bestellung>();

const mutations = {
  setBewegungen: createMutation<State, Bewe[]>('wwsWarenuebernahmeBestellung/mutations/setBewegungen')
}

const getters = {
  bewegungen: createGetter<State, Bewe[]>('wwsWarenuebernahmeBestellung/getters/bewegungen'),
}

const actions = {
  loadBewegungen: createAction<State, number[], Bewe[]>('wwsWarenuebernahmeBestellung/actions/loadBewegungen'),
  bestellungUebernehmen: createAction<State, {idbestellungList: number[], lieferscheinnummer: string}, Warenuebernahme>('wwsWarenuebernahmeBestellung/actions/bestellungUebernehmen')
}

const WwsWarenuebernahmeBestellungStoreExtension: CustomStoreAddOn<State, typeof mutations, typeof getters, typeof actions> = {
  mutations,
  getters,
  actions,
  feature: {
    initialState: {
      ...remoteCollectionStore.feature.initialState,
      bewegungen: []
    },
    getters: [
      select(getters.bewegungen, ({ state }) => state.bewegungen)
    ],
    actions: [
      on(actions.loadBewegungen, async ({ params, injector, commit, featureName }) => {
        const service = injector.get(BestellungService);

        const result = await lastValueFrom(
          forkJoin(
            params.map(id =>
              service.loadBewegungen(`${id}`)
            )
          ).pipe(
            tap(o => console.log('result1: ', o)),
            map(o => {
              const result: Bewe[] = [];

              for (const array of o) {
                result.push(...array)
              }

              return result
            }),
            tap(o => console.log('result1: ', o))
          )
        );

        console.log('bewegungen: ', result);
        commit(featureName, mutations.setBewegungen, result);

        return result;
      }),
      on(actions.bestellungUebernehmen, async ({ params, injector}) => {
        const service = injector.get(BestellungService);
        const result =  await lastValueFrom(service.bestellungUebernehmen(params.idbestellungList, params.lieferscheinnummer));
        return result;
      })
    ],
    mutations: [
      mutate(mutations.setBewegungen, ({  state, params }) => {
        return {
          ...state,
          bewegungen: params
        }
      })
    ]
  },
}

export const WwsBestellungStore = new StoreBuilder(remoteCollectionStore)
  .add(FilterCollectionStore.create<Bestellung>())
  .add(SortCollectionStore.create<Bestellung>())
  .add(WwsWarenuebernahmeBestellungStoreExtension)
  .value;
