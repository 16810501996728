import { InjectionToken } from '@angular/core';

export interface BreakpointConfig {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
}

export const SOFTLINE_FEATURE_BREAKPOINTS = 'breakpoints';
export const SOFTLINE_CONFIG_BREAKPOINTS = new InjectionToken<BreakpointConfig>(
  'SOFTLINE_CONFIG_BREAKPOINTS'
);
