import {createAction, CustomStoreAddOn,
  FilterCollectionStore, on, SortCollectionStore, StoreBuilder, SyncedRemoteCollectionStore} from '@softline/core';
import {EtikettendruckBewe} from '../data/etikettendruck-bewe';

const collectionStore = SyncedRemoteCollectionStore.create<EtikettendruckBewe>()
type State = typeof collectionStore.feature.initialState;

const getters = {}
const mutations = {}
const actions = {
  createOrUpdate: createAction<State, Omit<EtikettendruckBewe, 'id'>, EtikettendruckBewe>('mdeetikettendruck/createOrUpdate')
}

export const EtikettendruckBeweStoreExtension: CustomStoreAddOn<
  State,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  getters,
  mutations,
  actions,
  feature: {
    initialState: collectionStore.feature.initialState,
    getters: [],
    mutations: [],
    actions: [
      on(actions.createOrUpdate, async ({ commit, featureName, params, injector }) => {
        const result: EtikettendruckBewe = { ...params, id: params.artikel.id };
        commit(featureName, collectionStore.mutations.addOrUpdate, result);
        return result;
      })
    ],
  }
}

export const EtikettendruckBeweStore = new StoreBuilder(collectionStore)
  .add(EtikettendruckBeweStoreExtension)
  .add(SortCollectionStore.create<EtikettendruckBewe>())
  .add(FilterCollectionStore.create<EtikettendruckBewe>())
  .value
