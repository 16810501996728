export interface DateCalculationOptions {
  referenceDate?: Date
  strategy: DateCalculationStrategy
  parts: DateParts
}

export interface DateParts {
  days?: number;
  month?: number;
  years?: number;
}

export type DateCalculationStrategy = (parts: DateParts, referenceDate: Date) => Date

export function addDateCalculationStrategy(parts: DateParts, referenceDate: Date): Date{
  const newDate = referenceDate;

  if (parts?.month) {
    newDate.setMonth(referenceDate.getMonth() + parts.month)
  }

  if (parts?.years) {
    newDate.setFullYear(referenceDate.getFullYear() + parts.years)
  }

  if (parts?.days) {
    newDate.setDate(referenceDate.getDate() + parts.days)
  }

  return newDate
}

export function subtractDateCalculationStrategy(parts: DateParts, referenceDate: Date): Date{
  const newDate = referenceDate;

  if (parts?.month) {
    newDate.setMonth(referenceDate.getMonth() - parts.month)
  }

  if (parts?.years) {
    newDate.setFullYear(referenceDate.getFullYear() - parts.years)
  }

  if (parts?.days) {
    newDate.setDate(referenceDate.getDate() - parts.days)
  }

  return newDate
}

export function calculateDate(options: DateCalculationOptions): Date {
  const referenceDate = options?.referenceDate ? new Date(options.referenceDate) : new Date();
  return options.strategy(options.parts, referenceDate)
}
