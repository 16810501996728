import {inject, Injectable} from '@angular/core';
import {SOFTLINE_WWS_UMSATZSTATISTIK_QUERY_UPDATE_STRATEGY} from '../umsatzstatistik.tokens';
import {FrontendUmsatzstatistikQuery} from '../types/frontend-umsatzstatistik.query';
import {Umsatzebene} from '../types/umsatzebene';


@Injectable()
export class UmsatzstatistikQueryUpdateService {

  private readonly queryUpdaters = inject(SOFTLINE_WWS_UMSATZSTATISTIK_QUERY_UPDATE_STRATEGY, { optional: true });

  async update(ebene: string, value: string, query: FrontendUmsatzstatistikQuery): Promise<FrontendUmsatzstatistikQuery> {
    const updater = (this.queryUpdaters ?? []).find(o => o.umsatzebene === ebene);
    return (await updater?.updateFn?.(value as never, query)) ?? query;
  }

  async remove(ebeneToLoad: string, ebenenForRemoval: Umsatzebene[], query: FrontendUmsatzstatistikQuery): Promise<FrontendUmsatzstatistikQuery> {
    for (const { umsatzebene} of ebenenForRemoval) {
      const updater = this.queryUpdaters?.find(o => o.umsatzebene === umsatzebene);
      query = (await updater?.removeFn?.(query)) ?? query;
    }

    return query;
  }
}
