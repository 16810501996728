import { Injector, Type } from '@angular/core';
import { Modal } from './modal';

export interface DismissConfig {
  backdrop?: boolean;
  escape?: boolean;
  button?: boolean;
}

export interface ModalConfig<T extends Modal<U> & V, U, V extends object> {
  id?: string;
  component: Type<T>;
  class?: string;
  data?: V;
  priority?: number;
  dismiss?: boolean | DismissConfig;
  getInjector?(): Injector; // required because property: Injector Breaks Chromes DevTools Extension
  callback?(result: U): void;
  onDismiss?(): void;
}

export interface CommonModalConfig<
  T extends Modal<U> & V,
  U,
  V extends object
> {
  id?: string;
  component: Type<T>;
  class?: string;
  data?: V;
  priority?: number;
  dismiss?: boolean | DismissConfig;
  getInjector?(): Injector; // required because property: Injector Breaks Chromes DevTools Extension
}
