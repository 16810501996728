import { inject, Injectable, Injector, OnDestroy, OnInit, runInInjectionContext, Signal } from '@angular/core';
import { Command2Service } from './command2.service';
import { Command2 } from './command2';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { distinct } from 'rxjs';

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithCommands2 = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class Commands2Mixin extends Base implements OnInit, OnDestroy {

    #registeredCommands: Command2[] = [];
    #injector = inject(Injector);
    #commandService = inject(Command2Service);
    abstract commands: Signal<Command2[]>;

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnInit(): void {
      if(super['ngOnInit'])
        super['ngOnInit']();
      runInInjectionContext(this.#injector, () => {
        toObservable(this.commands)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {
            const registeredCommands = this.#registeredCommands;
            this.#registeredCommands = o;
            for(const command of registeredCommands)
              this.#commandService.remove(command.name);
            for(const command of o) {
              this.#commandService.register(command);
            }
          })
      });
    }

    ngOnDestroy() {
      for(const command of this.#registeredCommands)
        this.#commandService.remove(command.name);

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return Commands2Mixin;
}
