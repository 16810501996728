import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isArray',
  pure: true,
})
export class IsArrayPipe<T> implements PipeTransform {
  transform(value: unknown): value is T[] {
    return Array.isArray(value);
  }
}
