import { Component, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'soft-page-summary',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-summary.component.html',
  styleUrl: './page-summary.component.scss',
})
export class PageSummaryComponent {
  template = viewChild(TemplateRef);
}
