import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_MODULE } from '../../application.shared';
import * as ModuleStore from '../module.store';
import { Observable } from 'rxjs';

@Injectable()
export class ModuleInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const active = this.store.get(
      SOFTLINE_FEATURE_MODULE,
      ModuleStore.getters.active
    );
    if (!active) return next.handle(request);

    request = request.clone({
      setHeaders: {
        'X-Softline-App-Module': active ?? '',
      },
    });
    return next.handle(request);
  }
}
