<h4>{{ title }}</h4>
<soft-check-group
  class="c column center-v"
  [checkStrategy]="strategy"
  [value]="values"
  #checkGrp="softCheckGroup"
>
  <soft-checkbox
    *ngFor="let option of options; let i = index"
    class="mt-2"
    [trueValue]="option.value"
    [falseValue]="undefined"
  >
    <span class="soft-label right">{{ option.title }}</span>
  </soft-checkbox>
</soft-check-group>
<div class="c row end">
  <button class="soft-button primary" (click)="onSubmit(checkGrp.value)">
    {{ '#DYNAMIC.MULTI_SELECT.SUBMIT' | translate }}
  </button>
</div>
