<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item?.objekt}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item?.bezeichnung}}</span>
  </div>
  <div *ngIf="item.nummer && item.kurzbez" class="flex flex-row">
    <span>{{item.nummer}} - {{item.kurzbez}}</span>
  </div>
</div>
