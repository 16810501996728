import { Pipe, PipeTransform } from "@angular/core";
import { base64Encode } from "@softline/core";

@Pipe({
  name: 'base64Encode',
  pure: true,
  standalone: true
})
export class Base64EncodePipe implements PipeTransform {
  transform(object: any): string {
    return base64Encode(JSON.stringify(object));
  }
}
