export function dataUriToBlob(dataURI: string): Blob {
  const match = /data:(.*?);base64,(.*)/g.exec(dataURI);

  if (!match) throw Error('invalidDataUri');

  const byteString: string = window.atob(match[2]);
  const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array: Uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([int8Array], { type: match[1] });
}

export function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(reader.result as string);
    reader.onerror = (e) => reject(reader.error);
    reader.onabort = (e) => reject(new Error('[blobToDataURL] Read aborted'));
    reader.readAsDataURL(blob);
  });
}
