import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Warenuebernahme} from "../../types/warenuebernahme";
import {Bestellung} from "../../types/bestellung";
import {UiCoreModule} from "@softline/ui-core";

@Component({
  selector: 'soft-kopfdaten',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './kopfdaten.component.html',
  styleUrls: ['./kopfdaten.component.scss'],
})
export class KopfdatenComponent {

  @Input() isBestellung = false;
  @Input() isBestellungUebernahme = false;
  @Input() warenuebernahme?: Warenuebernahme;
  @Input() bestellung?: Bestellung;
  @Output() lieferscheinnummerChange = new EventEmitter<string>();
}
