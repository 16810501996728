import { isDefined } from './is-defined.function';

export function containsText(
  value: unknown,
  text: string,
  ignoreCase: boolean = false
): boolean {
  if (text.length === 0) return true;
  let i = 0;
  const stack: any[] = [];
  stack.push(value);
  while (stack.length > 0) {
    const currentValue = stack.pop();
    if (!isDefined(currentValue)) continue;

    switch (typeof currentValue) {
      case 'string':
        if (
          currentValue.includes(text) ||
          (ignoreCase &&
            currentValue.toLowerCase().includes(text.toLowerCase()))
        )
          return true;
        break;
      case 'number':
        if (('' + currentValue).includes(text)) return true;
        break;
      case 'object':
        let a: unknown[];
        if (Array.isArray(currentValue)) a = currentValue;
        else a = Object.values(currentValue);
        stack.push(...a);
        break;
      default:
        break;
    }
  }
  return false;
}
