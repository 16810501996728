import { Component, Input, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DynamicPipesModule } from "../../pipes/dynamic-pipes.module";
import { DynamicSkeletonComponent } from "../dynamic-skeleton/dynamic-skeleton.component";
import { DynamicValueFieldDirective } from "../../directives/dynamic-value-field.directive";

@Component({
  selector: 'soft-dynamic-value',
  standalone: true,
  imports: [CommonModule, DynamicPipesModule, DynamicSkeletonComponent, DynamicValueFieldDirective],
  templateUrl: './dynamic-value.component.html',
  styleUrls: ['./dynamic-value.component.scss'],
})
export class DynamicValueComponent implements OnInit {
  @Input() definition?: any;
  @Input() value?: any;

  @Input() loading = false;

  constructor() {}

  ngOnInit(): void {}
}
