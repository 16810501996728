import { importProvidersFrom } from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE, SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
  SoftlineEntityService, SoftlineObjectService,
  StoreModule
} from '@softline/core';
import { SOFTLINE_FEATURE_FIBU_KUNDENINFO_LEISTE } from './kundeninfo-leiste.shared';
import { KundeninfoLeisteStore } from './store/kundeninfo-leiste.store';
import { SOFTLINE_API_KUNDENINFO_LEISTE } from './kundeninfo-leiste.api';

export const kundeninfoLeisteProviders = [
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_FIBU_KUNDENINFO_LEISTE,
      feature: KundeninfoLeisteStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
          useFactory: (o: ConnectionHttpService) => new SoftlineObjectService(o, SOFTLINE_API_KUNDENINFO_LEISTE),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    })),
];
