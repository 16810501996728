<div class="flex flex-col full-width sticky -top-4 bg-surface border-b px-4 -mt-4 pb-3 pt-5">
  <h1 class="text-2xl font-semibold mt-3">Kundenauswahl</h1>
</div>


<section class="border rounded flex flex-col gap-2 mt-4 p-4">
  <p class="text-sm">
    Bitte wählen Sie den gewünschten Kunden aus dann automatisch auf die Details
    des Kunden weitergeleitet zu werden.
  </p>
  <soft-value-converter [converter]="customerConverter"
                        (valueChange)="selectCustomer($event)">
    <soft-field-ok
      [name]="'ktostamm.kunde'"
      [placeholder]="'Kunde auswählen/suchen'"
      #convertValue
    >
    </soft-field-ok>
  </soft-value-converter>
</section>
