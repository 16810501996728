import {GesamtabfrageDownloadFile, GesamtabfrageDownloadStrategy} from "./gesamtabfrage-download-strategy";
import {Injectable} from "@angular/core";
import {Belegart} from "../../types/belegart.model";
import {Store} from "@softline/core";
import {SOFTLINE_API_DOWNLOAD_VOLUME} from "../../gesamtabfrage.api";
import {Beleg} from "../../types/beleg.model";

@Injectable()
export class DatentraegerDownloadStrategy implements GesamtabfrageDownloadStrategy {
  type = 'ebinvoice';
  icon = 'fa-regular fa-database';
  label = '#GESAMTABFRAGE.ACTIONS.DOWNLOAD_DATENTRÄGERDATEI';

  constructor(private store: Store) { }

  async getDownloadFiles(belege: Beleg[], belegart: Belegart): Promise<GesamtabfrageDownloadFile[]> {
    let combine = false;
    const files: GesamtabfrageDownloadFile[] = [];

    for (const beleg of belege)
      files.push({
        filename: `${belegart.id}_${beleg.bezugsnummer ?? beleg.belegnummer}_ebinvoice.xml`,
        location: {path: SOFTLINE_API_DOWNLOAD_VOLUME},
        body: { ids: [beleg.id], zusammenfassen: false, belegart, fremdbelegdruck: false },
      });

    return files;
  }
}
