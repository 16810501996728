import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'soft-dynamic-text-value',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit {
  @Input() value?: any;
  @Input() definition: any;

  constructor() {}

  ngOnInit(): void {}
}
