import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { MetaObject } from '../model/meta';

type Constructor<T> = new(...args: any[]) => T;

export const WithMetaLoader = <T extends object, TBase extends Constructor<{}>>(Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class MetaLoaderMixin extends Base implements OnInit, OnDestroy {

    //readonly meta = signal<MetaObject<T> | null>(null);

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
      //const meta = await this.loadMeta();
      //this.meta.set(meta)
    }

    ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    abstract loadMeta(): Promise<MetaObject<T>>;
  }
  return MetaLoaderMixin;
}

export const WithStaticMetaLoader = <T extends object, TBase extends Constructor<{}>>(meta: MetaObject<T>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class StaticMetaFormMixin extends WithMetaLoader(Base) {

    override async loadMeta(): Promise<MetaObject<T>> {
      return meta;
    }

  }
  return StaticMetaFormMixin;
}





  /*= <T extends object, TBase extends Constructor<{}>>(meta: MetaObject<T>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class StaticMetaLoaderMixin extends WithMetaLoader(Base) implements OnInit, OnDestroy {

    constructor(...args: any[]) {
      super(...args);
    }

    override async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        await super['ngOnInit']();
    }

    override ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    override async loadMeta(): Promise<MetaObject<T>> {
      return meta;
    }
  }
  return StaticMetaLoaderMixin;
}
*/
