import { createAction, createGetter, on, select } from "../../factories";
import { StoreFeature } from "../../store";
import { SOFTLINE_STORE_REMOTE_OBJECT_SERVICE } from "./object.shared";
import * as ObjectStore from "./object.store";
import * as ActionStore from "../remote/action.store";
import { ActionState, handleObservableActionState } from "../remote/action.store";
import * as SubscriptionStore from "../remote/subscription.store";
import { handleSubscriptionState } from "../remote/subscription.store";
import { SOFTLINE_SERVICE_UUID } from "../../../core.shared";
import { Dictionary } from "../../../types/dictionary";
import { lastValueFrom } from "rxjs";
import { isDefined } from "../../../functions/is-defined.function";

export interface LoadActionParameters {
  payload?: unknown;
  pathParams?: Dictionary<unknown>;
  queryParams?: Dictionary<unknown>;
  token?: string;
}

export interface State<T extends object = object>
  extends ObjectStore.State<T>,
    SubscriptionStore.State,
    ActionStore.State {}

export class Store<T extends object = object> {
  mutations = {
    ...this.objectStore.mutations,
    action: ActionStore.mutations,
    subscription: SubscriptionStore.mutations,
  };

  getters = {
    ...this.objectStore.getters,
    action: ActionStore.getters,
    subscription: SubscriptionStore.getters,
    loading: createGetter<State<T>, boolean, string | undefined>('loading'),
    loaded: createGetter<State<T>, boolean, string | undefined>('loaded'),
  };

  actions = {
    ...SubscriptionStore.actions,
    load: createAction<State<T>, LoadActionParameters, T>('load'),
    loadOnce: createAction<State<T>, LoadActionParameters, T>('loadOnce'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.objectStore.feature.initialState,
      ...ActionStore.feature.initialState,
      ...SubscriptionStore.feature.initialState,
    },
    mutations: [
      ...this.objectStore.feature.mutations,
      ...ActionStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
    ],
    actions: [
      ...this.objectStore.feature.actions,
      ...ActionStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      on(this.actions.loadOnce,
        async ({ commit, params, featureName, get, dispatch, injector }) => {
          let object = get(featureName, getters.data);
          if(!isDefined(object))
            object = await dispatch(featureName, actions.load, params, injector);
          return object
        }
      ),
      on(this.actions.load,
        async ({ commit, params, featureName, injector }) => {
          const service = injector.get(SOFTLINE_STORE_REMOTE_OBJECT_SERVICE);
          const token = params?.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const subscriptionState$ = handleSubscriptionState(
            service.get(
              params?.payload,
              params?.pathParams,
              params?.queryParams
            ),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscriptionState$,
              featureName,
              commit,
              this.actions.load.name,
              token
            )
          );
          commit(featureName, this.mutations.set, result);
          return result;
        }
      ),
    ],
    getters: [
      ...this.objectStore.feature.getters,
      ...ActionStore.feature.getters,
      select(this.getters.loading, ({ get, featureName, params }) => {
        const actions = [this.actions.load.name];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.loaded, ({ get, featureName, params }) => {
        const actions = [this.actions.load.name];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
  };

  constructor(private objectStore: ObjectStore.Store<T>) {}
}

export function create<T extends object>(): Store<T> {
  const objectStore = ObjectStore.create<T>();
  return new Store<T>(objectStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
