import {Routes} from "@angular/router";
import {AuthenticationGuard, AuthorizationGuard} from "@softline/auth";
import {SOFTLINE_PERMISSION_WWS_WARENUEBERNAHME} from "./warenuebernahme.shared";


export const warenuebernahmeRoutes: Routes = [
  {
    path: 'warenuebernahme',
    loadComponent: () => import('./components/warenuebernahme.component').then(c => c.WarenuebernahmeComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/uebersicht/uebersicht.component').then(c => c.UebersichtComponent)
      },
      {
        path: 'detail/:id',
        loadComponent: () => import('./pages/warenuebernahme-detail/warenuebernahme-detail.component').then(c => c.WarenuebernahmeDetailComponent)
      },
      {
        path: 'bestellung',
        loadComponent: () => import('./components/bestellung/bestellung.component').then(c => c.BestellungComponent),
        children: [
          {
            path: 'schritt-1',
            loadComponent: () => import('./pages/bestellung/bestellung-schritt-1/bestellung-schritt-1.component').then(c => c.BestellungSchritt1Component)
          },
          {
            path: 'schritt-2',
            loadComponent: () => import('./pages/bestellung/bestellung-schritt-2/bestellung-schritt-2.component').then(c => c.BestellungSchritt2Component)
          },
          {
            path: 'detail/:data',
            loadComponent: () => import('./pages/bestellung/bestellung-detail/bestellung-detail.component').then(c => c.BestellungDetailComponent)
          },
          {
            path: '',
            redirectTo: '/warenuebernahme/bestellung/schritt-1',
            pathMatch: 'full'
          }
        ],
      }
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_WWS_WARENUEBERNAHME },
  }
];
