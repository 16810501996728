<input
  [value]="textInput() ?? (value() | format: format())"
  (input)="onTextInput(inputElement.value)"
  (change)="onValueChange(inputElement.value)"
  #inputElement
  (dblclick)="!readonly() ? openDateRangePickerModal() : ''"
  (blur)="blur.emit()"
  [readOnly]="readonly()"
  placeholder="{{ placeholder() | translate}}"
/>
<i
  *ngIf="!readonly()"
  class="fa-regular fa-calendar-range picker-icon pointer ml-4"
  (click)="openDateRangePickerModal()"
></i>
