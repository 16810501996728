import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FilterValueComponentBase } from "../filter-value-component-base";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  I18nModule,
  ModalStore,
  SetFocusDirective,
  SOFTLINE_FEATURE_MODAL, TranslatePipe,
  UiCoreComponentsModule,
  UiCorePipesModule,
  Validators
} from "@softline/ui-core";
import { Store } from "@softline/core";
import { FilterOperatorPipe } from "../../filter-operator.pipe";
import { MultiFilterSelectOptionsPipe } from "./multi-filter-select-options.pipe";

@Component({
  selector: 'soft-text-filter-value',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, UiCoreComponentsModule, UiCorePipesModule, SetFocusDirective, I18nModule, MultiFilterSelectOptionsPipe],
  providers: [FilterOperatorPipe],
  templateUrl: './multi-filter-value.component.html',
  styleUrls: ['./multi-filter-value.component.scss'],
})
export class MultiFilterValueComponent extends FilterValueComponentBase<unknown, {formatFn: (o: unknown) => string}> implements OnInit {

  form = new FormGroup({
    value: new FormControl<string | null>(null, Validators.required()),
  });

  @ViewChild('dialogRef', { static: true }) dialogRef?: TemplateRef<any>;

  constructor(private store: Store,
              private translatePipe: TranslatePipe,
              private operatorPipe: FilterOperatorPipe) {
    super()
  }

  ngOnInit(): void { }

  async onValueClick(): Promise<void> {
    if(!this.filter)
      return;

    this.form.reset(this.filter as any);
    const value = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.template(),
      {
        title: `${this.translatePipe.transform(this.config?.title)} ${this.translatePipe.transform(this.operatorPipe.transform(this.filter.operator)?.title)}`,
        template: this.dialogRef
      })
    if(value === 'DISMISSED')
      return;
    this.onChange({...this.filter, ...this.form.value});
  }

  defaultTransformFn(value: unknown): string {
    if(typeof value === 'object' && value && value['id'])
      return value['id']
    else if (value === 'object' && value)
      return JSON.stringify(value)
    else
      return '' + value;
  }
}
