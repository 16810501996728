import { InjectionToken } from '@angular/core';

export const SOFTLINE_FEATURE_PASSWORD = 'password';

export const SOFTLINE_CONFIG_FORGOT_PASSWORD_LINKS = new InjectionToken<
  { name: string; route: string | string[] }[]
>('SOFTLINE_CONFIG_FORGOT_PASSWORD_LINKS');
export const SOFTLINE_PAGE_FORGOT_PASSWORD = '/forgot-password';
export const SOFTLINE_PAGE_RESET_PASSWORD = 'reset';
export const SOFTLINE_PAGE_LOGIN = '/login';
