import {
  ContentChildren,
  CUSTOM_ELEMENTS_SCHEMA,
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { equals } from '@softline/core';
import { RadioGroup, RadioOption } from './radio-option';
import { RadioOptionComponent } from './radio-option/radio-option.component';

@Directive({
  selector: 'soft-radio-group',
  exportAs: 'softRadioGroup',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupDirective),
      multi: true,
    },
    {
      provide: RadioGroup,
      useExisting: forwardRef(() => RadioGroupDirective),
    },
  ],
})
export class RadioGroupDirective
  extends RadioGroup
  implements ControlValueAccessor
{
  private _value: any;
  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  @ContentChildren(forwardRef(() => RadioOption), { descendants: true })
  options!: QueryList<RadioOption>;

  get value(): any {
    return this._value;
  }
  @Input()
  set value(value: any) {
    if (equals(value, this._value)) return;
    this.setValue(value);
  }
  @Output() valueChange = new EventEmitter<any>();

  @Input() readonly = false;

  constructor() {
    super();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this._value = obj;

    if (!this.options) return;
    for (const option of this.options)
      option.setChecked(equals(obj, option.value), false);
  }

  setValue(value: any): void {
    this._value = value;
    this.valueChange.emit(this._value);

    if (!this.options) return;
    for (const option of this.options)
      option.setChecked(equals(value, option.value), false);

    this.onChange(this._value);
    this.onTouch();
  }
}
