import { Pipe, PipeTransform, Type } from "@angular/core";

type AbstractType<T> = abstract new (...args: any[]) => T;

@Pipe({
  name: 'instanceof',
  standalone: true,
  pure: true
})
export class InstanceofPipe implements PipeTransform {
  transform<T, U>(value: T, type: AbstractType<U>): U | undefined {
    return value instanceof type ? value : undefined;
  }
}
