import { Inject, Injectable, Optional } from '@angular/core';
import { LogWriter } from './log-writer';
import { LogEntry } from './log';

@Injectable()
export class Logger {
  constructor(@Optional() @Inject(LogWriter) private writer: LogWriter[]) {}

  log(logEntry: LogEntry | string): void {
    if (!this.writer) return;
    if (typeof logEntry === 'string') logEntry = { subject: logEntry };

    for (const writer of this.writer) writer.write(logEntry);
  }
}
