<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary justify-between">
    <span>{{item['belegnummer']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item['kurzbez']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="color-light text-sm">{{item['nummer']}} - {{item['kurzbez_faktura']}}</span>
  </div>
</div>
