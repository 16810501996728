import { InjectionToken } from '@angular/core';

export interface LoginLink {
  name: string;
  route: string | string[];
}

export interface LoginConfig {
  scan?: boolean,
}

export const SOFTLINE_CONFIG_LOGIN_LINKS = new InjectionToken<LoginLink[]>(
  'SOFTLINE_CONFIG_LOGIN_LINKS'
);
export const SOFTLINE_CONST_SETTINGS_GROUP_LOGIN = 'login';

export const SOFTLINE_CONFIG_LOGIN = new InjectionToken<LoginConfig>(
  'SOFTLINE_CONFIG_LOGIN'
);
