import { Resolution } from "@softline/capacitor";

export interface QRBoxDimension {
  name: string;
  width: number;
  height: number;
}

export const SOFTLINE_CONST_QRBOX_DIMENSION_250w: QRBoxDimension = {
  name: '250x150',
  width: 250,
  height: 150
}

export const SOFTLINE_CONST_QRBOX_DIMENSION_250: QRBoxDimension = {
  name: '250x250',
  width: 250,
  height: 250
}
export const SOFTLINE_CONST_QRBOX_DIMENSION_500: QRBoxDimension = {
  name: '500x500',
  width: 500,
  height: 500
}
export const SOFTLINE_CONST_QRBOX_DIMENSION_500h: QRBoxDimension = {
  name: '250x500',
  width: 250,
  height: 500
}
