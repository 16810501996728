import { inject, Injectable, Injector, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import { MetaForm } from '../model/meta-form';
import { FormGroup, ValidatorFn } from '@angular/forms';

type Constructor<T> = new(...args: any[]) => T;

export const WithMetaForm = <T extends object, TBase extends Constructor<{}>>(Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class MetaFormMixin extends Base implements OnInit, OnDestroy {
    private validators: ValidatorFn[] | null = null;
    #injector = inject(Injector);

    abstract form: FormGroup;
    abstract metaForm: Signal<MetaForm<T> | null>;

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
    }

    ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

  }
  return MetaFormMixin;
}


export const WithStaticMetaForm = <T extends object, TBase extends Constructor<{}>>(meta: MetaForm<T>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class StaticMetaFormMixin extends WithMetaForm(Base) implements OnInit, OnDestroy {

    override metaForm = signal(meta);

    constructor(...args: any[]) {
      super(...args);
      this.metaForm.set(meta);
    }

    override async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        await super['ngOnInit']();
    }

    override ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

  }
  return StaticMetaFormMixin;
}
