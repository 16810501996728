import { Entity } from '../store/specialized/entity/types/entity';
import { Dictionary } from '../types/dictionary';
import { Observable } from 'rxjs';
import { Patch } from '../store/specialized/entity/types/patch';
import { RequestEvent } from '../data/request';

export interface EntityDetailService<T, TCreate = T> {
  get(
    id: number | string,
    pathParams: Dictionary<unknown> | undefined,
    queryParams: Dictionary<unknown> | undefined,
    body?: unknown | undefined
  ): Observable<T>;
  getMany(
    ids: (number | string)[],
    pathParams: Dictionary<unknown> | undefined,
    queryParams: Dictionary<unknown> | undefined
  ): Observable<T>;

  create(
    id: number | string,
    detail: TCreate,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T>;

  update(
    id: number | string,
    detail: T,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T>;

  patch(
    patch: Patch<T>,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T>;

  delete(
    id: number | string,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T>;

  upload(
    id: number | string,
    detail: TCreate,
    pathParams?: Dictionary<unknown>
  ): Observable<RequestEvent<T>>;
}
