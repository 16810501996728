import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'secondsToHours',
  standalone: true,
  pure: true,
})
export class SecondsToHoursPipe implements PipeTransform {
  transform(seconds: number) {
    const totalMinutes = seconds / 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours > 9 ? hours : `0${hours}`}:${minutes < 10 ? `0${minutes}` : minutes}`
  }
}
