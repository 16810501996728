import { Pipe, PipeTransform } from "@angular/core";
import { FilterConfig } from "@softline/application";
import { SortConfig } from "../../types/sort-config";

@Pipe({
  name: 'sortPropertyName',
  standalone: true,
  pure: true
})
export class SortPropertyNamePipe implements PipeTransform {

  constructor() {
  }

  transform(property: string | null | undefined, config: SortConfig | undefined): string {
    if(!property)
      return '';
    const propertyConfig = config?.properties.find(o => o.property === property);
    return propertyConfig?.title ?? property;
  }

}
