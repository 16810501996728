import moment from 'moment';

export function parseDuration(input: string): string | null {
  try {
    const duration = parseCustomFormat(input) ?? moment.duration(input);

    return duration.toISOString();
  } catch (message) {
    return null;
  }
}

function parseCustomFormat(input: string): moment.Duration | null {
  const match = /^(\d{1,2})(\d{2})?(\d{2})?$/g.exec(input);
  if (match == null) return null;

  const hours = +match[1];
  const minutes = +(match[2] ?? 0);
  const seconds = +(match[3] ?? 0);
  return moment.duration({ hours, minutes, seconds });
}
