import {
  createAction,
  isDefined,
  ListStore,
  on,
  StoreFeature,
  select,
  createGetter,
  createMutation,
  mutate,
} from '@softline/core';
import {
  LoadingMessageBarConfig,
  MessageBarConfig,
  MessageBarFactoryParams,
  ProgressMessageBarConfig,
} from './data/message-bar.config';
import { SOFTLINE_SERVICE_UUID } from '@softline/core';
import { SOFTLINE_CONFIG_MESSAGE_BAR_DURATION } from './message-bar.shared';
import { Observable, throwError } from 'rxjs';

export interface State extends ListStore.State<MessageBarConfig> {}

const listStore = ListStore.create<MessageBarConfig>();
export const mutations = {
  ...listStore.mutations,
  update: createMutation<State, MessageBarConfig>('update'),
};

export const getters = {
  ...listStore.getters,
  container: createGetter<State, MessageBarConfig[], string>('container'),
};

export const actions = {
  open: createAction<State, MessageBarConfig, string>('open'),
  close: createAction<State>('close'),
  closeContainer: createAction<State, string>('closeContainer'),
  closeAll: createAction<State>('closeAll'),

  success: createAction<State, string | MessageBarFactoryParams, string>(
    'success'
  ),
  warning: createAction<State, string | MessageBarFactoryParams, string>(
    'warning'
  ),
  error: createAction<State, string | MessageBarFactoryParams, string>('error'),
  info: createAction<State, string | MessageBarFactoryParams, string>('info'),
  loading: createAction<
    State,
    string | LoadingMessageBarConfig | undefined,
    string
  >('loading'),
  progress: createAction<
    State,
    Observable<string | ProgressMessageBarConfig | undefined>,
    string
  >('progress'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    items: [],
  },
  mutations: [
    ...listStore.feature.mutations,
    mutate(mutations.update, ({ state, params }) => {
      const index = state.items.findIndex((o) => o.id === params.id);
      if (index === -1)
        throw new Error('MessageBarStore: No message bar found for update');
      const items = [...state.items];
      items.splice(index, 1, params);
      return { ...state, items };
    }),
  ],
  getters: [
    ...listStore.feature.getters,
    select(getters.container, ({ state, params }) => {
      return state.items.filter((o) => o.container === params);
    }),
  ],
  actions: [
    // tslint:disable-next-line:no-shadowed-variable
    on(actions.open, ({ store, state, featureName, params, injector }) => {
      params = {
        ...params,
        id: params.id ?? injector.get(SOFTLINE_SERVICE_UUID)(),
      };
      store.commit(featureName, mutations.add, params);
      if ((params.duration ?? 0) < Number.POSITIVE_INFINITY) {
        setTimeout(() => {
          if (store.get(featureName, getters.items).indexOf(params) > -1)
            store.dispatch(featureName, actions.close, params.id);
        }, params.duration ?? injector.get(SOFTLINE_CONFIG_MESSAGE_BAR_DURATION));
      }
      return params.id;
    }),
    on(actions.close, ({ store, state, featureName, params }) => {
      const el = state.items.find((o) => o.id === params);
      if (!isDefined(el))
        throw new Error(`MessageBar with id '${params}' fot found in store`);
      store.commit(featureName, mutations.remove, el);
    }),
    on(actions.closeContainer, ({ store, state, featureName, params }) => {
      const el = state.items.filter((o) => o.container === params);
      store.commit(featureName, mutations.removeMany, el);
    }),
    on(actions.closeAll, ({ store, state, featureName, params }) => {
      const el = state.items.find((o) => o.id === params);
      store.commit(featureName, mutations.clear);
    }),
    on(actions.success, async ({ store, params, injector, featureName }) => {
      const config = messageFactory(
        params,
        '#UI_CORE.MESSAGE_BAR.SUCCESS_TITLE',
        'fa-regular fa-circle-check',
        'soft-message-bar-success'
      );
      return await store.dispatch(featureName, actions.open, config, injector);
    }),
    on(actions.warning, async ({ store, params, injector, featureName }) => {
      const config = messageFactory(
        params,
        '#UI_CORE.MESSAGE_BAR.WARNING_TITLE',
        'fa-regular fa-circle-exclamation',
        'soft-message-bar-warning'
      );
      return await store.dispatch(featureName, actions.open, config, injector);
    }),
    on(actions.error, async ({ store, params, injector, featureName }) => {
      const config = messageFactory(
        params,
        '#UI_CORE.MESSAGE_BAR.ERROR_TITLE',
        'fa-regular fa-circle-xmark',
        'soft-message-bar-error'
      );
      return await store.dispatch(featureName, actions.open, config, injector);
    }),
    on(actions.info, async ({ store, params, injector, featureName }) => {
      const config = messageFactory(
        params,
        '#UI_CORE.MESSAGE_BAR.INFO_TITLE',
        'fa-regular fa-circle-info',
        'soft-message-bar-info'
      );
      return await store.dispatch(featureName, actions.open, config, injector);
    }),
    on(actions.loading, async ({ store, params, injector, featureName }) => {
      if (typeof params === 'string') params = { message: params };

      const config = {
        title: '#UI_CORE.MESSAGE_BAR.LOADING',
        message: params?.message,
        params: params?.params,
        class: 'soft-message-bar-loading',
        icon: 'fa-regular fa-spinner fa-spin',
        duration: Number.POSITIVE_INFINITY,
        dismiss: {
          canDismiss: !!params?.onDismiss,
        },
        onDismiss: params?.onDismiss,
      };

      return await store.dispatch(featureName, actions.open, config, injector);
    }),
    on(
      actions.progress,
      ({ store, state, commit, get, params, injector, featureName }) => {
        const id = injector.get(SOFTLINE_SERVICE_UUID)();
        params.subscribe(async (o) => {
          if (typeof o === 'string') o = { message: o };

          const config = {
            id,
            title: '#UI_CORE.MESSAGE_BAR.SAVING',
            message: o?.message,
            params: o?.params,
            class:
              'soft-message-bar-progress progress-' +
              Math.round((o?.progress ?? 0) * 100),
            icon: 'fa-regular fa-spinner fa-spin',
            duration: Number.POSITIVE_INFINITY,
            dismiss: {
              canDismiss: !!o?.onDismiss,
            },
            onDismiss: o?.onDismiss,
          };
          const el = get<State>(featureName).items?.find((p) => p.id === id);
          if (el) store.commit(featureName, mutations.update, config);
          else
            await store.dispatch(featureName, actions.open, config, injector);
        });

        return id;
      }
    ),
  ],
};

function messageFactory(
  params: MessageBarFactoryParams | string,
  title: string,
  icon: string,
  cssClass: string
): MessageBarConfig {
  if (typeof params === 'string') params = { message: params };

  return {
    title: params?.title ?? title,
    message: params.message,
    params: params.params,
    icon,
    class: cssClass,
    duration: params?.duration,
    container: params?.container,
    group: params?.group,
    actions: params?.actions,
    dismiss: {
      canDismiss: true,
    },
    onDismiss: params?.onDismiss,
  };
}
