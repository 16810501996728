import { Pipe, PipeTransform } from '@angular/core';
import { deserializeHtmlCharacterEntitiesFunction } from '../../functions/html-character-entities.function';

@Pipe({
  name: 'deserializeHtmlCharacterEntities',
  pure: true,
})
export class DeserializeHtmlCharacterEntitiesPipe implements PipeTransform {
  transform(value: string | null | undefined): unknown {
    if (!value) return value;

    return deserializeHtmlCharacterEntitiesFunction(value);
  }
}
