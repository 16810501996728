import { Pipe, PipeTransform, QueryList } from '@angular/core';
import { equals } from "@softline/core";

@Pipe({
  name: 'distinct',
  standalone: true,
  pure: true
})
export class DistinctPipe implements PipeTransform {
  transform<T, U>(
    array: readonly T[] | null | undefined
  ): T[] {
    if (!array || !Array.isArray(array))
      return [];

    const returnValue = array.filter(
      (o, i, a) => a.findIndex(p => equals(o, p)) === i
    );
    return returnValue;
  }
}
