import {
  AfterContentInit,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[softClickOutside]',
})
export class ClickOutsideDirective implements AfterContentInit {
  private wasInside = false;
  private contentCreated = false;

  @Output('softClickOutside') clickOutside = new EventEmitter();
  @Input() clickOutsideEvents: string[] = ['click'];
  @Input() delayClickOutsideInit: boolean = false;

  @HostListener('click', ['$event'])
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('mousedown', ['$event'])
  @HostListener('mouseup', ['$event'])
  onElementClick(event: Event): void {
    if (!this.shouldHandle(event.type)) return;
    this.wasInside = true;
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:touchend', ['$event'])
  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:mouseup', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.shouldHandle(event.type)) return;
    if (!this.wasInside) this.clickOutside.emit();
    this.wasInside = false;
  }

  constructor() {}

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.contentCreated = true;
    }, 100);
  }

  private shouldHandle(type: string): boolean {
    return (
      (!this.delayClickOutsideInit || this.contentCreated) &&
      this.clickOutsideEvents.indexOf(type) > -1
    );
  }
}
