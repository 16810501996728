import { Component, computed, contentChildren, EventEmitter, input, Input, OnInit, Output } from "@angular/core";
import { SplitButtonOptionDirective } from "./split-button-option.directive";
import { Store } from "@softline/core";
import * as ModalStore from "../../modal/modal.store";
import { SOFTLINE_FEATURE_MODAL } from "../../modal/modal.shared";
import { boolean } from "mathjs";

@Component({
  selector: 'soft-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
})
export class SplitButtonComponent implements OnInit {

  type = input<string>('primary');
  disabled = input<boolean>(false);

  @Output() action = new EventEmitter<Event>();

  options = contentChildren(SplitButtonOptionDirective);
  splitDisabled = computed(() => {
    this.options().every(o => o.disabled);
  })
  constructor(private store: Store) {}

  ngOnInit(): void {}

  async onShowOptions(): Promise<void> {
    const action = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.choose<EventEmitter<any>>(), {
      title: '#UI_CORE.COMPONENTS.SPLIT_BUTTON.TITLE',
      options: this.options().map(o => o) ?? [],
      dismiss: true
    });
    if(action === 'DISMISSED')
      return;
    action.emit();
  }
}
