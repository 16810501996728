import { IndexedDBConfig } from '@softline/core';

export const SOFTLINE_INDEXED_DB_CONTEXT: IndexedDBConfig = {
  version: 1,
  name: 'context',
  objectStoreConfigs: [
    {
      name: 'claims',
      parameters: { keyPath: 'username', autoIncrement: false },
      schema: [],
    },
  ],
};
