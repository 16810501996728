import {
  createAction,
  createGetter,
  CustomStoreAddOn,
  on,
  RemoteCollectionStore,
  select,
  StoreBuilder
} from "@softline/core";
import {Bewe, WarenuebernahmeBeweEtikett} from "../types/bewegung";
import {Emballage} from "../types/warenuebernahme";
import {BewegungenService} from "../services/bewegungen-service";
import {lastValueFrom} from "rxjs";

type State = RemoteCollectionStore.State<Bewe>

const remoteCollectionStore = RemoteCollectionStore.create<Bewe>();

const mutations = {}

const getters = {
  bewegungenMap: createGetter<
    State,
    {
      offeneBewegungen: Bewe[],
      erledigteBewegungen: Bewe[],
      alleBewegungen: Bewe[],
    }>('wwsWarenuebernahmeBewegung/getters/bewegungenMap'),
}

const actions = {
  positionErledigen: createAction<State, WarenuebernahmeBeweEtikett, void>('wwsWarenuebernahmeBewegung/actions/positionErledigen'),
  positionOeffnen: createAction<State, Bewe, void>('wwsWarenuebernahmeBewegung/actions/positionOeffnen'),
  emballageHinzufuegen: createAction<State, {emballage: Emballage, idwu: string}, void>('wwsWarenuebernahmeBewegung/actions/emballageHinzufuegen')
}

const WwsWarenuebernahmeBewegungStoreExtension: CustomStoreAddOn<State, typeof mutations, typeof getters, typeof actions> = {
  mutations,
  getters,
  actions,
  feature: {
    getters: [
      select(getters.bewegungenMap, ({state}) => {
        const entities = state.ids.map(id => state.entities[id] as Bewe);
        const offeneBewegungen: Bewe[] = [];
        const erledigteBewegungen: Bewe[] = [];

        entities.forEach(entity => {
          if (entity.status === 'O')
            offeneBewegungen.push(entity);
          else
            erledigteBewegungen.push(entity);
        });

        const bewes = {
          offeneBewegungen: offeneBewegungen,
          erledigteBewegungen: erledigteBewegungen,
          alleBewegungen: entities
        };

        return bewes;
      })
    ],
    actions: [
      on(actions.positionErledigen, async ({ params, featureName, injector, commit }) => {
        const service = injector.get(BewegungenService);

        const result = await lastValueFrom(service.positionErledigen(params));

        console.log('ID RESULT: ', result);

        commit(featureName, remoteCollectionStore.mutations.addOrUpdate, result);
      }),
      on(actions.positionOeffnen, async ({ params, featureName, injector, commit }) => {
        const service = injector.get(BewegungenService);

        const result = await lastValueFrom(service.positionOeffnen(params));

        commit(featureName, remoteCollectionStore.mutations.addOrUpdate, result);
      }),
      on(actions.emballageHinzufuegen, async ({ params, featureName, injector, commit }) => {
        const service = injector.get(BewegungenService);

        const result = await lastValueFrom(service.emballageHinzufuegen(params.emballage, params.idwu));

        commit(featureName, remoteCollectionStore.mutations.addOrUpdate, result);
      })
    ],
    mutations: []
  },
}

export const WwsBewegungStore = new StoreBuilder(remoteCollectionStore)
  .add(WwsWarenuebernahmeBewegungStoreExtension)
  .value;
