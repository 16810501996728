import {
  CollectionStore2,
  ConnectionHttpService,
  ReadonlyHttpApiCollectionRepository,
  ReadonlyRepositoryCollectionStore2,
  SOFTLINE_COLLECTION_REPOSITORY,
  Store2Builder,
  SubscriptionStore2
} from '@softline/core';
import { Belegart } from '../types/belegart.model';
import { InjectionToken } from '@angular/core';
import { SOFTLINE_API_BELEGARTEN } from '../gesamtabfrage.api';
import { SelectStore2 } from '../../../../../../framework/core/src/lib/store2/specialized/select/select.store2';

const belegartStore = new Store2Builder('belegart')
  .withProvider({
    provide: SOFTLINE_COLLECTION_REPOSITORY,
    useFactory: (service: ConnectionHttpService) =>
      new ReadonlyHttpApiCollectionRepository(service, SOFTLINE_API_BELEGARTEN),
    deps: [ConnectionHttpService]
  })
  .withSubFeature('collection', CollectionStore2<Belegart>)
  .withSubFeature('subscription', SubscriptionStore2)
  .withSubFeature('remote', ReadonlyRepositoryCollectionStore2<Belegart>)
  .withSubFeature('select', SelectStore2<Belegart>)

export const BelegartStore = new InjectionToken(
  'BelegartStore',
  {providedIn: 'root', factory: () => belegartStore.build()}
)
