import { runInZone } from '@softline/core';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { LocalizationFile } from '../data/localization-file';
import { SOFTLINE_CONFIG_LOCALIZATION_BASE_PATH } from '../l10n.shared';
import { map } from 'rxjs/operators';
import { LocalizationLoader } from './localization-loader';

@Injectable()
export class HttpLocalizationLoader extends LocalizationLoader {
  constructor(
    private httpClient: HttpClient,
    @Inject(SOFTLINE_CONFIG_LOCALIZATION_BASE_PATH) private basePath: string,
    private zone: NgZone
  ) {
    super();
  }

  load(locale: string, module?: string): Promise<LocalizationFile> {
    const path = this.getPath(locale, module);
    return lastValueFrom(
      this.httpClient.get<object>(path).pipe(
        map((result) => ({ locale, formats: result, module })),
        runInZone(this.zone)
      )
    );
  }

  private getPath(locale: string, module?: string): string {
    let path = this.basePath;
    if (module) path += `/modules/${module}`;
    path += `/l10n/${locale}.json`;
    return path;
  }
}
