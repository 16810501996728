import { Store } from "@softline/core";
import { Router } from "@angular/router";
import { CloudMessagingPermission } from "../cloud-messaging.shared";

export abstract class CloudMessagingService {

  constructor(
    protected store: Store,
    protected oneSignalAppId: string,
    protected router: Router
  ) { }

  abstract init(): Promise<CloudMessagingPermission>;

  abstract subscribe(): Promise<CloudMessagingPermission>;

  abstract unsubscribe(): Promise<boolean>;


  abstract getTags(): Promise<object>;

  abstract setTag(tag: string, value: string): Promise<void>;
}
