import {
  ObjectStore,
  ParamsStore,
  KeyStore,
  SubscriptionStore,
  ActionStore,
  StoreBuilder,
  createMutation,
  createGetter,
  createAction,
  Dictionary,
  mutate, on, SOFTLINE_SERVICE_UUID, select,
} from '@softline/core';
import {SOFTLINE_STORE_HTTP_OBJECT_SERVICE} from '../milchgeld.shared';

export namespace HttpRemoteListStore {

  export interface LoadActionParameters {
    queryParams?: Dictionary<unknown>;
    payload?: string;
    token?: string;
  }

  export interface State<T extends object>{
    data: T[];
    sum: Partial<T> | null;
  }

  export class Store<T extends object> {
    mutations = {
      set: createMutation<State<T>, { result: T[], sum?: Partial<T> }>('set')
    };
    actions = {
      load: createAction<State<T>, LoadActionParameters, T[]>('load')
    };
    getters = {
      loading: createGetter<State<T>, boolean>('loading'),
      data: createGetter<State<T>, T[]>('data'),
      sum: createGetter<State<T>, Partial<T>>('sum')
    };
    feature = {
      initialState: {
        sum: null,
        data: []
      },
      mutations: [
        mutate(this.mutations.set, ({state, params}) => ({...state, data: params.result, sum: (params.sum ?? null)}))
      ],
      actions: [
        on(this.actions.load, async ({featureName, commit, injector, params}) => {
          const service = injector.get(SOFTLINE_STORE_HTTP_OBJECT_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const subscriptionState$ = SubscriptionStore.handleSubscriptionState(service.get<T>(params.queryParams), featureName, commit, token);
          const result = await ActionStore.handleObservableActionState(subscriptionState$, featureName, commit, this.actions.load.name, token).toPromise();
          commit(featureName, this.mutations.set, result);
          return result?.result;
        })
      ],
      getters: [
        select(this.getters.loading, ({get, featureName, params}) => {
          const actions = [this.actions.load.name];
          const states: ActionStore.ActionState[] = ['pending', 'processing'];
          return get(featureName, ActionStore.getters.hasState, {actions, states, id: params});
        }),
        select(this.getters.data, ({state}) => state.data),
        select(this.getters.sum, ({state}) => state.sum)
      ]
    };
  }

  export function create<T extends object>(){
    return new StoreBuilder(new Store<T>())
      .add(ParamsStore)
      .add(KeyStore)
      .add(SubscriptionStore)
      .add(ActionStore)
      .value;
  }
}
