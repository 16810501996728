<div class="soft-loading-spinner">
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
  <div class="soft-loading-spinner-part"></div>
</div>
