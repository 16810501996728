import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { LanguageStore2, Store } from '@softline/core';
import { SOFTLINE_FEATURE_APPLICATIONS, SOFTLINE_FEATURE_SETTINGS } from "../../../application.shared";
import { combineLatestWith, Subscription } from "rxjs";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import * as SettingsStore from "../../../settings/settings.store";
import { ApplicationStore } from "../../application.store";
import { UiCoreModule } from "@softline/ui-core";
import { SOFTLINE_SETTINGS_APPLICATION } from "../../application.shared";
import { ApplicationSettings } from "../../data/application-settings";
import { AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION } from "@softline/auth";
import { map } from "rxjs/operators";
import { CommonModule } from "@angular/common";

@Component({
  selector: "lib-application-settings",
  standalone: true,
  templateUrl: "./application-settings.component.html",
  styleUrls: ["./application-settings.component.scss"],
  imports: [
    CommonModule,
    UiCoreModule,
    ReactiveFormsModule
  ]
})
export class ApplicationSettingsComponent implements OnInit {

  private subscription?: Subscription;
  form = new FormGroup({
    startup: new FormControl<string | null | undefined>(null),
  });

  apps$ = this.store.observe(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.getters.items)
    .pipe(
      combineLatestWith(this.store.observe(SOFTLINE_FEATURE_AUTHORIZATION, AuthorizationStore.getters.permissions)),
      map(([apps, permissions]) => apps.filter(o =>  !o.permission || permissions.includes(o.permission)))
    );

  languageStore = inject(LanguageStore2)

  constructor(private store: Store, private cdRef: ChangeDetectorRef) {}

 async ngOnInit(): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_AUTHORIZATION, AuthorizationStore.actions.loadOnce);
    const value = this.store
     .get(
       SOFTLINE_FEATURE_SETTINGS,
       SettingsStore.getters.values<Partial<ApplicationSettings>>(),
       SOFTLINE_SETTINGS_APPLICATION
     )
   this.form.patchValue(value ?? {}, { emitEvent: false });
    this.cdRef.detectChanges();
    this.subscription = this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<Partial<ApplicationSettings>>(),
        SOFTLINE_SETTINGS_APPLICATION
      )
      .subscribe((o) => {
        this.form.patchValue(o ?? {}, { emitEvent: false });
      });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  async set(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.mutations.setKey(),
      { key: SOFTLINE_SETTINGS_APPLICATION, values: this.form.value }
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.actions.save
    );
  }
}
