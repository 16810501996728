import { Component } from '@angular/core';
import { SubmitDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
  selector: 'soft-dynamic-submit-input',
  templateUrl: './submit-input.component.html',
  styleUrls: ['./submit-input.component.scss'],
})
export class SubmitInputComponent extends DynamicInputAtom<SubmitDefinition> {
  constructor() {
    super();
  }
}
