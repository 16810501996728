import {Routes} from '@angular/router';
import {importProvidersFrom,} from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE, SOFTLINE_STORE_QUERY_SERVICE,
  SoftlineEntityService, SoftlineQueryService,
  StoreModule
} from "@softline/core";
import {
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEGART,
  SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
} from './gesamtabfrage.shared';
import {
  SOFTLINE_API_GESCHAEFTSFALL_QUERY,
  SOFTLINE_API_GESAMTAFRAGE_QUERY,
  SOFTLINE_API_BELEGARTEN
} from "./gesamtabfrage.api";
import {SOFTLINE_PERMISSION_GESAMTABFRAGE} from './gesamtabfrage.permissions';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {BelegStore} from './store/beleg.store';
import {BelegartStore} from './store';
import {GeschaeftsfallStore} from './store/geschaeftsfall.store';

export const gesamtabfrageRoutes: Routes = [
  {
    path: 'gesamtabfrage',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/gesamtabfrage.component').then(
            (o) => o.GesamtabfrageComponent
          ),
        children: [
          {
            path: ':type/find',
            loadComponent: () =>
              import(
                './pages/gesamtabfrage-query/gesamtabfrage-query.component'
              ).then((o) => o.GesamtabfrageQueryComponent),
          },
          {
            path: ':type/list',
            loadComponent: () =>
              import(
                './pages/gesamtabfrage-list/gesamtabfrage-list.component'
              ).then((o) => o.GesamtabfrageListComponent),
          },
          {
            path: ':type/business-case/:id',
            loadComponent: () =>
              import('./pages/business-case/business-case.component').then(
                (o) => o.BusinessCaseComponent
              ),
          },
          {
            path: '',
            loadComponent: () =>
              import('./pages/belegarten/belegarten.component').then(
                (o) => o.BelegartenComponent
              ),
          },
          { path: '**', redirectTo: '' },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard],
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    data: { permission: SOFTLINE_PERMISSION_GESAMTABFRAGE },
    providers: [
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
          feature: BelegStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_QUERY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineQueryService(service, SOFTLINE_API_GESAMTAFRAGE_QUERY),
              deps: [SOFTLINE_SERVICE_HTTP]
            }
          ],
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL,
          feature: GeschaeftsfallStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_QUERY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineQueryService(service, SOFTLINE_API_GESCHAEFTSFALL_QUERY),
              deps: [SOFTLINE_SERVICE_HTTP]
            }
          ],
        })
      ),
    ],
  },
];
