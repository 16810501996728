import { Belegart } from "../../types/belegart.model";
import { ConnectionResourceLocation } from "@softline/core";
import { Beleg } from "../../types/beleg.model";

export interface GesamtabfrageDownloadFile {
  filename: string,
  location: ConnectionResourceLocation,
  body: object
}

export interface GesamtabfrageDownloadStrategy {
  type: string;
  icon: string;
  label: string;

  getDownloadFiles(belege: readonly Beleg[], belegart: Belegart): Promise<GesamtabfrageDownloadFile[]>;
}
