import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {SOFTLINE_PERMISSION_SEND_NOTIFICATION} from './send-notification.shared';

export const sendNotificationRoutes: Routes = [
  {
    path: 'send-notification',
    loadComponent: () => import('./pages/send-notification.component').then(c =>
      c.SendNotificationComponent
    ),
    loadChildren: () => [
      {
        path: '',
        loadComponent: () => import('./pages/empfaenger-auswahl/empfaenger-auswahl.component').then(c =>
          c.EmpfaengerAuswahlComponent
        ),
      },
      {
        path: 'nachricht-verfassen',
        loadComponent: () => import('./pages/nachricht-verfassen/nachricht-verfassen.component').then(
          c => c.NachrichtVerfassenComponent
        ),
      },
    ],
    canActivate: [AuthorizationGuard, AuthenticationGuard],
    data: { permission: SOFTLINE_PERMISSION_SEND_NOTIFICATION }
  }
];
