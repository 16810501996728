<div
  class="c column"
  *ngIf="definition"
  [ngSwitch]="definition.control"
>
  <label class="soft-label top">
    {{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-check-group
    *ngSwitchCase="'checkboxes'"
    class="c center-v"
    [ngClass]="definition.direction === 'horizontal' ? ['row'] : ['column']"
    [formControl]="form|formControl:definition.name"
    [readonly]="definition.readonly | booleanRule: form.value"
    [checkStrategy]="strategy"

    softFieldValidation
  >
    <soft-checkbox
      *ngFor="let option of definition.options; let i = index"
      [class.collapse]="
        displayAll === false &&
        definition.maxDisplayed &&
        i >= definition.maxDisplayed
      "
      [ngClass]="definition.direction === 'horizontal' ? ['mr-4'] : ['mt-2']"
      [trueValue]="option.value"
      [falseValue]="undefined"
    >
      <span class="soft-label right">{{ option.title }}</span>
    </soft-checkbox>
    <ng-container
      *ngTemplateOutlet="
        displayMoreTemplate;
        context: { $implicit: definition.options.length }
      "
    ></ng-container>
  </soft-check-group>
  <div
    *ngSwitchCase="'dialog'"
    class="c row space-between center-v soft-input no-wrap"
  >
    <div>
      <span
        *ngIf="!form.value[definition.name]?.length"
        class="placeholder mr-2"
        >{{ '#DYNAMIC.MULTI_SELECT.PLACEHOLDER' | translate }}</span
      >
      <soft-chip-group>
        <soft-chip
          *ngFor="let value of form.value[definition.name] || []; let i = index"
          class="mr-2 mt-1 mb-1"
          [class.collapse]="
            displayAll === false &&
            definition.maxDisplayed &&
            i >= definition.maxDisplayed
          "
          [canDelete]="!(definition.readonly | booleanRule: form.value)"
          (delete)="onDelete(form.value[definition.name], i)"
        >
          {{ (definition.options | first: findOption:value)?.title }}
        </soft-chip>
      </soft-chip-group>
      <ng-container
        *ngTemplateOutlet="
          displayMoreTemplate;
          context: { $implicit: form.value[definition.name]?.length }
        "
      ></ng-container>
    </div>

    <div class="height-100">
      <soft-icon
        name='fa-regular fa-plus pointer'
        (click)="openDialog(form.value[definition.name])"
      ></soft-icon>
    </div>
  </div>
  <div *ngSwitchCase="'dropdown'" class="c row">
    <soft-multi-select
      [formControl]="form|formControl:definition.name"
      [maxDisplayed]="definition.maxDisplayed ?? 1000"
      [readonly]="definition.readonly | booleanRule: form.value"
      placeholder="{{ '#DYNAMIC.MULTI_SELECT.PLACEHOLDER' | translate }}"
      softFieldValidation
    >
      <soft-select-option
        *ngFor="let option of definition.options"
        [value]="option.value"
        >{{ option.title }}</soft-select-option
      >
    </soft-multi-select>
  </div>
</div>
<ng-template #displayMoreTemplate let-length>
  <ng-container
    *ngIf="
      displayAll === false &&
      definition.maxDisplayed &&
      definition.maxDisplayed < length
    "
  >
    <span class="soft-link small fluid no-wrap" (click)="displayAll = true">
      {{
        (length - definition.maxDisplayed === 1
          ? '#DYNAMIC.MULTI_SELECT.DISPLAY_MORE_SG'
          : '#DYNAMIC.MULTI_SELECT.DISPLAY_MORE_PL'
        )
          | translate
          | interpolate: { count: length - definition.maxDisplayed }
      }}
    </span>
  </ng-container>
  <span
    *ngIf="
      displayAll === true &&
      definition.maxDisplayed &&
      definition.maxDisplayed < length
    "
    class="soft-link small fluid no-wrap"
    (click)="displayAll = false"
    >{{ '#DYNAMIC.MULTI_SELECT.DISPLAY_LESS' | translate }}</span
  >
</ng-template>
