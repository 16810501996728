
<div class="flex flex-col soft-card rounded min-h-[30vh] h-full">
    <div class="flex items-center justify-between">
        <div class="flex flex-col gap-1">
            <h2 class="font-semibold text-xl">Kreditlimit</h2>
            <p class="flex font-normal text-sm text-light">{{ kreditlimitUeberschritten() ? 'Limit überschritten' : 'Im Rahmen'}}</p>
        </div>
        <span class="p-2 flex items-center justify-center rounded-full h-10 w-10" [ngClass]="kreditlimitUeberschritten() ? 'badge-credit-card-error' : 'badge-credit-card'">
            <i class="far fa-credit-card" [ngClass]="kreditlimitUeberschritten() ? 'text-danger-700' : 'text-accent-800'"></i>
        </span>
    </div>

    @if (kreditdaten$ | async; as data) {
        <div class="flex items-end justify-between mt-8 gap-1">
            <p class="flex font-bold text-2xl "
               [ngClass]="[data.saldoGesamt > data.kreditlimit2 ? 'text-danger-600' : 'text-success-700']">{{ (data.saldoGesamt) | currency:'EUR' }}</p>
            <span class="text-sm text-light">{{ data.kreditlimit2 | currency:'EUR' }}</span>
        </div>

        <div class="flex flex-col mt-2">
            <div
              class="flex w-full rounded-full border border-solid border-surface h-4 limit-gradient overflow-hidden whitespace-nowrap"
              [ngClass]="[data.saldoGesamt > data.kreditlimit2 ? 'limit-gradient' : 'limit-gradient-success']"
              *ngIf="gesamtKreditlimitTranslateX$ | async as offset">
                <!--<div [@animateIn2]="{ value: '*', params: { offset: offset } }" class="bg-gray-200 w-full h-full will-change-transform" [style.transform]="offset">&nbsp;</div>-->
                <div [@animateIn2]="{ value: '*', params: { offset: offset } }" class="bg-surface-tertiary w-full h-full will-change-transform" [style.transform]="offset">&nbsp;</div>
            </div>
            <span class="text-light mt-2 font-normal italic">{{ (data.saldoGesamt > data.kreditlimit2 ? (data.kreditlimit2 - data.saldoGesamt) * -1 : (data.kreditlimit2 - data.saldoGesamt)) | currency:'EUR' }} {{ data.saldoGesamt > data.kreditlimit2 ? 'überzogen' : 'verfügbar' }}</span>
        </div>
        <!--
        <div class="flex flex-col mt-3" #containerRef>
            <ngx-charts-pie-grid
            [view]="[containerRef.offsetWidth, 400]"
            [results]="chartData$ | async"
            (select)="onSelect($event)">
          </ngx-charts-pie-grid>
        </div> -->
    } @else {
        Laden...
    }
</div>
