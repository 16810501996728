import {
  Dictionary,
  ConnectionResourceLocation,
  isDefined,
} from '@softline/core';

export interface Archive {
  key: string;
}

export interface ArchiveFile {
  archiveKey: string;
  fileKey: string;
  metaData: ArchiveContentMetadata;
}

export interface ArchiveFileDownload extends ArchiveFile {
  url: string;
}

export interface ArchiveContentMetadata {
  id: string;
  name: string;
  type: string;
  lastModified: string;
  size: number;
  summary: string;
  title: string;
}

export interface ArchiveUpload {
  archiveKey: string | ConnectionResourceLocation;
  files: File[];
  fields?: Dictionary<any>;
  token?: string;
}

export function isArchiveFile(value: unknown): value is ArchiveFile {
  return (
    isDefined((value as ArchiveFile)?.archiveKey) &&
    isDefined((value as ArchiveFile)?.fileKey) &&
    isDefined((value as ArchiveFile)?.metaData)
  );
}
