<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item['lager']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item['name1']}}</span>
  </div>
  <div *ngIf="item['name2']" class="flex flex-row">
    <span>{{item['name2']}}</span>
  </div>
  <div *ngIf="item['name3']" class="flex flex-row">
    <span>{{item['name3']}}</span>
  </div>
</div>
