import {Injectable} from '@angular/core';
import {timeout} from 'rxjs';

@Injectable()
export class AudioService {

  private active: string | null = null;
  private queue: string[] = [];
  private cache: Map<string, HTMLAudioElement> = new Map();

  play(name: string): void {
    this.queue.push(name);
    if(this.active)
      return;
    this.playQueue();
  }

  private async playQueue(): Promise<void> {
    while (this.queue.length > 0) {
      [this.active] = this.queue.splice(0,1);
      await this.playSound(this.active);
      this.active = null;
    }
  }

  private async playSound(name: string): Promise<void> {
    if(!this.cache.has(name)) {
      const fileSrc = `assets/audio/${name}.mp3`
      const audio = new Audio(fileSrc);
      audio.autoplay = false;
      this.cache.set(name, audio);
    }
    const audio = this.cache.get(name);
    if(!audio)
      return;

    audio.currentTime = 0;
    try {
      await audio.play();
      await new Promise<void>((resolve) => setTimeout(() => resolve(), audio.duration * 1000));
    } catch (e) {
      console.log('could not play audio due to: ', e)
    }
  }

}
