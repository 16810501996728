import { AbstractControl, FormGroup, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { DateParser, Validators } from '@softline/ui-core';
import { inject } from '@angular/core';
import { MetaControl, MetaForm } from './model/meta-form';
import { Dictionary, isDefined } from '@softline/core';

export class MetaFormHelper {

  static createValidators<T>(meta: MetaForm<T>): Dictionary<ValidatorFn[]> {
    const returnValue: Dictionary<ValidatorFn[]> = {};
    for (const [key, control] of Object.entries(meta)) {
      const validators: ValidatorFn[] = [];
      const controlMeta = control as MetaControl<any>;
      if (controlMeta?.required)
        validators.push(Validators.required());
      if (controlMeta?.type === 'number') {
        if (isDefined(controlMeta.minimum))
          validators.push(Validators.min(controlMeta.minimum));
        if (isDefined(controlMeta.maximum))
          validators.push(Validators.max(controlMeta.maximum));
      }
      if (controlMeta?.type === 'string') {
        if (isDefined(controlMeta.minLength))
          validators.push(Validators.minLength(controlMeta.minLength));
        if (isDefined(controlMeta.maxLength))
          validators.push(Validators.maxLength(controlMeta.maxLength));
      }
      if(validators.length > 0) {
        returnValue[key] = validators;
      }
    }
    return returnValue;
  }

  static updateMeta<T>(formGroup: FormGroup<any>, meta: MetaForm<T>): FormGroup<{ [K in keyof T]: AbstractControl }> {
    for (const [key, controlMeta] of Object.entries(meta)) {
      if(formGroup.contains(key))
          formGroup.removeControl(key)
      if(controlMeta)
        formGroup.addControl(key, this.createControl(controlMeta as MetaControl<any>));
    }
    return formGroup;
  }

  static createControl<T extends MetaControl<T>>(property: MetaControl<T>): UntypedFormControl {
    let updateOn: 'blur' | 'change' | 'submit' = 'blur';
    let defaultValue: unknown = property.default ?? null;

    const validators: ValidatorFn[] = [];
    if (property.required)
      validators.push(Validators.required());

    switch (property.type) {
      case 'number':
        if(isDefined(property.minimum))
          validators.push(Validators.min(property.minimum))
        if(isDefined(property.maximum))
          validators.push(Validators.max(property.maximum))
        break;
      case 'select':
        updateOn = 'change';
        break;
      case 'boolean':
        updateOn = 'change';
        defaultValue = defaultValue ?? false;
        break;
      case 'date':
        updateOn = 'change';
        if(typeof defaultValue === 'string' && defaultValue !== "") {
          const dateParser: DateParser<string> = inject(DateParser);
          defaultValue = dateParser.parse(defaultValue)
        }
        break;
      case 'string':
        updateOn = 'blur';
        if(isDefined(property.maxLength))
          validators.push(Validators.maxLength(property.maxLength))
        if(isDefined(property.minLength))
          validators.push(Validators.minLength(property.minLength))
        property.minLength
        break;
    }

    const control = new UntypedFormControl(defaultValue, {
      updateOn,
      validators,
    });

    return control;
  }

}
