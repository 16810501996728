import {
  ENVIRONMENT_INITIALIZER,
  ImportedNgModuleProviders,
  importProvidersFrom,
  inject,
  StaticProvider
} from "@angular/core";
import { CloudMessagingService } from "@softline/application";
import { BrowserCloudMessagingService } from "./services/browser-cloud-messaging.service";
import { OneSignal, OnesignalNgxModule } from "onesignal-ngx";

export const browserCloudMessagingProviders: (StaticProvider | ImportedNgModuleProviders)[] = [
  importProvidersFrom(
    OnesignalNgxModule,
  ),
  {provide: BrowserCloudMessagingService, useClass: BrowserCloudMessagingService},
  {provide: CloudMessagingService, useExisting: BrowserCloudMessagingService},
]
