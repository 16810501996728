import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {CurrencyFormatPipe, UiCoreModule, UiCorePipesModule} from '@softline/ui-core';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {DiagramTabelleViewComponent} from '../../components/diagram-tabelle-view/diagram-tabelle-view.component';
import {SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_KREDITOREN} from '../../offene-posten.shared';
import {JopostenStore} from '../../store';
import {handleRequestErrors} from '@softline/application';
import {Store} from '@softline/core';

@Component({
  selector: 'soft-offene-posten-debitoren-widget',
  standalone: true,
  imports: [CommonModule, UiCoreModule, NgxChartsModule, UiCorePipesModule, DiagramTabelleViewComponent],
  templateUrl: './offene-posten-kreditoren.widget.html',
  styleUrl: './offene-posten-kreditoren.widget.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyFormatPipe, DatePipe]
})
export class OffenePostenKreditorenWidgetComponent implements OnInit, OnDestroy {

  private store = inject(Store);

  readonly loading = signal(false);

  readonly offenePosten = this.store.signal(
    SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_KREDITOREN,
    JopostenStore.getters.all
  );

  async ngOnInit(): Promise<void> {
    try {
      const queryParams = {
        kontoKategorie: "3",
        kzoffen: "J"
      }

      this.loading.set(true);

      await this.store.dispatch(
        SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_KREDITOREN,
        JopostenStore.actions.loadMany,
        {
          clear: true,
          queryParams: { query: queryParams }
        }
      );

      this.loading.set(false);
    } catch (e) {
      this.loading.set(false);
      handleRequestErrors(this.store, e);
    }
  }

  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_OFFENE_POSTEN_JOPOSTEN_KREDITOREN,
      JopostenStore.mutations.clear
    );
  }
}
