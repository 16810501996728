import { Component, HostListener, OnInit, TemplateRef } from "@angular/core";
import { Modal } from '../../data/modal';
import { QuestionResult } from '../../data/question-result';

@Component({
  selector: 'soft-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss'],
})
export class QuestionModalComponent implements Modal<QuestionResult> {
  private close!: (result: QuestionResult) => void;

  title: string | undefined;
  content?: TemplateRef<any>;
  question = '';
  showCancel = false;
  params?: object;

  registerCloseHandler(handler: (result: QuestionResult) => void): void {
    this.close = handler;
  }


  @HostListener('window:keydown.enter')
  onClick(result: QuestionResult = 'YES'): void {
    this.close(result);
  }
}
