import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'storeFunction',
  pure: true,
})
export class StoreFunctionPipe<T> implements PipeTransform {
  transform(
    func: (...args: any[]) => Observable<T>,
    ...args: any[]
  ): Observable<T> {
    return func(...args);
  }
}
