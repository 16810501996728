import {Component, ComponentRef, computed, inject, input, OnInit, TemplateRef} from "@angular/core";
import { CommonModule } from "@angular/common";
import { asapScheduler, Observable, observeOn } from "rxjs";
import { SOFTLINE_FEATURE_COMMANDS } from "../../../application.shared";
import { Command } from "../../../data/command";
import * as CommandStore from "../../../program/command.store";
import { Store } from "@softline/core";
import { NavigationExtras, Router } from "@angular/router";
import { UiCoreModule } from "@softline/ui-core";
import { BottomOutletService } from "../../../program/services/bottom-outlet.service";
import { BottomTabGroupService } from "../../../program/services/bottom-tab-group.service";
import { MenuOutletComponent } from '../../menu/components/menu-outlet/menu-outlet.component';
import {MenuItemStore2} from '../../menu/menu-item.store';

@Component({
  selector: 'soft-bottom-bar',
  standalone: true,
  imports: [CommonModule, UiCoreModule, MenuOutletComponent],
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {

  TemplateRef = TemplateRef;

  menuItemsStore = inject(MenuItemStore2);
  bottomOutletService = inject(BottomOutletService);
  bottomTabGroupService = inject(BottomTabGroupService);

  bottomCommands$: Observable<readonly Command[]> = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'bottom-menu'
    )
    .pipe(observeOn(asapScheduler));


  readonly hasBottomMenuItems = computed(() => {
    const items = this.menuItemsStore.values();
    const menuItems = items
      .flat()
      .filter((item) => (item.outlet === 'bottom' || item.outlet === 'responsive') && (item.visible ?? true))
      .sort((a, b) => (a.priority ?? Number.POSITIVE_INFINITY) - (b.priority ?? Number.POSITIVE_INFINITY));

    return menuItems.length > 0;
  });


  constructor(private store: Store,
              private router: Router) { }

  ngOnInit(): void {}

  onClick(
    execute: (() => void) | undefined,
    routerLink: any[] | string | undefined,
    canExecute: boolean,
    routerLinkParams: NavigationExtras | undefined
  ): void {
    if (!canExecute)
      return;
    if (execute)
      execute();
    else if (routerLink)
      this.router.navigate(
        typeof routerLink === 'string' ? [routerLink] : routerLink,
        routerLinkParams
      );
  }
}
