@if (data(); as data) {
<div class="flex flex-col relative">
  <svg class="donut w-full h-full z-10" viewBox="0 0 42 42">
    <!-- Middle -->
    <circle
      class="donut-hole"
      fill="var(--colorSurface)"
      cx="21"
      cy="21"
      r="15.91549430918954"
      style="pointer-events: none"
    ></circle>

    @for (segment of segments(); track segment) {
      <circle
        id="{{ 'donut-segment' + segment.strokeDasharrayOffset }}"
        cx="21"
        cy="21"
        r="15.91549430918954"
        [attr.stroke]="segment.color"
        stroke-width="4"
        fill="transparent"
        (mouseenter)="segmentHovered.emit(segment.color)"
        (mouseleave)="segmentHovered.emit('')"
        [attr.stroke-dasharray]="segment.strokeDasharray"
        [attr.stroke-dashoffset]="segment.strokeDasharrayOffset"
      ></circle>
    }
  </svg>

  <div
    class="absolute z-10 select-none text-success-700 font-semibold flex inset-0 text-xs text-center justify-center items-center"
    style="pointer-events: none"
  >
    <div #ref>
      <ng-content></ng-content>
    </div>

    @if (!ref.innerHTML.trim()) {
    {{ total() || 0 | number : '.0-2' }}
    }
  </div>
</div>
}
