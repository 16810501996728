import { Pipe, PipeTransform } from "@angular/core";
import { AbteilungUebersicht } from "../types/abteilung";

@Pipe({
  name: 'anzahlMitarbeiterGesamt',
  standalone: true,
  pure: true
})
export class AnzahlMitarbeiterGesamtPipe implements PipeTransform {
  transform(mitarbeiter: AbteilungUebersicht):number {
    if (mitarbeiter.status?.unbekannt && mitarbeiter.status?.unbekannt > 0)
      return mitarbeiter.status?.unbekannt

    return ((mitarbeiter.status?.anwesend || 0) + (mitarbeiter.status?.abwesendgelb || 0) + (mitarbeiter.status?.abwesendrot || 0));
  }
}
