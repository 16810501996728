import { createGetter, Dictionary, RemoteObjectStore, select, StoreBuilder } from "@softline/core";
import { RemoteConfig, RemoteConfigProperty } from "./remote-config";

export type RemoteConfigState = RemoteObjectStore.State<RemoteConfig>;

export class RemoteConfigStoreExtension {
  static mutations = { }
  static actions = { }
  static getters = {
    common: createGetter<RemoteConfigState, RemoteConfigProperty[]>('common'),
    module: createGetter<RemoteConfigState, RemoteConfigProperty[], string>('module'),
    property: createGetter<RemoteConfigState, string | undefined, string | {key: string, module: string}>('property'),
    commonProperties: createGetter<RemoteConfigState, Dictionary<string>>('commonProperties'),
    moduleProperties: createGetter<RemoteConfigState, Dictionary<string>, string>('moduleProperties'),
  }
  static feature = {
    mutations: [],
    actions: [],
    getters: [
      select(RemoteConfigStoreExtension.getters.common,
        ({ state }) => state.data?.properties ?? []),
      select(RemoteConfigStoreExtension.getters.module,
        ({ state, params }) => state.data?.modules?.find(o => o.module === params)?.properties ?? []),
      select(RemoteConfigStoreExtension.getters.property,
        ({ state, params }) => {
        let returnValue: string | undefined = undefined;
        if(typeof params === 'string')
          returnValue = state.data?.properties
            .find(o => o.key === params)
            ?.value
        else {
          returnValue = state.data?.modules
            .find(o => o.module === params.module)
            ?.properties
            ?.find(o => o.key === params.key)
            ?.value;
        }
        return returnValue;
        }),
      select(RemoteConfigStoreExtension.getters.commonProperties,
        ({ state, params }) => {
          return state.data?.properties
            .reduce((p, c) => ({...p, [c.key]: c.value}), {})
        }),
      select(RemoteConfigStoreExtension.getters.moduleProperties,
        ({ state, params }) => {
          return state.data?.modules
            ?.find(o => o.module === params)
            ?.properties
            ?.reduce((p, c) => ({...p, [c.key]: c.value}), {})
        }),
    ]
  }
}

export const RemoteConfigStore = new StoreBuilder(RemoteObjectStore.create<RemoteConfig>())
  .add(RemoteConfigStoreExtension)
  .value
