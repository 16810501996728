export function fileToBlob(file: File): Promise<Blob> {
  return new Promise<Blob>((resolve, fail) => {
    const reader = new FileReader();
    reader.onloadend = (event: any) => {
      const blob = new Blob([event.target.result], { type: file.type });
      resolve(blob);
    };
    reader.onerror = () => fail(new Error('Can not Read File'));
    reader.readAsArrayBuffer(file);
  });
}
