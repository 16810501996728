<span class="hover:underline cursor-pointer"
      [ngClass]="{'italic !text-light soft-label required': !($any(filter).value | isDefined)}"
      (click)="onValueClick()">
  {{($any(filter).value | isDefined) ? ($any(filter).value | format:'NUMBER.DEFAULT') : ('#APPLICATION.FILTER_AND_SORT.FILTER.NUMBER.PLACEHOLDER' | translate) }}
</span>

<ng-template let-close="close" #dialogRef>
  <div class="flex flex-col" [formGroup]="form">
    <label class="soft-label top required">{{'#APPLICATION.FILTER_AND_SORT.FILTER.NUMBER.LABEL' | translate}}</label>
    <soft-number-input formControlName="value"
                       changeTrigger="input"
                       softFocus>
    </soft-number-input>
    <soft-validation-container
      *ngIf="form.controls['value'].touched"
      [validation]="form | validationMessage: 'value'"
      class="soft-property-validation"
    ></soft-validation-container>
    <button [disabled]="form.invalid"
            class="soft-button primary h-16 rounded mt-4"
            (click)="close(form.value)">{{'#APPLICATION.FILTER_AND_SORT.FILTER.NUMBER.SUBMIT' | translate}}</button>
  </div>
</ng-template>
