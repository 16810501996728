import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KundeninfoLeisteComponent} from '@softapps/fibu/core';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from '@softline/auth';
import {map} from 'rxjs';
import {Store} from '@softline/core';

@Component({
  selector: 'soft-kundeninfo-widget',
  standalone: true,
  imports: [CommonModule, KundeninfoLeisteComponent],
  templateUrl: './kundeninfo-widget.component.html',
  styleUrl: './kundeninfo-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KundeninfoWidgetComponent {
  readonly idktostamm$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
    AuthenticationContextStore.getters.data
  ).pipe(
    map(o => (o && o?.['accountid'] && typeof o?.['accountid'] === 'number')
      ? o?.['accountid']
      : null
    )
  );

  constructor(private readonly store: Store) {}
}
