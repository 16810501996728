import { formatDate, formatNumber } from '@angular/common';
import { getValue } from './get-value.function';
import { isDefined } from './is-defined.function';

const IF_PATTERN: RegExp = /{{\s?([^{}:\s]*?)(?::'([^{}]*)')?\s?}}/g;
const PATTERN: RegExp = /{{\s?([^{}:\s]*?)(?::'([^{}]*)')?\s?}}/g;
const DEFAULT_LOCALE = 'de';

export function interpolate(
  value: string,
  params: object,
  locale?: string
): string {
  return value.replace(
    PATTERN,
    (substring: string, property: string, format: string) => {
      const replacement = getReplacement(params, property, format, locale);
      return isDefined(replacement) ? replacement : substring;
    }
  );
}

function getReplacement(
  value: any,
  property: string,
  format?: string,
  locale?: string
): any {
  value = getValue(value, property);

  if (!format) return value;

  switch (typeof value) {
    case 'string':
      return formatDate(value, format, locale ?? DEFAULT_LOCALE);
    case 'number':
      return formatNumber(value, locale ?? DEFAULT_LOCALE, format);
    default:
      return value;
  }
}
