import { StaticProvider } from "@angular/core";
import { FilterService } from "./services/filter.service";
import { SOFTLINE_REGISTRATION_FILTER } from "./types/filter";
import { FilterProvider } from "./services/filter.provider";
import {
  andFilterPredicate, containsTextFilterPredicate, endsWithFilterPredicate,
  equalFilterPredicate,
  falseFilterPredicate,
  greaterThanFilterPredicate,
  greaterThanOrEqualFilterPredicate, inFilterPredicate, isDefinedFilterPredicate,
  isDefinedNotEmptyFilterPredicate,
  isNotDefinedFilterPredicate,
  isNotDefinedOrEmptyFilterPredicate,
  lessThanFilterPredicate,
  lessThanOrEqualFilterPredicate, likeFilterPredicate,
  notEqualFilterPredicate, notInFilterPredicate, notLikeFilterPredicate,
  orFilterPredicate, regexFilterPredicate, startsWithFilterPredicate,
  trueFilterPredicate
} from "./functions/filter.functions";

// Logical Filters
export const andFilterRegistration = {
  operator: 'and',
  predicate: andFilterPredicate,
}
export const orFilterRegistration = {
  operator: 'or',
  predicate: orFilterPredicate
}

// Unary Filters
export const trueFilterRegistration = {
  operator: 'true',
  predicate: trueFilterPredicate
}
export const falseFilterRegistration = {
  operator: 'false',
  predicate: falseFilterPredicate
}
export const isDefinedFilterRegistration = {
  operator: 'isDefined',
  predicate: isDefinedFilterPredicate
}
export const isNotDefinedFilterRegistration = {
  operator: 'isNotDefined',
  predicate: isNotDefinedFilterPredicate
}
export const isDefinedNotEmptyFilterRegistration = {
  operator: 'isDefinedNotEmpty',
  predicate: isDefinedNotEmptyFilterPredicate
}
export const isNotDefinedOrEmptyFilterRegistration = {
  operator: 'isNotDefinedOrEmpty',
  predicate: isNotDefinedOrEmptyFilterPredicate
}

// Relational Filters
export const equalFilterRegistration = {
  operator: 'equal',
  predicate: equalFilterPredicate
}
export const notEqualFilterRegistration = {
  operator: 'notEqual',
  predicate: notEqualFilterPredicate
}
export const greaterThanFilterRegistration = {
  operator: 'greaterThan',
  predicate: greaterThanFilterPredicate
}
export const greaterThanOrEqualFilterRegistration = {
  operator: 'greaterThanOrEqual',
  predicate: greaterThanOrEqualFilterPredicate
}
export const lessThanFilterRegistration = {
  operator: 'lessThan',
  predicate: lessThanFilterPredicate
}
export const lessThanOrEqualFilterRegistration = {
  operator: 'lessThanOrEqual',
  predicate: lessThanOrEqualFilterPredicate
}

export const startsWithFilterRegistration = {
  operator: 'startsWith',
  predicate: startsWithFilterPredicate
}
export const endsWithFilterRegistration = {
  operator: 'endsWith',
  predicate: endsWithFilterPredicate
}
export const likeFilterRegistration = {
  operator: 'like',
  predicate: likeFilterPredicate
}
export const notLikeFilterRegistration = {
  operator: 'notLike',
  predicate: notLikeFilterPredicate
}
export const regexFilterRegistration = {
  operator: 'regex',
  predicate: regexFilterPredicate
}
export const containsTextFilterRegistration = {
  operator: 'containsText',
  predicate: containsTextFilterPredicate
}

export const inFilterRegistration = {
  operator: 'in',
  predicate: inFilterPredicate
}
export const notInFilterRegistration = {
  operator: 'notIn',
  predicate: notInFilterPredicate
}


export const filterProviders: StaticProvider[] = [
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: andFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: orFilterRegistration, multi: true},

  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: trueFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: falseFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: isDefinedFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: isNotDefinedFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: isDefinedNotEmptyFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: isNotDefinedOrEmptyFilterRegistration, multi: true},

  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: equalFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: notEqualFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: greaterThanFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: greaterThanOrEqualFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: lessThanFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: lessThanOrEqualFilterRegistration, multi: true},

  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: likeFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: notLikeFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: startsWithFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: endsWithFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: regexFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: containsTextFilterRegistration, multi: true},

  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: inFilterRegistration, multi: true},
  { provide: SOFTLINE_REGISTRATION_FILTER, useValue: notInFilterRegistration, multi: true},

  { provide: FilterService, useClass: FilterService },
  { provide: FilterProvider, useClass: FilterProvider },
]
