import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal} from '@softline/ui-core';
import {Empfaenger} from '@softapps/allgemein/send-notification';
import {BehaviorSubject, map} from 'rxjs';
import {Dictionary} from '@softline/core';

@Component({
  selector: 'soft-edit-empfaenger-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './edit-empfaenger-dialog.component.html',
  styleUrls: ['./edit-empfaenger-dialog.component.scss'],
})
export class EditEmpfaengerDialogComponent implements Modal<Empfaenger[]> {

  private readonly _empfaenger$ = new BehaviorSubject<Dictionary<Empfaenger>>({});

  _initialEmpfaenger: Empfaenger[] = [];

  set empfaenger(value: Empfaenger[]) {
    this._initialEmpfaenger = value;
    const dict = {};

    for (const empfaenger of value ) {
      dict[empfaenger.id] = empfaenger;
    }

    this._empfaenger$.next(dict);
  }

  readonly empfaenger$ = this._empfaenger$.pipe(
    map(dict => {
      return this._initialEmpfaenger.map(empfaenger => {
        return {
          empfaenger,
          selected: !!dict[empfaenger.id]
        }
      })
    })
  );

  close!: (result: Empfaenger[]) => void;

  constructor() {}

  registerCloseHandler(handler: (result: Empfaenger[]) => void) {
    this.close = handler;
  }

  save(): void {
    this.close(Object.values(this._empfaenger$.value).filter(o => o !== undefined) as Empfaenger[]);
  }

  selectOrUnselect(empfaenger: Empfaenger): void {
    let dict = this._empfaenger$.value

    console.log(empfaenger);

    if (dict[empfaenger.id]) {
      delete dict[empfaenger.id];
      dict = { ...dict };
    } else {
      dict = { ...dict, [empfaenger.id]: empfaenger }
    }

    this._empfaenger$.next(dict);
    console.log(this._empfaenger$.value);
  }
}
