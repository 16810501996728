import { Position } from '../position';
import { GeolocationService } from './geolocation.service';
import { Injectable } from '@angular/core';
import { Store } from '@softline/core';
import {
  ModalStore,
  SOFTLINE_FEATURE_MODAL,
} from '@softline/ui-core';
import { InputGeolocationComponent } from '../components/input-geolocation.component';

@Injectable()
export class InputGeolocationService extends GeolocationService {
  constructor(private store: Store) {
    super();
  }

  async getCurrentPosition(): Promise<Position> {
    const value = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Position, InputGeolocationComponent>(),
      {
        component: InputGeolocationComponent,
        dismiss: true,
      }
    );

    if (value === 'DISMISSED')
      throw new Error('InputGeolocationService: Input of GPS Position aborted');
    return value;
  }
}
