import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'soft-dynamic-container-value',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
  @Input() value?: any;
  @Input() definition: any;

  constructor() {}

  ngOnInit(): void {}
}
