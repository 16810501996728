import { Routes } from '@angular/router';
import {
  SOFTLINE_CONFIG_TEST_CONNECTION_PATH,
  SOFTLINE_SERVICE_TEST_CONNECTION,
  SOFTLINE_SETTINGS_CONNECTION,
} from './connection-settings.shared';
import { TestConnectionService } from './services/test-connection.service';
import { SOFTLINE_CONFIG_SETTINGS } from '@softline/application';
import { ConnectionSettingsComponent } from './components/connection-settings.component';

export const connectionSettingsRoutes: Routes = [
  {
    path: 'connectionSettings',
    loadComponent: () =>
      import('./components/connection-settings.component').then(
        (o) => o.ConnectionSettingsComponent
      ),
    providers: [],
  },
];
