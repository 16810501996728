import { ENVIRONMENT_INITIALIZER, inject } from '@angular/core';
import { Store } from '@softline/core';
import { default as de } from "../i18n/de.json";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from '@softline/ui-core';
import { kundeninfoLeisteProviders } from './kundeninfo-leiste/kundeninfo-leiste.providers';

export const fibuCoreProviders = [
  {
    ...kundeninfoLeisteProviders,
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'fibu-core',
        language: 'de',
        translations: de
      });
    },
    multi: true
  }
]
