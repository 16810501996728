import { ENVIRONMENT_INITIALIZER, inject, Provider } from '@angular/core';
import { Store2 } from './store2';
import { SOFTLINE_STORE_FEATURES } from './store2.shared';

export const store2Providers: Provider[] = [
  { provide: Store2, useClass: Store2 },
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store2);
      const features = inject(SOFTLINE_STORE_FEATURES);
      for(const feature of features) {
        store.registerFeature(feature);
      }
    },
    multi: true,
  }
];
