<soft-page>
  <soft-page-header>
    <ng-container
      *ngTemplateOutlet="header()?.template() ?? null"
    ></ng-container>
  </soft-page-header>
  <soft-remote-data-container
    [data]="items()"
    [loadingState]="loadingState()"
    (cancel)="cancelLoad.emit()"
  >
    <ul class="flex flex-col gap-2">
      <li *ngFor="let item of items()"
          [ngClass]="itemClick.observed ? 'cursor-pointer' : ''"
          (click)="itemClick.emit(item)">
        @if(itemTemplate(); as itemTemplate) {
          <ng-container *ngTemplateOutlet="itemTemplate; context: {item: item}"></ng-container>
        }
        @else {
          No-Template:
          {{item | json}}
        }
      </li>
    </ul>
    @if (summary(); as summary) {
      @if (summary.template(); as summaryTemplate) {
        <div class="mt-4">
          <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
        </div>
      }
    }
  </soft-remote-data-container>
  <ng-content></ng-content>
</soft-page>
