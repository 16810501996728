import { formatNumber } from '@angular/common';
import {
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Store } from '@softline/core';
import { isDefined } from '@softline/core';
import { Subscription } from 'rxjs';
import { SOFTLINE_FEATURE_LOCALIZATION } from '../../l10n/l10n.shared';
import * as LocalizationStore from '../../l10n/localization.store';

const BINARY_DIVISOR = 1024;
const DECIMAL_DIVISOR = 1000;
const UNITS = [
  { decimal: 'Bytes', binary: 'Bytes' },
  { decimal: 'KB', binary: 'KiB' },
  { decimal: 'MB', binary: 'MiB' },
  { decimal: 'GB', binary: 'GiB' },
  { decimal: 'TB', binary: 'TiB' },
  { decimal: 'PB', binary: 'PiB' },
  { decimal: 'EB', binary: 'EiB' },
  { decimal: 'ZB', binary: 'ZiB' },
  { decimal: 'YB', binary: 'YiB' },
];

export type FileSizeUnit =
  | 'Bytes'
  | 'KB'
  | 'MB'
  | 'GB'
  | 'TB'
  | 'PB'
  | 'EB'
  | 'ZB'
  | 'YB';

export interface FileSizeOptions {
  unit?: FileSizeUnit;
  type?: 'decimal' | 'binary';
  format?: string;
  locale?: string;
}

@Pipe({
  name: 'filesize',
  pure: true,
})
export class FilesizePipe implements PipeTransform, OnDestroy {
  private subscription: Subscription | undefined;
  private locale = 'de';

  constructor(
    private store: Store,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  transform(value: number, options?: FileSizeOptions): string {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = this.store
      .observe(SOFTLINE_FEATURE_LOCALIZATION, LocalizationStore.getter.locale)
      .subscribe((o) => {
        if (o) this.locale = isDefined(o) ? o : this.locale;
        this.changeDetector.markForCheck();
      });

    const divisor =
      options?.type === 'binary' ? BINARY_DIVISOR : DECIMAL_DIVISOR;

    let unit: string;
    let loops = 0;

    if (options?.unit) {
      loops = this.getLoops(options.unit);
      for (let i = 0; i < loops; i++) value = value / divisor;
    } else {
      while (value >= divisor) {
        value = value / divisor;
        loops++;
      }
    }

    unit = this.getUnit(loops, options?.type);
    return formatNumber(value, this.locale, options?.format) + ` ${unit}`;
  }

  getLoops(unit: FileSizeUnit): number {
    return UNITS.findIndex((o) => o.decimal === unit);
  }

  getUnit(loops: number, type: 'binary' | 'decimal' = 'decimal'): string {
    if (type === 'binary') return UNITS[loops].binary;
    else return UNITS[loops].decimal;
  }
}
