import { Component, Input, OnInit } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'soft-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent {
  private _images: (
    | Blob
    | string
    | Promise<Blob | string>
    | Observable<Blob | string>
  )[] = [];
  safeUrls: Observable<string>[] = [];

  @Input()
  get images(): (
    | Blob
    | string
    | Promise<Blob | string>
    | Observable<Blob | string>
  )[] {
    return this._images;
  }
  set images(
    value: (
      | Blob
      | string
      | Promise<Blob | string>
      | Observable<Blob | string>
    )[]
  ) {
    this._images = value;
    this.safeUrls = this.getSaveUrls(value);
  }

  @Input() selectedIndex = 0;

  get hasNext(): boolean {
    return (
      !!this.selectedIndex && (this.images.length - 1 < this.selectedIndex ?? 0)
    );
  }

  get hasPrevious(): boolean {
    return !!this.selectedIndex && this.selectedIndex > 0;
  }

  constructor() {}

  getSaveUrls(
    value: (
      | Blob
      | string
      | Promise<Blob | string>
      | Observable<Blob | string>
    )[]
  ): Observable<string>[] {
    return value.map((o) => {
      if (typeof o === 'string') return of(o);
      else if (o instanceof Blob) return of(URL.createObjectURL(o));
      else if (o instanceof Promise)
        return from(o).pipe(
          map((p) => (typeof p === 'string' ? p : URL.createObjectURL(p)))
        );
      else
        return o.pipe(
          map((p) => (typeof p === 'string' ? p : URL.createObjectURL(p)))
        );
    });
  }

  showNext(): void {
    this.selectedIndex++;
  }

  showPrevious(): void {
    this.selectedIndex--;
  }
}
