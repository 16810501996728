import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import {
  BackNavigable,
  BackNavigationService,
  DashboardComponent,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from "@softline/application";
import { Store } from "@softline/core";
import { ContentWidgetComponent } from "@softapps/migel/core";
import { Router } from "@angular/router";

@Component({
  selector: 'soft-dashboard-page',
  standalone: true,
  imports: [CommonModule, UiCoreModule, DashboardComponent, ContentWidgetComponent],
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardPage implements OnInit, OnDestroy, BackNavigable {

  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService) { }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#DASHBOARD.TITLE');
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

}
