import { ReadonlyRepositoryCollectionState, ReadonlyRepositoryCollectionStore2 } from './readonly-repository-collection.store2';
import { lastValueFrom } from 'rxjs';
import { computed, inject, Injectable } from '@angular/core';
import { Dictionary } from '../../../types/dictionary';
import { CancelledError } from '../../../types/errors';
import { Patch } from '../../../store/specialized/entity/types/patch';
import { SavingState, SOFTLINE_FEATURE_ID_FUNC } from '../../store2.shared';

export interface CollectionCreateActionParameters<T extends object = object> {
  value: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface CollectionUpdateActionParameters<T extends object = object> {
  value: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface CollectionPatchActionParameters<T extends object = object> {
  patch: Patch<T>;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface CollectionDeleteActionParameters<T extends object = object> {
  value: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface RepositoryCollectionState extends ReadonlyRepositoryCollectionState {
  savingState: SavingState;
}

@Injectable()
export class RepositoryCollectionStore2<T extends object, TState extends RepositoryCollectionState = RepositoryCollectionState> extends ReadonlyRepositoryCollectionStore2<T, TState> {

  savingState = computed(() => this.state().savingState);
  protected readonly getId = inject(SOFTLINE_FEATURE_ID_FUNC, {optional: true}) ?? ((item) => item['id'])


  constructor() {
    super();
  }

  override onRegister() {
    super.onRegister();
    this.registerSubFeature(this.subscription);
  }

  async create(params: CollectionCreateActionParameters): Promise<T> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();
    try {
      const id = this.getId(params.value);
      const value = await lastValueFrom(this.subscription.observe(
        token,
        this.service.create(id, params.value, params.pathParams)
      ));
      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.add(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed' } as Partial<TState>);
      throw e;
    }
  }

  async update(params: CollectionUpdateActionParameters): Promise<T> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();
    try {
      const id = this.getId(params.value);
      const value = await lastValueFrom(this.subscription.observe(
        token,
        this.service.update(id, params.value, params.pathParams)
      ));
      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.update(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed' } as Partial<TState>);
      throw e;
    }
  }

  async patch(params: CollectionPatchActionParameters): Promise<T> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();
    try {
      const value = await lastValueFrom(this.subscription.observe(
        token,
        this.service.patch(params.patch.id, params.patch.changes, params.pathParams)
      ));
      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.update(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed' } as Partial<TState>);
      throw e;
    }
  }

  async delete(params: CollectionDeleteActionParameters): Promise<T> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();
    try {
      const id = this.getId(params.value);
      const value = await lastValueFrom(this.subscription.observe(
        token,
        this.service.delete(id, params.pathParams)
      ));
      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.remove(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed' } as Partial<TState>);
      throw e;
    }
  }
}
