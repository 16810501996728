import { SOFTLINE_CONFIG_SETTINGS } from "../application.shared";
import { CloudMessagingService } from "./services/cloud-messaging.service";
import {
  CloudMessagingTopic,
  SOFTLINE_CONFIG_CLOUD_MESSAGING_TOPICS,
  SOFTLINE_CONFIG_ONE_SIGNAL_APP_ID, SOFTLINE_FEATURE_CLOUD_MESSAGING, SOFTLINE_SETTINGS_CLOUD_MESSAGING
} from "./cloud-messaging.shared";
import { CloudMessagingSettingsComponent } from "./settings/cloud-messaging-settings.component";
import { ENVIRONMENT_INITIALIZER, importProvidersFrom, inject } from "@angular/core";
import { Store, StoreModule } from "@softline/core";
import * as CloudMessagingStore from "./store";
import { AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from "@softline/auth";
import { map } from "rxjs/operators";
import { asapScheduler, observeOn } from "rxjs";

export const cloudMessagingProviders = (options: { oneSignalAppId: string, topics?: CloudMessagingTopic[]}
) => {
  return [
    importProvidersFrom(
      StoreModule.forFeature({
        name: SOFTLINE_FEATURE_CLOUD_MESSAGING,
        feature: CloudMessagingStore.feature}
      )
    ),
    {
      provide: SOFTLINE_CONFIG_ONE_SIGNAL_APP_ID,
      useValue: options.oneSignalAppId,
    },
    {
      provide: SOFTLINE_CONFIG_CLOUD_MESSAGING_TOPICS,
      useValue: options.topics ?? [],
    },
    {
      provide: SOFTLINE_CONFIG_SETTINGS,
      multi: true,
      useValue: {
        key: SOFTLINE_SETTINGS_CLOUD_MESSAGING,
        component: CloudMessagingSettingsComponent,
        title: '#APPLICATION.CLOUD_MESSAGING.SETTINGS.TITLE',
        default: {},
        icon: 'fa-regular fa-message-captions',
      },
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => {
        const store = inject(Store);
        store.dispatch(SOFTLINE_FEATURE_CLOUD_MESSAGING, CloudMessagingStore.actions.init);

        const subscription = store
          .observe(
            SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
            AuthenticationContextStore.getters.data
          )
          .pipe(
            observeOn(asapScheduler),
            map((o) => o as { userId: number })
          )
          .subscribe(async (o) => {
            const permission = store.get(SOFTLINE_FEATURE_CLOUD_MESSAGING, CloudMessagingStore.getters.permission);
            if(o?.userId && permission === 'default')
              await store.dispatch(SOFTLINE_FEATURE_CLOUD_MESSAGING, CloudMessagingStore.actions.subscribe)
            if(o?.userId)
              subscription.unsubscribe();
          });
      },
      multi: true
    }

  ]
}
