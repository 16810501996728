import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'soft-dynamic-currency-value',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit {
  @Input() value?: any;
  @Input() format?: string;
  @Input() display?: 'code' | 'symbol';
  @Input() currencyCode?: string;
  @Input() position?: 'before' | 'after';

  constructor() {}

  ngOnInit(): void {}
}
