import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
} from '../../../factories';
import { StoreFeature } from '../../../store';
import * as RemoteCollection from './remote-collection.store';
import {
  LazySaveResult,
  Change,
  getChangeId,
  PatchChange,
} from '../types/change';
import { BULK_ENTITY_SERVICE } from '../bulk/bulk.tokens';
import { Entity } from '../types/entity';
import { Patch } from '../types/patch';
import { Dictionary } from '../../../../types/dictionary';
import * as RemoteCollectionStore from './remote-collection.store';
import { lastValueFrom } from 'rxjs';

export interface LazyCollectionSaveParameters {
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface State<T extends Entity = Entity>
  extends RemoteCollection.State<T> {
  preparedChanges: Change<T>[];
}

export class Store<T extends Entity = Entity> {
  constructor(private collectionStore: RemoteCollection.Store<T>) {}

  mutations = {
    ...this.collectionStore.mutations,
    resetChanges: createMutation<State<T>>('resetChanges'),
    resetChange: createMutation<State<T>, string | number>('resetChange'),
    prepare: createMutation<State<T>, Change<T>>('prepare'),
    applySaveResult: createMutation<State<T>, LazySaveResult<T>>(
      'applySaveResult'
    ),
  };

  getters = {
    ...this.collectionStore.getters,
    change: createGetter<State<T>, Change<T> | null, string | number>('change'),
    changes: createGetter<
      State<T>,
      Change<T>[],
      (string | number)[] | undefined
    >('changes'),

    preparedIds: createGetter<State<T>, (string | number)[]>('preparedIds'),
    preparedEntities: createGetter<State<T>, Dictionary<T>>('preparedEntities'),
    preparedAll: createGetter<State<T>, T[]>('preparedAll'),
    preparedEntity: createGetter<State<T>, T, string | number>(
      'preparedEntity'
    ),
  };

  actions = {
    ...this.collectionStore.actions,
    prepareCreate: createAction<State<T>, T>('prepareCreate'),
    preparePatch: createAction<State<T>, Patch<T>>('preparePatch'),
    prepareUpdate: createAction<State<T>, T>('prepareUpdate'),
    prepareDelete: createAction<State<T>, T>('prepareDelete'),

    saveAll: createAction<
      State<T>,
      LazyCollectionSaveParameters,
      LazySaveResult<T>
    >('saveAll'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.collectionStore.feature.initialState,
      preparedChanges: [],
    },
    mutations: [
      ...this.collectionStore.feature.mutations,
      mutate(this.mutations.resetChanges, ({ state }) => ({
        ...state,
        preparedChanges: [],
      })),
      mutate(this.mutations.prepare, ({ state, params }) => {
        const preparedChanges = [...state.preparedChanges];
        const id = getChangeId(params);
        const index = state.preparedChanges.findIndex(
          (o) => getChangeId(o) === id
        );
        if (index === -1) preparedChanges.push(params);
        else if (
          preparedChanges[index].action === 'create' &&
          params.action === 'delete'
        )
          preparedChanges.splice(index, 1);
        else if (params.action === 'delete')
          preparedChanges.splice(index, 1, params);
        else if (
          preparedChanges[index].action === 'create' &&
          params.action === 'update'
        )
          return state;
        else if (
          preparedChanges[index].action === 'update' &&
          params.action === 'update'
        )
          return state;
        else if (
          preparedChanges[index].action === 'patch' &&
          params.action === 'patch'
        ) {
          const mark: PatchChange<T> = {
            id: params.id,
            action: 'patch',
            changes: {
              ...(preparedChanges[index] as PatchChange<T>).changes,
              ...params.changes,
            },
          };
          preparedChanges.splice(index, 1, mark);
        } else
          throw new Error(
            `BulkStore: Can not prepare ${id} for ${params.action} because its prepared for ${preparedChanges[index].action} before`
          );
        return { ...state, preparedChanges };
      }),
      mutate(this.mutations.resetChange, ({ state, params }) => {
        const preparedChanges = [...state.preparedChanges];
        const index = state.preparedChanges.findIndex(
          (o) => getChangeId(o) === params
        );
        if (index === -1)
          throw new Error(
            `BulkStore: Can not reset ${params} (id is not prepared)`
          );
        preparedChanges.splice(index, 1);
        return { ...state, preparedChanges };
      }),
      mutate(this.mutations.applySaveResult, ({ state, params }) => {
        const preparedChanges = [...state.preparedChanges];
        const ids = [...state.ids];
        let entities = { ...state.entities };

        for (const change of params.succeeded) {
          const index = preparedChanges.findIndex(
            (o) => getChangeId(o) === change.entity.id
          );
          preparedChanges.splice(index, 1);

          switch (change.action) {
            case 'create':
              ids.push(change.entity.id);
              entities = {
                ...entities,
                [change.entity.id]: { ...change.entity },
              };
              break;
            case 'update':
            case 'patch':
              entities = {
                ...entities,
                [change.entity.id]: { ...change.entity },
              };
              break;
            case 'delete':
              delete entities[change.entity.id];
              ids.splice(state.ids.indexOf(change.entity.id), 1);
              break;
          }
        }
        return { ...state, preparedChanges, ids, entities };
      }),
    ],
    getters: [
      ...this.collectionStore.feature.getters,
      select(
        this.getters.change,
        ({ state, params }) =>
          state.preparedChanges.find((o) => getChangeId(o) === params) ?? null
      ),
      select(
        this.getters.changes,
        ({ state, params }) => state.preparedChanges
      ),
      select(this.getters.preparedIds, ({ state }) => {
        const ids = state.ids;
        for (const change of state.preparedChanges) {
          switch (change.action) {
            case 'create':
              ids.push(change.entity.id);
              break;
            case 'delete':
              ids.splice(ids.indexOf(change.id), 1);
              break;
            default:
              break;
          }
        }
        return ids;
      }),
      select(this.getters.preparedEntities, ({ state }) =>
        this.applyChanges(state.entities, state.preparedChanges)
      ),
      select(this.getters.preparedEntity, ({ state, params }) => {
        let entity = state.entities[params];
        const change = state.preparedChanges.find(
          (o) => getChangeId(o) === params
        );
        switch (change?.action) {
          case 'create':
          case 'update':
            entity = change.entity;
            break;
          case 'delete':
            entity = undefined;
            break;
          case 'patch':
            entity = { ...entity, ...change.changes, id: change.id } as T;
            break;
        }
        return entity;
      }),
      select(this.getters.preparedAll, ({ state }) =>
        Object.values(this.applyChanges(state.entities, state.preparedChanges))
      ),
    ],
    actions: [
      ...this.collectionStore.feature.actions,
      on(this.actions.prepareCreate, ({ commit, featureName, params }) => {
        commit(featureName, this.mutations.prepare, {
          action: 'create',
          entity: params,
        });
      }),
      on(this.actions.prepareUpdate, ({ commit, featureName, params }) => {
        commit(featureName, this.mutations.prepare, {
          action: 'update',
          entity: params,
        });
      }),
      on(this.actions.preparePatch, ({ commit, featureName, params }) => {
        commit(featureName, this.mutations.prepare, {
          action: 'patch',
          id: params.id,
          changes: params.changes,
        });
      }),
      on(this.actions.prepareDelete, ({ commit, featureName, params }) => {
        commit(featureName, this.mutations.prepare, {
          action: 'delete',
          id: params.id,
        });
      }),

      on(
        this.actions.saveAll,
        async ({ get, commit, featureName, params, injector }) => {
          const changes = get(featureName, this.getters.changes);
          const service = injector.get(BULK_ENTITY_SERVICE);
          const result = await lastValueFrom(
            service.save(changes, params.pathParams)
          );
          commit(featureName, this.mutations.applySaveResult, result);

          return result;
        }
      ),
    ],
  };

  private applyChanges(
    entities: Dictionary<T>,
    changes: Change<T>[]
  ): Dictionary<T> {
    entities = { ...entities };
    for (const change of changes) {
      switch (change.action) {
        case 'create':
        case 'update':
          entities[change.entity.id] = change.entity;
          break;
        case 'delete':
          delete entities[change.id];
          break;
        case 'patch':
          entities[change.id] = {
            ...entities[change.id],
            ...change.changes,
            id: change.id,
          } as T;
          break;
        default:
          break;
      }
    }
    return entities;
  }
}

export function create<T extends Entity>(): Store<T> {
  const collectionStore = RemoteCollectionStore.create<T>();
  return new Store<T>(collectionStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
