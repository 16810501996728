<form [formGroup]="form">
  <div class="flex flex-col">
    <label class="soft-label top">{{'#HTML5_SCANNER.SETTINGS.SOURCE.LABEL' | translate}}</label>
    <soft-select formControlName="source" (valueChange)="set()">
      <soft-select-option [disabled]="cameras.length === 0" [value]="null">
        {{'#HTML5_SCANNER.SETTINGS.SOURCE.DEFAULT' | translate}}
      </soft-select-option>
      <soft-select-option *ngIf="cameras.length > 0" [value]="'prompt'">
        {{'#HTML5_SCANNER.SETTINGS.SOURCE.PROMPT' | translate}}
      </soft-select-option>
      <soft-select-option *ngIf="cameras.length > 0" [value]="'camera'">
        {{'#HTML5_SCANNER.SETTINGS.SOURCE.CAMERA' | translate}}
      </soft-select-option>
      <soft-select-option *ngIf="cameras.length > 0" [value]="'file'">
        {{'#HTML5_SCANNER.SETTINGS.SOURCE.FILE' | translate}}
      </soft-select-option>
    </soft-select>
  </div>

  <div *ngIf="form.value.source !== 'file'" class="flex flex-col mt-4">
    <label class="soft-label top">{{'#HTML5_SCANNER.SETTINGS.CAMERA.LABEL' | translate}}</label>
    <soft-select formControlName="cameraId"
                 (valueChange)="set()">
      <soft-select-option [value]="null">
        {{'#HTML5_SCANNER.SETTINGS.CAMERA.DEFAULT' | translate}}
      </soft-select-option>
      <soft-select-option *ngFor="let camera of cameras" [value]="camera.id">
        {{camera.label}}
      </soft-select-option>
    </soft-select>
  </div>

  <div *ngIf="form.value.source !== 'file'" class="flex flex-col mt-4">
    <label class="soft-label top">{{'#HTML5_SCANNER.SETTINGS.QR_BOX' | translate}}</label>
    <soft-select formControlName="qrBox" (valueChange)="set()">
      <soft-select-option *ngFor="let qrBox of qrBoxes"
                          [value]="qrBox">
        {{qrBox.name}}
      </soft-select-option>
    </soft-select>
  </div>

  <div *ngIf="form.value.source !== 'file'" class="c row column mt-4">
    <label class="soft-label top">{{'#HTML5_SCANNER.SETTINGS.FPS' | translate}}</label>
    <soft-number-input formControlName="fps" (valueChange)="set()">
    </soft-number-input>
  </div>

  <div *ngIf="form.value.source !== 'file'" class="flex flex-col mt-4">
    <label class="soft-label top">{{'#HTML5_SCANNER.SETTINGS.ASPECT_RATIO' | translate}}</label>
    <soft-select formControlName="aspectRatio" (valueChange)="set()">
      <soft-select-option *ngFor="let aspectRatio of aspectRatios"
                          [value]="aspectRatio.ratio">
        {{aspectRatio.name}}
      </soft-select-option>
    </soft-select>
  </div>
</form>
