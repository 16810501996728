import { Component, TemplateRef } from "@angular/core";
import { Modal } from "../../data/modal";

@Component({
  selector: 'soft-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
})
export class TemplateModalComponent<T> implements Modal<T> {
  close!: (result: T) => void;

  title: string | undefined;
  subtitle?: string;
  template?: TemplateRef<any>;
  params?: object;

  registerCloseHandler(handler: (result: T) => void): void {
    this.close = handler;
  }
}
