<div class="restrict-form-width">
  <div class="soft-form">
    <h3 class="text-xl font-bold">
      {{ '#TIMESTAMP.RECORD.TITLE' | translate }}
    </h3>
    <soft-loading-spinner
      *ngIf="templateLoading$ | async"
    ></soft-loading-spinner>
    <form
      *ngIf="!(templateLoading$ | async)"
      [formGroup]="form"
      (ngSubmit)="onSubmit(form.value)"
    >
      <div class="c column mt-3">
        <label class="soft-label top">{{
          '#TIMESTAMP.RECORD.EMPLOYEE' | translate
        }}</label>
        <soft-entity-input
          *ngIf="canModifyEmployee$ | async"
          formControlName="employee"
        >
          <ng-template let-entity="entity">
            <span *ngIf="entity">
              <strong>{{ entity.number }}</strong> - {{ entity.firstName }}
              {{ entity.lastName }}
            </span>
          </ng-template>
          <soft-entity-picker #picker>
            <ng-template
              [softDataView]="'data'"
              [softQueryView]="'query'"
              [title]="'Abfrage'"
              #dataView="dataView"
            >
              <div class="c column">
                <soft-master-detail [data]="(employees$ | async) ?? []" #masterDetail>
                  <ng-template let-entity="entity" softMasterTemplate>
                    <div class="entity">
                      <div class="entity-content">
                        <strong>{{ entity.number }}</strong>
                        <span class="desc"
                          >{{ entity.firstName }} - {{ entity.lastName }}</span
                        >
                      </div>
                    </div>
                  </ng-template>

                  <ng-template let-entity="entity" softDetailTemplate>
                    <div class="full-width">
                      <div class="detail-row">
                        <h5 class="font-bold">Nummer:</h5>
                        <p>{{ entity.number }}</p>
                      </div>
                      <div class="detail-row">
                        <h5 class="font-bold">Vorname:</h5>
                        <p>{{ entity.firstName }}</p>
                      </div>
                      <div class="detail-row">
                        <h5 class="font-bold">Nachname:</h5>
                        <p>{{ entity.lastName }}</p>
                      </div>
                    </div>
                  </ng-template>
                </soft-master-detail>
                <button
                  class="soft-button primary"
                  (click)="dataView.submit(masterDetail)"
                >
                  Auswählen
                </button>
              </div>
            </ng-template>
          </soft-entity-picker>
        </soft-entity-input>
        <div *ngIf="!(canModifyEmployee$ | async)" class="soft-input readonly">
          <span *ngIf="form.value.employee as entity">
            <strong>{{ entity.number }}</strong> - {{ entity.firstName }}
            {{ entity.lastName }}
          </span>
        </div>
      </div>
      <div class="c row space-between no-wrap-m">
        <div class="c column mt-3">
          <label for="code" class="soft-label top">{{
            '#TIMESTAMP.RECORD.TYPE' | translate
          }}</label>
          <soft-radio-group
            style="width: 100%"
            (valueChange)="onFlagChange()"
            formControlName="flag"
          >
            <div class="c">
              <div class="c">
                <soft-radio-option value="arrival">
                  <span class="soft-label right">{{
                    '#TIMESTAMP.TYPE.ARRIVAL' | translate
                  }}</span></soft-radio-option
                >
              </div>
              <div class="c">
                <soft-radio-option value="departure"
                  ><span class="soft-label right">{{
                    '#TIMESTAMP.TYPE.DEPARTURE' | translate
                  }}</span></soft-radio-option
                >
              </div>
            </div>
          </soft-radio-group>
        </div>
        <div class="c column mt-3">
          <label for="code" class="soft-label top">{{
            '#TIMESTAMP.RECORD.CODE' | translate
          }}</label>
          <soft-select id="code" #sMonth formControlName="code">
            <soft-select-option [value]="null">{{
              '#TIMESTAMP.DEFAULT_CODE' | translate
            }}</soft-select-option>
            <soft-select-option
              *ngFor="
                let code of codes$
                  | async
                  | filter: filterCodes:form.value.flag;
                let i = index
              "
              [value]="code"
              >{{ code.name
              }}<span *ngIf="code.description">
                - {{ code.description }}</span
              ></soft-select-option
            >
          </soft-select>
        </div>
      </div>
      <div class="c column mt-3">
        <label for="timestamp" class="soft-label soft-label-top">{{
          '#TIMESTAMP.RECORD.TIMESTAMP' | translate
        }}</label>
        <soft-date-time-input
          id="timestamp"
          formControlName="timestamp"
          *ngIf="canModifyTime$ | async"
        ></soft-date-time-input>
        <div *ngIf="!(canModifyTime$ | async)" class="soft-input readonly">
          {{ form.value.timestamp | date: 'dd.MM.yyyy HH:mm' }}
        </div>
      </div>
      <div class="mt-3">
        <soft-validation-container
          [validation]="validation$ | async"
          class="soft-entity-validation"
        ></soft-validation-container>
      </div>

      <div class="w-full py-4">
        <button type="submit" class="soft-button accent h-16 rounded w-full">
          <div class="flex flex-row flex-nowrap justify-center">
            <soft-icon class="mr-2" name="fa-regular fa-stopwatch"></soft-icon>
            {{'#TIMESTAMP.RECORD_TIMESTAMP' | translate}}
          </div>
        </button>
      </div>
    </form>
  </div>
</div>
