import { StoreFeature } from './store';

export type CombineFeatureMergeStrategy = (
  f: StoreFeature<any>,
  s: StoreFeature<any>
) => StoreFeature<any>;

export function combineFeatures(
  features: StoreFeature<any>[],
  mergeStrategy: CombineFeatureMergeStrategy = defaultCombineFeatureMergeStrategy
): StoreFeature<any> {
  let result: StoreFeature<any> = {
    initialState: {},
    mutations: [],
    getters: [],
    actions: [],
  };
  for (const feature of features) result = mergeStrategy(result, feature);
  return result;
}

export function defaultCombineFeatureMergeStrategy<T>(
  f: StoreFeature<any>,
  s: StoreFeature<any>
): StoreFeature<any> {
  return {
    initialState: { ...f.initialState, ...s.initialState },
    mutations: [...f.mutations, ...s.mutations],
    getters: [...f.getters, ...s.getters],
    actions: [...f.actions, ...s.actions],
  };
}

export function overrideDuplicateFeatureMergeStrategy<T>(
  f: StoreFeature<any>,
  s: StoreFeature<any>
): StoreFeature<any> {
  const initialState = { ...f.initialState, ...s.initialState };
  const mutations = [...f.mutations];
  for (const mutation of s.mutations) {
    const index = mutations.findIndex(
      (o) => o.mutation.name === mutation.mutation.name
    );
    if (index > -1) mutations.splice(index, 1);
    mutations.push(mutation);
  }

  const actions = [...f.actions];
  for (const action of s.actions) {
    const index = actions.findIndex(
      (o) => o.action.name === action.action.name
    );
    if (index > -1) actions.splice(index, 1);
    actions.push(action);
  }

  const getters = [...f.getters];
  for (const getter of s.getters) {
    const index = getters.findIndex(
      (o) => o.getter.name === getter.getter.name
    );
    if (index > -1) getters.splice(index, 1);
    getters.push(getter);
  }

  return { initialState, mutations, actions, getters };
}
