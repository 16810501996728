export interface Subkunde {
  id: number;
  category: number;
  number: number;
  subnumber: number;
  name: string | null;
  name1: string;
  name2: string;
  name3: string;
  street: string;
  zipcode: string;
  city: string;
  kostenstelle: string | null;
  aktiv: boolean | null;
  anlagedatum: string;
}
