import { Injectable, signal, TemplateRef, Type } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Dictionary } from "@softline/core";

export interface BottomTabConfig {
  name: string;
  title?: string;
  icon?: string;
  class?: string;
  visible?: boolean;
  disabled?: boolean;
}

export interface BottomTabGroup {
  name: string;
  tabs: BottomTabConfig[];
}

@Injectable()
export class BottomTabGroupService {

  tabGroups$ = new BehaviorSubject<BottomTabGroup[]>([]);
  activeTabs$ = new BehaviorSubject<Dictionary<string>>({ });

  setActiveTab(groupName: string, tabName: string): void {
    this.activeTabs$.next({ ...this.activeTabs$.value, [groupName]: tabName });
  }

  addOrUpdate(tabGroup: BottomTabGroup): void {
    const tabGroups = [...this.tabGroups$.value];
    const index = tabGroups.findIndex(t => t.name === tabGroup.name);
    if(index > -1)
      tabGroups.splice(index, 1, tabGroup);
    else
      tabGroups.push(tabGroup);
    this.tabGroups$.next(tabGroups);

    const activeTabs = { ...this.activeTabs$.value };
    if(!activeTabs[tabGroup.name])
      activeTabs[tabGroup.name] = tabGroup.tabs.find(t => !t.disabled)?.name;
    else if(tabGroup.tabs.find(t => t.name === activeTabs[tabGroup.name])?.disabled)
      activeTabs[tabGroup.name] = tabGroup.tabs.find(t => !t.disabled)?.name;
    this.activeTabs$.next(activeTabs);
  }

  remove(groupName: string): void {
    const tabGroups = [...this.tabGroups$.value];
    const index = tabGroups.findIndex(t => t.name === groupName);
    if(index > -1)
      tabGroups.splice(index, 1);
    this.tabGroups$.next(tabGroups);
  }
}
