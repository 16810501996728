import { Component } from '@angular/core';
import {Store} from '@softline/core';
import {RouterModule} from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule]
})
export class AppComponent {
  constructor(public store: Store) {}
}
