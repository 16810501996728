import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScanButtonComponent } from "@softline/application";

@Component({
  selector: 'soft-artikel-input',
  standalone: true,
  imports: [CommonModule, ScanButtonComponent],
  templateUrl: './artikel-input.component.html',
  styleUrls: ['./artikel-input.component.scss']
})
export class ArtikelInputComponent implements OnInit {


  constructor() { }
  ngOnInit(): void {
  }

}
