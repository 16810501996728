import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[softRepeat]',
})
export class RepeatDirective {
  constructor(
    private templateRef: TemplateRef<RepeatDirectiveContext>,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input('softRepeat')
  set count(repeat: number) {
    this.viewContainerRef.clear();
    for (let i = 0; i < repeat; i++)
      this.viewContainerRef.createEmbeddedView(
        this.templateRef,
        new RepeatDirectiveContext(i, i === (repeat - 1)),
        i
      );
  }
}

// tslint:disable-next-line:max-classes-per-file
export class RepeatDirectiveContext {
  constructor(public index: number, public last: boolean) {}
}
