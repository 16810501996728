<ng-container *ngIf="{ tabIndex: activatedTabIndex$ | async } as data">
  <div class="flex flex-col px-4 overflow-auto">
    <ng-container *ngComponentOutlet="activeComponent$ | async"></ng-container>
  </div>
</ng-container>

<ng-template #header>
  <div class="flex flex-col gap-2 w-full bg-surface">
    <soft-filter-and-sort
      class="w-full mt-6 px-4"
      [filter]="filter$ | async"
      [sort]="sort$ | async"
      [values]="(filterData$ | async) || []"
      [filterConfig]="filterConfig$ | async"
      [sortConfig]="sortConfig$ | async"
      (filterChange)="onFilterChange($event)"
      (sortChange)="onSortChange($event)">
    </soft-filter-and-sort>

    <soft-tab-group
      [activatedIndex]="(activatedTabIndex$ | async) ?? 0"
      (activatedIndexChange)="tabIndexChanged($event)">
      <soft-tab title="Abteilungen"></soft-tab>
      <soft-tab title="User"></soft-tab>
    </soft-tab-group>
  </div>
</ng-template>
