import { ENVIRONMENT_INITIALIZER, inject, Provider } from "@angular/core";
import { TelemetryService } from "./services/telemetry.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TelemetryInterceptor } from "./interceptors/telemetry.interceptor";
import { TelemetryBaggageService } from "./services/telemetry-baggage.service";
import { RouteBaggageService } from "./services/route-baggage.service";

export const telemetryProviders: Provider[] = [
  TelemetryService,
  {provide: HTTP_INTERCEPTORS, useClass: TelemetryInterceptor, multi: true},
  {
    provide: ENVIRONMENT_INITIALIZER, useValue: () => {
      const service = inject(TelemetryService)
      window['tracer'] = service;
    },
    multi: true
  },
  {
    provide: TelemetryBaggageService,
    useClass: RouteBaggageService,
    multi: true
  }
]
