export const SOFTLINE_API_GESAMTAFRAGE_QUERY = '/v2/gesamtabfrage/query';
export const SOFTLINE_API_BELEGARTEN = '/v2/gesamtabfrage/belegarten/entities';
export const SOFTLINE_API_GESCHAEFTSFALL_QUERY = '/v2/gesamtabfrage/geschaeftsfall/query';

export const SOFTLINE_API_DOWNLOAD_RECEIPT = '/v2/gesamtabfrage/export/beleg';
export const SOFTLINE_API_DOWNLOAD_ARCHIVE_RECEIPT =
  '/v2/gesamtabfrage/export/archiv-beleg';
export const SOFTLINE_API_DOWNLOAD_VOLUME = '/v2/gesamtabfrage/export/datentraeger';

export const SOFTLINE_DEFINITION_VOUCHER_LIST = 'gesamtabfrage-beleg-list';
export const SOFTLINE_DEFINITION_VOUCHER_QUERY = 'gesamtabfrage-beleg-query';
export const SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE =
  'gesamtabfrage-geschaeftsfall';
