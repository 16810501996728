import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { ArtehListView } from "./list-view/arteh.list-view";

export const artehFieldOk: FieldOkConfig = {
  name: 'arteh',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{arteh}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtehListView,
      sort: {
        properties: [
          {property: 'objekt', title: 'Objekt'},
        ]
      }
    }
  }
  ]
};
export const artehMultiselectFieldOk: FieldOkConfig = {
  name: 'arteh',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{arteh}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtehListView,
      sort: {
        properties: [
          {property: 'objekt', title: 'Objekt'},
        ]
      }
    }
  }
  ]
};

export const artehQueryFieldOk: FieldOkConfig = {
  name: 'arteh',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{arteh}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtehListView,
      sort: {
        properties: [
          {property: 'objekt', title: 'Objekt'},
        ]
      }
    }
  }
  ]
};
