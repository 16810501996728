import { inject, Injectable, Injector, OnDestroy, OnInit, runInInjectionContext, signal, Signal } from '@angular/core';
import { ScannerStore2 } from './scanner.store2';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinct } from 'rxjs';
import { Scan } from './data/scan';
import { Store } from '@softline/core';
import { LabelType } from './data/label-type';
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR } from '@softline/ui-core';

type Constructor<T extends {}> = new(...args: any[]) => T;

export interface WithScanParams {
  labelType?: LabelType | LabelType[] | null;
}

export const WithScan = <T extends Constructor<{}>>(params: WithScanParams, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class ScanMixin extends Base implements OnInit, OnDestroy {

    #injector = inject(Injector);
    #scannerStore = inject(ScannerStore2);
    #store = inject(Store);

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnInit(): void {
      if(super['ngOnInit'])
        super['ngOnInit']();
      runInInjectionContext(this.#injector, () => {
        this.#scannerStore.scan$
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(async o => {
            if(!params.labelType || ((o.labelType && Array.isArray(params.labelType)) ? params.labelType.includes(o.labelType) : o.labelType === params.labelType))
              await this.onScan(o);
            else {
              const actual = o.labelType ?? 'unknown';
              const expected = params.labelType
                ? (Array.isArray(params.labelType) ? params.labelType.join(', ') : params.labelType)
                : 'all';
              await this.#store.dispatch(
                SOFTLINE_FEATURE_MESSAGE_BAR,
                MessageBarStore.actions.warning,
                {
                  title: '#SCANNER.WARNINGS.WRONG_LABEL_TYPE',
                  message: '#SCANNER.WARNINGS.WRONG_LABEL_TYPE_DESC',
                  params: {
                    actual: actual.toUpperCase(),
                    expected: expected.toUpperCase(),
                  }
                }
              )
            }
          })
      });
    }

    ngOnDestroy() {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    abstract onScan(scan: Scan): Promise<void>;
  }
  return ScanMixin;
}
