import {Component, Input, OnInit} from '@angular/core';
import {containsText, FileService, isDefinedNotEmpty, saveAs, Store,} from '@softline/core';
import {MessageBarStore, PreviewService, SOFTLINE_FEATURE_MESSAGE_BAR,} from '@softline/ui-core';
import {BehaviorSubject, combineLatestWith, debounceTime, startWith,} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ArchiveFile} from '../../data/archive';
import * as ArchiveStore from '../../archive.store';
import {SOFTLINE_FEATURE_ARCHIVE} from '../../../application.shared';
import {ArchivePreview} from '../../data/archive-preview';
import {Platform} from '@angular/cdk/platform';

@Component({
  selector: 'soft-archive-list',
  templateUrl: './archive-list.component.html',
  styleUrls: ['./archive-list.component.scss'],
})
export class ArchiveListComponent implements OnInit {
  private _archiveKey$ = new BehaviorSubject<string | null | undefined>('');

  @Input() showFilter = false;

  @Input()
  get archiveKey(): string | null | undefined {
    return this._archiveKey$.value;
  }
  set archiveKey(value: string | null | undefined) {
    this._archiveKey$.next(value);
    if (value && value !== '')
      this.store.dispatch(SOFTLINE_FEATURE_ARCHIVE, ArchiveStore.actions.read, {
        key: value,
      });
  }

  filter$ = new BehaviorSubject<string | null | undefined>(undefined);
  debouncedFilter = this.filter$.pipe(debounceTime(200), startWith(undefined));

  files$ = this._archiveKey$.pipe(
    switchMap((archiveKey) =>
      this.store.observe(
        SOFTLINE_FEATURE_ARCHIVE,
        ArchiveStore.getters.archive,
        archiveKey
      )
    ),
    combineLatestWith(this.debouncedFilter),
    map(([files, filter]) =>
      isDefinedNotEmpty<string>(filter)
        ? files.filter((o) => containsText(o, filter, true))
        : files
    )
  );

  isLoading$ = this._archiveKey$.pipe(
    switchMap((archiveKey) =>
      this.store.observe(
        SOFTLINE_FEATURE_ARCHIVE,
        ArchiveStore.getters.downloading,
        archiveKey
      )
    )
  );

  constructor(
    private store: Store,
    private previewService: PreviewService,
    private fileService: FileService,
    private platform: Platform
  ) {}

  ngOnInit(): void {}

  async download(file: ArchiveFile): Promise<void> {
    const messageId = await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.loading
    );
    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_ARCHIVE,
        ArchiveStore.actions.download,
        { file }
      );
      // saveAs(result.content, file.metaData.name);

      // TODO: This is just a quick fix in order to get the download working in web
      // We cannot only use saveAs because it only works in browser/web not in an app environment
      // BrowserFileService is not implemented yet...
      if (this.platform.isBrowser) {
        saveAs(result.content, file.metaData.name);
      } else {
        const fileUri = await this.fileService.write(result.content, file.metaData.name);
        await this.fileService.open(fileUri, file.metaData.type);
      }

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.close,
        messageId
      );
    } catch (e) {
      console.error(e);
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.close,
        messageId
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#APPLICATION.ARCHIVE.MESSAGES.DOWNLOAD_FAILURE'
      );
    }
  }

  async preview(preview: ArchivePreview): Promise<void> {
    await this.previewService.open(preview);
  }
}
