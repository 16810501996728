import {
  isObservedTransaction,
  isReportingTransaction,
  Transaction,
  TransactionProgress,
  TransactionState,
} from '../transaction';
import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
} from '../../store/factories';
import { StoreFeature } from '../../store/store';

export interface TransactionReport {
  state: TransactionState;
  downloaded: number | undefined;
  downloadSize: number | undefined | null;
  downloadPercent: number;

  sent: boolean;
  uploaded: number | undefined;
  uploadSize: number | undefined | null;
  uploadPercent: number;

  progressPercent: number;
}

export interface State {
  transactions: Transaction[];
}

export const mutations = {
  add: createMutation<State, Transaction>('transaction add'),
  patch: createMutation<State, Partial<Transaction> & { id: string }>(
    'transaction patch'
  ),
  clear: createMutation<State>('transaction clear'),
  reportProgress: createMutation<
    State,
    { id: string; progress: TransactionProgress }
  >('transaction reportProgress'),
};

export const actions = {
  cancel: createAction<State, string>('transaction cancel'),
};

export const getters = {
  transaction: createGetter<State, Transaction, string>(
    'transaction transaction'
  ),
  transactions: createGetter<State, Transaction[], string>(
    'transaction transactions'
  ),

  state: createGetter<State, string, string>('transaction state'),

  allCompleted: createGetter<State, boolean>('transaction allCompleted'), // state = succeeded | failed | cancelled
  anyFailed: createGetter<State, boolean>('transaction anyFailed'),

  report: createGetter<State, TransactionReport, string>(
    'transaction progress'
  ),
};

export const feature: StoreFeature<State> = {
  initialState: {
    transactions: [],
  },
  mutations: [
    mutate(mutations.add, ({ state, params }) => {
      const transactions = [...state.transactions];
      const index = transactions.findIndex((o) => o.id === params.id);
      if (index > -1)
        throw new Error(
          `TransactionStore - add: Id '${params.id}' already exists`
        );
      transactions.push(params);
      return { ...state, transactions };
    }),
    mutate(mutations.patch, ({ state, params }) => {
      const transactions = [...state.transactions];
      const index = transactions.findIndex((o) => o.id === params.id);
      if (index === -1)
        throw new Error(
          `TransactionStore - patch: Id '${params.id}' does not exist`
        );

      const transaction = { ...transactions[index], params };
      transactions.splice(index, 1, transaction);
      return { ...state, transactions };
    }),
    mutate(mutations.clear, ({ state }) => {
      const outstandingTransactions = state.transactions.findIndex(
        (o) => o.state === 'pending' || o.state === 'processing'
      );
      if (outstandingTransactions > -1)
        throw new Error(
          'TransactionStore - clear: Unable to clear transactions. Outstanding requests'
        );
      return { ...state, transactions: [] };
    }),
    mutate(mutations.reportProgress, ({ state, params }) => {
      const transactions = [...state.transactions];
      const index = transactions.findIndex((o) => o.id === params.id);
      if (index === -1)
        throw new Error(
          `TransactionStore - reportProgress: Id '${params.id}' does not exist`
        );

      const transaction = { ...transactions[index] };
      if (!isReportingTransaction(transaction))
        throw new Error(
          `TransactionStore - reportProgress: transaction wit id '${params.id}' is not a reporting transaction`
        );

      if (params.progress.direction === 'upstream')
        transaction.upstream = params.progress;
      else transaction.downstream = params.progress;
      transactions.splice(index, 1, transaction);
      return { ...state, transactions };
    }),
  ],
  actions: [
    on(actions.cancel, ({ featureName, params, get, commit }) => {
      const transaction = get(featureName, getters.transaction, params);
      if (transaction && !isObservedTransaction(transaction))
        throw new Error(
          'TransactionStore - cancel: Unable to cancel. Transaction is not observed'
        );
      else if (!transaction || !transaction.subscription) return;

      transaction.subscription.unsubscribe();
      commit(featureName, mutations.patch, {
        ...transaction,
        state: 'cancelled',
        subscription: undefined,
      });
    }),
  ],
  getters: [
    select(getters.transactions, ({ state }) => state.transactions),
    select(getters.transaction, ({ state, params }) =>
      state.transactions.find((o) => o.id === params)
    ),

    select(
      getters.state,
      ({ params, get, featureName }) =>
        get(featureName, getters.transaction, params).state
    ),

    select(
      getters.allCompleted,
      ({ state }) =>
        state.transactions.length > 0 &&
        state.transactions.every(
          (o) =>
            o.state === 'succeeded' ||
            o.state === 'failed' ||
            o.state === 'cancelled'
        )
    ),
    select(getters.anyFailed, ({ state }) =>
      state.transactions.some((o) => o.state === 'failed')
    ),

    select(getters.report, ({ params, get, featureName }) => {
      const transaction = get(featureName, getters.transaction, params);
      if (!transaction) return undefined;
      if (!isReportingTransaction(transaction))
        throw new Error(
          `TransactionStore - progress: id: ${transaction.id} is not a reporting transaction`
        );

      const downloadPercent =
        (transaction.downstream?.loaded ?? 0) /
        (transaction.downstream?.total ?? Number.POSITIVE_INFINITY);
      const uploadPercent =
        (transaction.upstream?.loaded ?? 0) /
        (transaction.upstream?.total ?? Number.POSITIVE_INFINITY);
      return {
        state: transaction.state,
        downloaded: transaction.downstream?.loaded,
        downloadSize: transaction.downstream?.total,
        downloadPercent,

        sent: transaction.sent,
        uploaded: transaction.upstream?.loaded,
        uploadSize: transaction.upstream?.total,
        uploadPercent,

        progressPercent: (downloadPercent + uploadPercent) / 2,
      };
    }),
  ],
};
