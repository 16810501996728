import { State } from '../connection.store';
import { GetResourceService } from '../../data/abstraction';
import { HttpResourceLocation } from '../../data/specialized/http/http.location';
import { ConnectionLoader } from '../abstraction';
import { lastValueFrom } from 'rxjs';

export class HttpConnectionLoader extends ConnectionLoader {
  constructor(
    private resourceService: GetResourceService<HttpResourceLocation>,
    private path: string
  ) {
    super();
  }

  load(): Promise<State> {
    return lastValueFrom(this.resourceService.get<State>({ url: this.path }));
  }
}
