import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'soft-json-object-tree',
  templateUrl: './json-object-tree.component.html',
  styleUrls: ['./json-object-tree.component.css'],
})
export class JsonObjectTreeComponent implements OnInit {
  isExpanded = false;

  @Input() name?: string;
  @Input() value: unknown = undefined;

  constructor() {}

  ngOnInit(): void {}

  getClassName(value: unknown): string {
    return (value as object).constructor.name;
  }
}
