import {Media} from './media';
import {ArtikelEinheit} from './artikel-einheit';

export interface StaffelPreis {
  amount: number
  currency: string
  gross: number
  net: number
  vat: number
  unit: {
    name: string;
  };
  vatPercent: number
}

export interface ArtikelDetail {
  id: number;
  itemId: number;
  informations: any[];
  note: string;
  info: string;
  media: Media[];
  symbols: any[];
  tieredPricelist?: PriceListEntry[];
  discountTieredPricelist?: DiscountPriceListEntry[];
  tieredPrices: StaffelPreis[];
  itemFilterExtended?: unknown;
  itemSupplier?: Lieferant[];
  itemUnitData?: {
    factor: number;
    weight: number;
    itemUnit: ArtikelEinheit;
  }[];
  itemVkforg?: {
    vkforg: {
      vkforg: string;
      kurzbezeichnung: string;
      bezeichnung1: string;
      bezeichnung2: string;
      bezeichnung3: string;
      idfirma: number;
      id: number;
    },
    listing: {
      kzgelistet: string;
      title: string;
    }
  }[];
  itemStorage?: {
    stock: {
      id: number
      name: string
      title: string
    };
    name: string;
    title: string;
    kzlagerplatz: 'H' | 'S'
  }[]
}

export interface Lieferant {
  supplier: {
    number: number
    name: string
    kzliefgelistet: string
  }
  itemUnit: ArtikelEinheit
  foreignNumber: string
  minAmount: number
}

export interface PriceListEntry {
  priceLevel: {
    name: string;
    title: string;
  },
  tieredPrices: StaffelPreis[];
}

export interface DiscountPriceListEntry {
  discount: {
    name: string;
    title: string;
  },
  discountTieredPrices: (StaffelPreis & {
    discountPercentage: number[];
    from?: string;
    to?: string;
  })[];
}
