<div class="flex flex-col px-4 sm:px-0 mt-4 sm:mt-0">
  <div class="flex flex-col mb-2">
    <h2 class="text-lg font-semibold">Checkliste erstellen</h2>
    <p class="text-sm font-normal text-light">Bitte geben Sie den gewünschten Betreff für die zu erstellende Checkliste ein</p>
  </div>
  <soft-input #input></soft-input>
</div>

<div class="flex w-full items-center sm:gap-2 mt-4">
  <button class="soft-button w-full text-center h-14 primary" (click)="close(null)">
    Abbrechen
  </button>
  <button class="soft-button w-full text-center h-14 accent" [disabled]="(input?.value?.length || 0) < 1" (click)="close({ betreff: input?.value || '' })">
    Erstellen
  </button>
</div>

