<div [softScrollIntoView]="true" scrollBehavior="instant"></div>

<ng-container *ngIf="(filteredItems$ | async) as lieferanten">
  <div class="flex flex-col-reverse full-width bg-surface lg:bg-transparent -mt-2 sticky -top-2 !z-10">
    <div class="lg:-mt-3 w-full p-4 bg-surface border-b border-solid">
      <div class="flex flex-row mb-1">
        <soft-filter-and-sort
          class="w-full mt-2"
          [filter]="filter$ | async"
          [sort]="sort$ | async"
          [values]="(lieferanten$ | async) || []"
          [filterConfig]="filterConfig"
          [sortConfig]="sortConfig"
          (filterChange)="onFilterChange($event)"
          (sortChange)="onSortChange($event)">
        </soft-filter-and-sort>
      </div>
    </div>
    <soft-step-header [steps]="steps" [currentIndex]="0" #stepper class="bg-surface"></soft-step-header>
    <soft-default-header [overline]="stepper.stepText"
                      [title]="stepper.current()?.header"
                      [subtitle]="stepper.current()?.subHeader">

    </soft-default-header>
  </div>
  <ng-container *ngIf="(loading$ | async) === false; else skeleton">
    <div class="flex flex-grow overflow-auto full-width w-full z-[0] lg:-mt-3 relative">
      <div class="flex flex-col w-full px-4 pb-4 lg:pt-4">
        <div
          class="soft-card rounded-sm flex flex-row w-full items-center justify-between cursor-pointer mt-3 !p-3"
          *ngFor="let lieferant of lieferanten"
          [routerLink]="['/warenuebernahme/bestellung/schritt-2']"
          [queryParams]="{idlieferant: lieferant.lieferant.id}">
          <div class="flex flex-row w-full justify-between">
            <div class="flex flex-col w-4/5 gap-2">
              <div class="flex flex-row w-full justify-between">
                <div class="flex flex-col self-center gap-1">
                <span *ngIf="lieferant.lieferant" class="text-light text-sm">
                  {{lieferant.lieferant.lieferantennummer}}
                </span>
                  <span *ngIf="lieferant.lieferant" class="font-semibold">
                  {{lieferant.lieferant.kurzbez}}
                </span>
                  <span *ngIf="lieferant.anzahl" class="text-light text-sm">
                  {{lieferant.anzahl}} {{ (lieferant.anzahl < 2 ? '#WWS_WARENUEBERNAHME.BESTELLUNG.LABEL_OFFENE_BESTELLUNG' : '#WWS_WARENUEBERNAHME.BESTELLUNG.LABEL_OFFENE_BESTELLUNGEN') | translate}}
                </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-1/5 items-end self-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-light">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #skeleton>
  <div class="w-full lg:mt-2">
    <div class="flex flex-col mt-3 justify-between h-[91px] w-full soft-card !p-3 rounded-sm" *softRepeat="10">
      <div class="skeleton rounded h-4 w-20"></div>
      <div class="skeleton rounded h-4 w-28"></div>
      <div class="skeleton rounded h-4 w-20"></div>
    </div>
  </div>
</ng-template>
