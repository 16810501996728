import { Inject, Injectable } from "@angular/core";
import { LocalStorageService, SOFTLINE_CONFIG_DEFAULT_LANGUAGE } from "@softline/core";
import { firstValueFrom, lastValueFrom, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

const LANGUAGE_LOCAL_STORAGE_KEY = 'Softline Language';

@Injectable()
export class LanguageService {
  constructor(private service: LocalStorageService) {
  }

  async load(): Promise<string> {
    return await lastValueFrom(
      this.service.get<string>({key: LANGUAGE_LOCAL_STORAGE_KEY})
    );
  }

  async save(language: string): Promise<string> {
    return await lastValueFrom(
      this.service.create({key: LANGUAGE_LOCAL_STORAGE_KEY}, language)
    );
  }
}
