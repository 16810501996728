<div class="flex flex-col gap-1" *ngIf="{ selected: (selectedList$ | async) } as selectionData">

  <div class="flex flex-col gap-0 px-4 mt-3 mb-3">
    <h3 class="text-lg font-semibold">
      {{ '#ARTIKELINFO.DIALOGS.ADD_FAVORITE_ITEM.TITLE' | translate }}
    </h3>
    <p class="text-sm font-normal text-light m-0 p-0">
      {{ '#ARTIKELINFO.DIALOGS.ADD_FAVORITE_ITEM.SUBTITLE' | translate }}
    </p>
  </div>

  <ul class="flex flex-col gap-0 overflow-y-auto max-h-[45vh] px-4">
    <li class="flex flex-col w-full py-2"
        *ngFor="let list of favoriteLists$ | async; let last = last"
        (click)="selectOrUnselect(list)"
        [ngClass]="[
          (selectionData.selected && selectionData.selected?.id === list.id) ? 'bg-primary-100' : '',
          last ? '' : 'border-b border-b-solid border-b-separator'
        ]">
      <span class="text-sm font-semibold">{{ list.name }}</span>
      <span class="text-sm font-normal text-light">{{ list.creationtime | date:'dd.MM.YYYY' }}</span>
    </li>
  </ul>

  <div class="px-4 mt-2">
    <button
      class="w-full rounded-sm text-primary py-3 border border-solid border-primary"
      (click)="createNewList()">
      {{ '#ARTIKELINFO.DIALOGS.ADD_FAVORITE_ITEM.CREATE_LIST_ACTION' | translate }}
    </button>
  </div>

  <div class="w-full flex justify-between mt-3">
    <button class="soft-button secondary w-full !h-12" (click)="close(null)">
      {{ '#ARTIKELINFO.DIALOGS.ADD_FAVORITE_ITEM.CANCEL' | translate }}
    </button>
    <button class="soft-button primary w-full !h-12" (click)="addToList()" [disabled]="!selectionData.selected">
      <ng-container *ngIf="loading$ | async; else defaultTemplate">
        <soft-loading-spinner class="spin"></soft-loading-spinner>
      </ng-container>

      <ng-template #defaultTemplate>
        {{ '#ARTIKELINFO.DIALOGS.ADD_FAVORITE_ITEM.ADD' | translate }}
      </ng-template>
    </button>
  </div>
</div>

