import { Injectable } from '@angular/core';
import { ConnectionHttpService } from '@softline/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { SOFTLINE_API_PERMISSIONS } from './authorization.api';

@Injectable()
export class AuthorizationService {
  constructor(private service: ConnectionHttpService) {}

  load(): Promise<string[]> {
    return lastValueFrom(
      this.service
        .get<{ result: string[] }>({ path: SOFTLINE_API_PERMISSIONS })
        .pipe(map((o) => o.result))
    );
  }
}
