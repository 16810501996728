import { createGetter, createMutation, mutate, select } from '../../factories';
import { StoreFeature } from '../../store';

export interface State<T extends object = object> {
  data?: T;
}

export class Store<T extends object = object> {
  mutations = {
    set: createMutation<State<T>, T>('set'),
    patch: createMutation<State<T>, Partial<T>>('patch'),
    unset: createMutation<State<T>>('unset'),
  };

  actions = {};

  getters = {
    data: createGetter<State<T>, T>('data'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {},
    mutations: [
      mutate(this.mutations.set, ({ state, params }) => ({
        ...state,
        data: params,
      })),
      mutate(this.mutations.patch, ({ state, params }) => {
        if(!state.data)
          throw new Error('[ObjectStore] Cannot patch undefined object')
        return {
          ...state,
          data: {...state.data, ...params },
        }
      }),
      mutate(this.mutations.unset, ({ state, params }) => ({
        ...state,
        data: undefined,
      })),
    ],
    getters: [select(this.getters.data, ({ state }) => state.data)],
    actions: [],
  };
  AuthenticationContextStore: any;
}

export function create<T extends object>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
