import { Injector, Pipe, PipeTransform } from "@angular/core";
import { ControlDefinitionBase, Definition, GroupDefinition, isControlDefinition } from "../data/definitions";
import { FilterConfig, FilterConfigProperty } from "@softline/application";
import { RuleHelper } from "../utilities/rule.helper";
import { FormatPipe } from "@softline/ui-core";

@Pipe({
  name: 'definitionFilter',
  standalone: true,
  pure: true,
})
export class DefinitionFilterPipe implements PipeTransform {

  constructor(private injector: Injector, private formatPipe: FormatPipe) {
  }

  transform(
    definition: Definition | null | undefined
  ): FilterConfig | null {
    if(!definition)
      return null;
    const properties: FilterConfigProperty[] = [];
    const stack = [definition];
    while (stack.length > 0) {
      const current = stack.splice(0, 1)[0];
      if(isControlDefinition(current) && current.filter) {
        const filter = (current as ControlDefinitionBase).filter;
        if(typeof filter === "boolean") {
          properties.push({
            property: current.name,
            title: current.title ?? current.name,
            params: {formatFn: (value) => this.formatPipe.transform(value, RuleHelper.getFormat(current['format'], value, this.injector))}
          })
        }
        else if(filter) {
          properties.push({
            property: current.name,
            operators: filter.operators,
            title: filter.title ?? current.title ?? current.name,
            params: {formatFn: (value) => this.formatPipe.transform(value, RuleHelper.getFormat(current['format'], value, this.injector))}
          })
        }
      }
      if((current as GroupDefinition)?.definitions)
        stack.unshift(...(current as GroupDefinition)?.definitions);
    }
    return { properties }
  }
}
