import { Entity } from "../types/entity";
import { createAction, createGetter, createMutation, mutate, on, select } from "../../../factories";
import { Dictionary } from "../../../../types/dictionary";
import { StoreFeature } from "../../../store";
import { SOFTLINE_STORE_TEMPLATE_SERVICE } from "../single/entity.shared";
import { inject } from "@angular/core";

export interface LoadActionParameters {
  queryParams?: Dictionary<unknown>;
  pathParams?: Dictionary<unknown>;
  data?: Dictionary<unknown>;
}

export interface State<T extends Entity = Entity> {
  default: Partial<T> | null;
}

export class Store<T extends Entity = Entity> {
  mutations = {
    default: {
      set: createMutation<State<T>, Partial<T> | null>('set default'),
    },
  };

  actions = {
    default: {
      load: createAction<State<T>, LoadActionParameters, Partial<T>>(
        'load default'
      ),
    },
  };

  getters = {
    default: {
      default: createGetter<State<T>, Partial<T>>('default'),
    },
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      default: null,
    },
    mutations: [
      mutate(this.mutations.default.set, ({ state, params }) => ({
        ...state,
        default: params,
      })),
    ],
    actions: [
      on(
        this.actions.default.load,
        async ({ featureName, params, commit }) => {
          const service = inject(SOFTLINE_STORE_TEMPLATE_SERVICE);
          const template = await service.loadTemplate(
            params?.data,
            params?.pathParams,
            params?.queryParams
          );
          commit(
            featureName,
            this.mutations.default.set,
            template
          );
          return template;
        }
      ),
    ],
    getters: [
      select(this.getters.default.default, ({ state }) => state.default),
    ],
  };
}

export function create<T extends Entity>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
