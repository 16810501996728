import { AppInfo } from './data/app-info';
import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import { AppInfoService } from './services/app-info.service';
import { inject } from "@angular/core";

export interface State extends AppInfo {}

export const mutations = {
  set: createMutation<State, State>('set'),
};

export const actions = {
  load: createAction<State, AppInfo>('load'),
  loadOnce: createAction<State, AppInfo>('loadOnce'),
};

export const getters = {
  id: createGetter<State, string>('id'),
  name: createGetter<State, string>('name'),
  version: createGetter<State, string>('version'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    id: '',
    version: '0.0.0',
    name: '',
  },
  mutations: [
    ...ActionStore.feature.mutations,
    mutate(mutations.set, ({ params }) => params),
  ],
  actions: [
    on(actions.load, async ({  state, featureName, commit }) => {
      const service = inject(AppInfoService);
      const appInfo = await service.getAppInfo();
      commit(featureName, mutations.set, appInfo);
    }),
  ],
  getters: [
    select(getters.id, ({ state }) => state.id),
    select(getters.name, ({ state }) => state.name),
    select(getters.version, ({ state }) => state.version),
  ],
};
