import { inject, Injectable, Injector, OnDestroy, OnInit, runInInjectionContext, signal, Signal } from '@angular/core';
import { TitleStore2 } from '../program/title.store2';
import { distinct } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

type Constructor<T> = new(...args: any[]) => T;

export const WithTitle = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class TitleMixin extends Base implements OnInit, OnDestroy {

    #injector = inject(Injector);
    titleStore = inject(TitleStore2);

    abstract title: Signal<string | null | undefined>;
    subtitle: Signal<string | null | undefined> = signal(null);
    titleParams: Signal<object | null | undefined> = signal(null);

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnInit(): void {
      if(super['ngOnInit'])
        super['ngOnInit']();
      runInInjectionContext(this.#injector, () => {
        toObservable(this.title)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {this.titleStore.setTitle(o ?? '');})
        toObservable(this.subtitle)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {this.titleStore.setSubtitle(o ?? '');})
        toObservable(this.titleParams)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {this.titleStore.setParams(o ?? null);})
      });
    }

    ngOnDestroy() {
      this.titleStore.setTitle('');
      this.titleStore.setSubtitle('');
      this.titleStore.setParams(null);

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return TitleMixin;
}

export const WithStaticTitle = <T extends Constructor<{}>>(title: string, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class StaticTitleMixin extends WithTitle(Base) {

    title = signal(title);

    constructor(...args: any[]) {
      super(...args);
    }
  }
  return StaticTitleMixin;
}

export const WithStaticTitleAndSubtitle = <T extends Constructor<{}>>(title: string, subtitle: string, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class StaticTitleAndSubtitleMixin extends WithStaticTitle(title, Base) {

    override subtitle = signal(subtitle);

    constructor(...args: any[]) {
      super(...args);
    }
  }
  return StaticTitleAndSubtitleMixin;
}
