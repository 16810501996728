import {
  ActionStore,
  CollectionStore,
  createAction,
  createGetter,
  createMutation,
  Dictionary,
  KeyStore,
  mutate,
  on,
  ParamsStore,
  select,
  SOFTLINE_SERVICE_UUID,
  SubscriptionStore
} from "@softline/core";
import { lastValueFrom } from "rxjs";
import { ArtikelPreisStore } from "./artikel-preis.store";
import { ArtikelLagerMengenStore } from "./artikel-lager-mengen.store";
import { ArtikelService, LoadManyItemsResult } from "../services/artikel.service";
import { Artikel } from "../types/artikel";
import { ArtikelQuery } from "../types/artikel-query";
import { SOFTLINE_STORE_CONFIG_ARTIKEL } from "../artikel.shared";
import { ArtikelDetailStore } from "./artikel-detail.store";

export interface LoadActionParameters {
  id: number;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface LoadManyActionsParameters {
  query: ArtikelQuery;
  offset?: number;
  limit?: number;
  sort?: string;
  clear?: boolean;
  token?: string;
}

export interface ArtikelState extends CollectionStore.State<Artikel>, KeyStore.State, ParamsStore.State, SubscriptionStore.State, ActionStore.State {
  contentUrl?: string;
  totalResultCount?: number;
  pageSize?: number;
}

const collectionStore = CollectionStore.create<Artikel>();

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ArtikelStore {

  export const mutations = {
    ...collectionStore.mutations,
    action: ActionStore.mutations,
    subscription: SubscriptionStore.mutations,
    setAdditionalInfo: createMutation<ArtikelState, { totalResultCount: number }>('item set additional info'),
    setPageSize: createMutation<ArtikelState, { pageSize: number }>('item set page size'),
  };

  export const actions = {
    ...SubscriptionStore.actions,
    load: createAction<ArtikelState, LoadActionParameters, Artikel>('item load'),
    loadMany: createAction<ArtikelState, LoadManyActionsParameters, LoadManyItemsResult>('item load many'),
    reload: createAction<ArtikelState, Partial<LoadManyActionsParameters>, LoadManyItemsResult>('item/reload'),
  };

  export const getters = {
    ...collectionStore.getters,
    action: ActionStore.getters,
    subscription: SubscriptionStore.getters,
    loading: createGetter<ArtikelState, boolean>('item loading'),
    loaded: createGetter<ArtikelState, boolean>('item loaded'),
    pageSize: createGetter<ArtikelState, number>('page size'),
    totalResultCount: createGetter<ArtikelState, number | undefined>('item number of items'),
  };

  export const feature = {
    initialState: {
      ...collectionStore.feature.initialState,
      ...SubscriptionStore.feature.initialState,
      ...ActionStore.feature.initialState,
      ...KeyStore.feature.initialState,
      ...ParamsStore.feature.initialState,
      pageSize: 20,
    },
    mutations: [
      ...collectionStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
      ...ActionStore.feature.mutations,
      ...KeyStore.feature.mutations,
      ...ParamsStore.feature.mutations,
      mutate(mutations.setAdditionalInfo, ({state, params}) => {
        return {
          ...state,
          totalResultCount: params.totalResultCount
        };
      }),
      mutate(mutations.setPageSize, ({state, params: { pageSize }}) => {
        return {
          ...state,
          pageSize
        };
      }),
    ],
    actions: [
      ...collectionStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      ...ActionStore.feature.actions,
      ...KeyStore.feature.actions,
      ...ParamsStore.feature.actions,
      on(actions.load, async ({commit, featureName, params, dispatch, injector}) => {
        const service = injector.get(ArtikelService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const config = injector.get(SOFTLINE_STORE_CONFIG_ARTIKEL, {});
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const subscription$ = SubscriptionStore.handleSubscriptionState(service.load(params.id), featureName, commit, token);
        const result = await lastValueFrom(ActionStore.handleObservableActionState(subscription$, featureName, commit, actions.load.name, token));
        commit(featureName, mutations.addOrUpdate, result);

        if(config.artikelDetailFeature)
          dispatch(config.artikelDetailFeature, ArtikelDetailStore.actions.loadManySequential, {ids: [result.id]});
        if(config.artikelPreisFeature)
          dispatch(config.artikelPreisFeature, ArtikelPreisStore.actions.loadManySequential, {ids: [result.id]});
        if(config.artikelLagerMengenFeature)
          dispatch(config.artikelLagerMengenFeature, ArtikelLagerMengenStore.actions.loadManySequential, {ids: [result.id]});
        return result;
      }),
      on(actions.loadMany, async ({featureName, injector, params, dispatch, commit, get}) => {
        const service = injector.get(ArtikelService);
        const token = params?.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
        const config = injector.get(SOFTLINE_STORE_CONFIG_ARTIKEL, {});
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const limit = params.limit ?? get(featureName, getters.pageSize) ?? 10

        const $sub = SubscriptionStore.handleSubscriptionState(
          service.loadMany(params.query, limit, params.offset ?? 0, params.sort),
          featureName,
          commit,
          token
        );

        const result = await lastValueFrom(
          ActionStore.handleObservableActionState($sub, featureName, commit, actions.loadMany.name, token)
        );

        if (params.clear)
          commit(featureName, mutations.clear);

        commit(featureName, mutations.addOrUpdateMany, result.result);
        commit(featureName, mutations.setAdditionalInfo, {totalResultCount: result._totalResultCount});

        if(result.result.length > 0) {
          if(config.artikelDetailFeature)
            dispatch(config.artikelDetailFeature, ArtikelDetailStore.actions.loadManySequential, {ids: result.result.map(o => o.id)});
          if(config.artikelPreisFeature)
            dispatch(config.artikelPreisFeature, ArtikelPreisStore.actions.loadManySequential, {ids: result.result.map(o => o.id)});
          if(config.artikelLagerMengenFeature)
            dispatch(config.artikelLagerMengenFeature, ArtikelLagerMengenStore.actions.loadManySequential, {ids: result.result.map(o => o.id)});
        }

        return result;
      }),
      on(actions.reload, async ({ featureName, dispatch, params }) => {
        const loadManyParams: LoadManyActionsParameters = {
          query: params?.query ?? {},
          clear: true,
          offset: params?.offset ?? 0,
          sort: params?.sort,
        };

        return await dispatch(featureName, actions.loadMany, loadManyParams);
      })
    ],
    getters: [
      ...collectionStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      ...ActionStore.feature.getters,
      ...KeyStore.feature.getters,
      ...ParamsStore.feature.getters,
      select(getters.totalResultCount, ({state}) => state.totalResultCount),
      select(getters.pageSize, ({state}) => state.pageSize ?? 20),
    ],
  };
}
