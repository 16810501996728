import { Group } from '../types/group';
import { GroupIterator } from './group.iterator';

export function* groupBy<TKey, TElement>(
  elements: Iterable<TElement>,
  keySelector: (e: TElement) => TKey,
  comparer: (first: TKey, second: TKey) => boolean = (a, b) => a === b
): Iterable<Group<TKey, TElement>> {
  const keys: TKey[] = [];
  for (const element of elements) {
    const key = keySelector(element);
    if (!keys.find((o) => comparer(key, o))) {
      keys.push(key);
      yield {
        key,
        [Symbol.iterator]: () =>
          new GroupIterator(
            elements[Symbol.iterator](),
            keySelector,
            comparer,
            key
          ),
      };
    }
  }
}
