import {
  createAction,
  createGetter,
  createMutation,
  CustomStore,
  mutate,
  on,
  select,
  StoreBuilder
} from "@softline/core";
import {ArtikelvorratService} from '../services/artikelvorrat.service';
import {lastValueFrom} from 'rxjs';
import {ArtikelDetailStore} from '@softapps/wws/core';
import {
  SOFTLINE_FEATURE_MDE_ARTIKELVORRAT,
  SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE
} from "../artikelvorrat.shared";
import {ArtikelvorratBeweStore} from './artikelvorrat-bewe.store';
import {ItemScan, Scan} from '@softline/application';
import {ArtikelvorratKopf} from '../data/artikelvorrat-kopf';
import {ArtikelvorratBeleg} from '../data/artikelvorrat-beleg';
import {SOFTLINE_API_MDE_ARTIKELVORRAT_BEWEGUNGEN} from '../artikelvorrat.api';

export interface BestellvorschlagState {
  kopf?: ArtikelvorratKopf;
  itemScanResult?: ItemScan;
  newBeleg?: boolean;
}

const mutations = {
  setKopf: createMutation<BestellvorschlagState, ArtikelvorratKopf>('mdeArtikelvorrat/setKopf'),
  setItemScanResult: createMutation<BestellvorschlagState, ItemScan | undefined>('mdeArtikelvorrat/setItemScanResult'),
  setNewBeleg: createMutation<BestellvorschlagState, boolean>('mdeArtikelvorrat/setNewBeleg')
}

const getters = {
  kopf: createGetter<BestellvorschlagState, ArtikelvorratKopf | undefined>('mdeArtikelvorrat/getKopf'),
  itemScanResult: createGetter<BestellvorschlagState, ItemScan | undefined>('mdeArtikelvorrat/getItemScanResult'),
}

const actions = {
  abschluss: createAction<BestellvorschlagState, { ediSchicken: boolean }, void>('mdeArtikelvorrat/abschluss'),
  loadKopf: createAction<BestellvorschlagState, void, ArtikelvorratKopf>('mdeArtikelvorrat/loadKopf'),
  einlesen: createAction<BestellvorschlagState, ArtikelvorratBeleg, ArtikelvorratKopf>('mdeArtikelvorrat/einlesen'),
  removeBeleg: createAction<BestellvorschlagState, void, void>('mdeArtikelvorrat/removeBeleg'),
  belegScan: createAction<BestellvorschlagState, Scan, any>('mdeArtikelvorrat/bestellvorschlagScan')
}

const artikelvorratStore: CustomStore<
  BestellvorschlagState,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  mutations,
  getters,
  actions,
  feature: {
    initialState: {
      newBeleg: false,
      kopf: undefined,
      itemScanResult: undefined,
    },
    mutations: [
      mutate(mutations.setItemScanResult, ({ params, state }) => ({
        ...state,
        itemScanResult: params
      })),
      mutate(mutations.setKopf, ({ params: kopf, state }) => ({
        ...state,
        kopf
      })),
      mutate(mutations.setNewBeleg, ({ params, state }) => ({
        ...state,
        newBeleg: params
      }))
    ],
    getters: [
      select(getters.kopf, ({ state }) => state?.kopf),
      select(getters.itemScanResult, ({ state }) => state?.itemScanResult),
    ],
    actions: [
      on(actions.loadKopf, async ({ injector, featureName, commit, get }) => {
        const service = injector.get(ArtikelvorratService);
        const result = await lastValueFrom(service.getKopf());
        commit(featureName, mutations.setKopf, result);
        return result ?? null
      }),
      on(actions.abschluss, async ({ injector, featureName, commit, params }) => {
        const service = injector.get(ArtikelvorratService);
        await lastValueFrom(service.abschluss(params?.ediSchicken));
        commit(SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_ARTIKEL_DETAILS, ArtikelDetailStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE, ArtikelvorratBeweStore.mutations.clear);
        commit(featureName, mutations.setKopf, null);
        commit(featureName, mutations.setItemScanResult, null);
      }),
      on(actions.removeBeleg, async ({ injector, featureName, commit }) => {
        const service = injector.get(ArtikelvorratService);
        await lastValueFrom(service.removeBeleg());
        commit(SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_ARTIKEL_DETAILS, ArtikelDetailStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE, ArtikelvorratBeweStore.mutations.clear);
        commit(featureName, mutations.setKopf, null);
        commit(featureName, mutations.setItemScanResult, null);
      }),
      on(actions.belegScan, async ({ injector, featureName, commit, params }) => {
        const service = injector.get(ArtikelvorratService);
        const result = await lastValueFrom(service.belegScan(params));
        return result;
      }),
      on(actions.einlesen, async ({ injector, featureName, commit, params, dispatch }) => {
        const service = injector.get(ArtikelvorratService);
        const kopf = await lastValueFrom(service.einlesen(params));
        commit(featureName, mutations.setKopf, kopf);
        await dispatch(SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE, ArtikelvorratBeweStore.actions.loadMany, { clear: true });
        return kopf;
      }),
    ],
  }
}

export const ArtikelvorratStore = new StoreBuilder(artikelvorratStore)
  .value;
