import {
  BooleanDefinition,
  Definition,
  NumberDefinition,
  ObjectDefinition,
  SelectDefinition,
  StringDefinition,
} from '../data/definitions';

export function convertDefinition(oldDefinition: any): Definition {
  switch (oldDefinition.attributeType) {
    case 'Abfrage':
      return createObjectDefinition(oldDefinition);
      break;
    case 'integer':
      return createNumberDefinition(oldDefinition);
      break;
    case 'string':
      if (oldDefinition.entries) return createSelectDefinition(oldDefinition);
      else return createStringDefinition(oldDefinition);
      break;
    case 'boolean':
      return createBooleanDefinition(oldDefinition);
    default:
      if (oldDefinition.beanClass !== undefined)
        return createObjectDefinition(oldDefinition);
      break;
  }
  return {
    type: 'string',
    name: oldDefinition.name,
    title: oldDefinition.title,
    required: oldDefinition.required,
  };
}

function createObjectDefinition(oldDefinition: any): ObjectDefinition {
  const definition: ObjectDefinition = {
    type: 'object',
    name: oldDefinition.name,
    title: oldDefinition.title,
    definitions: [],
  };

  for (const oldChildDefinition of oldDefinition.definitions) {
    if (oldChildDefinition.attributeDefinition) {
      const childDefinition = convertDefinition(
        oldChildDefinition.attributeDefinition
      );
      definition.definitions.push(childDefinition);
    }
  }
  return definition;
}

function createNumberDefinition(oldDefinition: any): NumberDefinition {
  return {
    type: 'number',
    name: oldDefinition.name,
    title: oldDefinition.title,
    required: oldDefinition.required,
  };
}

function createStringDefinition(oldDefinition: any): StringDefinition {
  return {
    type: 'string',
    name: oldDefinition.name,
    title: oldDefinition.title,
    required: oldDefinition.required,
  };
}

function createSelectDefinition(oldDefinition: any): SelectDefinition {
  return {
    type: 'select',
    name: oldDefinition.name,
    title: oldDefinition.title,
    required: oldDefinition.required,
    control: 'dropdown',
    options: oldDefinition.entries.map((o: any) => ({
      value: o.value,
      title: o.description,
    })),
  };
}

function createBooleanDefinition(oldDefinition: any): BooleanDefinition {
  return {
    type: 'boolean',
    name: oldDefinition.name,
    label: oldDefinition.title,
    required: oldDefinition.required,
    control: 'checkbox',
  };
}
