import { Routes } from "@angular/router";
import { RemoteConfigResolver } from "@softline/application";
import { AuthenticationGuard, AuthorizationGuard } from "@softline/auth";
import { SOFTLINE_PERMISSION_MDE_MATERIALLIEFERSCHEIN_SCAN } from "./materiallieferschein-scan.shared";
import { MateriallieferscheinScanResolver } from "./resolver/materiallieferschein-scan.resolver";
import {OnDeactivateGuard} from './guards/on-deactivate.guard';
import {KopfGuard} from './guards/kopf.guard';

export const materiallieferscheinScanRoutes: Routes = [
  {
    path: 'materiallieferschein-scan',
    title: 'Material/Lieferschein-Scan',
    children: [
      {
        path: '',
        canDeactivate: [OnDeactivateGuard],
        loadComponent: () =>
          import('./components/materiallieferschein-scan.component').then(
            c => c.MateriallieferscheinScanComponent
        ),
        children: [
          {
            path: 'einlesen',
            loadComponent: () =>
              import('./pages/materiallieferschein-scannen/materiallieferschein-scannen.component').then(
                c => c.MateriallieferscheinScannenComponent
            ),
          },
          {
            path: 'artikel-erfassen',
            loadComponent: () =>
              import('./pages/artikel-erfassen/artikel-erfassen.component').then(
                c => c.ArtikelErfassenComponent
              ),
              loadChildren: () => [
                {
                  loadComponent: () =>
                    import('./pages/artikel-erfassen/schritt1/schritt1.component').then(
                      c => c.Schritt1Component
                    ),
                    path: 'suche'
                },
                {
                  loadComponent: () =>
                    import('./pages/artikel-erfassen/schritt2/schritt2.component').then(
                      c => c.Schritt2Component
                    ),
                    path: ':idartstamm'
                },
                {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: '/materiallieferschein-scan/artikel-erfassen/suche'
                }
              ]
            },
            {
              path: '',
              loadComponent: () =>
                import('./pages/erfassung/erfassung.component').then(
                  c => c.ErfassungComponent
                ),
              canActivate: [KopfGuard],
            },
            {
              path: '**',
              redirectTo: '/materiallieferschein-scan'
            },
          ],
        },
      ],
      canActivate: [AuthenticationGuard, AuthorizationGuard],
      data: { permission: SOFTLINE_PERMISSION_MDE_MATERIALLIEFERSCHEIN_SCAN },
      resolve: { config: RemoteConfigResolver, materiallieferscheinScan: MateriallieferscheinScanResolver },
      providers: [],
    },
  ];
