import { Input, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Definition } from '../../../data/definitions';
import { DynamicAtom } from '../dynamic-atom';

@Directive()
export abstract class DynamicInputAtom<
  T extends Definition
> extends DynamicAtom<T> {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
}
