import { Entity } from '../types/entity';
import { Dictionary } from '../../../../types/dictionary';
import * as SyncedRemoteCollectionStore from './synced-remote-collection.store';
import * as ProgressStore from '../../remote/progress.store';
import { handleProgressState } from '../../remote/progress.store';
import { createAction, createGetter, on, select } from '../../../factories';
import { StoreFeature } from '../../../store';
import { handleSubscriptionState } from '../../remote/subscription.store';
import { SOFTLINE_STORE_ENTITY_SERVICE } from '../single/entity.shared';
import { SOFTLINE_SERVICE_UUID } from '../../../../core.shared';
import {
  ActionState,
  handleObservableActionState,
} from '../../remote/action.store';
import * as KeyStore from '../../remote/key.store';
import * as ParamsStore from '../../remote/params.store';
import { lastValueFrom } from 'rxjs';

export interface UploadActionParameters<T extends object = object> {
  entity: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface State<T extends Entity = Entity>
  extends SyncedRemoteCollectionStore.State<T>,
    ProgressStore.State {}

export class Store<T extends Entity = Entity> {
  mutations = {
    ...this.collectionStore.mutations,
    progress: ProgressStore.mutations,
  };

  actions = {
    ...this.collectionStore.actions,
    upload: createAction<State<T>, UploadActionParameters, T>('upload'),
  };

  getters = {
    ...this.collectionStore.getters,
    progress: ProgressStore.getters,
    uploading: createGetter<State<T>, boolean>('uploading'),
    uploaded: createGetter<State<T>, boolean>('uploaded'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.collectionStore.feature.initialState,
      ...ProgressStore.feature.initialState,
    },
    mutations: [
      ...this.collectionStore.feature.mutations,
      ...ProgressStore.feature.mutations,
    ],
    getters: [
      ...this.collectionStore.feature.getters,
      ...ProgressStore.feature.getters,
      select(this.getters.uploading, ({ get, featureName, params }) => {
        const actions = [this.actions.upload.name];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.uploaded, ({ get, featureName, params }) => {
        const actions = [this.actions.upload.name];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
    actions: [
      ...this.collectionStore.feature.actions,
      on(
        this.actions.upload,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const progress$ = service.upload(params.entity, params.pathParams);
          const result$ = handleProgressState(
            progress$,
            featureName,
            commit,
            token
          );
          const subscription$ = handleSubscriptionState(
            result$,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.upload.name,
              token
            )
          );
          commit(featureName, this.mutations.addOrUpdate, result);
          return result;
        }
      ),
    ],
  };

  constructor(private collectionStore: SyncedRemoteCollectionStore.Store<T>) {}
}

export function create<T extends Entity>(): Store<T> {
  const collectionStore = SyncedRemoteCollectionStore.create<T>();
  return new Store<T>(collectionStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
