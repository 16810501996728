export interface ArtikelInfoModalParams<T extends object = object> {
  data?: T;
  onOpen?: () => void;
  onClose?: () => void;
}

export interface ArtikelInfoModal<T extends object = object> {
  id: number;
  params: T;
}
