<div class="text-lg font-semibold mb-2">Einheiten-Eingabe</div>

<div class="mb-1">
  {{ info | translate }}
</div>

<div class="text-lg font-semibold mb-2">
  {{titel | translate }}
</div>

<!--
<ng-container *ngIf="(summe$ | async) as summe">
  <table class="font-semibold border border-solid w-full table-fixed">
    <tr class="text-left bg-gray-100 border-b border-solid">
      <th class="p-4 text-right">{{ anzeigeeinheit?.arteh ?? 'Faktor'}}</th>
      <th class="p-4">Einheit</th>
      <th class="p-4 text-right">Menge</th>
    </tr>
    <tr *ngFor="let einheit of einheiten ?? []; let i = index; trackBy: trackByFn"
        class="border-b border-solid">
      <td class="text-right p-4">
        {{einheit.faktor | number: '.2'}}
      </td>
      <td class="p-4">
        {{einheit.arteh}}
      </td>
      <td class="p-4 text-right">
        <div>
          <soft-number-input *ngIf="einheit && einheit?.id"
            #mengeBeweInput
            textAlign="right"
            [value]="summenSignal()[einheit.id || ''] || 0"
            (valueChange)="updateAnzahl(einheit, mengeBeweInput?.value ?? 0)">
          </soft-number-input>
        </div>
      </td>
    </tr>
    <tr>
      <td class="mr-auto p-4">
        Summe
      </td>
      <td class="p-4"></td>
      <td class="ml-auto text-right p-4">
        {{ summe?.anzeige?.menge ?? 0 | number: '.2'}}
      </td>
    </tr>
  </table>

  <button class="soft-button bg-primary rounded-sm text-white h-16 w-full mt-2"
          (click)="close(summe)">
    Bestätigen
  </button>
</ng-container>
-->

<ng-container *ngIf="(summe$ | async) as summe">
  <table class="font-semibold border border-solid w-full table-fixed" [formGroup]="form">
    <tr class="text-left bg-gray-100 border-b border-solid">
      <th class="p-4 text-right">{{ anzeigeeinheit?.arteh ?? 'Faktor'}}</th>
      <th class="p-4">Einheit</th>
      <th class="p-4 text-right">Menge</th>
    </tr>
      <tr *ngFor="let einheit of einheiten ?? []; let i = index; trackBy: trackByFn"
          class="border-b border-solid">
        <td class="text-right p-4">
          {{einheit.faktor | number: '.2'}}
        </td>
        <td class="p-4">
          {{einheit.arteh}}
        </td>
        <td class="p-4 text-right">
          <div>
            <soft-number-input *ngIf="einheit && einheit?.id"
                               [formControlName]="einheit.id"
                               textAlign="right">
            </soft-number-input>
          </div>
        </td>
      </tr>
      <tr>
        <td class="mr-auto p-4">
          Summe
        </td>
        <td class="p-4"></td>
        <td class="ml-auto text-right p-4">
          {{ summe?.anzeige?.menge ?? 0 | number: '.2'}}
        </td>
      </tr>
  </table>

  <button class="soft-button bg-primary rounded-sm text-white h-16 w-full mt-2"
          (click)="close(summe)">
    Bestätigen
  </button>
</ng-container>
