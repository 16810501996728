import {ENVIRONMENT_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, Provider} from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
  SoftlineObjectService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {
  ApplicationStore,
  SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import {
  SOFTLINE_FEATURE_WWS_KREDITLIMIT,
  SOFTLINE_PERMISSION_WWS_KREDITLIMIT, SOFTLINE_PERMISSION_WWS_KREDITLIMIT_WIDGET,
  WwsKreditlimitStore
} from "./kreditlimit.shared";
import {SOFTLINE_API_WWS_KREDITLIMIT} from './kreditlimit.api';
import {KreditlimitWidgetComponent} from './widgets/kreditlimit-widget/kreditlimit-widget.component';

export const kreditlimitProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'Kreditlimit',
          language: 'de',
          translations: de,
        }
      );
    },
    multi: true,
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_KREDITLIMIT,
      feature: WwsKreditlimitStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
          useFactory: (service: ConnectionHttpService) =>
            new SoftlineObjectService(service, SOFTLINE_API_WWS_KREDITLIMIT),
          deps: [SOFTLINE_SERVICE_HTTP],
        },
      ],
    })
  ),
  {
    provide: SOFTLINE_CONFIG_DASHBOARD_COMPONENT,
    multi: true,
    useValue: {
      component: KreditlimitWidgetComponent,
      permission: SOFTLINE_PERMISSION_WWS_KREDITLIMIT_WIDGET
    }
  }
];
