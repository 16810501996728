import {
  ArrayFilter,
  Filter,
  FilterOptions,
  FilterPredicateSelector,
  LogicalFilter,
  PatternFilter,
  RelationalFilter,
  UnaryFilter
} from "../types/filter";
import { containsText, equals, getValue, isDefined, isDefinedNotEmpty } from "@softline/core";
import moment from 'moment';

// Logical Filter
export function andFilterPredicate<T>(value: T, options: FilterOptions<LogicalFilter>, getPredicate: FilterPredicateSelector): boolean {
  if(options.filters.length === 0)
    return true;
  return options.filters
    .map(filter => ({filter, predicate: getPredicate(filter.operator)}))
    .every(({filter, predicate}) => predicate(value, filter, getPredicate));
}
export function orFilterPredicate<T>(value: T, options: FilterOptions<LogicalFilter>, getPredicate: FilterPredicateSelector): boolean {
  if(options.filters.length === 0)
    return true;
  return options.filters
    .map(filter => ({filter, predicate: getPredicate(filter.operator)}))
    .some(({filter, predicate}) => predicate(value, filter, getPredicate));
}

// Unary Filters
export function trueFilterPredicate<T>(value: T, options: FilterOptions<UnaryFilter<T, keyof T>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);

  if(value !== null && typeof value === "boolean")
    return value
  return false;
}
export function falseFilterPredicate<T>(value: T, options: FilterOptions<UnaryFilter<T, keyof T>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);

  if(value !== null && typeof value === "boolean")
    return !value
  return false;
}
export function isDefinedFilterPredicate<T>(value: T, options:  FilterOptions<UnaryFilter<T, keyof T>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  return isDefined(value);
}
export function isNotDefinedFilterPredicate<T>(value: T, options: FilterOptions<UnaryFilter<T, keyof T>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);

  return !isDefined(value);
}
export function isDefinedNotEmptyFilterPredicate<T>(value: T, options:  FilterOptions<UnaryFilter<T, keyof T>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  return isDefinedNotEmpty(value);
}
export function isNotDefinedOrEmptyFilterPredicate<T>(value: T, options: FilterOptions<UnaryFilter<T, keyof T>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  return !isDefinedNotEmpty(value);
}



// Relational Filter
export function equalFilterPredicate<T>(value: T, options: FilterOptions<RelationalFilter<T, keyof T, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  return equals(value, options.value);
}
export function notEqualFilterPredicate<T>(value: T, options: FilterOptions<RelationalFilter<T, keyof T, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  return !equals(value, options.value);
}
export function greaterThanFilterPredicate<T>(value: T, options: FilterOptions<RelationalFilter<T, keyof T, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  if(typeof value === 'string') {
    const date = moment(value, false);
    if(date.isValid())
      return date.diff(options.value) > 0;
  }
  if(typeof value === 'number')
    return +value > +options.value;
  return false;
}
export function greaterThanOrEqualFilterPredicate<T>(value: T, options: FilterOptions<RelationalFilter<T, keyof T, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  if(typeof value === 'string') {
    const date = moment(value, false);
    if(date.isValid())
      return date.diff(options.value) >= 0;
  }
  if(typeof value === 'number')
    return +value >= +options.value;
  return false;
}
export function lessThanFilterPredicate<T>(value: T, options: FilterOptions<RelationalFilter<T, keyof T, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  if(typeof value === 'string') {
    const date = moment(value, false);
    if(date.isValid())
      return date.diff(options.value) < 0;
  }
  if(typeof value === 'number')
    return +value < +options.value;
  return false;
}
export function lessThanOrEqualFilterPredicate<T>(value: T, options: FilterOptions<RelationalFilter<T, keyof T, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  if(typeof value === 'string') {
    const date = moment(value, false);
    if(date.isValid())
      return date.diff(options.value) <= 0;
  }
  if(typeof value === 'number')
    return value <= options.value;
  return false;
}

export function startsWithFilterPredicate<T>(value: T, options: FilterOptions<PatternFilter<any, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  let optionsValue = options.value;
  if(typeof value === 'string' && options.caseSensitive)
    return value.startsWith(options.value)
  else if (typeof value === 'string')
    return value.toUpperCase().startsWith(options.value.toUpperCase())
  return false;
}
export function endsWithFilterPredicate<T>(value: T, options: FilterOptions<PatternFilter<any, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  if(typeof value === 'string' && options.caseSensitive)
    return value.endsWith(options.value)
  else if (typeof value === 'string')
    return value.toUpperCase().endsWith(options.value.toUpperCase())
  return false;
}
export function likeFilterPredicate<T>(value: T, options: FilterOptions<PatternFilter<any, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  if(typeof value === 'string' && options.caseSensitive)
    return value.includes(options.value)
  else if (typeof value === 'string')
    return value.toUpperCase().includes(options.value.toUpperCase())
  return false;
}

export function notLikeFilterPredicate<T>(value: T, options: FilterOptions<PatternFilter<any, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  if(typeof value === 'string' && options.caseSensitive)
    return !value.includes(options.value)
  else if (typeof value === 'string')
    return !value.toUpperCase().includes(options.value.toUpperCase())
  return false;
}
export function regexFilterPredicate<T>(value: T, options: FilterOptions<PatternFilter<any, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  if(typeof value === 'string')
    return new RegExp(options.value).test(value);
  return false;
}
export function containsTextFilterPredicate<T>(value: T, options: FilterOptions<PatternFilter<any, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  if(!options.wholeText && typeof value === 'object') {
    const parts = options.value.split(' ');
    return parts.every(p => containsText(value, p, !options.caseSensitive))
  }
  else if(typeof value === 'object')
    return containsText(value, options.value, !options.caseSensitive)
  return false;
}

export function inFilterPredicate<T>(value: T, options: FilterOptions<ArrayFilter<T, keyof T, any>>): boolean {
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  return (options.value ?? []).some(o => equals(value, o))
}
export function notInFilterPredicate<T>(value: T, options: FilterOptions<ArrayFilter<T, keyof T, any>>): boolean {
  if(Array.isArray(value))
  if(value !== null && typeof value === "object" && options.property)
    value = getValue(value, options.property);
  return !(options.value ?? []).some(o => equals(value, o))
}
