import {Routes} from "@angular/router";
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {SOFTLINE_PERMISSION_PMS_STATUSABFRAGE} from './statusabfrage.shared';

export const statusabfrageRoutes: Routes = [
  {
    path: 'statusabfrage',
    loadComponent: () => import('./components/statusabfrage.component').then(c => c.StatusabfrageComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/abteilungen/abteilungen.component').then(c => c.AbteilungenComponent),
      },
      {
        path: 'abteilung/:id',
        loadComponent: () => import('./pages/abteilung/abteilung.component').then(c => c.AbteilungComponent),
      },
      {
        path: 'abteilung/:id/detail/:idlstfix',
        loadComponent: () => import('./pages/mitarbeiter-detail/mitarbeiter-detail.component').then(c => c.MitarbeiterDetailComponent)
      }
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_PMS_STATUSABFRAGE },
  }
];
