import { Component, OnInit } from "@angular/core";
import { DateService, Store } from "@softline/core";
import moment from "moment";
import { SOFTLINE_FEATURE_REMOTE_CONFIG } from "../remote-config.shared";
import { RemoteConfigStore } from "../remote-config.store";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import { handleRequestErrors } from "../../errors/handle-request-errors.function";
import {Observable} from 'rxjs';
import {RemoteConfig} from '@softline/application';

@Component({
  selector: 'soft-remote-config-settings',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './remote-config-settings.component.html',
  styleUrls: ['./remote-config-settings.component.scss'],
})
export class RemoteConfigSettingsComponent implements OnInit {

  config$: Observable<RemoteConfig> = this.store.observe(
    SOFTLINE_FEATURE_REMOTE_CONFIG,
    RemoteConfigStore.getters.data
  )

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_REMOTE_CONFIG, RemoteConfigStore.actions.loadOnce)
    }
    catch (e) {
      handleRequestErrors(this.store, e);
    }
  }
}
