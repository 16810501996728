import { Inject, Injectable } from '@angular/core';
import { State } from '../connection.store';
import { LocalStorageService } from '../../data/specialized/storage/local-storage.service';
import { ConnectionLoader } from '../abstraction';
import { catchError } from 'rxjs/operators';
import { lastValueFrom, of } from 'rxjs';
import { SOFTLINE_CONFIG_CONNECTION_DEFAULT_STATE } from '../connection.shared';

const CONNECTION_RESOURCE_KEY = 'Connection';

@Injectable()
export class LocalStoreConnectionLoader extends ConnectionLoader {
  constructor(
    private service: LocalStorageService,
    @Inject(SOFTLINE_CONFIG_CONNECTION_DEFAULT_STATE)
    private defaultConnections: State
  ) {
    super();
  }

  load(): Promise<State> {
    return lastValueFrom(
      this.service
        .get<State>({ key: CONNECTION_RESOURCE_KEY })
        .pipe(catchError((_) => of(this.defaultConnections)))
    );
  }
}
