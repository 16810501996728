import {
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import { Store } from '@softline/core';
import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class TitleService {
  constructor(private store: Store, private titleService: Title) {
    store
      .observe(SOFTLINE_FEATURE_TITLE, TitleStore.getters.title)
      .subscribe((title) => {
        titleService.setTitle(title);
      });
  }
}
