
import * as CollectionStore from "./collection.store";
import { Sort } from "../../../../sort/types/sort";
import { Entity } from "../types/entity";
import { createGetter, createMutation, mutate, select } from "../../../factories";
import { StoreFeature } from "../../../store";
import { SortService } from "../../../../sort/services/sort.service";
import { isDefined } from "../../../../functions/is-defined.function";

export interface State<T extends Entity = Entity>
  extends CollectionStore.State<T> {
  sort: Sort | null
}

export class Store<T extends Entity = Entity> {
  mutations = {
    setSort: createMutation<State<T>, Sort | null>('setSort'),
  };

  getters = {
    sort: createGetter<State<T>, Sort | null>('sort'),
    sorted: createGetter<State<T>, T[]>('sorted'),
  };

  feature: Partial<StoreFeature<State<T>>> = {
    initialState: {
      sort: null,
    } as State<T>,
    mutations: [
      mutate(this.mutations.setSort, ({ state, params }) => {
        return { ...state, sort: params };
      }),
    ],
    getters: [
      select(
        this.getters.sort,
        ({ state, params }) =>
          state.sort
      ),
      select(
        this.getters.sorted,
        ({ state, params , injector}) => {
          const service = injector.get(SortService);
          const entities = state.ids.map(o => state.entities[o]);
          if(isDefined(state.sort))
            return service.sort(entities, state.sort as any)
          return entities;
        }
      ),
    ],
  };
}

export function create<T extends Entity>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = [];
export const feature = instance.feature;
