import { computed, Injectable } from '@angular/core';
import { Store2Feature } from '../../store2-feature';
import { InjectableStore2Feature } from '../../injectable-store2-feature';

@Injectable()
export class SelectStore2<T> extends InjectableStore2Feature<T | null> {

  selected = computed(() => this.state());
  selected$ = this.state$;

  constructor() {
    super();
  }

  select(selected: T | null): void {
    this.commit(selected);
  }

  unselect(): void {
    this.commit(null);
  }

  override getDefaultState(): T | null {
    return null;
  }
}
