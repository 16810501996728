import { Component, ComponentRef, Type, ViewContainerRef } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Filter } from "@softline/core";

export interface FilterConfig {
  properties: FilterConfigProperty[];
}

export interface FilterConfigProperty<T = unknown> {
  property: string;
  title: string;
  operators?: string[];
  params?: T;
}

export type FilterValueComponentParams<TValues = unknown, TParams = unknown> = {
  vcRef: ViewContainerRef,
  filter: Partial<Filter>,
  filterChange: (value: Partial<Filter>) => void,
  config: FilterConfigProperty<TParams> | undefined,
  values: TValues[]
};

export interface FilterConfigOperator {
  operator: string;
  title: string;
  componentFactory?: (params: FilterValueComponentParams) => ComponentRef<any>
}
