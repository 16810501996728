import {firstValueFrom, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Belegart} from "../types/belegart.model";
import {Beleg} from "../types/beleg.model";
import {
  GesamtabfrageConfig,
  GesamtabfrageExportConfig,
  SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG,
  SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL
} from "../gesamtabfrage.shared";
import {MessageBarStore, ModalStore, SOFTLINE_FEATURE_MESSAGE_BAR, SOFTLINE_FEATURE_MODAL} from "@softline/ui-core";
import {CancelledError, DownloadStore, saveAs, SOFTLINE_FEATURE_DOWNLOAD, Store} from "@softline/core";
import {handleRequestErrors} from "@softline/application";
import {GesamtabfrageDownloadFile, GesamtabfrageDownloadStrategy} from "./download/gesamtabfrage-download-strategy";
import {Injector, Type} from "@angular/core";
import {BelegStore} from "../store/beleg.store";
import {GeschaeftsfallStore} from "../store/geschaeftsfall.store";

export class DownloadCommand{
  icon = 'fa-regular fa-download';
  name = '#GESAMTABFRAGE.ACTIONS.DOWNLOAD';
  class = 'menu action-menu action-menu-top';
  belegart$ = this.selected$.pipe(
    map((o) => {
      const belegarten = o.map(p => p.belegart)
        .filter((v, i, a) => a.findIndex(q => q.id === v.id) === i);

      if(belegarten.length === 1)
        return belegarten[0]
      return undefined
    }
  ));
  canExecute = this.belegart$.pipe(
    map(o => !!o)
  );

  constructor(
    private store: Store,
    private selected$: Observable<readonly Beleg[]>,
    private uuid: () => string,
    private config: GesamtabfrageConfig,
    private injector: Injector
  ) {}

  execute = async () => await this.download();

  async download(): Promise<void> {
    const vouchers = await firstValueFrom(this.selected$);

    if (vouchers.length === 0)
      return;

    const belegart = await firstValueFrom(this.belegart$);

    if (!belegart)
      return;

    const strategy = await this.getStrategy(belegart);

    if (!strategy)
      return;

    const files = await strategy.getDownloadFiles(vouchers, belegart);
    console.log('files: ', files);

    if (files?.length < 1)
      return;

    for (const file of files)
      await this.downloadFile(file);

    this.updateNeuerBeleg(vouchers.map(o => o.id));
  }

  private async getStrategy(belegart: Belegart): Promise<GesamtabfrageDownloadStrategy | undefined> {
    if (!this.config.download)
      return undefined;

    const strategies = this.getStrategies(belegart, this.config);
    if(strategies.length === 1)
      return strategies[0];

    const options = strategies.map(o => ({
      icon: o.icon, label: o.label, value: o
    }))

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose<GesamtabfrageDownloadStrategy>(),
      {
        title: '#GESAMTABFRAGE.MODAL.CHOOSE.DOWNLOAD.TITLE',
        options,
        dismiss: true,
      }
    );

    if (!result || result === 'DISMISSED')
      return undefined;

    return result;
  }

  private async downloadFile(
    file: GesamtabfrageDownloadFile
  ): Promise<void> {
    let loadingBarId: string | undefined;
    try {
      const token = this.uuid();
      loadingBarId = await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.loading,
        {
          message: file.filename,
          onDismiss: () => {
            loadingBarId = undefined;
            this.store.dispatch(
              SOFTLINE_FEATURE_DOWNLOAD,
              DownloadStore.actions.cancel,
              token
            );
          }
        }
      );
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_DOWNLOAD,
        DownloadStore.actions.download,
        {
          location: file.location,
          filename: file.filename,
          body: file.body,
          token,
        }
      );

      console.log(file.filename);
      console.log(result.filename);

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.close,
        loadingBarId
      );

      // TODO: Check iOS download
      saveAs(result.content, result.filename);
    } catch (e) {
      if(loadingBarId)
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.close,
          loadingBarId
        );
      if(e instanceof CancelledError)
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.info,
          '#GESAMTABFRAGE.MESSAGES.INFO.DOWNLOAD_CANCELLED'
        );
      else
        handleRequestErrors(this.store, e);
    }
  }

  private getStrategies(belegart: Belegart, config: GesamtabfrageConfig): GesamtabfrageDownloadStrategy[] {
    const result: GesamtabfrageDownloadStrategy[] = [];

    for(const download of (config.download ?? [])) {
      if(isDownloadConfig(download) && download.belegart === belegart.id)
        result.push(...download.strategies.map(o => this.injector.get(o)))
      else if(!isDownloadConfig(download))
        result.push(this.injector.get(download))
    }
    return result;
  }

  private updateNeuerBeleg(ids: (string | number)[]): void {
    const belegIds = this.store.get(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.getters.ids)
    this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG, BelegStore.mutations.patchMany,
      ids.filter(id => belegIds.indexOf(id) > -1).map(id => ({id, changes: {neuerBeleg: false}})))

    const geschaeftsfallIds = this.store.get(SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL, GeschaeftsfallStore.getters.ids)
    this.store.commit(SOFTLINE_FEATURE_GESAMTABFRAGE_GESCHAEFTSFALL, GeschaeftsfallStore.mutations.patchMany,
      ids.filter(id => geschaeftsfallIds.indexOf(id) > -1).map(id => ({id, changes: {neuerBeleg: false}})))
  }
}

function isDownloadConfig(value: Type<GesamtabfrageDownloadStrategy> | GesamtabfrageExportConfig): value is GesamtabfrageExportConfig {
  return (value as GesamtabfrageExportConfig)?.strategies !== undefined
}
