import {
  createAction,
  createGetter,
  createMutation,
  CustomStore,
  mutate,
  on,
  select,
  StoreBuilder
} from "@softline/core";
import {WarenverprobungService} from '../services/warenverprobung.service';
import {lastValueFrom} from 'rxjs';
import {
  SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BELEGE,
  SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE
} from "../warenverprobung.shared";
import {WarenverprobungBeweStore} from './warenverprobung-bewe.store';
import {ItemScan} from '@softline/application';
import {WarenverprobungBeleg} from '../data/warenverprobung-beleg';
import {WarenverprobungKopf} from '../data/warenverprobung-kopf';
import {WarenverprobungBelegeStore} from './warenverprobung-belege.store';
import {WarenverprobungArtikelDetailStore} from './warenverprobung-artikel-detail.store';

export interface WarenverprobungState {
  kopf?: WarenverprobungKopf;
  belege: WarenverprobungBeleg[]
  bezugsnummer?: string | null;
  itemScanResult?: ItemScan;
}

const mutations = {
  setKopf: createMutation<WarenverprobungState, WarenverprobungKopf>('mdeWarenverprobung/setKopf'),
  setItemScanResult: createMutation<WarenverprobungState, ItemScan | undefined>('mdeWarenverprobung/setItemScanResult'),
  setBelege: createMutation<WarenverprobungState, WarenverprobungBeleg[]>('mdeWarenverprobung/setBelege'),
  setBezugsnummer: createMutation<WarenverprobungState, string | null | undefined>('mdeWarenverprobung/setBezugsnummer'),
}

const getters = {
  kopf: createGetter<WarenverprobungState, WarenverprobungKopf | undefined>('mdeWarenverprobung/getKopf'),
  belege: createGetter<WarenverprobungState, WarenverprobungBeleg[]>('mdeWarenverprobung/getBelege'),
  itemScanResult: createGetter<WarenverprobungState, ItemScan | undefined>('mdeWarenverprobung/getItemScanResult'),
  belegCount: createGetter<WarenverprobungState, number>('mdeWarenverprobung/belegCount'),
}

const actions = {
  abschluss: createAction<WarenverprobungState, void, void>('mdeWarenverprobung/abschluss'),
  loadKopf: createAction<WarenverprobungState, void, object>('mdeWarenverprobung/loadKopf'),
  removeBeleg: createAction<WarenverprobungState, void, void>('mdeWarenverprobung/removeBeleg'),
  createBeleg: createAction<WarenverprobungState, { id: number, variante: 'WARENUEBERNAHME' | 'BESTELLUNG', bezugsnummer: string }[], WarenverprobungKopf>('mdeWarenverprobung/createBeleg'),
}

const warenverprobungStore: CustomStore<
  WarenverprobungState,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  mutations,
  getters,
  actions,
  feature: {
    initialState: {
      kopf: undefined,
      belege: [],
    },
    mutations: [
      mutate(mutations.setKopf, ({ params: kopf, state }) => ({
        ...state,
        kopf: !kopf ? undefined : kopf
      })),
      mutate(mutations.setBelege, ({ params: belege, state }) => ({
        ...state,
        belege
      })),
      mutate(mutations.setBezugsnummer, ({ params: bezugsnummer, state }) => ({
        ...state,
        bezugsnummer
      })),
      mutate(mutations.setItemScanResult, ({ params, state }) => ({
        ...state,
        itemScanResult: params
      })),
    ],
    getters: [
      select(getters.kopf, ({ state }) => state?.kopf),
      select(getters.belege, ({ state, params }) => state?.belege ?? []),
      select(getters.itemScanResult, ({ state }) => state?.itemScanResult),
      select(getters.belegCount, ({ state }) => (state?.belege?.length ?? 0)),
    ],
    actions: [
      on(actions.loadKopf, async ({ injector, featureName, commit, get }) => {
        const service = injector.get(WarenverprobungService);
        const kopf = await lastValueFrom(service.loadKopf());
        commit(featureName, mutations.setKopf, kopf);
        return kopf;
      }),
      on(actions.removeBeleg, async ({ injector, featureName, commit, get }) => {
        const service = injector.get(WarenverprobungService);
        await lastValueFrom(service.removeBeleg());
        commit(featureName, mutations.setKopf, undefined);
        commit(featureName, mutations.setBelege, []);
        commit(featureName, mutations.setItemScanResult, undefined);
      }),
      on(actions.createBeleg, async ({ injector, params, commit, featureName }) => {
        const service = injector.get(WarenverprobungService);
        const belegKopf = await lastValueFrom(service.createBeleg(params));
        commit(featureName, mutations.setKopf, belegKopf);
        commit(featureName, mutations.setBelege, belegKopf?.belege ?? []);
        return belegKopf;
      }),
      on(actions.abschluss, async ({ injector, featureName, commit, get }) => {
        const service = injector.get(WarenverprobungService);
        const bewes = get(SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE, WarenverprobungBeweStore.getters.all)

        const artikel = bewes.map(bewe => ({
          menge: bewe.menge,
          artikel: {
            id: bewe.artikel.id,
            artikelnummer: bewe.artikel.artikelnummer,
            idarteh: bewe.einheit.id
          },
        }));

        await lastValueFrom(service.abschluss({ artikel }));

        commit(featureName, mutations.setKopf, undefined);
        commit(featureName, mutations.setBelege, []);
        commit(featureName, mutations.setBezugsnummer, undefined);
        commit(featureName, mutations.setItemScanResult, undefined);
        commit(SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BELEGE, WarenverprobungBelegeStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_BEWE, WarenverprobungBeweStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_WARENVERPROBUNG_ARTIKEL_DETAILS, WarenverprobungArtikelDetailStore.mutations.clear);
      })
    ],
  }
}

export const WarenverprobungStore = new StoreBuilder(warenverprobungStore)
  .value;
