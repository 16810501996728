<h4>{{ title }}</h4>
<soft-dynamic-form
  [definition]="$any(definition)"
  (formSubmit)="close($event)"
  #form
></soft-dynamic-form>
<div class="c row end">
  <button
    [disabled]="form?.form()?.errors"
    class="soft-button primary mt-2"
    (click)="form.formRef()?.ngSubmit?.emit()"
  >
    Hinzufügen
  </button>
</div>
