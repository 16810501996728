<div class="flex w-full justify-between flex-wrap mt-4">
  <div class="flex flex-col">
    <h1 class="text-2xl font-bold">
      {{ '#SUBKUNDEN.PAGES.SUBKUNDEN.HEADER' | translate }}
    </h1>
  </div>
  <soft-input
    class="w-full sm:w-[30vw]"
    placeholder="{{ '#SUBKUNDEN.PAGES.SUBKUNDEN.FILTER' | translate }}"
    (valueChange)="filter$.next($event ?? '')"
  ></soft-input>
</div>
<hr class="my-4" />
<div class="flex flex-row justify-between">
  <div class="flex flex-col justify-end">
  <span class="text-xs font-bold"
  >{{ subkundenLength$ | async }}
    {{ '#SUBKUNDEN.PAGES.SUBKUNDEN.SUBKUNDEN' | translate }}</span
  >
    <span class="text-xs color-light"
    >{{ '#SUBKUNDEN.PAGES.SUBKUNDEN.ACTIVE' | translate }}
      {{ subkundenActiveLength$ | async }}</span
    >
  </div>
  <div class="flex flex-col justify-end">
    <label class="soft-label top">
      {{ '#SUBKUNDEN.PAGES.SUBKUNDEN.ACTIVE_ONLY' | translate }}
    </label>
    <soft-toggle-switch
      class="justify-end mt-1"
      [value]="activeOnly$ | async"
      (valueChange)="activeOnly$.next($event ?? false)">
    </soft-toggle-switch>
  </div>
</div>
<ul
  *ngIf="!(loading$ | async); else loadingTemplate"
  class="grid gap-4 mt-2 custom-grid"
>
  <li
    *ngFor="let subkunde of subkunden$ | async"
    class="soft-card flex flex-col cursor-pointer selected border justify-between hover:bg-hover"
    [ngClass]="
      (selected$ | async)?.id === subkunde.id
        ? '!border-primary !bg-selected'
        : '!border-primary-50'
    "
    (click)="selected$.next(subkunde)"
  >
    <div class="flex justify-between text-sm">
      <span class="color-light">{{ subkunde.kostenstelle }}</span>
      <span>&nbsp;</span>
      <span
        *ngIf="!subkunde.aktiv"
        class="text-xs text-danger font-bold uppercase"
        >{{ '#SUBKUNDEN.PAGES.SUBKUNDEN.INACTIVE' | translate }}</span
      >
    </div>
    <div class="flex flex-col">
      <span class="font-bold">{{ subkunde.name }}</span>
      <span class="text-sm"
        >{{ '#SUBKUNDEN.PAGES.SUBKUNDEN.CREATED' | translate }}
        {{ subkunde.anlagedatum | format: 'DATE.DEFAULT' }}</span
      >
    </div>
  </li>
</ul>

<ng-template #loadingTemplate>
  <ul class="grid gap-4 mt-2 custom-grid">
    <li
      *softRepeat="12"
      class="soft-card flex flex-col cursor-pointer selected border"
    >
      <div class="flex justify-between text-sm">
        <span class="skeleton w-16 h-3"></span>
      </div>
      <div class="skeleton w-full h-4 mt-1"></div>
      <div class="skeleton w-1/2 h-3 mt-1"></div>
    </li>
  </ul>
</ng-template>
