import { Component, Type } from '@angular/core';
import { Observable } from "rxjs";

export interface Settings {
  title?: string;
  component?: Type<any> | null;
  key: string;
  default: object;
  icon?: string;
  priority?: number;
  group?: string;
  visible?: Observable<boolean>;
}
