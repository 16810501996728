import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal, UiCoreModule} from "@softline/ui-core";
import {BehaviorSubject, map, Observable, Subscription} from "rxjs";
import {Dictionary} from "@softline/core";
import {MengenEingabeEinheit} from "../../types/mengen-eingabe-einheit";
import {KzAusgabeStrategy} from "./strategies/kz-ausgabe.strategy";
import {KzAusgabeNStrategy} from "./strategies/kzausgabe-n/kz-ausgabe-n.strategy";
import {KzAusgabe1Strategy} from "./strategies/kzausgabe-1/kz-ausgabe-1.strategy";
import {KzAusgabeAllStrategy} from "./strategies/kzausgabe-all/kz-ausgabe-all";
import {KzAusgabe2Strategy} from "./strategies/kzausgabe-2/kz-ausgabe-2.strategy";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";

export interface MengenEingabeDialogResult {
  umrechnung: {
    menge: number;
    einheit: MengenEingabeEinheit;
  }
  anzeige: {
    menge: number;
    einheit: MengenEingabeEinheit;
  }
}

export interface MengenEingabeDialogParams {
  einheiten: MengenEingabeEinheit[];
  umrechnungseinheit: MengenEingabeEinheit;
  anzeigeeinheit: MengenEingabeEinheit;
  titel: string;
  info?: string;
  initial?: { [idarteh: number]: number };
}

@Component({
  selector: 'soft-mengen-eingabe-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './mengen-eingabe-dialog.component.html',
  styleUrls: ['./mengen-eingabe-dialog.component.scss'],
})
export class MengenEingabeDialogComponent implements OnInit, OnDestroy, Modal<MengenEingabeDialogResult> {

  private subscription?: Subscription;

  private _einheiten: MengenEingabeEinheit[] = [];
  private readonly summenMap$ = new BehaviorSubject<Dictionary<number | null>>({});

  close!: (result: MengenEingabeDialogResult) => void;

  @Input() titel!: string;
  @Input() info?: string;
  @Input()
  get einheiten(): MengenEingabeEinheit[] {
    return this._einheiten;
  }
  set einheiten(value: MengenEingabeEinheit[]) {
    this._einheiten = value;

    for (const einheit of value) {
      this.form.addControl(einheit.id + '', new FormControl(null,
        [Validators.pattern("^[0-9]*$")]));
    }
  }

  // Auf diese Einheit wird die Gesamtanzahl umgerechnet (im DialogResult)
  @Input() umrechnungseinheit!: MengenEingabeEinheit;
  // Einheit fuer Summenanzeige
  @Input() anzeigeeinheit!: MengenEingabeEinheit;

  set initial(dict: { [id: number]: number }) {
    this.summenMap$.next(dict);
    this.form.patchValue(dict, { emitEvent: false, onlySelf: true });
  }

  form = new FormGroup({});

  readonly summe$: Observable<MengenEingabeDialogResult> = this.summenMap$.pipe(
    map(dict => {
      let summe = 0;

      for (const [id, anzahl] of Object.entries(dict)) {
        const einheit = this.einheiten.find(o => o.id === +id);

        if (!einheit) {
          continue;
        }

        summe += this.summeBerechnen(einheit, anzahl ?? 0);
      }

      return {
        anzeige: {
          menge:summe / this.anzeigeeinheit.faktor,
          einheit: this.anzeigeeinheit
        },
        umrechnung: {
          menge: summe / this.umrechnungseinheit.faktor,
          einheit: this.umrechnungseinheit
        }
      }
    })
  );

  constructor(private cdref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.subscribe(value => {
      setTimeout(() => this.updateForm(value), 10);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }

  registerCloseHandler(handler: (result: MengenEingabeDialogResult) => void): void {
    this.close = handler;
  }

  trackByFn(_: number, einheit: MengenEingabeEinheit): number {
    return einheit.id
  }
  updateForm(formValue: Dictionary<number | null>) {
    const dict: Dictionary<number | null> = formValue
    const baseArteh = this.einheiten.find(o => o.id === this.umrechnungseinheit.id);

    if (!baseArteh)
        return dict;

    let changes: { arteh: MengenEingabeEinheit; value: number | null }[] = []

    for (const [idarteh, input] of Object.entries(dict).reverse()) {
        const arteh = this.einheiten.find(o => o.id === +idarteh);

        if (!arteh)
            continue;

        changes.push({ value: input === 0 ? null : (input ?? null), arteh })
    }

    console.log(changes);

    // When there is a value in the form that is not a "Ausgabe Einheit" then we
    // recalculate the complete form and only use the non-ausgabe einheit value (others will be ignored)
    if (changes.find(o => o.arteh.kzausgabe === 'N' && o?.value !== null)) {
        const onlyNonAusgabe = changes.filter(o => o.arteh.kzausgabe === 'N');
        const onlyAusgabe = changes.filter(o => o.arteh.kzausgabe !== 'N').map(o => ({ ...o, value: null }));
        changes = [...onlyAusgabe, ...onlyNonAusgabe];
    }

    const strategy = this.getStrategy(this.einheiten);
    console.log(strategy);

    let result: Dictionary<number | null> = {};

    for (const { arteh, value } of changes) {
        const calculationResult = strategy.calculate({
            artehs: this.einheiten,
            dictionary: result,
            inputArteh: arteh,
            inputValue: value,
            baseArteh: this.umrechnungseinheit
        });

        result = calculationResult;
        console.log('Calculation-Result forLoop: ', result);
    }

    this.summenMap$.next(result);
    this.cdref.markForCheck()

    for (const einheit of this.einheiten) {
        this.form.removeControl(einheit.id + '', {emitEvent: false});

        const value = result[einheit.id]
        this.form.addControl(einheit.id + '', new FormControl(value ? value : 0,
          [Validators.pattern("^[0-9]*$")]), { emitEvent: false });
    }

    setTimeout(() => this.form.patchValue(result, { emitEvent: false, onlySelf: true }), 1);
    this.cdref.detectChanges()
    return result;
  }

  private getStrategy(einheiten: MengenEingabeEinheit[]): KzAusgabeStrategy {
    const hasKzausgabe1Arteh = !!einheiten.find(o => o.kzausgabe === '1')
    const hasKzausgabe2Arteh = !!einheiten.find(o => o.kzausgabe === '2')

    if (hasKzausgabe2Arteh && hasKzausgabe1Arteh) {
      return KzAusgabeAllStrategy
    } else if (hasKzausgabe2Arteh && !hasKzausgabe1Arteh) {
      return KzAusgabe2Strategy
    } else if (hasKzausgabe1Arteh && !hasKzausgabe2Arteh) {
      return KzAusgabe1Strategy
    } else {
      return KzAusgabeNStrategy
    }
  }

  private round(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  private summeBerechnen(einheitParam: MengenEingabeEinheit, menge: number): number {
    if (menge < 0)
      menge = Math.abs(menge);

    return this.round(menge * einheitParam.faktor);
  }
}
