import { inject, Injectable } from "@angular/core";
import { Store } from "@softline/core";
import { ActivatedRoute, Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithActivatedRoute = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class ActivatedRouteMixin extends Base {

    route = inject(ActivatedRoute);
    routeParams = toSignal(this.route.params, {initialValue: {}});
    routeQueryParams = toSignal(this.route.queryParams, {initialValue: {}});
    routeFragment = toSignal(this.route.fragment);
    routeData = toSignal(this.route.data);

    constructor(...args: any[]) {
      super(...args);
    }
  }
  return ActivatedRouteMixin;
}
