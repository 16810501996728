
<div class="flex flex-col w-full text-center lg:hidden bg-surface">
  <div class="w-full bg-surface-tertiary h-2.5 border border-solid border-secondary-500">
    <div class="bg-primary h-2" [style]="{width: visiblePercent()+'%'}"></div>
  </div>
</div>

<div class="flex-col w-full text-center hidden lg:flex bg-surface">
  <div class="flex items-center justify-evenly w-full relative mt-6">
    <div class="h-[2px] w-full bg-surface-tertiary dark:bg-surface absolute left-0 right-0 inline-block">&nbsp;</div>
    <div class="h-[2px] w-full bg-primary absolute left-0 inline-block" [style]="{width: visiblePercent()+'%'}">&nbsp;</div>
    <div class="w-full absolute -top-6 bottom-3 left-0 right-0 inline-block bg-surface">&nbsp;</div>

    <div *ngFor="let step of visibleSteps(); let i = index" class="relative flex flex-col justify-center items-center z-10">
      <div [ngClass]="visibleIndex() < i ? 'border-input bg-surface-tertiary' : visibleIndex() === i ? 'border-primary bg-surface' : 'border-primary bg-surface'"
           class="rounded-full h-6 w-6 flex items-center justify-center border-solid border">
        <div class="h-3 w-3 bg-primary rounded-full inline-block" *ngIf="visibleIndex() === i">&nbsp;</div>
        <i class="fa-regular fa-check text-primary p-1 mt-[1px] text-xs" *ngIf="visibleIndex() > i"></i>
      </div>

      <div
        *ngIf="showLabels() || visibleIndex() === i"
        class="-top-6 mb-2 absolute break-none whitespace-nowrap text-xs flex flex-row flex-nowrap"
        [ngClass]="visibleIndex() === i ? 'opacity-100 text-primary font-bold' : 'opacity-40 font-semibold'">
        @if (step.header) {
          <soft-icon [name]="step?.icon" class="mr-1"></soft-icon>
        }
        {{ step?.header | translate }}
      </div>
    </div>
  </div>
</div>
