import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'soft-dynamic-object-value',
  templateUrl: './object.component.html',
  styleUrls: ['./object.component.scss'],
})
export class ObjectComponent implements OnInit {
  @Input() value?: any;
  @Input() definition: any;

  constructor() {}

  ngOnInit(): void {}
}
