import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import { DateService, equals, isDefined } from "@softline/core";
import moment from 'moment';
import {map} from 'rxjs/operators';
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";

@Component({
  selector: 'lib-header-paging',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './header-paging.component.html',
  styleUrls: ['./header-paging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderPagingComponent {

  private date$ = new BehaviorSubject<string>(this.dateService.today());
  @Input() get date(): string | null | undefined {
    return this.date$.value;
  }
  set date(value: string | null | undefined) {
    if (equals(this.date$.value, value))
      return;
    else if(!isDefined(value))
      value = this.dateService.today();
    this.date$.next(value);
  }

  private steps$ = new BehaviorSubject<'months' | 'years'>('years');
  @Input() get steps(): 'months' | 'years' {
    return this.steps$.value;
  }
  set steps(value: 'months' | 'years') {
    if (equals(this.steps$.value, value))
      return;
    this.steps$.next(value);
  }

  @Input() title: string | null | undefined = '';
  @Input() subtitle: string | null | undefined = '';
  @Input() showIcons = true;

  @Output() next = new EventEmitter<string>();
  @Output() previous = new EventEmitter<string>();


  previousLabel$ = combineLatest([this.date$, this.steps$])
    .pipe(
      map(([date, steps]) => {
        const m = moment(date).add(-1, steps);

        switch (steps) {
          case 'months':
            return `${m.format('MMMM')} ${m.format('YYYY')}`;
          case 'years':
            return `${m.format('YYYY')}`;
        }
      })
  );

  nextLabel$ = combineLatest([this.date$, this.steps$])
    .pipe(
      map(([date, steps]) => {
        let m = moment(date);
        m = m.add(1, steps);

        switch (steps) {
          case 'months':
            return `${m.format('MMMM')} ${m.format('YYYY')}`;
          case 'years':
            return `${m.format('YYYY')}`;
        }
      })
    );

  canNext$ = combineLatest([this.date$, this.steps$])
    .pipe(
      map(([date, steps]) => {
        const m = moment(date);
        const today = moment(this.dateService.today());
        switch (steps) {
          case 'months':
            return m.get('year') < today.get('year')
              || (m.get('year') === today.get('year') && m.get('month') < today.get('month'));
          case 'years':
            return m.get('year') < today.get('year');
        }
      })
    );

  currentLabel$ = combineLatest([this.date$, this.steps$])
    .pipe(
      map(([date, steps]) => {
        const m = moment(date);
        switch (steps) {
          case 'months':
            return `${m.format('MMMM')} ${m.format('YYYY')}`;
          case 'years':
            const end = m.clone().add(1, 'years').add(-1, 'days');
            return `${m.format('MMMM')} ${m.format('YYYY')} - ${end.format('MMMM')} ${end.format('YYYY')}`;
        }
      })
    );

  constructor(private dateService: DateService) { }

  onNext(): void {
    const value = moment(this.date)
      .add(1, this.steps)
      .toISOString(true);
    this.next.emit(value);
  }

  onPrevious(): void {
    const value = moment(this.date)
      .add(-1, this.steps)
      .toISOString(true);
    this.next.emit(value);
  }
}
