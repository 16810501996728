import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {Dictionary, Store} from '@softline/core';
import {SOFTLINE_FEATURE_SETTINGS} from '../application.shared';
import * as SettingsStore from './settings.store';

export const settingsResolver: ResolveFn<Dictionary<object>> = async () => {
  const store = inject(Store);
  return await store.dispatch(
    SOFTLINE_FEATURE_SETTINGS,
    SettingsStore.actions.load
  );
}
