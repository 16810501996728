import { Language } from "./languages";
import { StoreFeature } from "../store/store";
import { createAction, createGetter, createMutation, mutate, on, select } from "../store/factories";
import { SOFTLINE_CONFIG_DEFAULT_LANGUAGE } from "./language.shared";
import { LanguageService } from "./language.service";
import { isDefined } from "../functions/is-defined.function";

export namespace LanguageStore {
  export interface State {
    selected: string;
    languages: Language[];
  }

  export const mutations = {
    init: createMutation<State, {languages: Language[], selected: string}>('init'),
    select: createMutation<State, string>('select'),
  }

  export const actions = {
    load: createAction<State, string>('load'),
    set: createAction<State, string>('select')
  }

  export const getters = {
    selected: createGetter<State, Language>('selected'),
    languages: createGetter<State, Language[]>('languages'),
  }

  export const feature: StoreFeature<State> = {
    initialState: {
      selected: "de",
      languages: []
    },
    mutations: [
      mutate(mutations.init, ({state, params}) => ({...state, ...params})),
      mutate(mutations.select, ({state, params}) => {
        const index = state.languages.findIndex(o => o.code === params);
        if(index === -1)
          throw new Error(`[LanguageStore] select: No language with code ${params} registered`);
        return {...state, selected: params};
      }),
    ],
    actions: [
      on(actions.load, async ({state, params, injector, featureName, commit}) => {
        const service = injector.get(LanguageService);
        const defaultLanguage = injector.get(SOFTLINE_CONFIG_DEFAULT_LANGUAGE);
        let language: string;
        try {
          language = await service.load();
        }
        catch (e) {
          language = defaultLanguage;
        }
        language = getRegisteredLanguageCode(language, state.languages, defaultLanguage);
        commit(featureName, mutations.select, language);
      }),
      on(actions.set, async ({state, params, injector,featureName, commit}) => {
        const service = injector.get(LanguageService);
        const defaultLanguage = injector.get(SOFTLINE_CONFIG_DEFAULT_LANGUAGE);
        try {
          const language = getRegisteredLanguageCode(params, state.languages, defaultLanguage);
          commit(featureName, mutations.select, language);
          await service.save(language);
        }
        catch (e) {
          throw new Error('[LanguageStore]: Cannot save language')
        }
      })
    ],
    getters: [
      select(getters.languages, ({state}) => state.languages),
      select(getters.selected, ({state}) => state.languages.find(o => o.code === state.selected))
    ]
  }
}

function getRegisteredLanguageCode(language: string, languages: Language[], defaultLanguage: string): string {
  let code = languages.find(o => o.code === language)?.code;
  if(!isDefined(code)) {
    const languageCode = language.split('-')[0];
    code = languages.find(o => o.code === languageCode)?.code;
    if(!isDefined(code)) {
      code = languages.find(o => o.code.split('-')[0] === languageCode)?.code;
      if(!isDefined(code)) {
        code = defaultLanguage;
      }
    }
  }
  return code;
}
