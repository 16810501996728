import { computed, inject, Injectable, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { CollectionStore2, NestedStore2Feature } from '@softline/core';

type Constructor<T extends {}> = new(...args: any[]) => T;

type CollectionMixinParams<T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>>
  = InjectionToken<CollectionStore2<T>> | {store: InjectionToken<TStore>, feature: (o: TStore) => CollectionStore2<T>};

function hasSubFeature<T extends object, V extends NestedStore2Feature<any> = NestedStore2Feature<any>>(value: object): value is {store: InjectionToken<CollectionStore2<T>>, feature: (o: V) => CollectionStore2<T>} {
  return 'store' in value;
}

export const WithCollection = <T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>, TBase extends Constructor<{}> = Constructor<{}>>(store: CollectionMixinParams<T, TStore>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class CollectionMixin extends Base implements OnInit, OnDestroy {

    #store: CollectionStore2<T>;

    all = computed(() => this.#store.all());
    count = computed(() => this.#store.count());
    dict = computed(() => this.#store.dict());

    constructor(...args: any[]) {
      super(...args);

      if(hasSubFeature(store)) {
        const injectedStore = inject(store.store);
        this.#store = store.feature(injectedStore) as any;
      }
      else
        this.#store = inject(store as any);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
    }

    ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return CollectionMixin;
}
