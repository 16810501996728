import { TelemetryService } from "../services/telemetry.service";
import { isDefined } from "../../functions/is-defined.function";
import { MethodCall } from "../types/telemetry";

export function Trace(options?: {callArgs?: boolean, message?: string, stack?: boolean}) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]) {

      // console.log(`Calling method ${propertyKey} with arguments: ${JSON.stringify(args)}`);
      // console.log(`constructor: ${JSON.stringify(target.constructor.name)}`);
      let call: MethodCall | undefined;

      const tracer: TelemetryService = window['tracer'];
      const started = isDefined(tracer.id);
      if(!started)
        tracer.start();

      if(target.constructor)
        tracer.addCall({
          class: target.constructor.name,
          method: propertyKey,
          args: options?.callArgs !== false ? args : undefined
        })

      if(options?.message)
        tracer.addMessage(options.message)

      let result = originalMethod.apply(this, args);

      if(result instanceof Promise)
        result = await result

      // console.log(`Method ${propertyKey} returned: ${JSON.stringify(result)}`);
      if(!started)
        tracer.stop();
      return result;
    };
    return descriptor;
  }
}

export function TraceCall(options?: {callArgs?: boolean}) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]) {

      const tracer: TelemetryService = window['tracer'];

      if(target.constructor)
        tracer.addCall({
          class: target.constructor.name,
          method: propertyKey,
          args: options?.callArgs !== false ? args : undefined
        })

      let result = originalMethod.apply(this, args);

      if(result instanceof Promise)
        result = await result

      return result;
    };
    return descriptor;
  }
}

export function TraceMessage(message: string) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]) {

      const tracer: TelemetryService = window['tracer'];

      tracer.addMessage(message)

      let result = originalMethod.apply(this, args);

      if(result instanceof Promise)
        result = await result

      return result;
    };
    return descriptor;
  }
}
