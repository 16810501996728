<h4 class="text-xl font-semibold mt-3 ml-3">
  {{ title | translate | interpolate: params }}
</h4>

<p *ngIf="subtitle" class="mb-1 mt-1 ml-3 mr-3 border-box color-light">
  {{ subtitle | translate | interpolate: params }}
</p>

<p *ngIf="template" class="mb-1 mt-1 ml-3 mr-3 border-box">
  <ng-container *ngTemplateOutlet="template; context: {close: close, params: (params ?? null)}"></ng-container>
</p>
