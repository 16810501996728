<div class="px-3">
  <h4 class="text-xl font-semibold mt-3">
    {{ '#SOFTAPPS_CORE.TEMPLATES.LOAD_TEMPLATE' | translate }}
  </h4>

  <soft-tab-group class="mt-4">
    <soft-tab [title]="'Vorlagen'">
      <div class="flex flex-col sm:flex-col-reverse gap-4 mb-4 mt-4">
        <div class="flex flex-col gap-4 overflow-y-auto max-h-[60vh]">
          @for(template of filter.filteredItems() | sort:'title':'ASC'; track
          'id'; let last = $last) {
          <ng-container
            *ngTemplateOutlet="listTemplate; context: { template: template }"
          ></ng-container>
          } @empty {
          <div class="flex flex-row item-center justify-center h-16 mt-4">
            <span class="text-light">{{
              '#SOFTAPPS_CORE.TEMPLATES.NO_TEMPLATES' | translate
            }}</span>
          </div>
          }
        </div>
        <soft-filter-input [items]="$templates()" #filter></soft-filter-input>
      </div>
    </soft-tab>
    <soft-tab [title]="'Letzte Abfragen'">
      <div class="flex flex-col gap-4 overflow-y-auto max-h-[60vh] mb-4 mt-4">
        @for(template of $queryHistory() | sort:'timestamp':'DESC'; track 'id';
        let last = $last) {
        <ng-container
          *ngTemplateOutlet="listTemplate; context: { template: template }"
        ></ng-container>
        } @empty {
        <div class="flex flex-row item-center justify-center h-16 mt-4">
          <span class="text-light">{{
            '#SOFTAPPS_CORE.TEMPLATES.NO_TEMPLATES' | translate
          }}</span>
        </div>
        }
      </div>
    </soft-tab>
  </soft-tab-group>
</div>

<ng-template let-template="template" #listTemplate>
  <div class="flex flex-row justify-between">
    <soft-template-card
      [definition]="$definition()"
      [template]="template"
      class="cursor-pointer hover:bg-hover"
      (click)="onSubmit(template)"
    ></soft-template-card>
    <div
      class="flex justify-center items-center bg-surface-secondary cursor-pointer hover:bg-hover border w-16"
      (click)="onEllipsisClick(template)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 fill-current"
        viewBox="0 0 128 512"
      >
        <path
          d="M64 384a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm0-160a32 32 0 1 0 0 64 32 32 0 1 0 0-64zM96 96A32 32 0 1 0 32 96a32 32 0 1 0 64 0z"
        />
      </svg>
    </div>
  </div>
</ng-template>
<ng-template let-template="template" #chooseTemplate>
  <soft-template-card
    [definition]="$definition()"
    [template]="template"
  ></soft-template-card>
</ng-template>

<ng-template let-close="close" let-params="params" #editTemplate>
  <soft-template-card
    [definition]="$definition()"
    [template]="params.template"
  ></soft-template-card>
  <soft-dynamic-form
    class="mt-4"
    [value]="params.template.value"
    [definition]="$any($definition())"
    #editForm
  ></soft-dynamic-form>
  <button
    class="soft-button accent h-16 w-full mt-4"
    (click)="close(editForm.form().value)"
  >
    {{ '#SOFTAPPS_CORE.TEMPLATES.SAVE' | translate }}
  </button>
</ng-template>
