import { Pipe, PipeTransform } from '@angular/core';
import { isDefined } from "@softline/core";

@Pipe({
  name: 'isDefined',
  pure: true,
  standalone: true
})
export class IsDefinedPipe<T> implements PipeTransform {
  transform(value: T | null | undefined): value is T {
    return typeof value !== 'undefined' && value !== null;
  }
}
