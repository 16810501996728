import {
  createAction,
  createGetter,
  createMutation,
  CustomStore,
  mutate,
  on,
  select,
  StoreBuilder
} from "@softline/core";
import {RetourenService} from '../services/retouren.service';
import {lastValueFrom} from 'rxjs';
import {SOFTLINE_FEATURE_MDE_RETOUREN_ARTIKEL_DETAILS, SOFTLINE_FEATURE_MDE_RETOUREN_BEWE} from "../retouren.shared";
import {RetourenBeweStore} from './retouren-bewe.store';
import {ItemScan} from '@softline/application';
import {RetourenKopf} from '../data/retouren-kopf';
import {RetourenArtikelDetailStore} from './retouren-artikel-detail.store';

export interface RetourenState {
  kopf?: RetourenKopf | null
  lieferant?: { id: number; kurzbez: string; nummer: number; subnummer: number } | null
  belegdatum?: any | null
  endeEtikett?: string | null;
  itemScanResult?: ItemScan
}

const mutations = {
  setKopf: createMutation<RetourenState, RetourenKopf | undefined>('mdeRetouren/setKopf'),
  setLieferant: createMutation<RetourenState, RetourenState['lieferant'] | undefined>('mdeRetouren/setLieferant'),
  setItemScanResult: createMutation<RetourenState, ItemScan | undefined>('mdeRetouren/setItemScanResult'),
  setEndeEtikett: createMutation<RetourenState, string | undefined>('mdeRetouren/setEndeEtikett'),
}

const getters = {
  kopf: createGetter<RetourenState, RetourenKopf | null | undefined>('mdeRetouren/getKopf'),
  lieferant: createGetter<RetourenState, RetourenState['lieferant'] | null | undefined>('mdeRetouren/getLieferant'),
  itemScanResult: createGetter<RetourenState, ItemScan | undefined>('mdeRetouren/getItemScanResult'),
  endeEtikett: createGetter<RetourenState, string | undefined>('mdeRetouren/getEndeEtikett'),
}

const actions = {
  abschluss: createAction<RetourenState, { schwundlisteDrucken: boolean, differenz: number }, void>('mdeRetouren/abschluss'),
  loadKopf: createAction<RetourenState, void, RetourenKopf | undefined | null>('mdeRetouren/loadKopf'),
  removeAll: createAction<RetourenState, void, void>('mdeRetouren/removeAll'),
}

const retourenStore: CustomStore<
  RetourenState,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  mutations,
  getters,
  actions,
  feature: {
    initialState: {
      kopf: null,
      lieferant: null,
      endeEtikett: null
    },
    mutations: [
      mutate(mutations.setKopf, ({ params: kopf, state }) => {
        return {
          ...state,
          kopf,
          lieferant: !!kopf && kopf?.lieferant ? kopf?.lieferant : null
        }
      }),
      mutate(mutations.setLieferant, ({ params: lieferant, state }) => ({
        ...state,
        lieferant
      })),
      mutate(mutations.setEndeEtikett, ({ params: endeEtikett, state }) => ({
        ...state,
        endeEtikett
      })),
      mutate(mutations.setItemScanResult, ({ params, state }) => ({
        ...state,
        itemScanResult: params
      }))
    ],
    getters: [
      select(getters.kopf, ({ state }) => state?.kopf),
      select(getters.lieferant, ({ state }) => state?.lieferant),
      select(getters.itemScanResult, ({ state }) => state?.itemScanResult),
      select(getters.endeEtikett, ({ state }) => state?.endeEtikett),
    ],
    actions: [
      on(actions.loadKopf, async ({ injector, featureName, commit, get }) => {
        const service = injector.get(RetourenService);
        const result = await lastValueFrom(service.getKopf());
        commit(featureName, mutations.setKopf, result);
        return result ?? null
      }),
      on(actions.removeAll, async ({ injector, featureName, get, commit }) => {
        const service = injector.get(RetourenService);
        try {
          await lastValueFrom(service.removeAll());
          commit(SOFTLINE_FEATURE_MDE_RETOUREN_ARTIKEL_DETAILS, RetourenArtikelDetailStore.mutations.clear);
          commit(SOFTLINE_FEATURE_MDE_RETOUREN_BEWE, RetourenBeweStore.mutations.clear);
          commit(featureName, mutations.setKopf, null);
          commit(featureName, mutations.setItemScanResult, null);
        } catch (e) {
          console.log(e);
        }
      }),
      on(actions.abschluss, async ({ injector, featureName, params, commit }) => {
        const service = injector.get(RetourenService);
        await lastValueFrom(service.abschluss());
        commit(SOFTLINE_FEATURE_MDE_RETOUREN_ARTIKEL_DETAILS, RetourenArtikelDetailStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_RETOUREN_BEWE, RetourenBeweStore.mutations.clear);
        commit(featureName, mutations.setKopf, null);
        commit(featureName, mutations.setLieferant, null);
        commit(featureName, mutations.setItemScanResult, null);
      }),
    ],
  }
}

export const RetourenStore = new StoreBuilder(retourenStore)
  .value;
