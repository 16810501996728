import { GetResourceService, ResourceService } from '../../data/abstraction';
import {
  Id,
  LoadCollectionItemParameters,
  LoadCollectionParameters,
  ReadonlyCollectionRepository
} from '../abstraction';
import { Observable } from 'rxjs';
import { createHttpResourceLocation, HttpResourceLocation } from '../../data/specialized/http/http.location';

export class ReadonlyHttpCollectionRepository<TResult, TManyResult> implements ReadonlyCollectionRepository<TResult, TManyResult> {

  constructor(protected service: ResourceService<HttpResourceLocation>,
              protected url: string) {
  }

  loadItem(id: Id, params: LoadCollectionItemParameters): Observable<TResult> {
    const location = createHttpResourceLocation(
      { url: this.url, pathParams: {id: id, ...params.pathParams } }
    );
    return this.service.get(location);
  }

  loadMany(params?: LoadCollectionParameters): Observable<TManyResult> {
    const location = createHttpResourceLocation(
      { url: this.url, pathParams: params?.pathParams, queryParams: {...(params?.query ?? {}), limit: params?.limit, offset: params?.offset, sort: params?.sort } }
    );
    return this.service.get(location);
  }
}
