@switch (type()) {
  @case ('fa5') {
    <i [class]="name()"></i>
  }
  @case ('fa6') {
    <i [class]="name()"></i>
  }
  @case ('svg') {
    <i [innerHTML]="name()"></i>
  }
}
