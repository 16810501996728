/*
 * Public API Surface of password
 */

export * from './lib/pages/forgot-password/forgot.component';
export * from './lib/pages/forgot-password/user-form/user-form.component';
export * from './lib/pages/forgot-password/reset-form/reset-form.component';

export * from './lib/settings/components/settings.component';

export * from './lib/password.shared';
export * from './lib/password.providers';
export * from './lib/password.routes';
