import { InjectableStore2Feature, Store2Feature } from '@softline/core';
import { computed, Injectable } from '@angular/core';

export interface TitleState {
  title: string;
  subTitle: string;
  params: object | null;
}

@Injectable()
export class TitleStore2 extends Store2Feature<TitleState> {

  name = 'title';

  title = computed(() => this.state().title);
  subtitle = computed(() => this.state().subTitle);
  params = computed(() => this.state().params);

  constructor() {
    super();
  }

  setTitle(title: string | { title: string; params?: object | null }) {
    if(typeof title === 'string')
      this.commitPatch({ title })
    else
      this.commitPatch(title)
  }

  setSubtitle(subTitle: string | { subTitle: string; params?: object | null }) {
    if(typeof subTitle === 'string')
      this.commitPatch({ subTitle })
    else
      this.commitPatch(subTitle)
  }

  setParams(params: object | null) {
    this.commitPatch({ params })
  }

  override getDefaultState(): TitleState {
    return {
      title: '',
      subTitle: '',
      params: null
    };
  }
}
