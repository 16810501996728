import { Component, Inject, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from "@softline/dynamic";

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './verkaufsartwerkstatt.list-view.html',
  styleUrls: ['./verkaufsartwerkstatt.list-view.scss'],
})
export class VerkaufsartwerkstattListView implements FieldOkItemComponent<any>{

  @Input() item: any;

  constructor() {
  }
}
