import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { MetaObject } from '@softline/dynamic';

type Constructor<T> = new(...args: any[]) => T;

export const WithMeta = <T extends object, TBase extends Constructor<{}>>(meta: MetaObject<T>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class MetaMixin extends Base implements OnInit, OnDestroy {
    meta = meta;

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
    }

    ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

  }
  return MetaMixin;
}
