import { inject, Injectable, Injector, OnDestroy, OnInit, runInInjectionContext, Signal } from '@angular/core';
import { MenuItemStore2 } from '../menu-item.store';
import { MenuItem } from '../menu-item';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { distinct } from 'rxjs';

type Constructor<T extends {}> = new(...args: any[]) => T;
export const WithMenuItems = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class MenuItemsMixin extends Base implements OnInit, OnDestroy {

    #SET_NAME = this.constructor.name+'MenuItems';
    #injector = inject(Injector);
    #menuItemStore = inject(MenuItemStore2);

    abstract menuItems: Signal<MenuItem[]>;

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnInit(): void {
      if(super['ngOnInit'])
        super['ngOnInit']();
      runInInjectionContext(this.#injector, () => {
        toObservable(this.menuItems)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {
            this.#menuItemStore.set(this.#SET_NAME, o ?? []);
          })
      });
    }

    ngOnDestroy(): void {
      this.#menuItemStore.remove(this.#SET_NAME);

      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

  }
  return MenuItemsMixin;
}
