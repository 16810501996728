import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@softline/core';
import { I18nModule } from '../i18n/i18n.module';
import { UiCorePipesModule } from '../pipes/ui-core-pipes.module';
import { MessageBarContainerComponent } from './components/message-bar-container.component';
import { MessageBarComponent } from './components/message-bar/message-bar.component';
import {
  SOFTLINE_CONFIG_MESSAGE_BAR_DURATION,
  SOFTLINE_FEATURE_MESSAGE_BAR,
} from './message-bar.shared';
import * as MessageBarStore from './message-bar.store';

@NgModule({
  declarations: [MessageBarComponent, MessageBarContainerComponent],
  imports: [I18nModule, CommonModule, UiCorePipesModule],
  exports: [MessageBarComponent, MessageBarContainerComponent],
})
export class MessageBarModule {
  static forRoot(): ModuleWithProviders<MessageBarRootModule> {
    return {
      ngModule: MessageBarRootModule,
    };
  }
}

@NgModule({
  imports: [
    MessageBarModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MESSAGE_BAR,
      feature: MessageBarStore.feature,
    }),
  ],
  exports: [MessageBarModule],
  providers: [
    { provide: SOFTLINE_CONFIG_MESSAGE_BAR_DURATION, useValue: 3000 },
  ],
})
export class MessageBarRootModule {}
