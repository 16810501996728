import { Component, computed, HostBinding, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaDirective } from '@softline/dynamic';
import { UiCoreModule } from '@softline/ui-core';

@Component({
  selector: 'soft-meta-field',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './meta-field.component.html',
  styleUrl: './meta-field.component.scss',
})
export class MetaFieldComponent<T> {
  directive = inject(MetaDirective<T>);
  name = input.required<string>();

  field = computed(() => {
    const metaObject = this.directive.metaObject();
    if(!metaObject)
      return null
    return metaObject[this.name()] ?? null;
  });
  format = computed(() => {
    const field = this.field();
    if(!field)
      return null
    return field['format'];
  });
  selectedOption = computed(() => {
    const field = this.field();
    const value = this.value();
    if(field.type !== 'select')
      return null;
    return field.options.find(o => o.value === value)
  });

  value = computed(() => {
    const value = this.directive.value();
    return value[this.name()];
  });

  @HostBinding('collapsed')
  get collapsed() {
    return this.field()?.visible === false;
  }
}
