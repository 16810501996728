import {AbteilungPersonalUebersicht, PersonalUebersicht} from '../types/abteilung';
import {CustomStoreAddOn, Dictionary, FilterCollectionStore, RemoteCollectionStore, SortCollectionStore, StoreBuilder, createGetter, select} from '@softline/core';

function groupBy<Key, Value>(list: Value[], keyGetter: (v: Value) => Key): Map<Key, Value[]> {
  const map = new Map<Key, Value[]>();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

type State = RemoteCollectionStore.State<PersonalUebersicht>

const getters = {
  abteilungenMap: createGetter<State, Map<number, PersonalUebersicht[]>>('pmsPersonal/getters/abteilungenMap'),
  abteilungen: createGetter<State, AbteilungPersonalUebersicht[]>('pmsPersonal/getters/abteilungen'),
  abteilung: createGetter<State, AbteilungPersonalUebersicht, string>('pmsPersonal/getters/abteilung'),
}

const actions = {}
const mutations = {}

const PmsPersonalStoreExtension: CustomStoreAddOn<State, typeof mutations, typeof getters, typeof actions> = {
  feature: {
    getters: [
      select(getters.abteilungenMap, ({ state }) => {
        const entities = state.ids.map(id => state.entities[id] as PersonalUebersicht)  
        return groupBy<number, PersonalUebersicht>(entities, e => e?.abteilung.id ?? 0);
      }),
      select(getters.abteilungen, ({ get, featureName }) => {
        const map = get(featureName, getters.abteilungenMap)
        return Array.from(map, ([key, value]) => {
          return {
            abteilung: { id: key, bezeichnung: value[0]?.abteilung?.bezeichnung ?? '' },
            anwesend: value.reduce((count, mitarbeiter) => mitarbeiter?.status === 'A' ? count + 1 : count, 0),
            mitarbeiterGesamt: value?.length ?? 0,
            mitarbeiter: value
          };
        })
      }),
      select(getters.abteilung, ({ state, params, get, featureName }) => {
        const mitarbeiter = get(featureName, getters.abteilungenMap).get(+params)
        return {
          abteilung: { id: +params, bezeichnung: mitarbeiter?.[0].abteilung?.bezeichnung ?? '' },
          anwesend: mitarbeiter?.reduce((count, mitarbeiter) => mitarbeiter?.status === 'A' ? count + 1 : count, 0),
          mitarbeiterGesamt: mitarbeiter?.length ?? 0,
          mitarbeiter: mitarbeiter ?? []
        };
      })
    ]
  },
  actions,
  mutations,
  getters
}

export const PmsPersonalStore = new StoreBuilder(RemoteCollectionStore.create<PersonalUebersicht>())
  .add(FilterCollectionStore.create<PersonalUebersicht>())
  .add(SortCollectionStore.create<PersonalUebersicht>())
  .add(PmsPersonalStoreExtension)
  .value;