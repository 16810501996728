import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { LagerortListView } from "./list-view/lagerort.list-view";

export const lagerortFieldOk: FieldOkConfig = {
  name: 'lagerort',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{lagerort}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: LagerortListView
    }
  }
  ]
};
export const lagerortMultiselectFieldOk: FieldOkConfig = {
  name: 'lagerort',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{lagerort}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: LagerortListView
    }
  }
  ]
};

export const lagerortQueryFieldOk: FieldOkConfig = {
  name: 'lagerort',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{lagerort}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: LagerortListView
    }
  }
  ]
};
