import { ControlValueAccessor } from "@angular/forms";

export abstract class ControlValueAccessorBase implements ControlValueAccessor {
  isDisabled = false;
  protected onChange: Function = () => {};
  protected onTouch: Function = () => {};

  abstract writeValue(obj: any): void;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
