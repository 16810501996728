import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS} from '../../artikel-info.shared';
import {FavoriteListsStore} from '../../store/favorite-lists.store';
import {Modal, UiCoreModule} from '@softline/ui-core';
import {FavoriteList} from '../../types/favorite-list';
import {BehaviorSubject} from 'rxjs';
import {handleRequestErrors} from '@softline/application';
import {Favorite} from '../../types/favorite';
import {Item} from '../../types/item';
import {FavoriteService} from '../../services/favorite.service';

@Component({
  selector: 'soft-add-favorite-item-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './add-favorite-item-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./add-favorite-item-dialog.component.scss'],
})
export class AddFavoriteItemDialogComponent implements OnInit, Modal<{ favorite: Favorite, list: FavoriteList } | null | undefined> {

  item!: Item
  close!: (result: ({ favorite: Favorite, list: FavoriteList } | null | undefined)) => void;

  readonly selectedList$ = new BehaviorSubject<FavoriteList | null>(null);
  readonly loading$ = new BehaviorSubject<boolean>(false);

  readonly favoriteLists$ = this.store.observe(
    SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS,
    FavoriteListsStore.getters.all
  );

  constructor(private store: Store, private favoriteService: FavoriteService) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS,
      FavoriteListsStore.actions.loadManyOnce
    );
  }

  registerCloseHandler(handler: (result: ({ favorite: Favorite, list: FavoriteList } | null | undefined)) => void) {
    this.close = handler;
  }

  selectOrUnselect(list: FavoriteList): void {
    if (this.selectedList$.value?.id === list.id)
      this.selectedList$.next(null)
    else
      this.selectedList$.next(list)
  }

  async createNewList(): Promise<void> {
    await this.favoriteService.createList();
  }

  async addToList(): Promise<void> {
    if (!this.item || !this.selectedList$.value)
      return;

    try {
      this.loading$.next(true);

      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS,
        FavoriteListsStore.actions.addItemToList,
        { item: { id: this.item.id }, list: this.selectedList$.value }
      );

      this.loading$.next(false);

      this.close({
        favorite: result,
        list: this.selectedList$.value
      });
    } catch (e) {
      handleRequestErrors(this.store, e);
    } finally {
      this.loading$.next(false);
    }
  }
}
