<div class="flex flex-col flex-grow">
  <soft-tab-group class="mt-2 flex flex-col flex-grow" [customClass]="'full-width'">
    <soft-tab title="Suche">
      <soft-artikel-textsuche
        class="w-full mt-2 flex flex-col flex-grow"
        (selectArtikel)="selectArtikel.emit($event)">
      </soft-artikel-textsuche>
    </soft-tab>
    <soft-tab *ngIf="!disableScan" [title]="'Scannen'">
      <soft-scan-button
        class="mt-2"
        [labelType]="['ean13', 'ean128', 'ean8', 'qrcode', 'code39', 'code93', 'code128', 'upc_a', 'upc_e', 'itf']">
      </soft-scan-button>
    </soft-tab>
    <soft-tab [title]="'Favoriten'">
      <soft-artikel-favoriten-liste
        class="w-full mt-2 flex flex-col flex-grow"
        (selectArtikel)="selectArtikel.emit($event)"
      ></soft-artikel-favoriten-liste>
    </soft-tab>
  </soft-tab-group>
</div>

<!-- Templates -->

<soft-blocking-loading-spinner *ngIf="scanInProgress$ | async"></soft-blocking-loading-spinner>
