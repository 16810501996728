import { Component, Input, OnInit, TrackByFunction } from '@angular/core';
import { groupBy, Store } from "@softline/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { SOFTLINE_FEATURE_MESSAGE_BAR } from '../message-bar.shared';
import * as MessageBarStore from '../message-bar.store';
import { filter, map, switchMap, tap } from "rxjs/operators";
import { MessageBarConfig } from '../data/message-bar.config';
import { messages } from "nx/src/utils/ab-testing";
import {animate, animateChild, query, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'soft-message-bar-container',
  templateUrl: './message-bar-container.component.html',
  styleUrls: ['./message-bar-container.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10rem)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)'})),
        animateChild({}),
      ]),
      transition(':leave', [

        // animate('200ms ease-in', style({  opacity: 0, transform: 'translateY(10rem)' })),
        query('@*', animateChild()),
      ])
    ])
  ]
})
export class MessageBarContainerComponent implements OnInit {
  private name$ = new BehaviorSubject<string | undefined>(undefined);

  @Input()
  get name(): string | undefined {
    return this.name$.value;
  }
  set name(value: string | undefined) {
    this.name$.next(value);
  }

  messageBars$: Observable<Readonly<MessageBarConfig[]>> = this.name$.pipe(
    switchMap((name) =>
      this.store.observe(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.getters.items,
        name
      )
    ),
    map(messages => {
      const filteredMessages: MessageBarConfig[] = []
      const traverse = [...messages];
      while (traverse.length > 0) {
        const current = traverse.pop();
        if(!current)
          continue;
        if(current.group === undefined || filteredMessages.findIndex(o => o.group === current.group) === -1)
          filteredMessages.push(current)
      }
      return filteredMessages.reverse();
    })
  );

  trackMessageBars: TrackByFunction<MessageBarConfig> = (
    index: number,
    item: MessageBarConfig
  ) => item?.id ?? index;

  constructor(readonly store: Store) {}

  ngOnInit(): void {}
}
