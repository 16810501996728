import { Entity } from '../types/entity';
import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
} from '../../../factories';
import { Dictionary } from '../../../../types/dictionary';
import { StoreFeature } from '../../../store';
import { SOFTLINE_STORE_VALIDATION_SERVICE } from '../single/entity.shared';
import { ValidationProperty, ValidationResult } from '../types/validation';

export interface ValidateActionParameters {
  queryParams?: Dictionary<unknown>;
  pathParams?: Dictionary<unknown>;
  entity: Entity;
}

export interface State {
  validations: ValidationResult[];
  validating: boolean;
}

export const mutations = {
  validation: {
    clear: createMutation<State>('validation clear'),
    validating: createMutation<State>('validating'),
    success: createMutation<State, ValidationResult>('validation success'),
  },
};

export const actions = {
  validation: {
    validate: createAction<State, ValidateActionParameters, ValidationResult>(
      'validate'
    ),
  },
};

export const getters = {
  validation: {
    validating: createGetter<State, boolean>('validating'),
    validations: createGetter<State, ValidationResult[]>('validations'),
    validation: createGetter<State, ValidationResult, string>('validation'),
    allValid: createGetter<State, boolean>('all valid'),
    valid: createGetter<State, boolean | undefined, string>('valid'),
    property: createGetter<
      State,
      ValidationProperty,
      { id: string; propertyName: string }
    >('validation property'),
  },
};

export const feature: StoreFeature<State> = {
  initialState: {
    validations: [],
    validating: false,
  },
  mutations: [
    mutate(mutations.validation.clear, ({ state }) => ({
      ...state,
      validations: [],
    })),
    mutate(mutations.validation.validating, ({ state }) => ({
      ...state,
      validating: true,
    })),
    mutate(mutations.validation.success, ({ state, params }) => {
      const validations = [...state.validations];
      const index = validations.findIndex(
        (o) => o.entity.id === params.entity.id
      );
      if (index > -1) validations.splice(index, 1);
      validations.push(params);
      return { ...state, validating: false, validations };
    }),
  ],
  actions: [
    on(
      actions.validation.validate,
      async ({ store, featureName, params, injector }) => {
        const service = injector.get(SOFTLINE_STORE_VALIDATION_SERVICE);
        store.commit(featureName, mutations.validation.validating);
        const result = await service.validate(
          params.entity,
          params.pathParams,
          params.queryParams
        );
        store.commit(featureName, mutations.validation.success, {
          ...result,
          entity: params.entity,
        });
        return result;
      }
    ),
  ],
  getters: [
    select(getters.validation.validating, ({ state }) => state.validating),
    select(getters.validation.validations, ({ state }) => state.validations),
    select(getters.validation.validation, ({ state, params }) =>
      state.validations.find((o) => o.entity.id === params)
    ),
    select(getters.validation.allValid, ({ state, params }) =>
      state.validations.every((o) => o.isValid)
    ),
    select(
      getters.validation.valid,
      ({ state, params }) =>
        state.validations.find((o) => o.entity.id === params)?.isValid
    ),
    select(getters.validation.property, ({ state, params }) =>
      state.validations
        .find((o) => o.entity.id === params.id)
        ?.properties?.find((o) => o.propertyName === params.propertyName)
    ),
  ],
};
