import {Action, createAction, CustomStore, ListStore, on} from '@softline/core';
import {firstValueFrom} from 'rxjs';
import {UmsatzstatistikListService} from '../../services/umsatzstatistik-optionen-list.service';

type State<T> = ListStore.State<T>;

type Mutations<T> = ListStore.Store<T>['mutations'];

type Getters<T> = ListStore.Store<T>['getters'];

type Actions<T> =  {
  loadItems: Action<State<T>, void, T[]>
}

export class WwsUmsatzstatistikOptionenListStore<T> {

  private readonly listStore = ListStore.create<T>();

  readonly mutations = this.listStore.mutations;
  readonly getters = this.listStore.getters;
  readonly actions = {
    loadItems: createAction<ListStore.State<T>, void, T[]>('wws/umsatzstatistikOptionenListStore/actions/loadItems'),
    loadItemsOnce: createAction<ListStore.State<T>, void, T[]>('wws/umsatzstatistikOptionenListStore/actions/loadItemsOnce')
  };

  readonly feature = {
    initialState: {
      ...this.listStore.feature.initialState,
    },
    mutations: [
      ...this.listStore.feature.mutations
    ],
    getters: [
      ...this.listStore.feature.getters
    ],
    actions: [
      on(this.actions.loadItems, async ({ injector, commit, featureName }) => {
        const service = injector.get(UmsatzstatistikListService<T>);
        const result = await firstValueFrom(service.loadItems());
        commit(featureName, this.mutations.set, { items: result });
        return result;
      }),
      on(this.actions.loadItemsOnce, async ({ featureName, get, dispatch }) => {
        const state = get(featureName) as State<T>;

        if (state.items?.length > 0)
          return state.items;

        return dispatch(featureName, this.actions.loadItems)
      })
    ],
  }
}

export function create<T>(): CustomStore<ListStore.State<T>, Mutations<T>, Getters<T>, Actions<T>> {
  return new WwsUmsatzstatistikOptionenListStore<T>();
}
