export interface Konto {
  id: number;
  kategorie: number;
  nummer: number;
  kurzbez: string;
  name1: string;
  name2: string;
  name3: string;
  strasse: string;
  ort: string;
  plz: string;
  telefonFa: string;
  handyFa: string;
  mail1: string;
}
export interface Zahlart {
  id: number;
  zahlart: string;
  bezeichnung: string;
}

export interface Zahlkond {
  id: number;
  zahlkond: string;
  zahlkondinfo: string;
}

export interface Verteter {
  id: number;
  vertreter: string;
  bezeichnung: string;
}

export interface Kundengruppe {
  id: number;
  kundengruppe: string;
}

export interface BelegVersandZeile {
  belegart: string;
  belegversand: string;
  gueltigvon: string;
  gueltigbis: string;
  mailadresse: string;
  hinterlegungin: string;
}

export interface KundeninfoLeiste {
  id: number;
  konto: Konto;
  zahlart: Zahlart;
  zahlkond: Zahlkond;
  vertreter: Verteter;
  zahlartTankstelle: Zahlart;
  kundengruppe: Kundengruppe;
  einkaufslieferant: Konto;
  saldoFaellig: number;
  kreditlimit: number;
  kreditlimit2: number;
  versicherungslimit: number;
  iban: string;
  swiftcode: string;
  kontonummer: string;
  bankleitzahl: number;
  mahnstufe: number;
  belegart: string;
  zessionstext: string;
  kzsperre: string;
  isBeiRa: boolean;
  belegversandZeilen: BelegVersandZeile[];
}
