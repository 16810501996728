<div
  *ngIf="message"
  [ngClass]="'soft-validation soft-validation-' + message.type"
>
  <ng-container [ngSwitch]="message.type">
    <i
      *ngSwitchCase="'error'"
      class="fa-regular fa-circle-xmark soft-validation-icon"
    ></i>
    <i
      *ngSwitchCase="'warning'"
      class="fa-regular fa-circle-exclamation soft-validation-icon"
    ></i>
    <i
      *ngSwitchCase="'info'"
      class="fa-regular fa-circle-info soft-validation-icon"
    ></i>
    <i
      *ngSwitchCase="'success'"
      class="fa-regular fa-circle-check soft-validation-icon"
    ></i>
  </ng-container>
  <strong>
    {{ message.subject | translate | interpolate: message.data }}
  </strong>
  {{ message.message | translate | interpolate: message.data }}
</div>
