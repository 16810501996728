<h2 class="font-bold text-xl">
  {{ '#HAFTRUECKLASS.DIALOGS.EDIT_DUE_DATE.HEADER' | translate }}
</h2>
<div class="border border-secondary-300 mt-4 p-2">
  <div>
    <div class="soft-overline">
      {{ '#HAFTRUECKLASS.DIALOGS.EDIT_DUE_DATE.CURRENT' | translate }}
    </div>
    <div class="font-bold text-sm">{{ dueDate | format: 'DATE.DEFAULT' }}</div>
  </div>
</div>
<div class="flex flex-col mt-4">
  <label class="soft-label top" for="dialog/dueDate">{{
    '#HAFTRUECKLASS.DIALOGS.EDIT_DUE_DATE.NEW' | translate
  }}</label>
  <soft-date-input id="dialog/dueDate" #date></soft-date-input>
</div>
<div class="flex flex-row justify-end mt-4">
  <button class="soft-button primary" (click)="onSubmit(date.value)">
    {{ '#HAFTRUECKLASS.DIALOGS.EDIT_DUE_DATE.SUBMIT' | translate }}
  </button>
</div>
