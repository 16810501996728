import { Injectable } from '@angular/core';
import {
  PreviewHandler,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  MessageBarStore,
  SOFTLINE_FEATURE_MODAL,
  ModalStore,
} from '@softline/ui-core';
import { Store } from '@softline/core';
import { isArchivePreview } from '../data/archive-preview';
import { ArchiveFile } from '../data/archive';
import { SOFTLINE_FEATURE_ARCHIVE } from '../../application.shared';
import * as ArchiveStore from '../archive.store';

@Injectable()
export class ArchiveImagePreviewHandler implements PreviewHandler {
  constructor(private store: Store) {}

  canOpen(value: unknown): boolean {
    return (
      isArchivePreview(value) &&
      value.file?.metaData?.type?.startsWith('image/')
    );
  }

  async open(value: unknown): Promise<void> {
    if (!isArchivePreview(value)) return;
    await this.showGallery(value.file, value.files);
  }

  async showGallery(file: ArchiveFile, files: readonly ArchiveFile[]): Promise<void> {
    const images = (files ?? []).filter((o) =>
      o.metaData.type.startsWith('image/')
    );
    const activeIndex = images.indexOf(file);

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.gallery,
        {
          images: images.map(
            async (image) =>
              (
                await this.store.dispatch(
                  SOFTLINE_FEATURE_ARCHIVE,
                  ArchiveStore.actions.downloadOnce,
                  { file: image }
                )
              ).content
          ),
          title: 'Archiv',
          index: activeIndex,
        }
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#APPLICATION.ARCHIVE.MESSAGES.DOWNLOAD_FAILURE'
      );
    }
  }
}
