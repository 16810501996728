import { Component, OnInit } from '@angular/core';
import { SelectDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
  selector: 'soft-dynamic-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent
  extends DynamicInputAtom<SelectDefinition>
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
