import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeValue} from '@angular/platform-browser';

declare const window: any;

@Pipe({
  name: 'localImage',
  pure: true,
})
export class LocalImagePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(file: Blob | string | null | undefined): Promise<string> {
    if (!file) return new Promise<string>((done) => done(''));
    if ((file as any).localURL)
      return new Promise<string>((done) => {
        done((file as any).localURL);
      });

    if (!(file instanceof Blob)) {
      return Promise.resolve(this.sanitizer.sanitize(SecurityContext.URL, file) ?? '')
    }

    const url = URL.createObjectURL(file);
    return new Promise<string>((done) => done(url));
  }
}
