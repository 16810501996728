import {EnvironmentProviders, Provider} from '@angular/core';
import {SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT} from '../../utilities.shared';
import {SOFTLINE_WIDGET_SIDE_MENU_USER_INFO} from '@softline/application';
import {UserContextComponent} from './components/user-context/user-context.component';
import {SOFTLINE_CONFIG_WIDGETS} from '@softline/ui-core';
import {UserContextWidgetComponent} from './widget/user-context-widget.component';

export const userContextProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
    useValue: UserContextComponent,
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_WIDGETS,
    multi: true,
    useValue: {
      outlet: SOFTLINE_WIDGET_SIDE_MENU_USER_INFO,
      component: UserContextWidgetComponent,
    },
  },
];
