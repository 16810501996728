import {
  createAction,
  createGetter,
  createMutation,
  CustomStore,
  mutate,
  on,
  select,
  StoreBuilder
} from "@softline/core";
import {BestellvorschlagService} from '../services/bestellvorschlag.service';
import {lastValueFrom} from 'rxjs';
import {ArtikelDetailStore} from '@softapps/wws/core';
import {
  SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE
} from "../bestellvorschlag.shared";
import {BestellvorschlagBeweStore} from './bestellvorschlag-bewe.store';
import {ItemScan} from '@softline/application';

export interface BestellvorschlagState {
  datum?: string
  itemScanResult?: ItemScan
}

const mutations = {
  setDate: createMutation<BestellvorschlagState, string | undefined>('mdeBestellvorschlag/setDate'),
  setItemScanResult: createMutation<BestellvorschlagState, ItemScan | undefined>('mdeBestellvorschlag/setItemScanResult')
}

const getters = {
  datum: createGetter<BestellvorschlagState, string | null | undefined>('mdeBestellvorschlag/getDatum'),
  itemScanResult: createGetter<BestellvorschlagState, ItemScan | undefined>('mdeBestellvorschlag/getItemScanResult'),
}

const actions = {
  abschluss: createAction<BestellvorschlagState, void, void>('mdeBestellvorschlag/abschluss'),
  delete: createAction<BestellvorschlagState, void, void>('mdeBestellvorschlag/delete'),
}

const bestellvorschlagStore: CustomStore<
  BestellvorschlagState,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  mutations,
  getters,
  actions,
  feature: {
    initialState: {
      datum: undefined,
    },
    mutations: [
      mutate(mutations.setDate, ({ params: datum, state }) => ({
        ...state,
        datum
      })),
      mutate(mutations.setItemScanResult, ({ params, state }) => ({
        ...state,
        itemScanResult: params
      }))
    ],
    getters: [
      select(getters.datum, ({ state }) => state.datum),
      select(getters.itemScanResult, ({ state }) => state?.itemScanResult),
    ],
    actions: [
      on(actions.abschluss, async ({ injector, featureName, commit }) => {
        const service = injector.get(BestellvorschlagService);
        await lastValueFrom(service.abschluss());
        commit(SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_DETAILS, ArtikelDetailStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE, BestellvorschlagBeweStore.mutations.clear);
        commit(featureName, mutations.setDate, null);
      }),
      on(actions.delete, async ({ injector, featureName, commit }) => {
        const service = injector.get(BestellvorschlagService);
        await lastValueFrom(service.delete());
        commit(SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_DETAILS, ArtikelDetailStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE, BestellvorschlagBeweStore.mutations.clear);
        commit(featureName, mutations.setDate, null);
      })
    ],
  }
}

export const BestellvorschlagStore = new StoreBuilder(bestellvorschlagStore)
  .value;
