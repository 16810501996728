import { TemplateRef } from '@angular/core';
import { DismissConfig } from './modal-config';
import { Observable } from 'rxjs';

export interface GalleryModalConfig {
  title?: string;
  subtitle?: string;
  content?: string | TemplateRef<any>;
  images: (
    | Blob
    | string
    | Promise<Blob | string>
    | Observable<Blob | string>
  )[];
  index: number;
  class?: string;
  params?: object;
}
