import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  Connection,
  ConnectionStore,
  equals,
  isConnection,
  SOFTLINE_FEATURE_CONNECTION,
  Store,
} from '@softline/core';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import { Observable, skip, Subscription } from 'rxjs';
import {
  SOFTLINE_CONFIG_CONNECTION_SETTINGS_LINKS,
  SOFTLINE_SERVICE_TEST_CONNECTION,
} from '../connection-settings.shared';
import { TestConnectionService } from '../services/test-connection.service';
import {
  handleRequestErrors,
  Scan,
  ScannerStore,
  SOFTLINE_FEATURE_SCANNER,
} from '@softline/application';
import { ConnectionDialogComponent } from '../dialogs/connection-dialog/connection-dialog.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'soft-connection-settings',
  templateUrl: './connection-settings.component.html',
  styleUrls: ['./connection-settings.component.scss'],
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
})
export class ConnectionSettingsComponent implements OnInit, OnDestroy {
  private subscription?: Subscription;

  connections$: Observable<readonly Connection[]> = this.store.observe(
    SOFTLINE_FEATURE_CONNECTION,
    ConnectionStore.getters.connections
  );

  selectedConnection$ = this.store.observe(
    SOFTLINE_FEATURE_CONNECTION,
    ConnectionStore.getters.selected
  );

  constructor(
    private store: Store,
    @Inject(SOFTLINE_SERVICE_TEST_CONNECTION)
    private testService: TestConnectionService,
    @Optional()
    @Inject(SOFTLINE_CONFIG_CONNECTION_SETTINGS_LINKS)
    readonly links?: { name: string; route: string | string[] }[]
  ) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.actions.load
    );
    this.subscription = this.store
      .observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(skip(1))
      .subscribe((scan: Scan | undefined) => this.onScanResult(scan));
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  async onSelectionChange(connection: Connection): Promise<void> {
    await this.selectConnection(connection);
  }

  async onAdd(): Promise<void> {
    const connection = { host: '', port: null, basePath: null, name: null };
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Connection, any>(),
      {
        component: ConnectionDialogComponent,
        data: {
          mode: 'new',
          connection,
        },
        dismiss: { backdrop: true, button: true },
      }
    );
    if (result === 'DISMISSED') return;

    this.store.commit(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.mutations.add,
      result
    );
    await this.selectConnection(result);
  }

  async onEdit(): Promise<void> {
    const connection = this.store.get(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.getters.selected
    );
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Connection, any>(),
      {
        component: ConnectionDialogComponent,
        data: {
          mode: 'edit',
          connection,
        },
        dismiss: { backdrop: true, button: true },
      }
    );
    if (result === 'DISMISSED') return;

    const params = { old: connection, new: result };
    this.store.commit(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.mutations.update,
      params
    );
    await this.selectConnection(result);
  }

  async onDelete(): Promise<void> {
    const connection = this.store.get(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.getters.selected
    );
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      '#CONNECTION_SETTINGS.DELETE_CONFIRM'
    );

    if (result !== 'YES') return;

    this.store.commit(
      SOFTLINE_FEATURE_MODAL,
      ConnectionStore.mutations.remove,
      connection
    );
    const connections = this.store.get(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.getters.connections
    );

    if (connections && connections?.length > 0)
      this.store.commit(
        SOFTLINE_FEATURE_CONNECTION,
        ConnectionStore.mutations.select,
        0
      );

    await this.store.dispatch(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.actions.save
    );
  }

  async onScan(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_SCANNER,
      ScannerStore.actions.scan,
      { labelType: 'qrcode' }
    );
  }

  async onScanResult(scan: Scan | undefined): Promise<void> {
    if(!scan)
      throw new Error('[ConnectionSettingsComponent] onScanResult: no scan result')
    try {
      if (scan.labelType !== 'qrcode') {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.error,
          '#CONNECTION_SETTINGS.MESSAGES.SCAN_ERROR'
        );
        return;
      }
      const connection = JSON.parse(scan.data);
      if (!isConnection(connection)) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.error,
          '#CONNECTION_SETTINGS.MESSAGES.SCAN_ERROR'
        );
        return;
      }

      const connections = this.store.get(
        SOFTLINE_FEATURE_CONNECTION,
        ConnectionStore.getters.connections
      );
      if (connections.find((o) => equals(o, connection))) {
        await this.selectConnection(connection);
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.success,
          '#CONNECTION_SETTINGS.MESSAGES.SCAN_EXISTING'
        );
        return;
      }

      this.store.commit(
        SOFTLINE_FEATURE_CONNECTION,
        ConnectionStore.mutations.add,
        connection
      );
      await this.selectConnection(connection);
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#CONNECTION_SETTINGS.MESSAGES.SCAN_SUCCESS'
      );
    } catch (e) {
      console.log(e);
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#CONNECTION_SETTINGS.MESSAGES.SCAN_ERROR'
      );
    }
  }

  async onSave(
    oldConnection: Connection,
    newConnection: Connection
  ): Promise<void> {
    if (!oldConnection) {
      this.store.commit(
        SOFTLINE_FEATURE_CONNECTION,
        ConnectionStore.mutations.add,
        newConnection
      );
    } else {
      const params = { old: oldConnection, new: newConnection };
      this.store.commit(
        SOFTLINE_FEATURE_CONNECTION,
        ConnectionStore.mutations.update,
        params
      );
    }

    await this.selectConnection(newConnection);
  }

  private getConnections(): readonly Connection[] {
    return this.store.get(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.getters.connections
    );
  }

  private async selectConnection(connection: Connection): Promise<void> {
    const connections = this.getConnections();
    const index = connections.findIndex((o) => equals(o, connection));

    if (index < 0) return;

    this.store.commit(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.mutations.select,
      index
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.actions.save
    );
  }

  async onTest(): Promise<void> {
    const connection = this.store.get(
      SOFTLINE_FEATURE_CONNECTION,
      ConnectionStore.getters.selected
    );
    if(!connection)
      throw new Error('[connection-settings.component] onTest: no connection set')
    try {
      if (await this.testService.test(connection))
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.success,
          '#CONNECTION_SETTINGS.MESSAGES.TEST_SUCCESS'
        );
      else
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.warning,
          '#CONNECTION_SETTINGS.MESSAGES.TEST_FAILURE.NOT_VALID'
        );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }
}
