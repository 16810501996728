<div class="top-controls c row space-between no-wrap">
  <div class="c row start fixed-height">
    <a class="colored custom-size hover-bg" (click)="onPrevious()">
      <i class="fa-regular fa-arrow-left mr-1"></i>
      {{ previousYear }}
    </a>
  </div>
  <div class="c row end fixed-height">
    <a class="colored custom-size hover-bg" (click)="onNext()">
      {{ nextYear }}
      <i class="fa-regular fa-arrow-right ml-1"></i>
    </a>
  </div>
</div>
<div class="current-data c column center fill">
  <h3 class="text-lg md:text-xl lg:text-2xl font-semibold">{{ '#KONTOAUSZUG.HEADER.TITLE' | translate | interpolate:{ year: currentYear } }}</h3>
  <p class="text-xs text-light">{{ '#KONTOAUSZUG.HEADER.INFO' | translate | interpolate:{ amount: length } }}</p>
</div>
