<div
  class="border border-slate-200 mt-2 p-4"
  [ngClass]="{
    consoleError: entry.type === 'error',
    consoleWarn: entry.type === 'warn',
    consoleInfo: entry.type === 'info',
    consoleDebug: entry.type === 'debug'
  }"
  *ngFor="let entry of logs$ | async; let i = index"
>
  <div class="flex justify-between text-[0.5rem] font-semibold mb-2">
    <span>#{{ i + 1 }} - {{ entry.type | uppercase }}</span>
    <span>{{ entry.timestamp | format: 'DATE_TIME.SECONDS' }}</span>
  </div>
  <ng-container
    *ngTemplateOutlet="contentTemplate; context: { data: entry.data }"
  ></ng-container>
</div>

<ng-template #contentTemplate let-data="data">
  <ng-container
    *ngTemplateOutlet="
      (data | isArray) ? arrayTemplate : typeTemplate;
      context: { data: data }
    "
  ></ng-container>
</ng-template>

<ng-template #arrayTemplate let-data="data">
  <div *ngFor="let o of data">
    <ng-container
      *ngTemplateOutlet="contentTemplate; context: { data: o }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #typeTemplate let-data="data">
  <ng-container *ngIf="isError(data)">
    <p class="text-xs font-semibold">{{ data.message }}</p>
    <div>
      <p class="text-xs mt-2">{{ data.stack }}</p>
    </div>
  </ng-container>
  <soft-json-object-tree class="text-xs" [value]="data"></soft-json-object-tree>
</ng-template>
