import {InjectionToken, Type} from '@angular/core';
import {Step} from '@softline/application';

export const SOFTLINE_PERMISSION_SEND_NOTIFICATION = 'PUSHNACHRICHTEN.SENDEN';

export interface EmpfaengerComponentRegistration {
  name: string;
  component: Type<any>
}

export const SOFTLINE_CONFIG_SEND_NOTIFICATION_COMPONENT = new InjectionToken<EmpfaengerComponentRegistration[]>(
  'SOFTLINE_CONFIG_SEND_NOTIFICATION_COMPONENT'
);

export const SOFTLINE_CONFIG_DEFAULT_SEND_NOTIFICATION_COMPONENT_NAME = new InjectionToken<string>(
  'SOFTLINE_CONFIG_DEFAULT_SEND_NOTIFICATION_COMPONENT'
);

export const SOFTLINE_STEPS_SEND_NOTIFICATION: Step[] = [
  {
    header: 'Empfänger auswählen',
    subHeader: 'Bitte wählen Sie einen oder mehrere Empfänger aus',
  },
  {
    header: 'Nachricht verfassen',
    subHeader: 'Bitte Betreff und Nachricht eingeben',
  }
]
