export interface ValueReference {
  type: 'storeReference';
  feature: string;
  getter?: string;
  params?: any;
  property: string;
}

export function isStoreReference(value: unknown): value is ValueReference {
  return (value as ValueReference)?.type === 'storeReference';
}
