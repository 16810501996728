<div class="flex flex-col gap-0.5 mb-3">
  <h2 class="text-xl font-semibold px-3 sm:px-0 mt-1 sm:mt-0">Offene Posten</h2>
  <span class="text-sm text-light">
    @if (from && to) {
      Zeitraum: {{ from?.toISOString() | format:'DATE.DEFAULT' }} - {{ to?.toISOString() | format:'DATE.DEFAULT' }}
    } @else {
      Alle offenen Posten (Gesamter Zeitraum)
    }
  </span>
</div>

<soft-paginator
  [elements]="elemtentCount()"
  [pageSizes]="[10, 20, 30, 50]"
  [pageSize]="pageSize()"
  [page]="currentPage()"
  (pageSizeChange)="pageSize.set($event)"
  (pageChange)="currentPage.set($event)"
></soft-paginator>

<div class="max-h-[60vh] overflow-y-auto mt-1">
  <table class="soft-table-2">
    <thead class="md:sticky top-0">
    <tr class="md:sticky top-0">
      <th class="md:sticky top-0 !text-left">{{ '#OFFENE_POSTEN.TABLE.HEADER.FAELLIGKEIT' | translate }}</th>
      <th class="md:sticky top-0 !text-left">{{ '#OFFENE_POSTEN.TABLE.HEADER.NUMMER' | translate }}</th>
      <th class="md:sticky top-0 !text-left">{{ '#OFFENE_POSTEN.TABLE.HEADER.BELEGDATUM' | translate }}</th>
      <th class="md:sticky top-0 !text-left min-w-[1.75rem] max-w-[1.75rem]">{{ '#OFFENE_POSTEN.TABLE.HEADER.SYMBOL' | translate }}</th>
      @if (showExtraColumn) {
        <th class="md:sticky top-0 !text-left min-w-[2rem] max-w-[7rem]">{{ '#OFFENE_POSTEN.TABLE.HEADER.KUNDE' | translate }}</th>
      }
      <th class="md:sticky top-0 !text-right min-w-[8.5rem] max-w-[8.5rem]">{{ '#OFFENE_POSTEN.TABLE.HEADER.SALDO' | translate }}</th>
      <th class="md:sticky top-0 !text-right min-w-[7rem] max-w-[7rem]">{{ '#OFFENE_POSTEN.TABLE.HEADER.SKONTO' | translate }}</th>
      <th class="md:sticky top-0 !text-right min-w-[9rem] max-w-[9rem]" colspan="2">{{ '#OFFENE_POSTEN.TABLE.HEADER.SALDO_ABZGL_SKONTO' | translate }}</th>
    </tr>
    </thead>
    <tbody>
      @for (entry of visibleOffenePosten(); track $index) {
        <tr>
          <td>{{ entry?.faelldatum | format:'DATE.DEFAULT' }}</td>
          <td>{{ entry?.belegnummer }}</td>
          <td>{{ entry?.belegdatum | format:'DATE.DEFAULT' }}</td>
          <td>{{ entry?.symbol?.symbol }}</td>
          @if (showExtraColumn) {
            <td class="max-w-[8.5rem]">
              <span class="truncate" title="{{entry?.ktostammUrsache?.name}}">{{ entry?.ktostammUrsache?.number}} - {{ entry?.ktostammUrsache?.name }}</span>
            </td>
          }
          <td class="!text-right max-w-[8.5rem]">{{ entry?.saldo | currencyFormat:'EUR':'symbol-narrow' }}</td>
          <td class="!text-right min-w-[7rem] max-w-[7rem]">{{ entry?.skonto | currencyFormat:'EUR':'symbol-narrow'  }}</td>
          <td class="!text-right min-w-[8.5rem] max-w-[9rem]">{{ entry?.saldoAbzglSkonto | currencyFormat:'EUR':'symbol-narrow'  }}</td>
          <td class="w-10 !text-right">
            <div class="flex items-center justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 fill-current text-primary cursor-pointer hover:text-primary-600 p-1 hover:bg-hover rounded-sm" viewBox="0 0 384 512" (click)="downloadFile(entry)">
                <path d="M320 480H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352V448c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4H240zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zM208 240c0-8.8-7.2-16-16-16s-16 7.2-16 16V361.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l80 80c6.2 6.2 16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L208 361.4V240z"/>
              </svg>
            </div>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>
