import {Component, OnInit} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {
  SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
  SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER
} from '../../../statusabfrage.shared';
import {PmsAbteilungStore} from '../../../store/abteilung.store';
import {Dictionary, FilterService, SortService, Store} from '@softline/core';
import {AbteilungCardComponent} from '../../abteilung-card/abteilung-card.component';
import {UiCoreModule} from '@softline/ui-core';
import { Abteilung, AbteilungUebersicht, PersonalUebersicht } from '../../../types/abteilung';
import {BehaviorSubject, combineLatestWith, map, Observable} from 'rxjs';
import {
  Empfaenger,
  SendNotificationStore,
  SOFTLINE_FEATURE_SEND_NOTIFICATION
} from '@softapps/allgemein/send-notification';
import {PmsPersonalStore} from '../../../store/personal.store';
import { AbteilungenService } from '../../../services/abteilungen.service';
import { handleRequestErrors } from '@softline/application';

@Component({
  selector: 'soft-empfaenger-abteilung-list',
  standalone: true,
  imports: [CommonModule, AbteilungCardComponent, UiCoreModule],
  templateUrl: './empfaenger-abteilung-list.component.html',
  styleUrls: ['./empfaenger-abteilung-list.component.scss'],
  providers: [DatePipe]
})
export class EmpfaengerAbteilungListComponent implements OnInit {

  private readonly selected$ = new BehaviorSubject<Dictionary<AbteilungUebersicht>>({});

  readonly abteilungen$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
    PmsAbteilungStore.getters.all
  )

  readonly sort$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
    PmsAbteilungStore.getters.sort
  );

  readonly filter$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
    PmsAbteilungStore.getters.filter
  );

  readonly filteredItems$: Observable<{ abteilung: AbteilungUebersicht; selected: boolean }[]> = this.abteilungen$.pipe(
    combineLatestWith(
      this.sort$,
      this.filter$,
      this.selected$,
      this.store.observe(SOFTLINE_FEATURE_SEND_NOTIFICATION, SendNotificationStore.getters.data).pipe(
        map(o => ((o as any)?.empfaenger ?? [])?.map(o => o?.id))
      ),
      this.store.observe(SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER, PmsPersonalStore.getters.all)
    ),
    map(([values, sort, filter, selected, ids, all]) => {
      return this.sortService.sort(this.filterService.filter(values, filter), sort).map(abteilung => {
        const abteilungPersonalUebersicht = this.store.get(
          SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
          PmsPersonalStore.getters.abteilung,
          abteilung.id + ''
        );

        const allIncluded = abteilungPersonalUebersicht.mitarbeiter.every(m => ids.includes(m?.anwender?.id));

        return {
          abteilung,
          selected: all?.length > 0 &&  (allIncluded ? allIncluded : !!selected[abteilung.id])
        }
      })
    }),
  );

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
    PmsAbteilungStore.getters.loading
  );

  trackByFn = (_: number, { abteilung }: { abteilung: AbteilungUebersicht; selected: boolean; }) => abteilung.id

  constructor(
    private readonly datePipe: DatePipe,
    private readonly sortService: SortService,
    private readonly filterService: FilterService,
    private readonly abteilungenService: AbteilungenService,
    private readonly store: Store,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const stichdatum = this.datePipe.transform(new Date().toISOString(), 'yyyy-MM-dd');
      await this.abteilungenService.loadAbteilungen(stichdatum);
    }
    catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  async abteilungTapped(abteilung: AbteilungUebersicht): Promise<void> {
    const isSelected = this.selectOrUnselect(abteilung);
    let mitarbeiter: PersonalUebersicht[] = [];

    if (isSelected) {
      mitarbeiter = await this.store.dispatch(
        SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
        PmsPersonalStore.actions.loadMany,
        {
          pathParams: { stichdatum: this.datePipe.transform(new Date().toISOString(), 'yyyy-MM-dd') },
          queryParams: {
            idabteilung: abteilung?.id
          }
        }
      );
    } else {
      mitarbeiter = this.store.get(
        SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
        PmsPersonalStore.getters.abteilung,
        abteilung?.id + ''
      )?.mitarbeiter
    }

    const data = this.store.get(SOFTLINE_FEATURE_SEND_NOTIFICATION, SendNotificationStore.getters.data) ?? {}
    let empfaenger: Empfaenger[] = (data as any)?.empfaenger ?? [];

    for (const ma of mitarbeiter) {
      const index = empfaenger.findIndex(o => o.id === (ma?.anwender?.id ?? ma?.id));

      if (isSelected && index < 0 && (ma?.anwender?.id || ma?.id)) {
        empfaenger = empfaenger.concat({
          id: ma?.anwender?.id ?? ma?.id,
          name: `${ma.lstfix.vorname} ${ma.lstfix.zuname}`
        })
      } else if (!isSelected && index > -1) {
        empfaenger.splice(index, 1)
        empfaenger = [...empfaenger]
      }
    }

    this.store.commit(
      SOFTLINE_FEATURE_SEND_NOTIFICATION,
      SendNotificationStore.mutations.set,
      {
        ...data,
        empfaenger
      }
    );
  }

  private selectOrUnselect(abteilung: AbteilungUebersicht): boolean {
    let selectionMap = this.selected$.value;
    const selected = selectionMap[abteilung.id];

    let isSelected;

    if (!selected) {
      selectionMap = { ...selectionMap, [abteilung.id]: abteilung };
      isSelected = true;
    } else {
      delete selectionMap[abteilung.id];
      selectionMap = { ...selectionMap };
      isSelected = false;
    }

    this.selected$.next(selectionMap);
    return isSelected;
  }
}
