import {CalculationData, KzAusgabeStrategy} from "../kz-ausgabe.strategy";
import {Dictionary} from "@softline/core";

export const KzAusgabe1Strategy: KzAusgabeStrategy = {
  calculate(data: CalculationData): Dictionary<number | null> {

    const dict = data.dictionary
    const ausgabeEh1 = data.artehs.find(o => o.kzausgabe === '1')

    if (!ausgabeEh1 || !data.inputArteh?.kzausgabe || !data.inputValue) {
      return dict
    }

    if (data.inputArteh.kzausgabe === '1') {
      dict[ausgabeEh1.id] = Math.ceil(data.inputValue * data.inputArteh.faktor);
    } else if (data.inputArteh.kzausgabe === 'N') {
      const faktor = (ausgabeEh1.faktor / data.inputArteh.faktor)
      dict[ausgabeEh1.id] = Math.ceil(data.inputValue / faktor)
    }

    return dict
  }
}
