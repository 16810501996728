import {Step} from '@softline/application';

export const SOFTLINE_CONFIG_MDE_BESTELLERFASSUNG_STEPS: Step[] = [
  {
    header: 'Artikel suchen',
    subHeader: 'Bitte gewünschten Artikel auswählen',
  },
  {
    header: 'Artikeldaten',
    subHeader: 'Bitte passen Sie die Artikeldaten an',
  }
];

export const SOFTLINE_CONFIG_MDE_BESTELLERFASSUNG_INTRO_STEP_LIEFERANT: Step = {
  header: 'Lieferant eingeben',
  subHeader: 'Bitte Lieferant für Bestellung eingeben!',
};

export const SOFTLINE_CONFIG_MDE_BESTELLERFASSUNG_INTRO_STEP_LIEFERTERMIN: Step = {
  header: 'Liefertermin eingeben',
  subHeader: 'Bitte Liefertermin für die Bestellung eingeben!',
};

export const SOFTLINE_CONFIG_MDE_BESTELLERFASSUNG_INTRO_STEP_LAGERORT: Step = {
  header: 'Lagerort eingeben',
  subHeader: 'Bitte Lagerort für die Bestellung eingeben!',
};

export const SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG = 'mdeBestellerfassung'
export const SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_BEWE = 'mdeBestellerfassungBewe'
export const SOFTLINE_FEATURE_MDE_BESTELLERFASSUNG_ARTIKEL_DETAILS = 'mdeBestellerfassungArtikelDetails';

export const SOFTLINE_PERMISSION_MDE_BESTELLERFASSUNG = 'BESTELLUNG';
