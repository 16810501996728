<input
  type="text"
  [tabIndex]="tabIndex"
  pattern="\d*"
  [class.text-right]="textAlign === 'right'"
  [value]="(value | isNaN) ? input : (value | format:format:formatOptions)"
  (input)="onInput(inputElement.value)"
  (change)="onBlur(inputElement.value)"
  [readOnly]="readonly"
  placeholder="{{
    placeholder || '#UI_CORE.COMPONENTS.NUMBER_INPUT.PLACEHOLDER' | translate
  }}"
  inputmode="decimal"
  (blur)="onTouch()"
  #inputElement
/>
<!--<i
  *ngIf="!readonly"
  class="fa-regular fa-calculator picker-icon pointer ml-4"
  (click)="openCalculatorModal()"
></i>
-->
