<div class="flex flex-row items-center">
  @if (icon(); as icon) {
    <soft-icon [name]="icon" class="mr-4"></soft-icon>
  }
  <div class="flex flex-col">
    <div class="text-sm font-semibold">{{ title() | translate }}</div>
    <div class="soft-link text-sm"
         (click)="onClick()">{{ option()?.label ?? 'auswählen...' }}</div>
  </div>
</div>
