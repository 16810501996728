import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nModule, Modal} from '@softline/ui-core';
import {ArtikelInfoModalParams, SOFTLINE_CONFIG_ARTIKEL_INFO_MODAL_COMPONENT} from '@softapps/wws/core';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'soft-artikel-info',
  standalone: true,
  imports: [CommonModule, I18nModule],
  templateUrl: './artikel-info.component.html',
  styleUrls: ['./artikel-info.component.scss'],
})
export class ArtikelInfoComponent<T extends object> implements OnInit, OnDestroy, Modal<void> {

  private subscription?: Subscription;

  id$!: Observable<number>;
  params!: ArtikelInfoModalParams<T>;
  close!: (_: void) => void;

  inputs: Record<string, unknown> = {}

  constructor(
    @Inject(SOFTLINE_CONFIG_ARTIKEL_INFO_MODAL_COMPONENT) readonly modalComponent,
  ) {}

  registerCloseHandler(handler: (result: void) => void) {
    this.close = handler;
  }

  ngOnInit(): void {
    this.params?.onOpen?.();

    this.id$.subscribe(id => {
      this.inputs = {
        id,
        params: this.params?.data ?? {},
      }
    });
  }

  ngOnDestroy(): void {
    this.params?.onClose?.();

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.subscription = undefined;
  }
}
