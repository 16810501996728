import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlDefinition,
  Definition,
  ObjectDefinition,
} from '../../data/definitions';
import { Dictionary, Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_MODAL,
  ModalStore,
} from '@softline/ui-core';
import { ChangeFilterValueDialogComponent } from './change-filter-value-dialog/change-filter-value-dialog.component';

@Component({
  selector: 'soft-dynamic-filter',
  templateUrl: './dynamic-filter.component.html',
  styleUrls: ['./dynamic-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFilterComponent implements OnInit {
  @Input() definition?: ObjectDefinition;
  @Input() value: object | null | undefined;
  @Output() valueChange = new EventEmitter<object | null | undefined>();
  @Input() showAll = false;

  constructor(private store: Store, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  async onClick(propertyDefinition: Definition): Promise<void> {
    const value = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<object, object>(),
      {
        component: ChangeFilterValueDialogComponent,
        data: {
          definition: propertyDefinition,
          value: this.value,
        },
        dismiss: true,
      }
    );
    if (value === 'DISMISSED') return;
    this.value = value;
    this.cdRef.detectChanges();
    this.valueChange.emit(value);
  }

  onDelete(propertyDefinition: ControlDefinition): void {
    const value = { ...this.value } as Dictionary<unknown>;
    delete value[propertyDefinition.name];
    this.value = value;
    this.cdRef.detectChanges();
    this.valueChange.emit(value);
  }
}
