import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { SwipeAction, SwipeDirection } from "./swipe-action";
import { SwipeActionComponent } from "./swipe-action/swipe-action.component";
import { UiCorePipesModule } from "../../pipes/ui-core-pipes.module";
import { SwipeContainerTransformPipe } from "./swipe-container-transform.pipe";

type DragDirection = 'left' | 'top' | 'right' | 'bottom'

const MAX_X_ACTION_DRAG_DISTANCE = 5;
const MAX_Y_ACTION_DRAG_DISTANCE = 3;
const EXECUTE_X_ACTION_OVERDRAW = 3;
const EXECUTE_Y_ACTION_OVERDRAW = 1;

@Component({
  selector: 'soft-swipe-container',
  standalone: true,
  imports: [CommonModule, SwipeActionComponent, UiCorePipesModule, SwipeContainerTransformPipe],
  templateUrl: './swipe-container.component.html',
  styleUrls: ['./swipe-container.component.scss']
})
export class SwipeContainerComponent {

  @Input() actions: SwipeAction[] = [];

  @Output() swipeLeft = new EventEmitter<void>();
  @Output() swipeTop = new EventEmitter<void>();
  @Output() swipeRight = new EventEmitter<void>();
  @Output() swipeBottom = new EventEmitter<void>();

  distance = 0;
  direction?: SwipeDirection;
  offsetDirection?: SwipeDirection;

  private dragXStart?: number = undefined;
  private dragYStart?: number = undefined;

  private dragXCurrent?: number = undefined;
  private dragYCurrent?: number = undefined;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  startDragging($event: TouchEvent | MouseEvent): void {
    let pageX, pageY;
    if($event.type === 'mousedown') {
      pageX = ($event as MouseEvent).pageX
      pageY = ($event as MouseEvent).pageY
    }
    else {
      pageX = ($event as TouchEvent).touches[0].pageX
      pageY = ($event as TouchEvent).touches[0].pageY
    }
    this.dragXStart = this.convertPixelsToRem(pageX);
    this.dragYStart = this.convertPixelsToRem(pageY);

    this.dragXCurrent = this.convertPixelsToRem(pageX);
    this.dragYCurrent = this.convertPixelsToRem(pageY);
  }

  @HostListener('touchmove', ['$event'])
  @HostListener('window:mousemove', ['$event'])
  onDragging($event: TouchEvent | MouseEvent): void {
    if (!this.dragXStart || ! this.dragYStart)
      return;

    let pageX, pageY;
    if($event.type === 'mousemove') {
      pageX = ($event as MouseEvent).pageX
      pageY = ($event as MouseEvent).pageY
    }
    else {
      pageX = ($event as TouchEvent).touches[0].pageX
      pageY = ($event as TouchEvent).touches[0].pageY
    }
    let dragX = this.convertPixelsToRem(pageX);
    let dragY = this.convertPixelsToRem(pageY);

    let {distance, direction} = this.getDragDistance(dragX, dragY)
    if(!direction || (this.direction && this.getAxis(direction) !== this.getAxis(this.direction)))
      return;

    this.dragXCurrent = dragX;
    this.dragYCurrent = dragY;

    let maxDistance = this.getMaxDistance(direction)

    if(this.offsetDirection) {
      const offsetDistance = this.getOffsetDistance(this.getDirectionOpposite(this.offsetDirection))
      if(this.offsetDirection === direction)
        distance -= offsetDistance;
      else {
        distance += offsetDistance;
      }
    }

    this.distance = Math.min(distance, maxDistance)
    this.direction = direction;

    if ($event.cancelable)
      $event.preventDefault();
    return;
  }

  @HostListener('touchend')
  @HostListener('window:mouseup')
  endDragging(): void {
    if (!this.dragXStart || !this.dragYStart || !this.dragXCurrent || !this.dragYCurrent || !this.direction) {
      this.reset();
      return;
    }

    let {distance, direction} = this.getDragDistance(this.dragXCurrent, this.dragYCurrent)

    const offsetDistance = this.getOffsetDistance(this.direction)
    let maxDistance = this.getMaxDistance(this.direction)

    if(this.offsetDirection) {
      const offsetDistance = this.getOffsetDistance(this.getDirectionOpposite(this.offsetDirection))
      if(this.offsetDirection === direction)
        distance -= offsetDistance;
      else
        distance += offsetDistance;
    }

    if(distance > maxDistance) {
      this.triggerSwipeEvent(this.direction)
      this.direction = undefined;
      this.offsetDirection = undefined;
      this.distance = 0;
    }
    else if (this.offsetDirection) {
      this.distance = 0;
      this.offsetDirection = undefined;
      this.direction = undefined;
    }
    else {
      this.distance = offsetDistance;
      this.offsetDirection = this.getDirectionOpposite(this.direction);
    }


    this.reset();
  }

  private reset(): void {
    this.dragXStart = undefined;
    this.dragYStart = undefined;
    this.changeDetectorRef.markForCheck();
  }

  private getDragDistance(x: number, y: number): { direction?: SwipeDirection, distance: number } {
    if(!this.dragXStart || ! this.dragYStart)
      return { direction: undefined, distance: 0 };

    const xDistance = x - this.dragXStart;
    const yDistance = y - this.dragYStart;

    if(xDistance > 0)
      return {distance: xDistance, direction: 'right'}
    else if(xDistance < 0)
      return {distance: -1 * xDistance, direction: 'left'}
    else if(yDistance > 0)
      return {distance: yDistance, direction: 'bottom'}
    else if(yDistance < 0)
      return {distance: -1 * yDistance, direction: 'top'}
    return { distance: 0, direction: undefined };
  }

  private getAxis(direction: SwipeDirection): 'x' | 'y' {
    switch (direction) {
      case 'top':
      case 'bottom':
        return 'y'
      case 'right':
      case 'left':
        return 'x'
    }
  }

  private getMaxDistance(direction: SwipeDirection): number {
    let maxDistance = this.getOffsetDistance(direction);
    if(direction === 'left' && this.swipeLeft.observed)
      maxDistance += EXECUTE_X_ACTION_OVERDRAW;
    else if(direction === 'top' && this.swipeTop.observed)
      maxDistance += EXECUTE_X_ACTION_OVERDRAW;
    else if(direction === 'right' && this.swipeRight.observed)
      maxDistance += EXECUTE_X_ACTION_OVERDRAW;
    else if(direction === 'bottom' && this.swipeBottom.observed)
      maxDistance += EXECUTE_X_ACTION_OVERDRAW;
    return maxDistance;
  }

  private getOffsetDistance(direction: SwipeDirection): number {
    const rightActions = this.actions.filter(o => o.position === 'right');
    const leftActions = this.actions.filter(o => o.position === 'left');
    const topActions = this.actions.filter(o => o.position === 'top');
    const bottomActions = this.actions.filter(o => o.position === 'bottom');

    let offsetDistance: number;
    switch (direction) {
      case "left":
        offsetDistance = rightActions.length * MAX_X_ACTION_DRAG_DISTANCE;
        break;
      case "top":
        offsetDistance = bottomActions.length * MAX_Y_ACTION_DRAG_DISTANCE;
        break;
      case "right":
        offsetDistance = leftActions.length * MAX_X_ACTION_DRAG_DISTANCE;
        break;
      case "bottom":
        offsetDistance = topActions.length * MAX_Y_ACTION_DRAG_DISTANCE;
        break;
    }
    return offsetDistance;
  }

  private triggerSwipeEvent(direction: SwipeDirection): void {
    switch (direction) {
      case "left":
        this.swipeLeft.next();
        break;
      case "top":
        this.swipeTop.next();
        break;
      case "right":
        this.swipeRight.next();
        break;
      case "bottom":
        this.swipeBottom.next();
        break;
    }
  }

  private getDirectionOpposite(direction: SwipeDirection): SwipeDirection {
    switch (direction) {
      case 'top':
        return 'bottom';
      case 'bottom':
        return 'top';
      case 'right':
        return 'left'
      case 'left':
        return 'right'
    }
  }

  convertPixelsToRem(px: number): number {
    return px / parseFloat(getComputedStyle(document.documentElement).fontSize);
  }
}
