import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, Dictionary, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SOFTLINE_API_ITEM_DETAIL} from '../artikel-info.api';
import {ItemDetail, PriceListEntry, Supplier} from '../types/item-detail';

export interface LoadDetailParameters {
  ids: number[];
  vkforgRestriction?: boolean;
}

const mockTieredPriceList: Dictionary<PriceListEntry> = {
  "1": {
    priceLevel: {
      name: "1",
      title: 'Standard'
    },
    tieredPrices: [
      {
        amount: 0,
        net: 1000,
        vat: 200,
        currency: 'EUR',
        gross: 1200,
        vatPercent: 20,
        unit: {
          name: 'STK'
        },
      },
      {
        amount: 10,
        net: 800,
        vat: 160,
        unit: {
          name: 'STK'
        },
        currency: 'EUR',
        gross: 960,
        vatPercent: 20
      }
    ],
  },
  "3": {
    priceLevel: {
      name: "3",
      title: 'Profifachmarkt'
    },
    tieredPrices: [
      {
        amount: 0,
        net: 600,
        unit: {
          name: 'STK'
        },
        vat: 120,
        currency: 'EUR',
        gross: 720,
        vatPercent: 20
      },
    ]
  }
};

const mock: ItemDetail['itemVkforg'] = [
  {
    vkforg: {
      vkforg: "Melk",
      kurzbezeichnung: "Melk",
      bezeichnung1: "",
      bezeichnung2: "",
      bezeichnung3: "",
      idfirma: 1,
      id: 1,
    },
    listing: {
      kzgelistet: "L",
      title: "Auslaufartikel",
    }
  },
  {
    vkforg: {
      vkforg: "Wieselburg",
      kurzbezeichnung: "Wieselburg",
      bezeichnung1: "",
      bezeichnung2: "",
      bezeichnung3: "",
      idfirma: 1,
      id: 1,
    },
    listing: {
      kzgelistet: "L",
      title: "Auslaufartikel",
    }
  },
  {
    vkforg: {
      vkforg: "Wigu",
      kurzbezeichnung: "Wirtschaftsgüter",
      bezeichnung1: "",
      bezeichnung2: "",
      bezeichnung3: "",
      idfirma: 1,
      id: 1,
    },
    listing: {
      kzgelistet: "L",
      title: "Auslaufartikel",
    }
  }
]

const supplierMock: Supplier = {
  supplier: {
    name: 'Lieferantenname',
    number: 331245,
    kzliefgelistet: 'H'
  },
  itemUnit: {
    id: 1,
    title: 'Stück',
    name: 'Stk'
  },
  minAmount: 24,
  foreignNumber: ""
}

@Injectable({ providedIn: 'root' })
export class ItemDetailService {
  constructor(@Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService) { }

  load(params: LoadDetailParameters): Observable<ItemDetail[]> {
    return this.service
      .create<LoadDetailParameters, any[]>({ path: SOFTLINE_API_ITEM_DETAIL }, params)
      .pipe(map((o) =>
        o.map(p => ({
          ...p,
          id: p.itemId,
        }))
      ));
  }

  loadPricelist(params: { id: number; attributes: string[], vkforgRestriction?: boolean }): Observable<ItemDetail[]> {
    return this.service
      .create<any, any[]>({ path: SOFTLINE_API_ITEM_DETAIL }, {
        ids: [params.id],
        attributes: params.attributes,
        vkforgRestriction: params?.vkforgRestriction
      })
      .pipe(map((o) =>
        o.map(p => ({
          ...p,
          id: p.itemId,
          tieredPricelist: Object.values(p?.tieredPricelist ?? {}) ?? [],
          discountTieredPricelist: Object.values(p?.discountTieredPricelist ?? {}) ?? [],
        }))
      ));
  }
}
