import { Pipe, PipeTransform } from '@angular/core';
import { SwipeDirection } from "./swipe-action";

@Pipe({
  name: 'swipeContainerTransform',
  standalone: true,
})
export class SwipeContainerTransformPipe implements PipeTransform {
  transform(distance: number, direction?: SwipeDirection): string {
    switch (direction) {
      case 'left':
        return `translateX(${-1 * distance}rem)`;
      case 'top':
        return `translateY(${-1 * distance}rem)`;
      case 'right':
        return `translateX(${distance}rem)`;
      case 'bottom':
        return `translateY(${distance}rem)`;
      default:
        return ''
    }
  }
}
