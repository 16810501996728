import { AppInfoService } from './app-info.service';
import { SOFTLINE_CONFIG_APP_INFO } from '../../application.shared';
import { Inject, Injectable, Optional } from '@angular/core';
import { AppInfo } from '../data/app-info';

@Injectable()
export class CommonAppInfoService extends AppInfoService {
  constructor(
    @Optional() @Inject(SOFTLINE_CONFIG_APP_INFO) private appInfo: AppInfo
  ) {
    super();
  }

  async getAppInfo(): Promise<AppInfo> {
    return {
      id: this?.appInfo?.id ?? '',
      name: this?.appInfo?.name ?? '',
      version: this?.appInfo?.version ?? '0.0.0',
    };
  }
}
