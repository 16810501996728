<span
  class="select-text truncate pointer"
  [class.select-placeholder]="
    !selectedOption?.element?.nativeElement?.innerHTML && !selectedOption?.value
  "
  [innerHTML]="
    selectedOption?.element?.nativeElement?.innerHTML ||
      selectedOption?.value ||
      placeholder ||
      ('#UI_CORE.COMPONENTS.SELECT.PLACEHOLDER' | translate) | secureHtml
  "
  (click)="onClick()"
></span>

<div
  *ngIf="isOpen"
  class="select-panel"
  (softClickOutside)="onOutsideClick()"
  [delayClickOutsideInit]="true"
>
  <div class="soft-scroll vertical select-panel-items" #panel>
    <ng-container *ngFor="let option of options">
      <div
        *ngIf="isHeader(option)"
        [id]="option.id"
        class="select-option-header"
      >
        {{ option.text }}
      </div>
      <div
        *ngIf="isSeparator(option)"
        [id]="option.id"
        class="select-option-separator"
      >
        <hr />
      </div>
      <div
        *ngIf="isOption(option)"
        [id]="option.id"
        class="select-option"
        [class.disabled]="option.disabled"
        [class.pointer]="!option.disabled"
        [class.selected]="option.value === value"
        [softScrollIntoView]="
          panel.scrollHeight > panel.clientHeight && value === option.value
        "
        [scrollBlock]="'nearest'"
        [scrollInline]="'nearest'"
        (click)="!option.disabled ? onSelect(option.value) : undefined"
        #currentDiv
      >
        <span
          *ngIf="(option.element.nativeElement.innerHTML || option.value); else empty"
          [innerHTML]="
            (option.element.nativeElement.innerHTML || option.value) | secureHtml
          "
        >
        </span>
        <ng-template #empty>
          <span class='text-light'>
            {{'#UI_CORE.COMPONENTS.SELECT.EMPTY' | translate}}
          </span>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>
<span
  class="soft-backdrop mobile-only z-300"
  *ngIf="isOpen"
  (click)="onOutsideClick()"
></span>

<i
  class="fa-regular fa-chevron-up pointer"
  *ngIf="!readonly && isOpen"
  (click)="onClick()"
></i>
<i
  class="fa-regular fa-chevron-down pointer"
  *ngIf="!readonly && !isOpen"
  (click)="onClick()"
></i>
