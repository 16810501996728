@if(headerTemplate$ | async; as headerTemplate) {
  <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
}

<div class="flex flex-grow overflow-auto full-width z-[0] lg:-mt-3 relative">
  @if(component$ | async; as component) {
    <ng-container *ngComponentOutlet="component; injector: injector"></ng-container>
  }
</div>


