import { inject, Injectable } from '@angular/core';
import { ConnectionHttpService, LoadObjectParameters, ReadonlyRepository, Result } from '@softline/core';
import { Objekt } from './objekt';
import { Observable, switchMap } from 'rxjs';
import { ObjektRepository } from './objekt.repository';
import { SOFTLINE_API_OBJECT_SCAN } from '../wws-core.api';

@Injectable({providedIn: 'root'})
export class ScanObjektService implements ReadonlyRepository<Result<Objekt>> {

  service = inject(ConnectionHttpService);
  objectRepository = inject(ObjektRepository);

  constructor() {
  }

  load(params?: LoadObjectParameters | undefined): Observable<Result<Objekt>> {
    return this.service.get<number>({
      path: SOFTLINE_API_OBJECT_SCAN,
      pathParams: params?.pathParams
    }).pipe(
      switchMap((result) => this.objectRepository.loadItem(result, {}))
    );
  }
}
