import { Injectable } from '@angular/core';
import { CameraService } from '@softline/core';

@Injectable()
export class BrowserCameraService extends CameraService {
  isAvailable = true;

  snap(): Promise<{ file: File }> {
    return new Promise<{ file: File }>((resolve, reject) => {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('type', 'file');
      input.setAttribute('accept', '.jpg,.jpeg,.png,.gif');
      input.addEventListener('change', (e: any) => {
        document.body.removeChild(input);
        if (input.files?.length === 1) resolve({ file: input.files[0] });
        reject('No or too many files chosen');
      });
      input.click();
    });
  }

  browse(): Promise<{ file: File }> {
    return new Promise<{ file: File }>((resolve, reject) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', '.jpg,.jpeg,.png,.gif');
      input.onchange = (e) => {
        if (input.files?.length === 1) resolve({ file: input.files[0] });
        reject('No or too many files chosen');
      };
      input.click();
    });
  }
}
