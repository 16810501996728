import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Application } from '@softline/application';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import { NavigationExtras } from "@angular/router";

@Component({
  standalone: true,
  selector: 'soft-app-grid',
  templateUrl: './app-grid.component.html',
  styleUrls: ['./app-grid.component.scss'],
  imports: [CommonModule, UiCoreModule],
})
export class AppGridComponent implements OnInit {
  @Input() application!: Application;
  @Output() navigate = new EventEmitter<Application>();

  constructor() {}

  ngOnInit(): void {}

  goToApplication(application: Application): void {
    this.navigate.emit(application);
  }
}
