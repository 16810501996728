<div class="soft-card rounded-[6px] flex w-full abteilungs-center items-center justify-between relative cursor-pointer"
     [ngClass]="mode === 'selection' && selected ? 'border border-solid border-2 border-primary' : 'border-solid border-2 border-transparent'"
     (click)="tapped.emit()">
  <!--
  <div class="flex abteilungs-center top-0 bottom-0 left-0 right-0 absolute rounded overflow-hidden" *ngIf="abteilung | anzahlMitarbeiterGesamt as anzahlMitarbeiterGesamt">
   <div class="flex flex-grow m-0 bg-success-400 h-full opacity-[90%] will-change-auto"
        @animateIn
        *ngIf="(abteilung.status?.anwesend || 0) > 0"
        [ngStyle]="{'width': (100 * (abteilung.status?.anwesend || 1)) / anzahlMitarbeiterGesamt + '%'}">
   </div>
   <div class="flex flex-grow m-0 bg-warning-300 h-full will-change-auto"
        @animateIn
        *ngIf="abteilung.status?.abwesendgelb !== null"
        [ngStyle]="{'width': (100 * (abteilung.status?.abwesendgelb || 0)) / anzahlMitarbeiterGesamt + '%'}"></div>
   @if (abteilung.status?.abwesendrot !== null) {
     <div
       @animateIn
       class="flex flex-grow m-0 bg-danger-300 h-full opacity-[90%] will-change-auto"
          [ngStyle]="{'width': ((100 * (anzahlMitarbeiterGesamt - ((abteilung.status?.anwesend || 0) + (abteilung.status?.abwesendgelb ||0)))) / anzahlMitarbeiterGesamt) + '%'}">
     </div>
   } @else {
     <div class="flex flex-grow m-0 bg-gray-200 h-full opacity-[90%]"></div>
   }
 </div> -->



  <div class="w-full flex items-center flex-row gap-2 z-10">
    @if (abteilungStatusDasharrayData; as dasharrayData) {
      <div class="flex flex-col relative">
        <svg class="donut w-14 z-10" viewBox="0 0 42 42">
          <!-- Middle -->
          <circle class="donut-hole" fill="var(--colorSurface)" cx="21" cy="21" r="15.91549430918954"></circle>

          <circle
            id="donut-segment2"
            cx="21" cy="21"
            r="15.91549430918954"
            stroke="var(--colorSuccess500)"
            stroke-width="4"
            fill="transparent"
            [attr.stroke-dasharray]="dasharrayData.anwesend.dasharray"
            [attr.stroke-dashoffset]="dasharrayData.anwesend.dasharrayOffset">
          </circle>
          <circle
            id="donut-segment3"
            cx="21"
            cy="21"
            r="15.91549430918954"
            stroke="var(--colorDanger500)"
            stroke-width="4"
            fill="transparent"
            [attr.stroke-dasharray]="dasharrayData.abwesendRot.dasharray"
            [attr.stroke-dashoffset]="dasharrayData.abwesendRot.dasharrayOffset">
          </circle>
          <circle
            id="donut-segment4"
            cx="21"
            cy="21"
            r="15.91549430918954"
            stroke="var(--colorWarning500)"
            stroke-width="4"
            fill="transparent"
            [attr.stroke-dasharray]="dasharrayData.abwesendGelb.dasharray"
            [attr.stroke-dashoffset]="dasharrayData.abwesendGelb.dasharrayOffset">
          </circle>
        </svg>

        <div class="absolute z-10 text-light font-semibold flex inset-0 text-xs text-center justify-center items-center">
          {{abteilung.status?.anwesend || 0}}
        </div>
      </div>
    }

    <div class="flex flex-grow w-100 m-0">
      <div class="flex flex-col abteilungs-start pb-1.5">
        <div class="mt-1.5 font-semibold">{{abteilung.abteilung.bezeichnung}}</div>
        <div class="mt-1.5 text-sm text-light">
          @if (abteilung.status?.anwesend !== null || abteilung.status?.abwesendgelb !== null || abteilung.status?.abwesendrot !== null) {
            {{abteilung.status?.anwesend || 0}} von {{abteilung | anzahlMitarbeiterGesamt}} {{ '#PMS_STATUSABFRAGE.ABTEILUNGEN.PERSONEN_ANWESEND' | translate }}
          } @else {
            {{ '#PMS_STATUSABFRAGE.ABTEILUNGEN.PERSONEN' | translate | interpolate:{ count: (abteilung | anzahlMitarbeiterGesamt) } }}
          }
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="mode === 'display'">
    <div class="flex items-center gap-2">


      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 opacity-25">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
      </svg>
    </div>
  </ng-container>

  <ng-container *ngIf="mode === 'selection' && selected">
    <div class="bg-surface rounded-full border border-solid border-primary border-2 p-1 z-10 flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-5 h-5 text-primary">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
    </div>
  </ng-container>
</div>
