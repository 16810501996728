import { ItemScan, Scan } from "@softline/application";
import { CustomStore, StoreBuilder, createAction, createGetter, createMutation, mutate, on, select } from "@softline/core";
import { MateriallieferscheinScanBeweStore } from "./materiallieferschein-scan-bewe.store";
import { SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_ARTIKEL_DETAIL, SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE } from "../materiallieferschein-scan.shared";
import { MateriallieferscheinScanService } from "../services/materiallieferschein-scan.service";
import { lastValueFrom } from "rxjs";
import { MateriallieferscheinScanKopf } from "../types/materiallieferschein-scan-kopf";
import { MateriallieferscheinScanArtikelDetailStore } from "./materiallieferschein-scan-artikel-detail.store";


export interface MateriallieferscheinScanState {
  itemScanResult?: ItemScan;
  kopf?: MateriallieferscheinScanKopf;
}

const mutations = {
  setItemScanResult: createMutation<MateriallieferscheinScanState, ItemScan | undefined>('mdeMateriallieferscheinScan/setItemScanResult'),
  setKopf: createMutation<MateriallieferscheinScanState, MateriallieferscheinScanKopf | undefined | null>('mdeMateriallieferscheinScan/setKopf')
};
const getters = {
  kopf: createGetter<MateriallieferscheinScanState, MateriallieferscheinScanKopf | undefined>('mdeMateriallieferscheinScan/getKopf'),
  itemScanResult: createGetter<MateriallieferscheinScanState, ItemScan | undefined>('mdeMateriallieferscheinScan/getItemScanResult'),
};
const actions = {
  loadKopf: createAction<MateriallieferscheinScanState, void, MateriallieferscheinScanKopf | undefined | null>('mdeMateriallieferscheinScan/loadKopf'),
  abschluss: createAction<MateriallieferscheinScanState, void, void>('mdeMateriallieferscheinScan/abschluss'),
  removeBeleg: createAction<MateriallieferscheinScanState, void, void>('mdeMateriallieferscheinScan/removeBeleg'),
  materiallieferscheinScan: createAction<MateriallieferscheinScanState, Scan, any>('mdeMateriallieferscheinScan/scan'),
};

const materiallieferscheinScanStore: CustomStore<
  MateriallieferscheinScanState,
  typeof mutations,
  typeof getters,
  typeof actions> = {
    actions,
    getters,
    mutations,
    feature: {
      initialState: {
        itemScanResult: undefined,
        kopf: undefined
      },
      actions: [
        on(actions.abschluss, async ({ injector, state, featureName, commit, get }) => {
          const service = injector.get(MateriallieferscheinScanService);
          const bewes = get(SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE, MateriallieferscheinScanBeweStore.getters.all);

          if (!bewes || bewes?.length < 1 || !state.kopf)
            return;

          const artikel = bewes.map(bewe => ({
            menge: bewe.menge,
            artikel: {
              id: bewe.artikel.id,
              artikelnummer: bewe.artikel.artikelnummer
            },
          }));

          await lastValueFrom(service.abschluss(state.kopf.id, { artikel }));
          commit(SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_ARTIKEL_DETAIL, MateriallieferscheinScanArtikelDetailStore.mutations.clear);
          commit(SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE, MateriallieferscheinScanBeweStore.mutations.clear);
          commit(featureName, mutations.setKopf, null);
          commit(featureName, mutations.setItemScanResult, null);
        }),
       /* on(actions.loadKopf, async ({ injector, commit, featureName }) => {
          const service = injector.get(MateriallieferscheinScanService);

          // const result = await lastValueFrom(service.loadKopf());

          commit(featureName, mutations.setKopf, result);

          return result;
        }), */
        on(actions.removeBeleg, async ({ injector, featureName, commit, state }) => {
          const service = injector.get(MateriallieferscheinScanService);

          if (!state.kopf?.id)
            return;

          await lastValueFrom(service.removeBeleg(state.kopf.id));
          commit(SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_ARTIKEL_DETAIL, MateriallieferscheinScanArtikelDetailStore.mutations.clear);
          commit(SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE, MateriallieferscheinScanBeweStore.mutations.clear);
          commit(featureName, mutations.setKopf, null);
          commit(featureName, mutations.setItemScanResult, null);
        }),
        on(actions.materiallieferscheinScan, async ({ injector, featureName, commit, params }) => {
          const service = injector.get(MateriallieferscheinScanService);

          const result = await lastValueFrom(service.materiallieferscheinScan(params));
          const kopf = await lastValueFrom(service.einlesen(result));

          commit(featureName, mutations.setKopf, kopf);

          return kopf;
        }),
      ],
      getters: [
        select(getters.kopf, ({ state }) => state?.kopf),
        select(getters.itemScanResult, ({ state }) => state?.itemScanResult),
      ],
      mutations: [
        mutate(mutations.setItemScanResult, ({ params, state }) => ({
          ...state,
          itemScanResult: params
        })),
        mutate(mutations.setKopf, ({ params, state }) => ({
          ...state,
          kopf: params ?? undefined
        }))
      ]
    }
}

export const MateriallieferscheinScanStore = new StoreBuilder(materiallieferscheinScanStore)
  .value;
