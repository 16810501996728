<div class="flex flex-col border-b w-full pb-3">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item.cartikelnummer}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item[5]}}</span>
  </div>
  <div *ngIf="item[26]" class="flex flex-row">
    <span class="text-xs">{{item[26]}}</span>
  </div>
  <div *ngIf="item[27]" class="flex flex-row">
    <span class="text-xs">{{item[27]}}</span>
  </div>
  <div *ngIf="item[28]" class="flex flex-row">
    <span class="text-xs">{{item[28]}}</span>
  </div>
  <div *ngIf="item[29]" class="flex flex-row">
    <span class="text-xs">{{item[29]}}</span>
  </div>
</div>

<div class="flex flex-col">
  <div class="flex items-center justify-between py-2 border-b" *ngIf="item['lagerstand_gesamt'] !== null && item['lagerstand_gesamt'] !== undefined">
    {{ '#WWS_CORE.FOK.ARTSTAMM.MASTER_DETAIL.LAGERSTAND' | translate }} <span>{{ (item['lagerstand_gesamt'] || 0) | number:'1.2-2' }} {{item['lagereh']}}</span>
  </div>
  <div class="flex items-center justify-between py-2 border-b" *ngIf="item['21'] !== null && item['21'] !== undefined">
    {{ '#WWS_CORE.FOK.ARTSTAMM.MASTER_DETAIL.VKPREIS' | translate }} <span>{{ (item['21'] || 0) | currency:'EUR' }}</span>
  </div>
  <div class="flex items-center justify-between py-2 border-b" *ngIf="item['22'] !== null && item['2'] !== undefined">
    {{ '#WWS_CORE.FOK.ARTSTAMM.MASTER_DETAIL.VKPREIS_INKL' | translate }} <span>{{ (item['22'] || 0) | currency:'EUR' }}</span>
  </div>
</div>
