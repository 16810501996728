@if(definition(); as definition) {
  @if(definition.visible | booleanRule: {}:true) {
    @switch (definition?.type) {
      @case ('container') {
        @for(child of definition['definitions']; track child) {
          <soft-dynamic-skeleton [definition]="child"></soft-dynamic-skeleton>
        }
      }
      @case ('list') {
        <ul class="soft-list">
          <li *softRepeat="rows()"
              [ngClass]="['soft-list-item', 'soft-list-item' + definition.type]"
          >
            @for(child of definition['definitions']; track child) {
              <soft-dynamic-skeleton [definition]="child"></soft-dynamic-skeleton>
            }
          </li>
        </ul>
      }
      @case ('object') {
        <div [ngClass]="definition.class | classRule: {}">
          @for(child of definition['definitions']; track child) {
            <soft-dynamic-skeleton [definition]="child"></soft-dynamic-skeleton>
          }
        </div>
      }
      @default {
        <div class="skeleton" [ngClass]="definition?.skeleton ?? 'h-4 w-full'"></div>
      }
    }
  }

}
