import { Store2Feature } from '../../store2-feature';
import { computed } from '@angular/core';
import { InjectableStore2Feature } from '../../injectable-store2-feature';

export class ListStore2<T> extends InjectableStore2Feature<T[]> {

  data = computed(() => this.state());
  count = computed(() => this.state().length);

  constructor() {
    super();
  }

  set(items: T[]): void {
    this.commit([...items]);
  }

  add(item: T): void {
    this.commit([...this.state(), item]);
  }

  addMany(items: T[]): void {
    this.commit([...this.state(), ...items]);
  }

  remove(item: T): void {
    const index = this.state().indexOf(item);
    if (index === -1)
      throw new Error(
        `ListStore: Can not process mutation 'remove'. Can not find item '${JSON.stringify(
          item
        )}'`
      );

    this.commit(this.state().filter((o) => o !== item));
  }

  removeMany(items: T[]): void {
    const allItems = [...this.state()];

    const notFoundItems: unknown[] = [];
    for (const item of items) {
      const index = allItems.indexOf(item);
      if (index === -1)
        notFoundItems.push(item);
      else
        allItems.splice(index, 1);
    }
    if (notFoundItems.length > 0)
      throw new Error(
        `ListStore2: Can not process mutation 'removeMany'. Can not find items '${JSON.stringify(
          notFoundItems
        )}'`
      );

    this.commit(allItems);
  }

  clear(): void {
    this.commit([]);
  }

  override getDefaultState(): T[] {
    return [];
  }
}
