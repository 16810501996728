import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, Dictionary, SOFTLINE_SERVICE_HTTP, SOFTLINE_SERVICE_UUID} from '@softline/core';
import {ItemPrice} from '../types/item-price';
import {SOFTLINE_API_ITEM_PRICE} from '../artikel-info.api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface LoadPriceParameters {
  itemId: number,
  amount: number,
  selectedOptions?: Dictionary<unknown>;
}

@Injectable({ providedIn: 'root' })
export class ItemPriceService {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string
  ) {}

  load(params: LoadPriceParameters[]): Observable<ItemPrice[]> {
    return this.service.create<LoadPriceParameters[], ItemPrice[]>({path: SOFTLINE_API_ITEM_PRICE}, params)
      .pipe(
        map((o) => o.map(p => ({...p, id: p.itemId})))
      );
  }
}
