import { isDefinedNotEmpty } from "@softline/core";
import * as moment from 'moment';
import { getFilterValueTypes } from "./get-filter-value-types";

export function getDefaultOperators(values: unknown[]): string[] {

  const types = getFilterValueTypes(values);
  const operators: string[] = []
  for(const type of types) {
    switch (type){
      case "boolean":
        operators.push('true', 'false');
        break;
      case "string":
        operators.push('equal', 'notEqual', 'like', 'notLike', 'startsWith', 'endsWith', 'regex')
        break;
      case "undefined":
        operators.push('isDefined', 'isNotDefined');
        break;
      case "number":
      case "date":
        operators.push('equal', 'notEqual', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual');
        break;
      case "object":
        operators.push('equal', 'notEqual', 'isDefinedNotEmpty', 'isNotDefinedOrEmpty', 'containsText', 'in', 'notIn')
        break;
      case "array":
        operators.push('containsText');
        break;
      default:
        break;
    }
  }
  return operators
    .filter((v, i, a) => a.indexOf(v) === i);
}
