<form
  [formGroup]="form()"
  class="soft-form"
  [ngClass]="definition()?.class"
  (ngSubmit)="onSubmit()"
  (submit)="onNativeSubmit($event)"
  (reset)="onReset($event)"
  #formRef="ngForm"
>
  @for(propertyDefinition of definition()?.definitions ?? []; track propertyDefinition) {
    @switch (propertyDefinition.type) {
      @case ('group') {
        <soft-dynamic-group-input
          class="w-full"
          [ngClass]="propertyDefinition.class | classRule: form().value"
          [definition]="$any(propertyDefinition)"
          [form]="form()"
        >
        </soft-dynamic-group-input>
      }
      @default {
        <div
          *ngIf="propertyDefinition.visible | booleanRule: form().value:true"
          [ngClass]="propertyDefinition.class | classRule: form().value"
          class="soft-dynamic-form-field"
        >
          <ng-container
            softDynamicFormField
            [definition]="propertyDefinition"
            [form]="formRef"
          ></ng-container>
        </div>
      }
    }
  }
</form>
