<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item.nummer}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item['name1']}} {{item['name2']}} {{item['name3']}}</span>
  </div>
  <div class="flex flex-row">
    <span>{{item['strasse']}}</span>
  </div>
  <div class="flex flex-row">
    <span>{{item['postleitzahl']}} {{item['ort']}}</span>
  </div>
</div>
