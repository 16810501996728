import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ListComponent,
  ModalStore,
  SOFTLINE_FEATURE_MODAL,
} from '@softline/ui-core';
import { DynamicInputAtom } from '../dynamic-input-atom';
import { DateDefinition, ArrayDefinition } from '../../../../data/definitions';
import { AddItemDialogComponent } from './add-item-dialog/add-item-dialog.component';
import { EditItemDialogComponent } from './edit-item-dialog/edit-item-dialog.component';
import { Store } from '@softline/core';

@Component({
  selector: 'lib-dynamic-array-input',
  templateUrl: './array-input.component.html',
  styleUrls: ['./array-input.component.scss'],
})
export class ArrayInputComponent
  extends DynamicInputAtom<ArrayDefinition>
  implements OnInit
{
  @ViewChild(ListComponent) list?: ListComponent<unknown>;

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {}

  async onAddClick(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'ADD_ITEM',
        component: AddItemDialogComponent,
        data: {
          definition: this.definition!.definition,
        },
        dismiss: {
          backdrop: true,
          button: true,
          escape: true,
        },
      }
    );

    if (this.list && result !== 'DISMISSED') this.list.add(result);
  }

  async onEditClick(item: unknown): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'ADD_ITEM',
        component: EditItemDialogComponent,
        data: {
          definition: this.definition!.definition,
        },
        dismiss: {
          backdrop: true,
          button: true,
          escape: true,
        },
      }
    );
    if (this.list && result !== 'DISMISSED') this.list.replace(item, result);
  }

  async onDeleteClick(item: unknown): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      { question: 'Möchten Sie dieses Element wirklich entfernen?' }
    );

    if (this.list && result === 'YES') this.list.remove(item);
  }
}
