import { Inject, Pipe, PipeTransform } from "@angular/core";
import { FilterConfigOperator } from "@softline/application";
import { equals } from "@softline/core";

@Pipe({
  name: 'multiFilterSelectOptions',
  standalone: true,
  pure: true
})
export class MultiFilterSelectOptionsPipe<T> implements PipeTransform {

  constructor() {
  }

  transform(values: T[], formatFn?: (o: unknown) => string): { label: string, value: T }[] | null {
    return values
      .filter((o, i, a) => a.findIndex(p => equals(o, p)) === i)
      .map(o => ({label: (formatFn ?? this.defaultTransformFn)(o), value: o}))
      .sort((f, s) => f.label > s.label ? 1 : (f.label < s.label ? -1 : 0))?? []
  }

  defaultTransformFn(value: unknown): string {
    if(typeof value === 'object' && value && value['id'])
      return value['id']
    else if (value === 'object' && value)
      return JSON.stringify(value)
    else
      return '' + value;
  }

}
