<div class="flex flex-col soft-card rounded w-full">
  <div class="flex flex-row w-full justify-between">
    <div class="flex flex-col w-full">
      <div class="text-xl font-semibold">{{title() | translate}}</div>
      <div class="text-sm text-light">{{subtitle() | translate}}</div>
      <div *ngIf="faelligkeitenInTotal() as faelligkeitenInTotal" [ngStyle]="{ 'color': this.color() }"
           (click)="showOffenePostenDialog()"
           class="text-2xl cursor-pointer font-bold mt-4">
        {{faelligkeitenInTotal | currencyFormat:'EUR':'symbol':'after':'1.2-2':'de-AT'}}
      </div>
    </div>
    <div class="flex flex-col w-12">
      <div class="flex flex-row self-end gap-4">
        <div class="flex w-10 h-10 rounded-full bg-tertiary-200 justify-center ml-5">
          <i class="flex self-center fa-regular fa-file-invoice-dollar text-tertiary-800"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-3">
    <div class="w-full mt-2 max-h-[25vh] min-h-[25vh]" *ngIf="chartData() as chartData" #container2Ref>
      <ngx-charts-bar-horizontal
        #chart
        class="bg-surface block mt-1"
        [view]="$any((chartData?.length || 1) < 4 ? undefined : [container2Ref.offsetWidth, ((chartData?.length || 1) * 20) + 100])"
        [scheme]="$any(chartColorScheme())"
        [legend]="false"
        [gradient]="false"
        [xAxis]="true"
        [yAxis]="true"
        [results]="chartData"
        [roundDomains]="true"
        [roundEdges]="false"
        [tooltipDisabled]="false"
        [noBarWhenZero]="true"
        [showDataLabel]="false"
        (select)="onSelect($event)"
        [xAxisTickFormatting]="formatDataLabel"
        [dataLabelFormatting]="formatDataLabel">
      </ngx-charts-bar-horizontal>
    </div>

    <!--
    <soft-tab-group [activatedIndex]="selectedTabIndex()" (activatedIndexChange)="selectedTabIndex.set($event)">
      <soft-tab title="{{ '#OFFENE_POSTEN.TABS.DIAGRAM' | translate }}" (activate)="onChartActivate()">
        @if (selectedTabIndex() === 0) {
          @if (loading()) {
            <div class="w-full mt-2 skeleton max-h-[20vh] min-h-[20vh]">
              &nbsp;
            </div>
          } @else {
            <div class="w-full mt-2 max-h-[20vh] min-h-[20vh]" *ngIf="chartData() as chartData" #container2Ref>
              <ngx-charts-bar-horizontal
                #chart
                class="bg-white block mt-1"
                [view]="$any((chartData?.length || 1) < 4 ? undefined : [container2Ref.offsetWidth, ((chartData?.length || 1) * 20) + 100])"
                [scheme]="$any(chartColorScheme())"
                [legend]="false"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [results]="chartData"
                [roundDomains]="true"
                [tooltipDisabled]="false"
                [noBarWhenZero]="true"
                [showDataLabel]="false"
                (select)="onSelect($event)"
                [xAxisTickFormatting]="formatDataLabel"
                [dataLabelFormatting]="formatDataLabel">
              </ngx-charts-bar-horizontal>
            </div>
          }
        }
      </soft-tab>
      <soft-tab title="{{ '#OFFENE_POSTEN.TABS.TABLE' | translate }}">
        @if (selectedTabIndex() === 1) {
          <div class="flex flex-col mt-4 overflow-x-auto max-h-[20vh] min-h-[20vh]">
            @if (loading()) {
              <div class="w-full -mt-2 skeleton max-h-[20vh] min-h-[20vh]">
                &nbsp;
              </div>
            } @else {
              <table class="soft-table-2">
                <thead class="md:sticky top-0">
                <tr class="md:sticky top-0">
                  <th class="md:sticky top-0 !text-left">{{ '#OFFENE_POSTEN.TABLE.HEADER.FAELLIGKEIT' | translate }}</th>
                  <th class="md:sticky top-0 !text-left">{{ '#OFFENE_POSTEN.TABLE.HEADER.NUMMER' | translate }}</th>
                  <th class="md:sticky top-0 !text-left">{{ '#OFFENE_POSTEN.TABLE.HEADER.BELEGDATUM' | translate }}</th>
                  <th class="md:sticky top-0 !text-left min-w-[3rem] max-w-[3rem]">{{ '#OFFENE_POSTEN.TABLE.HEADER.SYMBOL' | translate }}</th>
                  <th class="md:sticky top-0 !text-right min-w-[10rem]">{{ '#OFFENE_POSTEN.TABLE.HEADER.SALDO' | translate }}</th>
                  <th class="md:sticky top-0 !text-right min-w-[10rem]">{{ '#OFFENE_POSTEN.TABLE.HEADER.SKONTO' | translate }}</th>
                  <th class="md:sticky top-0 !text-right min-w-[12rem]" colspan="2">{{ '#OFFENE_POSTEN.TABLE.HEADER.SALDO_ABZGL_SKONTO' | translate }}</th>
                </tr>
                </thead>
                <tbody>
                  @for (entry of tableData(); track $index) {
                    <tr>
                      <td>{{ entry?.faelldatum | format:'DATE.DEFAULT' }}</td>
                      <td>{{ entry?.belegnummer }}</td>
                      <td>{{ entry?.belegdatum | format:'DATE.DEFAULT' }}</td>
                      <td>{{ entry?.symbol?.symbol }}</td>
                      <td class="!text-right">{{ entry?.saldo | currencyFormat }}</td>
                      <td class="!text-right">{{ entry?.skonto | currencyFormat  }}</td>
                      <td class="!text-right min-w-[10rem]">{{ entry?.saldoAbzglSkonto | currencyFormat  }}</td>
                      <td class="w-10 !text-right">
                        <div class="flex items-center justify-end">
                          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 fill-current text-primary cursor-pointer hover:text-primary-600 p-1 hover:bg-hover rounded-sm" viewBox="0 0 384 512" (click)="downloadFile(entry)">
                            <path d="M320 480H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352V448c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4H240zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zM208 240c0-8.8-7.2-16-16-16s-16 7.2-16 16V361.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l80 80c6.2 6.2 16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L208 361.4V240z"/>
                          </svg>
                        </div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            }
          </div>
        }
      </soft-tab>
    </soft-tab-group> -->
  </div>
</div>

