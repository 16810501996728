import { equals, getValue, isDefined, Validation } from "@softline/core";
import moment from "moment";
import {
  BooleanRule,
  ClassRule,
  CompareRule,
  DateRule,
  EmptyRule,
  EqualRule,
  isLogicalRule,
  isValueRule,
  LengthRule,
  LogicalRule,
  NumberRule,
  PropertyRule, RegexRule,
  RequiredRule,
  Rule,
  StringRule,
  ValidationRule,
  ValueRule
} from "../data/rules";
import { InjectFlags, Injector } from "@angular/core";
import { CustomRuleResolver, SOFTLINE_CONFIG_CUSTOM_RULE_RESOLVER } from "../dynamic.shared";

export function resolveValidationRule(
  rule: ValidationRule,
  value: any,
  injector?: Injector
): Validation {
  if (!resolveRule(rule.rule, value, injector))
    return { isValid: rule.isValid, messages: rule.messages };
  return { isValid: true, messages: [] };
}

export function resolveClassRule(rule: ClassRule, value: any, injector?: Injector): string {
  if (resolveRule(rule.rule, value, injector))
    return rule.class;
  return '';
}

export function resolveRule(rule: Rule, value: any, injector?: Injector): boolean {
  if (isLogicalRule(rule))
    return processLogicalRule(rule, value);

  if (isValueRule(rule))
    return processValueRule(rule, value);

  const customResolver = getCustomRuleResolver(rule, injector);
  if (customResolver)
    return customResolver.resolve(rule, value);

  throw Error(`Unknown rule`);
}

export function processLogicalRule(rule: LogicalRule, value: any): boolean {
  switch (rule.name) {
    case 'not':
      return !resolveRule(rule.rule, value);
    case 'and':
      for (const child of rule.rules)
        if (!resolveRule(child, value)) return false;
      return true;
    case 'or':
      for (const child of rule.rules)
        if (resolveRule(child, value)) return true;
      return false;
    case 'xor':
      let trueValues = 0;
      for (const child of rule.rules)
        if (resolveRule(child, value)) trueValues++;
      return trueValues % 2 === 1;
    case 'conditional':
      if (resolveRule(rule.propertyRule, value[rule.property]))
        return resolveRule(rule.rule, value);
      return false;
    default:
      throw Error(`Unknown logical rule`);
  }
}

export function processValueRule(rule: ValueRule, value: any): boolean {
  switch (rule.name) {
    case 'required':
      return processRequiredRule(rule as RequiredRule, value);
    case 'empty':
      return processEmptyRule(rule as EmptyRule, value);
    case 'string':
      return processStringRule(rule as StringRule, value);
    case 'boolean':
      return processBooleanRule(rule as BooleanRule, value);
    case 'number':
      return processNumberRule(rule as NumberRule, value);
    case 'date':
      return processDateRule(rule as DateRule, value);
    case 'equal':
      return processEqualRule(rule as EqualRule, value);
    case 'compare':
      return processCompareRule(rule as CompareRule, value);
    case 'property':
      return processPropertyRule(rule as PropertyRule, value);
    case 'length':
      return processLengthRule(rule as LengthRule, value);
    case 'regex':
      return processRegexRule(rule as RegexRule, value);
    default:
      throw Error(`Unknown value rule`);
  }
}

export function processRequiredRule(rule: RequiredRule, value: any): boolean {
  return isDefined(value) && (typeof value !== 'string' || value.length > 0);
}

export function processEmptyRule(rule: EmptyRule, value: any): boolean {
  return !isDefined(value) || (typeof value === 'string' && value.length === 0);
}

export function processLengthRule(rule: LengthRule, value: any): boolean {
  return (
    value?.length >= (rule.minLength ?? -1) &&
    value?.length <= (rule.maxLength ?? Number.POSITIVE_INFINITY)
  );
}

export function processRegexRule(rule: RegexRule, value: any): boolean {
  const expression = new RegExp(rule.expression, 'g')
  return expression.test(value ?? '');
}

export function processStringRule(rule: StringRule, value: any): boolean {
  if (!isDefined(value)) value = '';
  if (typeof value !== 'string')
    throw Error(
      `StringRule is not applicable for value of type ${typeof value}`
    );

  return (
    value.length <= (rule?.maxLength ?? Number.POSITIVE_INFINITY) &&
    value.length >= (rule.minLength ?? 0) &&
    (rule.pattern === undefined || !!value.match(rule.pattern))
  );
}

export function processBooleanRule(rule: BooleanRule, value: any): boolean {
  if (!isDefined(value)) return true;

  if (typeof value !== 'boolean')
    throw Error(
      `BooleanRule is not applicable for value of type ${typeof value}`
    );

  return value === rule.value;
}

export function processNumberRule(rule: NumberRule, value: any): boolean {
  if (!isDefined(value)) return true;
  if (typeof value !== 'number')
    throw Error(
      `NumberRule is not applicable for value of type ${typeof value}`
    );

  return (
    value < (rule.maxValue ?? Number.POSITIVE_INFINITY) &&
    value >= (rule.minValue ?? Number.NEGATIVE_INFINITY)
  );
}

export function processDateRule(rule: DateRule, value: any): boolean {
  if (!isDefined(value)) return true;

  if (typeof value !== 'string')
    throw Error(`DateRule is not applicable for value of type ${typeof value}`);

  const dateValue = moment(value);

  if (!dateValue.isValid()) throw Error(`${value} is not a valid iso date`);

  return (
    (rule.maxValue === undefined || dateValue.isBefore(rule.maxValue)) &&
    (rule.minValue === undefined || dateValue.isAfter(rule.minValue))
  );
}

export function processEqualRule(rule: EqualRule, value: any): boolean {
  return equals(rule.value, value);
}

export function processPropertyRule(rule: PropertyRule, value: any): boolean {
  if (typeof value !== 'object')
    throw Error(
      `PropertyRule is not applicable for value of type ${typeof value}`
    );

  const propertyValue = getValue(value, rule.property);
  return resolveRule(rule.rule, propertyValue);
}

export function processCompareRule(rule: CompareRule, value: any): boolean {
  if (typeof value !== 'object')
    throw Error(
      `CompareRule is not applicable for value of type ${typeof value}`
    );

  const value1 = getValue(value, rule.property1);
  const value2 = getValue(value, rule.property2);
  switch (rule.operator) {
    case '==':
      return equals(value1, value2);
    case '!=':
      return !equals(value1, value2);
    case '>':
      return value1 > value2;
    case '>=':
      return value1 >= value2;
    case '<':
      return value1 < value2;
    case '<=':
      return value1 <= value2;
    default:
      throw Error(`Unknown operator ${rule.operator}`);
  }
}

export function getCustomRuleResolver(rule: Rule, injector?: Injector): CustomRuleResolver | undefined {
  if(!injector)
    return undefined;
  const resolvers = injector.get(SOFTLINE_CONFIG_CUSTOM_RULE_RESOLVER, []);
  return resolvers.find(o => o.name === rule.name);
}
