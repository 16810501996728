<div [softScrollIntoView]="true" scrollBehavior="instant"></div>

<div class="flex flex-col-reverse full-width bg-surface lg:bg-transparent -mt-2 sticky -top-2 !z-10">
  <div class="lg:-mt-3 w-full p-4 bg-surface border-b border-solid">
    <div class="flex flex-row mb-1">
      <soft-filter-and-sort
        #filterAndSortComponent
        class="w-full mt-2"
        [filter]="filter$ | async"
        [sort]="sort$ | async"
        [values]="(bestellungen$ | async) || []"
        [filterConfig]="filterConfig"
        [sortConfig]="sortConfig"
        (filterChange)="onFilterChange($event)"
        (sortChange)="onSortChange($event)">
      </soft-filter-and-sort>
    </div>
  </div>
  <ng-container *ngIf="(lieferant$ | async) as lieferant">
    <soft-step-header [steps]="steps" [currentIndex]="1" #stepper class="bg-surface"></soft-step-header>
    <soft-default-header [overline]="stepper.stepText"
                      [title]="stepper.current()?.header"
                      [subtitle]="stepper.current()?.subHeader + lieferant.kurzbez">
    </soft-default-header>
  </ng-container>
</div>
<ng-container *ngIf="(loading$ | async) === false; else skeleton">
  <ng-container *ngIf="(filteredItems$ | async) as bestellungen">
    <div class="flex flex-grow overflow-auto full-width w-full z-[0] lg:-mt-3 relative">
      <div class="flex flex-col w-full px-4 mt-3 pb-2 lg:mt-6">
        <soft-accordion class="flex flex-col gap-2 w-full select-none" [multi]="true">
          <soft-accordion-item
            #bestellungAccordionItem
            *ngFor="let bestellung of bestellungen; let bestellungLast = last"
            [class]="(bestellung.id | in:selectedBestellungen()) ? 'border-2 border-solid !border-primary' : 'border-2 border-solid border-transparent'">
            <div
              class="soft-card flex flex-row gap-4 w-full border-b border-b-transparent border-b-solid items-center justify-between cursor-pointer !p-3"
              [ngClass]="{
                'rounded-sm': !bestellungAccordionItem.expanded,
                'rounded-t-sm !border-b': bestellungAccordionItem.expanded,
              }"
              *softAccordionItemHeader>
              <soft-checkbox
                [class]="'flex flex-col w-auto'"
                [checked]="(bestellung.id | in:selectedBestellungen())"
                (valueChange)="selectOrUnselectBestellung(bestellung)"
                (click)="$event.stopPropagation()">
              </soft-checkbox>
              <div class="flex flex-row w-full">
                <div
                  class="flex flex-col w-4/5 gap-1"
                  (click)="selectOrUnselectBestellung(bestellung)">
                  <div class="flex flex-row w-full justify-items-start">
                    <div class="flex text-sm text-light">
                    <span *ngIf="bestellung.id">
                      {{bestellung.id}}
                    </span>
                    </div>
                  </div>
                  <div class="flex flex-row w-full justify-items-start">
                    <div class="flex font-semibold">
                    <span *ngIf="bestellung.bestellnummer">
                      {{'#WWS_WARENUEBERNAHME.BESTELLUNG.LABEL_BESTELLNUMMER' | translate}} {{bestellung.bestellnummer}}
                    </span>
                    </div>
                  </div>
                  <div class="flex flex-row w-full justify-items-start">
                    <div class="text-light text-sm">
                      {{bestellung.bewegungen.length}} {{bestellung.bewegungen.length === 1 ? ('#WWS_WARENUEBERNAHME.BESTELLUNG.LABEL_ENTHALTENE_BEWEGUNG' | translate) : ('#WWS_WARENUEBERNAHME.BESTELLUNG.LABEL_ENTHALTENE_BEWEGUNGEN' | translate)}}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col w-1/5 items-end self-center mr-2">
                  <div class="flex flex-col w-auto">
                    <button
                      class="soft-button primary h-10 rounded-sm"
                      [disabled]="bestellung.bewegungen.length <= 0"
                      (click)="bestellungAccordionItem.toggle()">
                      <ng-container *ngIf="bestellungAccordionItem.expanded">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                        </svg>
                      </ng-container>

                      <ng-container *ngIf="!bestellungAccordionItem.expanded">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                      </ng-container>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div *softAccordionItemContent="bestellungAccordionItem.expanded" (click)="selectOrUnselectBestellung(bestellung)">
              <div *ngFor="let bewegung of bestellung.bewegungen; let last = last"
                   class="soft-card flex flex-col w-full gap-1 !p-3 border-b border-b-solid border-b-transparent"
                   [ngClass]="{
                   'rounded-b-sm': last,
                   '!border-b': !last,
                   }">
                <div class="text-sm text-light">
                  <span *ngIf="(bewegung?.lfdnr?.toString()?.length ?? 0) === 1">00{{bewegung?.lfdnr}}</span>
                  <span *ngIf="(bewegung?.lfdnr?.toString()?.length ?? 0) === 2">0{{bewegung?.lfdnr}}</span>
                  <span *ngIf="(bewegung?.lfdnr?.toString()?.length ?? 0) >= 3">{{bewegung?.lfdnr}}</span>
                </div>
                <div class="flex flex-row justify-between w-full">
                  <div class="flex text-base font-semibold">
                    {{bewegung?.artikelMenge?.artikel?.artbez1 ?? ''}}
                  </div>
                  <div class="flex">
                    {{bewegung?.artikelMenge?.menge_lg}} {{ (bewegung?.artikelMenge?.einheit_lg?.arteh ?? '') | uppercase}}
                  </div>
                </div>
                <div class="text-sm">
                  {{'#WWS_WARENUEBERNAHME.BEWEGUNG.LABEL_ARTIKELNUMMER' | translate}} {{bewegung.artikelMenge?.artikel?.nummer ?? ''}}
                </div>
                <div
                  *ngIf="bewegung?.zeilentext && bewegung?.zeilentext !== ''"
                  class="text-sm text-light">
                  {{bewegung?.zeilentext}}
                </div>
              </div>
            </div>
          </soft-accordion-item>
        </soft-accordion>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #skeleton>
  <div class="flex flex-col w-full">
    <div class="flex flex-row mt-3 gap-4 items-center h-[91px] w-full soft-card !p-3 rounded-sm" *softRepeat="10">
      <div class="flex flex-col h-full justify-center w-auto">
        <div class="skeleton rounded w-[56px] h-[40px]"></div>
      </div>
      <div class="flex flex-col justify-between h-full w-full">
        <div class="skeleton rounded h-4 w-20"></div>
        <div class="skeleton rounded h-4 w-28"></div>
        <div class="skeleton rounded h-4 w-20"></div>
      </div>
    </div>
  </div>
</ng-template>
