import {Step} from '@softline/application';

export const SOFTLINE_CONFIG_MDE_BESTELLVORSCHLAG_STEPS: Step[] = [
  {
    header: 'Artikel suchen',
    subHeader: 'Bitte gewünschten Artikel auswählen',
  },
  {
    header: 'Artikeldaten',
    subHeader: 'Bitte passen Sie die Artikeldaten an',
  }
]

export const SOFTLINE_CONFIG_MDE_INVENTUR_START_STEPS: Step[] = [
  {
    header: 'Lager-Auswahl',
    subHeader: 'Bitte Lager für die Inventur auswählen!',
  },
  {
    header: 'Lagerplatz',
    subHeader: 'Bitte Lagerplatz eingeben oder scannen!',
  },
  {
    header: 'Belegdatum',
    subHeader: 'Bitte gewünschtes Belegdatum eingeben'
  }
]

export const SOFTLINE_FEATURE_MDE_INVENTUR = 'mdeInventur'
export const SOFTLINE_FEATURE_MDE_INVENTUR_BEWE = 'mdeInventurBewe'
export const SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS = 'mdeInventurArtikelDetails';

export const SOFTLINE_PERMISSION_MDE_INVENTUR = 'INVENTUR'
