import { ArchiveFile, isArchiveFile } from './archive';
import { isDefined } from '@softline/core';

export interface ArchivePreview {
  file: ArchiveFile;
  files: readonly ArchiveFile[];
}

export function isArchivePreview(value: unknown): value is ArchivePreview {
  return (
    isDefined((value as ArchivePreview)?.file) &&
    isArchiveFile((value as ArchivePreview)?.file)
  );
}
