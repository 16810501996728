import {Injectable} from '@angular/core';
import {ConnectionHttpService, ConnectionResourceLocation, ResponseResult} from '@softline/core';
import {map, Observable} from 'rxjs';
import {SOFTLINE_API_BESTELLVORSCHLAG_SCAN, SOFTLINE_API_MDE_BESTELLERFASSUNG_ABSCHLUSS, SOFTLINE_API_MDE_BESTELLERFASSUNG_KOPF} from '../bestellerfassung.api';
import { BestellerfassungKopf } from '../data/bestellerfassung-kopf';
import {Scan} from '@softline/application';
import {BestellvorschlagScanResult} from '../data/bestellvorschlag-scan-result';

@Injectable()
export class BestellerfassungService {

  private get location(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_BESTELLERFASSUNG_ABSCHLUSS,
    }
  }

  private get kopfLocation(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_BESTELLERFASSUNG_KOPF,
    }
  }

  private bestellvorschlagScanLocation(code: string): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_BESTELLVORSCHLAG_SCAN,
      pathParams: { code }
    }
  }

  constructor(private httpService: ConnectionHttpService) {}

  abschluss(ediSchicken: boolean): Observable<void> {
    return this.httpService.create(this.location, { ediSchicken });
  }

  getKopf(): Observable<BestellerfassungKopf> {
    return this.httpService.get<ResponseResult<BestellerfassungKopf>>(this.kopfLocation).pipe(
      map(o => o?.result)
    );
  }

  removeBeleg(): Observable<unknown> {
    return this.httpService.delete<ResponseResult<BestellerfassungKopf>>(this.kopfLocation);
  }

  bestellvorschlagScan(scan: Scan): Observable<BestellvorschlagScanResult> {
    return this.httpService.get<ResponseResult<BestellvorschlagScanResult>>(this.bestellvorschlagScanLocation(scan.data)).pipe(
      map(o => o?.result)
    );
  }

  bestellungFromBestellvorschlag(idbeleg: number): Observable<BestellerfassungKopf> {
    return this.httpService.create<object, ResponseResult<BestellerfassungKopf>>(
      this.kopfLocation,
      {
        idbeleg,
        variante: 'BESTELLVORSCHLAG'
      }
    ).pipe(
      map(o => o?.result)
    );
  }
}
