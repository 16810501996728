import {FilterConfig} from '@softline/application';

export const abteilungenFilterConfig: FilterConfig = {
  properties: [
    {
      property: 'abteilung',
      title: 'Abteilung ID',
      params: {
        formatFn: (o: any) => `${o.id}`
      }
    },
    {
      property: 'abteilung.bezeichnung',
      title: 'Abteilung-Bezeichnung',
      params: {
        formatFn: (o: any) => `${o}`
      }
    },
    {
      property: 'mitarbeiterGesamt',
      title: 'Anzahl Mitarbeiter Gesamt',
    },
    {
      property: 'anwesend',
      title: 'Anwesende Mitarbeiter'
    }
  ]
};

export const userFilterConfig: FilterConfig = {
  properties: [
    {
      property: 'lstfix',
      title: 'Personalnummer',
      params: {
        formatFn: (o: any) => `${o.persnr}`
      }
    },
    {
      property: 'lstfix.vorname',
      title: 'Vorname',
      params: {
        formatFn: (o: any) => `${o}`
      }
    },
    {
      property: 'lstfix.zuname',
      title: 'Zuname',
      params: {
        formatFn: (o: any) => `${o}`
      }
    },
    {
      property: 'status',
      title: 'Status',
      params: {
        formatFn: (o: 'A' | 'E' | 'U') => {
          switch (o) {
            case 'A':
              return 'Anwesend'
            case 'E':
              return 'Fehlt erwartet'
            case 'U':
              return 'Fehlt unerwartet'
          }
        }
      }
    }
  ]
}
