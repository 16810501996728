import { InjectionToken } from '@angular/core';

export type CloudMessagingPermission = 'default' | 'denied' | 'granted';
export interface CloudMessagingTopic {
  key: string;
  title: string;
}

export const SOFTLINE_CONST_TOPIC_PREFIX = 'topic_';
export const SOFTLINE_FEATURE_CLOUD_MESSAGING = 'cloudMessaging';

export const SOFTLINE_CONFIG_ONE_SIGNAL_APP_ID = new InjectionToken<string>(
  'SOFTLINE_CONFIG_ONE_SIGNAL_APP_ID'
);
export const SOFTLINE_CONFIG_CLOUD_MESSAGING_TOPICS = new InjectionToken<
  CloudMessagingTopic[]
>('SOFTLINE_CONFIG_CLOUD_MESSAGING_TOPICS');

export const SOFTLINE_SETTINGS_CLOUD_MESSAGING = 'cloudMessaging';
