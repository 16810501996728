import { Provider } from "@angular/core";
import { SOFTLINE_CONFIG_WIDGETS } from "@softline/ui-core";
import { SOFTLINE_WIDGET_SIDE_MENU_USER_INFO } from "@softline/application";
import { FirmaContextWidgetComponent } from "./widgets/firma-context-widget.component";
import { FirmaContextComponent } from "./components/firma-context.component";
import { SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT } from "../../utilities.shared";

export const firmaContextProviders: Provider[] = [
  {
    provide: SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
    useValue: FirmaContextComponent,
    multi: true,
  },
  {
    provide: SOFTLINE_CONFIG_WIDGETS,
    multi: true,
    useValue: {
      outlet: SOFTLINE_WIDGET_SIDE_MENU_USER_INFO,
      component: FirmaContextWidgetComponent,
    },
  },
];
