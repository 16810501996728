<ng-container *ngIf="userAgentService.isMobile()">
  <input
    type="time"
    [value]="value | format: format || 'TIME.DEFAULT':{ utcOffset: utcOffset }"
    (change)="setValue(input.value)"
    #input
    [readOnly]="readonly"
    (blur)="onTouch()"
    placeholder="{{
      placeholder || '#UI_CORE.COMPONENTS.TIME_INPUT.PLACEHOLDER' | translate
    }}"
  />
</ng-container>
<ng-container *ngIf="userAgentService.isDesktop()">
  <input
    [value]="value | format: format || 'TIME.DEFAULT':{ utcOffset: utcOffset }"
    [readOnly]="readonly"
    (blur)="onTouch()"
    [ngClass]="inputClass"
    placeholder="{{
      placeholder || '#UI_CORE.COMPONENTS.TIME_INPUT.PLACEHOLDER' | translate
    }}"
    (change)="setValue(input.value)"
    #input
  />
</ng-container>
