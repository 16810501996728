import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {SOFTLINE_API_ITEM_STOCK} from '../artikel.api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ArtikelLagerMenge} from '../types/artikel-lager-menge';

export interface LoadStockParameters {
  ids: number[]
}

@Injectable()
export class ArtikelLagerMengenService {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService,
  ) {}


  load(id: number): Observable<ArtikelLagerMenge | null> {
    const body = { ids: [id] }
    return this.service.create<typeof body, ArtikelLagerMenge[]>({ path: `${SOFTLINE_API_ITEM_STOCK}/amount` }, body).pipe(
      map(result => result && result.length > 0 ? result[0] : null)
    )
  }

  loadMany(params: LoadStockParameters): Observable<ArtikelLagerMenge[]> {
    const location = { path: `${SOFTLINE_API_ITEM_STOCK}/amount` }
    return this.service.create<LoadStockParameters, ArtikelLagerMenge[]>(location, params).pipe(
      map((o) => o.map(p => ({ ...p, id: p.itemId }))),
    );
  }
}
