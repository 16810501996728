import { isDefined } from './is-defined.function';
import { str } from "@trapezedev/configure/dist/ctx";

export function getValue(value: any, property: string | number | symbol): any {
  let keys: any[] = [property];
  if(typeof property === 'string')
    keys = property.split('.');

  property = '';
  do {
    property += keys.shift();
    if (
      isDefined(value) &&
      value[property] !== undefined &&
      (typeof value[property] === 'object' || !keys.length)
    ) {
      value = value[property];
      property = '';
    } else if (!keys.length) value = undefined;
    else property += '.';
  } while (keys.length);

  return value;
}
