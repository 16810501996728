import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@softline/core';
import { SOFTLINE_FEATURE_AUTHORIZATION } from './authorization.const';
import * as AuthorizationStore from './authorization.store';
import { AuthorizationService } from './authorization.service';
import { Store } from '@softline/core';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class AuthorizationModule {
  static forRoot(): ModuleWithProviders<AuthorizationRootModule> {
    return {
      ngModule: AuthorizationRootModule,
      providers: [AuthorizationService],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_AUTHORIZATION,
      feature: AuthorizationStore.feature,
    }),
  ],
  exports: [],
})
export class AuthorizationRootModule {}
