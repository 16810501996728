<form class="mt-2"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      #formRef="ngForm">
  <div class="gap-2 grid">
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Anrede</label>
      <soft-input formControlName="anrede" softFieldValidation></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Name 1</label>
      <soft-input formControlName="name_1" softFieldValidation></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Name 2</label>
      <soft-input formControlName="name_2" softFieldValidation></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Name 3</label>
      <soft-input formControlName="name_3" softFieldValidation></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Strasse</label>
      <soft-input formControlName="strasse" softFieldValidation></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">PLZ</label>
      <soft-query-field-ok name="orte" formControlName="ort" title="PLZ"  softFieldValidation></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Ort</label>
      <soft-query-field-ok name="orte" formControlName="ort" title="Ort" softFieldValidation></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Kunde</label>
      <soft-query-field-ok name="ktostamm.kunde" formControlName="kundennummer" title="Kundennummer" softFieldValidation></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Kundebezeichnung</label>
      <soft-input formControlName="kurzbezeichnung" softFieldValidation></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Kundengruppe</label>
      <soft-query-field-ok name="kundengruppe" formControlName="kundengruppe" title="Kundengruppe" softFieldValidation></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Zahlungsart</label>
      <soft-query-field-ok name="zahlungsart" formControlName="zahlungskennzeichen" title="Zahlungsart" softFieldValidation></soft-query-field-ok>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Email</label>
      <soft-input formControlName="mail" softFieldValidation></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">GLN</label>
      <soft-input formControlName="gln" softFieldValidation></soft-input>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top required-group-1">Zertifizierung</label>
      <soft-query-field-ok name="lwbbn" formControlName="lwbbn" title="Zertifizierung" softFieldValidation></soft-query-field-ok>
    </div>

    <div class="flex flex-col">
      <label class="soft-label top">Lieferadresse</label>
      <soft-radio-group class="flex flex-row w-full" formControlName="lieferadresse">
        <soft-radio-option class="w-full mr-4" [value]="0">
          <span class="soft-label right">Kunden</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="1">
          <span class="soft-label right">Lieferadresse</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="2">
          <span class="soft-label right">Alle</span>
        </soft-radio-option>
      </soft-radio-group>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Gelöscht</label>
      <soft-radio-group class="flex flex-row w-full" formControlName="geloescht">
        <soft-radio-option class="w-full mr-4" [value]="0">
          <span class="soft-label right">Nicht gelöscht</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="1">
          <span class="soft-label right">Gelöscht</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="2">
          <span class="soft-label right">Alle</span>
        </soft-radio-option>
      </soft-radio-group>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Grosskunde</label>
      <soft-radio-group class="flex flex-row w-full" formControlName="grosskunde">
        <soft-radio-option class="w-full mr-4" [value]="0">
          <span class="soft-label right">Ohne Großkunden</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="1">
          <span class="soft-label right">Nur Großkunden</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="2">
          <span class="soft-label right">Alle</span>
        </soft-radio-option>
      </soft-radio-group>
    </div>
    <div class="flex flex-col">
      <label class="soft-label top">Aktiv</label>
      <soft-radio-group class="flex flex-row w-full" formControlName="KUNDENABFRAGE_KENNZEICHEN_AKTIV">
        <soft-radio-option class="w-full mr-4" [value]="'ALLE'">
          <span class="soft-label right">Alle</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="'JA'">
          <span class="soft-label right">Aktiv</span>
        </soft-radio-option>
        <soft-radio-option class="w-full mr-4" [value]="'NEIN'">
          <span class="soft-label right">Nicht aktiv</span>
        </soft-radio-option>
      </soft-radio-group>
    </div>
    <div class="flex flex-row w-full">
      <soft-checkbox class="w-full mr-4" formControlName="baufuehrung">
        <span class="soft-label right">Bauführung</span>
      </soft-checkbox>
      <soft-checkbox class="w-full" formControlName="baufuehrung_provision">
        <span class="soft-label right">Provisionsabrechnung</span>
      </soft-checkbox>
    </div>
    <soft-checkbox formControlName="nurRechnungskunden">
      <span class="soft-label right">nur Rechnungskunden</span>
    </soft-checkbox>
  </div>
</form>
