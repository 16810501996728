<div class="flex flex-col gap-4">
  <div class=" flex flex-col px-4 sm:px-0">
    <div class="text-lg font-semibold mt-2">
      {{'#WWS_WARENUEBERNAHME.DIALOG.TITEL_WARENUEBERNAHME_ABSCHLIESSEN' | translate}}
    </div>
    <div class="mt-2">
      {{'#WWS_WARENUEBERNAHME.DIALOG.LABEL_WARENUEBERNAHME_ABSCHLIESSEN' | translate}}
    </div>
  </div>

  <div class="flex flex-row w-full sm:gap-2">
    <button
      class="soft-button secondary w-1/2 mt-2 h-16"
      (click)="close(false)">
      {{'#WWS_WARENUEBERNAHME.DIALOG.LABEL_ABBRECHEN' | translate}}
    </button>
    <button
      class="soft-button primary w-1/2 mt-2 h-16"
      (click)="close(true)">
      {{'#WWS_WARENUEBERNAHME.DIALOG.LABEL_FORTFAHREN' | translate}}
    </button>
  </div>
</div>
