export const environment = {
  production: true,
  connection: {
    name: 'Hagebau Ebster Softline',
    host: 'https://softline.baumarkt-ebster.at',
    port: 443,
    basePath: '/restapi/api'
  },
  androidUpdateRepository: ''
};
