import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim',
  pure: true,
})
export class TrimPipe implements PipeTransform {
  transform(
    value: string | null | undefined,
    chars: string
  ): string | null | undefined {
    if (!value) return value;
    if (chars.length === 0) return value.trim();

    for (const char of chars) value = this.trim(value, char);
    return value;
  }

  private trim(value: string, char: string): string {
    if (char === '(') char = '\\(';
    if (char === ')') char = '\\)';
    if (char === '[') char = '\\[';
    if (char === ']') char = '\\]';
    if (char === '\\') char = '\\\\';
    return value.replace(
      new RegExp('^[' + char + ']+|[' + char + ']+$', 'g'),
      ''
    );
  }
}
