import { computed, inject, Injectable } from '@angular/core';
import { Store2Feature } from '../store2/store2-feature';
import {
  SOFTLINE_CONFIG_DEFAULT_LANGUAGE,
  SOFTLINE_CONFIG_LANGUAGES,
  SOFTLINE_FEATURE_LANGUAGE
} from './language.shared';
import { isDefined } from '../functions/is-defined.function';
import { LanguageService } from './language.service';
import { Language } from './languages';

export interface LanguageState {
  selected: string;
  languages: Language[];
}

@Injectable()
export class LanguageStore2 extends Store2Feature<LanguageState> {

  override name = SOFTLINE_FEATURE_LANGUAGE;

  service = inject(LanguageService);
  defaultLanguage = inject(SOFTLINE_CONFIG_DEFAULT_LANGUAGE);

  languages = computed(() => this.state().languages);
  selectedCode = computed(() => this.state().selected);
  selected = computed(() => this.languages()[this.selectedCode()]);

  constructor() {
    super();
  }

  async init(): Promise<void> {
    const languages = inject(SOFTLINE_CONFIG_LANGUAGES);
    let code = languages.find(o => o.code === this.defaultLanguage)?.code
    if(!code)
      code = languages[0].code;

    this.commitPatch({selected: code, languages});
    if(this.languages.length > 1)
      await this.loadSelected();
  }

  async select(lang: string): Promise<void> {
    const index = this.languages().findIndex(o => o.code === lang);
    if(index === -1)
      throw new Error(`[LanguageStore] select: No language with code ${lang} registered`);
    this.commitPatch({selected: lang})
    try {
      await this.service.save(this.selected());
    }
    catch (e) {
      throw new Error('[LanguageStore]: Cannot save language')
    }
  }

  async loadSelected(): Promise<void> {
    let language: string;
    try {
      language = await this.service.load();
    }
    catch (e) {
      language = this.defaultLanguage;
    }
    language = this.getRegisteredLanguageCode(language);
    this.commitPatch({selected: language});
  }

  private getRegisteredLanguageCode(language: string): string {
    const languages = this.languages();
    let code = languages.find(o => o.code === language)?.code;
    if(!isDefined(code)) {
      const languageCode = language.split('-')[0];
      code = languages.find(o => o.code === languageCode)?.code;
      if(!isDefined(code)) {
        code = languages.find(o => o.code.split('-')[0] === languageCode)?.code;
        if(!isDefined(code)) {
          code = this.defaultLanguage;
        }
      }
    }
    return code;
  }

  override getDefaultState(): LanguageState {
    return {
      selected: this.defaultLanguage,
      languages: []
    };
  }
}
