import {Component, input, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import { SOFTLINE_FEATURE_WWS_KREDITLIMIT, WwsKreditlimitStore } from '../../kreditlimit.shared';
import { handleRequestErrors } from '@softline/application';
import { AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from '@softline/auth';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { combineLatestWith, map } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import {toSignal} from '@angular/core/rxjs-interop';

@Component({
  selector: 'soft-wws-kreditlimit-widget',
  standalone: true,
  templateUrl: './kreditlimit-widget.component.html',
  styleUrls: ['./kreditlimit-widget.component.scss'],
  imports: [RouterModule, CommonModule, NgxChartsModule],
  animations: [
    trigger('animateIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('1000ms 100ms cubic-bezier(.53,.6,.38,1.04)', style({ transform: '{{offset}}'})),
      ]),
    ]),
    trigger('animateIn2', [
      transition(':enter', [
        style({ transform: 'translateX(0%)' }),
        animate('1000ms 100ms cubic-bezier(.53,.6,.38,1.04)', style({ transform: '{{offset}}'})),
      ]),
    ]),
  ]
})
export class KreditlimitWidgetComponent implements OnInit, OnDestroy {

  idktostamm = input<number | null>(null)

  readonly data$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_KREDITLIMIT,
    WwsKreditlimitStore.getters.data
  );

  readonly data = this.store.signal(
    SOFTLINE_FEATURE_WWS_KREDITLIMIT,
    WwsKreditlimitStore.getters.data
  );

  readonly kreditdaten$ = this.data$.pipe(
    map(data => {
      return {
        kreditlimit1: data?.kreditlimit1 ?? 0,
        kreditlimit2: data?.kreditlimit2 ?? 0,
        ueberschritten: data?.kreditlimitUeberschritten ?? false,
        saldoGesamt: data?.saldoGesamt ?? 0,
      }
    })
  );

  readonly kreditlimitUeberschritten = toSignal(
    this.kreditdaten$.pipe(
      map(o => o?.ueberschritten)
    )
  );

  readonly chartData$ = this.data$.pipe(
    map((data) => {
      return [
        {
          name: 'Auftrag',
          value: data?.offenerSaldoAuftrag ?? 0
        },
        {
          name: 'Auftrag Strecke',
          value: data?.offenerSaldoAuftragStrecke ?? 0
        },
        {
          name: 'Fälligkeitsdatum Konto',
          value: data?.offenerSaldoFaelldatumKonto ?? 0
        },
        {
          name: 'Konto',
          value: data?.offenerSaldoKonto ?? 0
        },
        {
          name: 'Lieferschein',
          value: data?.offenerSaldoLieferschein ?? 0
        },
        {
          name: 'Rechnung',
          value: data?.offenerSaldoRechnung ?? 0
        }
      ]
    })
  );

  readonly gesamtKreditlimit2TranslateX$ = this.kreditdaten$.pipe(
    map((data) => {
      const offset = Math.min(((data?.kreditlimit2 - data.saldoGesamt) / data?.kreditlimit2) * 100, 100);
      return `translateX(-${offset}%)`;
    })
  );

  readonly gesamtKreditlimitTranslateX$ = this.kreditdaten$.pipe(
    map((data) => {
      const offset = Math.min(((data.saldoGesamt / data?.kreditlimit2) * 100), 100);
      return `translateX(${offset}%)`;
    })
  );

  constructor(private store: Store, protected router: Router) {}

  async ngOnInit(): Promise<void> {
    const context = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    ) as Record<string, unknown>;

    if (!this.idktostamm() && (!context || !context?.['accountid']))
      return;

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_KREDITLIMIT,
        WwsKreditlimitStore.actions.load,
        {
          pathParams: { idktostamm: this.idktostamm() ?? context['accountid'] }
        }
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_KREDITLIMIT,
      WwsKreditlimitStore.mutations.unset,
    );
  }

  onSelect(event: any): void {
    console.log(event);
  }

}
