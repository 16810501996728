import { Observable } from "rxjs";
import { Template } from "../template";
import { ResourceService } from "@softline/core";

export abstract class TemplateService {
  constructor() {}

  abstract loadMany(group: string | undefined): Observable<Template<any>[]>

  abstract create(template: Template<any>): Observable<Template<any>>

  abstract update(template: Template<any>): Observable<Template<any>>

  abstract delete(template: Template<any>): Observable<Template<any>>
}
