import {
  ComponentRef,
  createComponent,
  Directive,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewContainerRef
} from "@angular/core";
import { FilterConfig, FilterConfigOperator } from "../../../../types/filter-config";
import { getValue, PropertyFilter } from "@softline/core";
import { SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT } from "../../../../filter-and-sort.shared";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[softFilterValue]',
  standalone: true
})
export class FilterValueDirective implements OnInit {

  private componentRef?: ComponentRef<any>

  private _filter: Partial<PropertyFilter> | null | undefined;
  @Input('softFilterValue')
  get filter(): Partial<PropertyFilter> | null | undefined
  {
    return this._filter;
  }
  set filter(value: Partial<PropertyFilter> | null | undefined) {
    this._filter = value
    this.componentRef?.destroy();
    this.vcRef.clear();
    if(value)
      this.createComponent();
  }
  @Output() filterChange = new EventEmitter<Partial<PropertyFilter>>();

  private _values: object[] = []
  @Input('values')
  get values(): object[]
  {
    return this._values;
  }
  set values(value: object[]) {
    this._values = value
    this.componentRef?.destroy();
    this.vcRef.clear();
    if(value)
      this.createComponent();
  }

  @Input('config') config?: FilterConfig | undefined;


  constructor(private vcRef: ViewContainerRef,
              @Inject(SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT) private registeredOperators: FilterConfigOperator[]) {}

  ngOnInit(): void {
    this.createComponent();
  }

  private createComponent(): void {
    const operator = this.registeredOperators.find(o => o.operator === this.filter?.operator)
    if(!this.filter || !operator?.componentFactory || !this.config)
      return;

    const values = this.values
      ?.map(o => getValue(o, this.filter!['property'] ?? ''))
      ?.filter((v, i, a) => a.indexOf(v) === i);
    const propertyConfig = this.config?.properties.find(o => o.property === this.filter?.property)

    this.componentRef = operator.componentFactory(
      {
        vcRef: this.vcRef,
        filter: this.filter,
        values: values,
        config: propertyConfig,
        filterChange: (value) => this.filterChange.emit(value)
      });
  }
}
