<div class="flex flex-col mt-4 sm:mt-0 px-4 sm:px-0">
  <div class="flex flex-col gap-0.5 mb-2 pb-1">
    <h2 class="text-lg font-semibold">{{ (title || '#UI_CORE.DIALOGS.DATE') | translate }}</h2>
    <p *ngIf="subtitle" class="text-sm font-normal text-light">{{ subtitle | translate }}</p>
  </div>

  <soft-date-picker
    class="date-picker"
    [value]="value ?? today"
    (valueChange)="close($event)"
    #picker
  ></soft-date-picker>
</div>

