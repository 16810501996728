import { ResourceService } from '../../data/abstraction';
import { CRUDParameters, Repository } from '../abstraction';
import { StorageResourceLocation } from '../../data/specialized/storage/storage.location';
import { interpolate } from '../../functions/interpolate.function';
import { Observable } from 'rxjs';
import { ReadonlyStorageRepository } from './readonly-storage.repository';

export class StorageRepository<T> extends ReadonlyStorageRepository<T> implements Repository<T, T> {

  constructor(service: ResourceService<StorageResourceLocation>,
              key: string) {
    super(service, key);
  }

  create(value: T, params?: CRUDParameters | undefined): Observable<T> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    return this.service.create(location, value);
  }
  update(value: T, params?: CRUDParameters | undefined): Observable<T> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    return this.service.update(location, value);
  }
  patch(patch: Partial<T>, params?: CRUDParameters | undefined): Observable<T> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    return this.service.patch(location, patch);
  }
  delete(params?: CRUDParameters | undefined): Observable<T> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    return this.service.delete(location);
  }

}
