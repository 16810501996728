<ng-container *ngIf="(strategy.items$ | async) as items; else notFoundTemplate">
  <div class="flex flex-col sm:flex-col-reverse">
    <div class="flex flex-col max-h-[65vh] mt-2 overflow-y-auto">
      <ng-container *ngIf="strategy.loading()">
        <div *softRepeat="20" class="w-full m-2 px-2 h-4 skeleton block"></div>
      </ng-container>
      <ng-container *ngIf="items.length > 0; else notFoundTemplate">
        <ng-container *ngFor="let item of items">
          <div class="flex flex-row hover:bg-hover cursor-pointer border-b border-b-solid p-2 items-center justify-center"
               [class.bg-selected]="item | in:selected()"
               (click)="select($any(item))">
            <div class="mr-2">
              <soft-checkbox [checked]="item | in:selected()"
                             (click)="$event.preventDefault();"></soft-checkbox>
            </div>
            <div class="w-full">
              <ng-container *ngComponentOutlet="config.component; inputs: {item: item}"></ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <soft-filter-and-sort class="block mx-2 mt-2 mb-2 sm:mb-0"
                          [filter]="(filter$ | async) ?? null"
                          [filterConfig]="config.filter"
                          [sort]="(sort$ | async) ?? null"
                          [sortConfig]="config.sort"
                          [values]="items"
                          (filterChange)="onFilterChange($event)"
                          (sortChange)="onSortChange($event)">
    </soft-filter-and-sort>
  </div>
</ng-container>
<button class="soft-button accent w-full h-16 mt-2"
        (click)="resolve(selected())">Übernehmen</button>
<ng-template #notFoundTemplate>
  <div *ngIf="!strategy.loading()"
       class="flex flex-col text-light text-lg items-center content-center my-4">
    <span>Es wurden keine Daten gefunden</span>
  </div>
</ng-template>
