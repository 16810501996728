import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Modal, UiCoreComponentsModule} from '@softline/ui-core';

@Component({
  selector: 'soft-create-checkliste-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreComponentsModule],
  templateUrl: './create-checkliste-dialog.component.html',
  styleUrl: './create-checkliste-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateChecklisteDialogComponent implements Modal<{ betreff: string } | null> {

  close!: (result: { betreff: string } | null) => void;

  registerCloseHandler(handler: (result: ({ betreff: string } | null)) => void) {
    this.close = handler;
  }
}
