import {
  createGetter,
  createMutation,
  isDefined,
  mutate,
  select,
  StoreFeature,
} from '@softline/core';
import { DynamicAction } from './data/action';

export interface State {
  actions: DynamicAction[];
}

export const mutations = {
  add: createMutation<State, DynamicAction>('add'),
};

export const actions = {};

export const getters = {
  actions: createGetter<State, DynamicAction[]>('actions'),
  action: createGetter<State, DynamicAction | null, string | undefined>(
    'action'
  ),
};

export const feature: StoreFeature<State> = {
  initialState: {
    actions: [],
  },
  mutations: [
    mutate(mutations.add, ({ state, params }) => ({
      ...state,
      actions: [...state.actions, params],
    })),
  ],
  actions: [],
  getters: [
    select(getters.actions, ({ state }) => state.actions),
    select(getters.action, ({ state, params }) => {
      if (isDefined(params))
        return [...state.actions].reverse().find((o) => o.action === params);
      else if (!isDefined(params) && state.actions.length > 0)
        return state.actions[state.actions.length - 1];
      else return null;
    }),
  ],
};
