import { InjectionToken } from '@angular/core';

export const SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT = 'authenticationContext';

export const SOFTLINE_ROUTE_AUTHENTICATION = '/login';
export const SOFTLINE_CONFIG_AUTHENTICATION_ROUTE = new InjectionToken<string>(
  'AUTHENTICATION_ROUTE'
);
export const SOFTLINE_CONFIG_AUTHENTICATION_FORWARD_ROUTE = new InjectionToken<
  string | undefined
>('DEFAULT_FORWARD_ROUTE');
