import { StoreFeature } from './store';
import {
  CombineFeatureMergeStrategy,
  combineFeatures,
  defaultCombineFeatureMergeStrategy,
} from './combine-features.function';

export interface CustomStore<TState, TMutations, TGetters, TActions> {
  mutations: TMutations;
  getters: TGetters;
  actions: TActions;
  feature: StoreFeature<TState>;
}

export interface CustomStoreAddOn<TState, TMutations, TGetters, TActions> {
  mutations?: TMutations;
  getters?: TGetters;
  actions?: TActions;
  feature: Partial<StoreFeature<TState>>;
}

export class StoreBuilder<TState, TMutations, TGetters, TActions> {
  constructor(
    public value: CustomStore<TState, TMutations, TGetters, TActions>
  ) {}

  add<TState2, TMutations2, TGetters2, TActions2>(
    addon: CustomStoreAddOn<TState2, TMutations2, TGetters2, TActions2>,
    mergeStrategy: CombineFeatureMergeStrategy = defaultCombineFeatureMergeStrategy
  ): StoreBuilder<
    TState & (TState2 extends undefined ? TState : TState2),
    TMutations & TMutations2,
    TGetters & TGetters2,
    TActions & TActions2
  > {
    const mutations = {
      ...this.value.mutations,
      ...(addon.mutations ?? ({} as TMutations2)),
    };
    const actions = {
      ...this.value.actions,
      ...(addon.actions ?? ({} as TActions2)),
    };
    const getters = {
      ...this.value.getters,
      ...(addon.getters ?? ({} as TGetters2)),
    };
    const newFeature: StoreFeature<TState2> = {
      initialState: { ...(addon.feature.initialState ?? ({} as TState2)) },
      mutations: addon.feature.mutations ?? [],
      actions: addon.feature.actions ?? [],
      getters: addon.feature.getters ?? [],
    };
    const combinedFeature = combineFeatures(
      [this.value.feature, newFeature],
      mergeStrategy
    );
    const store = { mutations, actions, getters, feature: combinedFeature };
    return new StoreBuilder(store);
  }
}
