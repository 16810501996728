import { InjectionToken } from '@angular/core';

export const SOFTLINE_CONFIG_JWT_COOKIE_NAME = 'JWT Authentication Token';
export const SOFTLINE_CONFIG_JWT_LOCAL_STORAGE_NAME =
  'JWT Authentication Token';
export const SOFTLINE_CONFIG_JWT_EXPIRATION_TIME = new InjectionToken<number>(
  'JWT_EXPIRATION_TIME'
);
export const SOFTLINE_CONFIG_JWT_REFRESH_TIME = new InjectionToken<number>(
  'JWT_REFRESH_TIME'
);
export const SOFTLINE_CONFIG_JWT = new InjectionToken<string>(
  'SOFTLINE_CONFIG_JWT'
);
export const SOFTLINE_FEATURE_JWT_AUTHENTICATION = 'jwtAuthentication';
