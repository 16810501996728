import { Component, Inject, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from "@softline/dynamic";
import {I18nModule} from '@softline/ui-core';

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
    imports: [CommonModule, I18nModule],
  templateUrl: './artstamm.master-view.html',
  styleUrls: ['./artstamm.master-view.scss'],
})
export class ArtstammMasterView implements FieldOkItemComponent<any>{

  @Input() item: any;

  constructor() {
  }
}
