import { computed, inject, Injectable } from '@angular/core';
import { BelegartStore } from '@softapps/wws/gesamtabfrage';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithBelegart = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class BelegartMixin extends Base {

    route = inject(ActivatedRoute)
    belegartStore = inject(BelegartStore);

    belegartName = toSignal(this.route.paramMap.pipe(
        map((o) => o.get('type') ?? '')
      ), {initialValue: ''}
    );

    belegarten = this.belegartStore.collection.all;
    belegart = computed(() => this.belegartStore.collection.dict()[this.belegartName()]);


    constructor(...args: any[]) {
      super(...args);
    }
  }
  return BelegartMixin;
}
