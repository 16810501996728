<div class="c row center">
  <h4 class="text-lg font-bold">
    {{ '#PASSWORD.COMPONENTS.RESET_FORM.TITLE' | translate }}
  </h4>
</div>
<div class="soft-title light mt-4">
  {{ '#PASSWORD.COMPONENTS.RESET_FORM.STEP2' | translate }}
</div>
<hr class="soft-separator" />
<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
  <div class="c column mt-4">
    <label for="password-reset-form-username" class="soft-label top required">
      {{ '#PASSWORD.COMPONENTS.RESET_FORM.USERNAME' | translate }}
    </label>
    <soft-input
      formControlName="username"
      [readonly]="isUsernameReadOnly"
      id="password-reset-form-username"
      autocomplete="username"
    ></soft-input>
    <soft-validation-container
      *ngIf="form.controls['username'].touched"
      [validation]="form | validationMessage: 'username'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>
  <div class="c column mt-4">
    <label for="password-reset-form-token" class="soft-label top required">
      {{ '#PASSWORD.COMPONENTS.RESET_FORM.TOKEN' | translate }}
    </label>
    <soft-input
      formControlName="token"
      [readonly]="isTokenReadOnly"
      id="password-reset-form-token"
      autocomplete="one-time-code"
    ></soft-input>
    <soft-validation-container
      *ngIf="form.controls['token'].touched"
      [validation]="form | validationMessage: 'token'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>

  <div class="c column mt-4 required">
    <label for="password-reset-form-password" class="soft-label top required">
      {{ '#PASSWORD.COMPONENTS.RESET_FORM.PASSWORD' | translate }}
    </label>
    <soft-password-input
      formControlName="password"
      id="password-reset-form-password"
      autocomplete="new-password"
    ></soft-password-input>
    <soft-validation-container
      [validation]="form | validationMessage: 'password'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>

  <div class="c column mt-4 required">
    <label
      for="password-reset-form-password-repeat"
      class="soft-label top required"
    >
      {{ '#PASSWORD.COMPONENTS.RESET_FORM.PASSWORD_REPEAT' | translate }}
    </label>
    <soft-password-input
      formControlName="passwordRepeat"
      id="password-reset-form-password-repeat"
      autocomplete="new-password"
    ></soft-password-input>
    <soft-validation-container
      *ngIf="form.controls['passwordRepeat'].touched"
      [validation]="form | validationMessage: 'passwordRepeat'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>

  <div class="c center mt-2">
    <button
      type="submit"
      class="soft-button primary"
      [disabled]="!(form | isValid)"
    >
      {{ '#PASSWORD.COMPONENTS.RESET_FORM.SUBMIT' | translate }}
    </button>
  </div>
</form>
