import { NavigationExtras } from "@angular/router";

export interface Application {
  name: string;
  description?: string;
  icon?: string;
  id?: number;
  image?: string;
  route: string;
  routeParams?: NavigationExtras;
  permission?: string;
}
