import { Component, computed, forwardRef, input, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeFieldInput } from '../date-range-field-input';
import { I18nModule } from '../../../../i18n/i18n.module';
import { L10nModule } from '../../../../l10n/l10n.module';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRangeToInputComponent } from '../date-range-to-input/date-range-to-input.component';

@Component({
  selector: 'soft-date-range-from-input',
  standalone: true,
  imports: [CommonModule, I18nModule, L10nModule,],
  templateUrl: './date-range-from-input.component.html',
  styleUrl: './date-range-from-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeFromInputComponent),
      multi: true,
    },
  ],
})
export class DateRangeFromInputComponent extends DateRangeFieldInput {
    override valueInput: Signal<string | null> = input<string | null>(null);
    override readonly: Signal<boolean> = input<boolean>(false);
    override disabledInput: Signal<boolean> = input<boolean>(false, {alias: 'disabled'});
    override placeholder: Signal<string> = input<string>('#UI_CORE.COMPONENTS.DATE_INPUT.PLACEHOLDER');
    override changeTrigger: Signal<'blur' | 'input'> = input<'blur' | 'input'>('blur');
    protected override field: 'from' | 'to' = 'from'
    override format: Signal<string> = input<string>('DATE.DEFAULT');

    toControl = input<DateRangeToInputComponent | null>(null);
    override otherControl = computed(() => this.toControl());
}
