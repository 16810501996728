import * as EntityStore from './entity.store';
import { createAction, createGetter, on, select } from '../../../factories';
import { Entity } from '../types/entity';
import { StoreFeature } from '../../../store';
import { SOFTLINE_STORE_ENTITY_SERVICE } from './entity.shared';
import { Dictionary } from '../../../../types/dictionary';
import {
  ActionState,
  handleObservableActionState,
} from '../../remote/action.store';
import * as ActionStore from '../../remote/action.store';
import * as SubscriptionStore from '../../remote/subscription.store';
import { handleSubscriptionState } from '../../remote/subscription.store';
import { SOFTLINE_SERVICE_UUID } from '../../../../core.shared';
import { lastValueFrom } from 'rxjs';

export interface LoadActionParameters {
  id: string | number;
  pathParams?: Dictionary<unknown>;
  queryParams?: Dictionary<unknown>;
  token?: string;
}

export interface State<T extends Entity = Entity>
  extends EntityStore.State<T>,
    SubscriptionStore.State,
    ActionStore.State {}

export class Store<T extends Entity = Entity> {
  mutations = {
    ...this.entityStore.mutations,
    action: ActionStore.mutations,
    subscription: SubscriptionStore.mutations,
  };

  actions = {
    load: createAction<State<T>, LoadActionParameters, T>('load'),
    ...SubscriptionStore.actions,
  };

  getters = {
    ...this.entityStore.getters,
    action: ActionStore.getters,
    subscription: SubscriptionStore.getters,
    loading: createGetter<State<T>, boolean>('loading'),
    loaded: createGetter<State<T>, boolean>('loaded'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.entityStore.feature.initialState,
      ...ActionStore.feature.initialState,
      ...SubscriptionStore.feature.initialState,
    },
    mutations: [
      ...this.entityStore.feature.mutations,
      ...ActionStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
    ],
    getters: [
      ...this.entityStore.feature.getters,
      ...ActionStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      select(this.getters.loading, ({ get, featureName, params }) => {
        const actions = [this.actions.load.name];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.loaded, ({ get, featureName, params }) => {
        const actions = [this.actions.load.name];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
    actions: [
      ...ActionStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      on(
        this.actions.load,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const subscription$ = handleSubscriptionState(
            service.get(params.id, params.pathParams, params.queryParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.load.name,
              token
            )
          );
          commit(featureName, this.mutations.set, result as T);
          return result;
        }
      ),
    ],
  };

  constructor(private entityStore: EntityStore.Store<T>) {}
}

export function create<T extends Entity>(): Store<T> {
  const entityStore = EntityStore.create<T>();
  return new Store<T>(entityStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
