import { ComponentRef, Injectable } from "@angular/core";
import {
  LabelType,
  Scan,
  ScannerService,
  ScannerStore,
  SettingsStore,
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_SETTINGS
} from "@softline/application";
import { Html5Qrcode } from "html5-qrcode";
import { DateService, Store } from "@softline/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Html5ScannerComponent } from "../components/html5-scanner.component";
import { Html5ScannerSettings, Html5ScannerSource } from "../types/html5-scanner.settings";
import { SOFTLINE_SETTINGS_HTML5_SCANNER } from "../html5-scanner.shared";
import { ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";

@Injectable()
export class Html5ScannerService extends ScannerService {

  private cameraId: string = '';
  private overlayRef: OverlayRef;
  private portal: ComponentPortal<Html5ScannerComponent>;
  private scannerRef!: ComponentRef<Html5ScannerComponent>

  scanner!: Html5Qrcode;

  constructor(private store: Store, private dateService: DateService, private overlay: Overlay) {
    super();
    this.overlayRef = this.overlay.create();
    this.portal = new ComponentPortal(Html5ScannerComponent);
  }

  override async init(): Promise<void> {
    // This method will trigger user permissions
    this.isAvailable = true;
    this.scannerRef = this.overlayRef.attach(this.portal);
    this.overlayRef.addPanelClass(['m-auto', 'hidden']);
    this.overlayRef.backdropClick().subscribe(async () => {
      await this.cancel();
      }
    )
  }

  async scan(labelType?: LabelType | LabelType[]): Promise<Scan> {
    const settings = this.store.get(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.getters.values<Html5ScannerSettings>(),
      SOFTLINE_SETTINGS_HTML5_SCANNER
    )
    const source = await this.getSource(settings?.source ?? null);
    try {
      let result: Scan;
      if(source === "camera") {
        this.overlayRef.removePanelClass('hidden');
        result = await this.scannerRef.instance.startCameraScan(settings);
        this.overlayRef.addPanelClass('hidden');
      }
      else{
        const files = await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.file, {
          title: '#HTML5_SCANNER.SCAN_FILE',
          sources: ['cameraRoll', 'file'],
          accept: 'image/*',
          selectionMode: 'single',
          dismiss: true,
          autoSubmit: true,
        });
        if(files === 'DISMISSED' || files === null || files.length === 0) {
          await this.store.dispatch(SOFTLINE_FEATURE_SCANNER, ScannerStore.actions.cancel);
          throw new Error('Scan cancelled');
        }

        result = await this.scannerRef.instance.startFileScan(files[0])
      }
      return result
    }
    catch (e) {
      this.overlayRef.addPanelClass('hidden');
      throw e;
    }
  }

  async cancel(): Promise<void> {
    this.overlayRef.addPanelClass('hidden');
    await this.scannerRef.instance.stopScan();
  }

  private async getSource(sourceSettings: Html5ScannerSource): Promise<'camera' | 'file'> {
    let source: 'camera' | 'file' = 'camera';
    switch (sourceSettings) {
      case 'file':
        source = 'file';
        break
      case 'camera':
        source = 'camera';
        break
      case 'prompt':
        const promptResult = await this.store.dispatch(
          SOFTLINE_FEATURE_MODAL,
          ModalStore.actions.choose<'file' | 'camera'>(),
          {
            title: '#HTML5_SCANNER.DIALOGS.SOURCE.TITLE',
            options: [
              { value: 'camera', label: '#HTML5_SCANNER.SETTINGS.SOURCE.CAMERA', icon: 'fa-regular fa-camera'},
              { value: 'file', label: '#HTML5_SCANNER.SETTINGS.SOURCE.FILE', icon: 'fa-regular fa-file'}
            ],
            dismiss: true
          })
        if(promptResult === 'DISMISSED')
          throw new Error('Scan cancelled');
        source = promptResult;
        break;
      default:
        const devices = await navigator.mediaDevices?.enumerateDevices() ?? [];
        const cameras = devices.filter(o => o.kind === 'videoinput');
        source = cameras.length > 0 ? 'camera' : 'file';
    }
    return source;
  }

}
