import { NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';

export interface Command {
  name?: string;
  description?: string;
  icon?: string;
  src?: string;
  routerLink?: any[] | string;
  routerLinkParams?: NavigationExtras;
  canExecute?: Observable<boolean>;
  badge?: Observable<string | undefined>;
  class?: string;
  isVisible?: Observable<boolean>;
  commands?: Command[];
  execute?(): void;
}
