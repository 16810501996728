import { Component, Inject, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from "@softline/dynamic";
import { UiCoreModule } from "@softline/ui-core";

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './fibper.list-view.html',
  styleUrls: ['./fibper.list-view.scss'],
})
export class FibperListView implements FieldOkItemComponent<any>{

  @Input() item: any;

  constructor() {
  }
}
