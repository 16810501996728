import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@softline/core';
import { Subscription, combineLatest, map, switchMap } from 'rxjs';
import { SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL } from './../../statusabfrage.shared';
import { PmsPersonalDetailStore } from './../../store/personal-detail.store';
import { DateNavigatorService } from './../../services/date-navigator.service';
import { BackNavigable, BackNavigationService, RefreshService, Refreshable, SOFTLINE_FEATURE_TITLE, TitleStore } from '@softline/application';
import { UiCoreModule } from '@softline/ui-core';
import { SecondsToHoursPipe } from './../../pipes/seconds-to-hours.pipe';

@Component({
  selector: 'soft-mitarbeiter-detail',
  standalone: true,
  imports: [CommonModule, UiCoreModule, SecondsToHoursPipe],
  templateUrl: './mitarbeiter-detail.component.html',
  styleUrls: ['./mitarbeiter-detail.component.scss'],
  providers: [DatePipe]
})
export class MitarbeiterDetailComponent implements OnInit, OnDestroy, BackNavigable, Refreshable {

  readonly expanded = true;
  private mitarbeiterSubscription?: Subscription;
  private subscription?: Subscription;

  private readonly id$ = this.activatedRoute.paramMap.pipe(
    map(o => o.get('idlstfix'))
  );

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL,
    PmsPersonalDetailStore.getters.loading
  );

  readonly mitarbeiter$ = this.store.observe(
    SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL,
    PmsPersonalDetailStore.getters.data
  );

  readonly mitarbeiterDetail$ = this.id$.pipe(
    switchMap(id => this.store.observe(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL,
      PmsPersonalDetailStore.getters.data,
    ))
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly store: Store,
    private datePipe: DatePipe,
    private readonly dateNavigatorService: DateNavigatorService,
    private readonly backNavigationService: BackNavigationService,
    private readonly refreshService: RefreshService) {}

  async navigateBack(): Promise<void> {
    await this.router.navigate(['./../../'], { relativeTo: this.activatedRoute });
  }

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.refreshService.add(this);

    this.subscription = combineLatest([
      this.id$,
      this.dateNavigatorService.date$
    ]).subscribe(async ([id, date]) => {
      await this.store.dispatch(
        SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL,
        PmsPersonalDetailStore.actions.load,
        {
          pathParams: {
            stichdatum: this.datePipe.transform(date, 'yyyy-MM-dd'),
            idlstfix: id
          },
        }
      )
    });


    this.mitarbeiterSubscription = this.mitarbeiter$.subscribe(mitarbeiter => {
      if (!mitarbeiter) {
        this.store.commit(
          SOFTLINE_FEATURE_TITLE,
          TitleStore.mutations.setSubTitle,
          `Lade...`
        );
        return;
      }

      const { persnr, vorname, zuname } = mitarbeiter.lstfix;

      this.store.commit(
        SOFTLINE_FEATURE_TITLE,
        TitleStore.mutations.setSubTitle,
        `${persnr} - ${vorname} ${zuname}`
      );
    });
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.refreshService.remove(this);
    this.mitarbeiterSubscription?.unsubscribe();
    this.subscription?.unsubscribe();

    this.store.commit(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL,
      PmsPersonalDetailStore.mutations.unset
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
  }

  async refresh(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER_DETAIL,
      PmsPersonalDetailStore.actions.load,
      {
        pathParams: {
          stichdatum: this.datePipe.transform(this.dateNavigatorService.date, 'yyyy-MM-dd'),
          idlstfix: this.activatedRoute.snapshot.paramMap.get('idlstfix')
        },
      }
    )
  }
}
