import {Inject, Injectable} from "@angular/core";
import {ConnectionHttpService, ConnectionResourceLocation, SOFTLINE_SERVICE_HTTP} from "@softline/core";
import {
  SOFTLINE_API_WWS_WARENUEBERNAHME_ABSCHLIESSEN,
  SOFTLINE_API_WWS_WARENUEBERNAHME_OEFFNEN,
  SOFTLINE_API_WWS_WARENUEBERNAHME_SPERREN
} from "../warenuebernahme.api";
import {Warenuebernahme} from "../types/warenuebernahme";
import {Observable} from "rxjs";

@Injectable()
export class WarenuebernahmeService {
  private abschliessenlocation(idwu: string): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_ABSCHLIESSEN,
      pathParams: {idwu}
    }
  }

  private sperrenLocation(idwu: string): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_SPERREN,
      pathParams: {idwu}
    }
  }

  private oeffnenLocation(idwu: string): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_OEFFNEN,
      pathParams: {idwu}
    }
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService
  ) {}

  abschliessen(idwu: string): Observable<Warenuebernahme> {
    const location = this.abschliessenlocation(idwu);

    return this.httpService.create<string, Warenuebernahme>(location, '');
  }

  sperren(idwu: string): Observable<Warenuebernahme> {
    const location = this.sperrenLocation(idwu);

    return this.httpService.create<string, Warenuebernahme>(location, '');
  }

  oeffnen(idwu: string): Observable<Warenuebernahme> {
    const location = this.oeffnenLocation(idwu);

    return this.httpService.create<string, Warenuebernahme>(location, '');
  }
}
