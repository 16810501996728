import { Component, Inject, OnInit } from '@angular/core';
import { SOFTLINE_DATA_CONSOLE } from '../console.shared';
import { Observable } from 'rxjs';
import { ConsoleEntry } from '../console.entry';
import { map } from 'rxjs/operators';

@Component({
  selector: 'soft-log-settings',
  templateUrl: './console-settings.component.html',
  styleUrls: ['./console-settings.component.scss'],
})
export class ConsoleSettingsComponent implements OnInit {
  logs$ = this.console$.pipe(map((o) => o.slice(-50)));

  constructor(
    @Inject(SOFTLINE_DATA_CONSOLE) public console$: Observable<ConsoleEntry[]>
  ) {}

  ngOnInit(): void {}

  isError(value: unknown): value is Error {
    return value instanceof Error;
  }
}
