<ng-container *ngIf="filteredMitarbeiter$ | async as filteredMitarbeiter">
  <div class="flex flex-col mt-5 pb-5 gap-2 select-none"
       *ngIf="(loaded$ | async) === true || (filteredMitarbeiter?.length || 0) > 0; else skeleton">
    <ng-container *ngIf="filteredMitarbeiter.length < 1; else data">
      <div class="flex items-center justify-center px-4 py-4 text-center mt-3">
        Die Suche hat keine Treffer ergeben.
      </div>
    </ng-container>

    <ng-template #data>
      <soft-mitarbeiter-card
        mode="selection"
        [ngClass]="mitarbeiter.selected ? 'opacity-100' : 'opacity-75'"
        *ngFor="let mitarbeiter of filteredMitarbeiter; trackBy: trackByMitarbeiterId"
        (tapped)="mitarbeiterTapped(mitarbeiter.mitarbeiter)"
        [mitarbeiter]="mitarbeiter.mitarbeiter"
        [selected]="mitarbeiter.selected">
      </soft-mitarbeiter-card>
    </ng-template>
  </div>
</ng-container>

<ng-template #skeleton>
  <div class="flex flex-col mt-5 pb-5 gap-2">
    <div class="flex soft-card rounded w-full h-20" *softRepeat="5">
      <div class="flex flex-col gap-2.5 items-start pb-1.5 pt-2">
        <div class="h-4 w-20 skeleton rounded"></div>
        <div class="h-4 w-36 skeleton rounded"></div>
      </div>
    </div>
  </div>
</ng-template>
