import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { Store, StoreModule } from '@softline/core';
import {
  SOFTLINE_CONFIG_DEFAULT_LOCALE,
  SOFTLINE_CONFIG_LOCALIZATION_BASE_PATH,
  SOFTLINE_FEATURE_LOCALIZATION,
} from './l10n.shared';
import { FormatPipe } from './pipes/format.pipe';
import { HttpLocalizationLoader } from './services/http-localization-loader';
import { LocalizationLoader } from './services/localization-loader';
import * as LocalizationStore from './localization.store';
import { MomentCalendarService } from '../services/moment-calendar.service';

@NgModule({
  declarations: [FormatPipe],
  providers: [FormatPipe],
  exports: [FormatPipe],
})
export class L10nModule {
  static forRoot(): ModuleWithProviders<L10nRootModule> {
    return {
      ngModule: L10nRootModule,
    };
  }
}

@NgModule({
  declarations: [],
  imports: [
    L10nModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_LOCALIZATION,
      feature: LocalizationStore.feature,
    }),
  ],
  exports: [L10nModule],
  providers: [
    { provide: SOFTLINE_CONFIG_DEFAULT_LOCALE, useValue: 'de' },
    { provide: SOFTLINE_CONFIG_LOCALIZATION_BASE_PATH, useValue: './assets' },
    { provide: LocalizationLoader, useClass: HttpLocalizationLoader },
  ],
})
export class L10nRootModule {
  constructor(
    store: Store,
    calendarService: MomentCalendarService,
    @Inject(SOFTLINE_CONFIG_DEFAULT_LOCALE) defaultLocale: string
  ) {
    calendarService.setLocale(defaultLocale);
    store.dispatch(
      SOFTLINE_FEATURE_LOCALIZATION,
      LocalizationStore.actions.changeLocale,
      { locale: defaultLocale }
    );
  }
}
