import { Injector, Pipe, PipeTransform } from "@angular/core";
import { ClassRule, Rule } from '../data/rules';
import { RuleHelper } from '../utilities/rule.helper';
import { isDefined } from "@softline/core";

@Pipe({
  name: 'classRule',
  standalone: true,
})
export class ClassRulePipe implements PipeTransform {
  constructor(private injector: Injector) {
  }

  transform(
    rule: undefined | string | (string | ClassRule)[],
    value: any
  ): string[] {
    if (!isDefined(rule))
      return [];
    return RuleHelper.getClassList(rule, value, this.injector);
  }
}
