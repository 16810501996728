import { Component } from "@angular/core";
import { Modal } from "../../../../modal/data/modal";
import { I18nModule } from "../../../../i18n/i18n.module";
import { UiCorePipesModule } from "../../../../pipes/ui-core-pipes.module";
import { CommonModule } from "@angular/common";
import { EntityInputStrategy } from "../strategies/entity-input.strategy";
import { TabGroupComponent } from "../../../tab-group/tab-group.component";
import { TabComponent } from "../../../tab-group/tab/tab.component";


@Component({
  selector: 'soft-entity-input-dialog',
  standalone: true,
  imports: [CommonModule, I18nModule, UiCorePipesModule, TabGroupComponent, TabComponent],
  templateUrl: './entity-input.dialog.html',
  styleUrls: ['./entity-input.dialog.scss'],
})
export class EntityInputDialog implements Modal<unknown> {

  private close!: (result: unknown) => void;

  title: string | undefined;
  subtitle?: string;
  params?: object;

  strategy!: EntityInputStrategy<any>

  constructor() {
  }

  registerCloseHandler(handler: (result: unknown) => void): void {
    this.close = handler;
  }
}
