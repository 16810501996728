<soft-input
  placeholder="{{
        '#APPLICATION.FILTER_AND_SORT.PLACEHOLDER' | translate
      }}"
  [value]="input()"
  (valueChange)="input.set($event ?? '')">
  <div class="cursor-pointer select-none"
       [class.text-lighter]="!params().caseSensitive"
       (click)="onParamsClick('caseSensitive')">Cc</div>
  <!--<div class="cursor-pointer select-none ml-2"
       [class.text-lighter]="!params().wholeWord"
       (click)="onParamsClick('wholeWord')">W</div>-->
</soft-input>
