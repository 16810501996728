import { inject, Injectable } from "@angular/core";
import { Store } from "@softline/core";
import { Router } from "@angular/router";

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithRouter = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class RouterMixin extends Base {

    router = inject(Router);

    constructor(...args: any[]) {
      super(...args);
    }
  }
  return RouterMixin as unknown as T & {router: Router};
}
