<div class="soft-card h-full flex flex-col gap-2 min-h-[40vh] max-h-[50vh] overflow-y-hidden rounded">
  <div class="flex flex-col">
    <h3 class="text-xl font-semibold">{{ '#UMSATZSTATISTIK.TITLE' | translate }}</h3>
    <p class="flex font-normal text-sm text-light">Zeitraum: {{ vondatum() | format:'DATE.DEFAULT' }} bis {{ bisdatum() | format:'DATE.DEFAULT' }}</p>
  </div>

  <soft-main-chart
    [chartClass]="'min-h-[28vh] max-h-[28vh]'"
    [data]="chartData()"
    [loading]="loading()">
  </soft-main-chart>
</div>
