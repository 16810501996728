import {UpdateQueryStrategyFactory} from './query-update.strategy';
import {SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_VKFORG} from '../umsatzstatistik.shared';
import {WwsUmsatzstatistikVkforgStore} from '../store/vkforg.store';
import {inject} from '@angular/core';
import {Store} from '@softline/core';
import {FrontendUmsatzstatistikQuery} from '../types/frontend-umsatzstatistik.query';

export const vkforgQueryUpdateStrategy: UpdateQueryStrategyFactory<string> = () => {
  const store = inject(Store);

  return {
    umsatzebene: 'Vkforg',
    updateFn: async (vkforgName, query) => {
      const filialen = await store.dispatch(
        SOFTLINE_FEATURE_WWS_UMSATZSTATISTIK_VKFORG,
        WwsUmsatzstatistikVkforgStore.actions.loadItemsOnce
      );

      const vkforg = filialen.find(o => o.vkforg === vkforgName);
      query['filiale'] = vkforg ?? null;

      return query;
    },
    removeFn: async (query: FrontendUmsatzstatistikQuery)  => {
      // Wenn filiale === null, dann setzt store beim Laden automatisch alle vkforgs
      query.filiale = null
      return query;
    }
  }
}
