import { Store } from '@softline/core';
import { of } from 'rxjs';
import { Command } from '../../data/command';
import { UploadCommandOptions } from '../data/upload-command-options';
import {
  ModalStore,
  SOFTLINE_FEATURE_MODAL,
} from '@softline/ui-core';
import { UploadFileDialogComponent } from '../components/dialogs/upload-file-dialog/upload-file-dialog.component';

export class UploadFileCommand implements Command {
  icon = 'fa-regular fa-upload';
  name =
    this.options.selectionMode !== 'multi'
      ? '#APPLICATION.UPLOAD.FILE.TITLE'
      : '#APPLICATION.UPLOAD.FILE.TITLE_PL';
  class = this.options.class ?? 'menu action-menu action-menu-top';
  canExecute = this.options.canExecute ?? of(true);
  execute = () => this.onExecute();

  constructor(private store: Store, private options: UploadCommandOptions) {}

  onExecute(): void {
    this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
      id: 'UPLOAD_FILE',
      component: UploadFileDialogComponent,
      data: {
        options: this.options,
      },
      dismiss: true,
    });
  }
}
