import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[softAccordionItemContent]'
})
export class AccordionItemContentDirective {

  @Input('softAccordionItemContent') expanded = false

  constructor(public templateRef: TemplateRef<unknown>) { }
}
