import { Pipe, PipeTransform } from '@angular/core';
import { equals } from '@softline/core';

@Pipe({
  name: 'transform',
  pure: true,
  standalone: true
})
export class TransformPipe<TValue, TReturn, TParams = undefined> implements PipeTransform {
  transform(value: TValue, mapFn: (o: TValue, p?: TParams) => TReturn, params?: TParams): TReturn {
    return mapFn(value, params);
  }
}
