import { Pipe, PipeTransform } from '@angular/core';
import { isImage } from '../functions/is-image.function';

@Pipe({
  name: 'log',
  standalone: true,
  pure: true
})
export class LogPipe<T> implements PipeTransform {
  transform(value: T): T {
    console.log('[LogPipe] value:', value);
    return value;
  }
}
