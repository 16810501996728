import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

const MAX_DRAG_DISTANCE = 120;
const LOADING_DRAG_POSITION = 60;

@Component({
  selector: 'soft-pull-to-refresh-container',
  templateUrl: './pull-to-refresh-container.component.html',
  styleUrls: ['./pull-to-refresh-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PullToRefreshContainerComponent {
  @Input() canRefresh = false;
  @Output() refresh = new EventEmitter<() => void>();

  dragDistance = 0;
  isRefreshing = false;

  private isOnTop = true;
  private dragStart?: number = undefined;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @HostListener('touchstart', ['$event'])
  startDragging($event: TouchEvent): void {
    if (!this.isOnTop || !this.canRefresh || this.isRefreshing) return;

    this.dragStart = $event.touches[0].pageY;
  }

  @HostListener('touchmove', ['$event'])
  onDragging($event: TouchEvent): void {
    if (!this.dragStart || !this.canRefresh || this.isRefreshing) return;

    const distance = $event.touches[0].pageY - (this.dragStart ?? 0);
    this.dragDistance = Math.min(distance, MAX_DRAG_DISTANCE);
  }

  @HostListener('touchend')
  endDragging(): void {
    if (!this.dragStart || !this.canRefresh || this.isRefreshing) return;

    if (this.dragDistance >= LOADING_DRAG_POSITION) this.execute();
    else this.reset();
  }

  @HostListener('scroll', ['$event'])
  onScroll($event: any): void {
    this.isOnTop = $event.target.scrollTop === 0;
  }

  get transformWhileDragging(): object {
    return this.dragDistance > 0
      ? { transform: `translateY(${this.dragDistance}px)` }
      : {};
  }

  private execute(): void {
    if (!this.canRefresh || this.refresh.observers.length === 0) return;

    this.dragDistance = LOADING_DRAG_POSITION;
    this.isRefreshing = true;

    this.refresh.emit();
    this.reset();
  }

  private reset(): void {
    this.dragStart = undefined;
    this.isRefreshing = false;
    this.dragDistance = 0;
    this.changeDetectorRef.markForCheck();
  }
}
