import {Component, inject, Inject, OnDestroy, OnInit, Optional} from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  BackNavigable, BackNavigationService, Command, CommandStore,
  handleRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from "@softline/application";
import { groupBy, Store } from "@softline/core";
import {
  OffenePostenConfig,
  SOFTLINE_CONFIG_OFFENE_POSTEN,
  SOFTLINE_FEATURE_OFFENE_POSTEN
} from "../../offene-posten.shared";
import { OffenePostenStore } from "../../store";
import { BehaviorSubject, map } from "rxjs";
import { Router } from "@angular/router";
import { UiCoreModule } from "@softline/ui-core";
import { OffenerPosten } from "../../types/offener-posten";

@Component({
  selector: 'soft-offene-posten',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './offene-posten.component.html',
  styleUrls: ['./offene-posten.component.scss']
})
export class OffenePostenComponent
  implements OnInit, OnDestroy, BackNavigable {

  readonly nichtFakturierteAnzeigen$ = new BehaviorSubject<boolean>(false);

  loading$ = new BehaviorSubject(false);
  expanded$ = new BehaviorSubject<string[]>([]);

  offenePosten$ = this.store.observe(
    SOFTLINE_FEATURE_OFFENE_POSTEN,
    OffenePostenStore.getters.all
  )
  groups$ = this.offenePosten$.pipe(
    map(o => groupBy(o, p => p.faelldatum)),
    map(o => {
      const returnValue: {key: string, values: OffenerPosten[]}[] = [];
      for (const group of o)
        returnValue.push({key: group.key, values: [...group]})
      return returnValue;
    })
  );

  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService,
              @Optional() @Inject(SOFTLINE_CONFIG_OFFENE_POSTEN) readonly offenePostenConfig?: OffenePostenConfig) { }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#OFFENE_POSTEN.TITLE'
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: OffenePostenComponent,
        commands: this.createCommands(),
      }
    );
    try {
      this.loading$.next(true);
      await this.store.dispatch(
        SOFTLINE_FEATURE_OFFENE_POSTEN,
        OffenePostenStore.actions.loadMany,
        { clear: true, queryParams: {nichtFakturierteLesen: false} }
      )
      this.loading$.next(false);
    }
    catch (e) {
      this.loading$.next(false);
      handleRequestErrors(this.store, e);
    }
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_OFFENE_POSTEN,
      OffenePostenStore.mutations.clear,
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      OffenePostenComponent
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  createCommands(): Command[] {
    return [
      {
        name: '#OFFENE_POSTEN.TITLE',
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fa-regular fa-list',
        name: '#OFFENE_POSTEN.LIST',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/offene-posten'],
      },
    ];
  }

  async onToggleChange(checked: boolean): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_OFFENE_POSTEN,
      OffenePostenStore.actions.loadMany,
      {queryParams: {nichtFakturierteLesen: checked}, clear: true}
    );
    this.nichtFakturierteAnzeigen$.next(checked);
  }

  toggleExpand(value: string): void {
    const expanded = [...this.expanded$.value];
    const index = expanded.indexOf(value)
    if(index > -1)
      expanded.splice(index, 1);
    else
      expanded.push(value);
    this.expanded$.next(expanded);
  }
}
