import { Component, Inject, Optional } from '@angular/core';
import { SOFTLINE_CONFIG_FORGOT_PASSWORD_LINKS } from '../../password.shared';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiCoreModule } from '@softline/ui-core';

@Component({
  selector: 'soft-forgot-password',
  standalone: true,
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  imports: [CommonModule, RouterModule, UiCoreModule],
})
export class ForgotComponent {
  constructor(
    @Optional()
    @Inject(SOFTLINE_CONFIG_FORGOT_PASSWORD_LINKS)
    public links?: { name: string; route: string | string[] }[]
  ) {}
}
