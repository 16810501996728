import {
  createAction,
  createGetter,
  createMutation,
  CustomStore,
  mutate,
  on,
  select,
  StoreBuilder
} from "@softline/core";
import {EtikettendruckService} from '../services/etikettendruck.service';
import {lastValueFrom} from 'rxjs';
import {ArtikelDetailStore} from '@softapps/wws/core';
import {
  SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_BEWE
} from "../etikettendruck.shared";
import {EtikettendruckBeweStore} from './etikettendruck-bewe.store';
import {ItemScan} from '@softline/application';
import {EtikettenArt} from '../data/etiketten-art';
import {EtikettendruckArtikelDetailStore} from './etikettendruck-artikel-detail.store';

export interface EtikettendruckState {
  art?: EtikettenArt
  arten: EtikettenArt[]
  itemScanResult?: ItemScan
}

const mutations = {
  setEtikettenart: createMutation<EtikettendruckState, EtikettenArt | undefined>('mdeetikettendruck/setEtikettenart'),
  setEtikettenarten: createMutation<EtikettendruckState, EtikettenArt[]>('mdeetikettendruck/setEtikettenarten'),
  setItemScanResult: createMutation<EtikettendruckState, ItemScan | undefined>('mdeetikettendruck/setItemScanResult')
}

const getters = {
  art: createGetter<EtikettendruckState, EtikettenArt | null | undefined>('mdeetikettendruck/art'),
  arten: createGetter<EtikettendruckState, EtikettenArt[]>('mdeetikettendruck/arten'),
  itemScanResult: createGetter<EtikettendruckState, ItemScan | undefined>('mdeetikettendruck/getItemScanResult'),
}

const actions = {
  abschluss: createAction<EtikettendruckState, void, void>('mdeetikettendruck/abschluss'),
  loadArtenOnce: createAction<EtikettendruckState, void, EtikettenArt[]>('mdeetikettendruck/loadArten'),
}

const etikettendruckStore: CustomStore<
  EtikettendruckState,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  mutations,
  getters,
  actions,
  feature: {
    initialState: {
      art: undefined,
      arten: [],
    },
    mutations: [
      mutate(mutations.setEtikettenart, ({ params: art, state }) => ({
        ...state,
        art
      })),
      mutate(mutations.setItemScanResult, ({ params, state }) => ({
        ...state,
        itemScanResult: params
      })),
      mutate(mutations.setEtikettenarten, ({ params: arten, state }) => ({
        ...state,
        arten
      })),
    ],
    getters: [
      select(getters.art, ({ state }) => state.art),
      select(getters.arten, ({ state }) => state?.arten ?? []),
      select(getters.itemScanResult, ({ state }) => state?.itemScanResult),
    ],
    actions: [
      on(actions.loadArtenOnce, async ({ injector, featureName, commit, get }) => {
        const service = injector.get(EtikettendruckService);
        const storeArten = get(featureName, getters.arten);

        if (storeArten && storeArten?.length > 0)
          return storeArten;

        const arten = await lastValueFrom(service.loadArten());
        commit(featureName, mutations.setEtikettenarten, arten);
        return arten
      }),
      on(actions.abschluss, async ({ injector, featureName, commit, get }) => {
        const service = injector.get(EtikettendruckService);
        const bewes = get(SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK_BEWE, EtikettendruckBeweStore.getters.all)
        const etikettenArt = get(featureName, getters.art);

        if (!bewes || bewes?.length < 1 || !etikettenArt)
          return;

        const artikel = bewes.map(bewe => ({
          menge: bewe.menge,
          artikel: {
            id: bewe.artikel.id,
            artikelnummer: bewe.artikel.artikelnummer,
            idarteh: bewe.einheit.id
          },
        }));

        await lastValueFrom(service.abschluss({ etikettenArt, artikel }));
      })
    ],
  }
}

export const EtikettendruckStore = new StoreBuilder(etikettendruckStore)
  .value;
