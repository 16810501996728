import { inject, Injectable, InjectionToken, Injector, Provider, StaticProvider, Type } from '@angular/core';
import { NestedStore2Feature } from './nested-store2-feature';
import { Dictionary } from '../types/dictionary';
import { Store2Feature } from './store2-feature';
import { SOFTLINE_FEATURE_NAME } from './store2.shared';

@Injectable()
class CustomStore<T extends object> extends NestedStore2Feature<T> {

  private subFeatures: Store2Feature<any>[] = [];

  constructor() {
    super();
  }

  addSubFeature(feature: Store2Feature<any>): void {
    this[feature.name] = feature;
    this.subFeatures.push(feature);
  }

  override onRegister(): void {
    super.onRegister();
    for (const feature of this.subFeatures) {
      this.registerSubFeature(feature);
    }
  }

  override getDefaultState(): T {
    return { } as T;
  }
}

export class Store2Builder<T extends Store2Feature<any> = CustomStore<object>> {

  private feature: Type<any> = CustomStore;
  private subFeatures: {name: string, feature: Type<any>, providers: Provider[]}[] = [];
  private providers: Provider[] = [];
  private _token = new InjectionToken('SOFTLINE_FEATURE_' + this.name);

  constructor(private name: string) { }

  withFeature<TFeature extends Store2Feature<any>>(feature: Type<TFeature>): Store2Builder<TFeature> {
    this.feature = feature;
    return this as any;
  }

  withProvider(provider: Provider): Store2Builder<T> {
    this.providers.push(provider);
    return this;
  }

  withSubFeature<TFeature extends Store2Feature<any>, TName extends string = ''>(name: TName, feature: Type<TFeature>, providers: Provider[] = []): Store2Builder<T & Record<TName, TFeature>> {
    this.subFeatures.push({name, feature, providers: providers});
    return this as any;
  }

  get token(): InjectionToken<T> {
    return this._token as InjectionToken<T>;
  }

  build() : T {
    let baseInjector = Injector.create({providers: this.providers, parent: inject(Injector)});
    const feature = this.feature ?? CustomStore;
    const storeInjector = Injector.create({
      providers: [
        {provide: SOFTLINE_FEATURE_NAME, useValue: this.name},
        {provide: feature, useClass: feature}
      ],
      parent: baseInjector
    });
    const store = storeInjector.get(feature);

    if(!(store instanceof CustomStore) && this.subFeatures.length > 0)
      throw new Error(`[Store2Builder]build: Subfeatures can only be added to a CustomStore (${this.name})`);

    for (const subFeature of this.subFeatures) {
      baseInjector = Injector.create({
        providers: [
          ...subFeature.providers,
          {provide: SOFTLINE_FEATURE_NAME, useValue: subFeature.name},
          {provide: subFeature.feature, useClass: subFeature.feature},
        ],
        parent: baseInjector}
      );
      store[subFeature.name] = baseInjector.get(subFeature.feature);
      store.addSubFeature(store[subFeature.name]);
    }
    return store as T;
  }
}
