import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { FibperListView } from "./list-view/fibper.list-view";
import { FilterConfig, SortConfig } from "@softline/application";

const defaultSort= {property: 'jahr', direction: 'desc'}
const sort: SortConfig = {
  properties: [
    {property: 'jahr', title: 'Jahr'},
  ]
}
const filter: FilterConfig = {
  properties: [
    {property: 'vondat', title: 'Von'},
    {property: 'bisdat', title: 'Bis'},
    {property: 'jahr', title: 'Jahr'},
  ]
}

export const fibperFieldOk: FieldOkConfig = {
  name: 'fibper',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{jahr}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: FibperListView,
      defaultSort,
      sort,
      filter
    }
  }
  ]
};
export const fibperMultiselectFieldOk: FieldOkConfig = {
  name: 'fibper',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{jahr}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: FibperListView,
      sort,
      filter
    }
  }
  ]
};

export const fibperQueryFieldOk: FieldOkConfig = {
  name: 'fibper',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{jahr}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: FibperListView,
      sort,
      filter
    }
  }
  ]
};
