<input
  [value]="input ?? (value | format: format || 'DATE.DEFAULT')"
  (input)="onInput(inputElement.value)"
  (change)="onBlur(inputElement.value)"
  #inputElement
  (dblclick)="!readonly ? openDatePickerModal() : ''"
  (blur)="onTouch()"
  [readOnly]="readonly"
  placeholder="{{
    placeholder || '#UI_CORE.COMPONENTS.DATE_INPUT.PLACEHOLDER' | translate
  }}"
/>
<i
  *ngIf="!readonly"
  class="fa-regular fa-calendar-day picker-icon pointer ml-4"
  (click)="openDatePickerModal()"
></i>
