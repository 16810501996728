import { Dictionary } from '../../../../types/dictionary';
import {
  createGetter,
  createMutation,
  mutate,
  select,
} from '../../../factories';
import { Entity } from '../types/entity';
import * as CollectionStore from './collection.store';

export interface State<T extends object> {
  details: Dictionary<Partial<T>>;
}

export type AddDetailParameters<
  T extends object,
  K extends keyof T = keyof T
> = { id: string | number; name: K; value: T[K] };
export type UpdateDetailParameters<
  T extends object,
  K extends keyof T = keyof T
> = { id: string | number; name: K; value: T[K] };
export type PatchDetailParameters<
  T extends object,
  K extends keyof T = keyof T
> = { id: string | number; name: K; value: Partial<T[K]> };
export type RemoveDetailParameters<
  T extends object,
  K extends keyof T = keyof T
> = { id: string | number; name: K };
export type AddOrUpdateDetailParameters<
  T extends object,
  K extends keyof T = keyof T
> = { id: string | number; name: K; value: T[K] };

export class Store<TEntity extends Entity, T extends object> {
  mutations = {
    addDetail: createMutation<State<T>, AddDetailParameters<T>>('addDetail'),
    updateDetail: createMutation<State<T>, UpdateDetailParameters<T>>(
      'updateDetail'
    ),
    patchDetail: createMutation<State<T>, PatchDetailParameters<T>>(
      'patchDetail'
    ),
    removeDetail: createMutation<State<T>, RemoveDetailParameters<T>>(
      'removeDetail'
    ),
    addOrUpdateDetail: createMutation<State<T>, AddOrUpdateDetailParameters<T>>(
      'addOrUpdateDetail'
    ),
  };
  getters = {
    details: createGetter<State<T>, T, { id: string | number }>('details'),
    detail: <K extends keyof T>() =>
      createGetter<State<T>, T[K], { id: string | number; name: K }>('detail'),
    entityWithDetails: createGetter<State<T>, TEntity & T, string | number>(
      'entityWithDetails'
    ),
    allWithDetails: createGetter<State<T>, (TEntity & T)[]>('allWithDetails'),
  };

  feature = {
    initialState: {
      details: {},
    },
    mutations: [
      mutate(this.mutations.addDetail, ({ state, params }) => {
        let detail = state.details[params.id];
        if (!detail) detail = {};
        const value = detail[params.name];
        if (value)
          throw new Error(
            `DetailStore - addDetail: Detail '${String(
              params.name
            )}' already exists for Id ${params.id}.`
          );
        detail = { ...detail, [params.name]: params.value };
        return { ...state, details: { ...state.details, [params.id]: detail } };
      }),
      mutate(this.mutations.updateDetail, ({ state, params }) => {
        let detail = state.details[params.id];
        if (!detail) detail = {};
        const value = detail[params.name];
        if (!value)
          throw new Error(
            `DetailStore - updateDetail: Detail '${String(
              params.name
            )}' does not exist for Id ${params.id}.`
          );
        detail = { ...detail, [params.name]: params.value };
        return { ...state, details: { ...state.details, [params.id]: detail } };
      }),
      mutate(this.mutations.patchDetail, ({ state, params }) => {
        let detail = state.details[params.id];
        if (!detail) detail = {};
        const value = detail[params.name];
        if (!value)
          throw new Error(
            `DetailStore - patchDetail: Detail '${String(
              params.name
            )}' does not exist for Id ${params.id}.`
          );
        detail = { ...detail, [params.name]: { ...value, ...params.value } };
        return { ...state, details: { ...state.details, [params.id]: detail } };
      }),
      mutate(this.mutations.removeDetail, ({ state, params }) => {
        let detail = state.details[params.id];
        if (!detail) detail = {};
        const value = detail[params.name];
        if (!value)
          throw new Error(
            `DetailStore - removeDetail: Detail '${String(
              params.name
            )}' does not exist for Id ${params.id}.`
          );
        delete detail[params.name];
        return { ...state, details: { ...state.details, [params.id]: detail } };
      }),
      mutate(this.mutations.addOrUpdateDetail, ({ state, params }) => {
        let detail = state.details[params.id];
        if (!detail) detail = {};
        detail = { ...detail, [params.name]: params.value };
        return { ...state, details: { ...state.details, [params.id]: detail } };
      }),
    ],
    actions: [],
    getters: [
      select(this.getters.details, ({ state, params }) => {
        return state.details[params.id];
      }),
      select(this.getters.detail(), ({ state, params }) => {
        const details = state.details[params.id];
        if (details === undefined) return undefined;
        return details[params.name];
      }),
      select(
        this.getters.entityWithDetails,
        ({ state, params, get, featureName }) => {
          const entity = get(
            featureName,
            CollectionStore.getters.entity,
            params
          );
          return { ...entity, ...state.details[params] };
        }
      ),
      select(
        this.getters.allWithDetails,
        ({ state, params, get, featureName }) => {
          const entities = get(
            featureName,
            CollectionStore.getters.all,
            params
          );
          return entities.map((o) => ({ ...o, ...state.details[o.id] }));
        }
      ),
    ],
  };
}

export function create<TEntity extends Entity, T extends object>(): Store<
  TEntity,
  T
> {
  return new Store<TEntity, T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const feature = instance.feature;
