import { Pipe, PipeTransform } from '@angular/core';
import { ControlDefinition, Definition } from '../data/definitions';
import { Dictionary, isDefined } from '@softline/core';
import { FormControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'formControl',
  pure: true,
  standalone: true,
})
export class FormControlPipe implements PipeTransform {
  transform(
    formGroup: FormGroup,
    name: string
  ): FormControl {
    return formGroup.controls[name] as any;
  }
}
