import { Observable } from "rxjs";

export type SwipeDirection = 'left' | 'top' | 'right' | 'bottom'

export interface SwipeAction {
  title?: string;
  position: SwipeDirection;
  icon?: string;
  canExecute?: Observable<boolean>;
  class?: string;
  isVisible?: Observable<boolean>;
  execute(): void;
  priority?: number;
}
