import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { isDefined, Store, StoreModule } from '@softline/core';
import { JwtAuthenticationInterceptor } from './interceptors/jwt-authentication.interceptor';
import {
  SOFTLINE_CONFIG_JWT,
  SOFTLINE_CONFIG_JWT_EXPIRATION_TIME,
  SOFTLINE_CONFIG_JWT_REFRESH_TIME,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION,
} from './jwt-authentication.shared';
import * as JwtAuthenticationStore from './jwt-authentication.store';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class JwtAuthenticationModule {
  static forRoot(): ModuleWithProviders<JwtAuthenticationRootModule> {
    return {
      ngModule: JwtAuthenticationRootModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtAuthenticationInterceptor,
          multi: true,
        },
        { provide: SOFTLINE_CONFIG_JWT_EXPIRATION_TIME, useValue: 3600 },
        { provide: SOFTLINE_CONFIG_JWT_REFRESH_TIME, useValue: 1800 },
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_JWT_AUTHENTICATION,
      feature: JwtAuthenticationStore.feature,
    }),
  ],
  providers: [],
})
export class JwtAuthenticationRootModule {
  constructor(
    store: Store,
    @Optional() @Inject(SOFTLINE_CONFIG_JWT) jwt: string | undefined
  ) {
    if (isDefined(jwt))
      store.commit(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.mutations.setToken,
        jwt
      );
    else
      store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.load
      );
  }
}
