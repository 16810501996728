<div class="soft-title light">{{ '#PASSWORD.SETTINGS.STEP1' | translate }}</div>
<hr class="soft-separator" />

<form [formGroup]="emailForm" (ngSubmit)="sendToken(emailForm.value)">
  <div class="c column mt-4">
    <label class="soft-label top required" for="settings-password-email">{{
      '#PASSWORD.SETTINGS.EMAIL' | translate
    }}</label>
    <soft-input
      id="settings-password-email"
      formControlName="email"
    ></soft-input>
    <soft-validation-container
      *ngIf="emailForm.controls['email'].touched"
      [validation]="emailForm | validationMessage: 'email'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>
  <div class="c row end mt-2">
    <button
      type="submit"
      class="soft-button primary"
      [disabled]="!(emailForm | isValid)"
    >
      {{ '#PASSWORD.SETTINGS.SEND_TOKEN' | translate }}
    </button>
  </div>
</form>

<div class="soft-title light">{{ '#PASSWORD.SETTINGS.STEP2' | translate }}</div>
<hr class="soft-separator" />

<form
  [formGroup]="passwordForm"
  (ngSubmit)="changePassword(passwordForm.value)"
>
  <div class="c column mt-4">
    <label for="settings-password-token" class="soft-label top required">{{
      '#PASSWORD.SETTINGS.TOKEN' | translate
    }}</label>
    <soft-input
      id="settings-password-token"
      formControlName="token"
      autocomplete="one-time-code"
    ></soft-input>
    <soft-validation-container
      *ngIf="passwordForm.controls['token'].touched"
      [validation]="passwordForm | validationMessage: 'token'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>
  <div class="c column mt-4">
    <label for="settings-password-new" class="soft-label top required">{{
      '#PASSWORD.SETTINGS.PASSWORD' | translate
    }}</label>
    <soft-password-input
      id="settings-password-new"
      formControlName="password"
      autocomplete="new-password"
    ></soft-password-input>
    <soft-validation-container
      [validation]="passwordForm | validationMessage: 'password'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>
  <div class="c column mt-4">
    <label for="settings-password-repeat" class="soft-label top required">{{
      '#PASSWORD.SETTINGS.PASSWORD_REPEAT' | translate
    }}</label>
    <soft-password-input
      id="settings-password-repeat"
      formControlName="passwordRepeat"
      autocomplete="new-password"
    ></soft-password-input>
    <soft-validation-container
      *ngIf="passwordForm.controls['passwordRepeat'].touched"
      [validation]="passwordForm | validationMessage: 'passwordRepeat'"
      class="soft-property-validation"
    ></soft-validation-container>
  </div>

  <div class="c row space-between mt-2 soft-text s">
    <span> </span>
    <button
      type="submit"
      class="soft-button primary"
      [disabled]="!(passwordForm | isValid)"
    >
      {{ '#PASSWORD.SETTINGS.CHANGE_PASSWORD' | translate }}
    </button>
  </div>
</form>
