import { Pipe, PipeTransform } from "@angular/core";
import { Dictionary, NoopValueConverter, Store, ValueConverter } from "@softline/core";
import { FieldOkResultConverter } from "../../lib/components/field-ok/services/field-ok-result.converter";

@Pipe({
  name: 'resultMappingConverter',
  standalone: true,
})
export class ResultMappingConverterPipe implements PipeTransform {
  constructor() {
  }

  transform(parameters: Dictionary<string> | undefined, options?: {mapKeysOnly?: boolean}): ValueConverter<any, any> {
    if(!parameters)
      return new NoopValueConverter();
    return new FieldOkResultConverter(parameters, options)
  }
}
