import { Component } from "@angular/core";
import { WithModal } from "../../modal.mixin";

@Component({
  selector: 'soft-calculator-modal',
  templateUrl: './calculator-modal.component.html',
  styleUrls: ['./calculator-modal.component.scss'],
})
export class CalculatorModalComponent extends WithModal() {

  title: string | undefined;
  showCancel = false;
  params?: object;
  value: string = '';

  constructor() {
    super();
  }

  onSubmit(result: number): void {
    this.close(result);
  }
}
