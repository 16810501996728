import {
  Component,
  computed,
  contentChild,
  EventEmitter,
  inject,
  input,
  Output,
  output,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { isDefinedNotEmpty, LoadingState } from '@softline/core';
import {
  IconComponent,
  IsDefinedNotEmptyPipe,
  UiCoreModule,
} from '@softline/ui-core';
import { Definition, DynamicSkeletonComponent } from '@softline/dynamic';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  auditTime,
  debounce,
  debounceTime,
  iif,
  mergeMap,
  of,
  tap,
  throttleTime,
  timer,
} from 'rxjs';
import { SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME } from '@softline/application';

@Component({
  selector: 'soft-remote-data-container',
  standalone: true,
  imports: [
    CommonModule,
    IsDefinedNotEmptyPipe,
    IconComponent,
    UiCoreModule,
    DynamicSkeletonComponent,
  ],
  templateUrl: './remote-data-container.component.html',
  styleUrl: './remote-data-container.component.scss',
})
export class RemoteDataContainerComponent<T> {
  private debounceTime = 150;

  data = input.required<T>();

  loadingState = input.required<LoadingState>();
  debouncedLoadingState$ = toObservable(this.loadingState).pipe(
    debounceTime(this.debounceTime)
  );
  debouncedLoadingState = toSignal(this.debouncedLoadingState$, {
    initialValue: null,
  });
  @Output() cancel = new EventEmitter<void>();

  definition = input<Definition | null>(null);
  loadingTemplate = input<TemplateRef<any> | null>(null);

  emptyMessage = input<string | null>(null);
  failureMessage = input<string | null>(null);
  canceledMessage = input<string | null>(null);
}
