<span class="hover:underline cursor-pointer"
      [ngClass]="{'italic !text-light soft-label required': !$any(filter).value}"
      (click)="onValueClick()">
  {{$any(filter).value ? ('"'+$any(filter).value+'"') : ('#APPLICATION.FILTER_AND_SORT.FILTER.TEXT.PLACEHOLDER' | translate) }}
  <span class="italic text-light" *ngIf="$any(filter).caseSensitive">{{'#APPLICATION.FILTER_AND_SORT.FILTER.TEXT.CASE_SENSITIVE' | translate}}</span>
</span>

<ng-template let-close="close" #dialogRef>
  <div class="flex flex-col" [formGroup]="form">
    <label class="soft-label top required">{{'#APPLICATION.FILTER_AND_SORT.FILTER.TEXT.LABEL' | translate}}</label>
    <soft-combo-box formControlName="value"
                    softFocus>
      <div class="cursor-pointer select-none"
           [class.text-lighter]="!form.value?.caseSensitive"
           (click)="toggleCaseSensitive()">Cc</div>
      <soft-combo-box-option *ngFor="let value of values" [value]="value"></soft-combo-box-option>
    </soft-combo-box>
    <soft-validation-container
      *ngIf="form.controls['value'].touched"
      [validation]="form | validationMessage: 'value'"
      class="soft-property-validation"
    ></soft-validation-container>
    <button [disabled]="form.invalid"
            class="soft-button primary h-16 rounded mt-4"
            (click)="close(form.value)">{{'#APPLICATION.FILTER_AND_SORT.FILTER.TEXT.SUBMIT' | translate}}</button>
  </div>
</ng-template>
