export interface TelemetryBaggage {
  messages: string[];
  calls: MethodCall[];
  [name: string]: any;
}

export interface MethodCall {
  class?: string;
  method?: string;
  args?: any;
}
