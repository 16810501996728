import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard } from '@softline/auth';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  StoreModule,
} from '@softline/core';
import { SubkundenStore } from './store';
import {
  SOFTLINE_FEATURE_SUBKUNDEN,
  SOFTLINE_PERMISSIONS_SUBKUNDEN,
} from './subkunden.shared';
import { SOFTLINE_API_SUBKUNDEN } from './subkunden.api';

export const subkundenRoutes: Routes = [
  {
    path: 'subkunden',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/subkunden-page/subkunden-page.component').then(
            (o) => o.SubkundenPageComponent
          ),
        pathMatch: 'full',
      },
      { path: '**', redirectTo: '' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSIONS_SUBKUNDEN },
    providers: [
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SUBKUNDEN,
          feature: SubkundenStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineEntityService(service, SOFTLINE_API_SUBKUNDEN),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
          ],
        })
      ),
    ],
  },
];
