import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import { AuthorizationService } from './authorization.service';

export interface State {
  loading: boolean;
  loaded: boolean;
  permissions: string[];
}

export const mutations = {
  loadingCompleted: createMutation<State, string[]>('loadingCompleted'),
  setLoading: createMutation<State>('setLoading'),
  addPermissions: createMutation<State, string[]>('addPermissions'),
  reset: createMutation<State>('reset'),
};

export const actions = {
  load: createAction<State, undefined, string[]>('load'),
  loadOnce: createAction<State, undefined, string[]>('loadOnce'),
};

export const getters = {
  permissions: createGetter<State, string[]>('permissions'),
  authorized: createGetter<State, boolean, string>('authorized'),
  loading: createGetter<State, boolean>('loading'),
  loaded: createGetter<State, boolean, string>('loaded'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    permissions: [],
    loading: false,
    loaded: false,
  },
  mutations: [
    mutate(mutations.loadingCompleted, ({ state, params }) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        permissions: [...state.permissions, ...params],
      };
    }),
    mutate(mutations.addPermissions, ({ state, params }) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        permissions: [...state.permissions, ...params],
      };
    }),
    mutate(mutations.setLoading, ({ state, params }) => {
      return { ...state, loading: true };
    }),
    mutate(mutations.reset, ({ state, params }) => {
      return { ...state, loading: false, loaded: false, permissions: [] };
    }),
  ],
  actions: [
    on(actions.load, async ({ commit, featureName, injector }) => {
      const service = injector.get(AuthorizationService);
      commit(featureName, mutations.setLoading);
      const permissions = await service.load();
      commit(featureName, mutations.loadingCompleted, permissions);
      return permissions;
    }),
    on(actions.loadOnce, async ({ store, featureName, injector, state, dispatch }) => {
      if(state.loaded)
        return state.permissions;
      return await dispatch(featureName, actions.load);
    }),
  ],
  getters: [
    select(getters.permissions, ({ state }) => state.permissions),
    select(getters.loading, ({ state }) => state.loading),
    select(getters.loaded, ({ state }) => state.loaded),
    select(
      getters.authorized,
      ({ state, params }) => state.permissions.indexOf(params) > -1
    ),
  ],
};
