import { NotificationResult } from './notification-result';

export interface NotificationConfig {
  title?: string;
  text: string;
  class?: string;
  showCancelAction?: boolean;
  params?: object;
  dismiss?:
    | boolean
    | {
        backdrop?: boolean;
        escape?: boolean;
      };
}
