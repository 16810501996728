import { Component, Input, OnInit } from '@angular/core';
import { ValidationMessage } from '@softline/core';

@Component({
  selector: 'soft-validation-container',
  templateUrl: './validation-container.component.html',
  styleUrls: ['./validation-container.component.scss'],
})
export class ValidationContainerComponent implements OnInit {
  @Input() validation?: { messages?: ValidationMessage[] } | null;

  constructor() {}

  ngOnInit(): void {}
}
