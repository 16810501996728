import { Observable, Subscription } from 'rxjs';
import { Store2Feature } from '../../store2-feature';
import { Dictionary } from '../../../types/dictionary';
import { CancelledError } from '../../../types/errors';
import { InjectableStore2Feature } from '../../injectable-store2-feature';
import { Injectable } from '@angular/core';

export type SubscriptionState = 'active' | 'canceled' | 'completed' | 'failed';

@Injectable()
export class SubscriptionStore2 extends InjectableStore2Feature<Dictionary<SubscriptionState>>{
  private subscriptions = new Dictionary<Subscription>();

  constructor() {
    super();
  }

  observe<T>(token: string, observable: Observable<T>): Observable<T> {
    if(this.subscriptions[token])
      throw new Error(`[SubscriptionStore2] - subscribe: There is already a subscription with id ${token}`);

    return new Observable<T>((observer) => {
      const subscription = observable.subscribe(
        (o) => observer.next(o),
        (e) => {
          this.commitPatch({ [token]: 'failed' });
          delete this.subscriptions[token];
          observer.error(e);
        },
        () => {
          this.commitPatch({ [token]: 'completed' });
          delete this.subscriptions[token];
          observer.complete();
        }
      );

      this.commitPatch({ [token]: 'active' })
      subscription.add(() => {
        this.commitPatch({ [token]: 'canceled' });
        delete this.subscriptions[token];
        observer.error(new CancelledError(`[SubscriptionStore2] - observe: canceled (${token})`));
      });
      this.subscriptions[token] = subscription;
    });
  }

  cancel(token: string): void {
    const subscription = this.subscriptions[token];
    if(!subscription)
      throw new Error(`[SubscriptionStore2] - cancel: There is no subscription with id ${token}`);
    if(subscription.closed)
      throw new Error(`[SubscriptionStore2] - cancel: subscription with id ${token} is already closed`);
    subscription.unsubscribe();
  }

  override getDefaultState(): Dictionary<SubscriptionState> {
    return {};
  }
}
