import { catchError, Observable, of, switchMap } from 'rxjs';
import { LocalStorageService } from '@softline/core';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  QueryHistoryService,
  Template,
  TemplateService,
} from '@softline/application';

const KEY = 'queryHistory';

@Injectable()
export class SoftappsQueryHistoryService extends QueryHistoryService {
  constructor(private service: LocalStorageService) {
    super();
  }

  override loadMany(group: string | undefined): Observable<Template<any>[]> {
    const location = {
      key: KEY,
    };
    return this.service.get<Template<any>[]>(location).pipe(
      map((o) => o.filter((p) => !group || p.group === group)),
      catchError(() => of([]))
    );
  }

  override create(template: Template<any>): Observable<Template<any>> {
    const location = {
      key: KEY,
    };
    return this.service.get<Template<any>[]>(location).pipe(
      catchError(() => of([])),
      switchMap((o) =>
        this.service.update<Template<any>[], Template<any>[]>(location, [
          ...o,
          template,
        ])
      ),
      map((o) => o.find((p) => p.id === template.id) as Template<any>)
    );
  }

  override delete(template: Template<any>): Observable<Template<any>> {
    const location = {
      key: KEY,
    };
    return this.service.get<Template<any>[]>(location).pipe(
      switchMap((o) => {
        const templates = [...o];
        templates.splice(
          templates.findIndex((p) => p.id === template.id),
          1
        );
        return this.service.update<Template<any>[], Template<any>[]>(
          location,
          templates
        );
      }),
      map((o) => template)
    );
  }
}
