import { ObjectDefinition } from "@softline/dynamic";

export const gesamtabfrageQueryDefinition: ObjectDefinition = {
  type: "object",
  name: "GesamtabfrageQuery",
  class: "grid grid-cols-1 md:grid-cols-2 auto-rows-auto gap-4",
  definitions: [
    {
      type: "date",
      name: "from",
      title: "Von Datum",
      required: true,
      class: ""
    },
    {
      type: "date",
      name: "to",
      title: "Bis Datum",
      required: true,
      class: ""
    },
    {
      type: "string",
      name: "belegnummer",
      title: "Belegnummer",
      class: "md:col-span-2",
      validations: [{
        rule: {
          name: "regex",
          expression: "^\\d*$"
        },
        isValid: false,
        messages: [{"type": "error", "subject": "Muss eine Zahl sein!", "message": ""}]
      }]
    },
    {
      type: "fieldOk",
      name: "konto",
      title: "Kunde",
      format: "{{nummer}} - {{kurzbez}}",
      fieldOk: "ktostamm.kunde",
      class: "md:col-span-2",
      visible: {
        name: "permission",
        permission: "GESAMTABFRAGE.KUNDENSUCHE"
      } as any,
    },
    {
      type: "string",
      name: "artikel",
      title: "Artikel",
      class: "md:col-span-2"
    },
    /*{
      type: "group",
      control: "section",
      title: "Zusätzliche Filter",
      transformer: SelectedGroupFieldsDefinitionTransformer,
      transformerArgs: ["gesamtabfrageQueryZusätzlicheFilter", ["belegnummer2"]],
      class: "md:col-span-2",
      definitions: [
        {
          type: "string",
          title: "Belegnummer2",
          name: "belegnummer2",
          filter: {"title": "Belegnummer"},
          sortable: {"title": "Belegnummer"}
        },
        {
          type: "string",
          title: "Belegnummer3",
          name: "belegnummer3",
          filter: {"title": "Belegnummer"},
          sortable: {"title": "Belegnummer"}
        },
      ]
    }*/
  ]
}
