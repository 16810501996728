import {Route} from '@angular/router';
import {checklistenResolver} from './resolver/checklisten.resolver';
import {SOFTLINE_PERMISSION_CHECKLISTE} from './checkliste.shared';

export const checklisteRoutes: Route[] = [
  {
    path: 'aufgaben',
    loadComponent: () => import('./pages/checkliste-host.component'),
    resolve: { checklisten: checklistenResolver },
    children: [
      {
        path: ':id',
        loadComponent: () => import('./pages/checkpunkte/checkpunkte.component')
      },
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () => import('./pages/checklisten/checklisten.component')
      },
    ],
    data: {
      permission: SOFTLINE_PERMISSION_CHECKLISTE
    }
  },
];
