import {Component, OnDestroy, OnInit, Optional} from "@angular/core";
import { Store } from "@softline/core";
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule } from "@softline/ui-core";
import { CommonModule } from "@angular/common";
import { asapScheduler, lastValueFrom, observeOn, skip, Subscription } from "rxjs";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NfcReaderSettings } from "../types/nfc-reader-settings";
import { SOFTLINE_FEATURE_NFC_READER, SOFTLINE_SETTINGS_NFC_READER } from "../nfc-reader.shared";
import * as NfcReaderStore from '../nfc-reader.store';
import { SOFTLINE_FEATURE_SETTINGS } from "../../application.shared";
import * as SettingsStore from '../../settings/settings.store';
import { NfcReaderService } from "../services/nfc-reader.service";

@Component({
  selector: 'lib-storage-settings',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
  templateUrl: './nfc-reader-settings.component.html',
  styleUrls: ['./nfc-reader-settings.component.scss'],
})
export class NfcReaderSettingsComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;
  private settingsSubscription?: Subscription;
  private formSubscription?: Subscription;

  form = new FormGroup({
    enabled: new FormControl(false)
  })
  constructor(private store: Store, @Optional() public service: NfcReaderService) {
  }

  ngOnInit(): void {
    if(!this.service) {
      console.warn('[NfcReaderSettingsComponent] ngOnInit, no Nfc Reader Service provided');
    }
    if(!this.service?.available)
      this.form.disable();

    this.subscription= this.store.observe(SOFTLINE_FEATURE_NFC_READER, NfcReaderStore.getters.latest)
      .pipe(
        skip(1),
        observeOn(asapScheduler)
      )
      .subscribe(async o => {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          o?.type === 'read' ? MessageBarStore.actions.info:  MessageBarStore.actions.error,
          JSON.stringify(o));
      });
    this.settingsSubscription = this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<NfcReaderSettings>(),
        SOFTLINE_SETTINGS_NFC_READER
      )
      .subscribe((o) => {
        this.form.patchValue(o ?? {}, { emitEvent: false });
      });

    this.formSubscription = this.form.valueChanges.subscribe(value => {
      this.store.commit(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.mutations.setKey(),
        {key: SOFTLINE_SETTINGS_NFC_READER, values: this.form.value as any}
      )
      this.store.dispatch(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.actions.save
      )
    })
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
    if (this.settingsSubscription && !this.settingsSubscription.closed)
      this.settingsSubscription.unsubscribe();
    this.settingsSubscription = undefined;
    if (this.formSubscription && !this.formSubscription.closed)
      this.formSubscription.unsubscribe();
    this.formSubscription = undefined;
  }

}
