import { Entity } from '../store/specialized/entity/types/entity';
import { TemplateService } from './entity.service';
import { Inject } from '@angular/core';
import { SOFTLINE_SERVICE_HTTP } from '../core.shared';
import { ResourceService } from '../data/abstraction';
import { ConnectionResourceLocation } from '../data/specialized/http/connection.location';
import { Dictionary } from '../types/dictionary';
import { lastValueFrom } from 'rxjs';

export class SoftlineTemplateService<T extends Entity>
  implements TemplateService<T>
{
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP)
    protected service: ResourceService<ConnectionResourceLocation>,
    protected path: string
  ) {}

  loadTemplate(
    data?: Dictionary<unknown>,
    pathParams?: Dictionary<unknown>,
    queryParams?: Dictionary<unknown>
  ): Promise<Partial<T>> {
    return lastValueFrom(
      this.service.get<Partial<T>, Dictionary<unknown>>(
        { path: this.path, pathParams, queryParams },
        data
      )
    );
  }
}
