<ul class="soft-list">
  <li
    *ngFor="let item of value"
    class="soft-list-item"
    [class.selected]="selected === item"
    (click)="onItemClick(item)"
  >
    <ng-container
      *ngTemplateOutlet="template || defaultTemplate; context: { item: item }"
    ></ng-container>
  </li>
</ul>
<ng-template let-item="item" #defaultTemplate>
  {{ item }}
</ng-template>
