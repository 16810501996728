import { ENVIRONMENT_INITIALIZER, inject, importProvidersFrom } from "@angular/core";
import { ConnectionHttpService, SOFTLINE_SERVICE_HTTP, SOFTLINE_STORE_ENTITY_SERVICE, SoftlineEntityDetailService, SoftlineEntityService, Store, StoreModule } from "@softline/core"
import { ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from "@softline/application"
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { default as de } from './../i18n/de.json';
import { SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN, SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_ARTIKEL_DETAIL, SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE, SOFTLINE_PERMISSION_MDE_MATERIALLIEFERSCHEIN_SCAN } from "./materiallieferschein-scan.shared";
import { MateriallieferscheinScanBeweStore } from "./stores/materiallieferschein-scan-bewe.store";
import { MateriallieferscheinScanService } from "./services/materiallieferschein-scan.service";
import { MateriallieferscheinScanStore } from "./stores/materiallieferschein-scan.store";
import { SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_ARTIKEL_DETAIL, SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE } from "./materiallieferschein-scan.api";
import { MateriallieferscheinScanArtikelDetailStore } from "./stores/materiallieferschein-scan-artikel-detail.store";
import { MateriallieferscheinScanResolver } from "./resolver/materiallieferschein-scan.resolver";
import {OnDeactivateGuard} from './guards/on-deactivate.guard';
import {KopfGuard} from './guards/kopf.guard';

export const materiallieferscheinScanProviders = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue: async () => {
      const store = inject(Store);

      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'mdeMateriallieferscheinScan',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Materiallieferschein-Scan',
          route: '/materiallieferschein-scan',
          icon: 'fa-regular fa-truck',
          permission: SOFTLINE_PERMISSION_MDE_MATERIALLIEFERSCHEIN_SCAN
        }
      );
    },
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN,
      feature: MateriallieferscheinScanStore.feature,
      providers: [
        {
          provide: MateriallieferscheinScanService,
          useFactory: () => new MateriallieferscheinScanService(
            inject(ConnectionHttpService)
          )
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE,
      feature: MateriallieferscheinScanBeweStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => new SoftlineEntityService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE
          )
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_ARTIKEL_DETAIL,
      feature: MateriallieferscheinScanArtikelDetailStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => new SoftlineEntityDetailService(
            inject(SOFTLINE_SERVICE_HTTP),
            SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_ARTIKEL_DETAIL,
            'detail'
          )
        }
      ]
    })
  ),
  MateriallieferscheinScanResolver,
  OnDeactivateGuard,
  KopfGuard
]
