import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {Umsatzebene} from '../types/umsatzebene';
import {Observable} from 'rxjs';
import {SOFTLINE_API_WWS_UMSATZABFRAGE_SUBEBENEN} from '../umsatzstatistik.api';

@Injectable()
export class UmsatzstatistikSubebenenService {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private readonly httpService: ConnectionHttpService
  ) {}

  loadSubebenen(aktuelleEbene: Umsatzebene): Observable<Umsatzebene[]> {
    return this.httpService.create(
      { path: SOFTLINE_API_WWS_UMSATZABFRAGE_SUBEBENEN },
      aktuelleEbene.umsatzebene
    );
  }
}
