import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@softline/core';
import * as BreakpointStore from './breakpoint.store';
import {
  SOFTLINE_CONFIG_BREAKPOINTS,
  SOFTLINE_FEATURE_BREAKPOINTS,
} from './breakpoint.shared';
import { BreakpointService } from './services/breakpoint.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class BreakpointModule {
  static forRoot(): ModuleWithProviders<BreakpointRootModule> {
    return {
      ngModule: BreakpointRootModule,
    };
  }
}

@NgModule({
  imports: [
    BreakpointModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_BREAKPOINTS,
      feature: BreakpointStore.feature,
    }),
  ],
  providers: [
    BreakpointService,
    {
      provide: SOFTLINE_CONFIG_BREAKPOINTS,
      useValue: {
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        xxl: '1536px',
      },
    },
  ],
  exports: [BreakpointModule],
})
export class BreakpointRootModule {
  constructor(service: BreakpointService) {}
}
