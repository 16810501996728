export type LabelType = LabelType1D | LabelType2D | MultiLabelType;
export type LabelType1D =
  | 'codabar'
  | 'code39'
  | 'code93'
  | 'code128'
  | 'ean8'
  | 'ean13'
  | 'ean128'
  | 'itf'
  | 'rss14'
  | 'upc_a'
  | 'upc_e0'
  | 'upc_e1'
  | 'msi';
export type LabelType2D =
  | 'aztec'
  | 'datamatrix'
  | 'maxicode'
  | 'pdf417'
  | 'rss_expanded'
  | 'micropdf'
  | 'qrcode';
export type MultiLabelType = 'code' | 'ean' | 'upc' | 'upc_e';
