import {CustomStore, RemoteCollectionStore, StoreBuilder} from '@softline/core';
import {ArtikelvorratArtikelDetail} from '../data/artikelvorrat-artikel-detail';

type State = typeof remoteCollectionStore.feature.initialState

const remoteCollectionStore = RemoteCollectionStore.create<ArtikelvorratArtikelDetail>()

const getters = {}
const mutations = {}
const actions = {}

const artikelvorratArtikelDetailStoreExtension: CustomStore<State, typeof mutations, typeof getters, typeof actions> = {
  getters,
  mutations,
  actions,
  feature: {
    initialState: remoteCollectionStore.feature.initialState,
    getters: [],
    mutations: [],
    actions: []
  }
}

export const ArtikelvorratArtikelDetailStore =
  new StoreBuilder(remoteCollectionStore)
    .add(artikelvorratArtikelDetailStoreExtension)
    .value
