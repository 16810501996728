import {ChangeDetectionStrategy, Component, computed, inject, input, signal, ViewChild} from '@angular/core';
import {CommonModule, DatePipe, formatDate} from '@angular/common';
import {
  CurrencyFormatPipe,
  ModalStore,
  SOFTLINE_FEATURE_MODAL,
  TabComponent,
  TabGroupComponent,
  TranslatePipe,
  UiCoreModule
} from '@softline/ui-core';
import {BarChartModule, BarHorizontalComponent} from '@swimlane/ngx-charts';
import {DownloadStore, groupBy, saveAs, SOFTLINE_FEATURE_DOWNLOAD, Store} from '@softline/core';
import {BarChartData} from '../../types/chart-data.model';
import moment from 'moment';
import {OffenerPosten} from '../../types/offener-posten';
import {handleRequestErrors} from '@softline/application';
import {SOFTLINE_API_JOPOSTEN_BELEG_DOWNLOAD} from '../../offene-posten.api';
import {
  OffenePostenTableDialogComponent
} from '../../dialogs/offene-posten-table-dialog/offene-posten-table-dialog.component';

@Component({
  selector: 'soft-op-diagram-tabelle-view',
  standalone: true,
  imports: [CommonModule, TabGroupComponent, TabComponent, BarChartModule, UiCoreModule],
  templateUrl: './diagram-tabelle-view.component.html',
  styleUrl: './diagram-tabelle-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TranslatePipe]
})
export class DiagramTabelleViewComponent {

  private readonly datePipe = inject(DatePipe);

  offenePosten = input<OffenerPosten[]>([]);
  title = input('');
  subtitle = input('');
  loading = input(false);
  invertValues = input(false);
  showExtraColumn = input(false);
  color = input<string>('var(--colorPrimary500)');

  readonly chartColorScheme= computed(() => {
    return { domain: [this.color()] }
  });

  @ViewChild('chart') readonly chart?: BarHorizontalComponent;

  readonly selectedTabIndex = signal<number>(0);

  readonly tabelleDatumsbereich = signal<{ from: Date | null, to: Date | null }>({
    from: null,
    to: null
  });

  readonly tableData = computed(() => {
    const offenePosten = this.offenePosten();
    const datumsbereich = this.tabelleDatumsbereich();

    if (offenePosten?.length < 1)
      return [];

    const vondatum = datumsbereich?.from;
    const bisdatum = datumsbereich?.to;

    if (!vondatum || !bisdatum)
      return offenePosten;

    return offenePosten
      .filter(o => this.isBetweenDate(o.faelldatum, vondatum, bisdatum))
      .sort((a, b) =>
        (a.faelldatum > b.faelldatum) ? 1 : ((b.faelldatum > a.faelldatum) ? -1 : 0)
      );
  });

  readonly chartData = computed(() => {
    const offenePosten = this.offenePosten();
    const invertValues = this.invertValues();
    const opHeute = {
      name: this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_ALLE_PER_HEUTE') ?? '',
      value: 0
    }
    const opWoche = {
      name: this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_DERZEITIGE_KW') ?? '',
      value: 0
    }
    const opNaechsteWoche = {
      name: this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_NAECHSTE_KW') ?? '',
      value: 0
    }
    const opSpaeter: BarChartData = {
      name: this.translatePipe.transform('#OFFENE_POSTEN.DIAGRAMM.LABEL_SPAETER') ?? '',
      value: 0
    }

    const today = moment().startOf('day');
    const nextWeek = moment().add(1, 'week').startOf('week');
    for (const item of offenePosten) {
      const faelldatum = moment(item.faelldatum);
      const saldo = item.saldo * (invertValues ? -1 : 1);
      if(faelldatum.isSame(today, 'day'))
        opHeute.value += saldo;
      else if(faelldatum.isSame(today, 'week'))
        opWoche.value += saldo;
      else if (faelldatum.isSame(nextWeek, 'week'))
        opNaechsteWoche.value += saldo;
      else if(faelldatum.isAfter(nextWeek, 'week'))
        opSpaeter.value += saldo;
      else
        opHeute.value += saldo;
    }
    return [opHeute, opWoche, opNaechsteWoche, opSpaeter];
  });

  readonly faelligkeitenInTotal = computed(() => {
    const sum = this.offenePosten().reduce((sum, value) => sum + value.saldo, 0);
    return this.invertValues() ? sum * -1 : sum;
  });

  constructor(
    private store: Store,
    private translatePipe: TranslatePipe,
    private currencyFormatPipe: CurrencyFormatPipe,
  ) {}

  readonly formatDataLabel = (value: string) => {
    return this?.currencyFormatPipe?.transform(value, 'EUR', 'symbol', 'before', '', 'de-AT') ?? value;
  }

  // required to fix a glitch in the chart when switching between chart and table (tabs)
  onChartActivate(): void {
    setTimeout(() => this.chart?.update(), 100);
  }

  async onSelect(event: BarChartData): Promise<void> {
    let weekNumber;
    let isThisWeek = false

    if (event.name === 'Alle per heute') {
      weekNumber = moment(new Date()).get('week');
      isThisWeek = true;
    } else if (event.name === 'Derzeitige KW') {
      weekNumber = moment(new Date()).get('week');
      isThisWeek = false;
    } else {
      weekNumber = +event.name.split(' ')[1];
      isThisWeek = false;
    }

    if (!weekNumber || isNaN(weekNumber))
      return;

    const year = this.datePipe.transform(new Date(), 'YYYY');
    const momentWeek = moment(`${year}W${weekNumber}`);

    let startDate: Date;
    let endDate: Date;

    if (isThisWeek) {
      const today = this.resetHoursToStartOfDay();

      const alleHeuteFaellig = this.offenePosten()
        .filter(a => (this.resetHoursToStartOfDay(a.faelldatum) <= today))
        .sort((a, b) =>
          (b.faelldatum > a.faelldatum)
            ? 1
            : -1
        );

      startDate = moment(alleHeuteFaellig[alleHeuteFaellig.length - 1].faelldatum).startOf('day').toDate();
      endDate = momentWeek.endOf('week').toDate();
    } else if (event.name === 'Derzeitige KW') {
      startDate = moment(new Date()).add(1, 'day').startOf('day').toDate()
      endDate = momentWeek.endOf('week').toDate();
    } else {
      startDate = momentWeek.startOf('week').toDate();
      endDate = momentWeek.endOf('week').toDate();
    }

    this.tabelleDatumsbereich.set({ from: startDate, to: endDate });
    this.selectedTabIndex.set(1);

    await this.openDetails({
      from: startDate,
      to: endDate,
      offenePosten: this.tableData(),
    });
  }

  async showOffenePostenDialog(): Promise<void> {
    await this.openDetails({
      offenePosten: this.offenePosten()
    });
  }

  async openDetails({ from, to, offenePosten }: { from?: Date, to?: Date, offenePosten: OffenerPosten[] }): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        component: OffenePostenTableDialogComponent,
        dismiss: true,
        data: {
          from,
          to,
          offenePosten,
          showExtraColumn: this.showExtraColumn()
        }
      }
    )
  }

  async downloadFile(op: OffenerPosten): Promise<void> {
    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_DOWNLOAD,
        DownloadStore.actions.download,
        {
          filename: `OP_${op.symbol.symbol}_${op.belegnummer}.pdf`,
          location: {
            path: SOFTLINE_API_JOPOSTEN_BELEG_DOWNLOAD,
            pathParams: {
              idjoposten: op.id
            }
          }
        }
      );

      saveAs(result.content, result.filename);
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  private resetHoursToStartOfDay(dateString?: string): Date {
    const date = dateString ? new Date(dateString) : new Date()
    date.setHours(0, 0, 0, 0);
    return date;
  }

  private isBetweenDate(date: string, from: Date, to: Date): boolean {
    return moment(new Date(date)).isBetween(from, to);
  }
}
