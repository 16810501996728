import { Injectable } from "@angular/core";
import { Store } from "@softline/core";
import { ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { InputScannerComponent } from "../components/input-scanner.component";
import { LabelType } from "../../../data/label-type";
import { ScannerService } from "../../../services/scanner.service";
import { Scan } from "../../../data/scan";

const MODAL_ID = 'InputScannerModal';

@Injectable()
export class InputScannerService extends ScannerService {

  constructor(private store: Store) {
    super();
  }

  override async init(): Promise<void> {
    this.isAvailable = true;
  }

  async scan(labelType?: LabelType | LabelType[]): Promise<Scan> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Scan, any>(),
      {
        id: MODAL_ID,
        component: InputScannerComponent,
        data: {
          labelTypes: Array.isArray(labelType) ? labelType : [labelType ?? ''],
        },
        priority: 50,
        dismiss: true,
      }
    );
    if (result === 'DISMISSED')
      throw new Error('InputScanner: canceled scan');

    return result;
  }

  async cancel(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.close,
      MODAL_ID
    );
  }
}
