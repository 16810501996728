import { UmsatzstatistikRemoteConfig } from "../types/remote-config";
import {RemoteConfig} from '@softline/application';

function getSort(value?: string): UmsatzstatistikRemoteConfig['sort'] {
  const validCases: UmsatzstatistikRemoteConfig['sort'][] = ['Umsatz_asc', 'Umsatz_desc', 'DB_asc', 'DB_desc'];
  return validCases.find(o => o === value) ?? 'Umsatz_desc';
}

export function extractUmsatzstatistikRemoteConfig(config: RemoteConfig): UmsatzstatistikRemoteConfig {
  const module = (config?.modules ?? []).find(o => o.module === 'umsatzstatistik');

  return {
    sort: getSort(module?.properties?.find(o => o.key === 'SORTIERUNG')?.value),
    defaultAufgliederung: module?.properties?.find(o => o.key === 'DEF_AUFGLIEDERUNG')?.value,
    defaultEbene: module?.properties?.find(o => o.key === 'DEF_UMSATZEBENE')?.value,
    defaultUmsatzart: module?.properties?.find(o => o.key === 'DEF_UMSATZART')?.value,
  }
}
