import { InjectionToken } from "@angular/core";

export const SOFTLINE_REGISTRATION_FILTER = new InjectionToken<FilterRegistration<any, Filter>>('SOFTLINE_REGISTRATION_FILTER')

export type FilterOptions<T extends Filter> = Omit<T, 'operator'>;
export type FilterPredicateSelector = (operator: string) => (value: unknown, options: FilterOptions<Filter>, getFilter: FilterPredicateSelector) => boolean;

export interface FilterRegistration<T, TFilter extends Filter> {
  operator: string;
  predicate(value: T, options: FilterOptions<TFilter>, getFilter: FilterPredicateSelector): boolean
}

export interface Filter {
  operator: string;
}

export interface LogicalFilter extends Filter{
  filters: Filter[]
}

export interface PropertyFilter extends Filter {
  property: string;
}

export interface UnaryFilter<T, P extends keyof T> extends Filter {
  property?: P | null;
}

export interface RelationalFilter<T, P extends keyof T, V extends T[P]> extends Filter {
  property?: P | null;
  value: V;
}

export interface ArrayFilter<T, P extends keyof T, V extends T[P]> extends Filter {
  property?: P | null;
  value: V[];
}

export interface PatternFilter<T, P extends keyof T> extends Filter {
  property?: P | null;
  value: string;
  caseSensitive?: boolean;
  wholeText?: string;
}

export function isLogicalFilter(filter: unknown): filter is LogicalFilter {
  return (filter as LogicalFilter)?.filters !== undefined
}

export function isPropertyFilter<P>(filter: unknown): filter is PropertyFilter {
  return (filter as any)?.property !== undefined
}
