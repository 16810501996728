import { computed, inject, Injectable, OnDestroy, OnInit } from "@angular/core";
import {
  CollectionStore, Entity, Filter,
  FilterCollectionStore,
  FilterService, Sort,
  SortCollectionStore,
  SortService,
  Store
} from "@softline/core";
import { BelegStore, SOFTLINE_FEATURE_GESAMTABFRAGE_BELEG } from "@softapps/wws/gesamtabfrage";

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithData = <T extends Constructor<{}>>(featureName: string, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class DataMixin extends Base {

    store = inject(Store);

    all= this.store.signal(
      featureName,
      CollectionStore.getters.all
    );

    total = this.store.signal(
      featureName,
      CollectionStore.getters.count
    );

    constructor(...args: any[]) {
      super(...args);
    }
  }
  return DataMixin;
}
