export abstract class FileService {
  abstract read(file: string): Promise<Blob>;

  abstract write(data: Blob, filename: string): Promise<string>;
  abstract writeTmp(
    data: Blob,
    filename?: string,
    mimeType?: string
  ): Promise<string>;

  abstract open(url: string, mimeType?: string): Promise<void>;
  abstract openBlob(
    data: Blob,
    filename?: string,
    mimeType?: string
  ): Promise<void>;

  abstract browse(options?: { mode?: 'single' | 'multi', accept?: string}): Promise<File[]>;
  /*{
  return new Promise<File[]>((resolve, reject) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('multiple', options?.mode === 'multi' ? 'true' : 'false');
  input.onchange = (e) => {
    if (input.files && input.files.length > 0)
      resolve(Array.from(input.files));
    reject('No files chosen');
  };
  input.click();
});
}*/
}
