<div class="modal question-modal">
  <div class="p-4 sm:pl-0 sm:pt-0 text-2xl font-semibold">
    {{ title ?? 'Löschen' | translate | interpolate: params }}
  </div>
  <div class="question pr-4 pl-4 pb-4 sm:pl-0">
    {{ question ?? 'Möchten Sie diesen Eintag wirklich löschen?' | translate | interpolate: params }}
  </div>
  <p *ngIf="content" class="mb-4 mx-3 border-box">
    <ng-container *ngTemplateOutlet="content; context: (params ?? null)"></ng-container>
  </p>
  <div class="flex no-wrap w-full gap-4 px-4 sm:px-0 pb-4 pt-2">
    <button
      class="soft-button h-16 w-full"
      (click)="onClick('CANCEL')"
    >
      {{ '#UI_CORE.DIALOGS.NO' | translate }}
    </button>
    <button
      class="soft-button danger sm:ml-3 h-16 w-full flex flex-row flex-nowrap justify-center items-center"
      (click)="onClick('DELETE')"
    >
      <soft-icon name="fa-regular fa-trash" class="mr-1"></soft-icon>
      {{ '#UI_CORE.DIALOGS.DELETE' | translate }}
    </button>
  </div>
</div>
