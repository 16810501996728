import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'min',
})
export class MinimumPipe<T> implements PipeTransform {
  transform(
    values: readonly T[],
    comparer?: (a: T, b: T) => -1 | 0 | 1
  ): T | undefined {
    if (!comparer) comparer = this.defaultComparer;
    if (values.length === 0) return undefined;

    let returnValue = values[0];
    let i = 1;
    while (i < values.length) {
      if (comparer(values[i], returnValue) === -1) returnValue = values[i];
      i++;
    }
    return returnValue;
  }

  private defaultComparer(a: T, b: T): -1 | 0 | 1 {
    if (a === b) return 0;
    else if (a < b) return -1;
    else return 1;
  }
}
