import { Injectable } from '@angular/core';
import { Refreshable } from '../refreshable';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  concatMap, Observable,
  of,
  switchMap
} from "rxjs";
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class RefreshService {
  private refreshables$ = new BehaviorSubject<Refreshable[]>([]);
  private _refreshing$ = new BehaviorSubject<boolean>(false);

  get canRefresh$(): Observable<boolean> {
    return this.refreshables$.pipe(
      mergeMap((o) => combineLatest(o.map((p) => p.canRefresh$ ?? of(true)))),
      map((o) => o.some((p) => p))
    );
  }
  get refreshing(): Observable<boolean> {
    return this._refreshing$;
  }

  add(refreshable: Refreshable): void {
    this.refreshables$.next([...this.refreshables$.value, refreshable]);
  }

  remove(refreshable: Refreshable): void {
    const refreshables = [...this.refreshables$.value];
    refreshables.splice(refreshables.indexOf(refreshable), 1);
    this.refreshables$.next(refreshables);
  }

  async refresh(): Promise<void> {
    this._refreshing$.next(true);
    await Promise.all(
      this.refreshables$.value.map((o) => o.refresh())
    );
    this._refreshing$.next(false);
  }
}
