import {Pipe, PipeTransform} from '@angular/core';
import {ItemDetail} from '../types/item-detail';

@Pipe({
  name: 'groupByStock',
  standalone: true,
  pure: true
})
export class GroupByStockPipe implements PipeTransform {
  transform(value: ItemDetail['itemStorage']): any[] {
    const data: { [stockId: string]: { stock: object; storages: any[] } } = {};

    for (const entry of value ?? []) {
      const dataEntry = data[entry.stock.id];

      if (!dataEntry) {
        data[entry.stock.id] = {
          stock: entry.stock,
          storages: [
            ...(data[entry.stock.id]?.storages ?? []),
            { name: entry?.name, kzlagerplatz: entry?.kzlagerplatz, title: entry?.title }
          ]
        };
      } else {
        data[entry.stock.id] = {
          ...data[entry.stock.id],
          storages: [
            ...data[entry.stock.id].storages,
            { name: entry?.name, kzlagerplatz: entry?.kzlagerplatz, title: entry?.title }
          ]
        }
      }
    }

    return Object.values(data);
  }
}
