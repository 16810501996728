import {Step} from '@softline/application';

export const SOFTLINE_CONFIG_MDE_BESTELLVORSCHLAG_STEPS: Step[] = [
  {
    header: 'Artikel suchen',
    subHeader: 'Bitte gewünschten Artikel auswählen',
  },
  {
    header: 'Artikeldaten',
    subHeader: 'Bitte passen Sie die Artikeldaten an',
  }
]

export const SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG = 'mdeBestellvorschlag'
export const SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_BEWE = 'mdeBestellvorschlagBewe'
export const SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_DETAILS = 'mdeBestellvorschlagArtikelDetails';

export const SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL = 'mdeBestellvorschlagArtikel';
export const SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_PREIS = 'mdeBestellvorschlagArtikelPreis';
export const SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG_ARTIKEL_LAGERMENGE = 'mdeBestellvorschlagArtikelLagermenge';

export const SOFTLINE_PERMISSION_MDE_BESTELLVORSCHLAG = 'BESTELLVORSCHLAG'
