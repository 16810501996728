export const SOFTLINE_API_WWS_WARENUEBERNAHME = 'v1/wws/warenuebernahme-kontrolle/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_SPERREN = 'v1/wws/warenuebernahme-kontrolle/entities/{{idwu}}/sperren';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_OEFFNEN = 'v1/wws/warenuebernahme-kontrolle/entities/{{idwu}}/oeffnen';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_ABSCHLIESSEN = 'v1/wws/warenuebernahme-kontrolle/entities/{{idwu}}/abschluss';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES = 'v1/wws/warenuebernahme-kontrolle/entities/{{idwu}}/bewegungen/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES_POSITION_ERLEDIGEN = 'v1/wws/warenuebernahme-kontrolle/bewegung/erledigt';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BEWES_POSITION_OEFFNEN = 'v1/wws/warenuebernahme-kontrolle/bewegung/oeffnen';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_EMBALLAGEN = 'v1/wws/warenuebernahme-kontrolle/emballagen/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_EMBALLAGEN_HINZUFUEGEN = 'v1/wws/warenuebernahme-kontrolle/warenuebernahme/entities/{{idwu}}/emballage';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_LIEFERANTEN = 'v1/wws/warenuebernahme-kontrolle/bestellung/lieferant/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG = 'v1/wws/warenuebernahme-kontrolle/bestellung/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG_BEWES = 'v1/wws/warenuebernahme-kontrolle/bestellung/entities/{{idbestellung}}/bewegungen/entities';
export const SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG_UEBERNEHMEN = 'v1/wws/warenuebernahme-kontrolle/bestellung/entities/uebernahme';
