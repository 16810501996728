export interface RemoteConfig {
  properties: RemoteConfigProperty[];
  modules: ModuleConfig[]
}

export interface ModuleConfig {
  module: string,
  properties: RemoteConfigProperty[]
}

export interface RemoteConfigProperty {
  prefix: string;
  key: string;
  value: string;
}
