import {Directive, ElementRef, forwardRef, Input,} from '@angular/core';
import {SelectOptionBase} from './select-option-base';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'soft-select-option',
  standalone: true,
  providers: [
    {
      provide: SelectOptionBase,
      useExisting: forwardRef(() => SelectOptionDirective),
    },
  ],
})
export class SelectOptionDirective extends SelectOptionBase {
  @Input() id?: string;
  @Input() value: any;
  @Input() disabled = false;

  constructor(public element: ElementRef) {
    super();
  }
}
