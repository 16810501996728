import { DefinitionBase } from "../data/definitions";
import { Dictionary } from "@softline/core";
import { signal, Signal } from "@angular/core";


export class DefinitionTransformer<T extends DefinitionBase> {
  constructor() { }

  transform(definition: T, value: Dictionary<unknown>): Signal<T> {
    return signal(definition);
  }
}
