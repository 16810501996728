import {
  createGetter,
  createMutation,
  mutate,
  ObjectStore,
  select,
  StoreFeature,
} from '@softline/core';

export interface State {
  active?: string;
}

export const mutations = {
  setActive: createMutation<State, string>('init'),
  unsetActive: createMutation<State>('destroy'),
};

export const actions = {};

export const getters = {
  active: createGetter<State, string>('get'),
};

export const feature: StoreFeature<State> = {
  initialState: {},
  mutations: [
    mutate(mutations.setActive, ({ state, params }) => ({
      ...state,
      active: params,
    })),
    mutate(mutations.unsetActive, ({ state }) => ({
      ...state,
      active: undefined,
    })),
  ],
  getters: [select(getters.active, ({ state }) => state.active)],
  actions: [],
};
