import { Component, Input } from '@angular/core';

@Component({
  selector: 'soft-dynamic-image-value',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() value?: any;

  constructor() {}
}
