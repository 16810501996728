<div class="flex flex-row soft-card shadow flex-nowrap cursor-pointer"
  (click)="goToApplication(application)"
>
  <i *ngIf="application?.icon !== ''; else defaultIcon"
    [class]="application?.icon"
    class="ml-3 fa-3x w-16"
  ></i>
  <div class="flex flex-col w-full ml-3">
    <span class="font-bold text-xl">{{ application?.name | translate }}</span>
    <p class="color-light" *ngIf="application?.description">
      {{ application?.description | translate }}
    </p>
  </div>
  <div class="flex flex-col justify-center items-center">
    <i class="fa-regular fa-chevron-right color-lighter"></i>
  </div>
</div>

<ng-template #defaultIcon>
  <i class="fa-regular fa-ban fa-3x"></i>
</ng-template>
