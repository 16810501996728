export type NfcReadResult = NFCReadSuccess | NFCReadError;

export interface NFCReadSuccess {
  type: 'read'
  serialNumber: string;
  records: unknown[];
  timestamp: string;
}

export interface NFCReadError {
  type: 'error'
  error: string;
  timestamp: string;
}
