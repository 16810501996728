import { inject, Injectable } from '@angular/core';
import { ListStore2 } from '../list.store2';


export interface ListResponseCommit<T> {
  set(values: T): void;
  clear(): void;
}

@Injectable()
export class DefaultListResponseCommit<T extends object> implements ListResponseCommit<T[]> {
  listStore = inject(ListStore2<T>);

  set(values: T[]): void {
    this.listStore.set(values)
  }

  clear(): void {
    this.listStore.clear()
  }
}

@Injectable()
export class ResultListResponseCommit<T extends object> implements ListResponseCommit<{ result: T[] }> {
  listStore = inject(ListStore2<T>);

  set(value: { result: T[] }): void {
    this.listStore.set(value.result)
  }

  clear(): void {
    this.listStore.clear()
  }
}
