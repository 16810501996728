export interface Language {
  code: string;
  name: string;
  flag?: string;
}

export const SOFTLINE_LANGUAGE_DE: Language = {
  code: "de",
  name: "Deutsch",
  flag: "<svg xmlns=\"http://www.w3.org/2000/svg\" id=\"flag-icons-at\" viewBox=\"0 0 640 480\">\n" +
    "  <g fill-rule=\"evenodd\">\n" +
    "    <path fill=\"#fff\" d=\"M640 480H0V0h640z\"/>\n" +
    "    <path fill=\"#c8102e\" d=\"M640 480H0V320h640zm0-319.9H0V.1h640z\"/>\n" +
    "  </g>\n" +
    "</svg>\n"
}

export const SOFTLINE_LANGUAGE_EN: Language = {
  code: "en",
  name: "English",
  flag: "<svg xmlns=\"http://www.w3.org/2000/svg\" id=\"flag-icons-gb\" viewBox=\"0 0 640 480\">\n" +
    "  <path fill=\"#012169\" d=\"M0 0h640v480H0z\"/>\n" +
    "  <path fill=\"#FFF\" d=\"m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z\"/>\n" +
    "  <path fill=\"#C8102E\" d=\"m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z\"/>\n" +
    "  <path fill=\"#FFF\" d=\"M241 0v480h160V0H241zM0 160v160h640V160H0z\"/>\n" +
    "  <path fill=\"#C8102E\" d=\"M0 193v96h640v-96H0zM273 0v480h96V0h-96z\"/>\n" +
    "</svg>\n"
}
