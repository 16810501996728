<ng-container [ngSwitch]="definition?.control">
  <soft-dynamic-table
    *ngSwitchCase="'table'"
    [definition]="definition?.definition"
    [rows]="values ?? []"
  ></soft-dynamic-table>
  <ng-container *ngSwitchCase="'chips'">
    <ng-container *ngFor="let value of values">
      <soft-chip>
        <soft-dynamic-object
          [definition]="definition?.definition"
          [value]="value"
        ></soft-dynamic-object>
      </soft-chip>
    </ng-container>
  </ng-container>
  <soft-dynamic-list
    *ngSwitchDefault
    [definition]="definition?.definition"
    [items]="values ?? []"
  ></soft-dynamic-list>
</ng-container>
