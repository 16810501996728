import { distinct } from './distinct.function';
import { isDefined } from './is-defined.function';
import { count } from './count.function';

export interface FindDuplicatesParams<T> {
  occurrences?: number;
  comparer?: (f: T, s: T) => boolean;
}
export function findDuplicates<T>(
  array: T[],
  params?: FindDuplicatesParams<T>
): T[] {
  const occurrences = params?.occurrences ?? 2;
  const result: T[] = [];
  const stack = distinct(array, { comparer: params?.comparer });

  while (stack.length > 0) {
    const current = stack.shift();
    if (!isDefined(current)) continue;

    if (count(array, current, { comparer: params?.comparer }) >= occurrences)
      result.push(current);
  }
  return result;
}
