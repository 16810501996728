import { Dictionary, Unpacked } from '@softline/core';
import { IMetaField, FieldOption, MetaObject, MetaField } from './meta';

export type MetaForm<T> = {
  [Property in keyof T]: MetaControl<T[Property]>;
}

export type MetaControl<T> = StringControl | NumberControl | BooleanControl | DateControl | SelectControl | ArrayControl<Unpacked<T>> | ObjectControl<T> | FieldOkControl<T> | FileControl | ImageControl;

export interface IMetaControl extends IMetaField {
  title?: string;
  default?: unknown;
  required?: boolean;
  disabled?: boolean;
  group?: string;
  icon?: string;
  priority?: number;
}

export interface StringControl extends IMetaControl {
  type: 'string';
  minLength?: number;
  maxLength?: number;
}

export interface NumberControl extends IMetaControl {
  type: 'number';
  minimum?: number;
  maximum?: number;
  format?: string;
}

export interface BooleanControl extends IMetaControl {
  type: 'boolean';
}

export interface DateControl extends IMetaControl {
  type: 'date';
  format?: string;
}

export interface SelectControl extends IMetaControl {
  type: 'select';
  options: FieldOption[];
}

export interface ArrayControl<T> extends IMetaControl {
  type: 'array';
  minLength?: number;
  maxLength?: number;
  of: T;
}

export interface ObjectControl<T> extends IMetaControl {
  type: 'object';
  object: MetaObject<T>;
  format?: string;
}

export interface FieldOkControl<T> extends IMetaControl {
  type: 'fieldOk';
  fieldOk: string;
  params?: Dictionary<unknown>;
  object: MetaObject<T>;
  format?: string;
}

export interface FileControl extends IMetaControl {
  type: 'file';
  accept: string;
}

export interface ImageControl extends IMetaControl {
  type: 'image';
  accept: string;
}
