import { inject, Injectable, OnDestroy, OnInit, signal } from "@angular/core";
import { QueryStore, RemoteCollectionStore, RemoteQueryStore, SOFTLINE_SERVICE_UUID, Store, SubscriptionStore } from "@softline/core";
import { handleRequestErrors } from "@softline/application";

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithQuery = <T extends Constructor<{}>>(featureName: string, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class QueryMixin extends Base implements OnDestroy {

    queryToken: string | undefined;

    store = inject(Store);
    uuid = inject(SOFTLINE_SERVICE_UUID);

    loading = signal(false);
    loaded = signal(false);

    queryValue = this.store.signal(
      featureName,
      RemoteQueryStore.getters.query.query
    );

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnDestroy(): Promise<void> {
      await this.cancelQuery();
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    async query(): Promise<void> {
      try {
        await this.cancelQuery();
        this.queryToken = this.uuid();
        this.store.commit(featureName, RemoteCollectionStore.mutations.clear);
        this.loading.set(true);
        this.loaded.set(false);
        await this.executeQuery();
        this.loaded.set(true);
      }
      catch (e) {
        handleRequestErrors(this.store, e);
      }
      finally {
        this.loading.set(false);
        this.queryToken = undefined;
      }
    }

    async executeQuery(): Promise<void> {
      await this.store.dispatch(featureName, RemoteQueryStore.actions.query, {
        query: this.queryValue(),
        clear: true,
        token: this.queryToken,
      });
    }

    async cancelQuery(): Promise<void> {
      if (!this.queryToken)
        return;
      await this.store.dispatch(
        featureName,
        SubscriptionStore.actions.cancel,
        this.queryToken
      );
      this.queryToken = undefined;
    }
  }
  return QueryMixin;
}
