import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@softline/core';
import * as ItemScanStore from './item-scan.store';
import { SOFTLINE_FEATURE_ITEM_SCAN } from './item-scan.shared';
import { ItemScanService } from './item-scan.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [],
})
export class ItemScanModule {
  static forRoot(): ModuleWithProviders<ItemScanRootModule> {
    return {
      ngModule: ItemScanRootModule,
      providers: [ItemScanService],
    };
  }
}

@NgModule({
  imports: [
    ItemScanModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ITEM_SCAN,
      feature: ItemScanStore.feature,
    }),
  ],
})
export class ItemScanRootModule {}
