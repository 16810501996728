import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@softline/core';
import { combineLatestWith, distinct, skip, Subscription } from "rxjs";
import { LabelType } from '../data/label-type';
import { Scanner } from '../data/scanner';
import {
  SOFTLINE_CONFIG_SCANNER,
  SOFTLINE_SETTINGS_SCANNER,
} from '../scanner.shared';
import * as SettingsStore from '../../settings/settings.store';
import * as ScannerStore from '../scanner.store';
import {
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_SETTINGS,
} from '../../application.shared';
import {
  SOFTLINE_FEATURE_MESSAGE_BAR,
  MessageBarStore,
} from '@softline/ui-core';
import { distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'soft-scanner-settings',
  templateUrl: './scanner-settings.component.html',
  styleUrls: ['./scanner-settings.component.scss'],
})
export class ScannerSettingsComponent implements OnInit, OnDestroy {
  private subscription?: Subscription;
  private scanSubscription?: Subscription;
  form = new UntypedFormGroup({
    scanner: new UntypedFormControl(undefined),
  });
  private scannerName: string | undefined = undefined;

  scanResult$ = this.store.observe(
    SOFTLINE_FEATURE_SCANNER,
    ScannerStore.getters.latest
  );

  constructor(
    private store: Store,
    @Optional() @Inject(SOFTLINE_CONFIG_SCANNER) public scanners: Scanner[]
  ) {}

  ngOnInit(): void {
    this.subscription = this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<{ scanner: string | undefined }>(),
        SOFTLINE_SETTINGS_SCANNER
      )
      .subscribe((settings) => {
        let scanner = this.scanners.find(o => o.name === settings?.scanner);
        if(!scanner) {
          scanner = this.scanners
            .sort((a, b) => a.priority < b.priority ? 1 : (a.priority > b.priority ? -1 : 0))
            .find(o => o.service.isAvailable)
        }
        this.scannerName = scanner?.name;
        this.form.patchValue({scanner: scanner?.name}, { emitEvent: false });
      });

    this.scanSubscription = this.store.observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(skip(1))
      .subscribe(async result => {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.info,
          {
            message: '#APPLICATION.SCANNER.MESSAGES.TEST_SUCCESS',
            params: result,
          }
        );
      })
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
    if (this.scanSubscription && !this.scanSubscription.closed)
      this.scanSubscription.unsubscribe();
    this.scanSubscription = undefined;
  }

  async set(): Promise<void> {
    const oldScanner = this.scanners.find(o => o.name === this.scannerName);
    if(oldScanner)
      await oldScanner.service.deactivate();
    this.store.commit(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.mutations.setKey(),
      { key: SOFTLINE_SETTINGS_SCANNER, values: this.form.value }
    );
    const newScanner = this.scanners.find(o => o.name === this.form.value.scanner);
    await newScanner?.service.activate();
    await this.store.dispatch(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.actions.save
    );
  }

  async tryScan(labelType: LabelType[]): Promise<void> {
    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_SCANNER,
        ScannerStore.actions.scan,
        { labelType }
      );
    } catch (e) {
      console.error(e);
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#APPLICATION.SCANNER.MESSAGES.TEST_ERROR'
      );
    }
  }
}
