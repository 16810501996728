import { Component, contentChild, effect, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponsiveMenuComponent } from '@softline/application';
import { PageHeaderComponent } from './page-header/page-header.component';

@Component({
  selector: 'soft-page',
  standalone: true,
  imports: [CommonModule, ResponsiveMenuComponent],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
})
export class PageComponent {
  header = contentChild(PageHeaderComponent, { descendants: true });
}
