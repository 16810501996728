import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import moment from 'moment';
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";

@Component({
  selector: 'lib-paginated-header',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './paginated-header.component.html',
  styleUrls: ['./paginated-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatedHeaderComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  private _currentYear = moment().get('year');

  @Input()
  get currentYear(): number { return this._currentYear; }
  set currentYear(value: number) {
    if (this._currentYear === value) { return; }
    this._currentYear = value;
    this.nextYear = this._currentYear + 1;
    this.previousYear = this._currentYear - 1;
  }

  @Input() length = 0;

  @Output() previous = new EventEmitter<void>();
  @Output() next = new EventEmitter<void>();

  nextYear: number = this.currentYear + 1;
  previousYear: number = this.currentYear - 1;

  ngOnInit(): void {}

  onNext(): void {
    this.next.emit();
  }

  onPrevious(): void {
    this.previous.emit();
  }
}
