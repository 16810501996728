import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
} from '../../../factories';
import { StoreFeature } from '../../../store';
import { SOFTLINE_STORE_DETAIL_SERVICE_FACTORY } from '../single/entity.shared';
import { Dictionary } from '../../../../types/dictionary';
import * as ActionStore from '../../remote/action.store';
import {
  ActionState,
  handleObservableActionState,
} from '../../remote/action.store';
import { handleSubscriptionState } from '../../remote/subscription.store';
import { SOFTLINE_SERVICE_UUID } from '../../../../core.shared';
import { lastValueFrom } from 'rxjs';
import * as DetailStore from './collection-detail.store';
import { isDefined } from '../../../../functions/is-defined.function';
import { Entity } from '../types/entity';

export interface LoadDetailActionParameters<
  T extends object,
  K extends keyof T & string = keyof T & string
> {
  id: string | number;
  name: K;
  pathParams?: Dictionary<unknown>;
  queryParams?: Dictionary<unknown>;
  token?: string;
}
export interface LoadManyDetailsActionParameters<
  T extends object,
  K extends keyof T & string = keyof T & string
> {
  ids: (string | number)[];
  name: K;
  keyMap?: (detail: T[K]) => string | number;
  pathParams?: Dictionary<unknown>;
  queryParams?: Dictionary<unknown>;
  token?: string;
}

export type AddOrUpdateParameters<
  T extends object,
  K extends keyof T = keyof T
> = { id: string | number; name: K; value: T[K] };

export interface State<T extends object = object>
  extends DetailStore.State<T> {}

export class Store<TEntity extends Entity, T extends object = object> {
  mutations = {
    ...this.detailStore.mutations,
    addOrUpdateManyDetails: createMutation<
      DetailStore.State<T>,
      AddOrUpdateParameters<T>[]
    >('addOrUpdateManyDetails'),
  };

  actions = {
    loadDetail: createAction<State<T>, LoadDetailActionParameters<T>, unknown>(
      'loadDetail'
    ),
    loadDetailOnce: createAction<
      State<T>,
      LoadDetailActionParameters<T>,
      unknown
    >('loadDetailOnce'),

    loadManyDetails: createAction<
      State<T>,
      LoadManyDetailsActionParameters<T>,
      unknown
    >('loadManyDetails'),
    loadManyDetailsOnce: createAction<
      State<T>,
      LoadManyDetailsActionParameters<T>,
      unknown
    >('loadManyDetailsOnce'),
  };

  getters = {
    ...this.detailStore.getters,
    loadingDetail: createGetter<State<T>, boolean>('loadingDetail'),
    loadedDetail: createGetter<State<T>, boolean>('loadedDetail'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.detailStore.feature.initialState,
    },
    mutations: [
      ...this.detailStore.feature.mutations,
      mutate(this.mutations.addOrUpdateManyDetails, ({ state, params }) => {
        let newState = { ...state };
        for (const param of params) {
          let detail = state.details[param.id];
          if (!detail) detail = {};
          detail = { ...detail, [param.name]: param.value };
          newState = {
            ...newState,
            details: { ...newState.details, [param.id]: detail },
          };
        }
        return newState;
      }),
    ],
    getters: [
      ...this.detailStore.feature.getters,
      select(this.getters.loadingDetail, ({ get, featureName, params }) => {
        const actions = [this.actions.loadDetail.name];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, ActionStore.getters.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.loadedDetail, ({ get, featureName, params }) => {
        const actions = [this.actions.loadDetail.name];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, ActionStore.getters.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
    actions: [
      on(
        this.actions.loadDetail,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const subscription$ = handleSubscriptionState(
            service.get(params.id, params.pathParams, params.queryParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.loadDetail.name,
              token
            )
          );
          commit(featureName, this.mutations.addOrUpdateDetail, {
            id: params.id,
            name: params.name,
            value: result,
          });
          return result;
        }
      ),

      on(
        this.actions.loadManyDetails,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const subscription$ = handleSubscriptionState(
            service.getMany(params.ids, params.pathParams, params.queryParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.loadDetail.name,
              token
            )
          );

          const keyMap = params.keyMap ?? ((o: any) => o.id);
          const commitValue = result.map((o: any) => ({
            id: keyMap(o),
            name: params.name as string,
            value: o,
          }));
          commit(
            featureName,
            this.mutations.addOrUpdateManyDetails,
            commitValue
          );
          return result;
        }
      ),

      on(
        this.actions.loadDetailOnce,
        async ({ commit, featureName, params, injector, state, dispatch }) => {
          const details = state.details[params.id];
          if (details && isDefined(details[params.name]))
            return details[params.name];

          return await dispatch(
            featureName,
            this.actions.loadDetail,
            params,
            injector
          );
        }
      ),
      on(
        this.actions.loadManyDetailsOnce,
        async ({ commit, featureName, params, injector, state, dispatch }) => {
          const ids = params.ids.filter(
            (id) =>
              !isDefined(state.details[id]) ||
              isDefined((state as any).details[id][params.name])
          );
          if (ids.length === 0) return undefined;

          return await dispatch(
            featureName,
            this.actions.loadManyDetails,
            params,
            injector
          );
        }
      ),
    ],
  };

  constructor(private detailStore: DetailStore.Store<TEntity, T>) {}
}

export function create<TEntity extends Entity, T extends object>(): Store<
  TEntity,
  T
> {
  const entityStore = DetailStore.create<TEntity, T>();
  return new Store<TEntity, T>(entityStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
