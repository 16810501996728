import { SortDirection } from '@softline/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform<T extends object, U>(
    array: readonly T[] | null | undefined,
    field: string,
    direction: 'ASC' | 'DESC' | null = SortDirection.Ascending,
    comparer?: (a: any, b: any) => number
  ): T[] {
    if (!array || !Array.isArray(array)) return [];
    const multiplier = direction === SortDirection.Ascending ? 1 : -1;

    const returnValue = [...array];
    returnValue.sort((a: T, b: T) => {
      if (!comparer) comparer = this.defaultComparer;
      return comparer(a[field], b[field]) * multiplier;
    });
    return returnValue;
  }

  private defaultComparer(a: any, b: any): number {
    if ((a ?? 0) < (b ?? 0)) return -1;
    else if ((a ?? 0) > (b ?? 0)) return 1;
    else return 0;
  }
}
