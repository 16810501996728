import { computed, effect, inject, Injectable, OnDestroy, Signal } from "@angular/core";
import { BottomTabConfig, BottomTabGroup, BottomTabGroupService } from "../program/services/bottom-tab-group.service";
import { toSignal } from "@angular/core/rxjs-interop";


type Constructor<T> = new(...args: any[]) => T;

export const WithBottomTabGroup = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class BottomTabGroupMixin extends Base implements OnDestroy {

    BOTTOM_TAB_GROUP_NAME = this.constructor.name;
    bottomTabGroupService = inject(BottomTabGroupService);

    activeTabs = toSignal(this.bottomTabGroupService.activeTabs$, {initialValue: {}});
    activeTab: Signal<string | null | undefined> = computed(() => {
      return this.activeTabs()[this.BOTTOM_TAB_GROUP_NAME];
    });

    abstract bottomTabs: Signal<BottomTabConfig[]>;

    effect = effect(() => {
      const tabs = this.bottomTabs();
      const tabGroup: BottomTabGroup = {
        name: this.BOTTOM_TAB_GROUP_NAME,
        tabs: tabs
      };
      this.bottomTabGroupService.addOrUpdate(tabGroup);
    }, {allowSignalWrites: true});

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnDestroy() {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();

      this.bottomTabGroupService.remove(this.BOTTOM_TAB_GROUP_NAME);
    }
  }
  return BottomTabGroupMixin;
}
