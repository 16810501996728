import {CalculationData, KzAusgabeStrategy} from "../kz-ausgabe.strategy";
import {Dictionary} from "@softline/core";

export const KzAusgabe2Strategy: KzAusgabeStrategy = {
  calculate(data: CalculationData): Dictionary<number | null> {

    const dict = data.dictionary
    const ausgabeEh2 = data.artehs.find(o => o.kzausgabe === '2')

    if (!ausgabeEh2 || !data.inputArteh?.kzausgabe || !data.inputValue) {
      return dict
    }

    if (data.inputArteh.kzausgabe === '2') {
      console.log('calculation strategy 2 in use....: ', dict);
      dict[data.inputArteh.id] = null;
      dict[ausgabeEh2.id] = Math.floor(data.inputValue);
    } else if (data.inputArteh.kzausgabe === 'N') {
      const erfMenge = data.inputValue / (data.baseArteh.faktor / data.inputArteh.faktor);
      const faktor = (ausgabeEh2.faktor / data.baseArteh.faktor);
      const ausgabeEh2Menge = Math.floor(erfMenge / faktor);
      dict[ausgabeEh2.id] = ausgabeEh2Menge
      dict[data.inputArteh.id] = (erfMenge - (ausgabeEh2Menge * ausgabeEh2.faktor)) / data.inputArteh.faktor;
    }

    return dict
  }
}
