import {Injectable} from '@angular/core';
import {ConnectionHttpService,} from '@softline/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SOFTLINE_API_ITEM} from '../artikel.api';
import { Artikel } from '../types/artikel';
import { ArtikelQuery } from '../types/artikel-query';


export interface LoadManyItemsResult {
  result: Artikel[];
  _totalResultCount: number;
}

@Injectable()
export class ArtikelService {

  constructor(
    protected connectionService: ConnectionHttpService
  ) { }

  load(id: number): Observable<Artikel> {
    return this.connectionService.get({path: `${SOFTLINE_API_ITEM}/{{id}}`, pathParams: {id}})
      .pipe(
        map(o => o as { result: Artikel }),
        map(o => o.result)
      );
  }

  loadMany(query: ArtikelQuery, limit: number, offset: number, sort?: string): Observable<LoadManyItemsResult> {
    return this.connectionService.get({
      path: SOFTLINE_API_ITEM,
      queryParams: {query, limit: limit, offset: offset, sort: sort}
    });
  }
}
