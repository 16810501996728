import { GetResourceService, ResourceService } from '../../data/abstraction';
import { StorageResourceLocation } from '../../data/specialized/storage/storage.location';
import { interpolate } from '../../functions/interpolate.function';
import { LoadObjectParameters, ReadonlyRepository } from '../abstraction';
import { Observable } from 'rxjs';

export class ReadonlyStorageRepository<TResult> implements ReadonlyRepository<TResult> {

  constructor(protected service: ResourceService<StorageResourceLocation>,
              protected key: string) { }

  load(params?: LoadObjectParameters): Observable<TResult> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    return this.service.get(location);
  }
}
