import {Routes} from "@angular/router";
import {AuthenticationGuard} from "@softline/auth";
import {loginRoutes} from "@softapps/allgemein/login";
import {connectionSettingsRoutes, settingsRoutes} from "@softapps/allgemein/utils";
import {passwordRoutes} from "@softapps/allgemein/password";
import {moduleRoutes} from "@softapps/allgemein/modules";
import {artikelInfoRoutes} from '@softapps/wws/artikel-info';
import { bestellvorschlagRoutes } from '@softapps/mde/bestellvorschlag';
import { bestellerfassungRoutes } from '@softapps/mde/bestellerfassung';
import { etikettendruckRoutes } from '@softapps/mde/etikettendruck';
import { lagerInventurRoutes } from '@softapps/mde/inventur';
import { retourenRoutes } from '@softapps/mde/retouren';
import { warenverprobungRoutes } from '@softapps/mde/warenverprobung';
import {stempelerfassungRoutes} from '@softapps/pms/stempelerfassung';
import {statusabfrageRoutes} from '@softapps/pms/statusabfrage';
import { artikelvorratRoutes } from '@softapps/mde/artikelvorrat';
import { materiallieferscheinScanRoutes } from '@softapps/mde/materiallieferschein-scan';
import { gesamtabfrageRoutes } from '@softapps/wws/gesamtabfrage';
import { warenuebernahmeRoutes } from '@softapps/wws/warenuebernahme';
import { checklisteRoutes } from '@softapps/allgemein/checkliste';
import { kundenabfrageRoutes } from '@softapps/wws/kundenabfrage';
import { umsatzstatistikRoutes } from '@softapps/wws/umsatzstatistik';
import { subkundenRoutes } from '@softapps/wws/subkunden';
import { dashboardRoutes } from '@softapps/allgemein/dashboard';
import { kreditlimitRoutes } from '@softapps/wws/kreditlimit';
import { offenePostenRoutes } from '@softapps/fibu/offene-posten';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('@softline/application').then((o) => o.ShellComponent),
    children: [
      // Allgemein
      ...moduleRoutes,
      ...settingsRoutes,

      // Libs
      ...subkundenRoutes,
      ...dashboardRoutes,
      ...kreditlimitRoutes,
      ...umsatzstatistikRoutes,
      ...offenePostenRoutes,

      ...artikelInfoRoutes,
      ...bestellvorschlagRoutes,
      ...bestellerfassungRoutes,
      ...etikettendruckRoutes,
      ...lagerInventurRoutes,
      ...retourenRoutes,
      ...warenverprobungRoutes,

      ...stempelerfassungRoutes,
      ...statusabfrageRoutes,
      ...artikelvorratRoutes,
      ...materiallieferscheinScanRoutes,
      ...gesamtabfrageRoutes,
      ...warenuebernahmeRoutes,
      ...checklisteRoutes,
      ...kundenabfrageRoutes,
    ],
    canActivate: [AuthenticationGuard],
  },
  {
    path: '',
    loadComponent: () => import('@softline/application').then((o) => o.PublicShellComponent),
    children: [
      ...loginRoutes,
      ...passwordRoutes,
      ...connectionSettingsRoutes,
      ...settingsRoutes
    ],
  },
];
