import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {Store} from '@softline/core';
import {
  BackNavigable,
  BackNavigationService,
  handleRequestErrors,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {Subscription} from 'rxjs';
import {MateriallieferscheinScanStore} from '../stores/materiallieferschein-scan.store';
import {
  SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN,
  SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE
} from '../materiallieferschein-scan.shared';
import {MateriallieferscheinScanBeweStore} from '../stores/materiallieferschein-scan-bewe.store';
import {Deactivateable} from '../guards/on-deactivate.guard';
import {ModalStore, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';
import {DeactivateComponent} from '../dialogs/deactivate/deactivate.component';

@Component({
  selector: 'soft-materiallieferschein-scan',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './materiallieferschein-scan.component.html',
  styleUrls: ['./materiallieferschein-scan.component.css'],
})
export class MateriallieferscheinScanComponent implements OnInit, OnDestroy, BackNavigable, Deactivateable {

  private subscription?: Subscription;
  private subscription2?: Subscription;

  constructor(private readonly store: Store,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly backNavigationService: BackNavigationService) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Material-/Lieferschein'
    );

    this.subscription = this.activatedRoute.data.subscribe( async ({ materiallieferscheinScan }) => {
      if (materiallieferscheinScan?.kopf) {
        this.store.commit(
          SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN,
          MateriallieferscheinScanStore.mutations.setKopf,
          materiallieferscheinScan.kopf
        );
      }

      if (!materiallieferscheinScan?.kopf) {
        await this.router.navigate(['/materiallieferschein-scan/einlesen']);
      }
    });

    this.subscription2 = this.store.observe(
      SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN,
      MateriallieferscheinScanStore.getters.kopf
    ).subscribe(async kopf => {
      if (!kopf)
        return;

      await this.store.dispatch(
        SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE,
        MateriallieferscheinScanBeweStore.actions.loadMany,
        { clear: true, pathParams: { idkopflf: kopf.id } }
      );
    });
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);

    this.subscription?.unsubscribe();
    this.subscription2?.unsubscribe();

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
  }

  async canDeactivate(): Promise<boolean> {
    const kopf = this.store.get(
      SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN,
      MateriallieferscheinScanStore.getters.kopf
    );

    if (!kopf || !kopf?.id)
      return true;

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<'LOESCHEN' | 'ABSCHLIESSEN', object>(),
      {
        component: DeactivateComponent,
        dismiss: true,
      }
    );

    if (result === 'DISMISSED')
      return false

    if (result === 'LOESCHEN') {
      try {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN,
          MateriallieferscheinScanStore.actions.removeBeleg
        );
      } catch (e) {
        handleRequestErrors(this.store, e)
      }
    } else if (result === 'ABSCHLIESSEN') {
      try {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN,
          MateriallieferscheinScanStore.actions.abschluss,
        );
      } catch (e) {
        handleRequestErrors(this.store, e)
      }
    }

    return true
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }
}
