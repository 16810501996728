import { Injectable } from '@angular/core';
import { equals } from '@softline/core';
import { CheckOption } from '../check-group';

@Injectable()
export class TrueOnlyCheckStrategy {
  getValues(options: CheckOption[]): any[] {
    return options.filter((o) => o.checked).map((o) => o.value);
  }

  setOptions(options: CheckOption[], values: any[] | null | undefined): void {
    if (!values || !options?.length) return;

    for (const option of options) {
      if (values.findIndex((o) => equals(o, option.trueValue)) > -1)
        option.writeValue(option.trueValue);
      else option.writeValue(option.falseValue);
    }
  }
}
