<div class="container" [class.minified]="isMinified">
  <div *ngIf="isMinified"
    class="w-full flex flex-col justify-center items-center action-menu-toggle box-content"
    (click)="open.emit()"
  >
    <i class="fa-regular fa-ellipsis-vertical actions-icon"></i>
    <span class="action-menu-toggle-name">{{
      '#SHELL.ACTIONS' | translate
    }}</span>
  </div>
  <div *ngIf="!isMinified" class="action-menu-header">
    <span class="font-semibold">{{ '#SHELL.ACTIONS' | translate }}</span>
    <span class="action-menu-close" (click)="close.emit()">
      <i class="fa-regular fa-xmark"></i>
    </span>
  </div>
  <div class="w-full h-full flex flex-col justify-between items-stretch vertical no-horizontal">
    <soft-menu-outlet name="action" #action></soft-menu-outlet>

    <ng-container *ngFor="let command of topCommands$ | async">
      <ng-container
        *ngIf="command.isVisible ? (command.isVisible | async) : true"
      >
        <div
          [id]="'framework-application-shell-actions-commands-bottom-' + command.name"
          class="c row menu-item no-wrap relative"
          softToolTip="{{ command.name | translate }}"
          toolTipPosition="left"
          [toolTipVisible]="isMinified"
          *ngVar="
            command.canExecute
              ? (command.canExecute | async)
              : true as canExecute
          "
          [routerLinkActive]="command.routerLink ? 'active' : ''"
          [routerLinkActiveOptions]="{ exact: true }"
          [class.disabled]="!canExecute"
          [ngClass]="command.class"
          (click)="
            onClick(
              command.execute,
              command.routerLink,
              canExecute,
              command.routerLinkParams
            )
          "
        >
          <span class="soft-badge" *ngIf="command?.badge | async as badge">{{ badge }}</span>
          <span
            *ngIf="command.icon"
            class="soft-container soft-container-center command-icon"
            [class.minified]="isMinified"
          >
            <soft-icon [name]="command.icon"></soft-icon>
          </span>
          <span class="ml-2" *ngIf="!isMinified">{{
            command.name | translate
          }}</span>
        </div>
      </ng-container>
    </ng-container>

    <div class="menu-container-bottom">
      <soft-menu-outlet name="action-bottom" #actionBottom></soft-menu-outlet>
      <ng-container *ngFor="let command of bottomCommands$ | async">
        <ng-container
          *ngIf="command.isVisible ? (command.isVisible | async) : true"
        >
          <div
            [id]="'framework-application-shell-actions-commands-bottom-' + command.name"
            class="c row menu-item no-wrap relative"
            softToolTip="{{ command.name | translate }}"
            toolTipPosition="left"
            [toolTipVisible]="isMinified"
            *ngVar="
              command.canExecute
                ? (command.canExecute | async)
                : true as canExecute
            "
            [routerLinkActive]="command.routerLink ? 'active' : ''"
            [routerLinkActiveOptions]="{ exact: true }"
            [class.disabled]="!canExecute"
            [ngClass]="command.class"
            (click)="
              onClick(
                command.execute,
                command.routerLink,
                canExecute,
                command.routerLinkParams
              )
            "
          >
            <span class="soft-badge" *ngIf="command?.badge | async as badge">{{ badge }}</span>
            <span
              *ngIf="command.icon"
              class="soft-container soft-container-center command-icon"
              [class.minified]="!isMinified"
            >
              <soft-icon [name]="command.icon"></soft-icon>
            </span>
            <span class="ml-2" *ngIf="!isMinified">{{
              command.name | translate
            }}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
