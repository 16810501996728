import { InjectionToken } from '@angular/core';
import { Position } from './position';

export type GeolocationLinkFactory = (position: Position) => string;

export const SOFTLINE_FEATURE_GEOLOCATION = 'geolocation';
export const SOFTLINE_SETTINGS_GEOLOCATION = 'geolocation';
export const SOFTLINE_SERVICE_GEOLOCATION_LINK_FACTORY =
  new InjectionToken<GeolocationLinkFactory>(
    'SOFTLINE_SERVICE_GEOLOCATION_LINK_FACTORY'
  );
