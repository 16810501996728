import { Injector, Pipe, PipeTransform, StaticProvider } from '@angular/core';

@Pipe({
  name: 'widgetOutletInjector',
  pure: true,
})
export class WidgetOutletInjectorPipe implements PipeTransform {
  transform(injector: Injector, ...args: StaticProvider[][]): Injector {
    const providers: StaticProvider[] = [];
    for (const arg of args) providers.push(...arg);
    return Injector.create({
      parent: injector,
      providers: providers,
    });
  }
}
