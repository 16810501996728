import { computed, Directive, effect, ElementRef, inject, input, Renderer2 } from '@angular/core';
import { MetaFormDirective } from './meta-form.directive';

@Directive({
  selector: '[softMetaControlName]',
  standalone: true
})
export class MetaControlNameDirective {

  private elRef = inject(ElementRef);
  private renderer = inject(Renderer2);

  metaDirective = inject(MetaFormDirective, {skipSelf: true});

  nameInput = input.required<string | null>({alias: 'softMetaControlName'});

  control = computed(() => {
    const metaDirective = this.metaDirective;
    const name = this.nameInput();
    if(!metaDirective)
      throw new Error('[MetaControlNameDirective] MetaDirective is not provided.');
    const meta = metaDirective.metaForm();
    if(!meta)
      throw new Error('[MetaControlNameDirective] MetaObject is not provided.');
    if(!name)
      return null;
    return meta[name];
  });

  format = computed(() => {
    const control = this.control();
    if(!control)
      return undefined;
    return control['format'];
  });

  controlEffect = effect(() => {
    const control = this.control();
    if(!control)
      return;
    if(control?.visible === false || !control)
      this.renderer.addClass(this.elRef.nativeElement, 'collapse')
    else
      this.renderer.removeClass(this.elRef.nativeElement, 'collapse')
  });

  metaObject = computed(() => {
    let meta = this.metaDirective.metaObject();
    const field = this.control();
    if(field?.type === 'object' && field?.object)
      meta = field.object;
    return meta;
  })

  constructor() { }
}
