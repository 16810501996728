<ng-container *ngIf="queryParams$ | async as queryParams">
  <div *ngIf="queryParams?.from || queryParams?.to" class="c row mt-4">
    <div class="c">
      <strong>{{ '#TIMESTAMP.TABLE.APPLIED_FILTERS' | translate }}</strong>
    </div>
    <soft-chip-group class="c row">
      <soft-chip *ngIf="queryParams?.from">
        <strong>{{ '#TIMESTAMP.TABLE.FROM' | translate }}</strong>
        {{ queryParams?.from | format: 'DATE.DEFAULT' }}
      </soft-chip>
      <soft-chip *ngIf="queryParams?.to" class="ml-2">
        <strong>{{ '#TIMESTAMP.TABLE.TO' | translate }}</strong>
        {{ queryParams?.to | format: 'DATE.DEFAULT' }}
      </soft-chip>
    </soft-chip-group>
  </div>
</ng-container>

<div class="grid mt-2">
  <div *ngFor="let group of dates$ | async | toArray" class="mt-2 card">
    <h5 class="font-bold stamp-day">{{ group?.key | date: 'dd.MM.yyyy' }}</h5>
    <ul>
      <li
        *ngFor="let timestamp of group | toArray | sortFunction: sortDates"
        class="h-stack spacer"
      >
        <div class="item">
          <div class="item-row">
            <span class="stamp-title">{{
              timestamp?.timestamp | date: 'HH:mm'
            }}</span>
            <span class="ml-4">{{
              (timestamp.flag === 'arrival'
                ? '#TIMESTAMP.TYPE.ARRIVAL'
                : '#TIMESTAMP.TYPE.DEPARTURE'
              ) | translate
            }}</span>
            <i
              *ngIf="timestamp.code"
              class="fa-regular fa-circle-info ml-2"
              [softToolTip]="codeTemplate"
              [toolTipPosition]="'right'"
            ></i>
            <ng-template #codeTemplate>
              {{ timestamp.code.name }}
              <ng-container *ngIf="timestamp.code.description"
                >&nbsp;-&nbsp;{{ timestamp.code.description }}</ng-container
              >
            </ng-template>
          </div>
        </div>
        <span
          *ngIf="timestamp.isDeletable"
          class="delete-timestamp"
          (click)="onDelete(timestamp)"
          ><i class="fa-regular fa-trash remove-icon"></i
        ></span>
      </li>
    </ul>
  </div>
</div>
