import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Store, isDefined, DateService } from "@softline/core";
import moment from 'moment';
import { Observable, throwError } from "rxjs";
import {
  SOFTLINE_CONFIG_JWT_REFRESH_TIME,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION,
} from '../jwt-authentication.shared';
import { JwtHelper } from '../utilities/jwt-helper';
import * as JwtAuthenticationStore from '../jwt-authentication.store';

const MILLISECONDS_MULTIPLIER = 1000;

@Injectable()
export class JwtAuthenticationInterceptor implements HttpInterceptor {
  private jwt?: string;
  private loading?: boolean;

  constructor(
    private store: Store,
    private dateService: DateService,
    @Inject(SOFTLINE_CONFIG_JWT_REFRESH_TIME) private refreshTime: number
  ) {
    store
      .observe(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.getters.token
      )
      .subscribe((token) => (this.jwt = token));

    store
      .observe(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.getters.loading
      )
      .subscribe((loading) => (this.loading = loading));
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.jwt)
      return next.handle(request);

    const now = moment(this.dateService.now());
    let expirationDate: string | null | undefined;
    if (isDefined(this.jwt))
      expirationDate = JwtHelper.getExpirationDate(this.jwt);

    if (expirationDate && this.isExpired(now, moment(expirationDate))) {
      this.store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.logout,
        { expired: true }
      );
      return throwError(() => new HttpErrorResponse({status: 401, statusText: '[JwtAuthenticationInterceptor]: Token expired'}));
    }
    if (
      !this.loading &&
      expirationDate &&
      this.shouldRefresh(now, moment(expirationDate))
    )
      this.store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.refresh
      );

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.jwt}`,
      },
    });
    return next.handle(request);
  }

  private shouldRefresh(
    now: moment.Moment,
    expirationDate: moment.Moment
  ): boolean {
    return expirationDate < now.add(this.refreshTime * MILLISECONDS_MULTIPLIER);
  }

  private isExpired(
    now: moment.Moment,
    expirationDate: moment.Moment
  ): boolean {
    return expirationDate < now;
  }
}
