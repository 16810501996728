import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNaN',
  standalone: true,
  pure: true
})
export class IsNaNPipe implements PipeTransform {
  transform(value: number | null | undefined): boolean {
    if(typeof value === 'number')
      return isNaN(value);
    return false;
  }
}
