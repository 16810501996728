<div class="flex flex-col bg-surface mt-3">
  <ng-container *ngIf="{ empfaenger: filteredEmpfaneger$ | async } as data; else skeletonTemplate">
    <ng-container *ngIf="(loading$ | async) === false && (data.empfaenger?.length || 0) < 1; else content">
      <div class="bg-surface flex flex-col select-none cursor-pointer py-2 border-b border-b-solid" *ngFor="let eintrag of suchverlauf$ | async">
        <span class="font-semibold text-base px-4">{{ eintrag.text }}</span>
        <span class="font-normal text-lighter text-sm px-4">{{ eintrag.date | format:'DATE.DEFAULT' }} {{ eintrag.date | format:'TIME.DEFAULT' }}</span>
      </div>
    </ng-container>
    <ng-template #content>
      <ng-container *ngIf="(loading$ | async) === false && (data?.empfaenger?.length || 0) > 0; else skeletonTemplate">
        <div class="bg-surface flex items-center select-none cursor-pointer nowrap justify-between px-4 py-2 border-b-solid border-b"
             [ngClass]="[empfaenger?.selected ? 'bg-primary-100' : 'bg-surface']"
             *ngFor="let empfaenger of data.empfaenger; trackBy: trackByFn; let last = last"
             (click)="selectOrUnselect(empfaenger)">
          <div class="flex flex-col max-w-[calc(100%_2rem)]" style="min-width: 0;">
            <h6 class="font-semibold text-base">{{ empfaenger?.vorname }} {{ empfaenger?.nachname }}</h6>
            <span class="font-normal text-sm" *ngIf="empfaenger?.kuerzel">{{ empfaenger?.kuerzel }}</span>
          </div>
          <div class="flex items-center justify-center" style="width: 2rem">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-5 h-5 text-primary" *ngIf="empfaenger?.selected">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>

</div>

<ng-template #header>
  <div class="flex pb-3 pt-6">
    <soft-input
      #input
      class="w-full h-10"
      placeholder="Empfänger suchen"
      [value]="input$ | async"
      (keydown.enter)="(input.value?.length ?? 0) > 2 && searchEmpfaenger(input?.value || null)"
      (valueChange)="input$.next($event ?? null)">
      <div *ngIf="(input.value?.length ?? 0) > 0"
           class="cursor-pointer"
           (click)="input.value = ''; onEmptyTextfield()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </soft-input>
    <button class="soft-button primary" [disabled]="((input?.value?.length || 0) < 2)" (click)="searchEmpfaenger(input?.value || null)">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
        <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clip-rule="evenodd" />
      </svg>
    </button>
  </div>
</ng-template>

<ng-template #skeletonTemplate>
  <div class="flex flex-col gap-2 w-full">
    <div class="flex flex-col w-full skeleton h-16 p-2" *softRepeat="10"></div>
  </div>
</ng-template>
