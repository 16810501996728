import { createAction, createGetter, createMutation, mutate, on, select, StoreFeature } from "@softline/core";
import { DeveloperService } from "./service/developer.service";
import { Inject } from "@angular/core";
import { SOFTLINE_CONFIG_DEVELOPER_PASSWORD } from "./developer.shared";

export interface DeveloperConfig {
  isDeveloper: boolean;
}


export interface State extends DeveloperConfig {
}

export namespace DeveloperStore {
  export const mutations = {
    set: createMutation<State, object>('set'),
  };

  export const actions = {
    load: createAction<State, object, DeveloperConfig>('load'),
    su: createAction<State, { password: string, remember: boolean }, DeveloperConfig>('su'),
    exit: createAction<State, undefined, DeveloperConfig>('exit'),
  };

  export const getters = {
    isDeveloper: createGetter<State, boolean>('isDeveloper'),
  };

  export const feature: StoreFeature<State> = {
    initialState: {
      isDeveloper: false
    },
    mutations: [
      mutate(mutations.set, ({ state, params }) => ({
        ...state,
        ...params
      })),
    ],
    actions: [
      on(actions.load, async ({ injector, featureName, commit }) => {
        const service = injector.get(DeveloperService);
        const settings = await service.load();
        commit(featureName, mutations.set, settings);
        return settings;
      }),
      on(actions.su, async ({ state, params, injector, commit, featureName }) => {
        const service = injector.get(DeveloperService);
        const developerPassword = injector.get(SOFTLINE_CONFIG_DEVELOPER_PASSWORD);
        if(params.password !== developerPassword)
          throw new Error('[DeveloperStore] Password is wrong')

        const newState = {isDeveloper: true}
        commit(featureName, mutations.set, newState)
        if(params.remember)
          await service.save(newState);
        return newState
      }),
      on(actions.exit, async ({ state, injector, commit, featureName }) => {
        commit(featureName, mutations.set, { isDeveloper: false })
        const service = injector.get(DeveloperService);
        return await service.delete();
      }),
    ],
    getters: [
      select(getters.isDeveloper, ({ state, params }) => state.isDeveloper),
    ],
  };

}
