import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indexOf',
  standalone: true,
  pure: true
})
export class IndexOfPipe<T> implements PipeTransform {
  transform(
    array: readonly T[] | null | undefined,
    value: T
  ): number {
    if (!array || array.length === 0)
      return -1;
    return array.indexOf(value);
  }
}
