import {
  ActionStore,
  createAction,
  createGetter,
  on,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore,
  SyncedRemoteCollectionStore
} from '@softline/core';
import {concat, lastValueFrom, tap, toArray} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemStockService, LoadStockParameters} from '../services/item-stock.service';
import {SOFTLINE_CONFIG_ARTIKEL_INFO_DETAIL_SEQUENCE_SIZE} from '../artikel-info.shared';
import {ItemStockAmount} from '../types/item-stock-amount';

export type ItemStockState = SyncedRemoteCollectionStore.State<ItemStockAmount>;
const collectionStore = SyncedRemoteCollectionStore.create<ItemStockAmount>();

export interface LoadManyStocksParallelParams {
  ids: number[];
  vkforgRestriction?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ItemStockStore {

  export const mutations = {
    ...collectionStore.mutations
  };

  export const actions = {
    ...collectionStore.actions,
    loadManySequential: createAction<ItemStockState, LoadManyStocksParallelParams, ItemStockAmount[]>('load many sequential'),
    loadItemStockAmount: createAction<ItemStockState, { id: number }, ItemStockAmount>('load item stock amount')
  };

  export const getters = {
    ...collectionStore.getters,
    total: createGetter<ItemStockState, number>('item stocks total'),
  };

  export const feature: StoreFeature<ItemStockState> = {
    initialState: {
      ...collectionStore.feature.initialState,
    },
    mutations: [
      ...collectionStore.feature.mutations
    ],
    actions: [
      ...collectionStore.feature.actions,
      on(actions.loadManySequential, async ({featureName, injector, commit, params}) => {
        const service = injector.get(ItemStockService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const sequenceSize = injector.get(SOFTLINE_CONFIG_ARTIKEL_INFO_DETAIL_SEQUENCE_SIZE);

        let requests: LoadStockParameters[] = [];

        while (params.ids.length > 0) {
          const size = Math.min(params.ids.length, sequenceSize);
          requests = [...requests, { ids: params.ids.splice(0, size), vkforgRestriction: params?.vkforgRestriction }];
        }

        const sub$ = concat(...requests.map(payload => service.load(payload))).pipe(
          tap(o => commit(featureName, mutations.addOrUpdateMany, o)),
          toArray(),
          map((responses) => responses.map(o => o).reduce((a, b) => ([...a, ...b]))),
        );

        return await lastValueFrom(ActionStore.handleObservableActionState(
          SubscriptionStore.handleSubscriptionState(sub$, featureName, commit, token),
          featureName,
          commit,
          actions.loadManySequential.name,
          token
        ));
      }),
      on(actions.loadItemStockAmount, async ({ injector, params, featureName, commit}) => {
        const service = injector.get(ItemStockService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const sub$ = service.loadStockAmount(params.id);
        const subState$ = SubscriptionStore.handleSubscriptionState(sub$, featureName, commit, token);
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subState$,
            featureName,
            commit,
            actions.loadItemStockAmount.name,
            token
          )
        );

        if (result) {
          commit(
            featureName,
            mutations.addOrUpdate,
            result
          );
        }

        console.log('result; ', result);

        return result
      })
    ],
    getters: [
      ...collectionStore.feature.getters,
    ]
  };
}
