import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import {
  ActivatedRoute, NavigationEnd,
  NavigationExtras,
  Router,
  RouterModule
} from "@angular/router";
import { Command } from '../../../data/command';
import {
  SOFTLINE_FEATURE_APP_INFO,
  SOFTLINE_FEATURE_COMMANDS,
} from '../../../application.shared';
import * as AppInfoStore from '../../../app/app-info.store';
import { asapScheduler, Observable, observeOn, Subscription } from "rxjs";
import * as CommandStore from '../../../program/command.store';
import { Store } from '@softline/core';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import { IsActiveRoutePipe } from '../../../pipes/is-active-route.pipe';
import { MenuOutletComponent } from '../../menu/components/menu-outlet/menu-outlet.component';
import { MenuSeparatorComponent } from '../../menu/components/menu-separator/menu-separator.component';

@Component({
  standalone: true,
  selector: 'soft-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule, RouterModule, IsActiveRoutePipe, MenuOutletComponent, MenuSeparatorComponent]
})
export class SideMenuComponent implements OnInit, OnDestroy {

  private subscription?: Subscription;

  version$ = this.store.observe(
    SOFTLINE_FEATURE_APP_INFO,
    AppInfoStore.getters.version
  );

  // Hotfix: observeOn(asapScheduler) ist hier notwending da aus unerklärlichen umständen
  // zeitweise in dieser Komponente (und nur in dieser) die Commands nicht
  // im View Aktualisiert werden (trotz store commmits)
  topCommands$: Observable<readonly Command[]> = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'main-menu-top'
    )
    .pipe(observeOn(asapScheduler));
  bottomCommands$: Observable<readonly Command[]> = this.store
    .observe(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.getters.commands,
      'main-menu-bottom'
    )
    .pipe(observeOn(asapScheduler));

  @Input() isMinified = false;

  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() execute = new EventEmitter();

  constructor(
    private store: Store,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Zur Aktualisierung des Aktiven Menüeintrags beim Navigieren
    this.subscription = this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd)
        this.cdRef.markForCheck()
    })
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  onClick(
    execute: (() => void) | undefined,
    routerLink: any[] | string | undefined,
    canExecute: boolean,
    routerLinkParams: NavigationExtras | undefined
  ): void {
    this.execute.emit();
    if (!canExecute) return;
    if (execute) execute();
    else if (routerLink)
      this.router.navigate(
        typeof routerLink === 'string' ? [routerLink] : routerLink,
        routerLinkParams
      );
  }

  closeMenu(): void {
    this.close.emit();
  }
}
