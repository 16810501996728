import {
  FieldOkConfig,
  FieldOkListViewComponent, FieldOkMasterDetailViewComponent, FieldOkMultiselectListViewComponent,
  FieldOkQueryViewComponent,
  FieldOkTableViewComponent, QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { KtostammKundeListView } from "./list-view/ktostamm-kunde.list-view";
import { KtostammKundeQueryView } from "./query-view/ktostamm-kunde.query-view";
import { KtostammKundeMasterView } from "./master-detail-view/master-view/ktostamm-kunde.master-view";
import { KtostammKundeDetailView } from "./master-detail-view/detail-view/ktostamm-kunde.detail-view";
import { FilterConfig, SortConfig } from "@softline/application";

const defaultSort= {property: 'nummer', direction: 'asc'}
const sort: SortConfig = {
    properties: [
      {property: 'nummer', title: 'Nummer'},
      {property: 'subnummer', title: 'Subnummer'},
      {property: '10', title: 'Nachname'},
      {property: '11', title: 'Vorname'},
      {property: '14', title: 'PLZ'},
      {property: '15', title: 'Ort'},
      {property: '13', title: 'Strasse'},
      {property: '49', title: 'Hausnummer'},
    ]
  };
const filter: FilterConfig = {
  properties: [
    {property: 'nummer', title: 'Nummer'},
    {property: 'subnummer', title: 'Subnummer'},
    {property: '10', title: 'Nachname'},
    {property: '11', title: 'Vorname'},
    {property: '14', title: 'PLZ'},
    {property: '15', title: 'Ort'},
    {property: '13', title: 'Strasse'},
    {property: '49', title: 'Hausnummer'},

  ]
};

export const ktostammKundeFieldOk: FieldOkConfig = {
  name: 'ktostamm.kunde',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: "{{nummer}} - {{kurzbez}}",
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: KtostammKundeQueryView,
    },
  }, {
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: KtostammKundeListView,
      sort,
      filter,
      defaultSort,
    }
  }, {
    name: 'masterDetail',
    component: FieldOkMasterDetailViewComponent,
    title: 'Detail',
    config: {
      masterComponent: KtostammKundeMasterView,
      detailComponent: KtostammKundeDetailView,
      sort,
      filter,
      defaultSort,
    }
  },{
    name: 'table',
    component: FieldOkTableViewComponent,
    title: 'Tabelle',
    config: {
      definition: {
        type: 'object',
        name: '',
        definitions: [{
          type: 'number',
          name: 'nummer',
          title: 'Nummer'
        },{
          type: 'string',
          name: '10',
          title: 'Name1'
        },{
          type: 'string',
          name: '11',
          title: 'Name2'
        },{
          type: 'string',
          name: '12',
          title: 'Name3'
        },{
          type: 'string',
          name: '13',
          title: 'Strasse'
        },{
          type: 'string',
          name: '49',
          title: 'Hausnummer'
        },{
          type: 'string',
          name: '14',
          title: 'PLZ'
        },{
          type: 'string',
          name: '15',
          title: 'Ort'
        }]
      },
      sort,
      filter,
      defaultSort,
    }
  }]
};
export const ktostammKundeMultiselectFieldOk: FieldOkConfig = {
  name: 'ktostamm.kunde',
  type: 'multi',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: "{{nummer}} - {{kurzbez}}",
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: KtostammKundeQueryView,
    },
  }, {
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl (mehrfach)',
    config: {
      component: KtostammKundeListView,
      sort,
      filter,
      defaultSort,
    },
  }]
};
export const ktostammKundeQueryFieldOk: FieldOkConfig = {
  name: 'ktostamm.kunde',
  type: 'query',
  priority: 0,
  defaultView: 'query',
  defaultDataView: 'list',
  template: "{{nummer}} - {{kurzbez}}",
  views: [{
    name: 'query',
    component: FieldOkQueryViewComponent,
    title: 'Abfrage',
    config: {
      component: KtostammKundeQueryView,
    },
  }, {
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl (mehrfach)',
    config: {
      component: KtostammKundeListView,
      sort,
      filter,
      defaultSort,
    },
  }]
};
