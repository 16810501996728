import {ENVIRONMENT_INITIALIZER, importProvidersFrom, inject} from '@angular/core';
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityDetailService,
  SoftlineEntityService,
  Store,
  StoreModule
} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore,} from '@softline/ui-core';
import {default as de} from '../i18n/de.json';
import {ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS,} from '@softline/application';
import {
  SOFTLINE_FEATURE_MDE_ARTIKELVORRAT,
  SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_ARTIKEL_DETAILS,
  SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE, SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_OFFENE_KOEPFE,
} from "./artikelvorrat.shared";
import {ArtikelvorratStore} from './store/artikelvorrat.store';
import {
  SOFTLINE_API_MDE_ARTIKELVORRAT_ARTIKEL,
  SOFTLINE_API_MDE_ARTIKELVORRAT_BEWEGUNGEN,
  SOFTLINE_API_MDE_ARTIKELVORRAT_KOPF, SOFTLINE_API_MDE_ARTIKELVORRAT_KOPF_ENTITES,
} from './artikelvorrat.api';
import {ArtikelvorratBeweStore} from './store/artikelvorrat-bewe.store';
import {ArtikelvorratService} from './services/artikelvorrat.service';
import {ArtikelvorratArtikelDetailStore} from './store/artikelvorrat-artikel-detail.store';
import {ArtikelvorratResolver} from './resolver/artikelvorrat.resolver';
import {ArtikelvorratOffeneKoepfeStore} from './store/artikelvorrat-offene-koepfe.store';

export const artikelvorratProviders = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: async () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'mdeArtikelvorrat',
          language: 'de',
          translations: de,
        }
      );

      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: 'Artikelvorrat',
          icon: 'fa-regular fa-boxes-stacked',
          route: '/mde-artikelvorrat',
          permission: 'ARTIKELVORRAT',
        }
      );

      store.commit(
        SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE,
        ArtikelvorratBeweStore.mutations.setSort,
        {
          property: 'id',
          title: 'Erfassungszeitpunkt',
          direction: 'desc'
        } as any,
      );
    },
    multi: true,
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_ARTIKELVORRAT,
      feature: ArtikelvorratStore.feature,
      providers: [{
        provide: ArtikelvorratService,
        useClass: ArtikelvorratService
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_BEWE,
      feature: ArtikelvorratBeweStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_MDE_ARTIKELVORRAT_BEWEGUNGEN
        )
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_ARTIKEL_DETAILS,
      feature: ArtikelvorratArtikelDetailStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: () => {
            return new SoftlineEntityDetailService(
              inject(SOFTLINE_SERVICE_HTTP),
              SOFTLINE_API_MDE_ARTIKELVORRAT_ARTIKEL,
              'detail'
            );
          }
        },
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MDE_ARTIKELVORRAT_OFFENE_KOEPFE,
      feature: ArtikelvorratOffeneKoepfeStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_ENTITY_SERVICE,
        useFactory: () => new SoftlineEntityService(
          inject(SOFTLINE_SERVICE_HTTP),
          SOFTLINE_API_MDE_ARTIKELVORRAT_KOPF_ENTITES
        )
      }]
    }),
  ),
  ArtikelvorratResolver,
]
