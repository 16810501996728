import {Injectable} from '@angular/core';
import {FavoriteList} from '../types/favorite-list';
import {Store} from '@softline/core';
import {MessageBarStore, ModalStore, SOFTLINE_FEATURE_MESSAGE_BAR, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';
import {AddFavoriteListDialogComponent} from '../dialogs/add-favorite-list-dialog/add-favorite-list-dialog.component';
import {SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS} from '../artikel-info.shared';
import {FavoriteListsStore} from '../store/favorite-lists.store';
import {handleRequestErrors} from '@softline/application';
import {Item} from '../types/item';
import {Favorite} from '../types/favorite';
import {AddFavoriteItemDialogComponent} from '../dialogs/add-favorite-item-dialog/add-favorite-item-dialog.component';

const FAVORITES_MESSAGE_GROUP = 'ARTIKEL_INFO_FAVORITES'

@Injectable()
export class FavoriteService {

  constructor(private store: Store) {}

  async removeList(list: FavoriteList): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      {
        title: `Liste löschen`,
        question: `Sind Sie sicher, dass die Liste "${list.name}" unwiderruflich gelöscht werden soll?`,
      }
    );

    if (!result || result === 'DISMISSED' || result !== 'YES')
      return;

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS,
        FavoriteListsStore.actions.delete,
        { entity: list }
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: 'Löschen erflogreich',
          message: `Die Liste ${list.name} wurde gelöscht!`,
          group: FAVORITES_MESSAGE_GROUP
        }
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  async addItemToList(item: Item): Promise<{ favorite: Favorite, list: FavoriteList } | null | undefined> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<{ favorite: Favorite, list: FavoriteList } | null | undefined, object>(),
      {
        component: AddFavoriteItemDialogComponent,
        dismiss: true,
        data: {
          item
        }
      }
    );

    if (!result || result === 'DISMISSED')
      return undefined;

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title: 'Artikel zur Liste hinzugefügt',
        message: `Der Artikel "${item.name ?? item?.title1}" wurde erfolgreich zur Liste "${result.list.name}" hinzugefügt`,
        group: FAVORITES_MESSAGE_GROUP,
      }
    );

    return result;
  }

  async createList(): Promise<FavoriteList | null | undefined> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<FavoriteList | null | undefined, object>(),
      {
        component: AddFavoriteListDialogComponent,
        dismiss: true,
        priority: 999,
      }
    );

    if (!result || result === 'DISMISSED')
      return undefined;

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title: 'Liste erstellt',
        message: `Die Favoriten-Liste ${result.name} wurde erfolgreich angelegt.`,
        group: FAVORITES_MESSAGE_GROUP,
      }
    );

    return result;
  }
}
