<div class="flex flex-col items-center">
  <div *ngIf="filters.length === 0" class="text-light p-4">
    {{'#APPLICATION.FILTER_AND_SORT.FILTER.NO_FILTERS' | translate}}
  </div>
  <ng-container *ngFor="let item of filters; let last = last; let index = index" >
    <soft-filter-item class="w-full"
                      [filter]="item"
                      [config]="config"
                      [values]="values"
                      (filterChange)="onFilterChange($event, index)"
                      (delete)="onRemove($event)"></soft-filter-item>
    <ng-container *ngIf="!last">
      <div class="relative block h-3">
        <div class="border-l-4 border-primary border-solid h-3 absolute left-[50%] top-0 ml-[-2px]"></div>
      </div>
      <div class="flex flex-row bg-primary text-primary-contrast px-2 rounded-full text-sm">UND</div>
      <div class="relative block h-3">
        <div class="border-l-4 border-primary border-solid h-3 absolute left-[50%] top-0 ml-[-2px]"></div>
      </div>
    </ng-container>
  </ng-container>
  <div *ngIf="filters.length > 0" class="relative block h-3">
    <div class="border-l-4 border-primary border-solid h-3 absolute left-[50%] top-0 ml-[-2px]"></div>
  </div>
  <div class="grid grid-cols-3 w-full">
    <div class="flex flex-col col-start-2 items-center">
      <div class="rounded-full bg-primary hover:bg-primary-700 text-primary-contrast p-3 cursor-pointer"
           (click)="onAddFilter()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </div>
      <div class="text-sm text-light">{{'#APPLICATION.FILTER_AND_SORT.FILTER.ADD' | translate}}</div>
    </div>
    <div *ngIf="filters.length > 0" class="flex flex-row col-start-3 text-danger hover:underline mr-4 mt-1 justify-end flex-wrap cursor-pointer"
         (click)="onRemoveAll()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
      </svg>
      <div class="text-sm ml-1 text-end">{{'#APPLICATION.FILTER_AND_SORT.FILTER.REMOVE_ALL' | translate}}</div>
    </div>
  </div>
</div>
