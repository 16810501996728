import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  ProgressStore,
  select,
  StoreFeature,
  SubscriptionStore,
  SOFTLINE_SERVICE_UUID,
} from '@softline/core';
import { lastValueFrom } from 'rxjs';
import { Archive, ArchiveFile, ArchiveUpload } from './data/archive';
import { ArchiveService } from './services/archive.service';
import { state } from '@angular/animations';

export interface State
  extends ProgressStore.State,
    ActionStore.State,
    SubscriptionStore.State {
  files: ArchiveFile[];
  downloads: { filename: string; content: Blob }[];
}

export const mutations = {
  progress: ProgressStore.mutations,
  action: ActionStore.mutations,
  subscription: SubscriptionStore.mutations,

  addFiles: createMutation<State, ArchiveFile[]>('addFiles'),
  addDownload: createMutation<State, { filename: string; content: Blob }>(
    'download'
  ),
  clear: createMutation<State>('clear'),
};

export const actions = {
  ...SubscriptionStore.actions,

  read: createAction<State, Archive & { token?: string }, ArchiveFile[]>(
    'read'
  ),
  download: createAction<
    State,
    { file: ArchiveFile; token?: string },
    { filename: string; content: Blob }
  >('download'),
  downloadOnce: createAction<
    State,
    { file: ArchiveFile; token?: string },
    { filename: string; content: Blob }
  >('downloadOnce'),
  upload: createAction<State, ArchiveUpload & { token?: string }, ArchiveFile>(
    'upload'
  ),
};

export const getters = {
  progress: ProgressStore.getters,
  action: ActionStore.getters,
  subscription: SubscriptionStore.getters,

  archive: createGetter<State, ArchiveFile[], string>('archive'),
  file: createGetter<State, ArchiveFile, string>('file'),

  downloading: createGetter<State, boolean, string | undefined>('downloading'),
  downloaded: createGetter<State, boolean, string | undefined>('downloaded'),

  uploading: createGetter<State, boolean, string | undefined>('uploading'),
  uploaded: createGetter<State, boolean, string | undefined>('uploaded'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    ...ProgressStore.feature.initialState,
    ...ActionStore.feature.initialState,
    ...SubscriptionStore.feature.initialState,
    files: [],
    downloads: [],
  },
  mutations: [
    ...ProgressStore.feature.mutations,
    ...ActionStore.feature.mutations,
    ...SubscriptionStore.feature.mutations,
    mutate(mutations.addFiles, ({ state, params }) => {
      const files = [...state.files];
      for (const file of params) {
        const index = files.findIndex((o) => o?.fileKey === file?.fileKey);
        if (index > -1) files.splice(index, 1, file);
        else files.push(file);
      }
      return { ...state, files };
    }),
    mutate(mutations.addDownload, ({ state, params }) => ({
      ...state,
      downloads: [...state.downloads, params],
    })),
    mutate(mutations.clear, ({ state }) => {
      return { ...state, files: [] };
    }),
  ],
  actions: [
    ...SubscriptionStore.feature.actions,
    on(actions.read, async ({ commit, featureName, params, injector }) => {
      const service = injector.get(ArchiveService);
      const observable$ = service.read(params);
      const token = params?.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
      const subscription$ = SubscriptionStore.handleSubscriptionState(
        observable$,
        featureName,
        commit,
        token
      );
      const result = await lastValueFrom(
        ActionStore.handleObservableActionState(
          subscription$,
          featureName,
          commit,
          actions.read.name,
          token
        )
      );
      commit(featureName, mutations.addFiles, result);
      return result;
    }),
    on(actions.download, async ({ commit, featureName, params, injector }) => {
      const service = injector.get(ArchiveService);
      const filename = params.file.metaData.name;

      const token = params?.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
      const progress$ = service.download(params.file);
      const result$ = ProgressStore.handleProgressState(
        progress$,
        featureName,
        commit,
        token
      );
      const subscription$ = SubscriptionStore.handleSubscriptionState(
        result$,
        featureName,
        commit,
        token
      );
      const result = await lastValueFrom(
        ActionStore.handleObservableActionState(
          subscription$,
          featureName,
          commit,
          actions.download.name,
          token
        )
      );
      const download = { filename, content: result };
      commit(featureName, mutations.addDownload, download);
      return download;
    }),
    on(
      actions.downloadOnce,
      async ({ commit, featureName, params, state, dispatch, injector }) => {
        const filename = params.file.metaData.name;
        const existing = state.downloads.find((o) => o.filename === filename);
        if (existing) return existing;
        return await dispatch(featureName, actions.download, params);
      }
    ),
    on(actions.upload, async ({ commit, featureName, params, injector }) => {
      const service = injector.get(ArchiveService);
      const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

      const progress$ = service.upload(params);
      const result$ = ProgressStore.handleProgressState(
        progress$,
        featureName,
        commit,
        token
      );
      const subscription$ = SubscriptionStore.handleSubscriptionState(
        result$,
        featureName,
        commit,
        token
      );
      const result = await lastValueFrom(
        ActionStore.handleObservableActionState(
          subscription$,
          featureName,
          commit,
          actions.download.name,
          token
        )
      );
      commit(featureName, mutations.addFiles, [(result as any).result]);
      return result;
    }),
  ],
  getters: [
    ...ProgressStore.feature.getters,
    ...ActionStore.feature.getters,
    ...SubscriptionStore.feature.getters,
    select(getters.archive, ({ state, params }) =>
      state.files.filter((o) => o.archiveKey === params)
    ),
    select(getters.file, ({ state, params }) =>
      state.files.find((o) => o.fileKey === params)
    ),

    select(getters.downloading, ({ get, featureName, params }) => {
      const action = [actions.download.name];
      const states: ActionStore.ActionState[] = ['pending', 'processing'];
      return get(featureName, getters.action.hasState, {
        actions: action,
        states,
        id: params,
      });
    }),
    select(getters.downloaded, ({ get, featureName, params }) => {
      const action = [actions.download.name];
      const states: ActionStore.ActionState[] = ['succeeded', 'failed'];
      return get(featureName, getters.action.hasState, {
        actions: action,
        states,
        id: params,
      });
    }),

    select(getters.uploading, ({ get, featureName, params }) => {
      const action = [actions.upload.name];
      const states: ActionStore.ActionState[] = ['pending', 'processing'];
      return get(featureName, getters.action.hasState, {
        actions: action,
        states,
        id: params,
      });
    }),
    select(getters.uploaded, ({ get, featureName, params }) => {
      const action = [actions.upload.name];
      const states: ActionStore.ActionState[] = ['succeeded', 'failed'];
      return get(featureName, getters.action.hasState, {
        actions: action,
        states,
        id: params,
      });
    }),
  ],
};
