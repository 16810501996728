import { appConfig } from './app/app.config';

import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { environment } from "./environments/environment";
import { enableProdMode } from "@angular/core";

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeDe, 'de');

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
