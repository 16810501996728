import { Component, Input, OnInit } from '@angular/core';
import { GroupDefinition } from '../../../../data/definitions';
import { CommonModule } from "@angular/common";
import { DynamicPipesModule } from "../../../../pipes/dynamic-pipes.module";
import { UiCoreModule } from "@softline/ui-core";
import { DynamicValueComponent } from '../../../dynamic-value/dynamic-value.component';

@Component({
  selector: 'soft-dynamic-group-value',
  standalone: true,
  imports: [CommonModule, UiCoreModule, DynamicPipesModule, DynamicValueComponent],
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit {
  @Input() definition?: GroupDefinition;
  @Input() value?: any;
  @Input() loading = false;
  @Input() displayHeader = true;

  constructor() {}

  ngOnInit(): void {}
}
