import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'takeLast',
})
export class TakeLastPipe<T> implements PipeTransform {
  transform(value: readonly T[] | null | undefined, count: number): T[] {
    if (!value) return [];
    const skipUntil = value.length - count;
    return value.filter((o, i) => i >= skipUntil);
  }
}
