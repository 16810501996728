import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { ZbewegungsartListView } from "./list-view/zbewegungsart.list-view";

export const zbewegungsartFieldOk: FieldOkConfig = {
  name: 'zbewegungsart',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{zbewegungsart}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ZbewegungsartListView
    }
  }
  ]
};
export const zbewegungsartMultiselectFieldOk: FieldOkConfig = {
  name: 'zbewegungsart',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{zbewegungsart}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: ZbewegungsartListView
    }
  }
  ]
};

export const zbewegungsartQueryFieldOk: FieldOkConfig = {
  name: 'zbewegungsart',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{zbewegungsart}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ZbewegungsartListView
    }
  }
  ]
};
