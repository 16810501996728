import { createAction, createGetter, on, select } from '../../../factories';
import { StoreFeature } from '../../../store';
import { SOFTLINE_STORE_DETAIL_SERVICE_FACTORY } from '../single/entity.shared';
import { Dictionary } from '../../../../types/dictionary';
import * as ActionStore from '../../remote/action.store';
import {
  ActionState,
  handleObservableActionState,
} from '../../remote/action.store';
import { handleSubscriptionState } from '../../remote/subscription.store';
import { SOFTLINE_SERVICE_UUID } from '../../../../core.shared';
import { lastValueFrom } from 'rxjs';
import * as DetailStore from './entity-detail.store';
import { Patch } from '../types/patch';
import { Entity } from '../types/entity';

export interface CreateDetailActionParameters<
  T extends object,
  K extends keyof T & string = keyof T & string
> {
  id: string | number;
  name: K;
  detail: T[K];
  pathParams?: Dictionary<unknown>;
  token?: string;
}
export interface UpdateDetailActionParameters<
  T extends object,
  K extends keyof T & string = keyof T & string
> {
  id: string | number;
  name: K;
  detail: T[K];
  pathParams?: Dictionary<unknown>;
  token?: string;
}
export interface PatchDetailActionParameters<
  T extends object,
  K extends keyof T & string = keyof T & string
> {
  id: string | number;
  name: K;
  patch: Patch<T[K]>;
  pathParams?: Dictionary<unknown>;
  token?: string;
}
export interface DeleteDetailActionParameters<
  T extends object,
  K extends keyof T & string = keyof T & string
> {
  id: string | number;
  name: K;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface State<T extends object = object>
  extends DetailStore.State<T> {}

export class Store<TEntity extends Entity, T extends object = object> {
  mutations = {
    ...this.detailStore.mutations,
  };

  actions = {
    createDetail: createAction<
      State<T>,
      CreateDetailActionParameters<T>,
      unknown
    >('createDetail'),
    updateDetail: createAction<
      State<T>,
      UpdateDetailActionParameters<T>,
      unknown
    >('updateDetail'),
    patchDetail: createAction<
      State<T>,
      PatchDetailActionParameters<T>,
      unknown
    >('patchDetail'),
    deleteDetail: createAction<
      State<T>,
      DeleteDetailActionParameters<T>,
      unknown
    >('deleteDetail'),
  };

  getters = {
    ...this.detailStore.getters,
    savingDetail: createGetter<State<T>, boolean>('savingDetail'),
    savedDetail: createGetter<State<T>, boolean>('savedDetail'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.detailStore.feature.initialState,
    },
    mutations: [...this.detailStore.feature.mutations],
    getters: [
      ...this.detailStore.feature.getters,
      select(this.getters.savingDetail, ({ get, featureName, params }) => {
        const actions = [
          this.actions.createDetail.name,
          this.actions.updateDetail.name,
          this.actions.patchDetail.name,
          this.actions.deleteDetail.name,
        ];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, ActionStore.getters.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.savedDetail, ({ get, featureName, params }) => {
        const actions = [
          this.actions.createDetail.name,
          this.actions.updateDetail.name,
          this.actions.patchDetail.name,
          this.actions.deleteDetail.name,
        ];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, ActionStore.getters.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
    actions: [
      on(
        this.actions.createDetail,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const subscription$ = handleSubscriptionState(
            service.create(params.id, params.detail, params.pathParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.createDetail.name,
              token
            )
          );
          commit(featureName, this.mutations.setDetail, {
            name: params.name,
            value: result,
          });
          return result;
        }
      ),

      on(
        this.actions.updateDetail,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const subscription$ = handleSubscriptionState(
            service.update(params.id, params.detail, params.pathParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.updateDetail.name,
              token
            )
          );
          commit(featureName, this.mutations.setDetail, {
            name: params.name,
            value: result,
          });
          return result;
        }
      ),

      on(
        this.actions.patchDetail,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const subscription$ = handleSubscriptionState(
            service.patch(params.patch, params.pathParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.createDetail.name,
              token
            )
          );
          commit(featureName, this.mutations.setDetail, {
            name: params.name,
            value: result,
          });
          return result;
        }
      ),

      on(
        this.actions.deleteDetail,
        async ({ commit, featureName, params, injector }) => {
          const factory = injector.get(SOFTLINE_STORE_DETAIL_SERVICE_FACTORY);
          const service = factory(params.name, injector);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const subscription$ = handleSubscriptionState(
            service.delete(params.id, params.pathParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.createDetail.name,
              token
            )
          );
          commit(featureName, this.mutations.setDetail, {
            name: params.name,
            value: result,
          });
          return result;
        }
      ),
    ],
  };

  constructor(private detailStore: DetailStore.Store<TEntity, T>) {}
}

export function create<TEntity extends Entity, T extends object>(): Store<
  TEntity,
  T
> {
  const entityStore = DetailStore.create<TEntity, T>();
  return new Store<TEntity, T>(entityStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
