<h4>
  {{
    (options.selectionMode !== 'multi'
      ? '#APPLICATION.UPLOAD.FILE.TITLE'
      : '#APPLICATION.UPLOAD.FILE.TITLE_PL'
    ) | translate
  }}
</h4>
<div class="c row center">
  <soft-file-input
    [selectionMode]="options.selectionMode ?? 'single'"
    [sources]="options?.sources ?? 'all'"
    [progress]="(uploadState$ | async) ?? []"
    #images
  ></soft-file-input>
</div>
<div *ngIf="images.value.length > 0" class="c row buttons no-wrap mt-4">
  <button class="soft-button secondary" (close)="close(false)">
    {{ '#APPLICATION.UPLOAD.CANCEL' | translate }}
  </button>
  <button class="soft-button primary" (click)="onUpload(images.value)" [disabled]="uploading">
    {{ '#APPLICATION.UPLOAD.SUBMIT' | translate }}
  </button>
</div>
