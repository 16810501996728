<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item['dekade']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item['bisdatum'] |format:'DATE.DEFAULT'}}</span>
  </div>
  <div class="flex flex-row">
    <span class="text-light">Einzug am: {{item['faelldtmitglied']}}</span>
  </div>
</div>
