<div class="soft-top-container">
  <header class="flex flex-row items-center flex-nowrap gap-4">
    <div class="w-full">
      <ng-container
        *ngTemplateOutlet="header()?.template() ?? null"
      ></ng-container>
    </div>
    <soft-responsive-menu></soft-responsive-menu>
  </header>
</div>
<main class="mt-4">
  <ng-content></ng-content>
</main>
