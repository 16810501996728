import { Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { RemoteObjectStore, Store } from '@softline/core';
import { SOFTLINE_FEATURE_MASTER_DATA } from '../application.shared';

@Injectable({
  providedIn: 'root',
})
export class MasterDataResolver  {
  constructor(private store: Store) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<object> {
    return await this.store.dispatch(
      SOFTLINE_FEATURE_MASTER_DATA,
      RemoteObjectStore.actions.load
    );
  }
}
