import {
  RemoteCollectionStore,
  StoreBuilder,
  SyncedRemoteCollectionStore,
  QueryStore,
  DefaultStore,
  ValidationStore,
} from '@softline/core';
import { Timestamp } from './data/timestamp.model';
import { TimestampCode } from './data/timestamp-code.model';
import { Employee } from './data/employee.model';
import { TimestampQuery } from './data/timestamp.query';

export const SOFTLINE_FEATURE_TIMESTAMP_EMPLOYEE = 'timestampEmployee';
export const SOFTLINE_FEATURE_TIMESTAMP_CURRENT_EMPLOYEE =
  'timestampCurrentEmployee';
export const SOFTLINE_FEATURE_TIMESTAMP_CODE = 'timestampCode';
export const SOFTLINE_FEATURE_TIMESTAMP = 'timestamp';

export const TimestampStore = new StoreBuilder(
  SyncedRemoteCollectionStore.create<Timestamp>()
)
  .add(QueryStore.create<TimestampQuery>())
  .add(DefaultStore.create<Timestamp>())
  .add(ValidationStore).value;
export const TimestampCodeStore = RemoteCollectionStore.create<TimestampCode>();
export const TimestampEmployeeStore = RemoteCollectionStore.create<Employee>();
