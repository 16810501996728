import { inject, Injectable } from '@angular/core';
import { ObjectStore2 } from '../object.store2';


export interface ObjectResponseCommit<T> {
  set(value: T): void;
  clear(): void;
}

@Injectable()
export class DefaultObjectResponseCommit<T extends object> implements ObjectResponseCommit<T> {
  objectStore = inject(ObjectStore2<T>);

  set(value: T): void {
    this.objectStore.set(value)
  }

  clear(): void {
    this.objectStore.clear()
  }
}

@Injectable()
export class ResultObjectResponseCommit<T extends object> implements ObjectResponseCommit<{ result: T }> {
  objectStore = inject(ObjectStore2<T>);

  set(value: { result: T }): void {
    this.objectStore.set(value.result)
  }

  clear(): void {
    this.objectStore.clear()
  }
}

export class MultiObjectResponseCommit<T extends object> implements ObjectResponseCommit<T> {
  private strategies: ObjectResponseCommit<T>[];

  constructor(...strategies: ObjectResponseCommit<T>[]) {
    this.strategies = strategies;
  }

  set(value: T): void {
    for(const strategy of this.strategies)
      strategy.set(value)
  }

  clear(): void {
    for(const strategy of this.strategies)
      strategy.clear()
  }
}
