import { ResourceService } from '../../../data/abstraction';
import { Observable } from 'rxjs';
import { RequestEvent } from '../../../data/request';
import { Dictionary } from '../../../types/dictionary';

export class ObjectService<TLocation> {
  constructor(
    protected service: ResourceService<TLocation>,
    protected location: TLocation
  ) {}

  get<T>(payload?: unknown): Observable<T> {
    return this.service.get(this.location, payload);
  }

  create<T, TResponse>(resource: T): Observable<TResponse> {
    return this.service.create<T, TResponse>(this.location, resource);
  }

  update<T, TResponse>(resource: T): Observable<TResponse> {
    return this.service.update<T, TResponse>(this.location, resource);
  }

  patch<T, TResponse>(changes: Partial<T>): Observable<TResponse> {
    return this.service.patch<T, TResponse>(this.location, changes);
  }

  delete<TResponse, TPayload>(payload?: TPayload): Observable<TResponse> {
    return this.service.delete<TResponse, TPayload>(this.location, payload);
  }

  upload<T, TResponse>(resource: T): Observable<RequestEvent<T>> {
    return this.service.upload(this.location, resource);
  }
}

export abstract class RemoteStoreObjectService<TLocation> {
  constructor(protected service: ResourceService<TLocation>) {}

  abstract get<T>(
    payload?: unknown,
    pathParams?: Dictionary<unknown>,
    queryParams?: Dictionary<unknown>
  ): Observable<T>;

  abstract create<T, TResponse>(
    resource: T,
    pathParams?: Dictionary<unknown>
  ): Observable<TResponse>;

  abstract update<T, TResponse>(
    resource: T,
    pathParams?: Dictionary<unknown>
  ): Observable<TResponse>;

  abstract patch<T, TResponse>(
    changes: Partial<T>,
    pathParams?: Dictionary<unknown>
  ): Observable<TResponse>;

  abstract delete<TResponse, TPayload>(
    payload?: TPayload,
    pathParams?: Dictionary<unknown>
  ): Observable<TResponse>;

  abstract upload<T, TResponse>(
    resource: T,
    pathParams?: Dictionary<unknown>
  ): Observable<RequestEvent<T>>;
}
