<ng-container *ngIf="{ empfaenger: (empfaenger$ | async), loading: (loading$ | async) } as data">
  <div class="flex flex-col gap-1 mt-4">
    <label class="soft-label soft-label-top required">Empfänger</label>
    <div class="flex gap-3 shrink-0 flex-wrap items-center" *ngIf="data.empfaenger as empfaengerList">
      <soft-chip
        *ngFor="let empfaenger of empfaengerList | slice:0:4"
        [canDelete]="true"
        (delete)="onDelete(empfaenger?.id ?? 0)">
        {{ empfaenger?.name }}
      </soft-chip>
      <soft-chip *ngIf="(empfaengerList?.length || 0) > 4">
        <a class="underline italic hover:text-primary cursor-pointer" (click)="openEmpfaengerDialog(empfaengerList)">{{ (empfaengerList | slice:4).length }} {{ (empfaengerList | slice:4).length === 1 ? 'weiterer' : 'weitere' }} Empfänger</a>
      </soft-chip>
    </div>
  </div>

  <form class="flex flex-col gap-4 w-full mt-4 flex-grow"
        [formGroup]="form">
    <div class="flex flex-col gap-0.5 w-full">
      <label for="betreff" class="soft-label soft-label-top required">Betreff</label>
      <soft-input id="betreff"
                  formControlName="betreff"
                  placeholder="Betreff/Thema/Grund"
                  class="w-full"
                  softFieldValidation></soft-input>
    </div>

    <div class="flex flex-col gap-0.5 w-full">
      <label for="nachricht" class="soft-label soft-label-top required">Nachricht</label>
      <soft-multiline-input
        id="nachricht"
        class="w-full"
        formControlName="inhalt"
        placeholder="Nachricht eingeben"
        [lines]="10"
        softFieldValidation>
      </soft-multiline-input>
    </div>
  </form>
</ng-container>
