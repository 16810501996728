import {
  Action,
  ActionFunc,
  ActionReg,
  Getter,
  GetterFunc,
  GetterReg,
  Mutation,
  MutationFunc,
  MutationReg,
} from './abstraction';

export function createMutation<T, P = undefined>(name: string): Mutation<T, P> {
  return { type: 'mutation', name };
}

export function mutate<T, P>(
  mutation: Mutation<T, P>,
  func: MutationFunc<T, P>
): MutationReg<T, P> {
  return { mutation, func };
}

export function createGetter<T, R, P = undefined>(
  name: string
): Getter<T, P, R> {
  return { type: 'getter', name };
}

export function select<T, P, R>(
  getter: Getter<T, P, R>,
  func: GetterFunc<T, P, R>,
  options?: { dependingFeatures?: string[] }
): GetterReg<T, P, R> {
  return { getter, func, options };
}

export function createAction<T, P = undefined, R = void>(
  name: string
): Action<T, P, R> {
  return { type: 'action', name };
}
export function on<T, P, R>(
  action: Action<T, P, R>,
  func: ActionFunc<T, P, R>
): ActionReg<T, P, R> {
  return { action, func };
}
