<span class="hover:underline cursor-pointer"
      [ngClass]="{'italic !text-light soft-label required': !$any(filter).value}"
      (click)="onValueClick()">
  {{$any(filter).value ? ($any(filter).value | format:'DATE.DEFAULT') : ('#APPLICATION.FILTER_AND_SORT.FILTER.DATE.PLACEHOLDER' | translate)  }}
</span>

<ng-template let-close="close" #dialogRef>
  <div class="flex flex-col" [formGroup]="form">
    <label class="soft-label top required">{{'#APPLICATION.FILTER_AND_SORT.FILTER.DATE.LABEL' | translate}}</label>
    <soft-date-input formControlName="value"
                     changeTrigger="input"
                     softFocus>
    </soft-date-input>
    <soft-validation-container
      *ngIf="form.controls['value'].touched"
      [validation]="form | validationMessage: 'value'"
      class="soft-property-validation"
    ></soft-validation-container>
    <button [disabled]="form.invalid"
            class="soft-button primary h-16 rounded mt-4"
            (click)="close(form.value)">{{'#APPLICATION.FILTER_AND_SORT.FILTER.DATE.SUBMIT' | translate}}</button>
  </div>
</ng-template>
