import { FilterConfigProperty } from "@softline/application";

export interface SortConfig {
  properties: SortConfigProperty[];
}

export interface SortConfigProperty<TProperty = unknown, TParams = unknown> {
  property: string;
  title: string;
  params?: TParams;
  comparer?: (f: TProperty, s: TProperty) => number
}
