import { computed, inject, Injectable, OnDestroy, OnInit } from "@angular/core";
import {
  CollectionStore, Entity, Filter,
  FilterCollectionStore,
  FilterService, Sort,
  SortCollectionStore,
  SortService,
  Store
} from "@softline/core";

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithFilter = <T extends Constructor<{}>>(featureName: string, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class FilterMixin extends Base {

    store = inject(Store);
    filterService = inject(FilterService);


    filter = this.store.signal(
      featureName,
      FilterCollectionStore.getters.filter
    )
    all= this.store.signal(
      featureName,
      CollectionStore.getters.all
    );

    constructor(...args: any[]) {
      super(...args);
    }

    async setFilter(filter: Filter | null): Promise<void> {
      this.store.commit(featureName,
        FilterCollectionStore.mutations.setFilter,
        filter
      )
    }
  }
  return FilterMixin;
}
