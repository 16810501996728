<ng-container *ngIf="isDeveloper$ | async; else loginForm">
  <div class="border border-solid rounded w-full p-8">
    {{'#APPLICATION.DEVELOPER.SETTINGS.LOGOUT_DESC' | translate}}
  </div>
  <div class="flex flex-row justify-end mt-2">
    <button class="soft-button primary" (click)="onExit()">{{'#APPLICATION.DEVELOPER.SETTINGS.LOGOUT' | translate}}
    </button>
  </div>
</ng-container>
<ng-template #loginForm>
  <form class="flex flex-col"
        [formGroup]="form"
        (ngSubmit)="onSubmit()">
    <label class="soft-label top" for="framework-application-admin-settings-password">
      {{'#APPLICATION.DEVELOPER.SETTINGS.PASSWORD' | translate}}
    </label>
    <soft-password-input
      formControlName="password"
      id="framework-application-admin-settings-password"
      #password>
    </soft-password-input>
    <div class="flex flex-row justify-between mt-4">
      <div class="flex flex-row justify-end items-center">
        <soft-checkbox
          formControlName="remember"
          id="framework-application-admin-settings-remember"
          #remember
        >
          <label class="soft-label right" for="framework-application-admin-settings-remember">
            {{'#APPLICATION.DEVELOPER.SETTINGS.REMEMBER' | translate}}
          </label>
        </soft-checkbox>
      </div>
      <button type="submit"
              class="soft-button primary"
              [disabled]="!password.value?.length">{{'#APPLICATION.DEVELOPER.SETTINGS.SUBMIT' | translate}}
      </button>
    </div>
  </form>
</ng-template>
