import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {MateriallieferscheinScanKopf} from "../types/materiallieferschein-scan-kopf";
import {Injectable} from "@angular/core";
import {filter, map, Observable, switchMap} from "rxjs";
import {RemoteConfigStore, SOFTLINE_FEATURE_REMOTE_CONFIG} from "@softline/application";
import {Store} from "@softline/core";
import {SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN} from '../materiallieferschein-scan.shared';
import {MateriallieferscheinScanStore} from '../stores/materiallieferschein-scan.store';

export const MODULE_NAME = 'mde-materiallieferschein-scan';

type MateriallieferscheinScanData = { kopf?: MateriallieferscheinScanKopf | null; }

@Injectable()
export class MateriallieferscheinScanResolver  {

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MateriallieferscheinScanData> {
    return this.store.observe(
      SOFTLINE_FEATURE_REMOTE_CONFIG,
      RemoteConfigStore.getters.data,
    ).pipe(
      filter(o => !!o && !!o?.modules),
      map(data => data?.modules?.find(o => o.module === MODULE_NAME)),
      switchMap(() => this.store.observe(SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN, MateriallieferscheinScanStore.getters.kopf)),
      map((kopf) => { return { kopf } })
    )
  }
}
