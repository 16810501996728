import {ArtikelEinheit} from './artikel-einheit';

export interface ArtikelLagerMenge {
  id: number;
  itemId: number;
  stockAmount: StockLocationInfo[];
  amount: number;
  orders: number;
  reserved: number;
}

// Info/Amount per location (Filiale)
export interface StockLocationInfo {
  stock: {
    id: number;
    name?: string;
    title: string;
  }
  itemUnit?: ArtikelEinheit;
  amount: number;
  orders: number;
  reserved: number;
}
