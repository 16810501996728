import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  StaticProvider,
} from '@angular/core';
import { Widget } from './widget';
import {
  SOFTLINE_CONFIG_WIDGETS,
  SOFTLINE_DATA_CONTEXT,
} from '../../ui-core.tokens';
import {
  AuthorizationStore,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from '@softline/auth';
import { Store } from '@softline/core';

@Component({
  selector: 'soft-widget-outlet',
  templateUrl: './widget-outlet.component.html',
  styleUrls: ['./widget-outlet.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetOutletComponent implements OnInit, OnChanges {
  @Input() name = '';
  @Input() context?: unknown;

  componentInjector: Injector;
  contextProviders: StaticProvider[] = [];

  permissions$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.permissions
  );

  constructor(
    private store: Store,
    public injector: Injector,
    @Optional() @Inject(SOFTLINE_CONFIG_WIDGETS) public widgets: Widget[],
    public cdRef: ChangeDetectorRef
  ) {
    this.componentInjector = injector;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['context']?.previousValue !== changes['context']?.currentValue) {
      this.contextProviders = [
        { provide: SOFTLINE_DATA_CONTEXT, useValue: this.context },
      ];
      this.componentInjector = Injector.create({
        providers: [{ provide: SOFTLINE_DATA_CONTEXT, useValue: this.context }],
        parent: this.injector,
      });
    }
  }

  outletFilter(widget: Widget, name?: string): boolean {
    return widget?.outlet === name;
  }

  permissionFilter(widget: Widget, permissions?: readonly string[] | null) {
    for (const permission of widget.permissions ?? []) {
      if (permissions?.indexOf(permission) === -1) return false;
    }
    return true;
  }

  createWidgetInjector(widget: Widget): Injector {
    return Injector.create({
      providers: [
        { provide: SOFTLINE_DATA_CONTEXT, useValue: this.context },
        ...(widget.providers ?? []),
      ],
      parent: this.injector,
    });
  }
}
