<soft-message-bar-container name="testConnection"></soft-message-bar-container>
<div class="px-2 sm:px-0">
  <h1 class="text-xl font-semibold mt-3">
    {{ '#CONNECTION_SETTINGS.DIALOGS.' + mode | uppercase | translate }}
  </h1>
  <form [formGroup]="form">
    <div class="c column mt-3">
      <label class="soft-label top required">
        {{ '#CONNECTION_SETTINGS.HOST' | translate }}
      </label>
      <soft-input type="url" formControlName="host"></soft-input>
      <soft-validation-container
        *ngIf="form.controls['host'].touched"
        [validation]="form | validationMessage: 'host'"
        class="soft-property-validation"
      ></soft-validation-container>
    </div>
    <div class="c column mt-3">
      <label class="soft-label top">
        {{ '#CONNECTION_SETTINGS.PORT' | translate }}
      </label>
      <soft-number-input formControlName="port"></soft-number-input>
      <soft-validation-container
        *ngIf="form.controls['port'].touched"
        [validation]="form | validationMessage: 'port'"
        class="soft-property-validation"
      ></soft-validation-container>
    </div>
    <div class="c column mt-3">
      <label class="soft-label top">
        {{ '#CONNECTION_SETTINGS.BASE_PATH' | translate }}
      </label>
      <soft-input type="url" formControlName="basePath"></soft-input>
      <soft-validation-container
        *ngIf="form.controls['basePath'].touched"
        [validation]="form | validationMessage: 'basePath'"
        class="soft-property-validation"
      ></soft-validation-container>
    </div>
    <div class="c column mt-3">
      <span>
        <label class="soft-label top">
          {{ '#CONNECTION_SETTINGS.NAME' | translate }}
        </label>
        <span class="color-light text-xs">
          {{ '#CONNECTION_SETTINGS.NAME_OPTIONAL' | translate }}
        </span>
      </span>
      <soft-input formControlName="name"></soft-input>
      <soft-validation-container
        *ngIf="form.controls['name'].touched"
        [validation]="form | validationMessage: 'name'"
        class="soft-property-validation"
      ></soft-validation-container>
    </div>
  </form>
</div>
<div class="flex">
  <!--<button class="soft-button secondary w-full h-8 mt-3">
    {{'#CONNECTION_SETTINGS.TEST' | translate}}
  </button>-->
  <button
    class="soft-button primary w-full h-8 mt-3"
    [disabled]="form.invalid"
    (click)="close(form.value)"
    type="submit"
  >
    {{ '#CONNECTION_SETTINGS.SAVE' | translate }}
  </button>
</div>
