import {ObjectStore, RemoteCollectionStore, SyncedRemoteObjectStore,} from '@softline/core';
import {InjectionToken} from '@angular/core';
import {Account} from './context/account-context/data/account';

export const SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT =
  'SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT'; //new InjectionToken<Type<Component>>('SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT');

export interface AccountContextConfiguration {
  widgetLinkColor: string;
}

export const SOFTLINE_CONFIG_CONTEXT_ACCOUNT = new InjectionToken<AccountContextConfiguration>(
  'SOFTLINE_CONFIG_CONTEXT_ACCOUNT'
);

export const SOFTLINE_FEATURE_UTILITIES_ACCOUNT = 'utilitiesAccount';
export const SOFTLINE_FEATURE_SELECTED_ACCOUNT = 'utilitiesSelectedAccount';
export const SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT = 'utilitiesPatchContext';
export const SOFTLINE_FEATURE_UTILITIES_VKFORGS = 'utilitiesVkforgs';
export const SOFTLINE_FEATURE_UTILITIES_FIRMA = 'utilitiesFirma';

export const UtilitiesAccountStore = RemoteCollectionStore.create<Account>();
export const UtilitiesSelectedAccountStore = ObjectStore.create<Account>();

export const UserContactStore = SyncedRemoteObjectStore.create<object>();
export const SOFTLINE_FEATURE_USER_CONTACT = 'userContact';
export const SOFTLINE_SETTINGS_USER_CONTACT = 'userContact';
export const SOFTLINE_DEFINITION_USER_CONTACT = 'userContact';

export const SOFTLINE_CONFIG_STORED_CONTEXT_FIELD = new InjectionToken<string[]>('SOFTLINE_CONFIG_STORED_CONTEXT_FIELD');
