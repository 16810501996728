const htmlEntities: [string, string][] = [
  ['&amp;', '&'],
  ['&nbsp;', ' '],
  ['&lt;', '<'],
  ['&gt;', '>'],
  ['&quot;', '"'],
  ['&apos;', "'"],
  ['&cent;', '¢'],
  ['&pound;', '£'],
  ['&yen;', '¥'],
  ['&euro;', '€'],
  ['&copy;', '©'],
  ['&reg;', '®'],
];

export function serializeHtmlCharacterEntitiesFunction(value: string): string {
  for (const [entity, unicode] of htmlEntities)
    value = value.replace(new RegExp(escapeRegExp(unicode), 'g'), entity);
  return value;
}

export function deserializeHtmlCharacterEntitiesFunction(
  value: string
): string {
  for (const [entity, unicode] of htmlEntities)
    value = value.replace(new RegExp(escapeRegExp(entity), 'g'), unicode);
  return value;
}
function escapeRegExp(string: string): string {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
