import * as ParamsStore from "./params.store"
import * as KeyStore from "./key.store"
import * as SubscriptionStore from "./subscription.store"
import * as ActionStore from "./action.store"

export const mutations = {
  params: ParamsStore.mutations,
  keys: KeyStore.mutations,
  subscription: SubscriptionStore.mutations,
  action: ActionStore.mutations
}

export const getters = {
  params: ParamsStore.getters,
  keys: KeyStore.getters,
  subscription: SubscriptionStore.getters,
  action: ActionStore.getters
}

export const actions = {
  keys: KeyStore.actions,
  subscription: SubscriptionStore.actions,
}

export const feature = {
  initialState: {
    ...ParamsStore.feature.initialState,
    ...ActionStore.feature.initialState,
    ...SubscriptionStore.feature.initialState,
    ...KeyStore.feature.initialState,
  },
  getters: [
    ...ParamsStore.feature.getters,
    ...ActionStore.feature.getters,
    ...SubscriptionStore.feature.getters,
    ...KeyStore.feature.getters,
  ],
  mutations: [
    ...ParamsStore.feature.mutations,
    ...ActionStore.feature.mutations,
    ...SubscriptionStore.feature.mutations,
    ...KeyStore.feature.mutations,
  ],
  actions: [
    ...ParamsStore.feature.actions,
    ...ActionStore.feature.actions,
    ...SubscriptionStore.feature.actions,
    ...KeyStore.feature.actions,
  ]
}
