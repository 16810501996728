import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { Scanner, SOFTLINE_CONFIG_SCANNER, SOFTLINE_CONFIG_SETTINGS } from "@softline/application";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore, UiCoreModule } from "@softline/ui-core";
import { Html5ScannerService } from "./services/html5-scanner.service";
import { Html5ScannerSettings } from "./types/html5-scanner.settings";
import { SOFTLINE_CONST_QRBOX_DIMENSION_250 } from "./types/qr-box-dimensions";
import { Html5ScannerSettingsComponent } from "./settings/html5-scanner-settings.component";
import { Store } from "@softline/core";
import { default as deLang } from '../../../i18n/scanner/de.json';
import { SOFTLINE_SETTINGS_HTML5_SCANNER } from "./html5-scanner.shared";

export interface Html5ScannerModuleOptions {
  defaultSettings: Html5ScannerSettings;
}

export const defaultOptions: Html5ScannerModuleOptions = {
  defaultSettings: {
    fps: 100,
    qrBox: SOFTLINE_CONST_QRBOX_DIMENSION_250,
    source: null,
    aspectRatio: 1,
    focusMode: 'continuous',
    advanced: [{zoom: 1}]
  },
};
export function html5ScannerFactory(service: Html5ScannerService): Scanner {
  return {
    name: 'html5',
    priority: 6,
    title: 'Camera (HTML5)',
    service,
    settingsComponent: Html5ScannerSettingsComponent
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class Html5ScannerModule {
  static forRoot(options: Html5ScannerModuleOptions = defaultOptions): ModuleWithProviders<Html5ScannerRootModule> {
    return {
      ngModule: Html5ScannerRootModule,
      providers: [
        Html5ScannerService,
        {
          provide: SOFTLINE_CONFIG_SCANNER,
          multi: true,
          useFactory: html5ScannerFactory,
          deps: [Html5ScannerService],
        },
        {
          provide: SOFTLINE_CONFIG_SETTINGS,
          multi: true,
          useValue: {
            key: SOFTLINE_SETTINGS_HTML5_SCANNER,
            default: options.defaultSettings,
          },
        },
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule, UiCoreModule],
  exports: [],
})
export class Html5ScannerRootModule {
  constructor(store: Store) {
    store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
      module: 'html5Scanner',
      language: 'de',
      translations: deLang,
    });
  }
}
