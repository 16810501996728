import { inject, Injectable, InjectionToken, Injector, OnDestroy, OnInit } from '@angular/core';
import { handleRequestErrors, WithLoadRepositoryCollection } from '@softline/application';
import {
  CancelledError,
  CollectionStore2,
  NestedStore2Feature,
  Patch, ReadonlyCollectionRepository,
  RepositoryCollectionStore2,
  SOFTLINE_SERVICE_UUID,
  Store
} from '@softline/core';
import { WithCRUDRepositoryCollection } from './crud-repository-collection.mixin';

type Constructor<T extends {}> = new(...args: any[]) => T;

type RepositoryCollectionMixinParams<T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>>
  = {store: InjectionToken<TStore>, collectionFeature: (o: TStore) => CollectionStore2<T>, repositoryFeature: (o: TStore) => RepositoryCollectionStore2<T>};

export const WithRepositoryCollection = <T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>, TBase extends Constructor<{}> = Constructor<{}>>(params: RepositoryCollectionMixinParams<T, TStore>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class RepositoryCollectionMixin
    extends
      WithCRUDRepositoryCollection<T, TStore, TBase>({store: params.store, repositoryFeature: params.repositoryFeature},
      WithLoadRepositoryCollection<T, TStore, TBase>({store: params.store, collectionFeature: params.collectionFeature, repositoryFeature: params.repositoryFeature}, Base
      ))
    implements OnInit, OnDestroy {
    #token: string | null = null;
    #destroying = false;

    #injector = inject(Injector);
    #uuid = inject(SOFTLINE_SERVICE_UUID);
    #store = inject(Store);

    #repositoryStore: RepositoryCollectionStore2<T>;

    constructor(...args: any[]) {
      super(...args);
      const injectedStore = inject(params.store);
      this.#repositoryStore = params.repositoryFeature(injectedStore);
    }

    override async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
    }

    override ngOnDestroy(): void {
      this.#destroying = true;
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return RepositoryCollectionMixin;
}
