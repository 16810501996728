<ng-container
  *ngIf="definition && definition.visible | booleanRule: value:true"
>
  <ng-container
    *ngIf="!loading; else skeleton"
    softDynamicValueField
    [definition]="definition"
    [value]="value"
  ></ng-container>

  <ng-template #skeleton>
    <soft-dynamic-skeleton [definition]="definition"> </soft-dynamic-skeleton>
  </ng-template>
</ng-container>
