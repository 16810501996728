import { AfterContentInit, ContentChild, Directive, ElementRef, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { NoopValueConverter, ValueConverter } from "@softline/core";
import { ControlValueAccessor, FormControlDirective, FormControlName, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
  selector: 'soft-value-converter, [valueConverter]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValueConverterDirective),
      multi: true,
    },
  ],
})
export class ValueConverterDirective<TInner, TOuter> implements AfterContentInit, ControlValueAccessor {

  private onChangeFn?: (obj) => void;
  private onTouchedFn?: () => void;

  innerValue?: TInner;
  @Output() innerValueChange = new EventEmitter<TInner>;

  private _value!: TOuter;
  get value(): TOuter {
    return this._value;
  }
  @Input() set value(value: TOuter) {
    this._value = value;
    if(!this.control)
      return;
    this.innerValue = this.converter.convertBack(value);
    this.control.writeValue(this.innerValue);
  }
  @Output() valueChange = new EventEmitter<TOuter>;

  @Input() converter: ValueConverter<TInner, TOuter> = new NoopValueConverter();

  @ContentChild('convertValue') control?: ControlValueAccessor;

  constructor() { }

  ngAfterContentInit() {
    if(!this.control)
      return;

    this.control.registerOnChange((v: TInner) => {
      this.innerValue = v;
      this._value = this.converter.convert(v);
      if(this.onChangeFn)
        this.onChangeFn(this._value);
      this.innerValueChange.emit(this.innerValue);
      this.valueChange.emit(this._value);
    });
    this.control.registerOnTouched(() => {
      if(this.onTouchedFn)
        this.onTouchedFn();
    })

    this.innerValue = this.converter.convertBack(this.value);
    this.control.writeValue(this.innerValue);
    this.innerValueChange.emit(this.innerValue);
  }

  writeValue(obj: any): void {
    this._value = obj;
    if(this.control) {
      this.innerValue = this.converter.convertBack(obj);
      this.control.writeValue(this.innerValue);
      this.innerValueChange.emit(this.innerValue);
    }
    this.valueChange.emit(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if(!this.control || !this.control.setDisabledState)
      return;
    this.control.setDisabledState(isDisabled)
  }
}
