import { inject, Injectable, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { Store } from '@softline/core';
import { Definition } from '../data/definitions';
import { SOFTLINE_FEATURE_DEFINITIONS } from '../dynamic.shared';
import * as DefinitionStore from '../definition.store';

type Constructor<T> = new(...args: any[]) => T;

export const WithDefinition = <T extends Constructor<{}>>(name: string, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class DefinitionMixin extends Base implements OnInit, OnDestroy {
    #store = inject(Store);

    definition = this.#store.signal(
      SOFTLINE_FEATURE_DEFINITIONS,
      DefinitionStore.getters.definition,
      name
    )

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit() {
      if(super['ngOnInit'])
        super['ngOnInit']();

      const definition = await this.#store.dispatch(
        SOFTLINE_FEATURE_DEFINITIONS,
        DefinitionStore.actions.loadOnce,
        { name }
      );
    }

    ngOnDestroy() {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

  }
  return DefinitionMixin;
}
