import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  BackNavigable,
  BackNavigationService,
  CommandStore,
  handleRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  UploadFileCommand
} from "@softline/application";
import {Store} from "@softline/core";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_EMBALLAGE
} from "../../warenuebernahme.shared";
import {WwsWarenuebernahmeStore} from "../../store/warenuebernahme.store";
import {BehaviorSubject, combineLatestWith, map, Subscription, switchMap} from "rxjs";
import {KopfdatenComponent} from "../../components/kopfdaten/kopfdaten.component";
import {WwsBewegungStore} from "../../store/bewegung.store";
import {BewegungListComponent} from "../../components/bewegung-list/bewegung-list.component";
import {WwsEmballageStore} from "../../store/emballage.store";
import {Bewe} from "../../types/bewegung";
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule
} from "@softline/ui-core";
import {PositionErledigenDialogComponent} from "../../dialogs/position-erledigen/position-erledigen-dialog.component";
import {
  EmballageHinzufuegenDialogComponent
} from "../../dialogs/emballage-hinzufuegen/emballage-hinzufuegen-dialog.component";
import {FilterValue} from "../../types/filter-value";
import {Einheiteneingabe, EinheiteneingabeDialogComponent, EinheiteneingabeParams} from "@softapps/wws/core";
import {
  WarenuebernahmeAbschliessenDialogComponent
} from "../../dialogs/warenuebernahme-abschliessen/warenuebernahme-abschliessen-dialog.component";

@Component({
  selector: 'soft-warenuebernahme-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    KopfdatenComponent,
    BewegungListComponent,
    UiCoreModule
  ],
  templateUrl: './warenuebernahme-detail.component.html',
  styleUrls: ['./warenuebernahme-detail.component.scss'],
})
export class WarenuebernahmeDetailComponent implements OnInit, OnDestroy, BackNavigable {

  private archiveKey = '';
  private warenuebernahmeSubscription?: Subscription;

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  readonly warenuebernahme$ = this.activatedRoute.paramMap.pipe(
    map(o => o.get('id')),
    switchMap(id => this.store.observe(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
        WwsWarenuebernahmeStore.getters.entity,
        id
      )
    )
  );

  readonly filterValues: FilterValue[] = [
    {
      id: 0,
      value: 'O',
      bezeichnung: '#WWS_WARENUEBERNAHME.DETAIL.LABEL_OFFENE_POSITIONEN'
    },
    {
      id: 1,
      value: 'E',
      bezeichnung: '#WWS_WARENUEBERNAHME.DETAIL.LABEL_ERLEDIGTE_POSITIONEN'
    },
    {
      id: 2,
      value: 'A',
      bezeichnung: '#WWS_WARENUEBERNAHME.DETAIL.LABEL_ALLE_POSITIONEN'
    },
  ];

  readonly visibleStatus$ = new BehaviorSubject<FilterValue>(this.filterValues[0]);

  readonly warenuebernahmeBewegung$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
    WwsBewegungStore.getters.bewegungenMap
  );

  readonly bewegungen$ = this.warenuebernahmeBewegung$.pipe(
    combineLatestWith(this.visibleStatus$),
    map(([beweMap, status]) => {
      switch (status.value) {
        case 'O':
          return beweMap.offeneBewegungen
        case 'E':
          return beweMap.erledigteBewegungen
        default:
          return beweMap.alleBewegungen
      }
    })
  );

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
    WwsBewegungStore.getters.loading
  );

  constructor(private store: Store,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private backNavigationService: BackNavigationService) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);

    this.warenuebernahmeSubscription = this.warenuebernahme$.subscribe(warenuebernahme => {
      this.archiveKey = warenuebernahme?.archivekey;
    });

    const uploadFileCommand = new UploadFileCommand(this.store, {
      sources: 'photo',
      selectionMode: 'multi',
      archiveKey: this.archiveKey
    });

    uploadFileCommand.icon = 'fa-regular fa-camera';
    uploadFileCommand.name = '#WWS_WARENUEBERNAHME.DETAIL.LABEL_FOTOS_SPEICHERN';

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: WarenuebernahmeDetailComponent,
        commands: [
          uploadFileCommand,
          {
            name: '#WWS_WARENUEBERNAHME.DETAIL.LABEL_ABSCHLIESSEN',
            class: 'menu bottom-menu primary',
            execute: async () => await this.abschliessen(),
          }
        ]
      }
    );

    try {
      const idwu = this.activatedRoute.snapshot.paramMap.get('id');

      if (!idwu)
        return;

      await Promise.all([
        this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
          WwsWarenuebernahmeStore.actions.sperren,
          idwu
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
          WwsBewegungStore.actions.loadMany,
          {
            clear: true,
            pathParams: {
              idwu
            }}
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_EMBALLAGE,
          WwsEmballageStore.actions.loadMany
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
          WwsWarenuebernahmeStore.actions.loadOnce,
          { id: idwu }
        )
      ]);
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  async ngOnDestroy(): Promise<void> {
    this.backNavigationService.set(undefined);

    if (this.warenuebernahmeSubscription && !this.warenuebernahmeSubscription.closed)
      this.warenuebernahmeSubscription.unsubscribe();

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      WarenuebernahmeDetailComponent
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.actions.oeffnen,
      this.activatedRoute.snapshot.paramMap.get('id')
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/warenuebernahme']);
  }

  async abschliessen() {

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<boolean, object>(),
      {
        id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_ABSCHLIESSEN',
        component: WarenuebernahmeAbschliessenDialogComponent,
        dismiss: true
      }
    );

    if (!result || result === 'DISMISSED')
      return;

    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
      WwsWarenuebernahmeStore.actions.abschliessen,
      this.activatedRoute.snapshot.paramMap.get('id')
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title: '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.TITEL_WARENUEBERNAHME_ABGESCHLOSSEN',
        message: '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.MESSAGE_WARENUEBERNAHME_ABGESCHLOSSEN',
    });

    await this.router.navigate(['/warenuebernahme']);
  }

  async updateBeweStatus(bewe: Bewe): Promise<void> {
    if (bewe.status === 'E') {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
        WwsBewegungStore.actions.positionOeffnen,
        bewe
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.TITEL_POSITION_GEOEFFNET',
          message: '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.MESSAGE_POSITION_GEOEFFNET',
        });

      return;
    }

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Bewe, object>(),
      {
        id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_POSITION_ERLEDIGEN',
        component: PositionErledigenDialogComponent,
        data: {
          bewegung: bewe
        },
        dismiss: true
      }
    );

    if (result === 'DISMISSED')
      return;

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title: '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.TITEL_POSITION_ERLEDIGT',
        message: '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.MESSAGE_POSITION_ERLEDIGT',
      });
  }

  updateMengeLocal(data: { bewe: Bewe; menge: number }): void {
      const menge_lg = (data.menge * (data?.bewe?.artikelMenge?.einheit?.faktor ?? 1)) / (data?.bewe?.artikelMenge?.einheit_lg?.faktor ?? 1);

      this.store.commit(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
        WwsBewegungStore.mutations.patch,
        {
          id: data.bewe.id,
          changes: {
            artikelMenge: {
              ...data.bewe.artikelMenge,
              menge: data.menge,
              menge_lg: menge_lg
            }
          }
        }
      );

  }

  async openMengeDialog(bewegung: Bewe) {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<Einheiteneingabe | null, { params: EinheiteneingabeParams }>(),
      {
        id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_MENGEN_EINGABE',
        component: EinheiteneingabeDialogComponent,
        dismiss: true,
        data: {
          params: {
            idartstamm: bewegung.artikelMenge.artikel.id,
            menge: bewegung.artikelMenge.menge,
            idbasisEinheit: bewegung.artikelMenge.einheit.id
          }
        },
      }
    );

    if (!result || result === 'DISMISSED')
      return;

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE,
      WwsBewegungStore.mutations.patch,
      {
        id: bewegung.id,
        changes: {
          artikelMenge: {
            ...bewegung.artikelMenge,
            menge: result.erfassungsEinheit.menge ?? 0,
            menge_lg: result.summeEinheit.menge ?? 0
          }
        }
      }
    );
  }

  async openEmballageHinzufuegenDialog() {
    const idwu = this.activatedRoute.snapshot.paramMap.get('id');

    if (!idwu)
      return;

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<string, object>(),
      {
        id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_EMBALLAGE_HINZUFUEGEN',
        component: EmballageHinzufuegenDialogComponent,
        data: { idwu },
        dismiss: true
      }
    );

    if (result === 'DISMISSED')
      return

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      {
        title: '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.TITEL_EMBALLAGE_HINZUGEFUEGT',
        message: '#WWS_WARENUEBERNAHME.DETAIL.MESSAGES.MESSAGE_EMBALLAGE_HINZUGEFUEGT',
    });
  }
}
