import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Modal } from '../../data/modal';
import { dataUriToBlob } from '@softline/core';

@Component({
  selector: 'soft-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureModalComponent implements Modal<Blob | null> {
  close!: (result: Blob | null) => void;

  title: string | undefined;
  subtitle?: string;
  content?: TemplateRef<any>;
  params?: object;
  showCancel?: boolean;

  constructor() {}

  registerCloseHandler(handler: (result: Blob | null) => void): void {
    this.close = handler;
  }

  onSubmit(value: string | null): void {
    if (typeof value === 'string') {
      const blob = dataUriToBlob(value);
      this.close(blob);
    } else this.close(value);
  }
}
