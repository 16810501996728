import { Inject, Pipe, PipeTransform } from "@angular/core";
import { FilterConfigOperator } from "@softline/application";
import { SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT } from "../../../filter-and-sort.shared";

@Pipe({
  name: 'filterOperator',
  standalone: true,
  pure: true
})
export class FilterOperatorPipe implements PipeTransform {

  constructor(@Inject(SOFTLINE_REGISTRATION_FILTER_OPERATOR_COMPONENT) private registeredOperators: FilterConfigOperator[]) {
  }

  transform(operator: string | undefined | null): FilterConfigOperator | null {
    if(!operator)
      return null;

    return this.registeredOperators
      .find(o => o.operator === operator) ?? {operator, title: operator };
  }

}
