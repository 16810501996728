import { GetResourceService, ResourceService } from '../../data/abstraction';
import { LoadObjectParameters, ReadonlyRepository } from '../abstraction';
import { createHttpResourceLocation, HttpResourceLocation } from '@softline/core';
import { Observable } from 'rxjs';

export class ReadonlyHttpRepository<TResult> implements ReadonlyRepository<TResult> {

  constructor(protected service: ResourceService<HttpResourceLocation>,
              protected url: string) {
  }

  load(params?: LoadObjectParameters): Observable<TResult> {
    const location = createHttpResourceLocation({ url: this.url, pathParams: params?.pathParams });
    return this.service.get(location);
  }
}
