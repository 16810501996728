import {SortConfig} from '@softline/application';

export const abteilungenSortConfig: SortConfig = {
  properties: [
    {
      property: 'abteilung.id',
      title: 'Abteilung ID'
    },
    {
      property: 'abteilung.bezeichnung',
      title: 'Abteilungs-Bezeichnung'
    },
    {
      property: 'mitarbeiterGesamt',
      title: 'Anzahl Mitarbeiter Gesamt'
    },
    {
      property: 'anwesend',
      title: 'Anwesend'
    }
  ]
};

export const userSortConfig: SortConfig = {
  properties: [
    {
      property: 'lstfix.persnr',
      title: 'Personalnummer'
    },
    {
      property: 'lstfix.vorname',
      title: 'Vorname'
    },
    {
      property: 'lstfix.zuname',
      title: 'Zuname'
    },
    {
      property: 'status',
      title: 'Status',
      params: {
        formatFn: (o: 'A' | 'E' | 'U') => {
          switch (o) {
            case 'A':
              return 'Anwesend'
            case 'E':
              return 'Fehlt erwartet'
            case 'U':
              return 'Fehlt unerwartet'
          }
        }
      }
    }
  ]
}
