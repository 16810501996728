import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@softline/core';
import { UiCoreModule } from '@softline/ui-core';
import * as PrinterStore from './printer.store';
import { SOFTLINE_FEATURE_PRINTER } from '../application.shared';

@NgModule({
  declarations: [],
  imports: [CommonModule, ReactiveFormsModule, UiCoreModule],
  exports: [],
  providers: [],
})
export class PrinterModule {
  static forRoot(): ModuleWithProviders<PrinterRootModule> {
    return {
      ngModule: PrinterRootModule,
    };
  }
}

@NgModule({
  imports: [
    PrinterModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_PRINTER,
      feature: PrinterStore.feature,
    }),
  ],
})
export class PrinterRootModule {}
