import {ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {BehaviorSubject, map, switchMap} from 'rxjs';
import {EmpfaengerAbteilungListComponent} from './empfaenger-abteilung-list/empfaenger-abteilung-list.component';
import {EmpfaengerUserListComponent} from './empfaenger-user-list/empfaenger-user-list.component';
import {EmpfaengerAuswahlComponent} from '@softapps/allgemein/send-notification';
import {
  SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
  SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER
} from '../../statusabfrage.shared';
import {PmsPersonalStore} from '../../store/personal.store';
import {Filter, FilterService, Sort, SortService, Store} from '@softline/core';
import {FilterAndSortComponent} from '@softline/application';
import {PmsAbteilungStore} from '../../store/abteilung.store';
import {abteilungenFilterConfig, userFilterConfig} from './statusabfrage-empfaenger-auswahl.component.filter';
import {abteilungenSortConfig, userSortConfig} from './statusabfrage-empfaenger-auswahl.component.sort';

const COMPONENT_INDEX_MAP = {
  0: EmpfaengerAbteilungListComponent,
  1: EmpfaengerUserListComponent
}

@Component({
  selector: 'soft-statusabfrage-empfaenger-auswahl',
  standalone: true,
  imports: [CommonModule, UiCoreModule, FilterAndSortComponent],
  templateUrl: './statusabfrage-empfaenger-auswahl.component.html',
  styleUrls: ['./statusabfrage-empfaenger-auswahl.component.scss'],
  providers: [DatePipe]
})
export class StatusabfrageEmpfaengerAuswahlComponent implements OnInit {

  @ViewChild('header', { static: true }) private header?: TemplateRef<any>;

  readonly activatedTabIndex$ = new BehaviorSubject<number>(0);

  readonly activeComponent$ = this.activatedTabIndex$.pipe(
    map(index => COMPONENT_INDEX_MAP[index] ?? EmpfaengerAbteilungListComponent)
  );

  readonly sort$ =  this.activatedTabIndex$.pipe(
    switchMap(index =>
      this.store.observe(
        index === 0 ? SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN : SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
        index === 0 ? PmsAbteilungStore.getters.sort : PmsPersonalStore.getters.sort
      )
    )
  );

  readonly filter$ =  this.activatedTabIndex$.pipe(
    switchMap(index =>
      this.store.observe(
        index === 0 ? SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN : SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
        index === 0 ? PmsAbteilungStore.getters.filter : PmsPersonalStore.getters.filter
      )
    )
  );

  readonly filterData$ = this.activatedTabIndex$.pipe(
    switchMap(index =>
      this.store.observe(
        index === 0 ? SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN : SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
        index === 0 ? PmsAbteilungStore.getters.all : PmsPersonalStore.getters.all
      )
    )
  );

 readonly filterConfig$ =  this.activatedTabIndex$.pipe(
   map(index => index === 0 ? abteilungenFilterConfig : userFilterConfig)
 );

  readonly sortConfig$ =  this.activatedTabIndex$.pipe(
    map(index => index === 0 ? abteilungenSortConfig : userSortConfig)
  );

  constructor(
    private readonly datePipe: DatePipe,
    private readonly store: Store,
    private readonly sortService: SortService,
    private readonly filterService: FilterService,
    private readonly cdRef: ChangeDetectorRef,
    @Inject(EmpfaengerAuswahlComponent) private hostComponent: EmpfaengerAuswahlComponent,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.header){
      this.hostComponent.attachHeader(this.header);
      this.cdRef.markForCheck();
    }

    await this.store.dispatch(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
      PmsPersonalStore.actions.loadMany,
      {
        pathParams: { stichdatum: this.datePipe.transform(new Date().toISOString(), 'yyyy-MM-dd') },
      }
    );
  }

  tabIndexChanged(index: number): void {
    this.activatedTabIndex$.next(index);
  }

  async onFilterChange(filter: Filter | null): Promise<void> {
    const tabIndex = this.activatedTabIndex$.value;

    /*
    const featureName = tabIndex === 0
      ? SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN
      : SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER;

    const mutation = tabIndex === 0
      ? PmsAbteilungStore.mutations.setFilter
      : PmsPersonalStore.mutations.setFilter;

    this.store.commit(
      featureName,
      mutation,
      filter
    ); */

    this.store.commit(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
      PmsAbteilungStore.mutations.setFilter,
      filter
    );

    this.store.commit(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
      PmsPersonalStore.mutations.setFilter,
      filter
    );
  }

  async onSortChange(sort: Sort | null): Promise<void> {
    const tabIndex = this.activatedTabIndex$.value;

    /*
    const featureName = tabIndex === 0
      ? SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN
      : SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER;

    const mutation = tabIndex === 0
      ? PmsAbteilungStore.mutations.setSort
      : PmsPersonalStore.mutations.setSort;

    this.store.commit(
      featureName,
      mutation,
      sort
    ); */

    this.store.commit(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN,
      PmsAbteilungStore.mutations.setSort,
      sort
    );

    this.store.commit(
      SOFTLINE_FEATURE_PMS_STATUSABFRAGE_ABTEILUNGEN_MITARBEITER,
      PmsPersonalStore.mutations.setSort,
      sort
    );
  }
}
