import {createGetter, createMutation, CustomStore, mutate, select} from '@softline/core';
import {SearchEntry} from '../types/search-entry';



interface SearchHistoryState {
  history: Record<string, SearchEntry>;
}

const mutations = {
  addOrUpdate: createMutation<SearchHistoryState, SearchEntry>('searchHistory/addOrUpdate'),
  remove: createMutation<SearchHistoryState, string>('searchHistory/remove')
}

const actions = {}

const getters = {
  all: createGetter<SearchHistoryState, SearchEntry[], void>('searchHistory/all'),
}

export const SearchHistoryStore: CustomStore<SearchHistoryState, typeof mutations, typeof getters, typeof actions> = {
  mutations,
  actions,
  getters,
  feature: {
    initialState: {
      history: {}
    },
    getters: [
      select(getters.all, ({ state }) => {
        return Object.values(state.history);
      })
    ],
    actions: [],
    mutations: [
      mutate(mutations.addOrUpdate, ({ state, params }) => {
        const history = { ...state.history };
        history[params.term] = params;

        return {
          ...state,
          history
        };
      }),
      mutate(mutations.remove, ({ state, params }) => {
        const history = { ...state.history };

        if (history[params])
          delete history[params];

        return {
          ...state,
          history
        };
      })
    ]
  }
}
