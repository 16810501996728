import { InjectionToken, Injector } from '@angular/core';
import { Entity } from '../types/entity';
import {
  EntityService,
  QueryEntityService,
  TemplateService,
  UploadEntityService,
  ValidationService,
} from '../../../../softline/entity.service';
import { EntityDetailService } from '../../../../softline/entity-detail.service';

export const SOFTLINE_STORE_ENTITY_SERVICE = new InjectionToken<
  EntityService<any, any> & UploadEntityService<any, any>
>('SOFTLINE_STORE_ENTITY_SERVICE');

export const SOFTLINE_STORE_QUERY_SERVICE = new InjectionToken<
  QueryEntityService<any>
>('SOFTLINE_STORE_QUERY_SERVICE');

export const SOFTLINE_STORE_TEMPLATE_SERVICE = new InjectionToken<
  TemplateService<Entity>
>('SOFTLINE_STORE_TEMPLATE_SERVICE');

export const SOFTLINE_STORE_VALIDATION_SERVICE = new InjectionToken<
  ValidationService<Entity>
>('SOFTLINE_STORE_VALIDATION_SERVICE');

export const SOFTLINE_STORE_DETAIL_SERVICE_FACTORY = new InjectionToken<
  (name: string, injector: Injector) => EntityDetailService<any, any>
>('SOFTLINE_STORE_DETAIL_SERVICE_FACTORY');

export const SOFTLINE_STORE_DETAIL_QUERY_SERVICE_FACTORY = new InjectionToken<
  (name: string, injector: Injector) => QueryEntityService<any>
>('SOFTLINE_STORE_DETAIL_QUERY_SERVICE_FACTORY');
