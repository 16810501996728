import { Entity, isDefined, isDefinedNotEmpty, SelectStrategy, Store } from "@softline/core";
import { Injector } from "@angular/core";
import { FieldOkStrategyOptions, FieldOkStrategy, FieldOkInputParams } from "../../field-ok/strategies/field-ok.strategy";
import { ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { FieldOkParameters } from "../../../data/field-ok";
import { SOFTLINE_FEATURE_FIELD_OK } from "../../../dynamic.shared";
import * as FieldOkComponentStore from "../../../field-ok-component.store";

export class QueryFieldOkStrategy extends FieldOkStrategy<string, Entity> {

  multipleValueName$ = this.store.observe(
    SOFTLINE_FEATURE_FIELD_OK,
    FieldOkComponentStore.getters.multipleValueName,
    this.options.componentId
  );

  constructor(
    store: Store,
    injector: Injector,
    uuid: () => string,
    options: FieldOkStrategyOptions,
    protected selectStrategy: SelectStrategy<Entity[]>) {
    super(store, injector, uuid, options);
  }

  override async validate(input: string, options: FieldOkInputParams<string, object>): Promise<string | null> {
    if (input.length <= 0)
      return null;

    if(!isDefined(options.current))
      options = {...options, current: ''}

    let result = await this.executeValidation(input, options.parameters);
    if(typeof result === 'string') {
      return result;
    }

    result = await this.openDialog(this.options.config.defaultDataView, options) as string;
    if(result === 'DISMISSED')
      return options.current ?? null;

    return result;
  }

  override async resolve(value: string): Promise<void> {
    if(!this.modalId)
      return;

    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.resolve(),
      {id: this.modalId, result: value }
    )
  }

  protected override getQueryParams(input: string | null, extraQuery?: object, multiValued?: boolean): FieldOkParameters {
    return super.getQueryParams(input, extraQuery, isDefined(multiValued) ? multiValued : true);
  }
}
