import {Inject, Injectable, Optional} from "@angular/core";
import {Dictionary, IndexedDbService, Store} from "@softline/core";
import {
  AuthenticationContextStore,
  SOFTLINE_API_AUTHENTICATION,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT
} from "@softline/auth";
import {SOFTLINE_CONFIG_STORED_CONTEXT_FIELD} from "../../utilities.shared";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, switchMap} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class ContextStorageInterceptor implements HttpInterceptor {
  constructor(private store: Store,
              protected service: IndexedDbService,
              @Optional() @Inject(SOFTLINE_CONFIG_STORED_CONTEXT_FIELD) private fields: string[]) {
  }

  init(): void {
    if(!this.fields)
      return;

    this.store.observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data)
      .pipe(map(o => o as unknown as Dictionary<unknown>))
      .subscribe(async o => {
        const username = (o as {username: string})?.username;
        if(!username)
          return;
        const claims: Dictionary<unknown> = {username};
        for(const field of this.fields)
          claims[field] = o[field];
        this.service.update({databaseName: 'context', objectStoreName: 'claims', key: username}, claims);
      });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.indexOf(SOFTLINE_API_AUTHENTICATION) === -1)
      return next.handle(req);

    const username = req.body.username;
    return this.service.get({databaseName: 'context', objectStoreName: 'claims', key: username})
      .pipe(switchMap(o => {
        req = req.clone({
          body: {...req.body, claims: o }
        })
        return next.handle(req)
      }));
  }
}
