<soft-wizard (cancel)="navigateBack()">
  <soft-wizard-step [title]="'#SEND_NOTIFICATION.EMPFAENGER_AUSWAEHLEN'"
                    [description]="'#SEND_NOTIFICATION.EMPFAENGER_AUSWAEHLEN_DESC'">
    <soft-empfaenger-auswahl></soft-empfaenger-auswahl>
  </soft-wizard-step>
  <soft-wizard-step [title]="'#SEND_NOTIFICATION.NACHRICHT_VERFASSEN'"
                    [description]="'#SEND_NOTIFICATION.NACHRICHT_VERFASSEN_DESC'"
                    [canEnter]="empfaenger().length > 0">
    <soft-nachricht-verfassen #nachricht></soft-nachricht-verfassen>
  </soft-wizard-step>
  <soft-wizard-action [title]="'#SEND_NOTIFICATION.NACHRICHT_SENDEN'"
                      [icon]="'fa-regular fa-paper-plane'"
                      [state]="'hidden'"
                      [canEnter]="empfaenger().length > 0 && nachricht.form.valid"
                      (execute)="nachrichtSenden($event)"></soft-wizard-action>
</soft-wizard>
