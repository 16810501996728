import {Injectable} from '@angular/core';
import {ConnectionHttpService, ConnectionResourceLocation, ResponseResult} from '@softline/core';
import {map, Observable} from 'rxjs';
import {
  SOFTLINE_API_MDE_INVENTUR_ABSCHLUSS,
  SOFTLINE_API_MDE_INVENTUR_DEFAULT_LAGER,
  SOFTLINE_API_MDE_INVENTUR_KOPF,
  SOFTLINE_API_MDE_INVENTUR_LAGERPLATZ_SCAN
} from '../inventur.api';
import {InventurKopf} from '../data/inventur-kopf';
import {InventurLager} from '../data/inventur-lager';
import {Scan} from '@softline/application';

@Injectable()
export class InventurService {

  private get location(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_INVENTUR_ABSCHLUSS,
    }
  }

  private get kopfLocation(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_INVENTUR_KOPF,
    }
  }

  private get defaultLagerLocation(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_INVENTUR_DEFAULT_LAGER,
    }
  }

  private lagerplatzScanLocation(code: string): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_INVENTUR_LAGERPLATZ_SCAN,
      pathParams: {
        code
      }
    }
  }

  constructor(private httpService: ConnectionHttpService) {}

  abschluss(params: object): Observable<void> {
    return this.httpService.create(this.location, params);
  }

  getKopf(): Observable<InventurKopf | null | undefined> {
    return this.httpService.get<ResponseResult<InventurKopf>>(this.kopfLocation).pipe(
      map(o => o?.result)
    );
  }

  getDefaultLager(): Observable<InventurLager | null | undefined> {
    return this.httpService.get<ResponseResult<InventurLager>>(this.defaultLagerLocation).pipe(
      map(o => o?.result)
    );
  }

  removeAll(): Observable<unknown> {
    return this.httpService.delete<ResponseResult<InventurLager>>(this.kopfLocation);
  }

  getLagerplatz(scan: Scan): Observable<unknown> {
    return this.httpService.get<ResponseResult<any>>(this.lagerplatzScanLocation(encodeURIComponent(scan.data))).pipe(
      map(o => o?.result)
    );
  }
}
