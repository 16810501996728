import { Component, computed, contentChild, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaControlNameDirective, MetaFormDirective, MetaLabelComponent } from '@softline/dynamic';
import { FormControlName } from '@angular/forms';

@Component({
  selector: 'soft-meta-form-cell',
  standalone: true,
  imports: [CommonModule, MetaLabelComponent, MetaControlNameDirective],
  templateUrl: './meta-form-cell.component.html',
  styleUrl: './meta-form-cell.component.scss',
})
export class MetaFormCellComponent {
  metaFormDirective = inject(MetaFormDirective);

  name = input.required<string>();

  metaContainsFormControl = computed(() => {
    const metaForm = this.metaFormDirective.metaForm();
    const name = this.name();
    return metaForm;
  });
}
