<ng-container [ngSwitch]="definition.type">
  <span *ngSwitchCase="'span'">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </span>
  <div *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</ng-container>

<ng-template #template>
  <ng-container *ngFor="let child of definition.definitions">
    <div
      *ngIf="definition.visible | booleanRule: value:true"
      [ngClass]="child.class | classRule: value"
    >
      <label
        *ngIf="child.title"
        class="soft-label"
        [ngClass]="(child.titleClass | classRule: value) || 'left'"
        >{{ child.title }}</label
      >
      <soft-dynamic-value
        [definition]="child"
        [value]="value"
      ></soft-dynamic-value>
    </div>
  </ng-container>
</ng-template>
