<div class="w-full grid grid-cols-6 grid-rows-6 divide-x divide-y place-items-stretch text-2xl border border-solid">
  <div class="w-full col-span-6 row-span-1 text-right font-semibold">
    <div class="flex flex-col w-full h-full justify-center">
      <span class="truncate" [ngClass]="{'text-sm text-light': result}">{{input}}</span>
      @if(error) {
        <span class="text-sm text-danger-400 truncate">{{error}}</span>
      }
      @if(result) {
        <span>{{result}}</span>
      }
    </div>
  </div>
  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick('(')">(</div>
  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick(')')">)</div>
  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick(',')">,</div>
  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="clearClick()">
    C
  </div>
  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="deleteClick()">
    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" viewBox="0 0 576 512"><path d="M544 128c0-17.7-14.3-32-32-32H205.3c-8.5 0-16.6 3.4-22.6 9.4L32 256 182.6 406.6c6 6 14.1 9.4 22.6 9.4H512c17.7 0 32-14.3 32-32V128zM512 64c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H205.3c-17 0-33.3-6.7-45.3-18.7L9.4 278.6c-6-6-9.4-14.1-9.4-22.6s3.4-16.6 9.4-22.6L160 82.7c12-12 28.3-18.7 45.3-18.7H512zM427.3 180.7c6.2 6.2 6.2 16.4 0 22.6L374.6 256l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L352 278.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L329.4 256l-52.7-52.7c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L352 233.4l52.7-52.7c6.2-6.2 16.4-6.2 22.6 0z"/></svg>
  </div>
  <div class="flex justify-center items-center p-4 bg-primary hover:bg-primary-600 cursor-pointer" (click)="operatorClick('/')">
    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-6 h-6 text-primary-contrast" viewBox="0 0 448 512"><path class="stroke-primary-contrast" d="M224 96a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 96a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm0 160a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 96a64 64 0 1 0 0-128 64 64 0 1 0 0 128zM416 272c8.8 0 16-7.2 16-16s-7.2-16-16-16H32c-8.8 0-16 7.2-16 16s7.2 16 16 16H416z"/></svg>
  </div>

  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick('^2')">x<sup class="text-xs">2</sup></div>
  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick('^')">x<sup class="text-xs">y</sup></div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(7)">7</div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(8)">8</div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(9)">9</div>
  <div class="flex justify-center items-center p-4 bg-primary hover:bg-primary-600 cursor-pointer" (click)="operatorClick('*')">
    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-6 h-6 text-primary-contrast" viewBox="0 0 384 512"><path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z"/></svg>
  </div>

  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick('sqrt(')">
    <span class="text-xs">2</span>
    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 stroke-primary-contrast" viewBox="0 0 576 512"><path d="M352.1 64c-7.1 0-13.4 4.7-15.4 11.6L223.4 468.4c-1.8 6.3-7.2 10.8-13.7 11.5s-12.7-2.6-15.8-8.4L82.9 264.4c-2.8-5.2-8.2-8.4-14.1-8.4H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H68.8c17.7 0 34 9.7 42.3 25.3l92.4 172.4L306 66.7C311.9 46.1 330.7 32 352.1 32H560c8.8 0 16 7.2 16 16s-7.2 16-16 16H352.1zm52.6 180.7c6.2-6.2 16.4-6.2 22.6 0L480 297.4l52.7-52.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L502.6 320l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L480 342.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L457.4 320l-52.7-52.7c-6.2-6.2-6.2-16.4 0-22.6z"/></svg>
  </div>
  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick('nthRoot(')">
    <span class="text-xs">y</span>
    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 stroke-primary-contrast" viewBox="0 0 576 512"><path d="M352.1 64c-7.1 0-13.4 4.7-15.4 11.6L223.4 468.4c-1.8 6.3-7.2 10.8-13.7 11.5s-12.7-2.6-15.8-8.4L82.9 264.4c-2.8-5.2-8.2-8.4-14.1-8.4H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H68.8c17.7 0 34 9.7 42.3 25.3l92.4 172.4L306 66.7C311.9 46.1 330.7 32 352.1 32H560c8.8 0 16 7.2 16 16s-7.2 16-16 16H352.1zm52.6 180.7c6.2-6.2 16.4-6.2 22.6 0L480 297.4l52.7-52.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L502.6 320l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L480 342.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L457.4 320l-52.7-52.7c-6.2-6.2-6.2-16.4 0-22.6z"/></svg>
  </div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(4)">4</div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(5)">5</div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(6)">6</div>
  <div class="flex justify-center items-center p-4 bg-primary hover:bg-primary-600 cursor-pointer" (click)="operatorClick('-')">
    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-6 h-6 text-primary-contrast" viewBox="0 0 448 512"><path d="M432 256c0 8.8-7.2 16-16 16L32 272c-8.8 0-16-7.2-16-16s7.2-16 16-16l384 0c8.8 0 16 7.2 16 16z"/></svg>
  </div>

  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick('sin(')">sin</div>
  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick('cos(')">cos</div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(1)">1</div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(2)">2</div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(3)">3</div>
  <div class="flex justify-center items-center p-4 bg-primary hover:bg-primary-600 cursor-pointer" (click)="operatorClick('+')">
    <svg xmlns="http://www.w3.org/2000/svg"  class="fill-current w-6 h-6 text-primary-contrast" viewBox="0 0 448 512"><path d="M240 64c0-8.8-7.2-16-16-16s-16 7.2-16 16V240H32c-8.8 0-16 7.2-16 16s7.2 16 16 16H208V448c0 8.8 7.2 16 16 16s16-7.2 16-16V272H416c8.8 0 16-7.2 16-16s-7.2-16-16-16H240V64z"/></svg>
  </div>

  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick('tan(')">tan</div>
  <div class="flex justify-center items-center p-4 bg-secondary-300 hover:bg-secondary-400 cursor-pointer" (click)="operatorClick('pi')">
    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 stroke-primary-contrast" viewBox="0 0 448 512"><path d="M16 64C7.2 64 0 71.2 0 80s7.2 16 16 16H128V251c0 61.8-21 121.7-59.6 169.9l-.9 1.1c-5.5 6.9-4.4 17 2.5 22.5s17 4.4 22.5-2.5l.9-1.1C136.5 387 160 320 160 251V96H320V382.6c0 36.1 29.3 65.4 65.4 65.4c24.8 0 47.4-14 58.5-36.2l2.3-4.7c4-7.9 .7-17.5-7.2-21.5s-17.5-.7-21.5 7.2l-2.3 4.7c-5.7 11.3-17.2 18.5-29.9 18.5C367 416 352 401 352 382.6V96h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H16z"/></svg>
  </div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="operatorClick('-')">(-)</div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="numberClick(0)">0</div>
  <div class="flex justify-center items-center p-4 bg-secondary-100 hover:bg-secondary-200 cursor-pointer" (click)="operatorClick('.')">.</div>
  @if(result === null || !submit.observed) {
    <div class="flex justify-center items-center p-4 bg-accent hover:bg-accent-600 cursor-pointer" (click)="equalClick()">
      <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-6 h-6 text-accent-contrast" viewBox="0 0 448 512"><path d="M32 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H416c8.8 0 16-7.2 16-16s-7.2-16-16-16H32zm0 192c-8.8 0-16 7.2-16 16s7.2 16 16 16H416c8.8 0 16-7.2 16-16s-7.2-16-16-16H32z"/></svg>
    </div>
  }
  @else if(submit.observed) {
    <div class="flex justify-center items-center p-4 bg-accent hover:bg-accent-600 cursor-pointer" (click)="submitClick()">
      <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-6 h-6 text-accent-contrast" viewBox="0 0 512 512"><path d="M480 48c0-8.8 7.2-16 16-16s16 7.2 16 16V224c0 44.2-35.8 80-80 80H54.6L155.3 404.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-128-128c-6.2-6.2-6.2-16.4 0-22.6l128-128c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L54.6 272H432c26.5 0 48-21.5 48-48V48z"/></svg>
    </div>
  }
</div>
