import { inject, Injectable } from '@angular/core';
import { ConnectionHttpService, HttpApiCollectionRepository, Result } from '@softline/core';
import { Objekt } from './objekt';
import { SOFTLINE_API_OBJECTS } from '../wws-core.api';

@Injectable({providedIn: 'root'})
export class ObjektRepository extends HttpApiCollectionRepository<Objekt, Result<Objekt>, Result<Objekt[]>> {
  constructor() {
    super(inject(ConnectionHttpService), SOFTLINE_API_OBJECTS);
  }
}
