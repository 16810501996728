import {Scan} from '@softline/application';
import {Observable} from 'rxjs';
import {InjectionToken} from '@angular/core';
import {Beleg} from '../data/beleg';

export interface WarenverprobungBelegeingabeService {

  get inputLabel(): string
  get inputPlaceholder(): string
  get pageTitle(): string

  scan(scan: Scan): Observable<Beleg>
  find(belegnumer: string): Observable<Beleg>
}

export const WARENVERPROBUNG_BELEGEINGABE_SERVICE = new InjectionToken<WarenverprobungBelegeingabeService>(
  'WARENVERPROBUNG_BELEGEINGABE_SERVICE'
);
