export interface IndexedDBConfig {
  name: string;
  version: number;
  objectStoreConfigs: ObjectStoreConfig[];
  migrationCallbacks?: {
    [key: number]: (db: IDBDatabase, transaction: IDBTransaction) => void;
  };
}

export interface ObjectStoreConfig {
  name: string;
  parameters: {
    keyPath: string;
    autoIncrement: boolean;
    [key: string]: any;
  };
  schema: ObjectStoreSchema[];
}

export interface ObjectStoreSchema {
  name: string;
  keypath: string;
  options: {
    unique: boolean;
    [key: string]: any;
  };
}
