import { Injectable } from '@angular/core';
import { isDefined, Store } from '@softline/core';
import {
  LabelType,
  Scan,
  ScannerStore,
  ScannerService,
  SOFTLINE_FEATURE_SCANNER,
} from '@softline/application';
import {
  ModalStore,
  SOFTLINE_FEATURE_MODAL,
} from '@softline/ui-core';
import { BarcodeFormat } from '@zxing/library';
import { JSScannerComponent } from '../components/js-scanner.component';

const MODAL_ID = 'barcode_scanner';

@Injectable()
export class JSScannerService extends ScannerService {

  constructor(private store: Store) {
    super();
  }

  override async init(): Promise<void> {
    this.isAvailable = true;
  }

  async scan(labelType?: LabelType | LabelType[]): Promise<Scan> {
    const labelTypes = this.convertLabelTypes(labelType);

    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<{ result?: Scan; error?: Error }, any>(),
      {
        id: MODAL_ID,
        component: JSScannerComponent,
        data: { labelTypes },
      }
    );
    if (result === 'DISMISSED') throw new Error('JS Scanner: canceled scan');
    if (isDefined(result.error)) throw Error;
    const returnValue = result.result;
    if (!returnValue)
      throw new Error('JS Scanner: undefined result');
    return returnValue;
  }

  async cancel(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.close,
      MODAL_ID
    );
  }

  convertLabelTypes(labelType?: LabelType | LabelType[]): BarcodeFormat[] {
    if (!Array.isArray(labelType)) return this.getZXingLabelType(labelType);

    const returnValue: BarcodeFormat[] = [];
    for (const t of labelType) returnValue.push(...this.getZXingLabelType(t));
    return returnValue.filter((v, i, a) => a.indexOf(v) === i);
  }

  getZXingLabelType(labelType?: LabelType): BarcodeFormat[] {
    switch (labelType) {
      case 'aztec':
        return [BarcodeFormat.AZTEC];
      case 'codabar':
        return [BarcodeFormat.CODABAR];
      case 'code39':
        return [BarcodeFormat.CODE_39];
      case 'code93':
        return [BarcodeFormat.CODE_93];
      case 'code128':
        return [BarcodeFormat.CODE_128];
      case 'datamatrix':
        return [BarcodeFormat.DATA_MATRIX];
      case 'ean8':
        return [BarcodeFormat.EAN_8];
      case 'ean13':
        return [BarcodeFormat.EAN_13];

      case 'itf':
        return [BarcodeFormat.ITF];
      case 'maxicode':
        return [BarcodeFormat.MAXICODE];
      case 'pdf417':
        return [BarcodeFormat.PDF_417];
      case 'qrcode':
        return [BarcodeFormat.QR_CODE];
      case 'rss14':
        return [BarcodeFormat.RSS_14];
      case 'rss_expanded':
        return [BarcodeFormat.RSS_EXPANDED];

      case 'upc':
        return [BarcodeFormat.UPC_A, BarcodeFormat.UPC_E];
      case 'upc_e':
        return [BarcodeFormat.UPC_E];
      case 'upc_e0':
        return [BarcodeFormat.UPC_E];
      case 'upc_e1':
        return [BarcodeFormat.UPC_E];
      case 'code':
        return [
          BarcodeFormat.CODE_39,
          BarcodeFormat.CODE_93,
          BarcodeFormat.CODE_128,
        ];
      case 'ean':
        return [BarcodeFormat.EAN_8, BarcodeFormat.EAN_13];
      case undefined:
        return [
          BarcodeFormat.AZTEC,
          BarcodeFormat.CODE_39,
          BarcodeFormat.CODE_93,
          BarcodeFormat.DATA_MATRIX,
          BarcodeFormat.CODE_128,
          BarcodeFormat.CODABAR,
          BarcodeFormat.EAN_8,
          BarcodeFormat.EAN_13,
          BarcodeFormat.ITF,
          BarcodeFormat.MAXICODE,
          BarcodeFormat.PDF_417,
          BarcodeFormat.RSS_14,
          BarcodeFormat.RSS_EXPANDED,
          BarcodeFormat.UPC_A,
          BarcodeFormat.UPC_E,
          BarcodeFormat.QR_CODE,
        ];
      default:
        return [];
    }
  }
}
