import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {BestellerfassungArtikelDetail} from '../data/bestellerfassung-artikel-detail';
import {ConnectionHttpService, ConnectionResourceLocation, ResponseResult, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {SOFTLINE_API_MDE_BESTELLERFASSUNG_CHECK_EINGABE} from '../bestellerfassung.api';

@Injectable()
export class CheckEingabeService {

  private get location(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_BESTELLERFASSUNG_CHECK_EINGABE,
    }
  }

  constructor(@Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService) {}

  checkEingabe(bewe: object): Observable<BestellerfassungArtikelDetail['artikelCheckResult']> {
    return this.httpService.create<object, BestellerfassungArtikelDetail['artikelCheckResult']>(this.location, bewe);
  }
}
