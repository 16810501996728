import { Injectable, isSignal } from '@angular/core';
import { Command2, Command2Set } from './command2';
import { Dictionary, isDefined } from '@softline/core';
import { BehaviorSubject, combineLatestWith, mergeMap, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Injectable({providedIn: 'root'})
export class Command2Service {
  private commands$ = new BehaviorSubject<Dictionary<Command2>>({});

  private get commands(): Dictionary<Command2> {
    return this.commands$.value;
  }
  private set commands(value: Dictionary<Command2>) {
    this.commands$.next(value);
  }

  register(command: Command2) {
    if(this.commands[command.name])
        throw new Error(`[Command2Service]registerSet: Command with name ${command.name} already exists`);
    this.commands = {...this.commands, [command.name]: command};
  }

  remove(name: string) {
    if(!this.commands[name])
      throw new Error(`[Command2Service]removeSet: Command with name ${name} does not exist`);
    const commands = {...this.commands};
    delete commands[name];
    this.commands = commands;
  }

  canExecute(name: string): Observable<boolean> {
    return this.commands$.pipe(
      map((commands) => commands[name]),
      mergeMap((command) => {
        if(!command)
          return of(false);
        if(!isDefined(command.canExecute))
          return of(true);

        if(typeof command?.canExecute === 'boolean')
          return of(command.canExecute);
        else
          return command.canExecute;
      })
    );
  }

  async execute(name: string): Promise<void> {
    const command = this.commands[name]
    if(!command)
      throw new Error(`[Command2Service]execute: Command with name ${name} does not exist`);
    await command.execute();
  }
}
