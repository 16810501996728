<ng-container *ngIf="config$ | async as config">
  <div>
    <div class="soft-overline">Common</div>
    {{config?.properties | json }}
  </div>
  <div>
    <div class="soft-overline">Modules</div>
    {{config?.modules | json}}
  </div>
</ng-container>
