import { Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { IndexedDbStoreAdapter } from './adapter/indexed-db-store.adapter';
import { EMPTY, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfflineResolver  {
  constructor(
    private router: Router,
    @Optional() private adapter?: IndexedDbStoreAdapter
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (!this.adapter) return of(false);

    return this.adapter.loaded$.pipe(first((o) => o));
  }
}
