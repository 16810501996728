import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
} from '../../../factories';
import { StoreFeature } from '../../../store';
import * as CollectionStore from './collection.store';
import { SOFTLINE_STORE_ENTITY_SERVICE } from '../single/entity.shared';
import { Dictionary } from '../../../../types/dictionary';
import { Entity } from '../types/entity';
import * as SubscriptionStore from '../../remote/subscription.store';
import { handleSubscriptionState } from '../../remote/subscription.store';
import {
  ActionState,
  handleObservableActionState,
} from '../../remote/action.store';
import * as ActionStore from '../../remote/action.store';
import * as KeyStore from '../../remote/key.store';
import * as ParamsStore from '../../remote/params.store';
import { SOFTLINE_SERVICE_UUID } from '../../../../core.shared';
import { lastValueFrom } from 'rxjs';
import { inject } from "@angular/core";

export interface State<T extends Entity = Entity>
  extends CollectionStore.State<T>,
    KeyStore.State,
    ParamsStore.State,
    SubscriptionStore.State,
    ActionStore.State {
  loadedManyOnce: boolean;
}

export interface LoadActionParameters {
  id: string | number;
  pathParams?: Dictionary<unknown>;
  queryParams?: Dictionary<unknown>;
  token?: string;
}

export interface LoadManyActionsParameters {
  pathParams?: Dictionary<unknown>;
  queryParams?: Dictionary<unknown>;
  clear?: boolean;
  token?: string;
}

export class Store<T extends Entity = Entity> {
  mutations = {
    ...this.collectionStore.mutations,
    action: ActionStore.mutations,
    subscription: SubscriptionStore.mutations,
  };

  actions = {
    ...SubscriptionStore.actions,
    load: createAction<State<T>, LoadActionParameters, T>('load'),
    loadMany: createAction<State<T>, LoadManyActionsParameters, T[]>(
      'loadMany'
    ),

    loadOnce: createAction<State<T>, LoadActionParameters, T>('loadOnce'),
    loadManyOnce: createAction<State<T>, LoadManyActionsParameters, T[]>(
      'loadAllOnce'
    ),
  };

  getters = {
    ...this.collectionStore.getters,
    action: ActionStore.getters,
    subscription: SubscriptionStore.getters,
    loading: createGetter<State<T>, boolean>('loading'),
    loaded: createGetter<State<T>, boolean>('loaded'),
  };

  protected internalMutations = {
    setLoadedManyOnce: createMutation<State<T>, undefined>('setLoadedManyOnce'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.collectionStore.feature.initialState,
      ...SubscriptionStore.feature.initialState,
      ...ActionStore.feature.initialState,
      ...KeyStore.feature.initialState,
      ...ParamsStore.feature.initialState,
      loadedManyOnce: false,
    },
    mutations: [
      ...this.collectionStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
      ...ActionStore.feature.mutations,
      ...KeyStore.feature.mutations,
      ...ParamsStore.feature.mutations,
      mutate(this.internalMutations.setLoadedManyOnce, ({ state }) => ({
        ...state,
        loadedManyOnce: true,
      })),
    ],
    getters: [
      ...this.collectionStore.feature.getters,
      ...ActionStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      ...KeyStore.feature.getters,
      ...ParamsStore.feature.getters,
      select(this.getters.loading, ({ get, featureName, params }) => {
        const actions = [this.actions.load.name, this.actions.loadMany.name];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.loaded, ({ get, featureName, params }) => {
        const actions = [this.actions.load.name, this.actions.loadMany.name];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
    actions: [
      ...ActionStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      ...KeyStore.feature.actions,
      ...ParamsStore.feature.actions,
      on(
        this.actions.load,
        async ({ commit, featureName, params }) => {
          const service = inject(SOFTLINE_STORE_ENTITY_SERVICE);
          const token = params?.token ?? inject(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const subscription$ = handleSubscriptionState(
            service.get(params.id, params.pathParams, params.queryParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.load.name,
              token
            )
          );
          commit(featureName, this.mutations.addOrUpdate, result);
          return result;
        }
      ),
      on(
        this.actions.loadMany,
        async ({ commit, featureName, params }) => {
          const service = inject(SOFTLINE_STORE_ENTITY_SERVICE);
          const token = params?.token ?? inject(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const subscription$ = handleSubscriptionState(
            service.getMany(params?.pathParams, params?.queryParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.loadMany.name,
              token
            )
          );

          if (params?.clear) commit(featureName, this.mutations.clear);

          commit(featureName, this.mutations.addOrUpdateMany, result);
          return result;
        }
      ),
      on(
        this.actions.loadOnce,
        async ({ commit, get, dispatch, featureName, params, injector }) => {
          let entity = get(featureName, getters.entity, params.id);
          if (!entity)
            entity = await dispatch(
              featureName,
              actions.load,
              params,
              injector
            );
          return entity;
        }
      ),
      on(
        this.actions.loadManyOnce,
        async ({ commit, get, dispatch, featureName, params, injector }) => {
          const state = get(featureName) as State;

          let entities: any;
          if (state.loadedManyOnce) entities = get(featureName, getters.all);
          else {
            commit(featureName, this.internalMutations.setLoadedManyOnce);
            entities = await dispatch(
              featureName,
              actions.loadMany,
              params,
              injector
            );
          }

          return entities;
        }
      ),
    ],
  };

  constructor(private collectionStore: CollectionStore.Store<T>) {}
}

export function create<T extends Entity>(): Store<T> {
  const collectionStore = CollectionStore.create<T>();
  return new Store<T>(collectionStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
