import { createGetter, createMutation, CustomStore, ListStore, mutate, select } from "@softline/core";

export interface SearchEntry {
  text: string;
  date: string;
}

interface SearchHistoryState {
  history: Record<string, SearchEntry>;
}
export const mutations = {
  addOrUpdate: createMutation<SearchHistoryState, SearchEntry>('searchHistory/addOrUpdate'),
  remove: createMutation<SearchHistoryState, string>('searchHistory/remove')
}

export const actions = {}

export const getters = {
  all: createGetter<SearchHistoryState, SearchEntry[], void>('searchHistory/all'),
}

export const ArtikelSuchverlaufStore: CustomStore<SearchHistoryState, typeof mutations, typeof getters, typeof actions> = {
  mutations,
  actions,
  getters,
  feature: {
    initialState: {
      history: {}
    },
    getters: [
      select(getters.all, ({ state }) => {
        return Object.values(state.history);
      })
    ],
    actions: [],
    mutations: [
      mutate(mutations.addOrUpdate, ({ state, params }) => {
        const history = { ...state.history };
        history[params.text] = params;

        return {
          ...state,
          history
        };
      }),
      mutate(mutations.remove, ({ state, params }) => {
        const history = { ...state.history };

        if (history[params])
          delete history[params];

        return {
          ...state,
          history
        };
      })
    ]
  }
}
