import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Store } from '@softline/core';
import { Subscription } from 'rxjs';
import { VirtualScrollItemDisplay } from '../data/virtual-scroll';
import {
  BreakpointStore,
  SOFTLINE_FEATURE_BREAKPOINTS,
} from '@softline/ui-core';

@Pipe({
  name: 'virtualScrollItemDisplay',
  pure: false,
  standalone: true,
})
export class VirtualScrollItemDisplayPipe implements PipeTransform, OnDestroy {
  private subscription?: Subscription;
  private height?: number;

  constructor(
    private store: Store,
    private changeDetector: ChangeDetectorRef
  ) {}

  transform(
    display: number | VirtualScrollItemDisplay,
    itemHeight: number
  ): number {
    if (typeof display === 'number') return display * itemHeight;

    this.height = display.default * itemHeight;
    if (this.subscription) this.subscription.unsubscribe();

    this.store
      .observe(SOFTLINE_FEATURE_BREAKPOINTS, BreakpointStore.getters.breakpoint)
      .subscribe((o) => {
        let c;
        switch (o) {
          case '2xl':
          case 'xl':
            c =
              display.extraLarge ??
              display.large ??
              display.medium ??
              display.small ??
              display.default;
            break;
          case 'lg':
            c =
              display.large ??
              display.medium ??
              display.small ??
              display.default;
            break;
          case 'md':
            c = display.medium ?? display.small ?? display.default;
            break;
          case 'sm':
            c = display.small ?? display.default;
            break;
          default:
            c = display.default;
            break;
        }
        this.height = c * itemHeight;
        this.changeDetector.markForCheck();
      });
    return this.height;
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }
}
