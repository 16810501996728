import { Inject, Injectable } from '@angular/core';
import {
  PreviewHandler,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  MessageBarStore,
  SOFTLINE_FEATURE_MODAL,
  ModalStore,
} from '@softline/ui-core';
import { SOFTLINE_SERVICE_UUID, Store } from '@softline/core';
import {
  isArchivePreview,
  SOFTLINE_FEATURE_ARCHIVE,
  ArchiveFile,
  ArchiveStore,
} from '@softline/application';
import { PdfViewerDialogComponent } from '../dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { from, map } from 'rxjs';

@Injectable()
export class ArchivePdfPreviewHandler implements PreviewHandler {
  constructor(
    private store: Store,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string
  ) {}

  canOpen(value: unknown): boolean {
    return (
      isArchivePreview(value) &&
      value.file?.metaData?.type === 'application/pdf'
    );
  }

  async open(value: unknown): Promise<void> {
    if (!isArchivePreview(value)) return;
    await this.openPdf(value.file);
  }

  async openPdf(file: ArchiveFile): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open(),
        {
          component: PdfViewerDialogComponent,
          data: {
            document: from(
              this.store.dispatch(
                SOFTLINE_FEATURE_ARCHIVE,
                ArchiveStore.actions.downloadOnce,
                { file }
              )
            ).pipe(map((o) => o.content)),
          },
          dismiss: true,
        }
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#APPLICATION.ARCHIVE.MESSAGES.DOWNLOAD_FAILURE'
      );
    }
  }
}
