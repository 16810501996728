import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StockLocationInfo} from '../../types/item-stock-amount';
import {BehaviorSubject, lastValueFrom} from 'rxjs';
import {handleRequestErrors} from '@softline/application';
import {Store} from '@softline/core';
import {Modal, UiCoreModule} from '@softline/ui-core';
import {GesamtabfrageBewegungenService} from '../../services/gesamtabfrage-bewegungen.service';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {map} from 'rxjs/operators';
import {Item} from '../../types/item';

@Component({
  selector: 'soft-gesamtabfrage-bewegungen-dialog',
  standalone: true,
  imports: [CommonModule, CdkAccordionModule, UiCoreModule],
  templateUrl: './gesamtabfrage-bewegungen-dialog.component.html',
  styleUrls: ['./gesamtabfrage-bewegungen-dialog.component.scss'],
})
export class GesamtabfrageBewegungenDialogComponent implements OnInit, Modal<any> {

  readonly loading$ = new BehaviorSubject(false);
  readonly bewegungen$ = new BehaviorSubject<any[]>([]);

  readonly tabIndex$ = new BehaviorSubject(0);

  readonly summedBewegungen$ = this.bewegungen$.pipe(
    map(bewegungen => {
      return bewegungen.map(bewegung => {
        return {
          ...bewegung,
          sum: {
            menge: bewegung?.childs?.reduce((a, c) => (a + (c?.erfmenge || c?.menge_lg) ?? 0), 0),
            mengeOffen: bewegung?.childs?.reduce((a, c) => (a + (c?.erfmenge_offen || c?.menge_offen_lg) ?? 0), 0),
            einheit: bewegung?.childs[0]?.einheit_lg?.arteh ?? ''
          }
        }
      })
    })
  )

  stockLocation!: StockLocationInfo;
  artikel!: Item;
  close!: (result: any) => void

  constructor(
    private store: Store,
    private gesamtabfrageBewegungenService: GesamtabfrageBewegungenService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadBewegungen(this.stockLocation);
  }

  registerCloseHandler(handler: (result: any) => void) {
    this.close = handler;
  }

  async tabIndexChanged(index: number): Promise<void> {
    if (this.tabIndex$.value === index)
      return;

    this.tabIndex$.next(index);
    await this.loadBewegungen(this.stockLocation, index === 0 ? 'auftraege' : index === 1 ? 'kommscheine' : 'bestellungen');
  }

  private async loadBewegungen(entry: StockLocationInfo, type: 'auftraege' | 'bestellungen' | 'kommscheine' = 'auftraege'): Promise<void> {
    try {
      if (!entry || !entry.stock.name)
        return;

      this.loading$.next(true)

      let bewegungen = []

      switch (type) {
        case 'auftraege':
          bewegungen = await lastValueFrom(this.gesamtabfrageBewegungenService.getAuftraege(this.artikel.number, entry.stock.name));
          break;
        case 'bestellungen':
          bewegungen = await lastValueFrom(this.gesamtabfrageBewegungenService.getBestellungen(this.artikel.number, entry.stock.name));
          break;
        case 'kommscheine':
          bewegungen = await lastValueFrom(this.gesamtabfrageBewegungenService.getKommscheine(this.artikel.number, entry.stock.name));
          break;
      }

      this.bewegungen$.next(bewegungen);
    } catch (e) {
      handleRequestErrors(this.store, e);
    } finally {
      this.loading$.next(false);
    }
  }
}
