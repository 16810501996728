import {
  createGetter,
  createMutation,
  mutate,
  select,
} from '../../../factories';
import * as CollectionStore from '../collection/collection.store';
import { Entity } from '../types/entity';

export interface State<T extends object> {
  details: Partial<T>;
}

export type SetDetailParameters<
  T extends object,
  K extends keyof T = keyof T
> = { name: K; value: T[K] };

export class Store<TEntity extends Entity, T extends object> {
  mutations = {
    setDetail: createMutation<State<T>, SetDetailParameters<T>>('setDetail'),
  };
  getters = {
    details: createGetter<State<T>, T>('details'),
    detail: <K extends keyof T>() => createGetter<State<T>, T[K], K>('detail'),
    entityWithDetails: createGetter<State<T>, TEntity & T, string | number>(
      'entityWithDetails'
    ),
  };

  feature = {
    initialState: {
      details: {},
    },
    mutations: [
      mutate(this.mutations.setDetail, ({ state, params }) => {
        let details = state.details;
        if (!details) details = {};
        details = { ...details, [params.name]: params.value };
        return { ...state, details };
      }),
    ],
    actions: [],
    getters: [
      select(this.getters.details, ({ state, params }) => {
        return state.details;
      }),
      select(this.getters.detail(), ({ state, params }) => {
        const details = state.details;
        if (details === undefined) return undefined;
        return details[params];
      }),
      select(
        this.getters.entityWithDetails,
        ({ state, params, get, featureName }) => {
          const entity = get(
            featureName,
            CollectionStore.getters.entity,
            params
          );
          return { ...entity, ...state.details };
        }
      ),
    ],
  };
}

export function create<TEntity extends Entity, T extends object>(): Store<
  TEntity,
  T
> {
  return new Store<TEntity, T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const feature = instance.feature;
