<div class="modal question-modal">
  <div class="p-4 sm:pl-0 sm:pt-0 text-2xl font-semibold">
    {{ title ?? 'Frage' | translate | interpolate: params }}
  </div>
  <div class="question pr-4 pl-4 pb-4 sm:pl-0">
    {{ question | translate | interpolate: params }}
  </div>
  <p *ngIf="content" class="mb-4 mx-3 border-box">
    <ng-container *ngTemplateOutlet="content; context: (params ?? null)"></ng-container>
  </p>
  <div class="flex no-wrap w-full gap-4 px-4 sm:px-0 pb-4 pt-2">
    <button
      *ngIf="showCancel"
      class="soft-button link cancel h-16 w-full"
      (click)="onClick('CANCEL')"
    >
      {{ '#UI_CORE.DIALOGS.CANCEL' | translate }}
    </button>
    <button
      class="soft-button secondary no h-16 w-full"
      (click)="onClick('NO')"
    >
      {{ '#UI_CORE.DIALOGS.NO' | translate }}
    </button>
    <button
      class="soft-button accent yes sm:ml-3 h-16 w-full"
      (click)="onClick('YES')"
    >
      {{ '#UI_CORE.DIALOGS.YES' | translate }}
    </button>
  </div>
</div>
