import {Injectable} from '@angular/core';
import {ConnectionHttpService, ConnectionResourceLocation} from '@softline/core';
import {Observable} from 'rxjs';
import {SOFTLINE_API_MDE_BESTELLVORSCHLAG, SOFTLINE_API_MDE_BESTELLVORSCHLAG_ABSCHLUSS} from '../bestellvorschlag.api';

@Injectable()
export class BestellvorschlagService {

  private get location(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_BESTELLVORSCHLAG_ABSCHLUSS,
    }
  }

  private get deleteLocation(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_BESTELLVORSCHLAG,
    }
  }

  constructor(private httpService: ConnectionHttpService) {}

  abschluss(): Observable<void> {
    return this.httpService.create(this.location, {});
  }

  delete(): Observable<void> {
    return this.httpService.delete(this.deleteLocation);
  }
}
