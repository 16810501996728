<form
  [formGroup]="form"
  (ngSubmit)="onSubmit(form.value)"
  class="soft-form auto-height no-padding"
>
  <div class="soft-form-row">
    <div class="c column mt-2">
      <label class="soft-label top">{{
        '#TIMESTAMP.FIND.EMPLOYEE_NUMBER' | translate
      }}</label>
      <div *ngIf="!(canModifyEmployee$ | async)" class="soft-input">
        <span *ngIf="form.value.employee as entity">
          <strong>{{ entity.number }}</strong> - {{ entity.firstName }}
          {{ entity.lastName }}
        </span>
      </div>
      <soft-employee-input
        *ngIf="canModifyEmployee$ | async"
        formControlName="employee"
      ></soft-employee-input>
    </div>
    <div class="c row no-wrap">
      <div class="c column mt-2 mr-1">
        <label class="soft-label top">{{
          '#TIMESTAMP.FIND.FROM' | translate
        }}</label>
        <soft-date-input formControlName="from"></soft-date-input>
      </div>
      <div class="c column mt-2 ml-1">
        <label class="soft-label top">{{
          '#TIMESTAMP.FIND.TO' | translate
        }}</label>
        <soft-date-input formControlName="to"></soft-date-input>
      </div>
    </div>
  </div>
  <div class="c row end mt-2">
    <button type="submit" class="soft-button primary mt-2">
      {{ '#TIMESTAMP.FIND.SUBMIT' | translate }}
    </button>
  </div>
</form>
