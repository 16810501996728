import { Dictionary } from '../types/dictionary';
import { Observable } from 'rxjs';
import { ResourceService } from '../data/abstraction';
import { ConnectionResourceLocation } from '../data/specialized/http/connection.location';
import { SOFTLINE_SERVICE_HTTP } from '../core.shared';
import { Inject } from '@angular/core';
import { Entity } from '../store/specialized/entity/types/entity';
import { Patch } from '../store/specialized/entity/types/patch';
import { RequestEvent } from '../data/request';
import { map } from 'rxjs/operators';
import { EntityDetailService } from './entity-detail.service';
import { isDefined } from '../functions/is-defined.function';

export class SoftlineEntityDetailService<T extends object, TCreate = T>
  implements EntityDetailService<T, TCreate>
{
  detailPath = '';

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP)
    protected service: ResourceService<ConnectionResourceLocation>,
    path: string,
    detailPath: string,
    protected manyDetailsPath?: string
  ) {
    if (!path.endsWith('/')) path = path + '/';
    this.detailPath = `${path}{{id}}/${detailPath}`;
  }

  get(
    id: number | string,
    pathParams: Dictionary<unknown> | undefined,
    queryParams: Dictionary<unknown> | undefined,
    body: unknown | undefined
  ): Observable<T> {
    const location = {
      path: this.detailPath,
      pathParams: { ...pathParams, id },
      queryParams,
    };
    return this.service.get<T, unknown>(location, body);
  }

  getMany(
    ids: (number | string)[],
    pathParams: Dictionary<unknown> | undefined,
    queryParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    if (!isDefined(this.manyDetailsPath))
      throw new Error(
        '[SoftlineEntityDetailService] No manyDetailsPath provided in service'
      );
    const location = { path: this.manyDetailsPath, pathParams, queryParams };
    return this.service.get<T, unknown>(location, { ids });
  }

  create(
    id: number | string,
    detail: TCreate,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    const location = {
      path: this.detailPath,
      pathParams: { ...pathParams, id },
    };
    return this.service.create<TCreate, T>(location, detail);
  }

  update(
    id: number | string,
    detail: T,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    const location = {
      path: this.detailPath,
      pathParams: { ...pathParams, id },
    };
    return this.service.update<T, T>(location, detail);
  }

  patch(
    patch: Patch<T>,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    const location = {
      path: this.detailPath,
      pathParams: { ...pathParams, id: patch.id },
    };
    return this.service.patch<T, T>(location, patch.changes);
  }

  delete(
    id: number | string,
    pathParams: Dictionary<unknown> | undefined
  ): Observable<T> {
    const location = {
      path: this.detailPath,
      pathParams: { ...pathParams, id },
    };
    return this.service.delete<T>(location);
  }

  upload(
    id: number | string,
    detail: TCreate,
    pathParams?: Dictionary<unknown>
  ): Observable<RequestEvent<T>> {
    const location = { path: this.detailPath, pathParams };
    return this.service.upload<TCreate, T>(location, detail).pipe(
      map((o) => {
        if (o.type === 'response')
          return { ...o, response: o.response ? { ...o.response, id } : null };
        return o;
      })
    );
  }
}
