<div
  class="soft-modal-content"
  [class.hidden]="hidden"
  [ngClass]="config.class"
  (softClickOutside)="
    dismiss(
      config.dismiss === true || (config.dismiss && config.dismiss.backdrop)
    )
  "
  [delayClickOutsideInit]="true"
  [clickOutsideEvents]="['mousedown']"
>
  <div
    class="closeBtn"
    *ngIf="config.dismiss === true || (config.dismiss && config.dismiss.button)"
  >
    <i
      class="fa-regular fa-xmark modal-dismiss-button"
      (click)="
        dismiss(
          config.dismiss === true || (config.dismiss && config.dismiss.button)
        )
      "
    ></i>
  </div>
  <template #container> </template>
</div>
