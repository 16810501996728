import { createAction, createGetter, createMutation, mutate, select, StoreFeature } from "@softline/core";
import { NfcReadResult } from "./types/nfc-read-result";

export interface State {
  active: boolean;
  available: boolean;
  values: NfcReadResult[];
}

export const mutations = {
  add: createMutation<State, NfcReadResult>('add'),
  clear: createMutation<State>('clear'),
};

export const actions = {
};

export const getters = {
  values: createGetter<State, NfcReadResult[]>('values'),
  latest: createGetter<State, NfcReadResult | null>('latest'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    values: [],
    active: false,
    available: false
  },
  mutations: [
    mutate(mutations.add, ({ state, params }) => {
      return {
        ...state,
        values: [...state.values, params],
      }}),
    mutate(mutations.clear, ({ state }) => ({ ...state, values: [] })),
  ],
  actions: [],
  getters: [
    select(getters.values, ({ state }) => state.values),
    select(getters.latest, ({ state }) => {
      const index = state.values.length - 1;
      if (index === -1)
        return null;
      return state.values[index];
    }),
  ],
};
