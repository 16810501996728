<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item['fahrzeug']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item['bezeichnung']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="text-light">{{item['kennzeichen']}}</span>
  </div>
</div>
