@if(field(); as field) {
  @switch (field.type) {
    @case ('select') {
      {{selectedOption().title}}
    }
    @default {
      {{value() | format:format()}}
    }
  }
}

