<div class="flex flex-col min-h-full" #container>
  @if (!loading()) {
    <div class="flex items-center justify-between">
      <soft-paginator
        class="mt-1"
        [page]="currentPage()"
        [pageSize]="itemsPerPage()"
        [elements]="data().length"
        [pageSizes]="[10, 20, 30, 50]"
        (pageChange)="pageChanged($event)"
        (pageSizeChange)="itemsPerPage.set($event)">
      </soft-paginator>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex items-center justify-end w-full">
        <div class="flex items-center gap-4 justify-end">
          <div class="flex items-center gap-1 text-sm">
            <span class="w-3 h-3 rounded bg-primary"></span>
            {{ '#UMSATZSTATISTIK.PAGES.RESULT.DIAGRAM.LEGEND.NETTO' | translate }}
          </div>
          <div class="flex items-center gap-1 text-sm">
            <span class="w-3 h-3 rounded bg-accent"></span>
            {{ '#UMSATZSTATISTIK.PAGES.RESULT.DIAGRAM.LEGEND.DB' | translate }}
          </div>
        </div>
      </div>
    </div>
  } @else {
    <div class="flex items-center justify-end w-full my-1.5">
      <div class="flex items-center gap-4 justify-end w-full">
        <div class="w-40 h-5 skeleton">&nbsp;</div>
      </div>
    </div>
  }
  <div [ngClass]="chartClass() ? chartClass() + '' : 'min-h-[50vh] max-h-[50vh]'" class="overflow-y-auto">
    @if (!loading()) {
      @if (activeItems(); as chartData) {
        <ngx-charts-bar-horizontal-2d
          [view]="$any(chartData && chartData.length > 5 ? [(container?.offsetWidth || 124) - 30, ((chartData?.length || 1) * 44)] : undefined)"
          [results]="chartData"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="false"
          [barPadding]="2"
          [noBarWhenZero]="false"
          [showGridLines]="true"
          [groupPadding]="6"
          [xAxisTickFormatting]="formatValue"
          [dataLabelFormatting]="formatValue"
          [xScaleMax]="maxXAxisValue()"
          [scheme]="$any(chartColorScheme)"
          (select)="onSelect($event, chartData)">
          <ng-template #tooltipTemplate let-model="model">
            <div class="flex flex-col p-2">
              <span class="text-sm font-semibold pb-1 text-left border-b border-b-gray-200">{{ model?.series }}</span>
              <div class="flex items-center gap-1 mt-0.5">
                <span class="text-sm">{{ model?.name }}:</span>
                <span class="text-sm">{{ model?.value | currencyFormat }}</span>
              </div>
              <div class="flex items-center gap-1 opacity-60">
                <span class="text-sm">DB in Prozent:</span>
                <span class="text-sm">{{ ((model?.extra?.dbproz || 1) / 100) | percent:'1.2-2' }}</span>
              </div>
            </div>
          </ng-template>
        </ngx-charts-bar-horizontal-2d>
      }
    } @else {
      <div [ngClass]="chartClass() ? chartClass() + '' : 'min-h-[50vh] max-h-[50vh]'" class="flex w-full skeleton">&nbsp;</div>
    }
  </div>
</div>
