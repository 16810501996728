<ng-container *ngIf="(strategy.items$ | async) as items; else notFoundTemplate">
  <ng-container *ngIf="(items.length > 0 || strategy.loading()); else notFoundTemplate">
    <div class="flex flex-col sm:flex-col-reverse">
      <div class="flex flex-col max-h-[70vh] overflow-y-auto">
        <soft-master-detail class="h-[70vh]"
                            [loading]="strategy.loading()"
                            [data]="items"
                            [masterTemplate]="masterTemplate"
                            [detailTemplate]="detailTemplate"
                            [detailPlaceholder]="detailPlaceholder"
                            (masterDblClick)="strategy.resolve($event)"
                            #masterDetail>
        </soft-master-detail>
        <ng-template let-data='data' #masterTemplate>
          <div class="mx-2">
            <ng-container *ngComponentOutlet="config.masterComponent; inputs: {item: data}"></ng-container>
          </div>
        </ng-template>
        <ng-template let-data='data'  #detailTemplate>
          <div class="sm:hidden soft-link my-2">
            <a (click)="masterDetail.selectedIndex = -1">
              <div class="flex flex-row flex-nowrap items-center content-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
                Zurück
              </div>
            </a>
          </div>
          <div class="h-[65vh] overflow-y-auto m-2">
            <ng-container *ngComponentOutlet="config.detailComponent; inputs: {item: data}"></ng-container>
          </div>
          <button class="soft-button sticky bottom-0 accent w-full h-16 mt-2" (click)="strategy.resolve(data)">Auswählen</button>
        </ng-template>
        <ng-template #detailPlaceholder>
          <div class="w-full flex flex-col items-center mt-16 text-light">
            Noch nichts ausgewählt
          </div>
        </ng-template>

      </div>
      <soft-filter-and-sort *ngIf="masterDetail.view !== 'detail'"
                            class="block mx-2 mt-2 mb-2 sm:mb-0"
                            [filter]="(filter$ | async) ?? null"
                            [filterConfig]="config.filter"
                            [sort]="(sort$ | async) ?? null"
                            [sortConfig]="config.sort"
                            [values]="items"
                            (filterChange)="onFilterChange($event)"
                            (sortChange)="onSortChange($event)">
      </soft-filter-and-sort>
    </div>
  </ng-container>
</ng-container>
<ng-template #notFoundTemplate>
  <div *ngIf="!strategy.loading()"
       class="flex flex-col text-light text-lg items-center content-center my-4">
    <span>Es wurden keine Daten gefunden</span>
  </div>
</ng-template>
