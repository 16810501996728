import { Component, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Observable, Subscription } from "rxjs";
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule } from "@softline/ui-core";
import { Store } from "@softline/core";
import { Platform } from "@angular/cdk/platform";
import { AppInfoStore, SOFTLINE_FEATURE_APP_INFO } from "@softline/application";
import { PwaInstallService } from "../../services/pwa-install.service";
import { SOFTLINE_FEATURE_PWA_INSTALL } from "../../../pwa.shared";
import { PwaInstall, PwaInstallStore } from "../../store";
import { Router } from "@angular/router";

export interface RelatedApp {id: string, platform: string, url: string}

@Component({
  selector: 'soft-pwa-install-settings',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './pwa-install-settings.component.html',
  styleUrls: ['./pwa-install-settings.component.scss']
})
export class PwaInstallSettingsComponent implements OnInit, OnDestroy {
  private subscription?: Subscription;

  install$: Observable<PwaInstall> = this.store.observe(SOFTLINE_FEATURE_PWA_INSTALL, PwaInstallStore.getters.data);

  constructor(private store: Store,
              private installService: PwaInstallService,
              public platform: Platform,
              public router: Router) { }

  async ngOnInit(): Promise<void> {
  }

  ngOnDestroy(): void {
  }

  async registerSW(): Promise<void> {
    await this.installService.registerNgServiceWorker();
  }

  async unregisterSW(): Promise<void> {
    await this.installService.unregisterNgServiceWorker();
  }

  async installApp(): Promise<void> {
    const result = await this.installService.installApp();
    if(!result) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.info,
        '#PWA.INSTALL.MESSAGES.INFO.APP_NOT_INSTALLED');
      return;
    }
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      '#PWA.INSTALL.MESSAGES.SUCCESS.APP_INSTALLED');
    await this.router.navigate(['/']);
  }

  async share(): Promise<void> {
    const title = this.store.get(SOFTLINE_FEATURE_APP_INFO, AppInfoStore.getters.name);
    const url = document.location.href;
    if(navigator.canShare({title, url}))
      await navigator.share({title, url})
  }

}
