import { Inject, Injectable } from "@angular/core";
import { ConnectionHttpService, ConnectionResourceLocation, ResponseResult, SOFTLINE_SERVICE_HTTP } from "@softline/core";
import { Observable, map, tap } from "rxjs";
import { SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN, SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_ABSCHLUSS, SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_BARCODE, SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_EINLESEN } from "../materiallieferschein-scan.api";
import { MateriallieferscheinScanKopf } from "../types/materiallieferschein-scan-kopf";
import { Scan } from "@softline/application";
import { MateriallieferscheinScanResult } from "../types/materiallieferschein-scan-result";


@Injectable()
export class MateriallieferscheinScanService {

  private location(idkopflf: number): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_ABSCHLUSS,
      pathParams: { idkopflf }
    }
  }

  private kopfLocation(idkopflf: number): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN + '/{{idkopflf}}',
      pathParams: { idkopflf }
    }
  }

  private materiallieferscheinScanLocation(code: string): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_BARCODE,
      pathParams: { code }
    }
  }

  private einlesenLocation(idkopflf: number): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_MATERIALLIEFERSCHEIN_SCAN_EINLESEN,
      pathParams: { idkopflf }
    }
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService
  ) {}

  abschluss(idkopf: number, payload: object): Observable<void> {
    return this.httpService.create(this.location(idkopf), payload);
  }

  removeBeleg(kopfId: number): Observable<unknown> {
    return this.httpService.delete<ResponseResult<MateriallieferscheinScanKopf>>(
      this.kopfLocation(kopfId)
    );
  }

  /*
  loadKopf(): Observable<MateriallieferscheinScanKopf | undefined | null> {
    return this.httpService.get<ResponseResult<MateriallieferscheinScanKopf | undefined | null>>(this.kopfLocation).pipe(
      map(o => o?.result)
    );
  } */

  materiallieferscheinScan(scan: Scan): Observable<MateriallieferscheinScanResult> {
    const location = this.materiallieferscheinScanLocation(scan.data);

    return this.httpService
      .get<ResponseResult<MateriallieferscheinScanResult>>(location)
      .pipe(map(o => o?.result));
  }

  einlesen(payload: object & { id: number }): Observable<MateriallieferscheinScanKopf | undefined | null> {
    const location = this.einlesenLocation(payload.id);

    return this.httpService
      .create<object, ResponseResult<MateriallieferscheinScanKopf>>(location, payload)
      .pipe(map(o => o?.result));
  }
}
