
<div class="flex flex-col md:flex-row md:justify-between items-start">
  <mbsc-datepicker [locale]="localeDe"
                   [showWeekNumbers]="true"
                   [value]="value()"
                   (onCellClick)="onCellClick($event.date)"
                   theme="windows"
                   class="w-full"
                   display="inline"
                   returnFormat="iso8601"
                   #datepicker>
  </mbsc-datepicker>

  <div class="flex w-full flex-col md:mt-3 mb-1 pb-2 md:w-2/3">
    <h6 class="soft-overline mb-1">Shortcuts</h6>
    <ul>
      <li class="py-3 px-2 border-t cursor-pointer hover:bg-hover" (click)="selectShortcut({ value: 'h', label: '' })">Heute</li>
      <li class="md:hidden py-3 px-2 border-t cursor-pointer hover:bg-hover" (click)="openShortcutsList()">Weitere Shortcuts</li>
      <div class="hidden md:flex md:flex-col">
        @for (shortcut of shortcuts; track shortcut.value) {
          <li class="py-3 px-2 border-t cursor-pointer hover:bg-hover" (click)="selectShortcut(shortcut)">{{ shortcut.label | translate }}</li>
        }
      </div>
    </ul>
  </div>
</div>

