import { Mitarbeiter } from "./mitarbeiter";
import { PersonalDetail } from "./personal-detail";

export interface AbteilungPersonalUebersicht {
  abteilung: Abteilung;
  anwesend: number;
  mitarbeiterGesamt: number;
  mitarbeiter: PersonalUebersicht[]
}

export interface Abteilung {
  id: number;
  bezeichnung: string;
}

export interface LstFix {
  id: number;
  persnr: number;
  vorname: string;
  zuname: string;
}

export interface LetzterStempel {
  kzkg: string;
  zeit: Date;
}

export interface AktuellerDpEintrag {
  vonzeit: Date;
  biszeit: Date;
  taetigkeit: string;
}

export interface AktuellerTermin {
  vonzeit: Date;
  biszeit: Date;
  taetigkeit: string;
}

export interface Abwesenheit {
  bezeichnung: string;
  bisdatum: Date;
}

export interface PersonalUebersicht {
  id: number;
  lstfix: LstFix;
  letzterStempel: LetzterStempel;
  aktuellerDpEintrag: AktuellerDpEintrag;
  aktuellerTermin: AktuellerTermin;
  abwesenheit: Abwesenheit;
  status: string;
  anwender: Mitarbeiter;
  abteilung: Abteilung;
}

export interface AbteilungUebersicht {
  id: number;
  abteilung: Abteilung;
  status: {
    anwesend: number | null;
    abwesendrot: number | null;
    abwesendgelb: number | null;
    unbekannt?: number | null;
  }
}
