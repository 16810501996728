import {
  ActionStore,
  createAction,
  createGetter,
  on,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore,
  SyncedRemoteCollectionStore
} from '@softline/core';
import {concat, lastValueFrom, tap, toArray} from 'rxjs';
import {map} from 'rxjs/operators';
import {ArtikelLagerMengenService, LoadStockParameters} from '../services/artikel-lager-mengen.service';
import {ArtikelLagerMenge} from '../types/artikel-lager-menge';
import {SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE} from '../artikel.shared';

export type ArtikelLagerMengenState = SyncedRemoteCollectionStore.State<ArtikelLagerMenge>;
const collectionStore = SyncedRemoteCollectionStore.create<ArtikelLagerMenge>();

interface LoadManyStocksParallelParams {
  ids: number[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ArtikelLagerMengenStore {

  export const mutations = {
    ...collectionStore.mutations
  };

  export const actions = {
    ...collectionStore.actions,
    loadManySequential: createAction<ArtikelLagerMengenState, LoadManyStocksParallelParams, ArtikelLagerMenge[]>('load many sequential'),
    load: createAction<ArtikelLagerMengenState, { id: number }, ArtikelLagerMenge>('load one')
  };

  export const getters = {
    ...collectionStore.getters,
    total: createGetter<ArtikelLagerMengenState, number>('item stocks total'),
  };

  export const feature: StoreFeature<ArtikelLagerMengenState> = {
    initialState: {
      ...collectionStore.feature.initialState,
    },
    mutations: [
      ...collectionStore.feature.mutations
    ],
    actions: [
      ...collectionStore.feature.actions,
      on(actions.loadManySequential, async ({featureName, injector, commit, params}) => {
        const service = injector.get(ArtikelLagerMengenService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const sequenceSize = injector.get(SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE);

        let requests: LoadStockParameters[] = [];

        while (params.ids.length > 0) {
          const size = Math.min(params.ids.length, sequenceSize);
          requests = [...requests, {ids: params.ids.splice(0, size)}];
        }

        const sub$ = concat(...requests.map(payload => service.loadMany(payload))).pipe(
          map(o => o.map(o => ({ ...o, id: o?.itemId }))),
          tap(o => commit(featureName, mutations.addOrUpdateMany, o)),
          toArray(),
          map((responses) => responses.map(o => o).reduce((a, b) => ([...a, ...b]))),
        );

        return await lastValueFrom(ActionStore.handleObservableActionState(
          SubscriptionStore.handleSubscriptionState(sub$, featureName, commit, token),
          featureName,
          commit,
          actions.loadManySequential.name,
          token
        ));
      }),
      on(actions.load, async ({ injector, params, featureName, commit}) => {
        const service = injector.get(ArtikelLagerMengenService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const sub$ = service.load(params.id).pipe(map(o => ({ ...o, id: o?.itemId })));
        const subState$ = SubscriptionStore.handleSubscriptionState(sub$, featureName, commit, token);
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subState$,
            featureName,
            commit,
            actions.load.name,
            token
          )
        );

        if (result) {
          commit(
            featureName,
            mutations.addOrUpdate,
            result
          );
        }

        return result
      })
    ],
    getters: [
      ...collectionStore.feature.getters,
    ]
  };
}
