import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { equals } from '@softline/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'soft-master-detail',
  templateUrl: './master-detail.component.html',
  styleUrls: ['./master-detail.component.scss'],
})
export class MasterDetailComponent<T> implements OnInit, OnDestroy {
  private subscription?: Subscription;
  private indexSubject$ = new BehaviorSubject(-1);

  @Input() data: T[] = [];

  @Input()
  get selected(): T | undefined {
    return this._selected;
  }
  set selected(value: T | undefined) {
    this._selected = value;
    this._selectedIndex = this.data.findIndex((o) => equals(o, value));
    this.indexSubject$.next(this._selectedIndex);
  }
  private _selected: T | undefined;

  @Output() selectedChange = new EventEmitter<T | undefined>();

  @Input()
  get selectedIndex(): number {
    return this._selectedIndex;
  }
  set selectedIndex(value: number) {
    this._selectedIndex = value;
    this._selected = this.data[value];
    this.indexSubject$.next(this._selectedIndex);
  }
  private _selectedIndex = -1;
  @Output() selectedIndexChange = new EventEmitter<number>();

  @Input() masterTemplate: TemplateRef<any> | null = null;
  @Input() detailTemplate: TemplateRef<any> | null = null;
  @Input() detailPlaceholder: TemplateRef<any> | null = null;

  @Input() loading: boolean = false;
  @Input() masterSkeleton: TemplateRef<any> | null = null;
  @Input() skeletonRows: number = 7;

  @Input() maxItems: number = Number.POSITIVE_INFINITY;
  @Input() moreItemsLabel = '#UI_CORE.MASTER_DETAIL.MAX_ITEMS';

  page = 1;

  private _view: 'master' | 'detail' | 'both' = 'master';
  get view(): 'master' | 'detail' | 'both' {
    return this._view;
  }
  @Output() viewChange = new EventEmitter<'master' | 'detail' | 'both'>();

  @Output() masterDblClick = new EventEmitter<T>();
  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.subscription = combineLatest([
      this.breakpointObserver.observe(Breakpoints.XSmall),
      this.indexSubject$,
    ]).subscribe(([breakpoint, index]) => {
      const view = this._view;
      this._view = !breakpoint.matches
        ? 'both'
        : index > -1
        ? 'detail'
        : 'master';
      if (view !== this._view) this.viewChange.emit(this._view);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  select(index: number): void {
    this._selectedIndex = index;
    this._selected = this.data[index];
    this.indexSubject$.next(this._selectedIndex);

    this.selectedChange.emit(this.selected);
    this.selectedIndexChange.emit(this._selectedIndex);
  }

  onMoreItems(): void {
    this.page = this.page + 1;
  }

  // Um beim Pull To refresh Container nicht voreilig neu zu laden (wenn der untere scrollcontainer
  // irgendwo in der mitte steht der vom parent aber ganz oben) darf das touchstart event
  // nicht weitergegeben werden ausser der untere scrollcontainer ist ganz oben.
  preventScrolling(event: TouchEvent, container: HTMLDivElement): void {
    if (container.scrollTop > 0)
      event.stopPropagation();
  }
}
