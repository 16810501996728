import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store, isDefined, SOFTLINE_FEATURE_LANGUAGE, LanguageStore } from "@softline/core";
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const language = this.store.get(
      SOFTLINE_FEATURE_LANGUAGE,
      LanguageStore.getters.selected
    );
    if (!language)
      return next.handle(request);

    request = request.clone({
      setHeaders: {
        'Accept-Language': language.code ?? ''
      },
    });
    return next.handle(request);
  }
}
