import { Dictionary } from '../../types/dictionary';
import { createGetter, createMutation, mutate, select } from '../factories';
import { StoreFeature } from '../store';

export interface State<T> {
  dictionary: Dictionary<T | null>;
}

export class Store<T> {
  mutations = {
    set: createMutation<State<T>, { key: string, value: T }>('set'),
    remove: createMutation<State<T>, string>('remove'),
    clear: createMutation<State<T>>('clear'),
  };
  getters = {
    field: createGetter<State<T>, T | null | undefined, string>('field'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      dictionary: {}
    },
    mutations: [
      mutate(this.mutations.set, ({ state, params }) => ({
        ...state,
        dictionary: {...state.dictionary, [params.key]: params.value},
      })),
      mutate(this.mutations.remove, ({ state, params }) => ({
          ...state,
          [params]: null,
      })),
      mutate(this.mutations.clear, ({ state, params }) => ({
        ...state,
        dictionary: {}
      })),
    ],
    getters: [
      select(this.getters.field, ({ state, params }) => state.dictionary[params]),
    ],
    actions: [],
  };
}

export function create<T extends unknown>(): Store<T> {
  return new Store<T>();
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const feature = instance.feature;
