<ng-container
  *ngFor="
    let widget of widgets
      | filter: outletFilter:name
      | filter: permissionFilter:(permissions$ | async)
      | sort: 'priority'
  "
>
  <ng-container
    *ngComponentOutlet="
      widget.component;
      injector: injector
        | widgetOutletInjector: widget.providers ?? []:contextProviders
    "
  ></ng-container>
</ng-container>
