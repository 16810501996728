@if (kontoInformationen$ | async; as kontoInformationen) {
  <div class="flex flex-col w-full soft-card">
    <div class="flex flex-row w-full pb-0 justify-between items-center"
         [ngClass]="{'pb-2 pl-2 lg:pb-0 lg:pl-0': alwaysExpanded}">
      <div class="flex text-xl font-bold w-full">
        <span>{{ kontoInformationen?.konto?.nummer ?? ''}} - {{ ((kontoInformationen?.konto?.kurzbez?.length ?? 0) > 0) ? kontoInformationen?.konto?.kurzbez : '' }}</span>
      </div>
      @if (!alwaysExpanded) {
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="flex fa-solid text-primary fill-current fa-arrow-up-right-from-square w-4 h-min cursor-pointer lg:hidden" (click)="openInfoDialog(kontoInformationen)">
          <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/>
        </svg>
      }
    </div>
    <div class="flex flex-row w-full" [ngClass]="{'flex-col lg:flex-row': alwaysExpanded}">
      <div class="flex flex-col gap-0.5 w-full pt-0 lg:border-r border-lighter border-solid lg:pt-2"
           [ngClass]="{'border-r-0 pl-2 pb-2 lg:pl-0 lg:pb-0': alwaysExpanded}">
        <div class="flex flex-row w-full">
          @if (kontoInformationen?.konto?.name1 && kontoInformationen.konto.name1.length > 0) {
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_KUNDE' | translate }} {{ ((kontoInformationen?.konto?.name1?.length ?? 0) > 0) ? kontoInformationen?.konto?.name1 : '' }}</span>
          }
          @if(kontoInformationen?.konto?.name2 && kontoInformationen.konto.name2.length > 0) {
          <span>, {{ kontoInformationen?.konto?.name2}}</span>
          }
          @if(kontoInformationen?.konto?.name3 && kontoInformationen.konto.name3.length > 0) {
          <span>, {{ kontoInformationen?.konto?.name3}}</span>
          }
        </div>
        <div class="flex flex-row w-full">
          @if (kontoInformationen?.konto?.strasse && kontoInformationen.konto.strasse.length > 0) {
          <span>{{ kontoInformationen?.konto?.strasse}}</span>
          }
          @if(kontoInformationen?.konto?.plz && kontoInformationen.konto.plz.length > 0) {
          <span>, {{ kontoInformationen?.konto?.plz}}</span>
          }
          @if(kontoInformationen?.konto?.ort && kontoInformationen.konto.ort.length > 0) {
          <span>, {{ kontoInformationen?.konto?.ort}}</span>
          }
        </div>
        <div class="flex flex-row w-full gap-4">
          @if (kontoInformationen?.konto?.telefonFa && kontoInformationen.konto.telefonFa.length > 0) {
          <div class="flex flex-row w-auto items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="flex-shrink-0 w-4 h-4">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
            </svg>
            <a class="ml-2 soft-link" [href]="'tel:' + kontoInformationen?.konto?.telefonFa">
              {{ kontoInformationen?.konto?.telefonFa }}
            </a>
          </div>
          }
          @if(kontoInformationen?.konto?.handyFa && kontoInformationen.konto.handyFa.length > 0) {
          <div class="flex flex-row w-auto items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="flex-shrink-0 w-4 h-4">
              <path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"/>
            </svg>
            <a class="ml-2 soft-link" [href]="'tel:' + kontoInformationen?.konto?.handyFa">
              {{ kontoInformationen?.konto?.handyFa }}
            </a>
          </div>
          }
        </div>
        @if (kontoInformationen?.konto?.mail1 && kontoInformationen.konto.mail1.length > 0) {
          <div class="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="flex-shrink-0 w-4 h-4">
              <path d="M256 8C118.9 8 8 118.9 8 256c0 137.1 110.9 248 248 248 48.2 0 95.3-14.1 135.4-40.2 12-7.8 14.6-24.3 5.6-35.4l-10.2-12.4c-7.7-9.4-21.2-11.7-31.4-5.1C325.9 429.8 291.3 440 256 440c-101.5 0-184-82.5-184-184S154.5 72 256 72c100.1 0 184 57.6 184 160 0 38.8-21.1 79.7-58.2 83.7-17.3-.5-16.9-12.9-13.5-30l23.4-121.1C394.7 149.8 383.3 136 368.2 136h-45a13.5 13.5 0 0 0 -13.4 12l0 .1c-14.7-17.9-40.4-21.8-60-21.8-74.6 0-137.8 62.2-137.8 151.5 0 65.3 36.8 105.9 96 105.9 27 0 57.4-15.6 75-38.3 9.5 34.1 40.6 34.1 70.7 34.1C462.6 379.4 504 307.8 504 232 504 95.7 394 8 256 8zm-21.7 304.4c-22.2 0-36.1-15.6-36.1-40.8 0-45 30.8-72.7 58.6-72.7 22.3 0 35.6 15.2 35.6 40.8 0 45.1-33.9 72.7-58.2 72.7z"/>
            </svg>
            <a class="ml-2 soft-link" [href]="'mailto:' + kontoInformationen?.konto?.mail1">
              {{ kontoInformationen?.konto?.mail1 ?? ''}}
            </a>
          </div>
          <div class="flex flex-row w-full"></div>
        }
      </div>
      <div class="flex-col gap-0.5 w-full p-2 border-r border-lighter border-solid hidden lg:flex xl:px-4"
           [ngClass]="{'!flex border-r-0 pt-2 border-t lg:mt-0 lg:border-r lg:border-t-0 lg:pt-2': alwaysExpanded}">
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_SALDO_FAELLIG' | translate }}</span>
          <span>{{ (kontoInformationen?.saldoFaellig | number:'.2') ?? '-' }}</span>
        </div>
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_ZAHLART' | translate }}</span>
          <span>{{ ((kontoInformationen?.zahlart?.bezeichnung?.length ?? 0) > 0) ? kontoInformationen?.zahlart?.bezeichnung : '-'}}</span>
        </div>
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_ZAHLKOND' | translate }}</span>
          <span>{{ ((kontoInformationen?.zahlkond?.zahlkondinfo?.length ?? 0) > 0) ? kontoInformationen?.zahlkond?.zahlkondinfo : '-'}}</span>
        </div>
      </div>
      <div class="flex-col gap-0.5 w-full p-2 border-r border-lighter border-solid hidden lg:flex xl:px-4"
           [ngClass]="{'!flex border-r-0 pt-2 border-t lg:mt-0 lg:border-r lg:border-t-0 lg:pt-2': alwaysExpanded}">
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_KREDITLIMIT' | translate }}</span>
          <span>{{ (kontoInformationen?.kreditlimit | number:'.2') ?? '-' }}</span>
        </div>
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_KREDITLIMIT2' | translate }}</span>
          <span>{{ (kontoInformationen?.kreditlimit2 | number:'.2') ?? '-' }}</span>
        </div>
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_VERSICHERUNGSLIMIT' | translate }}</span>
          <span>{{ (kontoInformationen?.versicherungslimit | number:'.2') ?? '-'}}</span>
        </div>
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_VERTRETER' | translate }}</span>
          <span>{{ ((kontoInformationen?.vertreter?.bezeichnung?.length ?? 0) > 0) ? kontoInformationen?.vertreter?.bezeichnung : '-'}}</span>
        </div>
      </div>
      <div class="flex-col gap-0.5 w-full p-2 hidden lg:flex xl:pl-4"
           [ngClass]="{'!flex border-r-0 pt-2 pb-0 border-t border-lighter lg:border-t-0': alwaysExpanded}">
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_IBAN' | translate }}</span>
          <span>{{ ((kontoInformationen?.iban?.length ?? 0) > 0) ? kontoInformationen?.iban : '-'}}</span>
        </div>
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_BIC' | translate }}</span>
          <span>{{ ((kontoInformationen?.swiftcode?.length ?? 0) > 0) ? kontoInformationen?.swiftcode : '-'}}</span>
        </div>
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_KONTONUMMER' | translate }}</span>
          <span>{{ ((kontoInformationen?.kontonummer?.length ?? 0) > 0) ? kontoInformationen?.kontonummer : '-'}}</span>
        </div>
        <div class="flex flex-row w-full justify-between">
          <span>{{ '#KUNDENINFO_LEISTE.LABEL_BLZ' | translate }}</span>
          <span>{{ ((kontoInformationen?.bankleitzahl ?? 0) !== 0) ? kontoInformationen?.bankleitzahl : '-'}}</span>
        </div>
      </div>
    </div>
  </div>
} @else if (loading()) {
  <div class="flex flex-col soft-card w-full">
    <div class="skeleton rounded-s w-[500px] min-h-[28px] ml-0.5 pt-2"></div>
    <div class="flex flex-row w-full">
      <div class="skeleton rounded-s w-[330px] min-h-[100px] ml-0.5 mt-3 mr-3" *softRepeat="3"></div>
      <div class="skeleton rounded-s w-[330px] min-h-[100px] mt-3"></div>
    </div>
  </div>
} @else {
  <div class="flex flex-col soft-card w-full min-h-[80px]">
    <span class="m-auto text-sm text-light">
      {{ '#KUNDENINFO_LEISTE.LABEL_KEINE_DATEN' | translate }}
    </span>
  </div>
}
