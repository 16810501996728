<soft-page>
  <soft-page-header>
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center">
        <ng-container
          *ngTemplateOutlet="header()?.template() ?? null"
        ></ng-container>
      </div>
      @if (shortcuts().length > 0) {
        <soft-query-page-shortcuts [shortcuts]="shortcuts()"
                                   (shortcutClick)="onShortcutClick($event)">
        </soft-query-page-shortcuts>
      }
    </div>
  </soft-page-header>
  <ng-content></ng-content>
</soft-page>
