import {InjectionToken} from '@angular/core';

export const SOFTLINE_PERMISSION_ARTIKEL_INFO = 'artikel-info';

// TOKENS
export const SOFTLINE_CONFIG_ARTIKEL_INFO_DETAIL_SEQUENCE_SIZE = new InjectionToken<number>(
  'SOFTLINE_CONFIG_ARTIKEL_INFO_DETAIL_SEQUENCE_SIZE'
);

// STORE
export const SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM = 'artikelInfoItem';
export const SOFTLINE_FEATURE_LOCAL_SEARCH_HISTORY = 'artikelInfoLocalSearchHistory';
export const SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICE = 'artikelInfoItemPrice';
export const SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICELIST = 'artikelInfoItemPricelist';
export const SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_STOCK = 'artikelInfoItemStock';
export const SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_DETAIL = 'artikelInfoItemDetail';
export const SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE = 'artikelInfoUmsatzabfrage';
export const SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_LISTS = 'artikelInfoFavoritenListen';
export const SOFTLINE_FEATURE_ARTIKEL_INFO_FAVORITE_ITEMS = 'artikelInfoFavoritenArtikel';
export const SOFTLINE_FEATURE_ARTIKEL_INFO_SCAN_LISTE = 'artikelInfoScanListe';
