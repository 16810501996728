import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChildren,
  effect,
  EventEmitter,
  forwardRef,
  inject,
  input, output,
  Output,
  signal
} from '@angular/core';
import { CommonModule } from "@angular/common";
import { IconComponent } from "../../icon/icon.component";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Store } from "@softline/core";
import * as ModalStore from "../../../modal/modal.store";
import { SOFTLINE_FEATURE_MODAL } from "../../../modal/modal.shared";
import { SelectBoxOptionDirective } from "./select-box-option.directive";
import { I18nModule } from "../../../i18n/i18n.module";

@Component({
  selector: 'soft-select-box',
  standalone: true,
  imports: [CommonModule, IconComponent, I18nModule],
  templateUrl: './select-box.component.html',
  styleUrl: './select-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectBoxComponent),
      multi: true,
    },
  ],
})
export class SelectBoxComponent {

  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  store = inject(Store);
  icon = input<string | undefined>();
  title = input.required<string>();
  options = contentChildren(SelectBoxOptionDirective);

  inputValue = input<any>(null, {alias: 'value'});
  value = signal<any>(null);
  valueChange = output<any>();

  option = computed(() => {
    const value = this.value();
    return this.options().find((option: SelectBoxOptionDirective) => option.value === value);
  });

  inputValueEffect= effect(() => {
    this.value.set(this.inputValue());
  }, {allowSignalWrites: true})

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  async onClick(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.choose(),
      {
        title: this.title(),
        options: this.options().map(o => o),
        dismiss: true
      });
    if(result === 'DISMISSED')
      return;
    this.value.set(result);
    this.valueChange.emit(result);
  }

}
