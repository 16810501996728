<div class="soft-card rounded-[6px] flex flex-row w-full mitarbeiters-center justify-between items-center relative cursor-pointer"
     [ngClass]="mode === 'selection' && selected ? 'border border-solid border-2 border-primary' : 'border-solid border-2 border-transparent'"
     (click)="tapped.emit()">
  <div class="flex mitarbeiters-center top-0 bottom-0 left-0 right-0 absolute rounded overflow-hidden">
    <div class="flex h-full m-0 w-2"
         [ngClass]="{'bg-success-500': mitarbeiter.status === 'A',
                          'bg-warning-500': mitarbeiter.status === 'E',
                          'bg-danger-500': mitarbeiter.status === 'U'}">
    </div>
  </div>

  <div class="w-full flex flex-row z-10 ml-2.5">
    <div class="flex flex-col w-full m-0 justify-start py-1.5">
      <div class="flex flex-col w-full mitarbeiters-start">
        <div class="text-lg font-semibold">{{mitarbeiter.lstfix.persnr}} - {{mitarbeiter.lstfix.vorname}} {{mitarbeiter.lstfix.zuname}}</div>
      </div>
      <div class="flex flex-col mitarbeiters-start w-full">
        <div class="mt-0 text-base text-light" *ngIf="mitarbeiter.letzterStempel">
          {{mitarbeiter.letzterStempel.kzkg === 'K' ? 'Kommt' : 'Geht' }} um {{mitarbeiter.letzterStempel.zeit | format:"TIME.DEFAULT" }}
        </div>
        <div class="mt-0 text-base text-light" *ngIf="mitarbeiter.aktuellerTermin">
          {{mitarbeiter.aktuellerTermin.vonzeit | format:"TIME.DEFAULT" }} - {{mitarbeiter.aktuellerTermin.biszeit | format:"TIME.DEFAULT" }} {{mitarbeiter.aktuellerTermin.taetigkeit}}
        </div>
        <div class="mt-0 text-base text-light" *ngIf="mitarbeiter.aktuellerDpEintrag">
          {{mitarbeiter.aktuellerDpEintrag.vonzeit | format:"TIME.DEFAULT" }} - {{mitarbeiter.aktuellerDpEintrag.biszeit | format:"TIME.DEFAULT" }} {{mitarbeiter.aktuellerDpEintrag.taetigkeit}}
        </div>
        <div class="mt-0 text-base text-light" *ngIf="mitarbeiter.abwesenheit && mitarbeiter.abwesenheit.bezeichnung === 'Urlaub'">
          {{mitarbeiter.abwesenheit.bezeichnung}} bis {{mitarbeiter.abwesenheit.bisdatum | date:'dd.MM.yyyy'}}
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="mode === 'display'">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 opacity-25">
      <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
  </ng-container>

  <ng-container *ngIf="mode === 'selection' && selected">
    <div class="bg-surface rounded-full border border-solid border-primary border-2 p-1 z-10 flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-5 h-5 text-primary">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
      </svg>
    </div>
  </ng-container>
</div>
