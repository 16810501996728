import { Step } from "@softline/application";


export const SOFTLINE_PERMISSION_MDE_MATERIALLIEFERSCHEIN_SCAN = "MATERIALLIEFERSCHEINSCAN";

export const SOFTLINE_CONFIG_MDE_MATERIALLIEFERSCHEIN_SCAN_STEPS: Step[] = [
  {
    header: 'Artikel suchen',
    subHeader: 'Bitte gewünschten Artikel auswählen',
  },
  {
    header: 'Artikeldaten',
    subHeader: 'Bitte passen Sie die Artikeldaten an',
  }
];

export const SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN = "mdeMateriallieferscheinScan";
export const SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_BEWE = "mdeMateriallieferscheinScanBewe";
export const SOFTLINE_FEATURE_MDE_MATERIALLIEFERSCHEIN_SCAN_ARTIKEL_DETAIL = "mdeMateriallieferscheinScanArtikelDetail";