<div [softScrollIntoView]="true" scrollBehavior="instant"></div>

<div class="pb-3">
  <ng-container *ngIf="(filteredItems$ | async) as warenuebernahmen">
    <div class="flex flex-col-reverse full-width bg-surface lg:bg-transparent -mt-2 sticky -top-2 !z-10">
      <div class="lg:-mt-3 w-full p-4 bg-surface border-b border-solid">
        <div class="font-bold soft-overline mt-3 mb-3">
          {{'#WWS_WARENUEBERNAHME.UEBERSICHT.TITEL_OFFENE_WARENUEBERNAHMEN' | translate}}
        </div>
        <soft-filter-and-sort
          class="w-full mt-2"
          [filter]="filter$ | async"
          [sort]="sort$ | async"
          [values]="(warenuebernahmen$ | async) || []"
          [filterConfig]="filterConfig"
          [sortConfig]="sortConfig"
          (filterChange)="onFilterChange($event)"
          (sortChange)="onSortChange($event)">
        </soft-filter-and-sort>
      </div>
    </div>
    <ng-container *ngIf="(loading$ | async) === false; else skeleton">
      <ng-container *ngIf="(anwender$ | async) as anwender">
        <div class="flex flex-grow overflow-auto full-width w-full z-[0] lg:-mt-3 relative">
          <div class="flex flex-col w-full px-4 pb-2 lg:mt-3">
            <div
              class="soft-card rounded-sm flex w-full items-center justify-between cursor-pointer mt-3 !p-3"
              *ngFor="let warenuebernahme of warenuebernahmen"
              [ngClass]="{'!bg-danger-300': warenuebernahme?.anwenderExzess && (anwender?.id !== warenuebernahme?.anwenderExzess?.id)}"
              (click)="warenuebernahmeSperrenBestaetigen(warenuebernahme, anwender)">
              <div class="flex flex-row w-full justify-between">
                <div class="flex flex-col w-4/5 gap-2">
                  <div class="flex flex-row w-full justify-between">
                    <div class="flex self-center text-lg font-semibold">
                  <span *ngIf="warenuebernahme.lieferant">
                    {{warenuebernahme?.lieferant?.lieferantennummer }} | {{warenuebernahme.lieferant.kurzbez}}
                  </span>
                    </div>
                    <div class="flex self-center">
                  <span *ngIf="warenuebernahme?.anwender">
                    {{'#WWS_WARENUEBERNAHME.UEBERSICHT.LABEL_ANWENDER' | translate}} {{warenuebernahme?.anwender?.anwender}}
                  </span>
                    </div>
                  </div>
                  <div class="flex flex-row w-full justify-between">
                    <div class="flex flex-col gap-2">
                      <p *ngIf="warenuebernahme.belegnummer && warenuebernahme.belegnummer !== ''">
                        {{'#WWS_WARENUEBERNAHME.UEBERSICHT.LABEL_LIEFERSCHEINNUMMER' | translate}} {{warenuebernahme.belegnummer}}
                      </p>
                      <p *ngIf="warenuebernahme.bestellnummer && warenuebernahme.bestellnummer !== ''">
                        {{'#WWS_WARENUEBERNAHME.UEBERSICHT.LABEL_BESTELLUNG' | translate}} {{warenuebernahme.bestellnummer}}
                      </p>
                    </div>
                    <div class="flex self-end">
                  <span *ngIf="warenuebernahme?.anwenderExzess">
                    {{'#WWS_WARENUEBERNAHME.UEBERSICHT.LABEL_BEARBEITET_VON' | translate}} {{warenuebernahme?.anwenderExzess?.anwender}}
                  </span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col w-1/5 items-end self-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 opacity-50">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #skeleton>
    <div class="w-full lg:mt-3">
      <div class="flex flex-col mt-3 justify-between h-[79px] w-full soft-card !p-4 rounded-sm" *softRepeat="10">
        <div class="skeleton rounded h-[1.10rem] w-28"></div>
        <div class="skeleton rounded h-[1.10rem] w-24"></div>
      </div>
    </div>
  </ng-template>
</div>
