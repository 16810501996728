import { createAction, createGetter, on, select } from '../../../factories';
import { StoreFeature } from '../../../store';
import * as QueryStore from './query.store';
import * as CollectionStore from './collection.store';
import { SOFTLINE_STORE_QUERY_SERVICE } from '../single/entity.shared';
import { Dictionary } from '../../../../types/dictionary';
import { Entity } from '../types/entity';
import { handleSubscriptionState } from '../../remote/subscription.store';
import * as ActionStore from '../../remote/action.store';
import {
  ActionState,
  handleObservableActionState,
} from '../../remote/action.store';
import * as KeyStore from '../../remote/key.store';
import * as ParamsStore from '../../remote/params.store';
import { SOFTLINE_SERVICE_UUID } from '../../../../core.shared';
import { lastValueFrom } from 'rxjs';
import { QueryParameters } from '../types/query';
import { equals } from '../../../../functions/equals.function';

export interface State<
  TQuery = string | number | object
> extends QueryStore.State<TQuery> {
  actualParams?: QueryParameters<TQuery>;
}

export interface QueryActionParameters<TQuery = string | number | object> {
  sort?: string;
  limit?: number;
  offset?: number;
  query: TQuery | null;

  pathParams?: Dictionary<unknown>;
  queryParams?: Dictionary<unknown>;
  clear?: boolean;
  token?: string;
}

export class Store<
  TQuery  extends string | number | object = string | number | object,
  TEntity extends Entity = Entity
> {
  mutations = {
    ...this.queryStore.mutations,
  };

  actions = {
    query: createAction<
      State<TQuery>,
      QueryActionParameters<TQuery>,
      TEntity[]
    >('query'),
    queryOnce: createAction<
      State<TQuery>,
      QueryActionParameters<TQuery>,
      TEntity[]
    >('queryOnce'),
  };

  getters = {
    ...this.queryStore.getters,
    querying: createGetter<State<TQuery>, boolean>('querying'),
    queried: createGetter<State<TQuery>, boolean>('queried'),
  };

  feature: StoreFeature<State<TQuery>> = {
    initialState: {
      ...this.queryStore.feature.initialState,
      actualParams: undefined,
    },
    mutations: [...this.queryStore.feature.mutations],
    getters: [
      ...this.queryStore.feature.getters,
      select(this.getters.querying, ({ get, featureName, params }) => {
        const actions = [this.actions.query.name];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, ActionStore.getters.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.queried, ({ get, featureName, params }) => {
        const actions = [this.actions.query.name];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, ActionStore.getters.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
    actions: [
      on(
        this.actions.query,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_QUERY_SERVICE);
          const token = params?.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          commit(featureName, KeyStore.mutations.add, token);
          commit(featureName, ParamsStore.mutations.add, {
            key: token,
            params,
          });

          const query: QueryParameters<any> = {
            query: params.query,
            sort: params.sort,
            limit: params.limit,
            offset: params.offset,
          };
          commit(featureName, this.mutations.query.setState, query);
          const subscription$ = handleSubscriptionState(
            service.query(query, params?.pathParams, params?.queryParams),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.query.name,
              token
            )
          );

          if (params?.clear)
            commit(featureName, CollectionStore.mutations.clear);

          commit(
            featureName,
            CollectionStore.mutations.addOrUpdateMany,
            result
          );
          return result;
        }
      ),
      on(
        this.actions.queryOnce,
        async ({ commit, get, dispatch, featureName, params, injector }) => {
          const state = get(featureName) as State;

          const query: QueryParameters<any> = {
            query: params.query,
            sort: params.sort,
            limit: params.limit,
            offset: params.offset,
          };
          let entities: any;
          if (equals(state.actualParams, query))
            entities = get(featureName, CollectionStore.getters.all);
          else
            entities = await dispatch(
              featureName,
              this.actions.query,
              params,
              injector
            );

          return entities;
        }
      ),
    ],
  };

  constructor(private queryStore: QueryStore.Store<TQuery>) {}
}

export function create<TQuery extends string | number | object = string | number | object, TEntity extends Entity = Entity>(): Store<TQuery, TEntity> {
  const queryStore = QueryStore.create<TQuery>();
  return new Store<TQuery, TEntity>(queryStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
