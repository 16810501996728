<div class="soft-card" *ngIf="appInfo$ | async as appInfo">
  <div class="c row center">
    <img
      class="max-h-12 mr-8 mb-4"
      [attr.src]="'/assets/images/softapps-logo.svg'"
    />
    <div>
      <h6 class="w-full font-semibold mb-2">SoftApps</h6>
      <div class="text-sm">
        <span class="font-semibold"
          >{{ '#APPLICATION.APP_INFO.NAME' | translate }}:</span
        >
        {{ appInfo.name }}
      </div>
      <div class="text-sm">
        <span class="font-semibold"
          >{{ '#APPLICATION.APP_INFO.ID' | translate }}:</span
        >
        {{ appInfo.id }}
      </div>
      <div class="text-sm">
        <span class="font-semibold"
          >{{ '#APPLICATION.APP_INFO.VERSION' | translate }}:</span
        >
        {{ appInfo.version }}
      </div>
      <div class="text-sm mt-4">
        &copy; Softline IT GmbH 1980-{{ year }}
      </div>
    </div>
  </div>
</div>
