import {
  createGetter,
  createMutation,
  mutate,
  select,
  StoreFeature,
} from '@softline/core';

export interface State {
  sideMenuOpen: boolean;
  sideMenuMinified: boolean | null;
  actionMenuOpen: boolean;
  actionMenuMinified: boolean | null;
}

export const mutations = {
  openSideMenu: createMutation<State>('openSideMenu'),
  minimizeSideMenu: createMutation<State>('minimizeSideMenu'),
  maximizeSideMenu: createMutation<State>('maximizeSideMenu'),
  closeSideMenu: createMutation<State>('closeSideMenu'),
  toggleSideMenu: createMutation<State>('toggleSideMenu'),
  openActionMenu: createMutation<State>('openActionMenu'),
  minimizeActionMenu: createMutation<State>('minimizeActionMenu'),
  maximizeActionMenu: createMutation<State>('maximizeActionMenu'),
  closeActionMenu: createMutation<State>('closeActionMenu'),
  toggleActionMenu: createMutation<State>('toggleActionMenu'),
};

export const getters = {
  sideMenuOpen: createGetter<State, boolean>('sideMenuOpen'),
  sideMenuMinified: createGetter<State, boolean | null>('sideMenuMinified'),
  actionMenuOpen: createGetter<State, boolean>('actionMenuOpen'),
  actionMenuMinified: createGetter<State, boolean | null>('actionMenuMinified'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    sideMenuOpen: false,
    actionMenuOpen: false,
    sideMenuMinified: null,
    actionMenuMinified: null,
  },
  mutations: [
    mutate(mutations.openSideMenu, ({ state }) => ({
      ...state,
      sideMenuOpen: true,
    })),
    mutate(mutations.closeSideMenu, ({ state }) => ({
      ...state,
      sideMenuOpen: false,
    })),
    mutate(mutations.minimizeSideMenu, ({ state }) => ({
      ...state,
      sideMenuMinified: true,
      sideMenuOpen: true,
    })),
    mutate(mutations.maximizeSideMenu, ({ state }) => ({
      ...state,
      sideMenuMinified: false,
      sideMenuOpen: true,
    })),
    mutate(mutations.toggleSideMenu, ({ state }) => ({
      ...state,
      sideMenuOpen: !state.sideMenuOpen,
    })),
    mutate(mutations.openActionMenu, ({ state }) => ({
      ...state,
      actionMenuOpen: true,
    })),
    mutate(mutations.closeActionMenu, ({ state }) => ({
      ...state,
      actionMenuOpen: false,
    })),
    mutate(mutations.minimizeActionMenu, ({ state }) => ({
      ...state,
      actionMenuMinified: true,
      actionMenuOpen: true,
    })),
    mutate(mutations.maximizeActionMenu, ({ state }) => ({
      ...state,
      actionMenuMinified: false,
      actionMenuOpen: true,
    })),
    mutate(mutations.toggleActionMenu, ({ state }) => ({
      ...state,
      actionMenuOpen: !state.actionMenuOpen,
    })),
  ],
  actions: [],
  getters: [
    select(getters.sideMenuOpen, ({ state }) => state.sideMenuOpen),
    select(getters.sideMenuMinified, ({ state }) => state.sideMenuMinified),
    select(getters.actionMenuOpen, ({ state }) => state.actionMenuOpen),
    select(getters.actionMenuMinified, ({ state }) => state.actionMenuMinified),
  ],
};
