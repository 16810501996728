import {ChangeDetectionStrategy, Component, computed, OnDestroy,} from '@angular/core';
import {map, Subscription} from 'rxjs';
import {HttpResourceLocation, Store} from "@softline/core";
import {Customer} from '../../data/customer';
import {SelectedCustomerStore, SOFTLINE_FEATURE_CUSTOMER_QUERY,} from '../../kundenabfrage.shared';
import {SOFTLINE_API_CUSTOMER} from '../../kundenabfrage.api';
import {BackNavigable, BottomTabConfig, WithBottomTabGroup, WithStaticBackNavigation} from '@softline/application';
import {CommonModule} from '@angular/common';
import {UiCoreModule, WithBreakpoints} from '@softline/ui-core';
import {KundeninfoLeisteComponent} from '@softapps/fibu/core';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from '@softline/auth';
import {UmsatzstatistikWidgetComponent} from '@softapps/wws/umsatzstatistik';
import {OffenePostenWidgetComponent} from '@softapps/fibu/offene-posten';
import {ActivatedRoute} from '@angular/router';
import {toSignal} from '@angular/core/rxjs-interop';
import {KreditlimitWidgetComponent} from '@softapps/wws/kreditlimit';

@Component({
  selector: 'soft-kundenabfrage-customer-detail',
  standalone: true,
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule, KundeninfoLeisteComponent, UmsatzstatistikWidgetComponent, OffenePostenWidgetComponent, KreditlimitWidgetComponent],
})
export class CustomerDetailComponent
  extends WithBottomTabGroup(
    WithBreakpoints(
      WithStaticBackNavigation(
        '/kundenabfrage',
      )
    )
  ) implements OnDestroy, BackNavigable {

  override bottomTabs = computed(() => {
    const config: BottomTabConfig[] = [];

    const breakpoint = this.breakpoint();

    if (breakpoint === null) {
      config.push(...[
        {
          name: 'kontoinfo',
          icon: 'fa-light fa-user',
          title: 'Informationen'
        },
        {
          name: 'kreditlimit',
          icon: 'fa-light fa-credit-card',
          title: 'Kreditlimit',
        },
        {
          name: 'umsatz',
          icon: 'fa-light fa-chart-mixed-up-circle-dollar',
          title: 'Umsatzstatistik'
        },
        {
          name: 'offene-posten',
          icon: 'fa-light fa-file-invoice-dollar',
          title: 'Offene Posten',
        },
      ])
    }

    return config;
  });

  public GOOGLE_LINK = 'https://www.google.com/maps/place/';

  private readonly id = toSignal(
    this.activatedRoute.paramMap.pipe(
      map(o => o.get('id') as string),
      map((id) => (id && !isNaN(+id)) ? +id : null)
    )
  );

  readonly kundennummer = toSignal(
    this.activatedRoute.queryParamMap.pipe(
      map(o => o.get('nummer')),
    )
  );

  readonly idktostamm = computed(() => {
    return this.id() ?? null;
  });

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
  ) {
    super();
  }

  getGoogleLink(customer: Customer) {
    this.GOOGLE_LINK += customer.strasse ? customer.strasse + ',' : '';
    this.GOOGLE_LINK += customer.strassenr ? customer.strassenr + ',' : '';
    this.GOOGLE_LINK += customer.plz ? customer.plz + ',' : '';
    this.GOOGLE_LINK += customer.ort ? customer.ort + ',' : '';
    return this.GOOGLE_LINK.slice(0, -1);
  }
}
