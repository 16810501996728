<div class="overflow-y-visible">
  <div class="max-h-[80vh]">
    <h4 class="text-lg font-bold">
      {{ '#UTILITIES.CONTEXT.TITLE' | translate }}
    </h4>
    <div *ngIf="components && components.length === 0">
      {{ '#UTILITIES.CONTEXT.NO_COMPONENTS' | translate }}
    </div>
    <div *ngFor="let component of components ?? []; let last = last" class="mt-4 !overflow-y-visible">
      <ng-container *ngComponentOutlet="component"></ng-container>
      <hr class="soft-separator" *ngIf="!last" />
    </div>
  </div>
  <div class="c row end">
    <button
      class="soft-button accent h-14 w-full mt-4"
      (click)="onSave()"
      [disabled]="loading$ | async"
    >
      <i *ngIf="loading$ | async" class="fa-regular fa-spinner fa-spin"></i>
      {{ '#UTILITIES.CONTEXT.SAVE' | translate }}
    </button>
  </div>
</div>
