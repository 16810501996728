<div class="modal notification-modal">
  <div class="p-4 sm:pl-0 sm:pt-0 text-2xl font-semibold">
    {{ title ?? 'Benachrichtigung' | translate | interpolate: params }}
  </div>
  <div class="content pr-4 pl-4 pb-4 sm:pl-0">
    {{ text | translate | interpolate: params }}
  </div>
  <div class="flex no-wrap">
    <button
      *ngIf="showCancel"
      class="soft-button link cancel h-14 w-full"
      (click)="onClick('CANCEL')"
    >
      {{ '#UI_CORE.DIALOGS.CANCEL' | translate }}
    </button>
    <button class="soft-button accent ok h-14 w-full" (click)="onClick('OK')">
      {{ '#UI_CORE.DIALOGS.OK' | translate }}
    </button>
  </div>
</div>
