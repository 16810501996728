import { IndexedDBConfig } from '@softline/core';

export const SOFTLINE_INDEXED_DB_STORE: IndexedDBConfig = {
  version: 1,
  name: 'store',
  objectStoreConfigs: [
    {
      name: 'features',
      parameters: { keyPath: '_featureName', autoIncrement: false },
      schema: [],
    },
  ],
};
