<div
  class="soft-container soft-container-column"
  *ngIf="form && definition"
>
  <label class="soft-label soft-label-top">
    {{ definition.title }}
    <span
      class="required-indicator"
      *ngIf="definition.required | booleanRule: form.value"
      >*</span
    >
  </label>
  <soft-duration-input
    [formControl]="form|formControl:definition.name"
    [format]="definition.format | formatRule: form.value"
    softFieldValidation
  ></soft-duration-input>
</div>
