import { Observable } from 'rxjs';

export abstract class CalendarService {
  abstract format$: Observable<string>;
  abstract weekdays$: Observable<string[]>;
  abstract shortWeekdays$: Observable<string[]>;
  abstract months$: Observable<string[]>;
  abstract years$: Observable<number[]>;

  abstract setLocale(locale: string): void;
}
