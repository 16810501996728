import { SOFTLINE_FEATURE_FIELD_OK } from "../../../dynamic.shared";
import * as FieldOkComponentStore from "../../../field-ok-component.store";
import { Filter, Sort, Store } from "@softline/core";
import { FieldOkStrategy } from "../strategies/field-ok.strategy";
import { inject } from "@angular/core";

type Constructor<T> = new(...args: any[]) => T;
export interface FieldOkView {
  strategy: FieldOkStrategy<any, any>;
  store: Store;
}

export function WithFilterAndSort<T extends Constructor<{}>>(Base: T = (class {} as any)) {
  return class extends Base implements FieldOkView {

    store = inject(Store);
    strategy = inject(FieldOkStrategy);

    filter$ = this.store.observe(SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.getters.filter,
      this.strategy.options.componentId
    )

    sort$ = this.store.observe(SOFTLINE_FEATURE_FIELD_OK,
      FieldOkComponentStore.getters.sort,
      this.strategy.options.componentId
    )

    async onFilterChange(filter: Filter | null): Promise<void> {
      this.store.commit(SOFTLINE_FEATURE_FIELD_OK,
        FieldOkComponentStore.mutations.addOrPatch,
        {key: this.strategy.options.componentId, component: {filter}}
      )
    }

    async onSortChange(sort: Sort | null): Promise<void> {
      this.store.commit(SOFTLINE_FEATURE_FIELD_OK,
        FieldOkComponentStore.mutations.addOrPatch,
        {key: this.strategy.options.componentId, component: {sort}}
      )
    }
  }
}
