import {
  createAction,
  on,
  RemoteCollectionStore,
  RemoteObjectStore,
  SOFTLINE_STORE_ENTITY_SERVICE,
  StoreBuilder,
} from '@softline/core';
import { Employee } from '../data/employee.model';

export type State = RemoteObjectStore.State<Employee>;

class StoreExtension {
  static actions = {
    loadEmployee: createAction<State, { userId: string }, Employee>(
      'loadEmployee'
    ),
  };

  static feature = {
    mutations: [],
    getters: [],
    actions: [
      on(
        StoreExtension.actions.loadEmployee,
        async ({ featureName, injector, commit, params }) => {
          const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
          const employee = await service.get(params.userId).toPromise();
          commit(
            featureName,
            TimestampCurrentEmployeeStore.mutations.set,
            employee
          );
          return employee;
        }
      ),
    ],
  };
}

export const TimestampCurrentEmployeeStore = new StoreBuilder(
  RemoteObjectStore.create<Employee>()
).add(StoreExtension).value;
