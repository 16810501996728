import { OnDestroy } from "@angular/core";
import { isDefined } from "../functions/is-defined.function";

export function hasOnInit(value: unknown): value is OnDestroy {
  return isDefined(value) && typeof (value as OnDestroy).ngOnDestroy === 'function';
}

export function hasOnDestroy(value: unknown): value is OnDestroy {
  return isDefined(value) && typeof (value as OnDestroy).ngOnDestroy === 'function';
}
