import { createAction, createGetter, on, select } from '../../../factories';
import { StoreFeature } from '../../../store';
import { SOFTLINE_STORE_ENTITY_SERVICE } from './entity.shared';
import { Entity } from '../types/entity';
import { Dictionary } from '../../../../types/dictionary';
import { Patch } from '../types/patch';
import * as RemoteEntityStore from './remote-entity.store';
import { handleSubscriptionState } from '../../remote/subscription.store';
import {
  ActionState,
  handleObservableActionState,
} from '../../remote/action.store';
import { SOFTLINE_SERVICE_UUID } from '../../../../core.shared';
import { lastValueFrom } from 'rxjs';

export interface CreateActionParameters<T extends object = object> {
  entity: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface UpdateActionParameters<T extends Entity = Entity> {
  entity: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface PatchActionParameters<T extends Entity = Entity> {
  patch: Patch<T>;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface DeleteActionParameters<T extends Entity = Entity> {
  entity: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface State<T extends Entity = Entity>
  extends RemoteEntityStore.State<T> {}

export class Store<T extends Entity = Entity> {
  mutations = {
    ...this.entityStore.mutations,
  };

  getters = {
    ...this.entityStore.getters,
    saving: createGetter<State<T>, boolean>('saving'),
    saved: createGetter<State<T>, boolean>('saved'),
  };

  actions = {
    ...this.entityStore.actions,
    create: createAction<State<T>, CreateActionParameters<T>, T>('create'),
    patch: createAction<State<T>, PatchActionParameters<T>, T>('patch'),
    update: createAction<State<T>, UpdateActionParameters<T>, T>('update'),
    delete: createAction<State<T>, DeleteActionParameters<T>, T>('delete'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.entityStore.feature.initialState,
    },
    mutations: [...this.entityStore.feature.mutations],
    getters: [
      ...this.entityStore.feature.getters,
      select(this.getters.saving, ({ get, featureName, params }) => {
        const actions = [
          this.actions.create.name,
          this.actions.patch.name,
          this.actions.update.name,
          this.actions.delete.name,
        ];
        const states: ActionState[] = ['pending', 'processing'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
      select(this.getters.saved, ({ get, featureName, params }) => {
        const actions = [
          this.actions.create.name,
          this.actions.patch.name,
          this.actions.update.name,
          this.actions.delete.name,
        ];
        const states: ActionState[] = ['succeeded', 'failed'];
        return get(featureName, getters.action.hasState, {
          actions,
          states,
          id: params,
        });
      }),
    ],
    actions: [
      ...this.entityStore.feature.actions,
      on(
        this.actions.create,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const observable = service.create(params.entity, params.pathParams);
          const subscription$ = handleSubscriptionState(
            observable,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.create.name,
              token
            )
          );
          commit(featureName, this.mutations.set, result);
          return result;
        }
      ),
      on(
        this.actions.patch,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const observable = service.patch(params.patch, params.pathParams);
          const subscription$ = handleSubscriptionState(
            observable,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.patch.name,
              token
            )
          );
          commit(featureName, this.mutations.set, result);
          return result;
        }
      ),
      on(
        this.actions.update,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const observable = service.update(params.entity, params.pathParams);
          const subscription$ = handleSubscriptionState(
            observable,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.update.name,
              token
            )
          );
          commit(featureName, this.mutations.set, result);
          return result;
        }
      ),
      on(
        this.actions.delete,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();

          const observable = service.delete(params.entity, params.pathParams);
          const subscription$ = handleSubscriptionState(
            observable,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.delete.name,
              token
            )
          );
          commit(featureName, this.mutations.set, null);
          return result;
        }
      ),
    ],
  };

  constructor(private entityStore: RemoteEntityStore.Store<T>) {}
}

export function create<T extends Entity>(): Store<T> {
  const entityStore = RemoteEntityStore.create<T>();
  return new Store<T>(entityStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const actions = instance.actions;
export const feature = instance.feature;
