import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  RemoteStoreObjectService,
  RemoteObjectStore,
  ResourceService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
  SoftlineObjectService,
  Store,
  StoreModule,
} from '@softline/core';
import { SOFTLINE_API_MASTER_DATA } from './master-data.api';
import { SOFTLINE_FEATURE_MASTER_DATA } from '../application.shared';
import { SOFTLINE_CONFIG_OFFLINE_FEATURE } from '../offline/offline.shared';

function serviceFactory(
  store: Store,
  service: ResourceService<any>
): RemoteStoreObjectService<any> {
  return new SoftlineObjectService(service, SOFTLINE_API_MASTER_DATA);
}

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [],
})
export class MasterDataModule {
  static forRoot(): ModuleWithProviders<MasterDataRootModule> {
    return {
      ngModule: MasterDataRootModule,
      providers: [
        {
          provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
          multi: true,
          useValue: {
            featureName: SOFTLINE_FEATURE_MASTER_DATA,
            fields: ['data'],
          },
        },
      ],
    };
  }
}

@NgModule({
  imports: [
    MasterDataModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_MASTER_DATA,
      feature: RemoteObjectStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
          useFactory: serviceFactory,
          deps: [Store, SOFTLINE_SERVICE_HTTP],
        },
      ],
    }),
  ],
})
export class MasterDataRootModule {}
