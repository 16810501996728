import { Pipe, PipeTransform } from '@angular/core';
import { ControlDefinitionBase, Definition, GroupDefinition, isControlDefinition } from '../data/definitions';
import { SortConfig, SortConfigProperty } from '@softline/application';

@Pipe({
  name: 'definitionSort',
  standalone: true,
  pure: true,
})
export class DefinitionSortPipe implements PipeTransform {

  constructor() {
  }

  transform(
    definition: Definition | null | undefined
  ): SortConfig | null {
    if(!definition)
      return null;
    const properties: SortConfigProperty[] = [];
    const stack = [definition];
    while (stack.length > 0) {
      const current = stack.splice(0, 1)[0];
      if(isControlDefinition(current) && current.sortable) {
        const filter = (current as ControlDefinitionBase).filter;
        if(typeof filter === "boolean") {
          properties.push({
            property: current.name,
            title: current.title ?? current.name
          })
        }
        else if(filter) {
          properties.push({
            property: current.name,
            title: filter.title ?? current.title ?? current.name
          })
        }

      }
      if((current as GroupDefinition)?.definitions)
        stack.unshift(...(current as GroupDefinition)?.definitions);
    }
    return { properties }
  }
}
