@switch(debouncedLoadingState()) { @case ('pending') {
<div class="soft-card w-full">
  <div class="w-full flex flex-row justify-center items-center p-4">
    <soft-icon
      class="mr-4 text-info"
      name="fa-regular fa-spinner fa-spin fa-2x"
    ></soft-icon>
    <span class="text-light">
      {{
        '#SOFTAPPS_CORE.COMPONENTS.REMOTE_DATA_CONTAINER.PENDING' | translate
      }}
    </span>
  </div>
</div>
} @case ('loading') {
<div class="relative w-full">
  @if(cancel.observed) {
  <soft-icon
    class="absolute right-4 top-2 pointer z-10"
    name="fa-regular fa-times"
    (click)="cancel.emit()"
  ></soft-icon>
  } @if(loadingTemplate(); as loadingTemplate) {
  <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
  } @else if (definition()) {
  <soft-dynamic-skeleton [definition]="definition()"></soft-dynamic-skeleton>
  } @else {
  <div class="soft-card w-full">
    <div class="w-full flex flex-row justify-center items-center p-4">
      <soft-icon
        class="mr-4 text-info"
        name="fa-regular fa-spinner fa-spin fa-2x"
      ></soft-icon>
      <span class="text-light">
        {{
          '#SOFTAPPS_CORE.COMPONENTS.REMOTE_DATA_CONTAINER.LOADING' | translate
        }}
      </span>
    </div>
  </div>
  }
</div>
} @case ('loaded') { @if(data() | isDefinedNotEmpty) {
<ng-content></ng-content>
} @else {
<div class="soft-card w-full">
  <div class="w-full flex flex-row justify-center items-center p-4">
    <soft-icon
      class="mr-4 text-lighter"
      name="fa-regular fa-file-circle-info fa-2x"
    ></soft-icon>
    <span class="text-light">
      {{
        '#SOFTAPPS_CORE.COMPONENTS.REMOTE_DATA_CONTAINER.NO_DATA' | translate
      }}
    </span>
  </div>
</div>
} } @case ('failed') {
<div class="soft-card w-full">
  <div class="w-full flex flex-row justify-center items-center p-4">
    <soft-icon
      class="mr-4 text-error-300"
      name="fa-regular fa-file-circle-exclamation fa-2x"
    ></soft-icon>
    <span class="text-error-400">
      {{ '#SOFTAPPS_CORE.COMPONENTS.REMOTE_DATA_CONTAINER.FAILED' | translate }}
    </span>
  </div>
</div>
} @case ('canceled') {
<div class="soft-card w-full">
  <div class="w-full flex flex-row justify-center items-center p-4">
    <soft-icon
      class="mr-4 text-warning"
      name="fa-regular fa-file-circle-xmark fa-2x"
    ></soft-icon>
    <span class="text-warning">
      {{
        '#SOFTAPPS_CORE.COMPONENTS.REMOTE_DATA_CONTAINER.CANCELED' | translate
      }}
    </span>
  </div>
</div>
} @default {
<div></div>
} }
