import { isDefined } from './is-defined.function';

export interface DistinctFunctionParams<T> {
  comparer?: (f: T, s: T) => boolean;
}

export function distinct<T>(
  array: T[],
  params?: DistinctFunctionParams<T>
): T[] {
  const comparer = params?.comparer ?? ((f: T, s: T) => f === s);
  return array.reduce(
    (p, c, i) => (p.findIndex((o) => comparer(o, c)) > -1 ? p : [...p, c]),
    [] as T[]
  );
}
