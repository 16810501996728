<ng-container *ngIf="configs$ | async as configs">
  <div id="modal"
       *ngIf="configs.length > 0" class="modal-overlay"
       (keydown)="$event.stopPropagation();"
       (keyup)="$event.stopPropagation();">
    <soft-modal
      *ngFor="let config of configs | sort: 'priority':'ASC'; let last = last"
      [config]="config"
      [hidden]="!last"
    ></soft-modal>
  </div>
</ng-container>
