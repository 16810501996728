import { TemplateRef } from '@angular/core';
import { DismissConfig } from './modal-config';

export interface SignatureModalConfig {
  title?: string;
  subtitle?: string;
  content?: string | TemplateRef<any>;
  class?: string;
  params?: object;
  dismiss?: boolean | DismissConfig;
}
