import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_AUTHORIZATION } from "./authorization.const";
import * as AuthorizationStore from "./authorization.store";
import { SOFTLINE_FEATURE_JWT_AUTHENTICATION } from "../authentication/jwt-authentication/jwt-authentication.shared";
import * as JwtAuthenticationStore from "../authentication/jwt-authentication/jwt-authentication.store";
import { SOFTLINE_CONFIG_AUTHENTICATION_ROUTE } from "../authentication/authentication.shared";

@Injectable({
  providedIn: 'root',
})
export class AuthorizationResolver  {
  constructor(private store: Store,
              private router: Router,
              @Inject(SOFTLINE_CONFIG_AUTHENTICATION_ROUTE) private loginRoute: string) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<object> {
    try{
      return await this.store.dispatch(
        SOFTLINE_FEATURE_AUTHORIZATION,
        AuthorizationStore.actions.loadOnce
      );
    }
    catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.logout,
        { }
      );
      await this.router.navigate([this.loginRoute]);
      return [];
    }
  }
}
