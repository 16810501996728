import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store, isDefined } from '@softline/core';
import { SOFTLINE_FEATURE_APP_INFO } from '../../application.shared';
import * as AppInfoStore from '../app-info.store';
import { Observable } from 'rxjs';

@Injectable()
export class AppInfoInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const appInfo = this.store.get(
      SOFTLINE_FEATURE_APP_INFO
    ) as AppInfoStore.State;
    if (!appInfo) return next.handle(request);

    request = request.clone({
      setHeaders: {
        'X-Softline-App-Id': appInfo.id ?? '',
        'X-Softline-App-Version': appInfo.version ?? '',
        'X-Softline-App-Name': appInfo.name ?? '',
      },
    });
    return next.handle(request);
  }
}
