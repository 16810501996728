import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {MasterDataResolver, RemoteConfigResolver} from '@softline/application';

export const artikelInfoRoutes: Routes = [
  {
    path: 'artikel-info',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/artikel-info.component').then(
            (o) => o.ArtikelInfoComponent
          ),
        children: [
          {
            path: 'favoriten',
            loadComponent: () => import('./pages/favorite-lists/favorite-lists.component').then(
              o => o.FavoriteListsComponent
            )
          },
          {
            path: 'favoriten/:id',
            loadComponent: () => import('./pages/favorite/favorite.component').then(
              o => o.FavoriteComponent
            )
          },
          {
            path: 'suchverlauf',
            loadComponent: () => import('./pages/search-history/search-history.component').then(
              o => o.SearchHistoryComponent
            )
          },
          {
            path: 'scan-liste',
            loadComponent: () => import('./pages/scan-liste/scan-liste.component').then(
              o => o.ScanListeComponent
            )
          },
          {
            path: '',
            loadComponent: () => import('./pages/artikel-liste/artikel-liste.component').then(
              o => o.ArtikelListeComponent
            )
          },
          {
            path: ':id',
            loadComponent: () => import('./pages/artikel-detail/artikel-detail.component').then(
              o => o.ArtikelDetailComponent
            )
          },
          {
            path: '**',
            redirectTo: '/'
          }
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: 'ARTIKELINFO' },
    resolve: { config: RemoteConfigResolver, masterData: MasterDataResolver },
    providers: [],
  },
];
