import { SortDirection } from '@softline/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
  standalone: true
})
export class MapPipe implements PipeTransform {
  transform<T extends object>(
    array: readonly T[] | null | undefined,
    field?: keyof T,
  ): (keyof T)[]{
    let value = [];
    if (!array || !Array.isArray(array))
      return value;

    return array.map( o => !!o ? o[field] : undefined)
  }
}
