import { ChangeDetectionStrategy, Component, computed, input, signal } from "@angular/core";
import { CommonModule } from '@angular/common';

type iconType = 'svg' | 'fa5' | 'fa6' | 'custom' | 'none';

@Component({
  selector: 'soft-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  name = input<string | undefined>('');
  type = computed(() => {
    const name = this.name();
    if(!name)
      return 'none'
    if (name.startsWith('fas fa-') || name.startsWith('far fa-') || name.startsWith('fal fa-') || name.startsWith('fad fa-') || name.startsWith('fab fa-'))
      return 'fa5';
    if (name.startsWith('fa-'))
      return 'fa6';
    if (name.startsWith('<svg'))
      return 'svg';
    return 'custom';
  });
}
