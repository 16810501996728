import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { VerkaufsartwerkstattListView } from "./list-view/verkaufsartwerkstatt.list-view";

export const verkaufsartwerkstattFieldOk: FieldOkConfig = {
  name: 'verkaufsartwerkstatt',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{verkaufsart}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: VerkaufsartwerkstattListView
    }
  }
  ]
};
export const verkaufsartwerkstattMultiselectFieldOk: FieldOkConfig = {
  name: 'verkaufsartwerkstatt',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{verkaufsart}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: VerkaufsartwerkstattListView
    }
  }
  ]
};

export const verkaufsartwerkstattQueryFieldOk: FieldOkConfig = {
  name: 'verkaufsartwerkstatt',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{verkaufsart}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: VerkaufsartwerkstattListView
    }
  }
  ]
};
