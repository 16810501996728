import { inject, Injectable, Injector } from "@angular/core";
import { FieldOkInputParams, FieldOkStrategyOptions } from "./field-ok.strategy";
import { EntityInputStrategy } from "@softline/ui-core";
import { Entity, MultiSelectStrategy, SOFTLINE_SERVICE_UUID, Store } from "@softline/core";
import { CommonFieldOkStrategy } from "./common-field-ok.strategy";
import { MultiselectFieldOkStrategy } from "../../multiselect-field-ok/strategies/mutliselect-field-ok.strategy";
import { QueryFieldOkStrategy } from "../../query-field-ok/strategies/query-field-ok.strategy";

@Injectable()
export class FieldOkStrategyFactory {
    constructor(protected injector: Injector) { }
    create(name: string,
           options: FieldOkStrategyOptions): EntityInputStrategy<any, FieldOkInputParams<any, any>> {
      switch (options.type) {
        case 'query':
          return new QueryFieldOkStrategy(
            inject(Store),
            this.injector,
            inject(SOFTLINE_SERVICE_UUID),
            options,
            new MultiSelectStrategy())
        case 'multi':
          return new MultiselectFieldOkStrategy(
            inject(Store),
            this.injector,
            inject(SOFTLINE_SERVICE_UUID),
            options,
            new MultiSelectStrategy());
        default:
          return new CommonFieldOkStrategy(
            inject(Store),
            this.injector,
            inject(SOFTLINE_SERVICE_UUID),
            options
          )
      }
    }
}
