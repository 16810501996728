import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'soft-date-time-range-input',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './date-time-range-input.component.html',
  styleUrl: './date-time-range-input.component.scss',
})
export class DateTimeRangeInputComponent {}
