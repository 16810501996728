import { Directive, forwardRef, input } from '@angular/core';
import { MetaObject } from '../../model/meta';
import { MetaBaseDirective } from './meta-base.directive';

@Directive({
  selector: '[softMeta]',
  standalone: true,
  providers: [
    {provide: MetaBaseDirective, useExisting: forwardRef(() => MetaDirective)}
  ]
})
export class MetaDirective<T> extends MetaBaseDirective<T> {
  metaObject = input.required<MetaObject<T> | null>({alias: 'softMeta'});
  value = input.required<T>({alias: 'softValue'});
  constructor() {
    super();
  }
}
