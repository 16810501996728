import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from "@angular/core";
import { KeyboardEvent } from "react";
import * as math from 'mathjs';

@Component({
  selector: 'soft-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit {
  isPickerOpen = false;

  error: string | null = null;
  @Input() input: string = '';

  result: number | null = null;
  @Output() resultChange = new EventEmitter<number | null>();

  @Output() submit = new EventEmitter<number>();

  constructor(
  ) {}

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if(/^[0-9]$/.test(event.key)) {
      this.numberClick(+event.key)
    }
    else if(/^[A-Za-z,()*\-+/.]$/.test(event.key)) {
      this.operatorClick(event.key)
    }
    else if (event.key === 'Enter' && this.result !== null && this.submit.observed) {
      this.submitClick();
    }
    else if(event.key === 'Enter') {
      this.equalClick();
    }
    else if(event.key === 'Backspace' && this.input.length > 0) {
      this.deleteClick()
    }
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnInit(): void {}

  numberClick(value: number): void {
    if((this.error?.length ?? 0) > 0)
      this.error = null;
    if(this.result)
      this.result = null;
    this.input += '' + value;
  }

  operatorClick(value: string): void {
    if((this.error?.length ?? 0) > 0)
      this.error = null;
    if(this.result)
      this.result = null;
    this.input += value;
  }

  equalClick(): void {
    try {
      const result = math.evaluate(this.input);
      this.result = result;
      this.error = null;
      this.resultChange.emit(this.result);
    }
    catch (e: any) {
      this.error = "SyntaxError: " + e.message;
      this.result = null;
    }
  }


  deleteClick(): void {
    if(this.result !== null)
      this.result = null;
    else if(this.error !== null)
      this.error = null;
    else if(this.input.length > 1)
      this.input = this.input.substring(0, this.input.length - 1);
    else if (this.input.length === 1)
      this.input = ''
  }

  clearClick(): void {
    if(this.result !== null)
      this.result = null;
    else if((this.error?.length ?? 0) > 0)
      this.error = null;
    else if(this.input.length > 0)
      this.input = '';
  }

  submitClick(): void {
    this.submit.emit(this.result ?? 0);
  }
}
