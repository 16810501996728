export interface ItemScan {
  artikel: Artikel;
  einheit: Einheit;
  ean?: string;
  sanArtikelnummer?: string;
  menge?: number;
  preis?: number;
  nettogewicht?: number;
  mhd?: string;
  chargenummer?: string;
  ssccnummer?: string;
  manartikel?: ManArtikel;
}

export interface Artikel {
  id: number;
  nummer: string;
  artbez1: string;
  artbez2?: string;
  artbez3?: string;
  artbez4?: string;
  artbez5?: string;
}

export interface Einheit {
  id: number;
  arteh: string;
}

export interface ManArtikel {
  id: number;
  idmanartikeldat?: number;
  vkpreisexcl?: number;
  vkpreisincl?: number;
  empfpreis?: number;
  nummer?: string;
  artbez1?: string;
  artbez2?: string;
  artbez3?: string;
  artbez4?: string;
  artbez5?: string;
  beschreibung?: string;
  manarteh?: string;
  manartnr?: string;
  pool_artikelnummer?: string;
}
