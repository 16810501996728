import { SelectStore2 } from '../select/select.store2';
import { computed, inject, Injectable } from '@angular/core';
import { CollectionStore2 } from './collection.store2';
import { ListStore2, SOFTLINE_FEATURE_ID_FUNC } from '@softline/core';

@Injectable()
export class SumStore2<T extends object> extends ListStore2<Partial<T>>{
  constructor() {
    super();
  }
}
