import { ObjectStore } from "@softline/core"

export interface PwaUpdate {
  loading: boolean;
  updateAvailable?: boolean,
  currentVersionHash?: string,
  latestVersionHash?: string,
}

export const PwaUpdateStore = ObjectStore.create<PwaUpdate>();
