import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Bewe} from "../../types/bewegung";
import {MengenEingabeComponent} from "@softline/application";
import {UiCoreModule} from "@softline/ui-core";

@Component({
  selector: 'soft-bewegung-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MengenEingabeComponent, UiCoreModule],
  templateUrl: './bewegung-list.component.html',
  styleUrls: ['./bewegung-list.component.scss'],
})
export class BewegungListComponent {

  @Input() isBestellung = false;
  @Input() bewegungen: Bewe[] = [];

  @Output() statusChange = new EventEmitter<Bewe>();
  @Output() mengeChange = new EventEmitter<{ bewe: Bewe, menge: number }>();
  @Output() mengenEingabeOeffnen = new EventEmitter<Bewe>();

  trackByFn(_: number, bewe: Bewe): number {
    return bewe.id
  }
}
