import { Component, Inject, OnInit } from '@angular/core';
import { IndexedDbService, Store } from '@softline/core';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
} from '@softline/ui-core';
import {
  OfflineFeatureConfig,
  SOFTLINE_CONFIG_OFFLINE_FEATURE,
} from '../../offline.shared';
import { SOFTLINE_INDEXED_DB_STORE } from '../../adapter/store.indexed-db';

@Component({
  selector: 'lib-storage-settings',
  templateUrl: './storage-settings.component.html',
  styleUrls: ['./storage-settings.component.scss'],
})
export class StorageSettingsComponent implements OnInit {
  constructor(
    private store: Store,
    @Inject(SOFTLINE_CONFIG_OFFLINE_FEATURE)
    private offlineFeatures: (string | OfflineFeatureConfig)[],
    private indexedDbService: IndexedDbService
  ) {}

  ngOnInit(): void {}

  async clearCache(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      {
        title: '#APPLICATION.OFFLINE.STORAGE.SETTINGS.RESET.CONFIRMATION_TITLE',
        question:
          '#APPLICATION.OFFLINE.STORAGE.SETTINGS.RESET.CONFIRMATION_MESSAGE',
      }
    );
    if (result !== 'YES') return;

    try {
      for (const feature of this.offlineFeatures)
        this.store.resetFeature(
          typeof feature === 'string' ? feature : feature.featureName
        );
      await this.indexedDbService.deleteDatabase(
        SOFTLINE_INDEXED_DB_STORE.name
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#APPLICATION.OFFLINE.STORAGE.SETTINGS.RESET.SUCCESS'
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#APPLICATION.OFFLINE.STORAGE.SETTINGS.RESET.ERROR'
      );
    }
  }
}
