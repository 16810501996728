import { Inject, Injectable } from "@angular/core";
import { Store } from "@softline/core";
import { Subscription } from "rxjs";
import { AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from "@softline/auth";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import {
  CloudMessagingService,
  SOFTLINE_CONFIG_ONE_SIGNAL_APP_ID,
  CloudMessagingPermission
} from "@softline/application";
import { OneSignal } from "onesignal-ngx";

@Injectable()
export class BrowserCloudMessagingService extends CloudMessagingService {
  private subscription?: Subscription;
  constructor(
    store: Store,
    @Inject(SOFTLINE_CONFIG_ONE_SIGNAL_APP_ID) oneSignalAppId: string,
    router: Router,
    private oneSignal: OneSignal
  ) { super(store, oneSignalAppId, router); }

  override async init(): Promise<CloudMessagingPermission> {

    try {
      await this.oneSignal.init({
        appId: this.oneSignalAppId,
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerParam: {scope: '/push/onesignal/'},
        // notifyButton: {enable: true},
        // persistNotification: true,
        // autoRegister: true,
        autoResubscribe: true,
        serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
        serviceWorkerUpdaterPath: 'push/onesignal/OneSignalSDKWorker.js'
      })
      console.log(this.oneSignalAppId, this.oneSignal);
    }
    catch (e) {
      console.error(e)
    }

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    await this.oneSignal.addListenerForNotificationOpened((jsonData) => {
      console.log('-----------notificationOpenedCallback: ' + JSON.stringify(jsonData));
      if ((jsonData.data as any).route)
        this.router.navigate([
          (jsonData.data as any).route,
        ]);
    });

    // Anwender Subscription
    this.subscription = this.store
      .observe(
        SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
        AuthenticationContextStore.getters.data
      )
      .pipe(map((o) => o as { userId: number }))
      .subscribe(async (o) => {
        if(o?.userId)
          await this.oneSignal.setExternalUserId('' + o.userId);
        else
          await this.oneSignal.removeExternalUserId();
      });

      const permission = await this.oneSignal.getNotificationPermission();
      return permission;
  }

  override async subscribe(): Promise<CloudMessagingPermission> {
    // await this.oneSignal.registerForPushNotifications()
    await this.oneSignal.registerForPushNotifications()
    const permission = await this.oneSignal.getNotificationPermission();
    return permission;
  }

  override async unsubscribe(): Promise<boolean> {
    return await this.oneSignal.isPushNotificationsEnabled();
  }

  override async getTags(): Promise<object> {
    return new Promise<object>((resolve) => {
      this.oneSignal.getTags((tags) => resolve(tags));
    });
  }

  override async setTag(tag: string, value: string): Promise<void> {
    await this.oneSignal.sendTag(tag, value);
  }

}
