import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@softline/core';
import {combineLatestWith, filter, map, Observable, tap} from 'rxjs';
import {RemoteConfigStore, SOFTLINE_FEATURE_REMOTE_CONFIG} from '@softline/application';
import {SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK} from '../etikettendruck.shared';
import {EtikettendruckStore} from '../store/etikettendruckStore';

const MODULE_NAME = 'mde-etikettendruck'
const LIEFERTERMIN_KEY = 'LIEFERTERMIN_EINGEBBAR'

@Injectable()
export class EtikettendruckResolver  {

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ etikettenart: string | undefined  }> | Promise<{ etikettenart: string | undefined  }> | { etikettenart: string | undefined  } {
    return this.store.observe(
      SOFTLINE_FEATURE_REMOTE_CONFIG,
      RemoteConfigStore.getters.data,
    ).pipe(
      filter(o => !!o && !!o?.modules),
      map(data => data?.modules?.find(o => o.module === MODULE_NAME)?.properties?.find(props => props.key === LIEFERTERMIN_KEY)),
      combineLatestWith(this.store.observe(SOFTLINE_FEATURE_MDE_ETIKETTENDRUCK, EtikettendruckStore.getters.art)),
      tap(([prop, art]) => console.log('resolver:', prop, art)),
      map(([_, art]) => ({
        etikettenart: art as string | undefined
      }))
    )
  }
}
