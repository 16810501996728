import {Injectable} from '@angular/core';
import {ConnectionHttpService,} from '@softline/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SOFTLINE_API_ITEM} from '../artikel-info.api';
import { Item } from '../types/item';
import { ItemQuery } from '../types/item-query';


export interface LoadManyItemsResult {
  result: Item[];
  _totalResultCount: number;
}

@Injectable({ providedIn: 'root' })
export class ItemService {

  constructor(
    protected connectionService: ConnectionHttpService
  ) { }

  load(id: number): Observable<Item> {
    return this.connectionService.get({path: `${SOFTLINE_API_ITEM}/{{id}}`, pathParams: {id}})
      .pipe(
        map(o => o as { result: Item }),
        map(o => o.result)
      );
  }

  loadMany(query: ItemQuery, limit: number, offset: number, sort?: string): Observable<LoadManyItemsResult> {
    return this.connectionService.get({
      path: SOFTLINE_API_ITEM,
      queryParams: {query, limit: limit, offset: offset, sort: sort}
    });
  }
}
