import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { SOFTLINE_DATA_CONSOLE } from '../console.shared';
import { BehaviorSubject } from 'rxjs';
import { ConsoleEntry } from '../console.entry';
import { DateService } from '@softline/core';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
  constructor(
    public dateService: DateService,
    @Inject(SOFTLINE_DATA_CONSOLE)
    public consoleData: BehaviorSubject<ConsoleEntry[]>
  ) {
    super();
  }

  override handleError(error: any): void {
    const currentValue = this.consoleData.value;
    const nextValue: ConsoleEntry[] = [
      ...currentValue,
      {
        timestamp: this.dateService.now(),
        type: 'error',
        data: error,
      },
    ];
    this.consoleData.next(nextValue);
    super.handleError(error);
  }
}
