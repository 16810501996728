import { Id } from "../abstraction";
import {v4 as uuid} from 'uuid'
import { isDefined } from '@softline/core';

export function toIdString(id: Id): string
export function toIdString(id: Id | null | undefined, params?: {createIfEmpty: true}): string
export function toIdString(id: Id | null | undefined, params?: {createIfEmpty: boolean}): string
export function toIdString(id: Id | null | undefined, params?: {createIfEmpty: boolean}): string {
  if(typeof id === 'string')
    return id;
  if(typeof id === 'number')
    return `${id}`;
  if(typeof id === 'object' && id !== null)
    return JSON.stringify(id);
  if(params?.createIfEmpty && !isDefined(id))
    return uuid();
  if(!params?.createIfEmpty || !isDefined(id))
    return '';
  throw new Error(`[ReadonlyStorageCollectionDataService]getIdString: Invalid id type: ${typeof id}`);
}
