import {createGetter, createMutation, CustomStore, mutate, select} from '@softline/core';

export interface SuchverlaufEintrag {
  text: string;
  date: string;
}

interface SearchHistoryState {
  history: Record<string, SuchverlaufEintrag>;
}

export const SOFTLINE_FEATURE_ALLGEMEIN_SEND_NOTIFICATION_SUCHVERLAUF = 'softapps/allgemein/send-notification/suchverlauf';

export const mutations = {
  addOrUpdate: createMutation<SearchHistoryState, SuchverlaufEintrag>('searchHistory/addOrUpdate'),
  remove: createMutation<SearchHistoryState, string>('searchHistory/remove')
}

export const getters = {
  all: createGetter<SearchHistoryState, SuchverlaufEintrag[], void>('searchHistory/all'),
}

export const actions = {}

export const EmpfaengerSuchverlaufStore: CustomStore<SearchHistoryState, typeof mutations, typeof getters, typeof actions> = {
  mutations,
  actions,
  getters,
  feature: {
    initialState: {
      history: {}
    },
    getters: [
      select(getters.all, ({ state }) => {
        return Object.values(state.history);
      })
    ],
    actions: [],
    mutations: [
      mutate(mutations.addOrUpdate, ({ state, params }) => {
        const history = { ...state.history };
        history[params.text] = params;

        return {
          ...state,
          history
        };
      }),
      mutate(mutations.remove, ({ state, params }) => {
        const history = { ...state.history };

        if (history[params])
          delete history[params];

        return {
          ...state,
          history
        };
      })
    ]
  }
}
