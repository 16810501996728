export function base64Encode(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode(parseInt('0x' + p1, undefined))
    )
  );
}

export function base64Decode(str: string): string {
  return decodeURIComponent(
    atob(str.replace(/_/g,'/')) // Ticket 296.215: Im AuthToken befindet sich ein Unterstrich, mit dem atob anscheinend nicht umgehen kann (https://github.com/jsonwebtoken/jsonwebtoken.github.io/issues/26)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
}
