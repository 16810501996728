import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[softScrollIntoView]',
})
export class ScrollIntoViewDirective implements AfterContentInit {
  private initialized = false;
  private _enabled = false;
  @Input('softScrollIntoView')
  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(value: boolean) {
    if (value === this._enabled) return;

    if (this.initialized && value && !this._enabled)
      this.elementRef.nativeElement.scrollIntoView({
        behavior: this.scrollBehavior,
        block: this.scrollBlock,
        inline: this.scrollInline,
      });
    this._enabled = value;
  }
  @Input() scrollBehavior?: ScrollBehavior;
  @Input() scrollBlock?: ScrollLogicalPosition;
  @Input() scrollInline?: ScrollLogicalPosition;

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit(): void {
    if (this._enabled)
      this.elementRef.nativeElement.scrollIntoView({
        behavior: this.scrollBehavior,
        block: this.scrollBlock,
        inline: this.scrollInline,
      });
    this.initialized = true;
  }
}
