import { Injectable } from '@angular/core';
import moment from 'moment';
import { TimeParser } from './time.parser';
import { DateService, isDefined } from '@softline/core';

@Injectable()
export class IsoTimeParser extends TimeParser<string> {
  private format: string = 'HH:mm';

  private empty(): moment.Moment {
    return moment(this.dateService.today());
  }

  constructor(private dateService: DateService) {
    super();
  }

  parse(value: string, utcOffset?: string): string | null {
    try {
      const date =
        this.parseNowInput(value) ||
        this.parseHourInput(value) ||
        this.parseMinutesInput(value) ||
        moment(value, this.format);
      if (isDefined(utcOffset)) date.utcOffset(utcOffset, true);

      const time = date.toISOString(true);
      return time.substring(time.indexOf('T'));
    } catch (message) {
      return null;
    }
  }

  private parseNowInput(value: string): moment.Moment | null {
    if (value === 'j') return moment();
    return null;
  }

  private parseHourInput(value: string): moment.Moment | null {
    const match = /^\d{1,2}$/g.exec(value);
    if (match == null) return null;
    const hours = +match[0];
    if (hours > 24) throw Error('Invalid Hour not more than 24 hours possible');
    let time = this.empty();
    time = time.add(hours, 'hours');
    return time;
  }

  private parseMinutesInput(value: string): moment.Moment | null {
    const match = /^(\d{1,2})(\d{2})$/g.exec(value);
    if (match == null) return null;

    const hours = +match[1];
    const minutes = +match[2];
    if (hours >= 24)
      throw Error('Invalid Hour not more than 24 hours possible');
    if (minutes > 59)
      throw Error('Invalid Hour not more than 59 minutes possible');
    let time = this.empty();
    time.add(hours, 'hours');
    time.add(minutes, 'minutes');
    return time;
  }
}
