import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@softline/core';
import {
  MessageBarAction,
  MessageBarConfig,
} from '../../data/message-bar.config';
import { SOFTLINE_FEATURE_MESSAGE_BAR } from '../../message-bar.shared';
import * as MessageBarStore from '../../message-bar.store';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'soft-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10rem)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({  opacity: 0, transform: 'translateY(10rem)' }))
      ])
    ])
  ]
})
export class MessageBarComponent implements OnInit {
  @Input() config!: MessageBarConfig;
  @Output() dismiss = new EventEmitter<MessageBarConfig>();

  constructor(private store: Store) {}

  ngOnInit(): void {}

  onDismiss(id?: string, callback?: () => void): void {
    if (callback) callback();
    this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.close,
      id
    );
  }

  actionFilter(
    action: MessageBarAction,
    type: 'link' | 'button' | undefined
  ): boolean {
    return action.type === type;
  }
}
