import {
  ActionStore,
  createAction,
  createGetter,
  on,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore,
  SyncedRemoteCollectionStore
} from '@softline/core';

import {concat, lastValueFrom, tap, toArray} from 'rxjs';
import {map} from 'rxjs/operators';
import {ArtikelPreisService, LoadPriceParameters} from '../services/artikel-preis.service';
import {ArtikelPreis} from '../types/artikel-preis';
import { SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE } from "../artikel.shared";

export type ArtikelPreisState = SyncedRemoteCollectionStore.State<ArtikelPreis>

const collectionStore = SyncedRemoteCollectionStore.create<ArtikelPreis>();

export interface LoadManyPricesParallelParams {
  ids: number[];
}

// tslint:disable-next-line:no-namespace
export namespace ArtikelPreisStore {

  export const mutations = {
    ...collectionStore.mutations
  };

  export const actions = {
    ...collectionStore.actions,
    loadManySequential: createAction<ArtikelPreisState, LoadManyPricesParallelParams, ArtikelPreis[]>('load many sequential'),
  };

  export const getters = {
    ...collectionStore.getters,
    total: createGetter<ArtikelPreisState, number>('item price total'),
  };

  export const feature: StoreFeature<ArtikelPreisState> = {
    initialState: {
      ...collectionStore.feature.initialState,
    },
    mutations: [
      ...collectionStore.feature.mutations
    ],
    actions: [
      ...collectionStore.feature.actions,
      on(actions.loadManySequential, async ({featureName, injector, commit, params}) => {
        const service = injector.get(ArtikelPreisService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const sequenceSize = injector.get(SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE);
        let requests: LoadPriceParameters[][] = [];

        const items = params.ids.map(itemId => ({itemId, amount: 1}));
        while (items.length > 0) {
          const size = Math.min(items.length, sequenceSize);
          requests = [...requests, items.splice(0, size)]
        }

        const sub$ = concat(
          ...requests.map(payload => service.load(payload))
        ).pipe(
          tap(o => commit(featureName, mutations.addOrUpdateMany, o)),
          toArray(),
          map((responses) => responses.map(o => o).reduce((a, b) => ([...a, ...b]))),
        );
        const subState$ = SubscriptionStore.handleSubscriptionState(sub$, featureName, commit, token);
        return await lastValueFrom(ActionStore.handleObservableActionState(
          subState$,
          featureName,
          commit,
          actions.loadManySequential.name,
          token
        ));
      }),
    ],
    getters: [
      ...collectionStore.feature.getters,
    ]
  };

}
