import * as SyncedRemoteObjectStore from './synced-remote-object.store';
import { createAction, createGetter, on } from '../../factories';
import { StoreFeature } from '../../store';
import {
  SOFTLINE_STORE_OBJECT_SERVICE,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
} from './object.shared';
import { handleSubscriptionState } from '../remote/subscription.store';
import * as ProgressStore from '../remote/progress.store';
import { handleProgressState } from '../remote/progress.store';
import { SOFTLINE_SERVICE_UUID } from '../../../core.shared';
import { handleObservableActionState } from '../remote/action.store';
import { Dictionary } from '../../../types/dictionary';
import { lastValueFrom } from 'rxjs';

export interface UploadObjectActionParameters<T extends object = object> {
  data: T;
  pathParams?: Dictionary<unknown>;
  token?: string;
}

export interface State<T extends object = object>
  extends SyncedRemoteObjectStore.State<T>,
    ProgressStore.State {}

export class Store<T extends object = object> {
  mutations = {
    ...this.objectStore.mutations,
    progress: ProgressStore.feature.mutations,
  };

  actions = {
    ...this.objectStore.actions,
    upload: createAction<State<T>, UploadObjectActionParameters, T>('upload'),
  };

  getters = {
    ...this.objectStore.getters,
    progress: ProgressStore.feature.getters,
    uploading: createGetter<State<T>, boolean, string | undefined>('uploading'),
    uploaded: createGetter<State<T>, boolean, string | undefined>('uploaded'),
  };

  feature: StoreFeature<State<T>> = {
    initialState: {
      ...this.objectStore.feature.initialState,
      ...ProgressStore.feature.initialState,
    },
    mutations: [
      ...this.objectStore.feature.mutations,
      ...ProgressStore.feature.mutations,
    ],
    getters: [
      ...this.objectStore.feature.getters,
      ...ProgressStore.feature.getters,
    ],
    actions: [
      ...this.objectStore.feature.actions,
      on(
        this.actions.create,
        async ({ commit, featureName, params, injector }) => {
          const service = injector.get(SOFTLINE_STORE_REMOTE_OBJECT_SERVICE);
          const token = params.token ?? injector.get(SOFTLINE_SERVICE_UUID)();
          const progress$ = service.upload(params.data, params.pathParams);
          const result$ = handleProgressState(
            progress$,
            featureName,
            commit,
            token
          );
          const subscription$ = handleSubscriptionState(
            result$,
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            handleObservableActionState(
              subscription$,
              featureName,
              commit,
              this.actions.upload.name,
              token
            )
          );
          commit(featureName, this.mutations.set, result);
          return result;
        }
      ),
    ],
  };

  constructor(private objectStore: SyncedRemoteObjectStore.Store<T>) {}
}

export function create<T extends object>(): Store<T> {
  const objectStore = SyncedRemoteObjectStore.create<T>();
  return new Store<T>(objectStore);
}

const instance = create();
export const mutations = instance.mutations;
export const getters = instance.getters;
export const feature = instance.feature;
