import { HttpService } from "./http.service";
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Logger } from "../../../log/logger";
import { HttpResourceLocation } from "./http.location";
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PostHttpService extends HttpService {
  constructor(httpClient: HttpClient, @Inject(Logger) logger: Logger
  ) {
    super(httpClient, logger);
  }

  override get<T>(location: HttpResourceLocation, payload?: unknown): Observable<T> {
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );

    console.log(
      location
    )

    if (payload === undefined)
      return this.httpClient
        .post<T>(location.url, location?.queryParams ?? {}, { headers })
        .pipe(catchError((error) => this.handleError(error)));
    else {
      return this.httpClient
        .post<T>(location.url, payload, { headers })
        .pipe(catchError((error) => this.handleError(error)));
    }
  }
}
