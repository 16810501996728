<h2 class="text-2xl font-semibold mt-2">Einheiteneingabe</h2>

<ng-container *ngIf="!loading() && form; else skeleton">
  <div class="flex flex-col gap-2 mt-2">
    <div class="flex flex-col gap-1">
      <span class="text-sm font-semibold uppercase tracking-wide text-primary">Artikel</span>
      <div class="flex flex-col border border-slate-300 p-3 bg-surface-secondary" *ngIf="form?.value?.artikel">
        <span class="text-sm text-light font-medium">{{ form?.value?.artikel?.nummer }}</span>
        <span class="text-lg font-semibold" *ngIf="form?.value?.artikel?.artbez1">{{ form?.value?.artikel?.artbez1 }}</span>
        <span class="text-base text-light" *ngIf="form?.value?.artikel?.artbez2">{{ form?.value?.artikel?.artbez2 }}</span>
      </div>
    </div>
  </div>

  <form [formGroup]="form" class="mt-4">
    <table class="table-fixed w-full border-collapse border border-slate-400">
      <thead>
      <tr class="h-10 bg-surface-tertiary">
        <th class="w-2/12 border border-slate-300 text-right px-2">{{ form.value?.summeEinheit?.einheit?.arteh }}</th>
        <th class="w-5/12 border border-slate-300 text-left px-2">Einheit</th>
        <th class="w-5/12 border border-slate-300 text-right px-2">Menge</th>
      </tr>
      </thead>
      <tbody formArrayName="einheiten">
      <tr *ngFor="let einheit of $any(form.controls['einheiten']).controls; let i = index;"
          class="h-10 bg-surface-secondary">
        <ng-container [formGroupName]="i">
          <td
            class="border border-slate-300 text-right px-2"
            [ngClass]="{'bg-yellow-100': einheit.controls['einheit']?.value?.kzausgabe !== 'N'}">
            {{ einheit.controls['einheit']?.value?.faktor ?? 1 | number:'1.3-3' }}
          </td>
          <td
            class="border border-slate-300 text-left px-2"
            [ngClass]="{'bg-yellow-100': einheit.controls['einheit']?.value?.kzausgabe !== 'N'}">
            {{ einheit.controls['einheit']?.value?.arteh }}
          </td>
          <td class="border border-slate-300 bg-surface">
            <soft-number-input
              changeTrigger="blur"
              placeholder=" "
              format="1.3-3"
              class="appearance-none border-none text-base"
              formControlName="menge"
              textAlign="right">
            </soft-number-input>
          </td>
        </ng-container>
      </tr>
      </tbody>
      <tfoot>
      <tr formGroupName="summeEinheit" class="h-10 bg-blue-800/[0.15]">
        <td class="border border-slate-300 text-right px-2 font-semibold">
          Gesamt
        </td>
        <td class="border border-slate-300 text-left px-2 font-semibold">
          {{ form.value?.summeEinheit?.einheit?.arteh }}
        </td>
        <td class="border border-slate-300 text-right px-2 font-semibold">
          {{ form.value?.summeEinheit?.menge ?? 1 | number:'1.3-3' }}
        </td>
      </tr>
      </tfoot>
    </table>
  </form>
</ng-container>

<div class="flex items-center mt-4 gap-3">
  <button
    class="soft-button w-full h-16 text-center secondary"
    (click)="close(null)">
    Abbrechen
  </button>
  <button
    (click)="close(form?.value)"
    class="soft-button w-full h-16 text-center primary" *ngIf="form">
    Übernehmen
  </button>
</div>

<ng-template #skeleton>
  <ng-container *ngIf="loading(); else errorTemplate">
    <div class="flex flex-col w-full gap-2 mt-2">
      <div class="w-1/12 h-6 skeleton rounded mt-1"></div>
      <div class="w-full h-20 skeleton"></div>
    </div>
    <div class="flex flex-col mt-2" *softRepeat="3">
      <div class="flex gap-4 w-full">
        <div class="w-2/12 h-10 skeleton text-right"></div>
        <div class="w-5/12 h-10 skeleton text-left"></div>
        <div class="w-5/12 h-10 skeleton text-right"></div>
      </div>
    </div>
  </ng-container>

  <ng-template #errorTemplate>
    <div class="flex flex-col w-full gap-3 mt-3">
      <div>
        Beim Laden ist ein Fehler aufgetreten. Möchten Sie es erneut versuchen?
      </div>
      <button class="soft-button primary h-16 text-center w-full" (click)="retry()">
        Erneut versuchen
      </button>
    </div>
  </ng-template>
</ng-template>

