import { Component, Inject, OnInit, Optional, Type } from '@angular/core';
import {
  AuthenticationContextStore,
  JwtAuthenticationStore,
  SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
  SOFTLINE_FEATURE_JWT_AUTHENTICATION,
} from '@softline/auth';
import { equals, Store } from '@softline/core';
import {
  SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
} from '../../../utilities.shared';
import { PatchContextStore } from '../../patch-context.store';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule,
} from '@softline/ui-core';
import { handleRequestErrors } from '@softline/application';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-context-settings',
  standalone: true,
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  imports: [CommonModule, UiCoreModule],
})
export class SettingsComponent implements OnInit {
  private close!: (result: any) => void;
  loading$ = this.store.observe(
    SOFTLINE_FEATURE_JWT_AUTHENTICATION,
    JwtAuthenticationStore.getters.loading
  );

  constructor(
    private store: Store,
    @Optional()
    @Inject(SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT)
    public components?: Type<Component>[]
  ) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.actions.load
    );
  }

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  async onSave(): Promise<void> {
    const context = this.store.get(
      SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
      AuthenticationContextStore.getters.data
    );
    const patchedContext = this.store.get(
      SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
      PatchContextStore.getters.data
    );
    if (equals(context, patchedContext)) {
      return;
    }
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_JWT_AUTHENTICATION,
        JwtAuthenticationStore.actions.change,
        patchedContext
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#UTILITIES.CONTEXT.SUCCESS'
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }
}
