<div class="g gtr-1 gtc-3">
  <soft-date-input
    [value]="date"
    (valueChange)="setDate($event)"
    [readonly]="readonly"
    class="gr-1 gc-1-2"
    (blur)="onTouch()"
  ></soft-date-input>
  <soft-time-input
    [value]="time"
    (valueChange)="setTime($event)"
    [readonly]="readonly"
    [utcOffset]="date | utcOffset"
    class="gr-1 gc-3"
    (blur)="onTouch()"
  ></soft-time-input>
</div>
