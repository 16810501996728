import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, Optional } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { Store, StoreModule } from "@softline/core";
import { UiCoreModule } from "@softline/ui-core";
import { SOFTLINE_CONFIG_SETTINGS } from "../application.shared";
import { NfcReaderSettingsComponent } from "./settings/nfc-reader-settings.component";
import * as NfcReader from "./nfc-reader.store";
import { SOFTLINE_FEATURE_NFC_READER, SOFTLINE_SETTINGS_NFC_READER } from "./nfc-reader.shared";
import { NfcReaderService } from "./services/nfc-reader.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiCoreModule,
    NfcReaderSettingsComponent
  ],
  exports: [],
  providers: [],
})
export class NfcReaderModule {
  static forRoot(): ModuleWithProviders<NfcReaderRootModule> {
    return {
      ngModule: NfcReaderRootModule,
      providers: [
        {
          provide: SOFTLINE_CONFIG_SETTINGS,
          multi: true,
          useValue: {
            key: SOFTLINE_SETTINGS_NFC_READER,
            component: NfcReaderSettingsComponent,
            title: '#APPLICATION.NFC_READER.TITLE',
            default: {enabled: false},
            icon: 'fa-regular fa-mobile-signal',
          },
        },
      ],
    };
  }
}

@NgModule({
  imports: [
    NfcReaderModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_NFC_READER,
      feature: NfcReader.feature,
    }),
  ],
})
export class NfcReaderRootModule {
  constructor(store: Store,
              @Optional() reader?: NfcReaderService) {
    this.init(store, reader);
  }

  async init(store: Store, reader?: NfcReaderService): Promise<void> {
    if(reader)
      await reader.init();
  }
}
