import { inject, Injectable } from "@angular/core";
import { Store } from "@softline/core";
import { Router } from "@angular/router";

type Constructor<T> = new(...args: any[]) => T;

export const WithStore = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class StoreMixin extends Base {

    store = inject(Store);

    constructor(...args: any[]) {
      super(...args);
    }
  }
  return StoreMixin as unknown as T & {store: Store};
}
