import {createAction, CustomStoreAddOn, ObjectStore, on, StoreBuilder} from "@softline/core";
import {Empfaenger} from '../types/empfaenger';
import {SendNotificationService} from '../services/send-notification.service';
import {lastValueFrom} from 'rxjs';
import {SendNotificationResult} from '../types/send-notification-result';

export interface SendNotificationState {
  betreff?: string;
  nachricht?: string;
  empfaenger: Empfaenger[];
}

const mutations = {}
const getters = {}
const actions = {
  send: createAction<SendNotificationState, void, SendNotificationResult>('softapps/allgemein/sendNotification/nachrichtVerfassen')
}

const objectStore = ObjectStore.create<SendNotificationState>()

const storeExtension: CustomStoreAddOn<SendNotificationState, typeof mutations, typeof getters, typeof actions> = {
  actions,
  mutations,
  getters,
  feature: {
    initialState: {
      betreff: undefined,
      nachricht: undefined,
      empfaenger: [],
    },
    actions: [
      on(actions.send, async ({ get, featureName, injector }) => {
        const service = injector.get(SendNotificationService);
        const data = get(featureName, objectStore.getters.data) ?? {};

        const result = await lastValueFrom(
          service.send({
            betreff: data?.betreff ?? '',
            inhalt: data?.nachricht,
            empfaengerIdanwender: data?.empfaenger?.map(o => o.id) ?? []
          })
        );

        return result;
      })
    ]
  }
}

export const SOFTLINE_FEATURE_SEND_NOTIFICATION = 'softapps/allgemein/sendNotification';

export const SendNotificationStore = new StoreBuilder(objectStore)
  .add(storeExtension)
  .value;
