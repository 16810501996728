import {
  ConnectionHttpService,
  ObjectStore2, ReadonlyHttpApiCollectionRepository,
  ReadonlyRepositoryObjectStore2,
  SOFTLINE_REPOSITORY,
  Store2Builder,
  SubscriptionStore2
} from '@softline/core';
import { InjectionToken } from '@angular/core';
import { ScanObjektService } from './scan-objekt.service';
import { Objekt } from './objekt';

const scanObjektStore = new Store2Builder('objectScan')
  .withSubFeature('object', ObjectStore2<Objekt>)
  .withSubFeature('subscription', SubscriptionStore2)
  .withSubFeature('repository', ReadonlyRepositoryObjectStore2<Objekt>, [
    {
      provide: SOFTLINE_REPOSITORY,
      useExisting: ScanObjektService
    }
  ]);

export const ScanObjektStore2 = new InjectionToken(
  'ScanObjektStore2',
  {providedIn: 'root', factory: () => scanObjektStore.build()}
)
