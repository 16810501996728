import { Component } from '@angular/core';
import { Store } from '@softline/core';
import * as ModalStore from '../modal.store';
import { SOFTLINE_FEATURE_MODAL } from '../modal.shared';

@Component({
  selector: 'soft-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent {
  configs$ = this.store.observe(
    SOFTLINE_FEATURE_MODAL,
    ModalStore.getters.items
  );

  constructor(private store: Store) {}
}
