import { Pipe, PipeTransform } from '@angular/core';

const MAP = {
  0: 'menge',
  1: 'netto',
  2: 'dbproz',
  3: 'mengeEinkauf',
}

const MAP_VGL = {
  0: 'menge',
  1: 'netto',
  2: 'dbproz',
  3: 'mengeEinkauf',
}

@Pipe({
  name: 'umsatzValueForIndex',
  pure: true,
  standalone: true
})
export class UmsatzValueForIndexPipe implements PipeTransform {
  transform(value: object, selectedIndex: number, vergleich: boolean = false): number {
    const property = vergleich ? MAP_VGL[selectedIndex] : MAP[selectedIndex];
    return value[property] ?? 0;
  }
}
