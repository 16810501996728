import { Observable } from "rxjs";
import { Template } from "../template";

export abstract class QueryHistoryService {
  constructor() {}

  abstract loadMany(group: string | undefined): Observable<Template<any>[]>

  abstract create(template: Template<any>): Observable<Template<any>>

  abstract delete(template: Template<any>): Observable<Template<any>>
}
