<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
  <h3 class="text-xl font-bold">
    {{ '#SUBKUNDEN.DIALOGS.CREATE.HEADER' | translate }}
  </h3>

  <div class="flex flex-row items-center border border-gray-200 mt-4 p-2 text-sm">
    <label for="createKostenstelle" class="soft-label font-bold w-32">
      {{'#SUBKUNDEN.DIALOGS.CREATE.NUMBER' | translate}}</label>
    <soft-input
      id="createKostenstelle"
      formControlName="kostenstelle"
      class="ml-4 w-full"
      softFocus
    ></soft-input>
  </div>
  <div class="flex flex-row items-center border border-gray-200 mt-2 p-2 text-sm">
    <label for="createName" class="soft-label font-bold required w-32">
      {{'#SUBKUNDEN.DIALOGS.CREATE.NAME' | translate}}
    </label>
    <soft-input
      id="createName"
      formControlName="name"
      class="ml-4 w-full"
    ></soft-input>
  </div>
  <div class="flex flex-row items-center border border-gray-200 mt-2 p-2 text-sm">
    <label for="createActive" class="soft-label font-bold required w-32">
      {{'#SUBKUNDEN.DIALOGS.CREATE.ACTIVE' | translate}}
    </label>
    <soft-toggle-switch
      id="createActive"
      formControlName="aktiv"
    ></soft-toggle-switch>
  </div>
  <button type="submit"
          class="soft-button primary mt-4 w-full h-16"
          [disabled]="form.invalid">
    {{ '#SUBKUNDEN.DIALOGS.CREATE.SUBMIT' | translate }}
  </button>
</form>
