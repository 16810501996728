import {Provider} from '@angular/core';
import {SOFTLINE_CONFIG_THEMES} from './appearance.shared';

export function provideDarkTheme(showSystemSyncedThemeOption = true): Provider[] {
  const themeProviders: Provider[] = [{
    provide: SOFTLINE_CONFIG_THEMES,
    multi: true,
    useValue: {
      key: 'dark',
      name: '#APPLICATION.APPEARANCE.THEMES.DARK',
    },
  }];

  if (showSystemSyncedThemeOption) {
    themeProviders.push({
      provide: SOFTLINE_CONFIG_THEMES,
      multi: true,
      useValue: {
        key: 'system',
        name: '#APPLICATION.APPEARANCE.THEMES.SYSTEM',
      },
    });
  }

  return themeProviders;
}
