import {createAction, CustomStoreAddOn,
  FilterCollectionStore, on, SortCollectionStore, StoreBuilder, SyncedRemoteCollectionStore} from '@softline/core';
import {WarenverprobungBewe} from '../data/warenverprobung-bewe';

const collectionStore = SyncedRemoteCollectionStore.create<WarenverprobungBewe>()
type State = typeof collectionStore.feature.initialState;

const getters = {}
const mutations = {}
const actions = {
  createOrUpdate: createAction<State, Omit<WarenverprobungBewe, 'id'>, WarenverprobungBewe>('mdeWarenverprobung/createOrUpdate')
}

export const WarenverprobungBeweStoreExtension: CustomStoreAddOn<
  State,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  getters,
  mutations,
  actions,
  feature: {
    initialState: collectionStore.feature.initialState,
    getters: [],
    mutations: [],
    actions: [
      on(actions.createOrUpdate, async ({ commit, featureName, params, injector }) => {
        const result: WarenverprobungBewe = { ...params, id: params.artikel.id };
        commit(featureName, collectionStore.mutations.addOrUpdate, result);
        return result;
      })
    ],
  }
}

export const WarenverprobungBeweStore = new StoreBuilder(collectionStore)
  .add(WarenverprobungBeweStoreExtension)
  .add(SortCollectionStore.create<WarenverprobungBewe>())
  .add(FilterCollectionStore.create<WarenverprobungBewe>())
  .value
