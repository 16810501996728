import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary, getValue, interpolate, Store } from "@softline/core";
import { isValueReference } from '../data/value-reference';
import { isStoreReference } from "../data/store-reference";

@Pipe({
  name: 'resolveParameters',
  standalone: true,
})
export class ResolveParametersPipe implements PipeTransform {
  constructor(private store: Store) {
  }

  transform(parameters: object | null | undefined, values: object): object {
    const returnValue: Dictionary<unknown> = {};
    if (!parameters) return returnValue;

    for (const [key, value] of Object.entries(parameters)) {
      if (isValueReference(value))
        returnValue[key] = getValue(values, value.path);
      else if (isStoreReference(value)) {
        let storeValue = this.store.get(
          value.feature,
          value.getter ? {type: 'getter', name: value.getter} : undefined,
          value.params);
        if(value.property)
          storeValue = getValue(storeValue, value.property)
        returnValue[key] = storeValue;
      }
      else returnValue[key] = value;
    }
    return returnValue;
  }
}
