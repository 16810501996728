import {ArtikelLagerMenge} from './artikel-lager-menge';

export interface ArtikelLagerInfo {
  id: number;
  itemId: number,
  stock: [
    {
      stock: {
        id: number,
        name: string,
        title: string
      },
      state: 'notAvailable' | 'available'
    }
  ]
  stockAmountInfo: ArtikelLagerMenge | null | undefined
}
