import { Parent } from '../types/parent';

export function buildTree<T extends object>(
  elements: T[],
  idPropertyName: keyof T,
  parentPropertyName: keyof T,
  sort?: (a: T, b: T) => number
): (T & Parent<T>)[] {
  const stack = elements
    .filter((o) => !o[parentPropertyName])
    .sort(sort)
    .reverse();

  const traverse: (T & Parent<T>)[] = [];
  const result: (T & Parent<T>)[] = [];

  while (stack.length > 0) {
    const el = stack.pop();
    if (!el) throw new Error('Can not build a tree of undefined element');
    const currentElement: T & Parent<T> = { ...el, children: [] };

    while (
      traverse.length > 0 &&
      traverse[traverse.length - 1][idPropertyName] !==
        currentElement[parentPropertyName]
    )
      traverse.pop();
    const parent = traverse.length > 0 ? traverse[traverse.length - 1] : null;
    if (parent) parent.children.push(currentElement);

    const children = elements
      .filter((o) => o[parentPropertyName] === currentElement[idPropertyName])
      .sort(sort)
      .reverse();
    stack.push(...children);

    traverse.push(currentElement);
    if (!currentElement[parentPropertyName]) result.push(currentElement);
  }
  return result;
}
