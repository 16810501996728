export const imageMimeTypes = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/x-icon',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
];

export function isImageMimeType(mimeType: string): boolean {
  return imageMimeTypes.indexOf(mimeType) > -1;
}
