import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { Transaction, base64Encode } from '@softline/core';
import { Subscription } from 'rxjs';
import { SOFTLINE_API_FILE } from '../files/file.api';
import { File } from '../data/file';

@Pipe({
  name: 'isFileDownloading',
  pure: true,
})
export class IsFileDownloadingPipe implements PipeTransform {
  constructor() {}

  transform(
    requests: Transaction<any>[] | undefined | null,
    file: File | undefined | null
  ): boolean {
    if (!requests || requests.length === 0 || !file) return false;

    // TODO: Richtig implementeren
    const path = SOFTLINE_API_FILE + base64Encode(JSON.stringify(file));
    const request = requests.find((o) => o.params.file === file);
    return request?.state === 'processing' || request?.state === 'pending';
  }
}
