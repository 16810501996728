import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {AccordionItemHeaderDirective} from '../../directives/accordion-item-header/accordion-item-header.directive';
import {AccordionItemContentDirective} from '../../directives/accordion-item-content/accordion-item-content.directive';
import {AccordionComponent} from '../../accordion.component';

@Component({
  selector: 'soft-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionItemComponent implements AfterContentInit {

  private contentInitialized!: () => void;
  private waitForContentInit = new Promise<void>(resolve => this.contentInitialized = resolve);

  @ViewChild('accordion') template!: TemplateRef<any>

  @Input() class = '';

  @Input()
  set expanded(value: boolean) {
    this.waitForContentInit.then(() => {
      const isExpanded = this.accordion.isExpanded(this);

      if (value !== isExpanded)
        this.toggle();
    })
  }
  get expanded(): boolean {
    return this.accordion.isExpanded(this);
  }

  @Output() expandedChange = new EventEmitter<boolean>();

  @ContentChild(AccordionItemHeaderDirective) header!: AccordionItemHeaderDirective;
  @ContentChild(AccordionItemContentDirective) content!: AccordionItemContentDirective;

  constructor(readonly accordion: AccordionComponent) { }

  toggle(): void {
    this.accordion.toggle(this);
    this.expandedChange.emit(this.expanded);
  }

  ngAfterContentInit(): void {
    this.contentInitialized()
  }
}
