<div class="soft-master-view"
     (touchstart)="preventScrolling($event, master)"
     [class.hide-on-mobile]="selected"
      #master>
  <ng-content select=".soft-master-view-title"></ng-content>
  <ul>
    <ng-container *ngIf="!loading; else skeleton">
      <li
        *ngFor="let value of data | take: (maxItems * page); let i = index"
        (click)="select(i)"
        (dblclick)="masterDblClick.emit(value)"
        class="pointer soft-master-view-item"
        [class.soft-master-view-selected]="selectedIndex === i"
      >
        <ng-container
          *ngTemplateOutlet="masterTemplate; context: { data: value }"
        ></ng-container>
      </li>
    </ng-container>
    <ng-template #skeleton>
      <li *softRepeat="skeletonRows" class="pb-2 px-2">
        <ng-container *ngIf="masterSkeleton; else defaultSkeleton">
          <ng-container
            *ngTemplateOutlet="masterSkeleton"
          ></ng-container>
        </ng-container>
        <ng-template #defaultSkeleton>
          <div class="flex flex-col gap-2">
            <div class="h-4 w-[50%] skeleton"></div>
            <div class="h-4 w-full skeleton"></div>
            <div class="h-4 w-[75%] skeleton"></div>
          </div>
        </ng-template>
      </li>
    </ng-template>
  </ul>
  <div
    *ngIf="(data?.length ?? 0) - (maxItems * page) > 0"
    class="c row center soft-text-xs color lighter hover:underline cursor-pointer"
    (click)="onMoreItems()"
  >
    {{
      moreItemsLabel
        | translate
        | interpolate: { count: data.length - (maxItems * page) }
    }}
  </div>
</div>
<div class="soft-detail-view flex flex-col flex-grow"
     (touchstart)="preventScrolling($event, detail)"
     [class.hide-on-mobile]="!selected"
     #detail>
  <ng-container *ngIf="selected; else detailPlaceholder">
    <ng-container
      *ngTemplateOutlet="detailTemplate; context: { data: selected }"
    ></ng-container>
  </ng-container>
</div>
