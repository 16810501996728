import { Component, Input, OnInit } from '@angular/core';
import { ValidationMessage } from '@softline/core';

@Component({
  selector: 'soft-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent implements OnInit {
  @Input() message?: ValidationMessage;

  constructor() {}

  ngOnInit(): void {}
}
