export const imageFileEndings = [
  '.apng',
  '.bmp',
  '.gif',
  '.ico',
  '.cur',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.pjpeg',
  '.pjp',
  '.png',
  '.svg',
  '.tif',
  '.tiff',
  '.webp',
];

export function isImage(src: string): boolean {
  const match = /\.\w+$/.exec(src);
  if (!match) return false;
  return imageFileEndings.indexOf(match[0]) > -1;
}
