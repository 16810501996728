import {Inject, Injectable, signal} from '@angular/core';
import {Store} from '@softline/core';
import {Appearance} from '../data/appearance';
import {Subscription} from 'rxjs';
import {SOFTLINE_FEATURE_SETTINGS} from '../../application.shared';
import * as SettingsStore from '../../settings/settings.store';
import {SOFTLINE_CONFIG_THEMES, SOFTLINE_SETTINGS_APPEARANCE} from '../appearance.shared';
import {DOCUMENT} from '@angular/common';
import {Theme} from '../data/theme';

@Injectable()
export class AppearanceService {
  private subscription: Subscription;
  private appearance?: Appearance;
  private readonly systemDarkModeEnabled = signal(false);

  constructor(
    private store: Store,
    @Inject(SOFTLINE_CONFIG_THEMES)private themes: Theme[],
    @Inject(DOCUMENT) private document: Document
  ) {
    const isSystemDarkModeEnabled = this.getSystemColorThemeMediaList();
    this.systemDarkModeEnabled.set(isSystemDarkModeEnabled?.matches ?? false);
    isSystemDarkModeEnabled?.addEventListener?.('change', this.systemThemeChangeHandler);

    this.subscription = this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<Appearance>(),
        SOFTLINE_SETTINGS_APPEARANCE
      )
      .subscribe((o) => {
        this.apply(o);
      });
  }

  apply(appearance: Appearance | undefined): void {
    this.appearance = appearance;

    const element = this.document.documentElement;

    const isSystemDarkModeEnabled = this.getSystemColorThemeMediaList();
    this.systemDarkModeEnabled.set(isSystemDarkModeEnabled?.matches ?? false);

    if (appearance?.theme === 'system' && this.systemDarkModeEnabled()) {
      element.setAttribute('theme', 'dark');
    } else if (appearance?.theme && appearance?.theme !== 'system') {
      element.setAttribute('theme', appearance?.theme);
    } else {
      element.removeAttribute('theme');
    }

    if (appearance?.fontSize)
      element.setAttribute('style', `font-size:${appearance.fontSize}`);
    else
      element.removeAttribute('style');
  }

  private systemThemeChangeHandler = (e: MediaQueryListEvent) => {
    this.systemDarkModeEnabled.set(e?.matches ?? false);
    this.apply(this.appearance);
  }

  private getSystemColorThemeMediaList(): MediaQueryList {
    return ((document.defaultView as Window) ?? window)?.matchMedia?.('(prefers-color-scheme: dark)');
  }
}
