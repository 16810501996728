import { CdkPortal } from '@angular/cdk/portal';
import { TemplateRef, ViewContainerRef } from '@angular/core';

export abstract class ViewDirective {
  abstract name: string;
  abstract title: string;
  visible = true;
  portal: CdkPortal;

  constructor(template: TemplateRef<any>, viewContainer: ViewContainerRef) {
    this.portal = new CdkPortal(template, viewContainer);
  }
}
