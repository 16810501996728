import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { Modal } from '../../data/modal';
import { dataUriToBlob } from '@softline/core';
import { FileSourceInput } from "@softline/ui-core";

@Component({
  selector: 'soft-file-modal',
  templateUrl: './file-modal.component.html',
  styleUrls: ['./file-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileModalComponent implements Modal<File[] | null> {
  close!: (result: File[] | null) => void;

  title: string | undefined;
  subtitle?: string;
  content?: TemplateRef<any>;
  params?: object;
  showCancel?: boolean;

  sources?: FileSourceInput;
  accept?: string;
  inputView?: 'common' | 'dragAndDrop';
  selectionMode?: 'single' | 'multi';
  valueView?: 'grid' | 'list';
  autoSubmit = false;

  constructor() {}

  registerCloseHandler(handler: (result: File[] | null) => void): void {
    this.close = handler;
  }

  onSubmit(value: File[] | null): void {
    this.close(value);
  }

  onValueChange(value: File[] | null): void {
    if(value && value.length > 0)
      this.onSubmit(value);
  }
}
