import { Signal, Type } from '@angular/core';
import { Observable } from 'rxjs';


export interface Command2 {
  name: string;
  canExecute?: boolean | Observable<boolean>;
  execute: () => Promise<void>;
}

export interface Command2Set {
  name: string | Type<any>;
  commands: Command2[];
}
