import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@softline/core';
import {defer, filter, from, map, Observable, switchMap} from 'rxjs';
import {RemoteConfigStore, SOFTLINE_FEATURE_REMOTE_CONFIG} from '@softline/application';
import {SOFTLINE_FEATURE_MDE_ARTIKELVORRAT} from '../artikelvorrat.shared';
import {ArtikelvorratStore} from '../store/artikelvorrat.store';
import {ArtikelvorratKopf} from '../data/artikelvorrat-kopf';

const MODULE_NAME = 'mde-artikelvorrat'

const KEY_MAP = {
  lagerortEingeben: 'FELD_LAGERORT',
  bestellungEdiFrage: 'BESTELLUNGEN_EDI',
}

type ArtikelvorratRouteData = {
  lagerortEingeben: boolean;
  bestellungEdiFrage: boolean;
  kopf?: ArtikelvorratKopf;
}

@Injectable()
export class ArtikelvorratResolver  {

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArtikelvorratRouteData> {
    return this.store.observe(
      SOFTLINE_FEATURE_REMOTE_CONFIG,
      RemoteConfigStore.getters.data,
    ).pipe(
      filter(o => !!o && !!o?.modules),
      map(data => data?.modules?.find(o => o.module === MODULE_NAME)),
      switchMap((module) =>
        defer(() =>
          from(
            this.store.dispatch(
              SOFTLINE_FEATURE_MDE_ARTIKELVORRAT,
              ArtikelvorratStore.actions.loadKopf
            )
          )
        ).pipe(
          map(kopf => ({ module, kopf })),
        )
      ),
      map(({ module, kopf }) => ({
        kopf,
        bestellungEdiFrage: module?.properties.find(o => o.key === KEY_MAP['bestellungEdiFrage'])?.value === 'frage' ?? false,
        lagerortEingeben: module?.properties.find(o => o.key === KEY_MAP['lagerortEingeben'])?.value === 'eingeblendet' ?? false,
      }))
    );
  }
}
