import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UiCoreModule } from '@softline/ui-core';
import { SOFTLINE_CONFIG_SCANNER } from "../../scanner.shared";
import { ScannerService } from '../../services/scanner.service';
import { ReactiveFormsModule } from "@angular/forms";
import { Scanner } from '../../data/scanner';
import { IntegratedScannerService } from "./services/integrated-scanner.service";
import { IntegratedScannerSettingsComponent } from "./settings/integrated-scanner-settings.component";
import { SOFTLINE_CONFIG_SETTINGS } from "../../../application.shared";
import { SOFTLINE_SETTINGS_INTEGRATED_SCANNER } from "./integrated-scanner.shared";

export function integratedScannerFactory(service: ScannerService): Scanner {
  return {
    name: 'integrated',
    title: 'Hardware (Input)',
    priority: 5,
    settingsComponent: IntegratedScannerSettingsComponent,
    service,
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
    exports: [ ],
})
export class IntegratedScannerModule {
  static forRoot(): ModuleWithProviders<IntegratedScannerModule> {
    return {
      ngModule: IntegratedScannerModule,
      providers: [
        IntegratedScannerService,
        {
          provide: SOFTLINE_CONFIG_SCANNER,
          multi: true,
          useFactory: integratedScannerFactory,
          deps: [IntegratedScannerService],
        },
        {
          provide: SOFTLINE_CONFIG_SETTINGS,
          multi: true,
          useValue: {
            key: SOFTLINE_SETTINGS_INTEGRATED_SCANNER,
            default: {scanEverywhere: false},
          },
        },
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class IntegratedScannerRootModule {}
