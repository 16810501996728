import {
  createAction,
  createGetter,
  createMutation,
  Dictionary,
  mutate,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import { CloudMessagingService } from '../services/cloud-messaging.service';
import { CloudMessagingPermission, SOFTLINE_CONST_TOPIC_PREFIX } from "../cloud-messaging.shared";

export interface Store {
  topics: Dictionary<boolean>;
  permission: CloudMessagingPermission;
}

export const mutations = {
  setPermission: createMutation<Store, CloudMessagingPermission>('setPermission'),
  setTopic: createMutation<Store, { key: string; value: boolean }>('setTopic'),
  setTopics: createMutation<Store, Dictionary<boolean>>('setTopics'),
};

export const actions = {
  init: createAction<Store>('init'),
  subscribe: createAction<Store, undefined, CloudMessagingPermission>('subscribe'),
  unsubscribe: createAction<Store, undefined, boolean>('unsubscribe'),
  loadTags: createAction<Store>('loadTags'),
  setTopic: createAction<Store, { key: string; value: boolean }>('setTopic'),
};
export const getters = {
  topics: createGetter<Store, Dictionary<boolean>>('topics'),
  permission: createGetter<Store, CloudMessagingPermission>('permission'),
};

export const feature: StoreFeature<Store> = {
  initialState: {
    topics: {},
    permission: 'default',
  },
  mutations: [
    mutate(mutations.setTopic, ({ state, params }) => {
      return {
        ...state,
        topics: { ...state.topics, [params.key]: params.value },
      };
    }),
    mutate(mutations.setTopics, ({ state, params }) => {
      return { ...state, topics: params };
    }),
    mutate(mutations.setPermission, ({ state, params }) => {
      return { ...state, permission: params };
    }),
  ],
  actions: [
    on(actions.init, async ({ state, injector, featureName, commit, params }) => {
      const service = injector.get(CloudMessagingService);
      const result = await service.init();
      commit(featureName, mutations.setPermission, result);
    }),
    on(actions.subscribe, async ({ state, injector, featureName, commit, params }) => {
      const service = injector.get(CloudMessagingService);
      const result = await service.subscribe();
      commit(featureName, mutations.setPermission, result);
      return result;
    }),
    on(actions.unsubscribe, async ({ state, injector, featureName, commit, params }) => {
      const service = injector.get(CloudMessagingService);
      const result = await service.unsubscribe();
      return result;
    }),
    on(actions.loadTags, async ({ state, injector, featureName, commit }) => {
      const service = injector.get(CloudMessagingService);
      const tags = await service.getTags();
      const topics = Object.entries(tags)
        .filter(([key, value]) => key.startsWith(SOFTLINE_CONST_TOPIC_PREFIX))
        .reduce((p, [key, value]) => ({ ...p, [key]: value === 'true' }), {});
      commit(featureName, mutations.setTopics, topics);
    }),
    on(actions.setTopic, ({ state, injector, featureName, commit, params }) => {
      const service = injector.get(CloudMessagingService);
      service.setTag(params.key, '' + params.value);
      commit(featureName, mutations.setTopic, params);
    }),
  ],
  getters: [
    select(getters.topics, ({ state }) => state.topics),
    select(getters.permission, ({ state }) => state.permission)
  ],
};
