import { InjectionToken } from '@angular/core';
import { IndexedDBConfig } from './specialized/indexed-db/indexed-db.config';
import { ResourceService } from './abstraction';

export const SOFTLINE_FEATURE_TRANSACTION = 'transaction';

export const SOFTLINE_CONFIG_INDEXED_DB = new InjectionToken<IndexedDBConfig[]>(
  'SOFTLINE_CONFIG_INDEXED_DB'
);

export const SOFTLINE_SERVICE_RESOURCE = new InjectionToken<
  ResourceService<any>
>('SOFTLINE_SERVICE_RESOURCE');

export const SOFTLINE_SERVICE_RESPONSE_CONVERTER = new InjectionToken<
  (response: any) => any
>('SOFTLINE_SERVICE_RESPONSE_CONVERTER');
