<ng-container *ngIf="belegart() as selectedBelegart">
  <div class="flex flex-col full-width sticky z-10 -top-4 bg-surface border-b px-4 -mt-4 pb-3 pt-5">
    <div class="flex w-full justify-between flex-wrap mt-2">
      <div class="flex flex-col">
        <soft-select-box title="#GESAMTABFRAGE.PAGES.QUERY.TYPE"
                         icon="fa-regular fa-file-invoice fa-2x"
                         [value]="selectedBelegart"
                         (valueChange)="onChangeBelegart($event)">
          @for (art of belegarten(); track art.id) {
            <soft-select-box-option [value]="art" [label]="art.kundenName ?? art.name"></soft-select-box-option>
          }
        </soft-select-box>
      </div>
      <div class="flex flex-col">
        <soft-filter-and-sort class="w-full sm:w-[30vw]"
                              [values]="all() ?? []"
                              [filter]="filter()"
                              [filterConfig]="definition() | definitionFilter"
                              [sort]="sort()"
                              [sortConfig]="definition() | definitionSort"
                              (filterChange)="setFilter($event)"
                              (sortChange)="setSort($event)"></soft-filter-and-sort>
        <span class="text-sm color-light">{{ total() }}
          {{ '#GESAMTABFRAGE.PAGES.LIST.RESULTS' | translate }}</span
        >
      </div>
    </div>
    <div class="flex w-full flex-row mt-4 items-center">
      <soft-icon class="mr-4" name="fa-regular fa-money-check"></soft-icon>
      <soft-dynamic-filter
        [definition]="$any(queryDefinition())"
        [value]="$any(queryValue())"
        (valueChange)="onQueryChange($event, selectedBelegart)"
      >
      </soft-dynamic-filter>
    </div>
  </div>

  <div class="w-full overflow-x-auto mt-4">
    <soft-dynamic-table
      selectionMode="multi"
      tableClass="soft-table no-border"
      class="body-border divided"
      [definition]="$any(definition())"
      [rows]="data() ?? []"
      [loading]="loading()"
      (selectedItemsChange)="onSelectedItemsChange($event)"
      [selectedItems]="selected()?? []"
      [footer]="footer() ?? []"
      [sort]="sort()"
      (sortChange)="setSort($event)"
    ></soft-dynamic-table>
  </div>

  <div
    *ngIf="!loading() && loaded() && total() === 0"
    class="soft-container center mt-5"
  >
    {{ '#GESAMTABFRAGE.PAGES.LIST.NO_DATA' | translate }}
  </div>
</ng-container>
