import { Component, Input, OnInit } from '@angular/core';

export interface LoadingBarConfiguration {
  backgroundColor: string;
  animation: string;
}

@Component({
  selector: 'soft-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
