import { Dictionary, SOFTLINE_SERVICE_UUID } from "@softline/core";
import { Subscription } from "rxjs";
import { Inject, Injectable } from "@angular/core";

export interface MediatorMessage {
  name: string,
  args?: unknown[]
}

@Injectable()
export class Mediator {
  private subscriptions: {
    id: string,
    subscription: Subscription,
    func: (message: MediatorMessage) => void | Promise<void>}[] = []

  constructor(@Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string) {
  }

  subscribe(func: (message: MediatorMessage) => void | Promise<void>): Subscription {
    const id = this.uuid();
    const subscription = new Subscription(() => {
      const index = this.subscriptions.findIndex(o => o.id === id);
      this.subscriptions.splice(index, 1);
    })
    this.subscriptions.push({id, subscription, func});
    return subscription;
  }

  async next(message: MediatorMessage): Promise<void> {
    for (const subscription of this.subscriptions) {
      const result = subscription.func(message);
      if(result instanceof Promise) {
        await result;
      }
    }
  }
}
