import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UiCoreModule } from '@softline/ui-core';
import { InputScannerComponent } from './components/input-scanner.component';
import { Scanner } from '../../data/scanner';
import { SOFTLINE_CONFIG_SCANNER } from '../../scanner.shared';
import { InputScannerService } from './services/input-scanner.service';
import { ScannerService } from '../../services/scanner.service';
import { ReactiveFormsModule } from "@angular/forms";
import { ScanHistoryComponent } from "../../components/scan-history/scan-history.component";

export function inputScannerFactory(service: ScannerService): Scanner {
  return {
    name: 'input',
    title: 'Eingabe (Text)',
    priority: -1,
    service,
  };
}

@NgModule({
  declarations: [InputScannerComponent],
  imports: [
    CommonModule,
    UiCoreModule,
    ReactiveFormsModule,
    ScanHistoryComponent
  ],
    exports: [
      InputScannerComponent
    ],
})
export class InputScannerModule {
  static forRoot(): ModuleWithProviders<InputScannerRootModule> {
    return {
      ngModule: InputScannerRootModule,
      providers: [
        InputScannerService,
        {
          provide: SOFTLINE_CONFIG_SCANNER,
          multi: true,
          useFactory: inputScannerFactory,
          deps: [InputScannerService],
        },
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class InputScannerRootModule {}
