<p class="font-semibold">
  {{ '#APPLICATION.GEOLOCATION.SETTINGS.CURRENT_POSITION' | translate }}
</p>

<div
  *ngIf="position$ | async as position; else noPositionTemplate"
  class="flex flex-col"
>
  <span class="mt-2">
    <span class="font-semibold mr-2"
      >{{ '#APPLICATION.GEOLOCATION.SETTINGS.LONGITUDE' | translate }}:</span
    >
    {{ position.gpslaenge }}
  </span>
  <span class="mt-2">
    <span class="font-semibold mr-2"
      >{{ '#APPLICATION.GEOLOCATION.SETTINGS.LATITUDE' | translate }}:</span
    >
    {{ position.gpsbreite }}
  </span>
  <a
    class="soft-link mt-2"
    target="_blank"
    [attr.href]="position | geolocationLink"
  >
    {{ '#APPLICATION.GEOLOCATION.SETTINGS.OPEN_IN_MAPS' | translate }}
  </a>
  <span>
    <button class="soft-button primary mt-4" (click)="onGetCurrentPosition()">
      {{ '#APPLICATION.GEOLOCATION.SETTINGS.GET_CURRENT_POSITION' | translate }}
    </button>
  </span>
</div>

<ng-template #noPositionTemplate>
  <p class="text-sm color-light mt-1">
    {{ '#APPLICATION.GEOLOCATION.SETTINGS.NO_POSITION' | translate }}
  </p>
  <button class="soft-button primary mt-4" (click)="onGetCurrentPosition()">
    {{ '#APPLICATION.GEOLOCATION.SETTINGS.GET_CURRENT_POSITION' | translate }}
  </button>
</ng-template>
