import { Injectable } from '@angular/core';
import { Store } from '../store';
import { LocalStorageService } from '../../data/specialized/storage/local-storage.service';
import { Logger } from '../../log/logger';
import { lastValueFrom } from 'rxjs';

const SOFTLINE_KEY_STORE = 'store';

@Injectable()
export class LocalStorageSyncService {
  constructor(
    private store: Store,
    private service: LocalStorageService,
    private logger: Logger
  ) {
    this.init();
  }

  async init(): Promise<void> {
    const newStore = this.store as any;
    try {
      await lastValueFrom(this.service.get({ key: SOFTLINE_KEY_STORE })).then(
        (s) => {
          this.logger.log('State Loaded from local store');
          newStore.state.next(s);
        }
      );
    } catch (e) {
      this.logger.log("Couldn't load state from local store");
    }
    newStore.state.subscribe((state: any) =>
      this.service.update({ key: SOFTLINE_KEY_STORE }, state)
    );
  }
}
