import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, signal} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Einheiteneingabe, EinheiteneingabeEinheitMenge} from "../types/einheiteneingabe";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {Modal, UiCoreModule} from "@softline/ui-core";
import {BehaviorSubject, catchError, concatMap, filter, of, pairwise, startWith, switchMap} from "rxjs";
import {map} from "rxjs/operators";
import {EinheiteneingabeService} from "../services/einheiteneingabe.service";
import {handleRequestErrors} from "@softline/application";
import {Store} from '@softline/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface EinheiteneingabeParams {
  idartstamm: number
  menge: number;
  idbasisEinheit: number;
}

@Component({
  selector: 'soft-einheiteneingabe-dialog',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, UiCoreModule],
  templateUrl: './einheiteneingabe-dialog.component.html',
  styleUrls: ['./einheiteneingabe-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EinheiteneingabeDialogComponent implements OnInit, Modal<Einheiteneingabe | null> {

  private _params$ = new BehaviorSubject<EinheiteneingabeParams | null>(null);

  private readonly value$ = this._params$.pipe(
      filter((o): o is EinheiteneingabeParams => !!o),
      switchMap(params =>
          this.service.initialisieren(params).pipe(
              map(value => this.createForm(value)),
              switchMap(form => {
                this.loading.set(false);

                return form.valueChanges.pipe(
                  startWith(form.value),
                  pairwise(),
                  map(([previous, current]) => this.computeFormState(previous, current)),
                  concatMap((formState: Einheiteneingabe) => this.service.umrechnen({
                    ...formState,
                    erfassungsEinheit: {
                      einheit: { id: params.idbasisEinheit } as EinheiteneingabeEinheitMenge['einheit'],
                      menge: 0
                    } as EinheiteneingabeEinheitMenge
                  }))
                );
              }),
              catchError((e) => {
                handleRequestErrors(this.store, e);
                this.loading.set(false);
                return of(null)
              })
          )
      ),
  )

  form?: FormGroup;
  close!: (result: Einheiteneingabe | null) => void;

  readonly loading = signal(false);

  set params(params: EinheiteneingabeParams) {
    this.loading.set(true);
    this._params$.next(params);
  }

  constructor(
      private readonly fb: FormBuilder,
      private readonly store: Store,
      private readonly destroyRef: DestroyRef,
      private readonly cdRef: ChangeDetectorRef,
      private readonly service: EinheiteneingabeService
  ) {}

  ngOnInit(): void {
    this.value$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newFormState => {
        console.log('patch value...', newFormState);
        this.form?.patchValue(newFormState as any, { emitEvent: false });
        this.cdRef.markForCheck();
      });
  }

  registerCloseHandler(handler: (result: Einheiteneingabe | null) => void) {
    this.close = handler;
  }

  retry(): void {
    this._params$.next(this._params$.value);
  }

  private createForm(value: Einheiteneingabe): FormGroup {
    this.form = this.fb.nonNullable.group({
      artikel: [value.artikel],
      ideinheitEingabe: [null],
      summeEinheit: this.createEinheitMengeFormGroup(value.summeEinheit),
      erfassungsEinheit: this.createEinheitMengeFormGroup(value.erfassungsEinheit),
      einheiten: this.fb.array<FormGroup>(
        value.einheiten.map(mengeEinheit =>
          this.createEinheitMengeFormGroup(mengeEinheit)
        )
      ),
    });

    return this.form!;
  }

  private createEinheitMengeFormGroup(mengeEinheit: EinheiteneingabeEinheitMenge): FormGroup {
    return new FormGroup({
      einheit: new FormControl(mengeEinheit.einheit),
      menge: new FormControl(mengeEinheit?.menge as never)
    })
  }

  private computeFormState(previousFormValue: Einheiteneingabe, currentFormValue: Einheiteneingabe): Einheiteneingabe {
    let change: EinheiteneingabeEinheitMenge | null = null;

    for (const [index, einheitMenge] of previousFormValue['einheiten'].entries()) {
      const currentEinheitMenge = currentFormValue['einheiten'][index]
      if (einheitMenge.menge !== currentEinheitMenge?.menge) {
        change = currentEinheitMenge;
        break;
      }
    }

    return {
      ...currentFormValue,
      ideinheitEingabe: change?.einheit?.id
    }
  }
}
