import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'soft-combo-box-option',
  standalone: true
})
export class ComboBoxOptionDirective {
  @Input() value: any;

  constructor(readonly element: ElementRef) {}
}
