import {
  Component,
  EventEmitter,
  input,
  output,
  Output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTemplateFieldListComponent } from '../dynamic-template-field-list/dynamic-template-field-list.component';
import { Template } from '@softline/application';
import { Definition, FormDefinition } from '@softline/dynamic';
import { UiCoreModule } from '@softline/ui-core';

@Component({
  selector: 'soft-template-card',
  standalone: true,
  imports: [CommonModule, DynamicTemplateFieldListComponent, UiCoreModule],
  templateUrl: './template-card.component.html',
  styleUrl: './template-card.component.scss',
})
export class TemplateCardComponent {
  maxHeight = signal('4rem');

  template = input.required<Partial<Template<any>>>();
  definition = input.required<FormDefinition>();

  click = output();
}
