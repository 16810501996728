import { createGetter, createMutation, mutate, select } from '../../factories';
import { StoreFeature } from '../../store';
import { StoreCommit } from '../../abstraction';
import { Dictionary } from '../../../types/dictionary';
import { isDefined } from '../../../functions/is-defined.function';
import { Observable } from 'rxjs';
import { CancelledError } from '../../../types/errors';

export type ActionState =
  | 'pending'
  | 'processing'
  | 'succeeded'
  | 'failed'
  | 'cancelled';

export interface Action {
  action: string;
  state: ActionState;
}

export interface State {
  actions: Dictionary<Action>;
}

export const mutations = {
  add: createMutation<
    State,
    { key: string; action: string; state: ActionState }
  >('add action'),
  setState: createMutation<State, { key: string; state: ActionState }>(
    'action set state'
  ),
};

export const getters = {
  state: createGetter<State, ActionState, string>('action state'),
  active: createGetter<State, boolean, string | undefined>('action active'),
  completed: createGetter<State, boolean, string | undefined>(
    'action completed'
  ),
  cancelled: createGetter<State, boolean, string | undefined>(
    'action cancelled'
  ),
  failed: createGetter<State, boolean, string | undefined>('action error'),
  hasState: createGetter<
    State,
    boolean,
    { actions: string[]; states: ActionState[]; id?: string }
  >('action has state'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    actions: {},
  },
  mutations: [
    mutate(mutations.add, ({ state, params }) => {
      if (state.actions[params.key])
        throw new Error(
          `ActionStore - add action: There is already an action with key ${params.key}`
        );
      const actions = {
        ...state.actions,
        [params.key]: { action: params.action, state: params.state },
      };
      return { ...state, actions };
    }),
    mutate(mutations.setState, ({ state, params }) => {
      const actions = { ...state.actions };
      const action = state.actions[params.key];
      if (!isDefined(action))
        throw new Error(
          `ActionStore - remove subscription: No action with key ${params} not found`
        );

      const newAction = { ...action };
      newAction.state = params.state;
      actions[params.key] = newAction;
      return { ...state, actions };
    }),
  ],
  getters: [
    select(getters.state, ({ state, params }) => state.actions[params]?.state),
    select(getters.active, ({ state, params }) => {
      if (!isDefined(params))
        return Object.values(state.actions).some(
          (o) =>
            isDefined(o) && (o.state === 'pending' || o.state === 'processing')
        );
      const action = state.actions[params];
      return action?.state === 'pending' || action?.state === 'processing';
    }),
    select(getters.completed, ({ state, params }) => {
      if (!isDefined(params))
        return Object.values(state.actions).some(
          (o) =>
            isDefined(o) && (o.state === 'succeeded' || o.state === 'failed')
        );
      const action = state.actions[params];
      return action?.state === 'succeeded' || action?.state === 'failed';
    }),
    select(getters.cancelled, ({ state, params }) => {
      if (!isDefined(params))
        return Object.values(state.actions).some(
          (o) => isDefined(o) && o.state === 'cancelled'
        );
      const action = state.actions[params];
      return action?.state === 'cancelled';
    }),
    select(getters.failed, ({ state, params }) => {
      if (!isDefined(params))
        return Object.values(state.actions).some(
          (o) => isDefined(o) && o.state === 'failed'
        );
      const action = state.actions[params];
      return action?.state === 'cancelled';
    }),
    select(getters.hasState, ({ state, params }) => {
      if (isDefined(params.id)) {
        const action = state.actions[params.id];
        return (
          isDefined(action) &&
          params.actions.includes(action.action) &&
          params.states.includes(action.state)
        );
      }
      return Object.values(state.actions).some(
        (o) =>
          isDefined(o) &&
          params.actions.includes(o.action) &&
          params.states.includes(o.state)
      );
    }),
  ],
  actions: [],
};

export async function handleActionState<T>(
  promise: Promise<T>,
  featureName: string,
  commit: StoreCommit,
  action: string,
  token: string
): Promise<T> {
  try {
    commit(featureName, mutations.add, {
      key: token,
      action,
      state: 'processing',
    });
    const result = await promise;
    commit(featureName, mutations.setState, { key: token, state: 'succeeded' });
    return result;
  } catch (e) {
    if (e instanceof CancelledError)
      commit(featureName, mutations.setState, {
        key: token,
        state: 'cancelled',
      });
    else
      commit(featureName, mutations.setState, { key: token, state: 'failed' });
    throw e;
  }
}

export function handleObservableActionState<T>(
  observable: Observable<T>,
  featureName: string,
  commit: StoreCommit,
  action: string,
  token: string
): Observable<T> {
  commit(featureName, mutations.add, { key: token, action, state: 'pending' });
  return new Observable<T>((observer) => {
    commit(featureName, mutations.setState, {
      key: token,
      state: 'processing',
    });
    observable.subscribe(
      (o) => observer.next(o),
      (error) => {
        if (error instanceof CancelledError)
          commit(featureName, mutations.setState, {
            key: token,
            state: 'cancelled',
          });
        else
          commit(featureName, mutations.setState, {
            key: token,
            state: 'failed',
          });
        observer.error(error);
      },
      () => {
        commit(featureName, mutations.setState, {
          key: token,
          state: 'succeeded',
        });
        observer.complete();
      }
    );
  });
}
