import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {RemoteConfigResolver} from '@softline/application';

export const umsatzstatistikRoutes: Routes = [
  {
    path: 'umsatzstatistik',
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/umsatzstatistik.component'),
        children: [
          {
            path: 'query',
            loadComponent: () => import('./pages/query/query.component')
          },
          {
            path: 'result',
            loadComponent: () => import('./pages/result/result.component')
          },
          {
            path: '**',
            redirectTo: '/umsatzstatistik/query'
          },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    resolve: {  config: RemoteConfigResolver, }
    // data: { permission: SOFTLINE_PERMISSION_WWS_UMSATZSTATISTIK }
  },
];
