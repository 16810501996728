import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Logger, Store } from '@softline/core';
import { Inject, Injectable, Optional } from '@angular/core';
import {
  SOFTLINE_CONFIG_AUTHORIZATION_DENIED_ROUTE,
  SOFTLINE_FEATURE_AUTHORIZATION,
} from './authorization.const';
import * as AuthorizationStore from './authorization.store';
import * as JwtAuthenticationStore from '../authentication/jwt-authentication/jwt-authentication.store';
import { SOFTLINE_FEATURE_JWT_AUTHENTICATION } from '../authentication/jwt-authentication/jwt-authentication.shared';
import { SOFTLINE_CONFIG_AUTHENTICATION_ROUTE } from "../authentication/authentication.shared";

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard
  
{
  private authToken: string = '';

  constructor(
    private store: Store,
    private router: Router,
    private logger: Logger,
    @Optional()
    @Inject(SOFTLINE_CONFIG_AUTHORIZATION_DENIED_ROUTE)
    private authorizationDeniedRoute: string | undefined,
    @Optional()
    @Inject(SOFTLINE_CONFIG_AUTHENTICATION_ROUTE)
    private authenticationRoute: string | undefined
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.isAuthorized(route.data['permission'], state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.isAuthorized(childRoute.data['permission'], state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return this.isAuthorized(
      (route.data ?? {})['permission'],
      segments.map((o) => o.path).join('/')
    );
  }

  private async isAuthorized(
    permission?: string,
    url?: string
  ): Promise<boolean> {
    const authToken = this.store.get(
      SOFTLINE_FEATURE_JWT_AUTHENTICATION,
      JwtAuthenticationStore.getters.token
    );
    if (authToken !== this.authToken) {
      this.store.commit(
        SOFTLINE_FEATURE_AUTHORIZATION,
        AuthorizationStore.mutations.reset
      );
      try {
        await this.store.dispatch(
          SOFTLINE_FEATURE_AUTHORIZATION,
          AuthorizationStore.actions.load
        );
      }
      catch (e) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_JWT_AUTHENTICATION,
          JwtAuthenticationStore.actions.logout
        );
        await this.router.navigate([this.authenticationRoute]);
        return false;
      }
      this.authToken = authToken;
    }
    if (!permission) return true;
    const isAuthorized = this.store.get(
      SOFTLINE_FEATURE_AUTHORIZATION,
      AuthorizationStore.getters.authorized,
      permission
    );

    if (!isAuthorized) {
      console.warn(
        `[AuthorizationGuard] User not Authorized to access ${url} (requires permission: ${permission})`
      );
      await this.router.navigate([this.authorizationDeniedRoute]);
      return false;
    }
    return true;
  }
}
