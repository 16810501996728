<header class="flex flex-col w-full bg-surface">
  <div class="w-full flex flex-col">
    <span *ngIf="overline" class="soft-overline">
      {{overline}}
    </span>
    <span *ngIf="title" class="font-bold">
      {{title}}
    </span>
    <span *ngIf="subtitle" class="text-light text-xs mt-1">
      {{subtitle}}
    </span>
  </div>
  <ng-content></ng-content>
</header>
