<ng-container [ngSwitch]="skeleton.type">
  <ng-container *ngSwitchCase="'text'">
    <ng-container>
      <div
        aria-busy="true"
        aria-roledescription="loading-state"
        role="progressbar"
        class="soft-skeleton-loading"
        [ngStyle]="{ width: skeleton.width || '100%' }"
      ></div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'circle'">
    <ng-container>
      <div
        aria-busy="true"
        aria-roledescription="loading-state"
        role="progressbar"
        class="soft-skeleton-loading"
        [ngStyle]="{
          height: skeleton.radius || '50px',
          width: skeleton.radius || '50px',
          borderRadius: skeleton.radius || '50px'
        }"
      ></div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'rectangle'">
    <ng-container>
      <div
        aria-busy="true"
        aria-roledescription="loading-state"
        role="progressbar"
        class="soft-skeleton-loading"
        [ngStyle]="{
          height: skeleton.height || '50px',
          width: skeleton.width || '70px',
          borderRadius: skeleton.borderRadius || '0'
        }"
      ></div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'circle'">
    <ng-container>
      <div
        aria-busy="true"
        aria-roledescription="loading-state"
        role="progressbar"
        class="soft-skeleton-loading"
        [ngStyle]="{
          height: skeleton.size || '50px',
          width: skeleton.size || '50px'
        }"
      ></div>
    </ng-container>
  </ng-container>
</ng-container>
