import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UiCoreModule } from "@softline/ui-core";
import { Html5ScannerSettings, Html5ScannerSource } from "../types/html5-scanner.settings";
import { SettingsStore, SOFTLINE_FEATURE_SETTINGS } from "@softline/application";
import { Subscription } from "rxjs";
import { Store } from "@softline/core";
import { SOFTLINE_SETTINGS_HTML5_SCANNER } from "../html5-scanner.shared";
import {
  QRBoxDimension,
  SOFTLINE_CONST_QRBOX_DIMENSION_250,
  SOFTLINE_CONST_QRBOX_DIMENSION_250w,
  SOFTLINE_CONST_QRBOX_DIMENSION_500,
  SOFTLINE_CONST_QRBOX_DIMENSION_500h
} from "../types/qr-box-dimensions";
import {
  SOFTLINE_CONST_SCANNER_ASPECT_RATIO_0p5,
  SOFTLINE_CONST_SCANNER_ASPECT_RATIO_1,
  SOFTLINE_CONST_SCANNER_ASPECT_RATIO_1p3, SOFTLINE_CONST_SCANNER_ASPECT_RATIO_1p7
} from "../types/aspect-ratio";

@Component({
  selector: 'soft-html5-scanner-settings',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
  templateUrl: './html5-scanner-settings.component.html',
  styleUrls: ['./html5-scanner-settings.component.scss']
})
export class Html5ScannerSettingsComponent implements OnInit {

  private subscription?: Subscription;

  qrBoxes = [
    SOFTLINE_CONST_QRBOX_DIMENSION_250w,
    SOFTLINE_CONST_QRBOX_DIMENSION_250,
    SOFTLINE_CONST_QRBOX_DIMENSION_500,
    SOFTLINE_CONST_QRBOX_DIMENSION_500h,
  ]

  aspectRatios = [
    SOFTLINE_CONST_SCANNER_ASPECT_RATIO_0p5,
    SOFTLINE_CONST_SCANNER_ASPECT_RATIO_1,
    SOFTLINE_CONST_SCANNER_ASPECT_RATIO_1p3,
    SOFTLINE_CONST_SCANNER_ASPECT_RATIO_1p7,
  ]

  cameras: { id: string, label: string }[] = [];

  form = new FormGroup({
    source: new FormControl<Html5ScannerSource>('file'),
    cameraId: new FormControl<string | null>(null),
    qrBox: new FormControl<QRBoxDimension>(SOFTLINE_CONST_QRBOX_DIMENSION_250),
    fps: new FormControl<number>(10),
    aspectRatio: new FormControl<number>(1)
  })
  constructor(private store: Store) { }

  async ngOnInit(): Promise<void> {
    const devices = await navigator.mediaDevices?.enumerateDevices() ?? [];
    this.cameras = devices.filter(o => o.kind === 'videoinput')
      .map(o => ({id: o.deviceId, label: o.label}));

    this.subscription = this.store
      .observe(
        SOFTLINE_FEATURE_SETTINGS,
        SettingsStore.getters.values<Html5ScannerSettings>(),
        SOFTLINE_SETTINGS_HTML5_SCANNER
      )
      .subscribe((o) => {
        this.form.patchValue(o ?? {}, { emitEvent: false });
        if(this.cameras.length === 0) {
          this.form.patchValue({ source: 'file' });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  set(): void {
    this.store.commit(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.mutations.setKey(),
      { key: SOFTLINE_SETTINGS_HTML5_SCANNER, values: this.form.value }
    );
    this.store.dispatch(SOFTLINE_FEATURE_SETTINGS, SettingsStore.actions.save);
  }

}
