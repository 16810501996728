<div class="flex flex-row justify-between items-center gap-2">
  <ng-container [ngSwitch]="permission$ | async">
    <ng-container *ngSwitchCase="'granted'">
      <div class="flex flex-row items-center gap-2">
        <div class="text-primary min-w-[2rem]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
          </svg>
        </div>
        <div>{{ '#APPLICATION.CLOUD_MESSAGING.SETTINGS.GRANTED' | translate }}</div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'denied'">
      <div class="flex flex-row items-center gap-2">
        <div class="text-danger min-w-[2rem]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div class="flex flex-col">
          <div>{{ '#APPLICATION.CLOUD_MESSAGING.SETTINGS.DENIED' | translate }}</div>
          <div class="text-light">{{ '#APPLICATION.CLOUD_MESSAGING.SETTINGS.DENIED_2' | translate }}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="flex flex-col">
        <span>{{ '#APPLICATION.CLOUD_MESSAGING.SETTINGS.DEFAULT' | translate }}</span>
        <span class="text-light">{{ '#APPLICATION.CLOUD_MESSAGING.SETTINGS.DEFAULT_2' | translate }}</span>
      </div>
      <div>
        <button class="soft-button primary"
                (click)="onSubscribe()">
          {{ '#APPLICATION.CLOUD_MESSAGING.SETTINGS.SUBSCRIBE' | translate }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>


<ng-container *ngIf="topics.length > 0">
  <div *ngIf="topics$ | async as storedTopics">
    <div class="soft-overline">
      {{ '#APPLICATION.CLOUD_MESSAGING.SETTINGS.TOPICS' | translate }}
    </div>
    <div *ngFor="let topic of topics" class="flex justify-between mt-2">
      <span>{{ topic.title | translate }}</span>
      <soft-toggle-switch
        [value]="storedTopics[topic.key]"
        (valueChange)="onTopicChange(topic.key, toggleSwitch.value)"
        #toggleSwitch
      ></soft-toggle-switch>
    </div>
  </div>
</ng-container>

