import { ObjectStore } from "@softline/core"
import { RelatedApp } from "../settings/pwa-install-settings/pwa-install-settings.component";

export interface PwaInstall {
  hasServiceWorker: boolean;
  isPwa: boolean;
  relatedApps: RelatedApp[];
  hasWebShare: boolean;
  canInstall: boolean;
  installed: boolean;
}

export const PwaInstallStore = ObjectStore.create<PwaInstall>();
