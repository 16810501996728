import {
  ActionStore,
  createAction,
  createGetter,
  on,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore,
  SyncedRemoteCollectionStore,
} from '@softline/core';
import {concat, lastValueFrom, tap, toArray} from 'rxjs';
import {map} from 'rxjs/operators';
import {ArtikelDetailService, LoadDetailParameters} from '../services/artikel-detail.service';
import {ArtikelDetail} from '../types/artikel-detail';
import {SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE} from '../artikel.shared';

export type State = SyncedRemoteCollectionStore.State<ArtikelDetail>

const collectionStore = SyncedRemoteCollectionStore.create<ArtikelDetail>();

export interface LoadManyStocksParallelParams {
  ids: number[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ArtikelDetailStore {

  export const mutations = {
    ...collectionStore.mutations
  };

  export const actions = {
    ...collectionStore.actions,
    loadManySequential: createAction<State, LoadManyStocksParallelParams, ArtikelDetail[]>('load many sequential'),
  };

  export const getters = {
    ...collectionStore.getters,
    total: createGetter<State, number>('item stocks total'),
  };

  export const feature: StoreFeature<State> = {
    initialState: {
      ...collectionStore.feature.initialState,
    },
    mutations: [
      ...collectionStore.feature.mutations
    ],
    actions: [
      ...collectionStore.feature.actions,
      on(actions.loadManySequential, async ({featureName, injector, commit, params}) => {
        const service = injector.get(ArtikelDetailService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const sequenceSize = injector.get(SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE);
        let requests: LoadDetailParameters[] = [];

        const ids = params.ids;
        while (ids.length > 0) {
          const size = Math.min(ids.length, sequenceSize);
          requests = [...requests, {ids: ids.splice(0, size)}];
        }

        const sub$ = concat(
          ...requests.map(payload => service.load(payload))
        ).pipe(
          tap(o => commit(featureName, mutations.addOrUpdateMany, o)),
          toArray(),
          map((responses) => responses.map(o => o).reduce((a, b) => ([...a, ...b]))),
        );
        const subState$ = SubscriptionStore.handleSubscriptionState(sub$, featureName, commit, token);
        return await lastValueFrom(ActionStore.handleObservableActionState(
          subState$,
          featureName,
          commit,
          actions.loadManySequential.name,
          token
        ));
      }),
    ],
    getters: [
      ...collectionStore.feature.getters,
    ]
  };
}
