export const SOFTLINE_API_UMSATZABFRAGE_AUFGLIEDERUNG = '/v2/wws/umsatzabfrage/umsatzebeneZeitraumAufgliederung';
export const SOFTLINE_API_UMSATZABFRAGE_EINKAUF_AUFGLIEDERUNG = '/v1/wws/umsatzabfrageeinkauf/umsatzebeneZeitraumAufgliederung';
export const SOFTLINE_API_UMSATZABFRAGE_FILIALEN = '/v2/wws/umsatzabfrage/vkforg';
export const SOFTLINE_API_UMSATZABFRAGE_EINKAUF_FILIALEN = '/v1/wws/umsatzabfrageeinkauf/ekorg';
export const SOFTLINE_API_ITEM_FAVORITE_LISTS = '/v1/items/favorites/list/entities';
export const SOFTLINE_API_ITEM_FAVORITE_LIST_EXPORT = '/v1/items/favorites/list/entities/export';
export const SOFTLINE_API_ITEM_FAVORITE_LIST_DOWNLOAD = '/v1/items/favorites/list/entities/download/{{id}}';
export const SOFTLINE_API_ITEM_FAVORITE_ITEMS = '/v1/items/favorites/entities';
export const SOFTLINE_API_GESAMTABFRAGE_BEWEGUNGEN = '/v2/gesamtabfrage/query';


export const SOFTLINE_API_OBJECT_SCAN = '/v1/objekt/scan/{{labelType}}/{{data}}';
export const SOFTLINE_API_OBJECTS = '/v1/objekt/entities/{{id}}';
