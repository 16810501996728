import { TemplateRef } from "@angular/core";

export interface DeleteConfig {
  title?: string;
  content?: string | TemplateRef<any>;
  question?: string;
  class?: string;
  params?: object;
  dismiss?:
    | boolean
    | {
        backdrop?: boolean;
        escape?: boolean;
      };
}
