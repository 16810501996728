import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilterAndSortComponent } from "@softline/application";
import { DefinitionFilterPipe } from "../../../pipes/definition-filter.pipe";
import { DefinitionSortPipe } from "../../../pipes/definition-sort.pipe";
import { I18nModule, Modal, UiCoreModule, UiCorePipesModule } from "@softline/ui-core";
import { FieldOkConfig, FieldOkView } from "../../../data/field-ok";
import { FieldOkStrategy } from "../strategies/field-ok.strategy";

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
  imports: [CommonModule, UiCoreModule, FilterAndSortComponent, DefinitionFilterPipe, DefinitionSortPipe, I18nModule, UiCorePipesModule],
  templateUrl: './field-ok.dialog.html',
  styleUrls: ['./field-ok.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldOkDialog implements Modal<any>{

  private close!: (result: unknown) => void;

  @Input() componentId: string = '';
  @Input() name: string = '';
  @Input() title: string = '';
  @Input() subtitle?: string;

  @Input() config!: FieldOkConfig;
  @Input() params: object = {}

  constructor(public strategy: FieldOkStrategy<any, any, any>) {
  }

  registerCloseHandler(handler: (result: unknown) => void): void {
    this.close = handler;
  }

  trackByView(index: number, item: FieldOkView<any>){
    return item.name;
  }
}
