import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_SETTINGS,
  SettingsStore,
  SOFTLINE_FEATURE_TITLE,
  TitleStore, Refreshable, RefreshService
} from "@softline/application";
import {Observable, of} from 'rxjs';
import { SOFTLINE_SETTINGS_APP_OVERVIEW } from '../module.shared';
import { NavigationExtras, Params, Router } from "@angular/router";
import {
  SOFTLINE_FEATURE_AUTHORIZATION,
  AuthorizationStore,
} from '@softline/auth';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
  UiCoreModule,
} from '@softline/ui-core';
import { ModuleSettings } from '../module.settings';
import {
  SOFTLINE_FEATURE_APPLICATIONS,
  ApplicationStore,
  Application,
} from '@softline/application';
import { CommonModule } from '@angular/common';
import { AppGridComponent } from './app-grid/app-grid.component';
import { AppListComponent } from './app-list/app-list.component';

@Component({
  standalone: true,
  selector: 'soft-app-overview',
  templateUrl: './app-overview.component.html',
  styleUrls: ['./app-overview.component.scss'],
  imports: [CommonModule, UiCoreModule, AppGridComponent, AppListComponent],
})
export class AppOverviewComponent implements OnInit, OnDestroy, Refreshable {
  settings$ = this.store.observe(
    SOFTLINE_FEATURE_SETTINGS,
    SettingsStore.getters.values<ModuleSettings>(),
    SOFTLINE_SETTINGS_APP_OVERVIEW
  );
  search = '';

  applications$: Observable<readonly Application[]> = this.store.observe(
    SOFTLINE_FEATURE_APPLICATIONS,
    ApplicationStore.getters.items
  );

  permissions$: Observable<readonly string[]> = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.permissions
  );

  constructor(public store: Store, protected router: Router, private refreshService: RefreshService) {}

  async ngOnInit(): Promise<void> {
    this.refreshService.add(this);

    await this.store.dispatch(
      SOFTLINE_FEATURE_AUTHORIZATION,
      AuthorizationStore.actions.loadOnce
    )

    await this.store.dispatch(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.actions.load
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#APP_OVERVIEW.ALL_APPLICATIONS'
    );
  }

  ngOnDestroy(): void {
    this.refreshService.remove(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
  }

  async refresh(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_AUTHORIZATION,
      AuthorizationStore.actions.load
    );
  }

  filterPermissions(application: Application, permissions: readonly string[] | undefined): boolean {
    return (
      !application.permission ||
      (permissions ?? []).indexOf(application.permission) > -1
    );
  }

  filterInput(
    application: Application,
    param?: { store: Store; input: string }
  ): boolean {
    if(!param)
      return false;
    const feature = SOFTLINE_FEATURE_TRANSLATION;
    const selector = TranslationStore.getters.translation;
    return param.store
      .get(feature, selector, application.name)
      .toLowerCase()
      .startsWith(param.input.toLowerCase());
  }

  async navigate(application: Application): Promise<void> {
    await this.router.navigate([application.route], application.routeParams);
  }

  async setMode(mode: 'grid' | 'list'): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.mutations.setKey<ModuleSettings>(),
      { key: SOFTLINE_SETTINGS_APP_OVERVIEW, values: { display: mode } }
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.actions.save,
      { display: mode }
    );
  }
}
