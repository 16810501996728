<div class="bg-surface full-width flex flex-col flex-grow border-t border-t-solid border-t-separator w-full mt-1" *ngIf="favorites$ | async as favorites">
    <li *ngFor="let favorite of favorites; trackBy: trackByFn"
      [ngClass]="{ 'border-b border-b-solid border-b-separator': true }"
      class="w-full flex justify-between nowrap items-center px-4 py-2 gap-2 cursor-pointer">
      <div class="flex flex-col gap-1 w-full" (click)="selectArtikel.emit(favorite.item)">
        <h6 class="font-semibold text-sm">{{ favorite.item.title1 }}</h6>
        <h6 class="font-normal text-sm">{{ favorite.item.title2 || favorite.item.title3 }}</h6>
        <h6 class="font-normal text-sm text-light">{{ favorite.item.number }}</h6>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 opacity-20 scale-90" (click)="selectArtikel.emit(favorite.item)">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
      </svg>
    </li>
</div>
