import { Scan } from './data/scan';
import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import {
  SOFTLINE_CONFIG_SCANNER,
  SOFTLINE_CONFIG_SCANNER_SELECTOR,
} from './scanner.shared';
import { LabelType } from './data/label-type';

export interface State {
  scanning: boolean;
  scans: Scan[];
}

export const mutations = {
  setScanning: createMutation<State, boolean>('setScanning'),
  add: createMutation<State, Scan>('add'),
  clear: createMutation<State, Scan>('clear'),
};

export const actions = {
  scan: createAction<State, { labelType?: LabelType | LabelType[] }, Scan>(
    'scan'
  ),
  cancel: createAction<State>('cancel'),
};

export const getters = {
  scanning: createGetter<State, boolean>('scanning'),
  scans: createGetter<State, Scan[]>('scans'),
  latest: createGetter<State, Scan | undefined>('latest'),
};

export const feature: StoreFeature<State> = {
  initialState: {
    scanning: false,
    scans: [],
  },
  mutations: [
    mutate(mutations.setScanning, ({ state, params }) => {
      return {
        ...state,
        scanning: params,
      }
    }),
    mutate(mutations.add, ({ state, params }) => {
      return {
      ...state,
      scans: [...state.scans, params],
      scanning: false,
    }}),
    mutate(mutations.clear, ({ state }) => ({ ...state, scans: [] })),
  ],
  actions: [
    on(actions.scan, async ({ commit, featureName, injector, params }) => {
      const services = injector.get(SOFTLINE_CONFIG_SCANNER);
      const selector = injector.get(SOFTLINE_CONFIG_SCANNER_SELECTOR);
      const service = selector(services);
      commit(featureName, mutations.setScanning, true);
      try {
        const scan = await service.scan(params.labelType);
        commit(featureName, mutations.add, scan);
        return scan;
      } catch (e) {
        throw e;
      } finally {
        commit(featureName, mutations.setScanning, false);
      }
    }),
    on(actions.cancel, async ({ commit, featureName, injector }) => {
      const services = injector.get(SOFTLINE_CONFIG_SCANNER);
      const selector = injector.get(SOFTLINE_CONFIG_SCANNER_SELECTOR);
      const service = selector(services);
      await service.cancel();
      commit(featureName, mutations.setScanning, false);
    }),
  ],
  getters: [
    select(getters.scanning, ({ state }) => state.scanning),
    select(getters.scans, ({ state }) => state.scans),
    select(getters.latest, ({ state }) => {
      const index = state.scans.length - 1;
      if (index === -1)
        return undefined;
      return state.scans[index];
    }),
  ],
};
