import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@softline/core';
import {combineLatestWith, filter, map, Observable, tap} from 'rxjs';
import {RemoteConfigStore, SOFTLINE_FEATURE_REMOTE_CONFIG} from '@softline/application';
import {SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG} from '../bestellvorschlag.shared';
import {BestellvorschlagStore} from '../store/bestellvorschlag.store';

const MODULE_NAME = 'mde-bestellvorschlag'
const LIEFERTERMIN_KEY = 'LIEFERTERMIN_EINGEBBAR'

@Injectable()
export class BestellvorschlagResolver  {

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ lieferdatum?: string; showLieferdatum: boolean }> | Promise<{ lieferdatum?: string; showLieferdatum: boolean }> | { lieferdatum?: string; showLieferdatum: boolean } {
    return this.store.observe(
      SOFTLINE_FEATURE_REMOTE_CONFIG,
      RemoteConfigStore.getters.data,
    ).pipe(
      filter(o => !!o && !!o?.modules),
      map(data => data?.modules?.find(o => o.module === MODULE_NAME)?.properties?.find(props => props.key === LIEFERTERMIN_KEY)),
      combineLatestWith(this.store.observe(SOFTLINE_FEATURE_MDE_BESTELLVORSCHLAG, BestellvorschlagStore.getters.datum)),
      map(([prop, datum]) => ({
        lieferdatum: datum as string | undefined,
        showLieferdatum: prop?.value === 'true'
      }))
    )
  }
}
