import {Command} from '@softline/application';
import {Store} from '@softline/core';
import {ModalStore, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';
import {Injector} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ArtikelInfoComponent} from '../dialogs/artikel-info/artikel-info.component';
import {ArtikelInfoModalParams} from '../types/artikel-info.modal';

export class ArtikelInfoCommand<T extends object = object> implements Command {
  name = '#WWS_CORE.COMMANDS.ARTIKELINFO.NAME';
  icon = 'fa-regular fa-info';
  class = 'menu action-menu action-menu-top';
  isVisible = of(true);
  canExecute = of(true)

  constructor(
    private store: Store,
    private injector: Injector,
    private id$: Observable<number>,
    private params: ArtikelInfoModalParams<T>
  ) {}

  execute = async (): Promise<void> => {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        dismiss: true,
        component: ArtikelInfoComponent,
        data: {
          id$: this.id$,
          params: this.params
        }
      }
    );
  }
}
