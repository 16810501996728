import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieOptions } from './cookie.options';
import { isDefined } from '../../../functions/is-defined.function';
import { Dictionary } from '../../../types/dictionary';

export interface Document {
  cookie: string;
}

@Injectable()
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  isSet(name?: string): boolean {
    if (this.document.cookie && this.document.cookie === '') return false;

    if (!name)
      return isDefined(this.document.cookie) && this.document.cookie !== '';

    return (this.document.cookie as string)
      .split(';')
      .some((item) => item.trim().startsWith(`${name}=`));
  }

  get(name: string): string {
    if (this.document.cookie && this.document.cookie === '') return '';

    const matches = this.document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : '';
  }

  set(name: string, value: string, options: CookieOptions = {}): string {
    if (!options.path) options.path = '/';
    if (!options.sameSite) options.sameSite = 'Lax';

    this.document.cookie = this.buildCookieString(name, value, options);
    return value;
  }

  delete(
    name: string,
    options: { path?: string; domain?: string } = {}
  ): string {
    const value = this.get(name);
    this.set(name, '', { ...options, maxAge: -1 });
    return value;
  }

  getAll(): Dictionary<string> {
    return this.parseCookie(this.document.cookie);
  }

  deleteAll(): Dictionary<string> {
    const all = this.getAll();
    this.document.cookie
      .split(';')
      .forEach(
        (c) =>
          (document.cookie = c
            .replace(/^ +/, '')
            .replace(
              /=.*/,
              '=;expires=' + new Date().toUTCString() + ';path=/'
            ))
      );
    return all;
  }

  private buildCookieString(
    name: string,
    value: string,
    options: CookieOptions
  ): string {
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;

    if (options?.expires)
      cookie += `path=${new Date(options.expires).toUTCString()};`;
    if (isDefined(options.maxAge)) cookie += `max-age=${options.maxAge};`;
    if (options?.path) cookie += `path=${options.path};`;
    if (options?.domain) cookie += `domain=${options.domain};`;
    if (options?.secure) cookie += `secure;`;
    if (options?.sameSite) cookie += `samesite=${options.sameSite}`;
    return cookie;
  }

  private parseCookie(cookie: string): Dictionary<string> {
    const returnValue: Dictionary<string> = {};
    const items = cookie.split(';');
    for (const item of items) {
      const [key, value] = item.trim().split('=');
      returnValue[key] = value;
    }
    return returnValue;
  }
}
