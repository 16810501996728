import { InjectionToken } from '@angular/core';
import { CheckOption } from '../check-group';

export interface CheckStrategy {
  getValues(options: CheckOption[]): any[];
  setOptions(options: CheckOption[], values: any[] | null | undefined): void;
}

export const CHECK_STRATEGY: InjectionToken<CheckStrategy> = new InjectionToken(
  'CHECK_STRATEGY'
);
