<!-- <ng-container *ngIf="customer$ | async as customer">
  <div class="mb-2 mt-2 title">{{ '#CUSTOMER.PERSON.TITLE' | translate }}</div>
  <section class="soft-container">
    <div class="soft-card soft-card-rounded w-full border-bottom">
      <div class="soft-container space-between pt-4 pb-3">
        <div class="soft-container-column-start">
          <strong>{{ '#CUSTOMER.NUMBER' | translate }}</strong>
        </div>
        <div class="soft-container-column-end">
          {{ customer.nummer || '-' }}
        </div>
      </div>
      <div class="soft-container space-between pt-4 pb-3">
        <div class="soft-container-column-start">
          <strong>{{ '#CUSTOMER.PERSON.GENDER' | translate }}</strong>
        </div>
        <div class="soft-container-column-end">
          {{ customer.anrede || '-' }}
        </div>
      </div>
      <div class="soft-container space-between pt-4 pb-3">
        <div class="soft-container-column-start">
          <strong>{{ '#CUSTOMER.PERSON.NAME' | translate }}</strong>
        </div>
        <div class="soft-container-column-end">
          <span>{{ customer.name1 || '-' }}</span>
          <span *ngIf="customer.name2" class="ml-1">{{ customer.name2 }}</span>
        </div>
      </div>
    </div>
  </section>
  <div class="soft-container space-between mb-2 mt-4">
    <div class="title">{{ '#CUSTOMER.ADDRESS.TITLE' | translate }}</div>
    <a
      target="_blank"
      class="soft-link"
      [attr.href]="getGoogleLink(customer)"
      >{{ '#CUSTOMER.ADDRESS.GOOGLE_MAPS' | translate }}</a
    >
  </div>
  <section class="soft-container">
    <div class="soft-card soft-card-rounded w-full border-bottom">
      <div class="soft-container space-between pt-4 pb-3">
        <div class="soft-container-column-start">
          <strong>{{ '#CUSTOMER.ADDRESS.STREET' | translate }}</strong>
        </div>
        <div class="soft-container-column-end">
          {{ customer.strasse || '-' }}
        </div>
      </div>
      <div class="soft-container space-between pt-4 pb-3">
        <div class="soft-container-column-start">
          <strong>{{ '#CUSTOMER.ADDRESS.NUMBER' | translate }}</strong>
        </div>
        <div class="soft-container-column-end">
          {{ customer.strassenr || '-' }}
        </div>
      </div>
      <div class="soft-container space-between pt-4 pb-3">
        <div class="soft-container-column-start">
          <strong>{{ '#CUSTOMER.ADDRESS.ZIP' | translate }}</strong>
        </div>
        <div class="soft-container-column-end">{{ customer.plz || '-' }}</div>
      </div>
      <div class="soft-container space-between pt-4 pb-3">
        <div class="soft-container-column-start">
          <strong>{{ '#CUSTOMER.ADDRESS.CITY' | translate }}</strong>
        </div>
        <div class="soft-container-column-end">{{ customer.ort || '-' }}</div>
      </div>
    </div>
  </section>
  <ng-container
    *ngIf="customer?.phoneNumberModule?.length || customer?.emailModule?.length"
  >
    <div class="mb-2 mt-4 title">
      {{ '#CUSTOMER.CONTACT.TITLE' | translate }}
    </div>
    <section class="soft-container" *ngIf="customer?.phoneNumberModule?.length">
      <div
        class="soft-card soft-card-rounded w-full border-bottom customer.phoneNumberModule"
      >
        <ng-container *ngFor="let phone of customer?.phoneNumberModule">
          <div class="soft-container space-between pt-4 pb-3">
            <div class="soft-container-column-start">
              <strong>{{ phone.type | translate }}</strong>
            </div>
            <a
              class="soft-link soft-container-column-end"
              href="tel:{{ phone.number }}"
              >{{ phone.number }}</a
            >
          </div>
        </ng-container>
      </div>
    </section>
    <section class="soft-container" *ngIf="customer?.emailModule?.length">
      <div class="soft-card soft-card-rounded w-full border-bottom customer.emailModule">
        <ng-container *ngFor="let emailModule of customer?.emailModule">
          <div class="soft-container space-between pt-4 pb-3">
            <div class="soft-container-column-start">
              <strong>{{ '#CUSTOMER.EMAIL.TITLE' | translate }}</strong>
            </div>
            <a
              class="soft-link soft-container-column-end"
              href="mailto:{{ emailModule.email }}"
              >{{ emailModule.email }}</a
            >
          </div>
        </ng-container>
      </div>
    </section>
  </ng-container>
</ng-container>
-->

<div class="grid dashboard-grid gap-4 mb-5 pb-4">
  @if (breakpoint() !== null || activeTab() === 'kontoinfo') {
    <soft-kundeninfo-leiste class="!col-span-full" [idktostamm]="idktostamm()" [alwaysExpanded]="true"></soft-kundeninfo-leiste>
  }

  @if (breakpoint() !== null || activeTab() === 'kreditlimit') {
    <soft-wws-kreditlimit-widget [idktostamm]="idktostamm()" class="!col-span-1"></soft-wws-kreditlimit-widget>
  }

  @if (breakpoint() !== null || activeTab() === 'umsatz') {
    <soft-umsatzstatistik-widget [kundennummer]="kundennummer()" [idktostamm]="idktostamm()" class="sm:!col-start-2 sm:!col-end-[-1]"></soft-umsatzstatistik-widget>
  }

  @if (breakpoint() !== null || activeTab() === 'offene-posten') {
    <soft-offene-posten-widget [idktostamm]="idktostamm()"></soft-offene-posten-widget>
  }
</div>
