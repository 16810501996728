<textarea
  [value]="
    escapeHtml
      ? (value ?? null | deserializeHtmlCharacterEntities)
      : value ?? null
  "
  [readOnly]="readonly"
  [class]="customClass"
  [rows]="(lines === 'auto' ? valueLines : lines) + 1"
  [ngClass]="[canResize ? 'verticalResize' : 'noResize']"
  placeholder="{{
    placeholder || '#UI_CORE.COMPONENTS.INPUT.PLACEHOLDER' | translate
  }}"
  (input)="setValue(inputElement.value)"
  (blur)="onTouch()"
  #inputElement
></textarea>
