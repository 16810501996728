import { CommonModule } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { SOFTLINE_STORE_FEATURES, Store, StoreModule } from '@softline/core';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule,
} from '@softline/ui-core';
import {
  SOFTLINE_CONFIG_SETTINGS,
  SOFTLINE_FEATURE_SCANNER,
  SOFTLINE_FEATURE_SETTINGS,
} from '../application.shared';
import { Scanner } from './data/scanner';
import { ScanDirective } from './directives/scan.directive';
import {
  SOFTLINE_CONFIG_SCANNER_SELECTOR,
  SOFTLINE_SETTINGS_SCANNER,
  ScannerServiceSelector, SOFTLINE_CONFIG_SCANNER
} from "./scanner.shared";
import { ScannerSettingsComponent } from './settings/scanner-settings.component';
import * as ScannerStore from './scanner.store';
import * as SettingsStore from '../settings/settings.store';
import { ScanButtonComponent } from "./components/scan-button/scan-button.component";
import { ScanHistoryComponent } from "./components/scan-history/scan-history.component";
import { ScannerService } from "./services/scanner.service";
import { MenuItemStore2 } from '../shell/menu/menu-item.store';
import { ScannerStore2 } from './scanner.store2';

export interface ScannerModuleOptions {
  defaultSettings: {
    scanner: string;
  };
}

export function scannerServiceSelector(store: Store): ScannerServiceSelector {
  const func = (scanners: Scanner[]) => {
    const settings = store.get(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.getters.values<{ scanner: string }>(),
      SOFTLINE_SETTINGS_SCANNER
    );
    let scanner = scanners.find((o) => o.name === settings?.scanner);
    if(!scanner) {
      scanner = scanners
        .sort((a, b) => a.priority < b.priority ? 1 : (a.priority > b.priority ? -1 : 0))
        .find(o => o.service.isAvailable)
    }
    if (!scanner) {
      store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#APPLICATION.SCANNER.NO_SCANNER_SELECTED'
      );
      throw new Error('[Softline Application] No Scanner Selected');
    }
    if (!scanner.service.isAvailable) {
      store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        '#APPLICATION.SCANNER.SELECTED_SCANNER_NOT_AVAILABLE'
      );
      throw new Error('[Softline Application] Scanner is not available');
    }

    return scanner?.service;
  };
  return func;
}

@NgModule({
  declarations: [ScannerSettingsComponent, ScanDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiCoreModule,
    ScanButtonComponent,
    ScanHistoryComponent
  ],
  exports: [ScanDirective, ScanButtonComponent],
  providers: [],
})
export class ScannerModule {
  static forRoot(
    options?: ScannerModuleOptions
  ): ModuleWithProviders<ScannerRootModule> {
    return {
      ngModule: ScannerRootModule,
      providers: [
        {
          provide: SOFTLINE_CONFIG_SETTINGS,
          multi: true,
          useValue: {
            key: SOFTLINE_SETTINGS_SCANNER,
            component: ScannerSettingsComponent,
            title: '#APPLICATION.SCANNER.TITLE',
            default: options?.defaultSettings,
            icon: 'fa-regular fa-barcode-read',
          },
        },
        {
          provide: SOFTLINE_CONFIG_SCANNER_SELECTOR,
          useFactory: scannerServiceSelector,
          deps: [Store],
        },
      ],
    };
  }
}

@NgModule({
  imports: [
    ScannerModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_SCANNER,
      feature: ScannerStore.feature,
    }),
  ],
  providers: [
    ScannerStore2,
    { provide: SOFTLINE_STORE_FEATURES, useExisting: ScannerStore2, multi: true},
  ]
})
export class ScannerRootModule {
  constructor(store: Store,
              @Inject(SOFTLINE_CONFIG_SCANNER) scanners: Scanner[],
              @Inject(SOFTLINE_CONFIG_SCANNER_SELECTOR) selector: (scanners: Scanner[]) => ScannerService) {
      this.init(store, scanners, selector);
  }

  async init(store: Store, scanners: Scanner[], selector: (scanners: Scanner[]) => ScannerService): Promise<void> {
    for(const scanner of scanners)
      await scanner.service.init();

    await store.dispatch(
      SOFTLINE_FEATURE_SETTINGS,
      SettingsStore.actions.load
    );

    const active = selector(scanners);
    await active.activate();
  }
}
