import { Inject, Injectable } from '@angular/core';
import {
  PreviewHandler,
  SOFTLINE_CONFIG_PREVIEW_HANDLER,
} from '../ui-core.tokens';

@Injectable()
export class PreviewService {
  constructor(
    @Inject(SOFTLINE_CONFIG_PREVIEW_HANDLER) private handlers: PreviewHandler[]
  ) {}

  canOpen(value: unknown): boolean {
    return this.handlers.some((o) => o.canOpen(value));
  }

  async open(value: unknown): Promise<void> {
    const handlers = this.handlers
      .filter((o) => o.canOpen(value))
      .sort((f, s) =>
        (f.priority ?? Number.NEGATIVE_INFINITY) <
        (s.priority ?? Number.NEGATIVE_INFINITY)
          ? 1
          : (f.priority ?? Number.NEGATIVE_INFINITY) ===
            (s.priority ?? Number.NEGATIVE_INFINITY)
          ? 0
          : -1
      );
    if (handlers.length === 0) return;

    const handler = handlers[0];
    await handler.open(value);
  }
}
