import { Injectable } from '@angular/core';
import {
  CreateResourceService,
  DeleteResourceService,
  GetResourceService,
  UpdateResourceService,
} from '../../abstraction';
import { CookieResourceLocation } from './cookie.location';
import { CookieService } from './cookie.service';
import { Observable, of, throwError } from 'rxjs';

@Injectable()
export class CookieResourceService
  implements
    GetResourceService<CookieResourceLocation>,
    CreateResourceService<CookieResourceLocation>,
    UpdateResourceService<CookieResourceLocation>,
    DeleteResourceService<CookieResourceLocation>
{
  constructor(private cookieService: CookieService) {}

  get<T>(location: CookieResourceLocation): Observable<T> {
    try {
      const value = this.getCookieValue<T>(location.name);
      return of(value);
    } catch (e) {
      return throwError(e);
    }
  }

  create<T, TResponse>(
    location: CookieResourceLocation,
    resource: T
  ): Observable<TResponse> {
    try {
      const value = this.setCookieValue<T, TResponse>(location.name, resource);
      return of(value);
    } catch (e) {
      return throwError(e);
    }
  }

  update<T, TResponse>(
    location: CookieResourceLocation,
    resource: T
  ): Observable<TResponse> {
    try {
      const value = this.setCookieValue<T, TResponse>(location.name, resource);
      return of(value);
    } catch (e) {
      return throwError(e);
    }
  }

  delete<T>(location: CookieResourceLocation): Observable<T> {
    try {
      const value = this.deleteCookieValue<T>(location.name);
      return of(value);
    } catch (e) {
      return throwError(e);
    }
  }

  private getCookieValue<T>(name: string): T {
    if (this.cookieService.isSet(name))
      return JSON.parse(this.cookieService.get(name));

    throw new Error(
      `CookieResourceService.getCookieValue: Can not find a value for cookie '${name}'`
    );
  }

  private setCookieValue<T, TResponse>(name: string, value: T): TResponse {
    const returnValue = this.cookieService.set(name, JSON.stringify(value));
    return JSON.parse(returnValue) as TResponse;
  }

  private deleteCookieValue<T>(name: string): T {
    const value = this.cookieService.delete(name);
    return JSON.parse(value) as T;
  }
}
