import {Injectable} from '@angular/core';
import {ConnectionHttpService, ConnectionResourceLocation, ResponseResult} from '@softline/core';
import {map, Observable} from 'rxjs';
import {RetourenKopf} from '../data/retouren-kopf';
import {RetourenLager} from '../data/retouren-lager';
import {SOFTLINE_API_MDE_RETOUREN_ABSCHLUSS, SOFTLINE_API_MDE_RETOUREN_KOPF} from '../retouren.api';

@Injectable()
export class RetourenService {

  private get location(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_RETOUREN_ABSCHLUSS,
    }
  }

  private get kopfLocation(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_MDE_RETOUREN_KOPF,
    }
  }

  constructor(private httpService: ConnectionHttpService) {}

  abschluss(): Observable<void> {
    return this.httpService.create(this.location, {});
  }

  getKopf(): Observable<RetourenKopf | null | undefined> {
    return this.httpService.get<ResponseResult<RetourenKopf>>(this.kopfLocation).pipe(
      map(o => o?.result)
    );
  }

  removeAll(): Observable<unknown> {
    return this.httpService.delete<ResponseResult<RetourenLager>>(this.kopfLocation);
  }
}
