import {
  computed,
  effect,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  OutputRefSubscription,
  runInInjectionContext,
  signal,
  Signal
} from '@angular/core';
import { NestedStore2Feature, RepositoryCollectionStore2 } from '@softline/core';
import { DynamicFormComponent, WithDefinition } from '@softline/dynamic';
import { WithCreatePage } from '@softapps/core';
import { distinct } from 'rxjs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

type Constructor<T extends {}> = new (...args: any[]) => T;

type DynamicCreatePageMixinParams<
  T extends object,
  TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>
> = {
  store: InjectionToken<TStore>;
  repositoryFeature: (o: TStore) => RepositoryCollectionStore2<T>;
  definitionName: string;
};

export const WithDynamicCreatePage = <
  T extends object,
  TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>,
  TBase extends Constructor<{}> = Constructor<{}>
>(
  params: DynamicCreatePageMixinParams<T, TStore>,
  Base: TBase = class {} as any
) => {
  @Injectable()
  abstract class DynamicCreatePageMixin extends WithCreatePage(
    params,
    WithDefinition(params.definitionName, Base)
  ) {
    #valueSubscription?: OutputRefSubscription;
    #submitSubscription?: OutputRefSubscription;

    #injector = inject(Injector);

    abstract form: Signal<DynamicFormComponent<T> | undefined>;

    #formEffect = effect(() => {
      const form = this.form();

      if (this.#valueSubscription) this.#valueSubscription.unsubscribe();
      if (this.#submitSubscription) this.#submitSubscription.unsubscribe();

      if (!form) return;

      this.#valueSubscription = form.valueChange.subscribe((value) => {
        this.formValue.set(value);
      });
      this.#submitSubscription = form.formSubmit.subscribe(async (value) => {
        await this.create(value);
      });
    });

    override dirty = computed(() => {
      return this.form()?.dirty() ?? false;
    });

    override formValue = signal<Partial<T> | null>(null);

    constructor(...args: any[]) {
      super(...args);
    }

    override async ngOnInit(): Promise<void> {
      if (super['ngOnInit']) super['ngOnInit']();
      runInInjectionContext(this.#injector, () => {
        toObservable(this.form)
          .pipe(distinct(), takeUntilDestroyed())
          .subscribe((form) => {
            if (this.#valueSubscription) this.#valueSubscription.unsubscribe();
            if (this.#submitSubscription)
              this.#submitSubscription.unsubscribe();

            if (!form) return;

            this.#valueSubscription = form.valueChange.subscribe((value) => {
              this.formValue.set(value);
            });
            this.#submitSubscription = form.formSubmit.subscribe(
              async (value) => {
                if (!this.form()?.valid()) return;
                await this.create(this.formValue());
              }
            );
          });
      });
    }

    override ngOnDestroy(): void {
      if (this.#valueSubscription) this.#valueSubscription.unsubscribe();
      if (this.#submitSubscription) this.#submitSubscription.unsubscribe();

      if (super['ngOnDestroy']) super['ngOnDestroy']();
    }

    override async submit(): Promise<void> {
      const form = this.form();
      if (form) form.submit();
    }
  }
  return DynamicCreatePageMixin;
};
