import { UserValidationInfo } from '../data/user-validation';
import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import { ResetPassword } from '../data/reset-password';
import { PasswordService } from '../services/password.service';
import { take } from 'rxjs/operators';
import {lastValueFrom} from 'rxjs';

export interface State {
  userValidation?: UserValidationInfo;
}

export class PasswordStore {
  static mutations = {
    setValidation: createMutation<State, UserValidationInfo>('setValidation'),
  };

  static actions = {
    resetPassword: createAction<State, ResetPassword, boolean>('resetPassword'),
    requestToken: createAction<State, UserValidationInfo, UserValidationInfo>(
      'requestToken'
    ),
  };

  static getters = {
    userInfo: createGetter<State, undefined, UserValidationInfo>('userInfo'),
  };

  static feature: StoreFeature<State> = {
    initialState: { userValidation: undefined },
    mutations: [
      mutate(PasswordStore.mutations.setValidation, ({ state, params }) => ({
        ...state,
        userValidation: params,
      })),
    ],
    getters: [
      select(
        PasswordStore.getters.userInfo,
        ({ state }) => state?.userValidation
      ),
    ],
    actions: [
      on(
        PasswordStore.actions.requestToken,
        async ({ state, featureName, store, injector, params }) => {
          const service = injector.get(PasswordService);
          const result: UserValidationInfo = await lastValueFrom(service
            .requestToken(params))
          store.commit(
            featureName,
            PasswordStore.mutations.setValidation,
            result
          );
          return result;
        }
      ),
      on(
        PasswordStore.actions.resetPassword,
        async ({ state, injector, params }) => {
          try {
            const service = injector.get(PasswordService);
            await service.resetPassword(params).pipe(take(1)).toPromise();
            return true;
          } catch {
            return false;
          }
        }
      ),
    ],
  };
}
