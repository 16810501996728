<input
  [value]="value | duration: 'hh:mm'"
  placeholder="{{
    placeholder || '#UI_CORE.COMPONENTS.DURATION_INPUT.PLACEHOLDER' | translate
  }}"
  (change)="setValue(input.value)"
  [readOnly]="readonly"
  (blur)="onTouch()"
  #input
/>
