import { Injectable, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import { MetaForm, MetaObject } from '@softline/dynamic';

type Constructor<T> = new(...args: any[]) => T;

export const WithMetaFormLoader = <T extends object, TBase extends Constructor<{}>>(Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class MetaFormLoaderMixin extends Base implements OnInit, OnDestroy {

    readonly metaForm = signal<MetaForm<T> | null>(null);

    constructor(...args: any[]) {
      super(...args);
    }

    async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();
      const metaForm = await this.loadMeta();
      this.metaForm.set(metaForm)
    }

    ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    abstract loadMeta(): Promise<MetaForm<T>>;
  }
  return MetaFormLoaderMixin;
}

export const WithStaticMetaFormLoader = <T extends object, TBase extends Constructor<{}>>(meta: MetaForm<T>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class StaticMetaFormLoaderMixin
    extends WithMetaFormLoader(Base)
    implements OnInit, OnDestroy {

    constructor(...args: any[]) {
      super(...args);
    }

    override async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        await super['ngOnInit']();
    }

    override ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    override async loadMeta(): Promise<MetaForm<T>> {
      return meta;
    }

  }
  return WithStaticMetaFormLoader;
}
