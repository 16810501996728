import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { WtaetigkeitListView } from "./list-view/wtaetigkeit.list-view";
import { FilterConfig, SortConfig } from "@softline/application";

const defaultSort= {property: 'taetigkeit', direction: 'asc'}
const sort: SortConfig = {
  properties: [
    {property: 'taetigkeit', title: 'Tätigkeit'},
  ]
}
const filter: FilterConfig = {
  properties: [
    {property: 'taetigkeit', title: 'Tätigkeit'},
    {property: 'bezeichnung', title: 'Bezeichnung'},
  ]
}
export const wtaetigkeitFieldOk: FieldOkConfig = {
  name: 'wtaetigkeit',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{taetigkeit}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: WtaetigkeitListView,
      sort,
      filter,
      defaultSort
    }
  }
  ]
};
export const wtaetigkeitMultiselectFieldOk: FieldOkConfig = {
  name: 'wtaetigkeit',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{taetigkeit}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: WtaetigkeitListView,
      sort,
      filter,
      defaultSort
    }
  }
  ]
};

export const wtaetigkeitQueryFieldOk: FieldOkConfig = {
  name: 'vkforg',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{taetigkeit}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: WtaetigkeitListView,
      sort,
      filter,
      defaultSort
    }
  }
  ]
};
