import { Component, OnDestroy, OnInit } from '@angular/core';
import {CommandStore, SOFTLINE_FEATURE_COMMANDS, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import { RouterModule } from '@angular/router';
import { Store } from '@softline/core';
import {SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE} from '../artikel-info.shared';
import {UmsatzabfrageStore} from '../store/umsatzabfrage.store';

@Component({
  selector: 'lib-artikel-info',
  standalone: true,
  templateUrl: './artikel-info.component.html',
  styleUrls: ['./artikel-info.component.scss'],
  imports: [RouterModule],
})
export class ArtikelInfoComponent implements OnInit, OnDestroy {
  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Artikelinfo'
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: ArtikelInfoComponent,
        commands: [
          {
            name: 'Suche',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-magnifying-glass',
            routerLink: '/artikel-info'
          },
          {
            name: 'Favoriten',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-heart',
            routerLink: '/artikel-info/favoriten'
          },
          {
            name: 'Scan-Liste',
            class: 'menu main-menu main-menu-top',
            icon: 'fa-regular fa-barcode-scan',
            routerLink: '/artikel-info/scan-liste'
          }
        ]
      }
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
      UmsatzabfrageStore.actions.loadFilialen
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      ArtikelInfoComponent
    );
  }
}
