import { AbstractControl, ValidatorFn } from '@angular/forms';

export function passwordConfirmValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) return null;

    if (control.value !== control.parent?.value?.password)
      return {
        passwordConfirm: {
          isValid: false,
          messages: [
            {
              type: 'error',
              subject: '#PASSWORD.VALIDATION.PASSWORD_REPEAT.UNEQUAL',
            },
          ],
        },
      };
    return null;
  };
}
