<ng-container *ngIf="definition" [ngSwitch]="definition.control">
  <soft-tab-group
    *ngSwitchCase="'tab-group'"
    [ngClass]="definition.class | classRule: value"
  >
    <ng-container *ngFor="let tab of definition.definitions">
      <soft-tab
        *ngIf="tab.visible | booleanRule: value:true"
        [ngClass]="tab.class | classRule: value"
        [title]="tab.title"
      >
        <ng-container
          *ngTemplateOutlet="
            valueTemplate;
            context: { $implicit: $any(tab).definitions }
          "
        ></ng-container>
      </soft-tab>
    </ng-container>
  </soft-tab-group>
  <div
    *ngSwitchCase="'card'"
    [ngClass]="definition.class | classRule: value"
    class="soft-card"
  >
    <div *ngIf="displayHeader && definition.title" class="soft-card-title">
      {{ definition.title }}
    </div>
    <ng-container
      *ngTemplateOutlet="
        valueTemplate;
        context: { $implicit: definition.definitions }
      "
    ></ng-container>
  </div>
  <section
    *ngSwitchCase="'section'"
    class="soft-section"
    [ngClass]="definition.class | classRule: value"
  >
    <header *ngIf="displayHeader && definition.title">{{ definition.title }}</header>
    <ng-container
      *ngTemplateOutlet="
        valueTemplate;
        context: { $implicit: definition.definitions }
      "
    ></ng-container>
  </section>
  <div
    *ngSwitchCase="'div'"
    class="soft-section"
    [ngClass]="definition.class | classRule: value"
  >
    <header *ngIf="displayHeader && definition.title">{{ definition.title }}</header>
    <ng-container
      *ngTemplateOutlet="
        valueTemplate;
        context: { $implicit: definition.definitions }
      "
    ></ng-container>
  </div>
</ng-container>

<ng-template let-children #valueTemplate>
  <ng-container *ngFor="let child of children">
    <div
      *ngIf="child.visible | booleanRule: value:true"
      [ngClass]="child.class | classRule: value"
    >
      <label
        *ngIf="child.title"
        class="soft-label"
        [ngClass]="(child.titleClass | classRule: value) || 'left'"
        >{{ child.title }}</label
      >
      <soft-dynamic-group-value
        *ngIf="child.type === 'group'; else valueTemplate"
        [value]="value"
        [definition]="$any(child)"
        [loading]="loading"
        [displayHeader]="false"
      >
      </soft-dynamic-group-value>
      <ng-template #valueTemplate>
        <soft-dynamic-value
          [definition]="child"
          [value]="value"
          [loading]="loading"
        ></soft-dynamic-value>
      </ng-template>
    </div>
  </ng-container>
</ng-template>
