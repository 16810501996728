import { SortDirection } from '@softline/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toArray',
})
export class ToArrayPipe implements PipeTransform {
  transform<T extends object>(iterable: Iterable<T> | null): T[] {
    if (!iterable) return [];
    return [...iterable];
  }
}
