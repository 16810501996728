import { Pipe, PipeTransform } from '@angular/core';
import {
  base64Encode,
  ConnectionStore,
  Dictionary,
  isDefined,
  SOFTLINE_FEATURE_CONNECTION,
  Store
} from '@softline/core';
import { SOFTLINE_API_MEDIA } from '../artikel-info.api';
import { Media } from '../types/media';

@Pipe({
  standalone: true,
  name: 'media'
})
export class MediaPipe implements PipeTransform {

  constructor(private store: Store) {}

  transform(value: Media | undefined, size?: {width?: number, height?: number}): string | null {
    if (!value)
      return null;

    const connection = this.store.get(SOFTLINE_FEATURE_CONNECTION, ConnectionStore.getters.selected);

    if (!connection)
      return null

    const path = isDefined(connection.basePath) ? [connection.basePath] : [];
    path.push(SOFTLINE_API_MEDIA);
    path.push(base64Encode(JSON.stringify(value)));

    return this.getUrl(connection.host, connection.port, path, size);
  }

  private getUrl(host: string, port: number | undefined, paths: string[], queryParams?: Dictionary<any>): string {
    let url = host.replace(/^\/+|\/+$/g, '');
    if (port)
      url += `:${port}`;
    for (const path of paths.filter(isDefined))
      url += `/${path.replace(/^\/+|\/+$/g, '')}`;

    if (queryParams) {
      url += `?${Object.entries(queryParams).map(([key, value]) => key + '=' + encodeURIComponent(value)).join('&')}`
    }

    return url;
  }
}
