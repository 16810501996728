import { CdkPortal, PortalModule } from "@angular/cdk/portal";
import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { CommonModule } from "@angular/common";

@Directive({
  selector: '[softTabTitle]',
  standalone: true
})
export class TabTitleDirective extends CdkPortal {
  constructor(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ) {
    super(templateRef, viewContainerRef);
  }
}
