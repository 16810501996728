import {Inject, Injectable} from '@angular/core';
import {ConnectionHttpService, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {SOFTLINE_API_ITEM_STOCK} from '../artikel-info.api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemStockAmount} from '../types/item-stock-amount';

export interface LoadStockParameters {
  ids: number[];
  vkforgRestriction?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ItemStockService {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService,
  ) {}

  load(params: LoadStockParameters): Observable<ItemStockAmount[]> {
    const location = { path: `${SOFTLINE_API_ITEM_STOCK}/amount` }
    return this.service.create<LoadStockParameters, ItemStockAmount[]>(location, params).pipe(
      map((o) => o.map(p => ({ ...p, id: p.itemId }))),
    );
  }

  loadStockAmount(id: number): Observable<ItemStockAmount | null> {
    const body = { ids: [id] }
    return this.service.create<typeof body, ItemStockAmount[]>({ path: `${SOFTLINE_API_ITEM_STOCK}/amount` }, body).pipe(
      map(result => result && result.length > 0 ? result[0] : null)
    )
  }
}
