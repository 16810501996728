import { Component, computed, forwardRef, input, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '../../../../i18n/i18n.module';
import { L10nModule } from '../../../../l10n/l10n.module';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRangeFieldInput } from '../date-range-field-input';
import { DateTimeRangeInputComponent } from '../../date-time-range-input/date-time-range-input.component';
import { DateRangeFromInputComponent } from '../date-range-from-input/date-range-from-input.component';

@Component({
  selector: 'soft-date-range-to-input',
  standalone: true,
  imports: [CommonModule, I18nModule, L10nModule],
  templateUrl: './date-range-to-input.component.html',
  styleUrl: './date-range-to-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeToInputComponent),
      multi: true,
    },
  ],
})
export class DateRangeToInputComponent extends DateRangeFieldInput{
  override valueInput: Signal<string | null> = input<string | null>(null);
  override readonly: Signal<boolean> = input<boolean>(false);
  override disabledInput: Signal<boolean> = input<boolean>(false, {alias: 'disabled'});
  override placeholder: Signal<string> = input<string>('#UI_CORE.COMPONENTS.DATE_INPUT.PLACEHOLDER');
  override changeTrigger: Signal<'blur' | 'input'> = input<'blur' | 'input'>('blur');
  protected override field: 'from' | 'to' = 'to'
  override format: Signal<string> = input<string>('DATE.DEFAULT');

  fromControl = input<DateRangeFromInputComponent | null>(null);
  override otherControl = computed(() => this.fromControl());
}
