import { Pipe, PipeTransform } from '@angular/core';
import { IsActiveMatchOptions, Router, UrlTree } from '@angular/router';
import { isDefined } from '@softline/core';

@Pipe({
  standalone: true,
  name: 'isActiveRoute',
  pure: false,
})
export class IsActiveRoutePipe implements PipeTransform {
  constructor(protected router: Router) {}

  transform(
    routerLink: string | any[] | undefined,
    matchOptions?: Partial<IsActiveMatchOptions>
  ): boolean {
    if (!isDefined(routerLink)) return false;
    let urlTree: UrlTree;
    if (Array.isArray(routerLink))
      urlTree = this.router.createUrlTree(routerLink);
    else urlTree = this.router.createUrlTree([routerLink]);

    return this.router.isActive(urlTree, {
      paths: matchOptions?.paths ?? 'exact',
      queryParams: matchOptions?.queryParams ?? 'ignored',
      matrixParams: matchOptions?.matrixParams ?? 'ignored',
      fragment: matchOptions?.fragment ?? 'ignored',
    });
  }
}
