import moment from 'moment';
import { base64Decode, isDefined } from '@softline/core';

const JWT_PARTS = 3;
const MILLISECONDS_MULTIPLIER = 1000;

export class JwtHelper {
  static getPayload(token: string): object | undefined;
  static getPayload<T>(token: string): T | undefined {
    const parts = token.split('.');
    if (parts.length !== JWT_PARTS) return undefined;
    try {
      const payload = JSON.parse(base64Decode(parts[1]));
      return payload as T;
    } catch (e) {
      console.error('[JwtHelper] getPayload: Invalid Token', e)
      return undefined;
    }
  }

  static getExpirationDate(token: string): string | null {
    const payload = this.getPayload(token) as { exp: number };
    if (payload?.exp)
      return moment(payload?.exp * MILLISECONDS_MULTIPLIER).toISOString(true);
    return null;
  }
}
