import { ApplicationRef, Inject, Injectable } from "@angular/core";
import { SwUpdate, UnrecoverableStateEvent, VersionEvent } from "@angular/service-worker";
import { concat, first, interval, skip } from "rxjs";
import { MessageBarStore, ModalStore, SOFTLINE_FEATURE_MESSAGE_BAR, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { Store } from "@softline/core";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { SOFTLINE_CONFIG_PWA_CHECK_UPDATE_INTERVALL, SOFTLINE_FEATURE_PWA_UPDATE } from "../../pwa.shared";
import { PwaUpdateStore } from "../store";

@Injectable()
export class PwaUpdateService {

  constructor(private store: Store,
              private appRef: ApplicationRef,
              private updates: SwUpdate,
              @Inject(DOCUMENT) private document: Document,
              @Inject(SOFTLINE_CONFIG_PWA_CHECK_UPDATE_INTERVALL) private updateIntervall: number,
              private route: ActivatedRoute) {
  }

  init(): void {
    this.store.commit(SOFTLINE_FEATURE_PWA_UPDATE, PwaUpdateStore.mutations.set, {
      loading: true,
    });

    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(this.updateIntervall);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    this.updates.versionUpdates.subscribe(async evt => await this.onVersionUpdates(evt));
    this.updates.unrecoverable.subscribe(async event => await this.handleUnrecoverableState(event));
    everySixHoursOnceAppIsStable$.subscribe(async () => await this.checkUpdate());

    if(window.location.href.indexOf('pwaUpdate=1') > -1) {
      this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#PWA.UPDATE.MESSAGES.SUCCESS.UPDATE_INSTALLED'
      )
    }
  }

  async onVersionUpdates(evt: VersionEvent): Promise<void> {
    switch (evt.type) {
      case 'VERSION_DETECTED':
        console.log(`[PwaUpdateService] Downloading new app version: ${evt.version.hash}`);
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.info,
          '#PWA.UPDATE.MESSAGES.INFO.VERSION_DETECTED'
        );
        this.store.commit(SOFTLINE_FEATURE_PWA_UPDATE, PwaUpdateStore.mutations.set, {
          loading: false,
          updateAvailable: true,
          currentVersionHash: evt.version.hash,
        });
        break;
      case 'VERSION_READY':
        console.log(`[PwaUpdateService] Current app version: ${evt.currentVersion.hash}`);
        console.log(`[PwaUpdateService] New app version ready for use: ${evt.latestVersion.hash}`);
        this.store.commit(SOFTLINE_FEATURE_PWA_UPDATE, PwaUpdateStore.mutations.set, {
          loading: false,
          updateAvailable: true,
          currentVersionHash: evt.currentVersion.hash,
          latestVersionHash: evt.latestVersion.hash
        });
        const result = await this.store.dispatch(
          SOFTLINE_FEATURE_MODAL,
          ModalStore.actions.ask,
          {
            title: "#PWA.UPDATE.DIALOG.INSTALL.TITLE",
            question: "#PWA.UPDATE.DIALOG.INSTALL.QUESTION"
          },
        );
        if(result === 'YES')
          this.activateUpdate();
        break;
      case 'VERSION_INSTALLATION_FAILED':
        console.error(`[PwaUpdateService] Failed to install app version '${evt.version.hash}': ${evt.error}`);
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.error,
          {
            message: '#PWA.UPDATE.MESSAGES.ERROR.VERSION_INSTALLATION_FAILED',
            params: evt
          }
        )
        break;
      case "NO_NEW_VERSION_DETECTED":
        console.info(`[PwaUpdateService] No update detected '${evt.version.hash}'`);
        if(!this.store.get(SOFTLINE_FEATURE_PWA_UPDATE, PwaUpdateStore.getters.data)?.updateAvailable)
          this.store.commit(SOFTLINE_FEATURE_PWA_UPDATE, PwaUpdateStore.mutations.set, {
            loading: false,
            updateAvailable: false,
            currentVersionHash: evt.version.hash,
          });
        break;
    }
  }

  async checkUpdate(): Promise<void> {
    try {
      const updateFound = await this.updates.checkForUpdate();
      console.log('[PwaUpdateService] checkUpdate: checked', updateFound);
    } catch (err) {
      console.error('[PwaUpdateService] checkUpdate: Failed to check for updates:', err);
    }
  }

  async handleUnrecoverableState(event: UnrecoverableStateEvent) {
    console.error('[PwaUpdateService] An error occurred that we cannot recover from:', event);
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.notify,
      '#PWA.UPDATE.MESSAGES.ERROR.UNRECOVERABLE_STATE'
    );
    this.document.location.reload();
  }

  activateUpdate(): void {
    if(this.document.location.href.indexOf('pwaUpdate=1') === -1)
      this.document.location.search = 'pwaUpdate=1';
    else
      this.document.location.reload();
  }
}
