import { InjectionToken } from '@angular/core';
import { ObjectService, RemoteStoreObjectService } from './object.service';

export const SOFTLINE_STORE_OBJECT_SERVICE = new InjectionToken<
  ObjectService<any>
>('SOFTLINE_STORE_OBJECT_SERVICE');

export const SOFTLINE_STORE_REMOTE_OBJECT_SERVICE = new InjectionToken<
  RemoteStoreObjectService<any>
>('SOFTLINE_STORE_REMOTE_OBJECT_SERVICE');
