import {InjectionToken, Type} from "@angular/core";
import {ArtikelInfoModalParams} from './types/artikel-info.modal';

export interface ArtikelStoreConfig {
  artikelPreisFeature?: string;
  artikelLagerMengenFeature?: string;
  artikelDetailFeature?: string;
}

export const SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE = new InjectionToken<number>(
  'SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE'
);

export const SOFTLINE_CONFIG_ARTIKEL_INFO_MODAL_COMPONENT = new InjectionToken<Type<ArtikelInfoModalParams>>(
  'SOFTLINE_CONFIG_ARTIKEL_INFO_MODAL_COMPONENT'
);

export const SOFTLINE_STORE_CONFIG_ARTIKEL = new InjectionToken<ArtikelStoreConfig>('SOFTLINE_STORE_CONFIG_ARTIKEL');

export const SOFTLINE_FEATURE_WWS_ARTIKELSUCHE = 'wwsArtikelsuche';
export const SOFTLINE_FEATURE_WWS_ARTIKELSUCHVERLAUF = 'wwsArtikelsuchverlauf';
export const SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN = 'wwsArtikelfavoriten';
