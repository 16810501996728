import { Inject, Injectable } from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
} from '@softline/core';
import { Scan } from '../scanner/data/scan';
import { SOFTLINE_API_ITEM_SCAN } from './item-scan.api';
import { lastValueFrom } from 'rxjs';
import { ItemScan } from './item-scan';

@Injectable()
export class ItemScanService {
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService
  ) {}

  async load(scan: Scan): Promise<ItemScan> {
    return lastValueFrom(
      this.service.get<ItemScan>({
        path: SOFTLINE_API_ITEM_SCAN,
        pathParams: { labelType: scan.labelType, data: scan.data },
      })
    );
  }
}
