import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  QueryList, signal,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {base64Encode, Filter, FilterService, Sort, SortService, Store} from "@softline/core";
import {
  SOFTLINE_CONFIG_WWS_WARENUEBERNAHME_BESTELLUNG_STEPS, SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
  SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG
} from "../../../warenuebernahme.shared";
import {
  BackNavigable,
  BackNavigationService,
  CommandStore,
  FilterAndSortComponent,
  FilterConfig, FilterConfigProperty,
  HeaderComponent, SOFTLINE_FEATURE_COMMANDS,
  SortConfig,
  StepHeaderComponent
} from "@softline/application";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {combineLatestWith, distinctUntilChanged, first, map, Observable, of, startWith, switchMap} from "rxjs";
import {Bestellung} from "../../../types/bestellung";
import {WwsBestellungStore} from "../../../store/bestellung.store";
import {
  AccordionItemComponent, MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from "@softline/ui-core";
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop";
import {
  BestellungUebernehmenDialogComponent
} from '../../../dialogs/bestellung-uebernehmen/bestellung-uebernehmen-dialog.component';
import {WwsWarenuebernahmeStore} from '../../../store/warenuebernahme.store';

@Component({
  selector: 'soft-bestellung-schritt-1',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, HeaderComponent, StepHeaderComponent, RouterModule, FilterAndSortComponent, UiCoreModule],
  templateUrl: './bestellung-schritt-2.component.html',
  styleUrls: ['./bestellung-schritt-2.component.scss']
})
export class BestellungSchritt2Component implements OnInit, OnDestroy, BackNavigable {

  @ViewChild('filterAndSortComponent', { static: true }) private readonly filterAndSortComponent?: FilterAndSortComponent;
  @ViewChildren('bestellungAccordionItem') private readonly accordionItems?: QueryList<AccordionItemComponent>;

  readonly steps = SOFTLINE_CONFIG_WWS_WARENUEBERNAHME_BESTELLUNG_STEPS;

  readonly selectedBestellungen = signal<number[]>([]);
  private readonly selectedBestellungen$ = toObservable(this.selectedBestellungen);

  readonly bestellungen$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
    WwsBestellungStore.getters.all
  );

  readonly lieferant$ = this.bestellungen$.pipe(
      map(bestellungen => bestellungen[0]?.lieferant)
  );

  readonly sort$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
    WwsBestellungStore.getters.sort
  );

  readonly filter$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
    WwsBestellungStore.getters.filter
  );

  readonly filteredItems$: Observable<Bestellung[]> = this.bestellungen$.pipe(
    combineLatestWith(this.sort$, this.filter$),
    map(([values, sort, filter]) =>
      this.sortService.sort(this.filterService.filter(values, filter), sort)
    )
  );

  readonly loading$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
    WwsBestellungStore.getters.loading
  );

  filterConfig: FilterConfig = {
    properties: [
      {
        property: 'bestellnummer',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_BESTELLNUMMER'
      },
      {
        property: 'id',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_BESTELLUNG_ID',
      },
      {
        property: 'bewegungen.length',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_ANZAHL_BEWEGUNGEN',
      }
    ]
  };

  sortConfig: SortConfig = {
    properties: [
      {
        property: 'bestellnummer',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_BESTELLNUMMER',
      },
      {
        property: 'id',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_BESTELLUNG_ID',
      },
      {
        property: 'bewegungen.length',
        title: '#WWS_WARENUEBERNAHME.FILTER_SORT.TITEL_ANZAHL_BEWEGUNGEN',
      }
    ]
  };

  constructor(private store: Store,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private sortService: SortService,
              private filterService: FilterService,
              private cdRef: ChangeDetectorRef,
              private backNavigationService: BackNavigationService,
              private readonly destroyRef: DestroyRef) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: BestellungSchritt2Component,
        commands: [
          {
            name: '#WWS_WARENUEBERNAHME.BESTELLUNG.LABEL_UEBERNEHMEN',
            class: 'menu bottom-menu primary',
            canExecute: this.selectedBestellungen$.pipe(map(o => !!o && o?.length > 0)),
            execute: async () => {
              const entities = this.store.get(
                SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
                WwsBestellungStore.getters.entities
              );

              const data = {
                ids: this.selectedBestellungen(),
                bestellnummern: this.selectedBestellungen().map(id => entities[id]?.bestellnummer ?? '')
              };

              await this.router.navigate(['/warenuebernahme/bestellung/detail', base64Encode(JSON.stringify(data))])
            }
          }
        ]
      }
    );

    this.filterAndSortComponent?.filterText$.pipe(
      map(filterText => filterText?.length > 0),
      combineLatestWith(this.filteredItems$.pipe(map(o => o?.length ?? 0))),
      distinctUntilChanged(([hasTextPrev, countPrev], [hasTextCurr, countCurr]) => {
        if (hasTextPrev === hasTextCurr && countPrev !== countCurr)
          return false

        return hasTextPrev === hasTextCurr
      }),
      switchMap(([hasText]) => {
        return this.accordionItems?.changes.pipe(
            first(),
            startWith(this.accordionItems),
            map(() => hasText),
        ) ?? of(hasText)
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((hasText) => {
      for (const item of (this.accordionItems?.toArray() ?? [])) {
        if (hasText && !item.expanded)
          item.toggle();
        else if (!hasText && item.expanded)
          item.toggle();
      }

      this.cdRef.detectChanges();
    });

    await this.store.dispatch(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
      WwsBestellungStore.actions.loadMany,
      {
        clear: true,
        queryParams: {
          idlieferant: this.activatedRoute.snapshot.queryParamMap.get('idlieferant')
        }
      }
    );
  }
  ngOnDestroy(): void {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
      WwsBestellungStore.mutations.setFilter,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
      WwsBestellungStore.mutations.setSort,
      null
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      BestellungSchritt2Component
    );

    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/warenuebernahme/bestellung/schritt-1']);
  }

  async onFilterChange(filter: Filter | null): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
      WwsBestellungStore.mutations.setFilter,
      filter
    );
  }

  async onSortChange(sort: Sort | null): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
      WwsBestellungStore.mutations.setSort,
      sort
    );
  }

  async selectOrUnselectBestellung(bestellung: Bestellung): Promise<void> {
    if (this.selectedBestellungen().includes(bestellung.id))
      this.selectedBestellungen.set(
        this.selectedBestellungen().filter(o => o !== bestellung.id)
      );
    else
      this.selectedBestellungen.set([
        ...this.selectedBestellungen(),
        bestellung.id
      ]);
  }

  async bestellungenUebernehmen(bestellungen: Bestellung[]) {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<string, object>(),
      {
        id: 'SOFTLINE_DIALOG_WARENUEBERNAHME_BESTELLUNG_UEBERNEHMEN',
        component: BestellungUebernehmenDialogComponent,
        data: {
          bestellungen
        },
        dismiss: true
      }
    );

    if (result === 'DISMISSED')
      return;

    if (result !== '') {
      const warenuebernahme = await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG,
        WwsBestellungStore.actions.bestellungUebernehmen,
        {
          idbestellungList: bestellungen.map(o => o.id),
          lieferscheinnummer: result
        }
      );

      this.store.commit(
        SOFTLINE_FEATURE_WWS_WARENUEBERNAHME,
        WwsWarenuebernahmeStore.mutations.addOrUpdate,
        warenuebernahme
      )
      console.log('API-Result: ', warenuebernahme);

      if (warenuebernahme) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.success,
          {
            title: '#WWS_WARENUEBERNAHME.BESTELLUNG.MESSAGES.TITEL_BESTELLUNG_ABGESCHLOSSEN',
            message: '#WWS_WARENUEBERNAHME.BESTELLUNG.MESSAGES.MESSAGE_BESTELLUNG_ABGESCHLOSSEN',
          });

        await this.router.navigate(['/warenuebernahme/detail', warenuebernahme.id])
      }
    }
  }
}
