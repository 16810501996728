import { LogEntry } from './log';
import { LogWriter } from './log-writer';

export type LogFilter = (entry: LogEntry) => boolean;
export type LogFormatter = (entry: LogEntry) => string;

function defaultFormatter(logEntry: LogEntry): string {
  if (logEntry.type)
    return `${logEntry.type?.toUpperCase()}: ${logEntry.subject} - ${
      logEntry.message
    }`;
  return `${logEntry.subject} - ${logEntry.message}`;
}

export class ConsoleLogWriter extends LogWriter {
  protected formatter: LogFormatter;

  constructor(protected filter?: LogFilter, _formatter?: LogFormatter) {
    super();
    this.formatter = _formatter ? _formatter : defaultFormatter;
  }

  write(logEntry: LogEntry): void {
    if (this.filter && !this.filter(logEntry)) return;

    const text = this.formatter(logEntry);
    switch (logEntry.type) {
      case 'error':
        console.error(text, logEntry.params);
        break;
      case 'warning':
        console.warn(text, logEntry.params);
        break;
      default:
        console.log(text, logEntry.params);
        break;
    }
  }
}
