import { Routes } from "@angular/router";
import { AuthenticationGuard, AuthorizationGuard } from "@softline/auth";
import { SOFTLINE_PERMISSION_TIMESTAMP } from "./stempelerfassung.permissions";

export const stempelerfassungRoutes: Routes = [
  {
    path: 'stempelerfassung',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/timestamp-app.component').then(
            (o) => o.TimestampAppComponent
          ),
        children: [
          {
            path: 'query',
            loadComponent: () =>
              import('./components/query/query.component').then(
                (o) => o.QueryComponent
              ),
          },
          {
            path: 'query/result',
            loadComponent: () =>
              import('./components/query-result/query-result.component').then(
                (o) => o.QueryResultComponent
              ),
          },
          {
            path: '**',
            loadComponent: () =>
              import('./components/overview/overview.component').then(
                (o) => o.OverviewComponent
              ),
          },
          { path: '**', redirectTo: '' },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_TIMESTAMP },
    providers: [
    ],
  },
];
