import { Signal, signal } from "@angular/core";
import { of } from "rxjs";

export abstract class EntityInputStrategy<T, TParam = unknown> {
  abstract loading: Signal<boolean>;
  abstract open(input: string | null, options?: TParam): Promise<T | null | 'DISMISSED' | 'CANCELED'>;
  abstract validate(input: string | null, options?: TParam): Promise<T | null | 'CANCELED'>;
  async cancel(): Promise<boolean> { return false; }
}

export class EmptyEntityInputStrategy extends EntityInputStrategy<any> {
  loading = signal(false);
  items$ = of(undefined);

  async open(input: string): Promise<object | null | 'DISMISSED'> { return null };
  async validate(input: string): Promise<object | null | 'CANCELED'> { return null }
}
