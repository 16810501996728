import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  handleRequestErrors,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore,
} from '@softline/application';
import {
  base64Decode,
  base64Encode,
  containsText,
  isDefinedNotEmpty,
  QueryStore,
  SOFTLINE_SERVICE_UUID,
  Store,
} from '@softline/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {BehaviorSubject, combineLatestWith, debounceTime, map, Observable, Subscription,} from 'rxjs';
import {
  HaftruecklassConfig,
  SOFTLINE_CONFIG_HAFTRUECKLASS,
  SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST,
  SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
  SOFTLINE_FEATURE_HAFTRUECKLASS,
} from '../../haftruecklass.shared';
import {HaftruecklassStore} from '../../store';
import {Haftruecklass} from '../../types/haftruecklass';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import {DefinitionStore, DynamicModule, SOFTLINE_FEATURE_DEFINITIONS,} from '@softline/dynamic';
import {EditDueDateDialogComponent} from '../../dialogs/edit-due-date-dialog/edit-due-date-dialog.component';
import {
  SOFTLINE_API_DEFINITION_HAFTRUECKLASS_LIST,
  SOFTLINE_API_DEFINITION_HAFTRUECKLASS_QUERY,
} from '../../haftruecklass.api';

@Component({
  selector: 'lib-open-item-list',
  standalone: true,
  imports: [CommonModule, UiCoreModule, DynamicModule],
  templateUrl: './haftruecklass-list.component.html',
  styleUrls: ['./haftruecklass-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HaftruecklassListComponent
  implements OnInit, OnDestroy, BackNavigable
{
  private routeSubscription?: Subscription;

  filter$ = new BehaviorSubject<string | undefined>(undefined);
  debouncedFilter$ = this.filter$.pipe(debounceTime(400));

  private queryToken?: string;
  selected$ = new BehaviorSubject<any | undefined>(undefined);

  query$ = this.store.observe(
    SOFTLINE_FEATURE_HAFTRUECKLASS,
    QueryStore.getters.query.query
  );

  queryDefinition$: any = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY
  );
  listDefinition$: any = this.store.observe(
    SOFTLINE_FEATURE_DEFINITIONS,
    DefinitionStore.getters.definition,
    SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST
  );
  openItems$: Observable<readonly Haftruecklass[]> = this.store
    .observe(SOFTLINE_FEATURE_HAFTRUECKLASS, HaftruecklassStore.getters.all)
    .pipe(
      combineLatestWith(this.debouncedFilter$),
      map(([items, filter]) =>
        items.filter((o) =>
          isDefinedNotEmpty(filter) ? containsText(o, filter, true) : true
        )
      )
    );
  openItemsLength$: Observable<number> = this.store
    .observe(SOFTLINE_FEATURE_HAFTRUECKLASS, HaftruecklassStore.getters.ids)
    .pipe(map((o) => o.length));
  loading$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_HAFTRUECKLASS,
    HaftruecklassStore.getters.loading
  );
  loaded$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_HAFTRUECKLASS,
    HaftruecklassStore.getters.loaded
  );
  total$: Observable<number> = this.store
    .observe(SOFTLINE_FEATURE_HAFTRUECKLASS, HaftruecklassStore.getters.ids)
    .pipe(map((o) => o.length));

  constructor(
    private store: Store,
    private router: Router,
    private backNavigationService: BackNavigationService,
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    @Optional()
    @Inject(SOFTLINE_CONFIG_HAFTRUECKLASS)
    private config: HaftruecklassConfig,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
    private injector: Injector
  ) {}

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#HAFTRUECKLASS.TITLE'
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: HaftruecklassListComponent,
        commands: this.createCommands(),
      }
    );

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_DEFINITIONS,
        DefinitionStore.actions.loadOnce,
        {
          name: SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
          location: { path: SOFTLINE_API_DEFINITION_HAFTRUECKLASS_QUERY },
        },
        this.injector
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_DEFINITIONS,
        DefinitionStore.actions.loadOnce,
        {
          name: SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST,
          location: { path: SOFTLINE_API_DEFINITION_HAFTRUECKLASS_LIST },
        },
        this.injector
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    }

    this.routeSubscription = this.route.queryParamMap.subscribe(async (o) =>
      this.onRouteChanged(o)
    );
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      HaftruecklassListComponent
    );

    if (this.routeSubscription && !this.routeSubscription.closed)
      this.routeSubscription.unsubscribe();
    this.routeSubscription = undefined;
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/haftruecklass']);
  }

  async onRouteChanged(queryParamsMap: ParamMap): Promise<void> {
    try {
      await this.store.commit(
        SOFTLINE_FEATURE_HAFTRUECKLASS,
        HaftruecklassStore.mutations.clear
      );
      if (
        this.queryToken &&
        this.store.get(
          SOFTLINE_FEATURE_HAFTRUECKLASS,
          HaftruecklassStore.getters.subscription.active,
          this.queryToken
        )
      )
        await this.store.dispatch(
          SOFTLINE_FEATURE_HAFTRUECKLASS,
          HaftruecklassStore.actions.cancel,
          this.queryToken
        );

      if (!queryParamsMap.get('query')) return;
      let queryParams = {};
      try {
        const encodedQueryParams = queryParamsMap.get('query');
        if (!isDefinedNotEmpty(encodedQueryParams)) return;
        const query = JSON.parse(base64Decode(encodedQueryParams));
        if (this.config?.nurHaftRuecklaesse)
          queryParams = {
            query,
            haftruecklaesseonly: this.config.nurHaftRuecklaesse,
          };

        this.store.commit(
          SOFTLINE_FEATURE_HAFTRUECKLASS,
          HaftruecklassStore.mutations.query.setQuery,
          query
        );
      } catch (e) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.error,
          '#HAFTRUECKLASS.MESSAGES.ERROR.INVALID_QUERY'
        );
        console.error(e);
        return;
      }

      this.queryToken = this.uuid();
      const items = await this.store.dispatch(
        SOFTLINE_FEATURE_HAFTRUECKLASS,
        HaftruecklassStore.actions.loadMany,
        {
          token: this.queryToken,
          queryParams,
        }
      );
    } catch (e) {
      handleRequestErrors(this.store, e);
    } finally {
      this.queryToken = undefined;
    }
  }

  async onQueryChange(query: object): Promise<void> {
    const encodedQuery = base64Encode(JSON.stringify(query));
    await this.router.navigate(['.'], {
      queryParams: { query: encodedQuery },
      relativeTo: this.route,
    });
  }

  async onDueDateChange(openItem: Haftruecklass): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<string, object>(),
      {
        component: EditDueDateDialogComponent,
        data: { dueDate: openItem.faelldatum },
        dismiss: true,
      }
    );
    if (result === 'DISMISSED') return;
    try {
      const patch = { id: openItem.id, changes: { faelldatum: result } };
      const newItem = await this.store.dispatch(
        SOFTLINE_FEATURE_HAFTRUECKLASS,
        HaftruecklassStore.actions.patch,
        { patch }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#HAFTRUECKLASS.MESSAGES.SUCCESS.CHANGED'
      );
      this.selected$.next(newItem);
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  async toggleLock(openItem: Haftruecklass): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      `#HAFTRUECKLASS.MESSAGES.QUESTIONS.${
        openItem.kzgesperrt ? 'UNLOCK' : 'LOCK'
      }`
    );
    if (result !== 'YES') return;

    try {
      const patch = {
        id: openItem.id,
        changes: { kzgesperrt: !openItem.kzgesperrt },
      };
      const newItem = await this.store.dispatch(
        SOFTLINE_FEATURE_HAFTRUECKLASS,
        HaftruecklassStore.actions.patch,
        { patch }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        `#HAFTRUECKLASS.MESSAGES.SUCCESS.${
          openItem.kzgesperrt ? 'UNLOCKED' : 'LOCKED'
        }`
      );
      this.selected$.next(newItem);
    } catch (e) {
      handleRequestErrors(this.store, e);
    }
  }

  createCommands(): Command[] {
    return [
      {
        name: '#HAFTRUECKLASS.TITLE',
        class: 'menu main-menu main-menu-top title',
      },
      {
        icon: 'fa-regular fa-magnifying-glass',
        name: '#HAFTRUECKLASS.MENU.QUERY',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/haftruecklass'],
      },
      {
        icon: 'fa-regular fa-list',
        name: '#HAFTRUECKLASS.MENU.RESULT',
        class: 'menu main-menu main-menu-top',
        routerLink: ['/haftruecklass', 'result'],
        routerLinkParams: { queryParams: this.route.snapshot.queryParams },
      },
      {
        icon: 'fa-regular fa-calendar-xmark',
        name: '#HAFTRUECKLASS.MENU.CHANGE_DUE',
        class: 'menu action-menu action-menu-top',
        canExecute: this.selected$.pipe(map((o) => !!o && !o.kzgesperrt)),
        execute: async () => {
          await this.onDueDateChange(this.selected$.value);
        },
      },
      {
        icon: 'fa-regular fa-lock',
        name: '#HAFTRUECKLASS.MENU.LOCK',
        class: 'menu action-menu action-menu-top',
        isVisible: this.selected$.pipe(map((o) => !o?.kzgesperrt)),
        canExecute: this.selected$.pipe(map((o) => !!o && !o.kzgesperrt)),
        execute: async () => {
          await this.toggleLock(this.selected$.value);
        },
      },
      {
        icon: 'fa-regular fa-lock-open',
        name: '#HAFTRUECKLASS.MENU.UNLOCK',
        class: 'menu action-menu action-menu-top',
        isVisible: this.selected$.pipe(map((o) => !!o && o.kzgesperrt)),
        execute: async () => {
          await this.toggleLock(this.selected$.value);
        },
      },
    ];
  }
}
