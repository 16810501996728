import { Injectable } from "@angular/core";
import { Dictionary, LocalStorageService } from "@softline/core";
import { lastValueFrom, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { DeveloperConfig } from "../developer.store";

export const DEVELOPER_LOCAL_STORAGE_KEY = 'SoftApps Developer'

@Injectable()
export class DeveloperService {
  constructor(
    private service: LocalStorageService,
  ) { }

  load(): Promise<DeveloperConfig> {
    return lastValueFrom(
      this.service
        .get<DeveloperConfig>({ key: DEVELOPER_LOCAL_STORAGE_KEY })
        .pipe(
          catchError((error: any) => {
            return of({isDeveloper: false});
          })
        )
    );
  }

  save(config: DeveloperConfig): Promise<DeveloperConfig> {
    return lastValueFrom(
      this.service.create<DeveloperConfig, DeveloperConfig>(
        { key: DEVELOPER_LOCAL_STORAGE_KEY },
        config
      )
    );
  }

  delete(): Promise<DeveloperConfig> {
    return lastValueFrom(
      this.service.delete<DeveloperConfig>(
        { key: DEVELOPER_LOCAL_STORAGE_KEY }
      ).pipe(
        catchError((error: any) => {
          return of({isDeveloper: false});
        })
      )
    );
  }

}
