import { GesamtabfrageDownloadFile, GesamtabfrageDownloadStrategy } from "./gesamtabfrage-download-strategy";
import { Injectable } from "@angular/core";
import { Belegart } from "../../types/belegart.model";
import { ModalStore, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import { DateService, Store } from "@softline/core";
import { SOFTLINE_API_DOWNLOAD_ARCHIVE_RECEIPT } from "../../gesamtabfrage.api";
import { Beleg } from "../../types/beleg.model";

@Injectable()
export class ArchivbelegDownloadStrategy implements GesamtabfrageDownloadStrategy {
  type = 'archivbeleg';
  icon = 'fa-regular fa-box-archive';
  label = '#GESAMTABFRAGE.ACTIONS.DOWNLOAD_ARCHIVBELEG';

  constructor(private store: Store, private dateService: DateService) { }

  async getDownloadFiles(belege: Beleg[], belegart: Belegart): Promise<GesamtabfrageDownloadFile[]> {
    let combine = false;
    const files: GesamtabfrageDownloadFile[] = [];

    if(belege.length > 1) {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.ask,
        {
          title: '#GESAMTABFRAGE.MODAL.ASK.COMBINE.TITLE',
          question: '#GESAMTABFRAGE.MODAL.ASK.COMBINE.TEXT',
          showCancelAction: true,
          dismiss: true,
        });

      if(result === "DISMISSED" || result === 'CANCEL')
        return [];

      combine = result === 'YES'
    }

    if(combine)
      files.push({
        filename: `${belegart.name}_${this.dateService.now()}_archiv.pdf`,
        location: {path: SOFTLINE_API_DOWNLOAD_ARCHIVE_RECEIPT},
        body: { ids: belege.map(o => +o.id), zusammenfassen: true, belegart, fremdbelegdruck: false },
      })
    else
      for (const beleg of belege)
        files.push({
          filename: `${belegart.id}_${beleg.bezugsnummer ?? beleg.belegnummer}_archiv.pdf`,
          location: {path: SOFTLINE_API_DOWNLOAD_ARCHIVE_RECEIPT},
          body: { ids: [beleg.id], zusammenfassen: false, belegart, fremdbelegdruck: false },
        });
    return files;
  }
}
