import {SOFTLINE_SERVICE_HTTP, SOFTLINE_STORE_ENTITY_SERVICE, SoftlineEntityService, StoreModule} from "@softline/core";
import {importProvidersFrom} from "@angular/core";
import {ArtikelStore} from "./store/artikel.store";
import {
  SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE,
  SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN,
  SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
  SOFTLINE_FEATURE_WWS_ARTIKELSUCHVERLAUF
} from "./artikel.shared";
import {SOFTLINE_API_ITEM} from "./artikel.api";
import {ArtikelSuchverlaufStore} from "./store/artikel-suchverlauf.store";
import {ArtikelService} from "./services/artikel.service";
import {SOFTLINE_CONFIG_OFFLINE_FEATURE} from "@softline/application";
import {ArtikelDetailService} from "./services/artikel-detail.service";
import {ArtikelPreisService} from "./services/artikel-preis.service";
import {ArtikelLagerMengenService} from "./services/artikel-lager-mengen.service";
import {ArtikelFavoritenStore} from './store/artikel-favoriten.store';
import {SOFTLINE_API_ITEM_FAVORITE_ITEMS} from '../wws-core.api';

export const artikelProviders = [
  ArtikelService,
  ArtikelDetailService,
  ArtikelPreisService,
  ArtikelLagerMengenService,
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
      feature: ArtikelStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (o: any) => new SoftlineEntityService(o, SOFTLINE_API_ITEM),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_ARTIKELSUCHVERLAUF,
      feature: ArtikelSuchverlaufStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN,
      feature: ArtikelFavoritenStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (o: any) => new SoftlineEntityService(o, SOFTLINE_API_ITEM_FAVORITE_ITEMS),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    })
  ),
  { provide: SOFTLINE_CONFIG_WWS_ARTIKEL_SEQUENCE_SIZE, useValue: 5 },
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: { featureName: SOFTLINE_FEATURE_WWS_ARTIKELSUCHVERLAUF, fields: ['history'] },
    multi: true
  },
]
