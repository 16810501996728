import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal, UiCoreModule, Validators } from '@softline/ui-core';
import { Subkunde } from '../../types/subkunde';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'soft-create-subkunde-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
  templateUrl: './create-subkunde-dialog.component.html',
  styleUrls: ['./create-subkunde-dialog.component.css'],
})
export class CreateSubkundeDialogComponent
  implements OnInit, Modal<Partial<Subkunde>>
{
  form = new FormGroup({
    kostenstelle: new FormControl(''),
    name: new FormControl('', [Validators.required()]),
    aktiv: new FormControl(true, [Validators.required()]),
  });

  private close!: (subkunde: Partial<Subkunde>) => void;

  constructor() {}

  ngOnInit(): void {}

  registerCloseHandler(handler: (result: Partial<Subkunde>) => void): void {
    this.close = handler;
  }

  onSubmit(value: Partial<Subkunde>): void {
    this.close(value);
  }
}
