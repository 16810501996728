import { ValidationMessage } from '@softline/core';

export type LogicalRule =
  | NotRule
  | AndRule
  | OrRule
  | XOrRule
  | ConditionalRule;
export type ValueRule =
  | RequiredRule
  | EmptyRule
  | BooleanRule
  | StringRule
  | NumberRule
  | DateRule
  | IbanRule
  | EqualRule
  | CompareRule
  | PropertyRule
  | LengthRule
  | RegexRule;

export type Rule = LogicalRule | ValueRule;

export interface ValidationRule {
  rule: Rule;
  isValid: boolean;
  messages: ValidationMessage[];
}

export interface ClassRule {
  rule: Rule;
  class: string;
}

export interface FormatRule {
  rules: {
    rule: Rule;
    format: string;
  }[];
  default: string;
}

// LogicalRules
export interface NotRule {
  name: 'not';
  rule: Rule;
}

export interface AndRule {
  name: 'and';
  rules: Rule[];
}

export interface OrRule {
  name: 'or';
  rules: Rule[];
}

export interface XOrRule {
  name: 'xor';
  rules: Rule[];
}

export interface ConditionalRule {
  name: 'conditional';
  property: string;
  propertyRule: Rule;
  rule: Rule;
}

export interface LengthRule {
  name: 'length';
  minLength?: number;
  maxLength?: number;
}

// ValueRules
export interface RequiredRule {
  name: 'required';
}

export interface EmptyRule {
  name: 'empty';
}

export interface BooleanRule {
  name: 'boolean';
  value: boolean;
}

export interface StringRule {
  name: 'string';
  minLength?: number;
  maxLength?: number;
  pattern?: string;
}

export interface NumberRule {
  name: 'number';
  minValue?: number;
  maxValue?: number;
}

export interface DateRule {
  name: 'date';
  minValue?: string;
  maxValue?: string;
}

export interface RegexRule {
  name: 'regex';
  expression: string;
}

export interface IbanRule {
  name: 'iban';
  countries: string[];
}

export interface EqualRule {
  name: 'equal';
  value: any;
}

// CompareRule
export interface PropertyRule {
  name: 'property';
  property: string;
  rule: Rule;
}

export interface CompareRule {
  name: 'compare';
  property1: string;
  property2: string;
  operator: '==' | '!=' | '>' | '>=' | '<' | '<=';
}

export function isValueRule(rule: Rule): rule is ValueRule {
  return (
    rule.name === 'required' ||
    rule.name === 'empty' ||
    rule.name === 'boolean' ||
    rule.name === 'string' ||
    rule.name === 'number' ||
    rule.name === 'date' ||
    rule.name === 'equal' ||
    rule.name === 'compare' ||
    rule.name === 'property' ||
    rule.name === 'length' ||
    rule.name === 'regex'
  );
}

export function isLogicalRule(rule: Rule): rule is LogicalRule {
  return (
    rule.name === 'not' ||
    rule.name === 'and' ||
    rule.name === 'or' ||
    rule.name === 'xor'
  );
}
