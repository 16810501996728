import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  Scanner,
  ScannerService,
  SOFTLINE_CONFIG_SCANNER,
} from '@softline/application';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { JSScannerComponent } from './components/js-scanner.component';
import { JSScannerService } from './services/js-scanner.service';
export function jsScannerFactory(service: ScannerService): Scanner {
  return {
    name: 'js-zxing',
    priority: 0,
    title: 'Camera (JavaScript)',
    service,
  };
}

@NgModule({
  declarations: [JSScannerComponent],
  imports: [CommonModule, ZXingScannerModule],
  exports: [JSScannerComponent],
})
export class JSScannerModule {
  static forRoot(): ModuleWithProviders<JSScannerModule> {
    return {
      ngModule: JSScannerModule,
      providers: [
        JSScannerService,
        {
          provide: SOFTLINE_CONFIG_SCANNER,
          multi: true,
          useFactory: jsScannerFactory,
          deps: [JSScannerService],
        },
      ],
    };
  }
}
