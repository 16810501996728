import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import {
  LocalizationStore,
  SOFTLINE_FEATURE_LOCALIZATION,
} from '@softline/ui-core';
import { defaultIfEmpty, map } from 'rxjs/operators';
import { NumberDefinition } from '../../../../data/definitions';
import { DynamicInputAtom } from '../dynamic-input-atom';

@Component({
  selector: 'soft-dynamic-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent
  extends DynamicInputAtom<NumberDefinition>
  implements OnInit
{
  locale$ = this.store
    .observe(SOFTLINE_FEATURE_LOCALIZATION, LocalizationStore.getter.locale)
    .pipe(map((o) => o ?? this.localeId));

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    super();
  }

  ngOnInit(): void {}
}
