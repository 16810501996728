<div class="flex flex-col-reverse full-width bg-surface lg:bg-transparent -mt-2 sticky -top-2 !z-10 mb-4">
  <soft-step-header [steps]="steps" [currentIndex]="2" #stepper></soft-step-header>
  <soft-default-header [overline]="stepper.stepText"
                    [title]="stepper.current()?.header"
                    [subtitle]="stepper.current()?.subHeader">
  </soft-default-header>
</div>
  <ng-container *ngIf="(bestellung$ | async) as bestellung">
    <div class="flex flex-grow overflow-auto full-width w-full z-[0] lg:-mt-3 relative">
      <div class="flex flex-col w-full h-full gap-4 p-4 pb-3">
        <soft-kopfdaten [isBestellung]="true" [bestellung]="bestellung" [softScrollIntoView]="(((bewegungen$ | async)?.length || 0) > 0)" scrollBehavior="instant" ></soft-kopfdaten>

        <ng-container *ngIf="!loadingBewes(); else skeleton">
          <soft-bewegung-list [isBestellung]="true" [bewegungen]="(bewegungen$ | async) || []"></soft-bewegung-list>
        </ng-container>
      </div>
    </div>
</ng-container>
<ng-template #skeleton>
  <div class="flex flex-col w-full pb-3">
    <div class="skeleton rounded h-[170px] w-full mt-4" *softRepeat="5"></div>
  </div>
</ng-template>
