import {Step} from "@softline/application";

export const SOFTLINE_FEATURE_WWS_WARENUEBERNAHME = 'wwsWarenuebernahme';
export const SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BEWE = 'wwsWarenuebernahmeBewegung';
export const SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_EMBALLAGE = 'wwsWarenuebernahmeEmballage';
export const SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_LIEFERANT = 'wwsWarenuebernahmeLieferant';
export const SOFTLINE_FEATURE_WWS_WARENUEBERNAHME_BESTELLUNG = 'wwsWarenuebernahmeBestellung';

export const SOFTLINE_CONFIG_WWS_WARENUEBERNAHME_BESTELLUNG_STEPS: Step[] = [
  {
    header: 'Lieferant auswählen',
    subHeader: 'Lieferanten mit offenen Bestellungen',
  },
  {
    header: 'Bestellung auswählen',
    subHeader: 'offene Bestellungen für Lieferant ',
  },
  {
    header: 'Bestellung überprüfen',
    subHeader: 'Zusammenfassung der Bestellung',
  }
];

export const SOFTLINE_PERMISSION_WWS_WARENUEBERNAHME = 'WARENUEBERNAHME';
