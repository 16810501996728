import { Component, Type } from "@angular/core";
import { isDefined } from "@softline/core";

export interface DashboardComponent {
  component: Type<Component>
  permission?: string;
}

export function isDashboardComponent(component: unknown): component is DashboardComponent {
  return isDefined((component as DashboardComponent)?.component);
}
