<ng-container *ngIf="!loading; else loadingTemplate">
  <div
    *ngIf="document; else noDocumentTemplate"
    class="flex flex-col h-full items-center justify-center"
  >
    <div class="overflow-auto h-full w-full flex flex-col">
      <canvas class="mx-auto" #container></canvas>
    </div>
    <div class="sticky mx-auto bottom-[10%] z-10 opacity-20 hover:opacity-80">
      <button class="soft-button secondary" (click)="zoomOut()">
        <i class="fa-regular fa-magnifying-glass-minus"></i>
      </button>
      <button class="soft-button secondary" (click)="zoomIn()">
        <i class="fa-regular fa-magnifying-glass-plus"></i>
      </button>

      <button
        class="soft-button secondary"
        [disabled]="((currentPage$ | async) ?? 0) <= 1"
        (click)="prevPage()"
      >
        <i class="fa-regular fa-arrow-up"></i>
      </button>
      <button
        class="soft-button secondary"
        [disabled]="((currentPage$ | async) ?? 0) > pages - 1"
        (click)="nextPage()"
      >
        <i class="fa-regular fa-arrow-down"></i>
      </button>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="h-full w-full p-4">
    <div class="flex flex-col bg-white gap-1 h-full w-full p-4">
      <div class="h-3 skeleton w-1/4 mt-8"></div>
      <div class="h-3 skeleton w-1/4"></div>
      <div class="h-3 skeleton w-1/4"></div>
      <div class="h-3 skeleton w-1/4"></div>

      <div class="h-5 skeleton w-full mt-16 w-1/2"></div>

      <div class="h-3 skeleton w-full mt-4"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-3/4"></div>

      <div class="h-3 skeleton w-full mt-8"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-3/4"></div>

      <div class="h-3 skeleton w-full mt-8"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-3/4"></div>

      <div class="h-3 skeleton w-full mt-8"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-full"></div>
      <div class="h-3 skeleton w-3/4"></div>
    </div>
  </div>
</ng-template>

<ng-template #noDocumentTemplate>
  <div class="flex justify-center h-full w-full p-4">
    <span>{{ '#PDF_VIEWER.NO_DOCUMENT' | translate }}</span>
  </div>
</ng-template>
