import { Type } from '@angular/core';

export type LogType = 'error' | 'warning' | 'success' | 'info' | 'debug';

export interface LogEntry {
  subject: string;
  message?: string;
  priority?: number;
  category?: string;
  type?: LogType;
  source?: string | Type<object>;
  params?: any;
}

export enum LogPriority {
  Critical = 1,
  Important = 2,
  Normal = 5,
  Low = 7,
  Miscellaneous = 9,
}
