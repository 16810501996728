import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { ConnectionModule, CoreModule, StoreModule } from '@softline/core';
import { UiCoreModule } from '@softline/ui-core';
import { environment } from '../environments/environment';
import {
  accountContextProviders,
  connectionSettingsProviders, contextSettingsProviders,
  contextStorageProviders,
  settingsProviders,
  userContextProviders,
  utilitiesProviders,
  vkforgContextProviders
} from '@softapps/allgemein/utils';
import {
  AuthenticationModule,
  AuthorizationModule,
  JwtAuthenticationModule,
} from '@softline/auth';
import {
  AppearanceModule,
  ApplicationModule,
  ConsoleModule,
  DeveloperModule,
  ItemScanModule,
  MasterDataModule,
  OfflineModule,
  remoteConfigProviders,
} from '@softline/application';
import { DynamicModule } from '@softline/dynamic';
import { pdfViewerProviders } from '@softapps/allgemein/pdf-viewer';
import { loginProviders } from '@softapps/allgemein/login';
import { passwordProviders } from '@softapps/allgemein/password';
import { moduleProviders } from '@softapps/allgemein/modules';
import { artikelInfoProviders } from '@softapps/wws/artikel-info';
import { platform } from '../platforms/platform';
import { wwsCoreProviders } from '@softapps/wws/core';
import { bestellvorschlagProviders } from '@softapps/mde/bestellvorschlag';
import { bestellerfassungProviders } from '@softapps/mde/bestellerfassung';
import { etikettendruckProviders } from '@softapps/mde/etikettendruck';
import { inventurProviders } from '@softapps/mde/inventur';
import { retourenProviders } from '@softapps/mde/retouren';
import { warenverprobungProviders } from '@softapps/mde/warenverprobung';
import { SoftappsCoreModule } from '@softapps/core';
import {statusabfrageProviders} from '@softapps/pms/statusabfrage';
import {stempelerfassungProviders} from '@softapps/pms/stempelerfassung';
import { fibuCoreProviders, kundeninfoLeisteProviders } from '@softapps/fibu/core';
import { haftruecklassProviders } from '@softapps/fibu/haftruecklass';
import { artikelvorratProviders } from '@softapps/mde/artikelvorrat';
import { materiallieferscheinScanProviders } from '@softapps/mde/materiallieferschein-scan';
import {
  BelegDownloadStrategy, FremdbelegDownloadStrategy,
  gesamtabfrageProviders,
  SOFTLINE_CONFIG_GESAMTABFRAGE
} from '@softapps/wws/gesamtabfrage';
import { warenuebernahmeProviders } from '@softapps/wws/warenuebernahme';
import { checklisteProviders } from '@softapps/allgemein/checkliste';
import { kundenabfrageProviders } from '@softapps/wws/kundenabfrage';
import { umsatzstatistikProviders } from '@softapps/wws/umsatzstatistik';
import { dashboardProviders, provideKundeninfoWidget } from '@softapps/allgemein/dashboard';
import { kreditlimitProviders } from '@softapps/wws/kreditlimit';
import { offenePostenProviders } from '@softapps/fibu/offene-posten';
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),

      // Framework
      CoreModule.forRoot(),
      StoreModule.forRoot(),
      UiCoreModule.forRoot(),
      ConnectionModule.forRoot(environment.connection),
      AuthenticationModule.forRoot(),

      JwtAuthenticationModule.forRoot(),
      AuthorizationModule.forRoot(),
      ApplicationModule.forRoot(),
      ItemScanModule.forRoot(),
      AppearanceModule.forRoot(),
      DynamicModule.forRoot(),
      MasterDataModule.forRoot(),
      DeveloperModule.forRoot(),
      ConsoleModule.forRoot(),
      OfflineModule.forRoot(),
      ItemScanModule.forRoot(),
      SoftappsCoreModule.forRoot()
    ),

    // Allgemein
    ...utilitiesProviders,
    ...userContextProviders,
    ...settingsProviders,
    ...connectionSettingsProviders,
    ...pdfViewerProviders,
    ...loginProviders,
    ...passwordProviders,
    ...moduleProviders,
    ...remoteConfigProviders,

    ...contextSettingsProviders,
    ...vkforgContextProviders,
    ...accountContextProviders,
    ...contextStorageProviders,

    // MDE
    ...wwsCoreProviders,
    ...fibuCoreProviders,

    // Libs
    ...dashboardProviders,
    ...kundeninfoLeisteProviders,
    ...provideKundeninfoWidget(),
    ...kreditlimitProviders,
    ...umsatzstatistikProviders,
    ...offenePostenProviders,

    ...haftruecklassProviders,

    ...bestellvorschlagProviders,
    ...bestellerfassungProviders,
    ...etikettendruckProviders,
    ...inventurProviders,
    ...warenverprobungProviders,
    ...retourenProviders,
    ...artikelvorratProviders,
    ...materiallieferscheinScanProviders,

    ...artikelInfoProviders,
    ...gesamtabfrageProviders,

    ...warenuebernahmeProviders,
    ...checklisteProviders,

    ...kundenabfrageProviders,
    ...statusabfrageProviders,
    ...stempelerfassungProviders,

    ...platform.providers,
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: SOFTLINE_CONFIG_GESAMTABFRAGE,
      useValue: {
        geschaeftsfall: true,
        download: [
          {
            belegart: 'Rechnung',
            strategies: [BelegDownloadStrategy, FremdbelegDownloadStrategy],
          },
          {
            belegart: 'Lieferschein',
            strategies: [BelegDownloadStrategy, FremdbelegDownloadStrategy],
          },
          {
            belegart: 'Auftrag',
            strategies: [BelegDownloadStrategy],
          },
          {
            belegart: 'Offert',
            strategies: [BelegDownloadStrategy],
          },
        ],
      },
    },
  ],
};
