<div class="flex flex-col">
  <div class="flex flex-row text-sm color-primary">
    <span>{{item['bereich']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="font-bold">{{item['dokumentation']}}</span>
  </div>
  <div class="flex flex-row">
    <span class="color-light">{{item['definition']}}</span>
  </div>
</div>
