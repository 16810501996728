<div class="flex flex-col soft-card rounded">
  <div class="soft-overline">{{'#TIMESTAMP.TITLE' | translate}}</div>

  <div class="grid timestamp-grid gap-y-2 mt-2">
    @for (timestamp of timestamps(); track timestamp.id) {
      <div class="w-1 rounded"
           [ngClass]="timestamp.flag === 'arrival' ? 'bg-success' : 'bg-warning'">
      </div>
      <span class="stamp-title ml-2">{{timestamp?.timestamp | date: 'HH:mm' }}</span>
      <span class="ml-4">{{(timestamp.flag === 'arrival' ? '#TIMESTAMP.TYPE.ARRIVAL' : '#TIMESTAMP.TYPE.DEPARTURE') | translate}}</span>
      <div class="flex flex-row">
        @if (timestamp.code) {
          <span class="ml-8 stamp-information">
        ({{ timestamp.code.name }}
            @if (timestamp.code.description) {
              -&nbsp;{{ timestamp.code.description }}
            })
        </span>
        }
      </div>
    }
    @empty {
      @if (loaded() && !loading()) {
        <div class="my-4 w-full flex flex-row col-span-5 items-center justify-center text-light">
          {{ '#TIMESTAMP.NO_DATA' | translate }}
        </div>
      } @else if (loading()) {
        <ng-container *softRepeat="3">
          <div class="w-[20vh] h-4 skeleton col-span-2"></div>
          <div class="w-full h-4 col-span-2"></div>
        </ng-container>
      }
    }
  </div>

  <button class="soft-button accent h-16 mt-4"
          (click)="onCreate()">
    <div class="flex flex-row flex-nowrap justify-center">
      <soft-icon class="mr-2" name="fa-regular fa-stopwatch"></soft-icon>
      {{'#TIMESTAMP.RECORD_TIMESTAMP' | translate}}
    </div>
  </button>
</div>

