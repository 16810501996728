import {Inject, Injectable} from "@angular/core";
import {ConnectionHttpService, ConnectionResourceLocation, SOFTLINE_SERVICE_HTTP} from "@softline/core";
import {
  SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG_BEWES,
  SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG_UEBERNEHMEN
} from "../warenuebernahme.api";
import {Warenuebernahme} from "../types/warenuebernahme";
import {Bewe} from "../types/bewegung";
import {Observable} from "rxjs";

@Injectable()
export class BestellungService {
  private loadBewegungenLocation(idbestellung: string): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG_BEWES,
      pathParams: {idbestellung}
    }
  }

  private bestellungUebernehmenLocation(): ConnectionResourceLocation {
    return {
      path: SOFTLINE_API_WWS_WARENUEBERNAHME_BESTELLUNG_UEBERNEHMEN,
    }
  }
  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService
  ) {}

  loadBewegungen(idbestellung: string): Observable<Bewe[]> {
    const location = this.loadBewegungenLocation(idbestellung);

    return this.httpService.get<Bewe[]>(location);
  }

  bestellungUebernehmen(idbestellungList: number[], lieferscheinnummer: string): Observable<Warenuebernahme> {
    const location = this.bestellungUebernehmenLocation();
    const payload = {
      lieferscheinnummer: lieferscheinnummer,
      idbestellungList
    }

    return this.httpService.create<object, Warenuebernahme>(location, payload);
  }
}
