import {ResourceService} from '../../data/abstraction';
import {ConnectionResourceLocation} from '../../data/specialized/http/connection.location';
import {LoadListParameters, ReadonlyRepository} from '../abstraction';
import {Observable} from 'rxjs';

export class ReadonlyHttpApiListRepository<TResult> implements ReadonlyRepository<TResult> {

  constructor(protected service: ResourceService<ConnectionResourceLocation>,
              protected path: string | string[],
              protected options?: { method: 'POST' | 'GET' }) {
  }

  load(params?: LoadListParameters): Observable<TResult> {
    const location: ConnectionResourceLocation = {
      path: this.path,
      pathParams: params?.pathParams,
    }

    if (this.options?.method === 'POST') {
      return this.service.get(location, params?.query);
    } else {
      location.queryParams = params?.query;
      return this.service.get(location);
    }
  }
}
