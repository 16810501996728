import { State } from '../connection.store';
import { ConnectionLoader } from '../abstraction';
import { Connection } from '../types/connection';

export class ConstantConnectionLoader extends ConnectionLoader {
  constructor(private connection: Connection) {
    super();
  }

  async load(): Promise<State> {
    return {
      connections: [this.connection],
      selected: 0,
    };
  }
}
